import React, { useContext, useEffect, useRef, useState } from 'react'
import '../../CSS/SavedAddresses.css'
import { Button, Card, Input, Modal } from 'reactstrap'
import customFetchData from '../../Components/Custom/customFetchData'
import { globalState } from '../../GlobalState/globalState'
import { MdOutlineEdit } from "react-icons/md";
import RadioBtnCheck from '../../Assets/radionButtonChk.png'
import RadioBtnUnCheck from '../../Assets/radioButtonUnChk.png'
import DeleteIcon from '../../Assets/delete_icon.png'
import EditIcon from '../../Assets/edit_profile.png'
import CloseIcon from '../../Assets/icons-closeModal.png'
import { ToastContainer, toast } from 'react-toastify';
import InputComponent from '../../Components/Custom/UI/InputComponent'
import { ClipLoader } from 'react-spinners'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'

export default function SavedAddresses(addressProps_Web) {
    const [state,dispatch]=useContext(globalState)
    const [savedAddresses,setSavedAddresses]=useState([])
    const [checkedRadio,setCheckedRadio]=useState(false)
    const [defaultCheck,setDefaultCheck]=useState([])
    const [loading,setLoading]=useState(false)
    const [deleteData,setDeleteData]=useState({})
    const [deleteModal,setDeleteModal]=useState(false)
    const [deletedItem,setDeletedItem]=useState(false)
    const [saveAddressModal,setSaveAddressModal]=useState(false)
    const input_Ref1 = useRef();
    const input_Ref2 = useRef();
    const [clickedValue,setClickedValue]=useState([])
    const [selectedTag,setSelectedTag]=useState('Home');
    const useTabletAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 760px)" })
    const isMobile = useTabletAndBelowMediaQuery()
    let placesArray=
    [
        {
            id:1,
            place:'Home'
        },
        {
            id:2,
            place:'Work'
        },
        {
            id:3,
            place:'Hotel'
        },
        {
            id:4,
            place:'Others'
        }
    ]
    const [allValues, setAllValues] = useState({
      cmptAddress: '',
      floor: '',
      landmark: '',
      status: '',
      message: '',
      addError: false,
      floorError: false,
      landError: false
  })
  const [addressSaved,setAddressSaved]=useState(false)
  const [editAddress,setEditAddress]=useState({})
  let paramsLocation=useNavigate()
  let addressProps=addressProps_Web ? addressProps_Web : paramsLocation.state.addressProps
//   console.log('address',addressProps)
    useEffect(()=>{
        // console.log('item')
        savedAddressFetch()
    },[checkedRadio,deletedItem,addressSaved])
    const savedAddressFetch=async()=>{
        let body={
            uid:state.user.id
        }
        setLoading(true)
        let savedAddresses=await customFetchData('POST',body,'/website/fetchSavedAddress/sv2',state,dispatch)
        // console.log('address saved',savedAddresses)
        if(savedAddresses.fetch){
            let dataFetch=savedAddresses.fetchedData
            if(dataFetch.data){
                if(dataFetch.fetchAddressResult.length!==0){
                    setLoading(false)
                    setSavedAddresses(dataFetch.fetchAddressResult)
                    // dataFetch.fetchAddressResult.map((item,index)=>{
                    //     return defaultCheck['defaultCheck_'+ item.QF_CONSUMER_LOCATION_PRID]=true
                    // })
                }else{
                    setLoading(false)
                    setSavedAddresses([]) 
                }
            }else{
                setLoading(false)
                setSavedAddresses([]) 
            }
        }
        placesArray.map((item,index)=>{
            if(item.id === 1){
                // console.log('place array1',item)
               return  clickedValue['clickedValue_'+ item.id]=true
                
            }else{
                // console.log('place array2',item)
               return clickedValue['clickedValue_'+ item.id]=false
                // console.log('placse array',clickedValue)
            }
        })
    }
    const checkClicked = async(item) =>{
        // console.log('item',item)
       
        let body={
            uid:item.QF_CONSUMER_PRID,
            consumer_loc_prid:item.QF_CONSUMER_LOCATION_PRID,
            // defaultCheck:item.CONSUMER_ADDRESS_DEFAULT_FLAG == 1 ? 0 : ((item.CONSUMER_ADDRESS_DEFAULT_FLAG == 0 || item.CONSUMER_ADDRESS_DEFAULT_FLAG == null) ? 1 : 0)
        }
        let radioChecked=await customFetchData('POST',body,'/website/setAsDefaultAddress',state,dispatch)
        setCheckedRadio(!checkedRadio)
        // console.log('radio checked',radioChecked)
    }
    const deleteModalOpen =(item)=>{
        setDeleteData(item)
        setDeleteModal(true)
    }
    const deleteModalClose=()=>{
        setDeleteModal(false)
        setDeleteData({})
    }
    const onClickYes=async()=>{
        setDeleteModal(false)
        // console.log(deleteData)
        let body={
            uid:deleteData.QF_CONSUMER_PRID,
            consumer_loc_prid:deleteData.QF_CONSUMER_LOCATION_PRID,
        }
        let deleteAddress=await customFetchData('POST',body,'/website/deleteAddress',state,dispatch)
        console.log('radio checked',deleteAddress)
        if(deleteAddress.fetch){
            if(deleteAddress.fetchedData.data){
                toast("address deleted successfully", {
                    position: "bottom-center",
                    hideProgressBar: true,
                    progress: undefined,
                    style:{backgroundColor:'#E0D1FF'}
                });
                // toast.success("address deleted successfully", {position:toast.POSITION.BOTTOM_CENTER})
                setDeletedItem(!deletedItem)
            }
        }else{
            toast("Something went wrong. Please try later.", {
                position: "bottom-center",
                hideProgressBar: true,
                progress: undefined,
                style:{backgroundColor:'#E0D1FF'}
            });
            // toast.error("Something went wrong. Please try later.", {position:toast.POSITION.BOTTOM_CENTER});
        }
    }
    let deleteModalDisplay=(
        <Modal isOpen={deleteModal} toggle={deleteModalClose} size={"md"} centered>
            <div style={{padding:isMobile ? 20 : 28,backgroundColor:'#fff', borderRadius:10}}>
                <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>
                    <text className='are_you_wantText'>Are you sure you want to remove?</text>
                    <div onClick={deleteModalClose} style={{cursor:'pointer',boxShadow: '0 2px 4px 0 rgba(103, 52, 204, 0.1)',width:22,height:22,borderRadius:20,backgroundColor: '#fff',display:'flex',justifyContent:'center',flexDirection:'row',alignItems:'center'}}>
                        <img src={CloseIcon} style={{width:16,height:16}}/>
                    </div>
                </div>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:20}}>
                    <div className='yes_Btn_SaveAdd' onClick={onClickYes}>
                        <text className='yes_Text_SaveAdd'>Yes, Please</text>
                    </div>
                    <div className='no_Btn_SaveAdd' onClick={deleteModalClose} style={{marginLeft:20}}>
                        <text className='no_Text_SaveAdd'>No, Thanks </text>
                    </div>
                </div>
            </div>
        </Modal>
    )
    const saveAddressModalOpen = () =>{
        setSaveAddressModal(true)
     }
     const saveAddressModalClose = () =>{
        setEditAddress({})
        setSaveAddressModal(false)
        setAllValues({
           cmptAddress: '',
           floor: '',
           landmark: '',
           status: '',
           message: '',
           addError: false,
           floorError: false,
           landError: false
        })
        placesArray.map((item,index)=>{
            if(item.id === 1){
                // console.log('place array1',item)
               return  clickedValue['clickedValue_'+ item.id]=true
                
            }else{
                // console.log('place array2',item)
               return clickedValue['clickedValue_'+ item.id]=false
                // console.log('placse array',clickedValue)
            }
        })
     }
     let smallCardStyle={
        width: '70px',
        height: '25px',
        display: 'flex',
        // flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '7px',
        border:'solid 1px rgba(116,116,116,0.4)',
        marginRight:20,
        // padding:10
     }
     let smallCardSelectedStyle={
        width: '70px',
        height: '25px',
        display: 'flex',
        // flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '7px',
        border: 'solid 1px #8c52ff',
        backgroundColor:'rgba(216,197,252,0.6)',
        marginRight:20,
        // padding:10
     }
     //// onClick func to active card with backgroud color
     const onPressSmallCard = (data) => {
        // console.log('values', data)
        setSelectedTag(data.place)
        placesArray.map(item=>{
            if(item.id === data.id){
            // console.log('items yes',item.id === data.id ,item.id,data.id)
                return setClickedValue({
                    ['clickedValue_'+ item.id]:true   
                })
            }
        })
        // console.log('clicked value',clickedValue)
     }
     const CompleteAddressHandler = (event) => {
        let value=event.target.value
        if (value.trim() === '') {
            setAllValues({
                ...allValues,
                cmptAddress: '',
                addError: true,
                status: 'error',
                message: 'Please enter complete address'
            })
        } else {
            setAllValues({
                ...allValues,
                cmptAddress: value,
                status: '',
                addError: false,
            })
        }
     }
     const FloorHandler = (event) => {
        let value=event.target.value
        setAllValues({
            ...allValues,
            floor: value
        })
     }
     const LandmarkHandler = (event) => {
        let value=event.target.value
        setAllValues({
            ...allValues,
            landmark: value
        })
     }
     const onCompleteAddressSubmit = () => {
        if (allValues.cmptAddress.trim() === '') {
            setAllValues({
                ...allValues,
                status: 'error',
                message: 'Please enter complete address',
                addError: true
            })
        } else {
            setAllValues({ ...allValues, addError: false })
            input_Ref1.current.focus()
        }
     }
     const onFloorSubmit = () => {
        setAllValues({ ...allValues, floorError: false })
        input_Ref2.current.focus()
     }
     const onLandmarkSubmit = () => {
        setAllValues({ landError: false })
        // Keyboard.dismiss();
     }
     const clickOnSaveAddress = async() =>{
        try {
           if (allValues.cmptAddress.trim() === '') {
               setAllValues({
                   ...allValues,
                   status: 'error',
                   message: 'Please enter complete address',
                   addError: true
               })
           }else{
            // console.log(state.address,selectedTag,addressProps.totalAddress,state.user.id,editAddress.length,Object.keys(editAddress))
            let body;
            if(Object.keys(editAddress).length!==0){
                console.log('body1')
                body = {
                    totalAddress: editAddress.CONSUMER_ADDRESS,
                    latitude: editAddress.CONSUMER_ADDRESS_LAT,
                    longitude: editAddress.CONSUMER_ADDRESS_LONG,
                    city: editAddress.CONSUMER_ADDRESS_CITY,
                    country: editAddress.CONSUMER_ADDRESS_COUNTRY,
                    state: editAddress.CONSUMER_ADDRESS_STATE,
                    zipcode: editAddress.CONSUMER_ADDRESS_ZIPCODE,
                    district: editAddress.CONSUMER_ADDRESS_DISTRICT,
                    addressLine1: editAddress.CONSUMER_ADDRESS_LINE1,
                    addressLine2: allValues.cmptAddress,
                    addressLine3: allValues.floor,
                    addressLine4: allValues.landmark,
                    selectedTag: selectedTag,
                    uid:state.user.id  
                }
            }else{
                // console.log('body2',addressProps)
                body = {
                    totalAddress: addressProps.totalAddress ? addressProps.totalAddress : state.address.totalAddress,
                    latitude: addressProps.latitude ? addressProps.latitude : state.address.latitude,
                    longitude: addressProps.longitude ? addressProps.longitude : state.address.longitude,
                    city: addressProps.city ? addressProps.city : state.address.city,
                    country: addressProps.country ? addressProps.country : state.address.country,
                    state: addressProps.stateOrProvince ? addressProps.stateOrProvince : state.address.stateOrProvince,
                    zipcode: addressProps.stateOrProvince ? addressProps.stateOrProvince : state.address.zipcode,
                    district: addressProps.district ? addressProps.district : state.address.district,
                    addressLine1: state.address.addressLine1 ? state.address.addressLine1 : addressProps.addressLine1,
                    addressLine2: allValues.cmptAddress,
                    addressLine3: allValues.floor,
                    addressLine4: allValues.landmark,
                    selectedTag: selectedTag,
                    uid:state.user.id 
                } 
            }
            // console.log('body', body)
            let method = 'POST';
            let url = "/website/addressSave/sv2"
            let useFetchRes = await customFetchData(method, body, url, state, dispatch)
            // console.log('response', useFetchRes)
            if (useFetchRes.fetch) {
                setSaveAddressModal(false)
                setAllValues({ ...allValues, cmptAddress: '', floor: '', landmark: '', addError: '', status: '' })
                toast("address saved successfully", {
                    position: "bottom-center",
                    hideProgressBar: true,
                    progress: undefined,
                    style:{backgroundColor:'#E0D1FF'}
                });
                // toast.success(<text style={{textAlign:'center'}}>address saved successfully</text>, { position: toast.POSITION.BOTTOM_CENTER })
                setAddressSaved(!addressSaved)                   
            }
           }
       }
       catch (error) {
        toast("something went wrong", {
            position: "bottom-center",
            hideProgressBar: true,
            progress: undefined,
            style:{backgroundColor:'#E0D1FF'}
        });
        //    toast.warn(<text style={{textAlign:'center'}}>something went wrong</text>, { position: toast.POSITION.BOTTOM_CENTER });
       }
     }
     let textStyle={
        width:'100%',
        opacity: 0.4,
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left', 
        color:'#8c52ff',
     }
     let errorBox={
        borderRadius: 4.2,
        backgroundColor: "#ffe4c5",
        shadowColor: "rgba(0, 0, 0, 0.04)",
        shadowOffset: {
          width: 0,
          height: 0
        },
        shadowRadius: 31,
        shadowOpacity: 1,
        display:"flex",
        justifyContent: "center",
        alignItems: "center", 
        paddingTop:5,
        paddingBottom:5
     }
     let errorText={
        fontFamily: "Inter",
        fontSize: 12.5,
        fontWeight: "600",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        color: "#f3972d"
     }
     let saveAddressModalDisplay=(
        <Modal isOpen={saveAddressModal} size={'md'} toggle={() => saveAddressModalClose()} centered>
           <div style={{padding:20}}>
              <div style={{ justifyContent: 'flex-end', display: 'flex', flexDirection: 'row' }}>
                 <div className='crossIcon_ViewPro_Home' style={{ cursor: 'pointer' }}>
                    <img src={CloseIcon} style={{ width: 18, height: 18 }} onClick={() => saveAddressModalClose()} />
                 </div>
              </div>
              <div style={{marginTop:10}}>
                 <text className='enterText_Address'>Enter complete address</text>
              </div>
              <hr/>
              <div>
                 <text className='saveAdreesAs_Text'>Save address as*</text>
                 <div style={{display: 'flex', flexDirection: 'row',marginTop:20}}>
                    {
                       placesArray.length!==0 &&
                       placesArray.map(item=>{
                          return <div onClick={()=>onPressSmallCard(item)} style={clickedValue['clickedValue_'+ item.id] ? smallCardSelectedStyle : smallCardStyle}>
                             <text className='saveAdreesAs_Text' style={{color:clickedValue['clickedValue_'+ item.id] ? '#8c52ff' : '#747474'}}>{item.place}</text>
                          </div>
                       })
                    }
                 </div>
              </div>
              <div style={{marginTop:20}}>
                 {
                    allValues.status == 'error' &&
                    <div>
                       <div style={errorBox}>
                          <text style={errorText}>{allValues.message}</text>
                       </div>
                    </div>
                 }
                 <div style={{paddingTop:20}}>
                    <InputComponent
                       inputWidthStyle={'100%'} 
                       InputFields={{
                          type:"text",
                          // placeholderTextColor: '#8c52ff',
                          onChange: CompleteAddressHandler,
                          value: allValues.cmptAddress,
                          placeholder: "Complete Address",
                          // onKeyDown: () => onCompleteAddressSubmit(),
                          blurOnSubmit: false,
                          error: allValues.addError,
                          // returnKeyType: "next"
                      }}
                      textStyle={textStyle}
                    />
                 </div>
                 <div style={{paddingTop:20}}>
                    <InputComponent 
                       inputWidthStyle={'100%'}
                       InputFields={{
                          type:"text",
                          // placeholderTextColor: '#9d9d9d',
                          onChange: FloorHandler,
                          value: allValues.floor,
                          placeholder: "Floor (Optional)",
                          ref: input_Ref1,
                          // onKeyDown: () => onFloorSubmit(),
                          blurOnSubmit: false,
                          error: allValues.floorError,
                          // returnKeyType: "next"
                       }}
                       textStyle={textStyle}
                    />
                 </div>
                 <div style={{paddingTop:20}}>
                    <InputComponent 
                       inputWidthStyle={'100%'}
                       InputFields={{
                          type:"text",
                          // placeholderTextColor: '#9d9d9d',
                          onChange: LandmarkHandler,
                          value: allValues.landmark,
                          placeholder: "Nearby landmark (Optional)",
                          //   keyboardType="default"
                          // returnKeyType: "done",
                          // onKeyDown: () => onLandmarkSubmit(),
                          blurOnSubmit: false,
                          ref: input_Ref2,
                          error: allValues.landError
                      }}
                      textStyle={textStyle}
                    />
                 </div>
              </div>
              <div style={{marginTop:20,marginBottom:20}}>
                 <Button className='saveAdd_Btn_Home' onClick={()=>clickOnSaveAddress()}>
                    <text className='saveAdd_Confm_Text'>
                       Save Address
                    </text>
                 </Button>
              </div>
           </div>
        </Modal>
     )
     const editAddressModalOpen = (item) =>{
        // console.log(item)
        setSaveAddressModal(true)
        setSelectedTag(item.CONSUMER_ADDRESS_SELECTED_TAG)
        placesArray.map(item1=>{
            if(item1.place === item.CONSUMER_ADDRESS_SELECTED_TAG){
            // console.log('items yes',item.id === data.id ,item.id,data.id)
                return setClickedValue({
                    ['clickedValue_'+ item1.id]:true   
                })
            }
        })
        setEditAddress(item)
        setAllValues({
            ...allValues,
            cmptAddress:item.CONSUMER_ADDRESS_LINE2,
            floor:item.CONSUMER_ADDRESS_LINE3,
            landmark:item.CONSUMER_ADDRESS_LINE4
        })
     }
    //  console.log('check',checkedRadio)
    //  console.log('state',state)
  return (
        <div style={{width:'100%',padding:16,paddingTop:isMobile ? 100 : 20}}>
            {
                isMobile ?
                <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',marginLeft:-16,marginRight:-16,padding:16,boxShadow:'0 2px 8px 0 rgba(103, 52, 204, 0.15)',backgroundColor: '#fff',flexDirection:'row'}} onClick={saveAddressModalOpen}>
                    <div>
                        <div className='addNew_Address_ProfileIcon'>
                            <text className='addNew_Address_Profile' style={{fontSize:20}}>+</text>
                        </div>
                    </div>
                    <div>
                        <text className='addNew_Address_Profile'>Add new address</text>
                    </div>
                   
                </div>
                :
                <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>
                    <div>
                        <text className='addNew_Address_Profile'>Add new address</text>
                    </div>
                    <div>
                        <div className='addNew_Address_ProfileIcon' onClick={saveAddressModalOpen} style={{cursor:'pointer'}}>
                            <text className='addNew_Address_Profile' style={{fontSize:20}}>+</text>
                        </div>
                    </div>
                </div>
            }
            {!isMobile && <hr/> }
            <div style={{height:isMobile ? (savedAddresses.length <=2 ? 250 : (savedAddresses.length === 0 ? 200 : 'auto')) : 'auto',paddingTop:15}}>
                {
                savedAddresses.length !==0 ?
                   savedAddresses.map(item=>{
                    return(
                        <div  key={item.QF_CONSUMER_LOCATION_PRID} style={{display:'flex',justifyContent:'space-between',flexDirection:'row',paddingTop:15,paddingBottom:15}}>
                            <div style={{display:'flex',flexDirection:'column'}}>
                                <div style={{display:'flex',flexDirection:'row',cursor:'pointer'}}>
                                    <div onClick={()=>checkClicked(item)} >
                                        {item.CONSUMER_ADDRESS_DEFAULT_FLAG == 1 ? <img src={RadioBtnCheck} style={{width:20,height:20}}/> : <img src={RadioBtnUnCheck} style={{width:16,height:16}}/>}
                                    </div>
                                    {/* <Input type="radio" checked={false} style={{ color: '#8c52ff' }}/> */}
                                    <div  style={{paddingLeft:20,display:'flex',flexDirection:'column'}}>
                                        <text className='addNew_Address_Profile'>{item.CONSUMER_ADDRESS_SELECTED_TAG}</text>
                                        <text className='subText_SaveAddress'>{item.CONSUMER_ADDRESS}</text>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                    <div>
                                        <div className='editBackView_Address' style={{cursor:'pointer'}} onClick={()=>editAddressModalOpen(item)}>
                                            <img src={EditIcon}/>
                                        </div>
                                    </div>
                                    <div style={{marginLeft:15}}>
                                        <div className='editBackView_Address'  style={{cursor:'pointer'}} onClick={()=>deleteModalOpen(item)}>
                                            <img src={DeleteIcon}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                   })
                :
                    loading ?
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:200,paddingTop:15,paddingBottom:15}}>  
                       <ClipLoader widthUnit={"%"} size={20} loadings={loading} color="#8c52ff" />
                    </div> 
                    :
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:200,paddingTop:15,paddingBottom:15}}>  
                        <text>No Addresses are saved</text>
                    </div>                  
                }
            </div>
            {
                deleteModal && deleteModalDisplay
            }
            {
                saveAddressModal && saveAddressModalDisplay
            }
            <ToastContainer/>
        </div>
  )
}
