import React, { useEffect, useState } from 'react'
import downArrow from '../../../New_WebPage_Design/Assets/Commons/Path 8 Copy.png'
import upArrow from '../../../New_WebPage_Design/Assets/Commons/Path 8.png'
import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import { Container, Row, Col, Card, CardText, Collapse } from "reactstrap";

import '../../CSS/FAQ.css'

const FaqMerchantMobile = () => {
    let [faqs_profileRegistration, setFaqs_profileRegistration] = useState(
        [
             {
                 id:1,
                 ques:'How do I download Slash Pro App?',
                 ans:(
                     <div>
                         Here’s how you can download the Slash Pro App on iOS devices with iOS 10 and newer–
                         <ol type='a'>
                             <li>Visit App store on your iPhone.</li>
                             <li>Select Search from the menu, type Slash Pro App in the search bar, then tap Search.</li>
                             <li>Select the Slash Pro icon.</li>
                             <li>Tap “Get” your device may require you to enter your App Store password.</li>
                             <li>When download is complete, “Get” will change to “Install”. Tap “Install” to start the installation.</li>
                             <li>When the installation is complete, “Install” will change to “Open”. Tap on it.</li>
                             <li>In the Slash Pro app, click on “Register” to create a new Professional account or click on “Login” if you already have an account.</li>
                         </ol>
                         Here’s how you can download the Slash Pro App on android devices 8 & above-
                         <ol type='a'>
                             <li>Head to the Google Play store and follow these steps:</li>
                             <li>Tap the Google Play icon.</li>
                             <li>Tap the search bar, type Slash Pro, then tap Search.</li>
                             <li>Select the Slash Pro icon and tap “Install”.</li>
                             <li>After app installation is complete, tap “Open”.</li>
                             <li>In the Slash Pro app, click on “Register” to create a new Professional account or click on “Login” if you already have an account.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:2,
                 ques:'How do I update my Slash App?',
                 ans:'We periodically update our app with new features and notify users to update the application for better user experience.  You can visit the Play Store or App Store to update your Slash Pro App.'
             },
             {
                 id:3,
                 ques:'How can I register myself as an individual or business on Slash Pro App?',
                 ans:(
                     <div>
                         To start with, you need to have the Slash Pro app installed on your mobile phone. After Installation, follow the steps below to register yourself -
                         <ol type="a">
                             <li>Open the app. Before registering, click on your preferred language and tap on “Proceed”.</li>
                             <li>Now, click on “Register” to create a new Professional account.</li>
                             <li>Please enter Business Name or Your Name.</li>
                             <li>Enter Email Address or Mobile Number.</li>
                             <li>Set your Password.</li>
                         </ol>
                         Follow the below steps if you are registering through Mobile number.
                         <ol type="a">
                             <li>If you’re registering with your mobile Number, a pop-up screen appears to validate your Mobile Number. Tap on Send to receive a 4-digit verification code</li>
                             <li>In your Text messages, check for the code and enter it in the app.</li>
                             <li>Tap on Verify</li>
                             <li>You may click on resend verification code if you have not received or the code has expired.</li>
                         </ol>
                         Follow the below steps if you are registering with your Email Address.
                         <ol type="a">
                             <li>Please access your email account inbox and check for a mail from “Verify Slash” (verify@goslash.com)</li>
                             <li>Tap on the mail and look for “Click here to Verify” tab in the mail body.</li>
                             <li>You can verify your account by clicking on “Click here to verify” tab or tap on the URL below the tab.</li>
                         </ol>
                         <p>After the verification is complete, enter your account password to login. Enter your Business Address so that Customers can easily access you. You can also Search for partial or full address and click on “Save Address”.</p>
                     </div>
                 )
             },
             {
                 id:4,
                 ques:'How can I edit my business profile/details?',
                 ans:(
                     <div>
                         You can follow below mentioned steps to edit your business profile/details -
                         <ol type="a">
                             <li>Visit your profile and click on the edit icon on the top right corner.</li>
                             <li>Change your “Business Name”, “Business Category”, “Mobile Number” and click on “Update”.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:5,
                 ques:'Can I edit my Business address?',
                 ans:'Business addresses once saved cannot be edited. However, in case of relocation of business/shop Professionals can reach out to Slash support for such requests.'
             },
             {
                 id:6,
                 ques:'How can I update my mobile number?',
                 ans:(
                     <div>
                         You can follow below mentioned steps to update your mobile number -
                         <ol type="a">
                             <li>Visit your profile and click on the edit icon on the top right corner.</li>
                             <li>Change your mobile Number and click on “Update”.</li>
                             <li>Verify your mobile number through the verification code, send the number and click on “update.”</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:7,
                 ques:'How do I update my bank account details?',
                 ans:(
                     <div>
                         In order to process payments, bank account details need to be updated on application. You can follow below steps to update your bank details on app -
                         <ol type="a">
                             <li>Visit your profile page and click on “Update Bank details”.</li>
                             <li>Enter “Account Holder Name”, “Account Number” and “Routing Number”.</li>
                             <li>Upon clicking on “Update”, bank details will be updated successfully and payments will be routed to that account.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:8,
                 ques:'What languages can be set for application?',
                 ans:'You can select either “English” or “Spanish” as language for your Slash application.'
             },
             {
                 id:9,
                 ques:'How can I change my preferred language?',
                 ans:(
                     <div>
                         You can follow below mentioned steps to change the language -
                         <ol type="a">
                             <li>Visit your profile.</li>
                             <li>Click on the language icon at the top right corner.</li>
                             <li>Select the language of your choice and click “Proceed”.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:10,
                 ques:' How can I change my email address?',
                 ans:(
                     <div>
                         You can follow below mentioned steps to change your email address -
                         <ol type="a">
                             <li>Visit your profile and click on “Edit icon” on the top right corner.</li>
                             <li>Now click on the “Change email” tab on business detail page.</li>
                             <li>Enter the new email address and click on “Submit”.</li>
                             <li>Enter the verification code sent to your new email address and click on “Verify”.</li>
                             <li>Your new email address will be updated successfully on application.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:11,
                 ques:'How can I change my password?',
                 ans:(
                     <div>
                         You can follow below mentioned steps to change your account password -
                         <ol type="a">
                             <li>Visit your profile and click on “Edit icon” on the top right corner.</li>
                             <li>Now click on “Change password” tab on the business detail page.</li>
                             <li>Enter your “Current Password” and “New Password” that you want to set for your profile and click on Update.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:12,
                 ques:'I forgot my Password, how can I reset it?',
                 ans:(
                     <div>
                         You can follow below mentioned steps to reset your account password -
                         <ol type="a">
                             <li>On the “Login” page below the Password field, click on “Forgot Password”.</li>
                             <li>Enter the registered email or mobile number and generate verification code.</li>
                             <li>Enter verification code received in your email or mobile number and change the password.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:13,
                 ques:'Why have I been asked to update documents?',
                 ans:'Whenever a Professional crosses earnings of $5000 from Slash, they are asked to update their identification document for government and regulatory verification.'
             },
             {
                 id:14,
                 ques:'How do I update my documents?',
                 ans:(
                     <div>
                         You can follow below mentioned steps to update your documents -
                         <ol type="a">
                             <li>Visit your profile and click on “Update Documents” in the menu.</li>
                             <li>Select documents you want to update from the options given in the drop down.</li>
                             <li>Attach the document and click on “Update”.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:15,
                 ques:'Where can I see the Terms & Conditions related to your app?',
                 ans:'To view T&C, go to your Profile Page and click on Terms & Conditions in the menu.'
             },
             {
                 id:16,
                 ques:'How can I share the app link with my friend?',
                 ans:'Go to your profile and click on the share icon at the top right.'
             }
         ],
     )
     let [faqs_Calender, setFaqs_Calender] = useState(
         [
             {
                 id:1,
                 ques:'How do I download Slash Pro App?',
                 ans:(
                     <div>
                         Here’s how you can download the Slash Pro App on iOS devices with iOS 10 and newer–
                         <ol type='a'>
                             <li>Visit App store on your iPhone.</li>
                             <li>Select Search from the menu, type Slash Pro App in the search bar, then tap Search.</li>
                             <li>Select the Slash Pro icon.</li>
                             <li>Tap “Get” your device may require you to enter your App Store password.</li>
                             <li>When download is complete, “Get” will change to “Install”. Tap “Install” to start the installation.</li>
                             <li>When the installation is complete, “Install” will change to “Open”. Tap on it.</li>
                             <li>In the Slash Pro app, click on “Register” to create a new Professional account or click on “Login” if you already have an account.</li>
                         </ol>
                         Here’s how you can download the Slash Pro App on android devices 8 & above-
                         <ol type='a'>
                             <li>Head to the Google Play store and follow these steps:</li>
                             <li>Tap the Google Play icon.</li>
                             <li>Tap the search bar, type Slash Pro, then tap Search.</li>
                             <li>Select the Slash Pro icon and tap “Install”.</li>
                             <li>After app installation is complete, tap “Open”.</li>
                             <li>In the Slash Pro app, click on “Register” to create a new Professional account or click on “Login” if you already have an account.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:2,
                 ques:'How do I update my Slash App?',
                 ans:'We periodically update our app with new features and notify users to update the application for better user experience.  You can visit the Play Store or App Store to update your Slash Pro App.'
             },
             {
                 id:3,
                 ques:'How can I register myself as an individual or business on Slash Pro App?',
                 ans:(
                     <div>
                         To start with, you need to have the Slash Pro app installed on your mobile phone. After Installation, follow the steps below to register yourself -
                         <ol type="a">
                             <li>Open the app. Before registering, click on your preferred language and tap on “Proceed”.</li>
                             <li>Now, click on “Register” to create a new Professional account.</li>
                             <li>Please enter Business Name or Your Name.</li>
                             <li>Enter Email Address or Mobile Number.</li>
                             <li>Set your Password.</li>
                         </ol>
                         Follow the below steps if you are registering through Mobile number.
                         <ol type="a">
                             <li>If you’re registering with your mobile Number, a pop-up screen appears to validate your Mobile Number. Tap on Send to receive a 4-digit verification code</li>
                             <li>In your Text messages, check for the code and enter it in the app.</li>
                             <li>Tap on Verify</li>
                             <li>You may click on resend verification code if you have not received or the code has expired.</li>
                         </ol>
                         Follow the below steps if you are registering with your Email Address.
                         <ol type="a">
                             <li>Please access your email account inbox and check for a mail from “Verify Slash” (verify@goslash.com)</li>
                             <li>Tap on the mail and look for “Click here to Verify” tab in the mail body.</li>
                             <li>You can verify your account by clicking on “Click here to verify” tab or tap on the URL below the tab.</li>
                         </ol>
                         <p>After the verification is complete, enter your account password to login. Enter your Business Address so that Customers can easily access you. You can also Search for partial or full address and click on “Save Address”.</p>
                     </div>
                 )
             },
             {
                 id:4,
                 ques:'How can I edit my business profile/details?',
                 ans:(
                     <div>
                         You can follow below mentioned steps to edit your business profile/details -
                         <ol type="a">
                             <li>Visit your profile and click on the edit icon on the top right corner.</li>
                             <li>Change your “Business Name”, “Business Category”, “Mobile Number” and click on “Update”.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:5,
                 ques:'Can I edit my Business address?',
                 ans:'Business addresses once saved cannot be edited. However, in case of relocation of business/shop Professionals can reach out to Slash support for such requests.'
             },
             {
                 id:6,
                 ques:'How can I update my mobile number?',
                 ans:(
                     <div>
                         You can follow below mentioned steps to update your mobile number -
                         <ol type="a">
                             <li>Visit your profile and click on the edit icon on the top right corner.</li>
                             <li>Change your mobile Number and click on “Update”.</li>
                             <li>Verify your mobile number through the verification code, send the number and click on “update.”</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:7,
                 ques:'How do I update my bank account details?',
                 ans:(
                     <div>
                         In order to process payments, bank account details need to be updated on application. You can follow below steps to update your bank details on app -
                         <ol type="a">
                             <li>Visit your profile page and click on “Update Bank details”.</li>
                             <li>Enter “Account Holder Name”, “Account Number” and “Routing Number”.</li>
                             <li>Upon clicking on “Update”, bank details will be updated successfully and payments will be routed to that account.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:8,
                 ques:'What languages can be set for application?',
                 ans:'You can select either “English” or “Spanish” as language for your Slash application.'
             },
             {
                 id:9,
                 ques:'How can I change my preferred language?',
                 ans:(
                     <div>
                         You can follow below mentioned steps to change the language -
                         <ol type="a">
                             <li>Visit your profile.</li>
                             <li>Click on the language icon at the top right corner.</li>
                             <li>Select the language of your choice and click “Proceed”.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:10,
                 ques:' How can I change my email address?',
                 ans:(
                     <div>
                         You can follow below mentioned steps to change your email address -
                         <ol type="a">
                             <li>Visit your profile and click on “Edit icon” on the top right corner.</li>
                             <li>Now click on the “Change email” tab on business detail page.</li>
                             <li>Enter the new email address and click on “Submit”.</li>
                             <li>Enter the verification code sent to your new email address and click on “Verify”.</li>
                             <li>Your new email address will be updated successfully on application.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:11,
                 ques:'How can I change my password?',
                 ans:(
                     <div>
                         You can follow below mentioned steps to change your account password -
                         <ol type="a">
                             <li>Visit your profile and click on “Edit icon” on the top right corner.</li>
                             <li>Now click on “Change password” tab on the business detail page.</li>
                             <li>Enter your “Current Password” and “New Password” that you want to set for your profile and click on Update.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:12,
                 ques:'I forgot my Password, how can I reset it?',
                 ans:(
                     <div>
                         You can follow below mentioned steps to reset your account password -
                         <ol type="a">
                             <li>On the “Login” page below the Password field, click on “Forgot Password”.</li>
                             <li>Enter the registered email or mobile number and generate verification code.</li>
                             <li>Enter verification code received in your email or mobile number and change the password.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:13,
                 ques:'Why have I been asked to update documents?',
                 ans:'Whenever a Professional crosses earnings of $5000 from Slash, they are asked to update their identification document for government and regulatory verification.'
             },
             {
                 id:14,
                 ques:'How do I update my documents?',
                 ans:(
                     <div>
                         You can follow below mentioned steps to update your documents -
                         <ol type="a">
                             <li>Visit your profile and click on “Update Documents” in the menu.</li>
                             <li>Select documents you want to update from the options given in the drop down.</li>
                             <li>Attach the document and click on “Update”.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:15,
                 ques:'Where can I see the Terms & Conditions related to your app?',
                 ans:'To view T&C, go to your Profile Page and click on Terms & Conditions in the menu.'
             },
             {
                 id:16,
                 ques:'How can I share the app link with my friend?',
                 ans:'Go to your profile and click on the share icon at the top right.'
             }
         ],
     )
 
     let [faqs_Coupons, setFaqs_Coupons] = useState(
 
         [
             {
                 id:1,
                 ques:'What is a Coupon?',
                 ans:(
                     <div>
                         A coupon is an entity that is created by a Professional and it entitles the Customer to avail certain benefits/discounts off a particular service booking.
                         A Professional can create Coupons for Customers in the form of a Voucher code.
                     </div>
                 )
             },
             {
                 id:2,
                 ques:'How can I create a coupon?',
                 ans:(
                     <div>
                         You can create a coupon by following the below mentioned steps -
                         <ol type="a">
                             <li>Go to the “Service Listings Page” and Click on “Coupon Icon”. You will be redirected to the “Coupons page”.</li>
                             <li>Now click on the ‘+’ icon.</li>
                             <li>Enter coupon code and description.</li>
                             <li>Select the service for which you want to create a coupon in “Business Category”. You can create coupons either between specific booking dates or service dates.</li>
                             <li>For creating coupons which are applicable between specific service booking dates.</li>
                             <li>Click on the check box “Allow coupon between booking dates” and set the dates for coupon validity. Now enter “Coupon Minimum Amount (optional), Enter “Discount Amount“,  Enter the number of times the coupon can be used in “Max # of Coupons”. Click on create coupon.</li>
                             <li>Or else enter “Discount Percentage” and enter “Coupon maximum discount amount”. Enter the number of times the coupon can be used in “Max # of Coupons”.  Click on create coupon.</li>
                             <li>The newly created coupons will be visible under the “Current coupons” tab in Coupons listing page. Toggle button can be used to discontinue applicability any coupon.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:3,
                 ques:'Can I create a copy of the coupon?',
                 ans:(
                     <div>
                         To create a copy of coupon you can follow below mentioned steps –
                         <ol type="a">
                             <li>Click on the “three dots icon” on the top right of any existing coupon.</li>
                             <li>Select duplicate and enter a new coupon code. Rest of the details are copied from the existing coupon.</li>
                             <li>You can edit these as per your requirement and click on the “Create Coupon” tab.</li>
                             <li>Newly added coupons will be visible under the “Current coupons” tab in Coupons listing page.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:4,
                 ques:'How can I delete a coupon?',
                 ans:'To delete a coupon click on the “three dots icon” on the top right of any existing coupon. Select “delete” and give confirmation on the pop-up for deleting the coupon.'
             },
             {
                 id:5,
                 ques:'How can I see my past/expired coupons?',
                 ans:'Coupons that have expired can be seen under the “Past Coupons” tab of Coupons listing page.'
             },
             {
                 id:6,
                 ques:'How shall I refer my friend?',
                 ans:(
                     <div>
                         You can follow below mentioned steps for referring your friend -
                         <ol type="a">
                             <li>Go to the profile page and click on refer. A pop up with your referral code will appear.</li>
                             <li>Copy your referral code.</li>
                             <li>Click on “Refer Customers” to share referral code to your Customers.</li>
                             <li>Click on refer a friend link to share referral code with your Professional friends.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:7,
                 ques:'Where can my friend or Customers enter the referral code?',
                 ans:(
                     <div>
                         To register using referral code below mentioned steps can be followed -
                         <ol type="a">
                             <li>On the Login page, click on Register tab at the bottom. On the registration Page, click on “Have a Referral code”. If your referral is a Professional he/she can find a link with “Have a referral code?”.</li>
                             <li>Enter Name, Email Address and Password, Mobile No (optional). Enter the referral code shared and click on Register.</li>
                         </ol>
                         Customers can also follow the above mentioned steps to register themselves using referral code.
                     </div>
                 )
             },
             {
                 id:8,
                 ques:' Will my friend receive any scratch card?',
                 ans:(
                     <div>
                         Scenarios for receiving scratch cards:
                         <ol type="a">
                             <li>Customer to Customer: Customer A refers to Customer B. Customer B will get an unlocked scratch card and Customer A will get a locked scratch card. As soon as Customer B books a service, Customer A’s scratch card will be unlocked.</li>
                             <li>Customer to Professional: Customer A refers to Professional B. Customer A will get the locked scratch card. As soon as Professional B verifies his/her account, Customer A’s scratch card will be unlocked.</li>
                             <li>Professional to Professional: Professional A refers to Professional B. No scratch card earned.</li>
                             <li>Professional to Customer: Professional A refers Customer B. Only Customer B will get the unlocked scratch card</li>
                         </ol>
                         <p><text style={{fontWeight:'bold'}}>Please note</text>: Professionals do not receive any scratch card for referring someone or getting referred by someone (customers/professionals). Only customers will receive the scratch card.</p>
                     </div>
                 )
             },
             {
                 id:9,
                 ques:'How many friends can I refer?',
                 ans:'There is no limitation on the number of referrals.'
             }
         ],
     )
 
     let [faqs_Payments, setFaqs_Payments] = useState(
 
         [
             {
                 id:1,
                 ques:'How would I get paid?',
                 ans:(
                     <div>
                         Once the service is delivered/completed, please validate PIN or Scan the QR Code available with the Customer. You are paid after the service is completed. Your payment for every service completed would be calculated as “Service Price - Platform fee” (Currently platform fee is 0%).
                         <p><text style={{fontWeight:'bold'}}>Note</text>: In case of cancellations, Cancellation Fee is paid after deducting the Platform fee (Currently platform fee is 0%).</p>
                     </div>
                 )
             },
             {
                 id:2,
                 ques:'Where can I view my payments?',
                 ans:'Payments can be viewed in the Payments page under “Transactions” and “Disputes” tab.'
             },
             {
                 id:3,
                 ques:'I did not get payment for the service I delivered?',
                 ans:'For any disputes in payment, check the “Dispute Tab” under “Payments” page.  Reach out to Slash support for further process.'
             },
             {
                 id:4,
                 ques:'What is the Platform fee?',
                 ans:'Currently Platform fee is 0%.'
             },
             {
                 id:5,
                 ques:'Booking was not validated as the Customer did not come for service? How will I get my payment?',
                 ans:'If the Customer didn’t show up and there is no dispute filed by the Customer for next 2 weeks from the day of appointment, Slash will process the payment according to the upcoming payment schedule.'
             }
         ]
     )
 
     let [ faqs_Services, setFaqs_Services] = useState(
        [
             {
                 id:1,
                 ques:'Which all services can I publish? ',
                 ans:(
                     <div>
                         Currently you can publish services in the Categories & Sub- categories as stated below -
                         <table style={{border:'1px solid black',margin:20}}>
                             <thead>
                                 <tr>
                                     <th style={{width:'20%',border:'1px solid black',fontWeight:'bold',padding:10}}>Category</th>
                                     <th style={{width:'80%',border:'1px solid black',fontWeight:'bold',padding:10}}>Subcategory</th>
                                 </tr>
                             </thead>
                             <tbody>
                                 <tr>
                                     <td style={{border:'1px solid black',padding:10}}>Hair Care</td>
                                     <td style={{border:'1px solid black',padding:10}}>Hair Cut & style, Hair Color, Hair Treatment</td>
                                 </tr>
                                 <tr>
                                     <td style={{border:'1px solid black',padding:10}}>Body Care</td>
                                     <td style={{border:'1px solid black',padding:10}}>Massage, Body Baths, Body wraps, Body Scrubs, Body Treatment</td>
                                 </tr>
                                 <tr>
                                     <td style={{border:'1px solid black',padding:10}}>Nail Care</td>
                                     <td style={{border:'1px solid black',padding:10}}>Hand/Feet Care, Manicure, Pedicure, Nail Styling</td>
                                 </tr>
                                 <tr>
                                     <td style={{border:'1px solid black',padding:10}}>Health & Fitness</td>
                                     <td style={{border:'1px solid black',padding:10}}>Yoga, Physical Trainer, Chiropractic, Physical Therapy, Nutritionist, Accu Treatment & More</td>
                                 </tr>
                                 <tr>
                                     <td style={{border:'1px solid black',padding:10}}>Beauty</td>
                                     <td style={{border:'1px solid black',padding:10}}>Facial, Hair Removal, Makeup, Permanent Makeup</td>
                                 </tr>
                                 <tr>
                                     <td style={{border:'1px solid black',padding:10}}>Home Care</td>
                                     <td style={{border:'1px solid black',padding:10}}>Electrical Service, Plumbing, House Cleaning, Lawn Care & Landscaping, Appliance Repair, Babysitting, Cooking, Dishwashing, Maid, Laundry & Folding</td>
                                 </tr>
                                 <tr>
                                     <td style={{border:'1px solid black',padding:10}}>Tutoring</td>
                                     <td style={{border:'1px solid black',padding:10}}>Academics, Language, Music</td>
                                 </tr>
                                 <tr>
                                     <td style={{border:'1px solid black',padding:10}}>Pet Care</td>
                                     <td style={{border:'1px solid black',padding:10}}>Pet Day-care & Boarding, Pet Grooming, Pet Sitting, Dog Training</td>
                                 </tr>
                                 <tr>
                                     <td style={{border:'1px solid black',padding:10}}>Professional Services</td>
                                     <td style={{border:'1px solid black',padding:10}}>Accounting, Financial Planning, Legal, Life & Career, Photography, Architects</td>
                                 </tr>
                                 <tr>
                                     <td style={{border:'1px solid black',padding:10}}>Auto Care</td>
                                     <td style={{border:'1px solid black',padding:10}}>Tire Services & Repair, Battery Service & Repair, Car Service & Repair, Suspension & Front-End Services, Car Wash, Detailing</td>
                                 </tr>
                                 <tr>
                                     <td style={{border:'1px solid black',padding:10}}>Garment Care</td>
                                     <td style={{border:'1px solid black',padding:10}}>Alterations, Dry Cleaning, Laundry, Tailoring</td>
                                 </tr>
                                 <tr>
                                     <td style={{border:'1px solid black',padding:10}}>Astro & More</td>
                                     <td style={{border:'1px solid black',padding:10}}>Astrology, Vaastu, Feng Shui</td>
                                 </tr>
                             </tbody>
                         </table>
                     </div>
                 )
             },
             {
                 id:2,
                 ques:'How do I publish a Service?',
                 ans:(
                     <div>
                         Follow the below steps to publish your service:
                         <ol type="a">
                             <li>On “Service Listings Page”, Click on ‘+’ icon at the bottom to create service, Select the category and sub category of services you want to publish.</li>
                             <li>A prefilled template of service published can be seen on the screen. Click on any of the templates.</li>
                             <li>Edit the “Service Name”, Description, Service Duration & “Price”. You can select the image to be displayed from the given set of images.</li>
                             <li>Click on “cancellation allowed” checkbox if you want to provide flexibility for cancelling appointment to Customers.</li>
                             <li>Enter the number of days till the cancellation is allowed from date of appointment booking.</li>
                             <li>You can also add the cancellation fee. Cancellation fee should be less than the service fee.</li>
                             <li>Now click on “Create Service”.  Your service created will be visible at the top most of the Service Listings Page.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:3,
                 ques:'How many services can I publish per day?',
                 ans:'There is no limit on the number of services created in a day.'
             },
             {
                 id:4,
                 ques:'What are Views and Consumes?',
                 ans:(
                     <div>
                         In the service listing page, on clicking on any service you’ll be able to view service details like Price, Duration, Service ID, Bookings, Cancellations, Views and Consumes.
                         <ol type="a">
                             <li>The “Views” will give you the number of times your service has been viewed by Customers.</li>
                             <li>The “Consumes” will give you the number of service bookings that have been completed/delivered by you.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:5,
                 ques:'Can I edit my service details (E.g. price, time, image etc.)',
                 ans:(
                     <div>
                         Yes, you can follow below mentioned steps to edit your service details -
                         <ol type="a">
                             <li>Click on the Service you want to edit.</li>
                             <li>Click on the “Edit Icon” at the top of right of “Service detail” page.</li>
                             <li>Edit the service and click on “Update”.</li>
                             <li>You can see the updated service at the topmost of the “Service Listing Page”.</li>
                             <li>To make any service inactive/active you can use the toggle button in the Service listing.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:6,
                 ques:'How can I delete a service?',
                 ans:'You can delete service if there are no active bookings for that service. Use the “delete icon” on the top right of “service detail” page to delete a service.\n Deleting a service has no impact to active or past bookings. Just like changing a service details has no impact or previously made bookings.'
             },
             {
                 id:7,
                 ques:'Can I create a copy of a service?',
                 ans:(
                     <div>
                         You can follow below mentioned steps to copy a service -
                         <ol type="a">
                             <li>You can use the copy icon on the top right of “service detail” page to copy a service along with the details.</li>
                             <li>Click on the “copy icon” and edit the details or just click on the “Create Service”.</li>
                             <li>Newly created service will be at the topmost of the “Service Listing Page”.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:8,
                 ques:'Can I upload my own images for service listing?',
                 ans:'Images are predefined in the application for any service listing. In future, we will enable you to upload your own images.'
             },
             {
                 id:9,
                 ques:'How can I check the Service updates (bookings, cancellation etc.)',
                 ans:'Click on any service published by you. You will be redirected to the Service Detail Page where the information regarding bookings, cancellations, views and Customers can be viewed.'
             },
             {
                 id:10,
                 ques:'How will I receive booking updates?',
                 ans:'Once a Customer books your service. You will receive an email or text and push notification with Booking ID and Booking Details. You can also view the bookings under “Upcoming”, “Past” and “Cancelled” tab in the “Bookings” Page.'
             },
             {
                 id:11,
                 ques:'How do I confirm my service delivery in App for booking?',
                 ans:(
                     <div>
                         <ol type="a">
                             <li>Service delivery can be confirmed in two ways as stated below:</li>
                             <li>You can validate the PIN sent to the Customer, at the time of service delivery.</li>
                             <li>Alternatively, you can scan the QR Code sent to Customer at the time of service.</li>
                             <li>You can find the QR Code scanner in the Service Listings Page and “upcoming service” detail page.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:12,
                 ques:'After the service delivery, I forgot to validate the PIN in the App? How do I deal with it?',
                 ans:(
                     <div>
                         If you forget to validate the PIN, you can follow below mentioned steps -
                         <ol type="a">
                             <li>If the Customer and the PIN are still accessible to you within the same business day, you can validate the PIN from your Professional App.</li>
                             <li>If one business day has passed since the completion of service from your side, the PIN no longer holds valid. You will need to contact the Slash support to rectify the issue.</li>
                         </ol>
                     </div>
                 )
             }
         ]
     )
     let [  faqs_Chat, setFaqs_Chat] = useState(
         [
             {
                 id:1,
                 ques:'How can I connect with Customers?',
                 ans:'Once your service has been booked by Customers you can connect with them via the chat feature available on the app.'
             },
             {
                 id:2,
                 ques:'Can I chat with Customers if my service has been Cancelled/Consumed?',
                 ans:'The Chat feature will let you talk to Customers who are yet to receive the service delivery. Once the service is cancelled or consumed you cannot connect with them.'
             },
             {
                 id:3,
                 ques:' Where can I find the chat option?',
                 ans:'To chat with your Customer you can go to the “Bookings” page. Under the “Upcoming” tab, click on any service appointment and click on the chat icon at the bottom.'
             },
             {
                 id:4,
                 ques:'How can I view/respond to messages received from Customers?',
                 ans:(
                     <div>
                         You can follow the below mentioned process for viewing/responding to the chats of your Customers -
                         <ol type="a">
                             <li>You will be notified once you receive a message from any Customer. Press on the “bell icon” in Service Listings Page to check/respond to any message that you have received from the Customer.</li>
                             <li>Or else click on any service appointment in the booking page under the upcoming tab, you will find the chat icon at the bottom of the page with new notifications count.</li>
                             <li>Tap on the icon to view new messages or to chat with the Customer.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:5,
                 ques:'Where can I learn more about Slash app?',
                 ans:(
                     <div>
                         To know more about Slash app you can click on “A Guide to slash” on login page or you can follow below mentioned steps-
                         <ol type="a">
                             <li>Go to your Profile Page.</li>
                             <li>Click on “A Guide to Slash” to know more about Slash and view demo videos on know-how of various features.</li>
                         </ol>
                     </div>
                 )
             },
             {
                 id:6,
                 ques:'How can I reach Slash support?',
                 ans:'You can email us at msupport@goslash.com or call us on +1 650-739-8103.'
             }
         ],
      )
      let [ serviceArray, setServiceArray] = useState(
         [
             {cats:'Hair Care',subs:'Hair Extension, Hair Styling, Hair Textures, Hair Treatment, Hair Trim, Men’s Hair Coloring, Men’s Hair Wash, Women’s Hair Coloring, Women’s Hair Wash, Kid’s Cut, Men’s Haircut, Specialty Haircut, Women’s Haircut'},
             {cats:'Skin Care',subs:'Acne Facial, Acupuncture Facial, Ageless Facial, Anti-Ageing Facial, Aroma therapy, Botox Injections, Brightening Facial, Chemical Peels, Classic Facial, Dermaplaning, Enzyme Facial, Eye Makeup, Galvanic Facial, High-Frequency, Hydrafacial, Hydrating Facial, Laser Hair Removal, Laser Rejuvenation, Laser resurfacing, LED light therapy, Lymphatic massage, Microcurrent, Microdermabrasion, Microneedling, Oxygen facial, PRP facial, Signature facial, Tanning, Tattoo, Waxing'},
             {cats:'Manicure',subs:'Acrylic, American, Basic, Brazilian, Dip Powder, French, Gel, Hot Stone, Mirror, Paraffin, Plexigel, Reverse French, Shellac, Vinylux'},
             {cats:'Pedicure',subs:'Chocolate, Fish, French, Gel, Ice cream, Margherita, Milk and honey, Mini, Paraffin, Rose, Salt, Spa, Shanghai, Sports, Standard, Stone, Waterless, Wine'},
             {cats:'Massage',subs:'Amatsu, Ashiatsu, Burmese, Couples, Cupping, Foot, Lomilomi, Myofascial, Reflexology, Shiatsu, Swedish, Trigger point, Aroma Therapy, Ayurvedic, Chair, Craniosacral, Deep tissue, Hot stone, Lymphatic Drainage, Pregnancy, Reiki, Sports, Thai'},
             {cats:'Body Care',subs:'Accu Treatment, Baths, Body mask,  Body scrub,  Body wrap, Chiropractic, Dry brushing, Exfoliation, Floating, Floatation Beds, Hydropathy, Microcurrent, Moisturizing Treatments, Physical therapy, Physical Trainer, Thalassotherapy, Yoga'},
             {cats:'Home Care',subs:'Appliance Repair, Baby Sitting, Cooking, Dish Washing, Electrical, Gardening, Handyman, House Cleaning, Landscaping, Laundry, Maid, Plumbing, Snow Plowing, Tree Cutting, Yard Work'},
             {cats:'Tutoring',subs:'Language, Literature, Math, Music, Science'},
             {cats:'Pet Care',subs:'Dog walking, Pet Grooming, Pet sitting, Pet training'},
             {cats:'Professional',subs:'Accounting, Astrology, Feng Shui, Financial planning, Legal, Life and career, Numerology, Nutritionist, Photography, Psychic reading, Tax filing, Vastu'},
             {cats:'Auto Care',subs:'Auto Body, Car Wash, Limo, Maintenance and Repair'},
             {cats:'Garment Care',subs:'Alteration, Dry Cleaning, Laundry, Tailoring'}
         ],
      )
     let [collapse_Consumer1, setCollapse_Consumer1] = useState({})
     const [prevId, setPrevId] = useState()
     let [show, setShow] = useState(5)
     let [docs, setDocs] = useState('Profile & Registration')
     const [activeItem, setActiveItem] = useState('Profile & Registration');
     const navItem = ['Profile & Registration', 'Services', 'Business hours', 'Coupons & Referrals', "Chat & Support","Payments"]
 

    const faqCollapseHandlerC1 = (id) => {
        let collapse_Consumer = {};
        console.log('id', id)
        if (collapse_Consumer1['collapse_' + id]) {
            collapse_Consumer = {
                ...collapse_Consumer1,
                ['collapse_' + id]: false
            }
            setCollapse_Consumer1(collapse_Consumer)
        } else {
            collapse_Consumer = {
                ...collapse_Consumer1,
                // ['collapse_' + prevId]: false,
                ['collapse_' + id]: true,
            }
            setCollapse_Consumer1(collapse_Consumer)
        }
    }

    function handleItemClick(item) {
        setActiveItem(item);
    }
    const profileHandler = () => {
        let collapse_Consumer = {};

        collapse_Consumer = {
            ...collapse_Consumer1,
            ['collapse_' + prevId]: false
        }
        setShow(5)
        setCollapse_Consumer1(collapse_Consumer)
        setDocs('Profile')
    }

    const bookingsHandler = () => {
        let collapse_Consumer = {};

        collapse_Consumer = {
            ...collapse_Consumer1,
            ['collapse_' + prevId]: false
        }
        setShow(5)
        setCollapse_Consumer1(collapse_Consumer)
        setDocs('Booking')
    }
    const paymentHandler = () => {
        let collapse_Consumer = {};

        collapse_Consumer = {
            ...collapse_Consumer1,
            ['collapse_' + prevId]: false
        }
        setShow(5)
        setCollapse_Consumer1(collapse_Consumer)
        setDocs('Payments')
    }
    const couponsHandler = () => {
        let collapse_Consumer = {};

        collapse_Consumer = {
            ...collapse_Consumer1,
            ['collapse_' + prevId]: false
        }
        setShow(5)
        setCollapse_Consumer1(collapse_Consumer)
        setDocs('Coupon')
    }
    const chatHandler = () => {
        let collapse_Consumer = {};

        collapse_Consumer = {
            ...collapse_Consumer1,
            ['collapse_' + prevId]: false
        }
        setShow(5)
        setCollapse_Consumer1(collapse_Consumer)
        setDocs('Support')
    }


    const showMore = () => {

        // this.setState({show: this.state.show+5})
        setShow(show = show + 5)
    }

    const showLess = () => {
        // this.setState({show: this.state.show-5})
        setShow(show = show - 5)
    }


    let FAQ_Profile;


    if (faqs_profileRegistration.length !== 0) {

        FAQ_Profile = faqs_profileRegistration.slice(0, show).map((ques, i) => {
            return (
                <div style={{ transition: 'opacity 0.3s ease', display: 'block' }}>
                    <div className='question' style={{ transition: 'opacity 0.3s ease', display: 'flex', flexDirection: 'row' }}>

                        <div style={{ cursor: 'pointer', width: '95%', paddingBottom: 10 }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                            <CardText className='questionText'> {ques.ques} </CardText>
                        </div>
                        <div style={{ cursor: 'pointer', width: '5%' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                            {collapse_Consumer1['collapse_' + ques.id] ?
                                <img src={upArrow} width={15} />
                                :
                                <img src={downArrow} width={15} />
                            }
                        </div>
                    </div>
                    {/* :
                                <Col sm={{size:'auto'}} style={{cursor:'pointer'}} onClick={() => this.faqCollapseHandlerC1(ques.id)}>                            
                                </Col> */}
                    {/* </Row>  */}
                    {/* <Row> */}
                    <div className='ansdiv'>
                        <Collapse isOpen={collapse_Consumer1['collapse_' + ques.id]} style={{ width: '95%',padding:20 }}>
                            <CardText style={{ fontFamily: "Mulish", fontSize: 14, fontWeight: "normal", fontStyle: "normal", lineHeight: 'normal', letterSpacing: 0, color: "#7e757e" }} >{ques.ans}</CardText>
                        </Collapse>
                    </div>
                    {/* </Row>                      */}
                    {show == i + 1 || faqs_profileRegistration.length == i + 1 ? null : <div style={{ marginTop: 10 }}></div>}

                </div>

            )
        })
    }


    let FAQ_Booking;


    if (faqs_Calender.length !== 0) {

        FAQ_Booking = faqs_Calender.slice(0, show).map((ques, i) => {
            return (
                <div style={{ transition: 'opacity 0.3s ease', display: 'block' }}>
                    <div className='question' style={{ transition: 'opacity 0.3s ease', display: 'flex', flexDirection: 'row' }}>

                        <div style={{ cursor: 'pointer', width: '95%' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                            <CardText className='questionText'> {ques.ques} </CardText>
                        </div>
                        <div style={{ cursor: 'pointer', width: '5%' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                            {collapse_Consumer1['collapse_' + ques.id] ?
                                <img src={upArrow} width={15} />
                                :
                                <img src={downArrow} width={15} />
                            }
                        </div>
                    </div>
                    {/* :
                                    <Col sm={{size:'auto'}} style={{cursor:'pointer'}} onClick={() => this.faqCollapseHandlerC1(ques.id)}>                            
                                    </Col> */}
                    {/* </Row>  */}
                    {/* <Row> */}
                    <div className='ansdiv'>
                        <Collapse isOpen={collapse_Consumer1['collapse_' + ques.id]} style={{ width: '95%',padding:10 }}>
                            <CardText style={{ fontFamily: "Mulish", fontSize: 14, fontWeight: "normal", fontStyle: "normal", lineHeight: 'normal', letterSpacing: 0, color: "#7e757e",padding:20 }} >{ques.ans}</CardText>
                        </Collapse>
                    </div>
                    {/* </Row>                      */}
                    {show == i + 1 || faqs_Calender.length == i + 1 ? null : <div style={{ marginTop: 10 }}></div>}

                </div>

            )
        })
    }


    let FAQ_Chat;
    if (faqs_Chat.length !== 0) {

        FAQ_Chat = faqs_Chat.slice(0, show).map((ques, i) => {
            return (
                <div style={{ transition: 'opacity 0.3s ease', display: 'block' }}>
                    <div className='question' style={{ transition: 'opacity 0.3s ease', display: 'flex', flexDirection: 'row' }}>

                        <div style={{ cursor: 'pointer', width: '95%' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                            <CardText className='questionText'> {ques.ques} </CardText>
                        </div>
                        <div style={{ cursor: 'pointer', width: '5%' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                            {collapse_Consumer1['collapse_' + ques.id] ?
                                <img src={upArrow} width={15} />
                                :
                                <img src={downArrow} width={15} />
                            }
                        </div>
                    </div>
                    {/* :
                                <Col sm={{size:'auto'}} style={{cursor:'pointer'}} onClick={() => this.faqCollapseHandlerC1(ques.id)}>                            
                                </Col> */}
                    {/* </Row>  */}
                    {/* <Row> */}
                    <div className='ansdiv'>
                        <Collapse isOpen={collapse_Consumer1['collapse_' + ques.id]} style={{ width: '95%' ,padding:10}}>
                            <CardText style={{ fontFamily: "Mulish", fontSize: 14, fontWeight: "normal", fontStyle: "normal", lineHeight: 'normal', letterSpacing: 0, color: "#7e757e" ,padding:20}} >{ques.ans}</CardText>
                        </Collapse>
                    </div>
                    {/* </Row>                      */}
                    {show == i + 1 || faqs_Chat.length == i + 1 ? null : <div style={{ marginTop: 10 }}></div>}

                </div>

            )
        })
    }

    let FAQ_Payment;


    if (faqs_Payments.length !== 0) {

        FAQ_Payment = faqs_Payments.slice(0, show).map((ques, i) => {
            return (
                <div style={{ transition: 'opacity 0.3s ease', display: 'block' }}>
                    <div className='question' style={{ transition: 'opacity 0.3s ease', display: 'flex', flexDirection: 'row' }}>

                        <div style={{ cursor: 'pointer', width: '95%' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                            <CardText className='questionText'> {ques.ques} </CardText>
                        </div>
                        <div style={{ cursor: 'pointer', width: '5%' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                            {collapse_Consumer1['collapse_' + ques.id] ?
                                <img src={upArrow} width={15} />
                                :
                                <img src={downArrow} width={15} />
                            }
                        </div>
                    </div>
                    {/* :
                                <Col sm={{size:'auto'}} style={{cursor:'pointer'}} onClick={() => this.faqCollapseHandlerC1(ques.id)}>                            
                                </Col> */}
                    {/* </Row>  */}
                    {/* <Row> */}
                    <div className='ansdiv'>
                        <Collapse isOpen={collapse_Consumer1['collapse_' + ques.id]} style={{ width: '95%',padding:10 }}>
                            <CardText style={{ fontFamily: "Mulish", fontSize: 14, fontWeight: "normal", fontStyle: "normal", lineHeight: 'normal', letterSpacing: 0, color: "#7e757e",padding:20 }} >{ques.ans}</CardText>
                        </Collapse>
                    </div>
                    {/* </Row>                      */}
                    {show == i + 1 || faqs_Payments.length == i + 1 ? null : <div style={{ marginTop: 10 }}></div>}

                </div>

            )
        })
    }


    let FAQ_Services


    if (faqs_Services.length !== 0) {

        FAQ_Services= faqs_Services.slice(0, show).map((ques,i) => {
            return (
                <div style={{ transition: 'opacity 0.3s ease', display: 'block' }}>
                    <div className='question' style={{ transition: 'opacity 0.3s ease', display: 'flex', flexDirection: 'row' }}>

                        <div style={{ cursor: 'pointer', width: '95%' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                            <CardText className='questionText'> {ques.ques} </CardText>
                        </div>
                        <div style={{ cursor: 'pointer', width: '5%' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                            {collapse_Consumer1['collapse_' + ques.id] ?
                                <img src={upArrow} width={15} />
                                :
                                <img src={downArrow} width={15} />
                            }
                        </div>
                    </div>
                    {/* :
                                    <Col sm={{size:'auto'}} style={{cursor:'pointer'}} onClick={() => this.faqCollapseHandlerC1(ques.id)}>                            
                                    </Col> */}
                    {/* </Row>  */}
                    {/* <Row> */}
                    <div className='ansdiv'>
                        <Collapse isOpen={collapse_Consumer1['collapse_' + ques.id]} style={{ width: '95%',padding:10 }}>
                            <CardText style={{ fontFamily: "Mulish", fontSize: 14, fontWeight: "normal", fontStyle: "normal", lineHeight: 'normal', letterSpacing: 0, color: "#7e757e",padding:20 }} >{ques.ans}</CardText>
                        </Collapse>
                    </div>
                    {/* </Row>                      */}
                    {show == i + 1 || faqs_Services.length == i + 1 ? null : <div style={{ marginTop: 10 }}></div>}

                </div>

            )
        })
    }


    let FAQ_Coupons;


    if (faqs_Coupons.length !== 0) {

        FAQ_Coupons = faqs_Coupons.slice(0, show).map((ques, i) => {
            return (
                <div style={{ transition: 'opacity 0.3s ease', display: 'block' }}>
                    <div className='question' style={{ transition: 'opacity 0.3s ease', display: 'flex', flexDirection: 'row' }}>

                        <div style={{ cursor: 'pointer', width: '95%' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                            <CardText className='questionText'> {ques.ques} </CardText>
                        </div>
                        <div style={{ cursor: 'pointer', width: '5%' }} onClick={() => faqCollapseHandlerC1(ques.id)}>
                            {collapse_Consumer1['collapse_' + ques.id] ?
                                <img src={upArrow} width={15} />
                                :
                                <img src={downArrow} width={15} />
                            }
                        </div>
                    </div>
                    {/* :
                                        <Col sm={{size:'auto'}} style={{cursor:'pointer'}} onClick={() => this.faqCollapseHandlerC1(ques.id)}>                            
                                        </Col> */}
                    {/* </Row>  */}
                    {/* <Row> */}
                    <div className='ansdiv'>
                        <Collapse isOpen={collapse_Consumer1['collapse_' + ques.id]} style={{ width: '95%',padding:10 }}>
                            <CardText style={{ fontFamily: "Mulish", fontSize: 14, fontWeight: "normal", fontStyle: "normal", lineHeight: 'normal', letterSpacing: 0, color: "#7e757e" ,padding:20}} >{ques.ans}</CardText>
                        </Collapse>
                    </div>
                    {/* </Row>                      */}
                    {show == i + 1 || faqs_Coupons.length == i + 1 ? null : <div style={{ marginTop: 10 }}></div>}

                </div>

            )
        })
    }
    let activeStyleForSelection = {
        color: '#8c52ff',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        borderBottom: '1.5px solid #8c52ff',
        paddingBottom: 10,
    }


    let sectionsHeader;
    if (navItem.length !== 0) {
        sectionsHeader = (
            // <div style={{position:'absolute',left:'auto',}}>
            <div className='faqScrool'>
                {
                    navItem.map((item, index) => {
                        return (
                            <div style={{ marginTop: 15, marginBottom: 15, marginLeft: 20, marginRight: 20 }}>
                                <text className={item === activeItem ? "mybooking_navbarItem_active_style1" : "mybooking_navbarItem_style1"} onClick={() => handleItemClick(item)} >{item}</text>
                            </div>
                        )

                    })
                }
            </div>
            // </div>
        )
    }

    return (
        <div style={{ paddingBottom:20 }}>

            {/* <Nav tabs className="mybooking_navbar_style1" style={{overflowX:'scroll',flexWrap:'nowrap' }}>
                {navItem.map((item) => (
                    <NavItem  className={item === activeItem ? "mybooking_navbarItem_active_style1" : "mybooking_navbarItem_style1"} style={{overflowX:'scroll'}}>
                        <NavLink key={item} onClick={() => handleItemClick(item)}  >{item}</NavLink>
                    </NavItem>
                ))}
            </Nav> */}


            {/* <Card style={{height:200,width:200,overflow:'scroll',paddingLeft:28,paddingTop:16,backgroundColor:'white'}}> */}
            <div style={{ overflow: 'hidden', }}>
                {sectionsHeader}
            </div>
            {/* </Card> */}
            <div style={{ padding: 0, display: 'flex', justifyContent: 'center' }}>
            <div style={{ padding: 10, textAlign: 'left', width: '100%' }}>
                    {activeItem == 'Profile & Registration' ? FAQ_Profile : null}
                    {activeItem == 'Business hours' ? FAQ_Booking : null}
                    {activeItem == 'Payments' ? FAQ_Payment : null}
                    {activeItem == 'Coupons & Referrals' ? FAQ_Coupons : null}
                    {activeItem == 'Services' ? FAQ_Services : null}
                    {activeItem == 'Chat & Support' ? FAQ_Chat : null}
                </div>



            </div>
            {show <= 5 ?

<div className='showMore' onClick={() => showMore()}>
    <text style={{ textDecorationLine: 'underline', color: '#461c99' }}>Show More</text>
    {/* <img src={downArrow} width={15} /> */}
</div>

: show >= faqs_profileRegistration.length && docs == 'Profile & Registration' ?
    <div className='showMore' onClick={() => showLess()}>
        <text style={{ textDecorationLine: 'underline', color: '#461c99' }}>Show Less</text>
        {/* <img src={upArrow} width={15} /> */}
    </div>

    : show >= faqs_Services.length && docs == 'Services' ?
        <div className='showMore' onClick={() => showLess()}>
            <text style={{ textDecorationLine: 'underline', color: '#461c99' }}>Show Less</text>
            {/* <img src={upArrow} width={15} /> */}
        </div>

        : show >= faqs_Calender.length && docs == 'Business hours' ?
            <div className='showMore' onClick={() => showLess()}>
                <text style={{ textDecorationLine: 'underline', color: '#461c99' }}>Show Less</text>
                {/* <img src={upArrow} width={15} /> */}
            </div>

            : show >= faqs_Coupons.length && docs == 'Coupons & Referrals' ?
                <div className='showMore' onClick={() => showLess()}>
                    <text style={{ textDecorationLine: 'underline', color: '#461c99' }}>Show Less</text>
                    {/* <img src={upArrow} width={15} /> */}
                </div>

                : show >= faqs_Payments.length && docs == 'Payments' ?
                    <div className='showMore' onClick={() => showLess()}>
                        <text style={{ textDecorationLine: 'underline', color: '#461c99' }}>Show Less</text>
                        {/* <img src={upArrow} width={15} /> */}
                    </div>

                    :
                    show >= faqs_Chat.length && docs == 'Chat & Support' ?
                    <div className='showMore' onClick={() => showLess()}>
                        <text style={{ textDecorationLine: 'underline', color: '#461c99' }}>Show Less</text>
                        {/* <img src={upArrow} width={15} /> */}
                    </div>

                    :
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: 400, marginRight: 'auto', marginLeft: 'auto' }}>
                        {/* <div className='showMore' onClick={() => showMore()}>
                            <text style={{ textDecorationLine: 'underline', color: '#461c99' }}>Show More</text>
                           
                        </div> */}
                        <div className='showMore' onClick={() => showLess()}>
                            <text style={{ textDecorationLine: 'underline', color: '#461c99' }}>Show Less</text>
                           
                        </div>
                    </div>
}
        </div>
    );
}

export default FaqMerchantMobile;
