
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Modal, ModalBody, Input, Button } from "reactstrap";
import '../../../CSS/UI Components/SettingPage.css'
import Cross from '../../../../New_Website_V2/Assets/cross.png'
import Arrow from '../../../../New_Website_V2/Assets/asicon-outline-arrow-right.png'
import { useMediaQuery } from "react-responsive"
import { authLogOut } from '../../../GlobalState/globalStateActions'
import { globalState } from '../../../GlobalState/globalState';
import { ToastContainer, toast } from 'react-toastify';
import customFetchData from '../../Custom/customFetchData';
import { HashLink } from 'react-router-hash-link';
import { useNavigate } from 'react-router-dom';
const SettingPage = () => {
  const [loading, setLoading] = useState(false)
  const [boxView1, setBoxView1] = useState(true)
  const [boxView2, setBoxView2] = useState(false)
  const [boxView3, setBoxView3] = useState(false)
  const [reasonText, setReasonText] = useState('')
  const [selectedReason, setSelectedReason] = useState('')
  const [textLength, setTextLength] = useState(0)
  const [maxTextLength, setMaxTextLength] = useState(150)
  const [delteModal, SetDeleteModal] = useState(false)
  const [settingView, SetsettingView] = useState(false)
  let [state, dispatch] = useContext(globalState)

  const [reasonList, setReasonList] = useState(
    [
      {
        title: 'I don’t want to use Slash anymore'
      },
      {
        title: 'The app is not working properly'
      },
      {
        title: 'The app is confusing'
      },
      {
        title: 'No support from the company'
      },
      {
        title: 'Others'
      },
    ]
  )
  const MobileAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 760px)" })
  const isMobileView = MobileAndBelowMediaQuery();
  const onLogOutHandler = async () => {
    let logOutStatusFromState = await authLogOut(dispatch)
    if (logOutStatusFromState) {
      // ToastAndroid.show("Account Deleted Successfully ",ToastAndroid.LONG);
      // toast.success("Account Deleted Successfully", { position: toast.POSITION.BOTTOM_CENTER });
      toast("Account Deleted Successfully", {
        position:'bottom-center',
        hideProgressBar: true,
        progress: undefined,
        style:{backgroundColor:'#E0D1FF'}
    });


      // navigation.dispatch(
      //     CommonActions.reset({
      //       index: 0,
      //       routes: [{ name: 'BottomTabIndex' }],
      //     }))
      //   ReactMoE.logout();
      //   AppEventsLogger.setUserID(null)
      //   navigation.dispatch(resetAction)
    }
    else {
      // toast.error("Verification code sent", { position: toast.POSITION.BOTTOM_CENTER });
      toast("Verification code sent", {
        position: 'bottom-center',
        hideProgressBar: true,
        progress: undefined,
        style:{backgroundColor:'#E0D1FF'}
    });

    }
  }

  const onPressDelete = async () => {
    try {
      setLoading(true)
      const deleteData = {
        consumer_id: state.user.id,
        deleteReason: selectedReason,
        del_comments: reasonText
      }
      // console.log('bodyDelete', deleteData)
      let deleteResponse = await customFetchData("POST", deleteData, '/website/profile/account/delete/Sv2', state, dispatch)
      // console.log('delete response', deleteResponse)
      if (deleteResponse.fetch) {
        if (deleteResponse.fetchedData.delete) {
          console.log("Logout")
          onLogOutHandler()
        } else {
          if (deleteResponse.fetchedData.upcomingBooking) {
            // ToastAndroid.show("You cannot delete your account now as you have an upcoming booking", ToastAndroid.LONG)
            // toast.info("You cannot delete your account now as you have an upcoming booking", { position: toast.POSITION.BOTTOM_CENTER });
            toast("You cannot delete your account now as you have an upcoming booking", {
              position:'bottom-center',
              hideProgressBar: true,
              progress: undefined,
              style:{backgroundColor:'#E0D1FF'}
          });

          } else if (deleteResponse.fetchedData.disputeFiled) {
            // toast.info("You cannot delete your account now as you have an active dispute pending.", { position: toast.POSITION.BOTTOM_CENTER });
            toast("You cannot delete your account now as you have an active dispute pending.", {
              position:'bottom-center',
              hideProgressBar: true,
              progress: undefined,
              style:{backgroundColor:'#E0D1FF'}
          });

            // ToastAndroid.show("You cannot delete your account now as you have an active dispute pending.", ToastAndroid.LONG)
          } else {
            // toast.error("Something went wrong. Please try again!", { position: toast.POSITION.BOTTOM_CENTER });
            toast("Something went wrong. Please try again!", {
              position:'bottom-center',
              hideProgressBar: true,
              progress: undefined,
              style:{backgroundColor:'#E0D1FF'}
          });
            // ToastAndroid.show("Something went wrong. Please try again!", ToastAndroid.LONG)
          }
        }
        setLoading(false)
      }
    } catch (error) {
      console.log('delete Account Failed ', error);
      setLoading(false)
      // toast.error("Account Deletion Failed. Please try again", { position: toast.POSITION.BOTTOM_CENTER });
      toast("Account Deletion Failed. Please try again", {
        position:'bottom-center',
        hideProgressBar: true,
        progress: undefined,
        style:{backgroundColor:'#E0D1FF'}
    });

      // ToastAndroid.show("Account Deletion Failed. Please try again. ",ToastAndroid.LONG);
    }
  }


  const navigate = useNavigate();
  const onSelectReason = (item) => {
    // console.log('item', item)
    setSelectedReason(item.title)
    setBoxView1(false)
    setBoxView2(true)
    SetDeleteModal(true)
  }
  const onChangeReasonText = (e) => {

    const maxLength = 150;
    setTextLength(e.target.value.length)
    setReasonText(e.target.value)  // same as `text: text`
  }
  const onPressNext = () => {
    console.log('onperess')
    setBoxView2(false)
    setBoxView3(true)
  }
  const modalHanmdler = () => {
    SetDeleteModal(false)
    setBoxView2(false)
    setBoxView3(false)

  }
  const setringHandler = () => {
    if(isMobileView){
      navigate('/c/deleteAccount')
    }else{
      SetsettingView(true)
    }

  }
  //  const termHandler=()=>{
  //   navigator('/termsPolicies')
  //  }
  //displaying option
  let reasonListDisplay = reasonList.map((item, index) => {
    return (
      <div style={{ cursor: 'pointer' }} onClick={() => onSelectReason(item)}>
        <text className="subtextsetting">{item.title}</text>
        <hr />
      </div>
    )
  })

  // console.log('consoled values:-- ', selectedReason)
  return (
    <div style={{paddingTop:isMobileView?100:10,paddingBottom:isMobileView?30:0}}>
      {!settingView ?
        <div style={{ paddingLeft: 10, paddingTop: 10 }}>
          
            
            <HashLink style={{ textDecoration: 'none', }} to="/privacy&policy" state={{ docs: 'privacy', type: 1, active: 1 }}>
            <div style={{ flexDirection: 'column', display: 'flex' }}>
            <text className="privacy">Privacy</text>
              <text className="subtextOption">We respect your privacy</text>   
              </div>
              <hr />
            </HashLink>
          
         
          <HashLink style={{ textDecoration: 'none', marginBottom: 20 }} to="/terms&conditions" state={{ docs: 'terms', type: 1, active: 1 }}>
            <div style={{ flexDirection: 'column', display: 'flex' }}>
              <text className="privacy">Terms & Conditions</text>
              <text className="subtextOption">Terms & Conditions for your use</text>
              <hr />
            </div>
          </HashLink>
       {state.token?
          <div style={{ flexDirection: 'column', display: 'flex' }} onClick={setringHandler}>
            <text className="privacy">Account Settings</text>
            <text className="subtextOption">We respect your privacy</text>
            <hr />
          </div>:null}
        </div> : null}
      {settingView ?
        <div >
          <div className="mainDiv">
            <img src={Arrow} width={18} height={18} style={{ marginRight: 20, transform: 'rotate(180deg)', cursor: 'pointer' }} onClick={() => { SetsettingView(false) }} />
            <text className="privacy">Delete Account</text>
            <hr />
          </div>

          <div style={{ marginLeft: 20 }}>
            <div style={{ flexDirection: 'column', display: 'flex' }}>
              <text className="deleteText">Delete Account</text>
              <text className="subtextOption">We're sorry to see you go. May we ask why you're choosing to delete your account?</text>
              <hr />
            </div>
            {reasonListDisplay}
          </div>

          <Modal isOpen={delteModal} toggle={() => { SetDeleteModal(false) }}>
            <div className='crossdivSetting' >
              {/* {state.country.country === 'IN1'?'india'+loginStatus:'us'+login_US} */}
              <text className="privacy">Delete Account</text>
              <img src={Cross} style={{ width: 20, height: 20, marginRight: 20 }} onClick={modalHanmdler} />

            </div>

            <div>
              {boxView2 ?
                <div style={{ flexDirection: 'column', display: 'flex', padding: 20 }}>
                  <text className="deleteText">{selectedReason}</text>
                  <text className="deleteSubtext">Do you have any feedback for us? We would love to hear that.</text>
                  <Input type="text" name="address" id="exampleAddress" placeholder="Write feedback" onChange={onChangeReasonText} />
                  <div style={{ flexDirection: 'row', justifyContent: 'flex-end', display: 'flex', marginTop: 10 }}>
                    <Button color="info" size="sm" onClick={onPressNext}>Next</Button>
                  </div>
                </div>
                :
                null
              }
              {boxView3 ?
                <div style={{ flexDirection: 'column', display: 'flex', padding: 20 }}>
                  <text className="deleteText">You are about to delete your account!</text>
                  <text className="deleteSubtext">You will lose all your data including your profile details, service bookings appointments & transactions and your account will be deleted permanently.</text>
                  <div style={{ flexDirection: 'column', justifyContent: 'flex-end', display: 'flex', marginTop: 10 }}>
                    <Button color="info" style={{ marginBottom: 10 }} onClick={onPressDelete} >Delete My Account</Button>
                    <Button color="secondary" onClick={modalHanmdler} >Cancle</Button>
                  </div>
                </div>
                :
                null
              }

            </div>

          </Modal>

        </div> : null}
    </div>
  );
}

export default SettingPage;
