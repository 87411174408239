//// Custom hook for checking network, fetching and returning data

import config from "../../../config"
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setAuthToken,authStoreToken,authLogOut } from '../../GlobalState/globalStateActions';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';


export default function customFetchData(method,body,url,state,dispatch,fullUrl,sentToken)
{
    // let navigation = useNavigate();
    return new Promise(async(resolve,reject)=>{
        // console.log("state,",state)
        //// Checking for network and fetching and returning the data to requested hook
    // console.log("fetchedData in usefetchdata",method,body,state,dispatch)
    try{
        // fetching and updating netinfo in global state 
        let netState= navigator.onLine;
        // console.log("Connection type", netState);
        // dispatch({type:'netInfo-multiple',payload:{isConnected:netState.isConnected,isInternetReachable:netState.isInternetReachable,type:netState.type}})  
        dispatch({type:'netInfo-multiple',payload:{isConnected:netState}})  

        // headers:{
        //     'Content-Type':'application/json'
        // },
        
        if(!netState)
        {
            toast.error("Unable to reach server. Please check your connection.");
            resolve({fetch:false,networkConnection:false})
        }
        else
        {
            let fetchOptions={
                method,
                url:fullUrl?fullUrl:url,
                headers:{'Content-Type':'application/json','x-auth':sentToken?sentToken:state.token}
            }
            if(method==='POST')
            {
                fetchOptions.data=body
            }
            // console.log("fetchOptions",fetchOptions,state.token)
            let fetchedDataComplete=await axios(fetchOptions)
            let fetchedData=fetchedDataComplete.data
            // console.log("fetchedData in usefetchdata",fetchedData)
            //// If token sent then checking for token validation and logging out user if token is not valid/not threre
            if(state.token || sentToken)
            {
                // console.log("token",state.token,sentToken)
                if(fetchedData.tokenValid===false || fetchedData.dbTokenError)
                {
                    if(fetchedData.dbTokenError)
                    {
                        toast.error("Internal Error. Please try again");
                    }
                    else
                    {
                        toast.error("Session Expired");
                        let logoutCheck=await authLogOut(dispatch)
                        if(logoutCheck)
                        {
                            // navigation('/',{});
                        }
                        else
                        {
                            toast.error("Something went wrong. Please try again");
                        }
                    }   
                    resolve({fetch:false,fetchedData:[],tokenValid:false})
                }
                else
                {
                    resolve({fetch:true,fetchedData})
                }
                
            }
            else
            {
                resolve({fetch:true,fetchedData})
            }
        } 
    }
    catch(c1)
    {
        console.log(c1)
        toast.error("Something went wrong. Please try again.");
        resolve({fetch:false,fetchedData:[],catch:true})
    }
        // console.log("globalState in usefetch",state,networkRefresh)
    })
}
