import React, { useState } from "react";
import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import '../../CSS/MyBookings.css';
import '../../CSS/HomePage.css';
import BookingCards from "../../Components/Custom/UI/BookingCards";
import UpcomingBookingsPage from "./UpcomingBookingPage";
import PastBookingsPage from "./PastBookingPage";
import CancelledBookingsPage from "./CancelledBookingPage";
import DiscountCard from "../../Components/Custom/UI/DiscountCard";
import DiscountCardColoured from "../../Components/Custom/UI/DiscountCardColoured";
import { useMediaQuery } from "react-responsive";

export default function MyBookings(){

    const MobileAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 760px)" })
    const TabletMediaQuery = () => useMediaQuery({ minWidth: 768, maxWidth: 991 })

    const isTabView = TabletMediaQuery()

    const isMobileView = MobileAndBelowMediaQuery();

    const [activeItem, setActiveItem] = useState('Upcoming');

    const navItem = ['Upcoming', 'Past', 'Cancelled']

    function handleItemClick(item) {
        setActiveItem(item);
    }

    const onCancelBookinfNavigation = (item) => {
        handleItemClick('Cancelled')
    }

    return (
        <div className="MainReusable_Container_Home mybooking_main_div_style" style={{paddingTop: isMobileView ? 80 : 120, paddingBottom:44}}>
            <div className="mybooking_header_div_style">
                <text className="mybooking_header_text_style">My Bookings</text>
            </div>
            <div>
                <Nav tabs className="mybooking_navbar_style">
                        {navItem.map((item) => (
                            <NavItem className={item === activeItem ? "mybooking_navbarItem_active_style" : "mybooking_navbarItem_style"}>
                                <NavLink key={item} onClick={() => handleItemClick(item)} className={item === activeItem ? 'mybooking_navItem_active_style' : 'mybooking_navItem_inactive_style'}>{item}</NavLink>
                            </NavItem>
                        ))}
                </Nav>
            </div>
            <div>
                {activeItem === 'Upcoming' && <UpcomingBookingsPage onCancelBooking={onCancelBookinfNavigation} />}
                {activeItem === 'Past' && <PastBookingsPage />}
                {activeItem === 'Cancelled' && <CancelledBookingsPage />}
            </div>

            
                {/* <DiscountCardColoured /> */}
                {/* <DiscountCard/> */}
            
        </div>
    )
}