import Offer from '../../../New_Website_V2/Assets/bxs-offer.png'
//// Enter paths where the nav bar should be transparent (page where banners are there)
const NavPaths =[
'/',
'/offers',
'/professionalUnderCategory',
'/HelpAndSupport',
'/blog',
'/businesslisting',
'/blogdetails/is-your-car-ready-to-hit-the-road',
'/blogdetails/is-your-hair-ready-to-be-styled',
'/blogdetails/5-unique-ways-to-surprise',
'/blogdetails/onlineservice',
'/blogdetails/trending',
'/blogdetails/skin',
'/blogdetails/summer-coming',
'/professionalPage',
// '/professionalUnderCategory',
'/support',
'/blogAricles',
'/areYouPro',
'/blogdetails',
'/allprofessionals',
// '/professionalPage',
'/allservices',   //'/allservices/:proName'
// '/servicedetails', //pathname:'/servicedetails/:servicename'
]

const dynamicNavPaths=[

]


const mobileHeaderNav =[ 
    {pathname:'/myTransactions',HeaderName:'My transactions', showBackIcon:true,backIconPath:'/profile'},
    {pathname:'/wishlist',HeaderName:'Wishlist', showBackIcon:true,backIconPath:'/'},
    {pathname:'/checkout',HeaderName:'Checkout', showBackIcon:true,backIconPath:''},
    {pathname:'/mybookings',HeaderName:'My Bookings', showBackIcon:false,backIconPath:''},
    {pathname:'/profile',HeaderName:'Profile', showBackIcon:false,backIconPath:''},
    {pathname:'/savedAddresses',HeaderName:'Saved Address', showBackIcon:true,backIconPath:'/profile'},
    {pathname:'/servicedetails',HeaderName:' ', showBackIcon:true,backIconPath:''}, //pathname:'/servicedetails/:servicename'
    {pathname:'/ConsumerEditProfile',HeaderName:'Edit Profile', showBackIcon:true,backIconPath:'/profile'},
    {pathname:'/offers',icon:true,HeaderName:'Offers', showBackIcon:true,backIconPath:''},
    {pathname:'/setting',HeaderName:'Settings', showBackIcon:true,backIconPath:'/profile'},
    {pathname:'/deleteAccount',HeaderName:'Delete Account', showBackIcon:true,backIconPath:'/setting'},
    {pathname:'/referandearn',HeaderName:'Refer & Earn', showBackIcon:true,backIconPath:'/profile'},
    {pathname:'/profile/referandearn',HeaderName:'Refer & Earn', showBackIcon:true,backIconPath:'/profile'},
    {pathname:'/allservices',HeaderName:' ', showBackIcon:true,backIconPath:''}, //pathname:'/allservices/:proName'
    {pathname:'/allprofessionals',HeaderName:' ', showBackIcon:true,backIconPath:''},
    {pathname:'/terms&policy',HeaderName:' ', showBackIcon:true,backIconPath:''}

 
] 


//Array of paths to hide footer
const FooterHidePaths =[
    '/checkout',
    '/slashApp/helpAndSupport/customerView',
    '/slashApp/helpAndSupport/professionalView',
    '/slashApp/helpAndSupport/complaint/consumer',
    '/slashApp/helpAndSupport/complaint/merchant'
    ]

//Array of paths to hide bottom navbar
const BottomNavHidePaths =[
    '/checkout',
    // '/professionalPage',
    '/allservices/:proName',
    '/allprofessionals',
    '/servicedetails/:servicename',
    '/slashApp/helpAndSupport/complaint/consumer',
    '/slashApp/helpAndSupport/complaint/merchant'
    ]

export default NavPaths
export {mobileHeaderNav, FooterHidePaths, BottomNavHidePaths}
