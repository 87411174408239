import React, { useContext, useEffect, useState } from "react";
import '../../../CSS/UI Components/ReferalRewards.css';
import Rupee_Icon from '../../../Assets/reward_rupee_symbol.png';
import Dollar_Icon from '../../../Assets/reward_dollar_symbol.png';
import whiteArrowHd from '../../../Assets/whiteArrowHd.png'
import scratchCard_image from '../../../Assets/reward_scratchcard_image.png'
import scratchCard_image_disabled from '../../../Assets/reward_scratchcard_image_disabled.png'
import scratchCard_image_revealed from '../../../Assets/reward_scrachcard_revealed.png';
import { Animate } from 'react-simple-animate'
import Cross_Icon from '../../../Assets/cross_icon.png';
import { globalState } from "../../../GlobalState/globalState";
import { Modal, ModalBody } from "reactstrap";
import Close_Cross from "./Close_Cross";
import ConfettiExplosion from "react-confetti-explosion";
import customFetchData from "../customFetchData";
import { toast } from "react-toastify";
import numeral from "numeral";
import ReferralCardReveal from '../../../Assets/proDiscountpink_refer.png'
import CouponBackground from '../../../Assets/couponRevealBackground.png'
import moment from "moment";

export default function ReferalReward(props){
    const [state, dispatch] = useContext(globalState);
    const [scratchCardModal, setScratchCardModal]=useState(false)
    const [isExploide, setIsExploide]=useState(false)
    const [isExploideDone, setIsExploideDone]=useState(false)
    const [isClickCoupon, setIsClickCoupon]=useState(false)
    const [loading, setLoading]=useState(false)
    const [tokenValid, setTokenValid]=useState(false)
    const [scratchAmount, setScratchAmount]=useState('')
    const [refPrid, setRefPrid]=useState(null)
    const [couponCode, setCouponCode]=useState('')
    const [showCDetails, setShowCDetails]=useState(false)
    const [maxBudget, setMaxBudget]=useState(false)
    const [scratchCardArr, setScratchCardArr]=useState([])
    const [scLocked,setSCLocked]=useState([])
    const [scUnlocked, setSCUnlocked]=useState([])
    const [scUsed, setSCUsed]=useState([])
    const [totalEarned, setTotalEarned]=useState(0)

    const onClickCouponCard=()=>{
        setIsClickCoupon(true)
        // setIsExploide(true)

        onScratchDone();

        // setTimeout(() => {
        //     setIsExploide(true)
        // }, 1000)
    }

    const onClickCloseModal=()=>{
        setScratchCardModal(false)
        setIsClickCoupon(false)
        setIsExploide(false)

        fetchReferalData()
    }

    useEffect(() => {
        fetchReferalData()
    },[])

    const fetchReferalData=async()=>{
        let totalEarned=0;
        let body={
            consumer_prid: state.user.id,
            country: state.country.country
        }

        setLoading(true)

        let {fetch, fetchedData} = await customFetchData('POST',body,'/website/referrals/earn', state, dispatch)
        
            if(fetchedData.tokenValid===false || fetchedData.dbTokenError)
            {
                if(fetchedData.dbTokenError)
                {
                    setLoading(false)
                    toast("Internal Error. Please try again");
                }
                else
                {
                    setLoading(false)
                    setTokenValid(false)
                    toast("Session Expired");

                    // this.props.onLogOut().then(
                    //     ()=>{
                    //         this.props.navigation.navigate('Profile',{});
                    //     }
                    // ).catch(err1=>{
                    //     ToastAndroid.show("Something went wrong. Please try again. ",ToastAndroid.LONG);
                    //     });
                }   
            }
            else{
                if(fetchedData.data){

                    console.log("fetchedData", fetchedData)

                    let scLocked=[],scUnlocked=[],scUsed=[];

                    if(fetchedData.scratchArr.length!==0)
                    {
                        scLocked=fetchedData.scratchArr.filter((item)=>{
                            return item.scratchStatus==="locked"
                        })

                        scUnlocked=fetchedData.scratchArr.filter((item)=>{
                            return (item.scratchStatus==="unlocked")
                        })

                        scUsed=fetchedData.scratchArr.filter((item)=>{
                            return (item.scratchStatus==="used")
                        })
                    }
                        
                    if(fetchedData.scratchArr.length !== 0){

                        fetchedData.scratchArr.map((item) => {
                            if(item.scratchStatus == 'used'){
                                return totalEarned = numeral(totalEarned).add(item.OUR_COUPON_VALUE_OFF).value().toFixed(2)
                            }
                        })
                    }

                    // setReferralCode(fetchedData.refCode)
                    setScratchCardArr(fetchedData.scratchArr)
                    setSCLocked(scLocked)
                    setSCUnlocked(scUnlocked)
                    setSCUsed(scUsed)
                    setTotalEarned(totalEarned)

                }
                else{
                    if(fetchedData.dbError)
                    {
                        toast("Internal Error. Please try again.");
                    }
                    else{
                    // ToastAndroid.show("Something went wrong. Please try again. ",ToastAndroid.SHORT);
                    }
                    setLoading(false)
                }
            }
    }

    const onClickCouponHandler = (item) => {
        setRefPrid(item.QF_REF_PRID)
        setScratchCardModal(true)
    }

    const onScratchDone = async(id) => {
        // Do something
        // console.log("isScratchDone",isScratchDone,id)

        let body={
            consumer_prid:state.user.id,
            ref_prid: refPrid,
            country: state.country.country

        }

        // if(!this.state.lockedCard){
        
        setLoading(true)
        
        let {fetch, fetchedData} = await customFetchData('POST',body,'/website/refer/scratchCard/sv2', state, dispatch)

        console.log("fetchedData in scratch",fetchedData)
    
            if(fetchedData.tokenValid===false || fetchedData.dbTokenError)
            {
                if(fetchedData.dbTokenError)
                {
                    setLoading(false)
                    toast("Internal Error. Please try again");
                }
                else
                {
                    setLoading(false)
                    setTokenValid(false)
                    toast("Session Expired");

                    // this.props.onLogOut().then(
                    //     ()=>{
                    //         this.props.navigation.navigate('Profile',{});
                    //     }
                    // ).catch(err1=>{
                    //         toast("Something went wrong. Please try again. ");
                    //     });
                }   
            }
            else{
                if(fetchedData.data){
                    setLoading(false)
                    setScratchAmount(fetchedData.scratchAmount)
                    setCouponCode(fetchedData.text)
                    setShowCDetails(true)
                    setIsExploide(true)
                    
                    // this.componentDidMount();
                }
                else{
                    if(fetchedData.dbError)
                    { 
                        toast("Internal Error. Please try again.");
                    }
                    else if(fetchedData.maxBudget){
                        // ToastAndroid.show("Something went wrong. Please try again. ",ToastAndroid.SHORT);
                        setMaxBudget(true)
                    }

                    setLoading(false)
                }
            }
               
        // }
    }


    let unlockedCoupons;
    if(scUnlocked.length!==0){
        console.log("scUnlocked", scUnlocked)
        unlockedCoupons=scUnlocked.map((item, index) => {
            return (
                <div className="reward_scratchCard_div_unlocked" onClick={() => onClickCouponHandler(item)}>
                    <img src={scratchCard_image} className="reward_scratchCard_image_unlocked" />
                </div>
            )

        })
    }

    let UsedCoupons;
    if(scUsed.length!==0){
        console.log("scUsed", scUsed)
        UsedCoupons=scUsed.map((item, index) => {
            return (
                <div className="reward_scratchCard_div_used" >
                    <div className="reward_referal_recieved_image_div" style={{backgroundImage: `url(${CouponBackground})`, backgroundSize:'cover', backgroundRepeat: 'no-repeat'}}>
                        <img src={scratchCard_image_revealed} className="reward_scratchCard_image_used" />
                        <div className="reward_reveal_amount_div">
                            <text className="reward_reveal_amount">{state.country.country == 'IN' ? '\u20B9' : '$'}{item.OUR_COUPON_VALUE_OFF}</text>
                        </div>
                    </div>
                    <div className="reward_referal_recieved_div">
                        <text className="reward_referral-pending">Reward received</text>
                        <text className="reward_referal_recieved_date">{moment(item.QF_REF_CREATED_ON_DATE).format('DD-MM-YYYY')}</text>
                    </div>    
                </div>
                
            )

        })
    }

    let lockedCoupons;
    if(scLocked.length!==0){
        console.log("scLocked", scLocked)
        lockedCoupons=scLocked.map((item, index) => {
            return (
                <div className="reward_scratchCard_div_locked">
                    <img src={scratchCard_image_disabled} className="reward_scratchCard_image_locked" />
                    <div className="Rectangle-6713">
                        <text className="reward_referral-pending">Referral Pending</text>
                        {/* <div className="Frame-1000005269">
                            <text className="reward_remind">Remind</text>
                        </div> */}
                    </div>
                </div>
            )

        })
    }

    return (
        <div>
            <div className="reward_main_div">
                <div className="reward_text_image_div">
                    <div onClick={() => props.onPressBack()}>
                        <img src={whiteArrowHd} style={{paddingTop:4}}/>
                    </div>
                    <div className="reward_text_div">
                        <text className="reward_header_text">{state.country.country == 'IN' ? '\u20B9' : '$'}{totalEarned}</text>
                        <text className="reward_desc_text">Total Rewards</text>
                    </div>
                </div>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    {state.country.country == 'IN' ? 
                        <img src={Rupee_Icon} />
                        :
                        <img src={Dollar_Icon} />
                    }
                </div>
            </div>
            <div className="reward_placeholder_div">

                {lockedCoupons}
                {unlockedCoupons}
                {UsedCoupons}

                {/* <div className="reward_scratchCard_div">
                    <img src={scratchCard_image_disabled} className="reward_scratchCard_image" />
                    <div className="Rectangle-6713">
                        <text className="reward_referral-pending">Referral Pending</text>
                        <div className="Frame-1000005269">
                            <text className="reward_remind">Remind</text>
                        </div>
                    </div>
                </div>

                <div className="reward_scratchCard_div" onClick={() => onScratchDone()}>
                    <img src={scratchCard_image} className="reward_scratchCard_image" />
                </div>

                <div className="reward_scratchCard_div">
                    <div className="reward_referal_recieved_image_div">
                        <img src={scratchCard_image_revealed} className="reward_scratchCard_image" />
                    </div>
                    <div className="reward_referal_recieved_div">
                        <text className="reward_referral-pending">Reward received</text>
                        <text className="reward_referal_recieved_date">7th Oct 2023</text>
                    </div>    
                </div> */}

            </div>

            <Modal isOpen={scratchCardModal} centered size="md">
                <ModalBody className="reward_modal_body">
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center', width:'100%'}}>
                        <div onClick={() => onClickCloseModal()}>
                            <img src={Cross_Icon} />
                        </div>
                        {/* <Close_Cross onClick={() => setScratchCardModal(false)}/> */}
                    </div>
                    <div className="reward_modal_main_div_wrapper">
                        
                        <div className={isClickCoupon ? "reward_modal_main_div_explode" : "reward_modal_main_div"}>
                            <div className="reward_scratchCard_div_modal">
                                <img src={scratchCard_image} className={isExploide ? "reward_scratchCard_image_change" : "reward_scratchCard_image"} style={{ opacity:isExploide ? 0.6 : 1}} onClick={() => onClickCouponCard()}/>
                                <img src={ReferralCardReveal} className={isExploide ? "reward_scratchCard_image_change_reverse" : "reward_scratchCard_image_reverse"} style={{ opacity:isExploide ? 1 : 0.6}} />
                                {isExploide ?
                                    <div className={isExploide ? "reward_coupon_reveal_amount_div_reverse" : "reward_coupon_reveal_amount_div"}>
                                        <text className={isExploide ? "reward_coupon_reveal_amount_text_reverse" : "reward_coupon_reveal_amount_text"}>{state.country.country == 'IN' ? '\u20B9' :  '$'}{scratchAmount}</text>
                                    </div> : null}
                                {/* <img src="https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1657540796698-lg.png" /> */}
                            </div>
                        </div>

                        <div style={{width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                            <text className="rewards_congratulations_text">Congratulations !</text>
                            <text className="Reward-will-be-soon-reflected-in-your-account">Reward will be soon reflected in your account </text>
                        </div>

                    </div>

                    {isExploide ? <ConfettiExplosion force={1} zIndex={9999} style={{position:'fixed'}}/> : null}
                </ModalBody>
            </Modal>
        </div>
    )
}