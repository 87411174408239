import React, { useEffect, useState } from 'react';
import {isAndroid, isIOS} from 'react-device-detect';
import '../../../CSS/QRScannerLoader/loader.css';
import { useLocation } from 'react-router-dom';


export default function QRScannerMerchant(){

    const [loading, setLoading]=useState(false);
    const [catchScan, setCatchScan]=useState(false)

    const location=useLocation()

    useEffect(() => {
        setLoading(true)

        let mainUtmURL= location.search != "" ? location.search.split('?') : []
        let androidUtmURL= mainUtmURL.length != 0 ? mainUtmURL[1] : ''
        let iosUtmURL= location.search != "" ? location.search : ''


        if(isAndroid){
            try{
                if(androidUtmURL !== ''){
                    window.location.href='https://play.google.com/store/apps/details?id=com.quickfindmerchant'+'&'+androidUtmURL
                } else {
                    window.location.href='https://play.google.com/store/apps/details?id=com.quickfindmerchant'
                }
                setLoading(false)
                setCatchScan(false)
            } catch(err1){
                console.log("err1", err1);
                setCatchScan(true)
                setLoading(false)
            }

        } else if(isIOS){
            try{
                window.location.href='https://apps.apple.com/us/app/slash-merchant/id1489211413?ls=1'+iosUtmURL
                setLoading(false)
                setCatchScan(false)
            } catch(err2){
                console.log("err2", err2);
                setLoading(false)
                setCatchScan(true)
            }

        } else {
            try{
                window.location.href='https://www.goslash.com'+iosUtmURL
                setLoading(false)
                setCatchScan(false)
            } catch(err3){
                console.log("err3", err3);
                setLoading(false)
                setCatchScan(true)
            }

        }
    })
    
    return(
        <div style={{display:'flex', justifyContent:'center', alignContent:'center'}}>
            {loading ? <g className="loading">Please Wait while we Redirect the page<span style={{padding:2}}>.</span><span style={{padding:2}}>.</span><span style={{padding:2}}>.</span> </g> : null}
            {catchScan ? <g>Unable to reach server, please try again!</g> : null}
        </div>
    )
    
}