import React, { useContext, useState } from 'react'
import { Button, Input, Modal } from 'reactstrap'
import { globalState } from '../../GlobalState/globalState'
import { useMediaQuery } from 'react-responsive'
import { authLogOut } from '../../GlobalState/globalStateActions'
import { ToastContainer, toast } from 'react-toastify';
import customFetchData from '../../Components/Custom/customFetchData'
import Cross from '../../Assets/cross.png'
export default function DeleteAccount_Mobile() {
    const [loading, setLoading] = useState(false)
    const [boxView1, setBoxView1] = useState(true)
    const [boxView2, setBoxView2] = useState(false)
    const [boxView3, setBoxView3] = useState(false)
    const [reasonText, setReasonText] = useState('')
    const [selectedReason, setSelectedReason] = useState('')
    const [textLength, setTextLength] = useState(0)
    const [maxTextLength, setMaxTextLength] = useState(150)
    const [deleteModal, SetDeleteModal] = useState(false)
    let [state, dispatch] = useContext(globalState)
    const [reasonList, setReasonList] = useState(
        [
            {
                title: 'I don’t want to use Slash anymore'
            },
            {
                title: 'The app is not working properly'
            },
            {
                title: 'The app is confusing'
            },
            {
                title: 'No support from the company'
            },
            {
                title: 'Others'
            },
        ]
    )
    const MobileAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 760px)" })
    const isMobileView = MobileAndBelowMediaQuery();
    const onLogOutHandler = async () => {
        let logOutStatusFromState = await authLogOut(dispatch)
        if (logOutStatusFromState) {
            // ToastAndroid.show("Account Deleted Successfully ",ToastAndroid.LONG);
            toast.success("Account Deleted Successfully", { position: toast.POSITION.BOTTOM_CENTER });


            // navigation.dispatch(
            //     CommonActions.reset({
            //       index: 0,
            //       routes: [{ name: 'BottomTabIndex' }],
            //     }))
            //   ReactMoE.logout();
            //   AppEventsLogger.setUserID(null)
            //   navigation.dispatch(resetAction)
        }
        else {
            toast.error("Verification code sent", { position: toast.POSITION.BOTTOM_CENTER });

        }
    }
    const onPressDelete = async () => {
        try {
            setLoading(true)
            const deleteData = {
                consumer_id: state.user.id,
                deleteReason: selectedReason,
                del_comments: reasonText
            }
            console.log('bodyDelete', deleteData)
            let deleteResponse = await customFetchData("POST", deleteData, '/website/profile/account/delete/Sv2', state, dispatch)
            console.log('delete response', deleteResponse)
            if (deleteResponse.fetch) {
                if (deleteResponse.fetchedData.delete) {
                    console.log("Logout")
                    onLogOutHandler()
                } else {
                    if (deleteResponse.fetchedData.upcomingBooking) {
                        // ToastAndroid.show("You cannot delete your account now as you have an upcoming booking", ToastAndroid.LONG)
                        toast.info("You cannot delete your account now as you have an upcoming booking", { position: toast.POSITION.BOTTOM_CENTER });

                    } else if (deleteResponse.fetchedData.disputeFiled) {
                        toast.info("You cannot delete your account now as you have an active dispute pending.", { position: toast.POSITION.BOTTOM_CENTER });

                        // ToastAndroid.show("You cannot delete your account now as you have an active dispute pending.", ToastAndroid.LONG)
                    } else {
                        toast.error("Something went wrong. Please try again!", { position: toast.POSITION.BOTTOM_CENTER });

                        // ToastAndroid.show("Something went wrong. Please try again!", ToastAndroid.LONG)
                    }
                }
                setLoading(false)
            }
        } catch (error) {
            console.log('delete Account Failed ', error);
            setLoading(false)
            toast.error("Account Deletion Failed. Please try again", { position: toast.POSITION.BOTTOM_CENTER });

            // ToastAndroid.show("Account Deletion Failed. Please try again. ",ToastAndroid.LONG);
        }
    }
    const onSelectReason = (item) => {
        // console.log('item', item)
        setSelectedReason(item.title)
        setBoxView1(false)
        setBoxView2(true)
        SetDeleteModal(true)
    }
    const onChangeReasonText = (e) => {

        const maxLength = 150;
        setTextLength(e.target.value.length)
        setReasonText(e.target.value)  // same as `text: text`
    }
    const onPressNext = () => {
        // console.log('onperess')
        setBoxView2(false)
        setBoxView3(true)
    }
    const modalHandler = () => {
        SetDeleteModal(false)
        setBoxView2(false)
        setBoxView3(false)

    }
    //displaying option
    let reasonListDisplay = reasonList.map((item, index) => {
        return (
            <div style={{ cursor: 'pointer' }} onClick={() => onSelectReason(item)}>
                <text className="subtextsetting">{item.title}</text>
                <hr />
            </div>
        )
    })
    return (
        <div style={{ marginTop: 100, marginBottom: 20 }}>
            <div>
                <div style={{ marginLeft: 20 }}>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <text className="deleteText">Delete Account</text>
                        <text className="subtextOption">We're sorry to see you go. May we ask why you're choosing to delete your account?</text>
                        <hr />
                    </div>
                    {reasonListDisplay}
                </div>
                <Modal isOpen={deleteModal} toggle={() => { SetDeleteModal(false) }}>
                    <div className='crossdivSetting' >
                        {/* {state.country.country === 'IN1'?'india'+loginStatus:'us'+login_US} */}
                        <img src={Cross} style={{ width: 20, height: 20, marginRight: 20 }} onClick={modalHandler} />
                        <text className="privacy">Delete Account</text>
                    </div>

                    <div>
                        {
                            boxView2 ?
                                <div style={{ flexDirection: 'column', display: 'flex', padding: 20 }}>
                                    <text className="deleteText">{selectedReason}</text>
                                    <text className="deleteSubtext">Do you have any feedback for us? We would love to hear that.</text>
                                    <Input type="text" name="address" id="exampleAddress" placeholder="Write feedback" onChange={onChangeReasonText} />
                                    <div style={{ flexDirection: 'row', justifyContent: 'flex-end', display: 'flex', marginTop: 10 }}>
                                        <Button color="info" size="sm" onClick={onPressNext}>Next</Button>
                                    </div>
                                </div>
                                :
                                null
                        }
                        {
                            boxView3 ?
                                <div style={{ flexDirection: 'column', display: 'flex', padding: 20 }}>
                                    <text className="deleteText">You are about to delete your account!</text>
                                    <text className="deleteSubtext">You will lose all your data including your profile details, service bookings appointments & transactions and your account will be deleted permanently.</text>
                                    <div style={{ flexDirection: 'column', justifyContent: 'flex-end', display: 'flex', marginTop: 10 }}>
                                        <Button color="info" style={{ marginBottom: 10 }} onClick={onPressDelete} >Delete My Account</Button>
                                        <Button color="secondary" onClick={modalHandler} >Cancle</Button>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                </Modal>
            </div>
            <ToastContainer/>
        </div>
    )
}
