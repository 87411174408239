import React,{useContext, useEffect, useState} from 'react'
import { Container, Row, Col,Card,CardBody, CarouselItem, Carousel, Navbar,CarouselIndicators,Modal, ModalFooter,ModalHeader, ModalBody, Collapse, FormGroup, Button} from 'reactstrap'
import ShareIcon from '../../Assets/shareIcon.png'
import WishlistIcon from '../../Assets/wishlistIcon.png'
import '../../CSS/UI Components/ServiceDetailsPage.css'
import RatingsAndReviews from '../../Components/Custom/UI/RatingsAndReviews'
import { useLocation, useNavigate } from 'react-router-dom'
import { globalState } from '../../GlobalState/globalState'
import { addToCart, clearAddress, clearServices, removeFromCart,storeAddress,storeDateAndTime, storeServices } from '../../GlobalState/globalStateActions'
import customFetchData from '../../Components/Custom/customFetchData'
import { FaArrowRight } from 'react-icons/fa';
import WishlistYes from '../../Assets/iconFavorite.png' 
import momenttz from 'moment-timezone'
import moment from 'moment';
import numeral from 'numeral'
import DistanceIcon from '../../Assets/gis-route.png'
import StarIcon from '../../Assets/group-482015.png'
import DurationIcon from '../../Assets/asicon-outline-timer.png'
import FetchCurrentLocation from '../../Components/Custom/FetchCurrentLocation'
import { ClipLoader } from 'react-spinners'
import { ToastContainer, toast } from 'react-toastify'
const DeepClone = require("rfdc/default")
export default function ServiceDetailsPage() {
    const paramsLocation=useLocation()
    let navigate=useNavigate()
    const [proData,setProData]=useState(paramsLocation.state ? (paramsLocation.state.proData ? paramsLocation.state.proData : {}) :{})
    const [serviceDetails,setServiceDetails]=useState(paramsLocation.state ? (paramsLocation.state.serviceDetails ? paramsLocation.state.serviceDetails : {}) :{})
    const [current,setCurrent]=useState(0)
    const [index,setIndex]=useState(0)
    const [couponDetails,setCouponDetails]=useState([])
    const [ratingDetails,setRatingDetails]=useState([])
    const [ratings1,setRatings1]=useState(0)
    const [ratings2,setRatings2]=useState(0)
    const [ratings3,setRatings3]=useState(0)
    const [ratings4,setRatings4]=useState(0)
    const [ratings5,setRatings5]=useState(0)
    const [totalRatings,setTotalRatings]=useState(0)
    const [avgRating,setAvgRating]=useState(0)
    const [wishlistPress,setWishlistPress]=useState(false)
    const [isAddedToCart, setIsAddedToCart] = useState(false)
    const [addCheck,setAddCheck]=useState(false)
    const [loading,setLoading]=useState(false)
    const [cartAmount, setCartAmount] = useState(0)
    const [state,dispatch]=useContext(globalState)
    const [ activeIndex,setActiveIndex ]=useState(0);
    const [ cancelAllowed, setCancelAllowed ] = useState(1)
    const [brokenLink,setBrokenLink]=useState(false)
let serviceDetailsCard={
    borderRadius: 8,
    backgroundColor: "#ffffff",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "rgba(140, 82, 255, 0.4)",
    marginTop:20,
    // height:200,
    // marginLeft:20,
    // marginRight:20
 } 
 let horizontalLine={
    width: '100%',
    height: 0.5,
    backgroundColor: "#747474",
    marginVertical:5
}
 
useEffect(()=>{
    if(paramsLocation.state){
        if (state.services.length === 0) {
            storeServices(paramsLocation.state.allServices, paramsLocation.state.categories, paramsLocation.state.subCategories, dispatch)
        }
        if (state.address.totalAddress === null) {
            // console.log('params', paramsLocation.state.addressDetails)
            let addressDetails = paramsLocation.state.addressDetails
            storeAddress(addressDetails.totalAddress, addressDetails.addressLine1, addressDetails.country, addressDetails.city, addressDetails.stateOrProvince, addressDetails.zipcode, addressDetails.district, addressDetails.latitude, addressDetails.longitude, addressDetails.currentAddress, addressDetails.searchAddress, addressDetails.currentLocationDenied, dispatch, state)
        }
        // console.log('params in service details page', paramsLocation.state)
        ratingsFetchHandler()
        if (paramsLocation.state.wishlisted) {
            setWishlistPress(true)
        }
        else {
            setWishlistPress(false)
        }
        setBrokenLink(false)
    }
    else{
        serviceDetailsFetch()
    }
},[])
const serviceDetailsFetch = async () => {

    let searchUrl = paramsLocation.search
    // console.log('searchUrl', searchUrl)
    let regex = /[?&]([^=#]+)=([^&#]*)/g, searchParams = {}, match
    while ((match = regex.exec(searchUrl))) {
        searchParams[match[1]] = match[2]
    }
    if (searchParams.serviceId && searchParams.proId) {
        setLoading(true)
        // console.log('searchParams', searchParams)
        let serviceDetails1 = {
            QF_MERCHANT_SERVICE_PRID: Number(searchParams.serviceId),
            QF_MERCHANT_PERSONNEL_PRID: Number(searchParams.proId)
        }
        let currentLocationFetch=await FetchCurrentLocation(state,dispatch)
        // console.log('currentLocationFetch',currentLocationFetch)
        // console.log('serviceDetails1', serviceDetails1)
        let body;
        // if(currentLocationFetch.locationFetch){
            let { city, country, curr_location_latitude, curr_location_longitude,stateOrProvince} = currentLocationFetch
            body = {
                curr_city: city,
                curr_lat: curr_location_latitude,
                curr_long: curr_location_longitude,
                stateOrProvince,
                mpid: serviceDetails1.QF_MERCHANT_PERSONNEL_PRID ? serviceDetails1.QF_MERCHANT_PERSONNEL_PRID : null,
                serviceId: serviceDetails1.QF_MERCHANT_SERVICE_PRID ? serviceDetails1.QF_MERCHANT_SERVICE_PRID : null,
                consumerId:state.user.id
            }
        // }
        if(Object.keys(body).length !==0){
            // console.log('body',body)
            let serviceFetch = await customFetchData('POST', body, '/website/proSingle/serviceDetail/sv2', state, dispatch)
            // console.log('servicesFe', serviceFetch, proData)
            if (serviceFetch.fetch) {
                if (serviceFetch.fetchedData.offers.length !== 0) {
                    // setLoading(false)
                    let serviceData = {
                        servicePridArr: [serviceDetails1.QF_MERCHANT_SERVICE_PRID],
                        consumer_prid: state.user.id,
                        wishlistType: 'service',
                        mpprid: serviceDetails1.QF_MERCHANT_PERSONNEL_PRID
                    }
                    let wishListedData = await customFetchData('POST', serviceData, '/website/wishlistedProServices/sv2', state, dispatch)
                    if (wishListedData.fetchedData.wishlistRes.length !== 0) {
                        let serviceDetails = serviceFetch.fetchedData.offers[0]
                        // console.log('service details', serviceDetails)
                        wishListedData.fetchedData.wishlistRes.map(item11 => {
                            return (
                                serviceDetails.QF_WISHLIST_PRID = item11.QF_WISHLIST_PRID,
                                serviceDetails.WISHLIST_DELETED_FLAG = item11.WISHLIST_DELETED_FLAG
                            )
                        })
                        if (serviceDetails.WISHLIST_DELETED_FLAG === 0) {
                            setWishlistPress(true)
                        }
                        setProData(serviceDetails)
                        setServiceDetails(serviceDetails ? serviceDetails : {})
                        setLoading(false)
                    } else {
                        setLoading(false)
                        let serviceDetails = serviceFetch.fetchedData.offers[0]
                        setProData(serviceDetails)
                        setServiceDetails(serviceDetails ? serviceDetails : {})
                    }
                    let body1 = {
                        m_id: serviceFetch.fetchedData.offers[0].QF_MERCHANT_PRID,
                        // loc_prid:route.params.serviceDetails.QF_MERCHANT_LOCATION_PRID
                    }
                    let url1 = '/website/serviceDetailsCoupons/sv2'
                    let method1 = 'POST'
                    let couponDetails = await customFetchData(method1, body1, url1, state, dispatch)
                    // console.log('coupons',couponDetails)
                    let check = couponDetails.fetchedData
                    if (couponDetails.fetch) {
                        if (check.data) {
                            if (check.couponRes.length !== 0) {
                                setCouponDetails(check.couponRes)
                            } else {
                                setCouponDetails([])
                            }
                        }
                    }
                    let body = {
                        mp_id: serviceFetch.fetchedData.offers[0].QF_MERCHANT_PERSONNEL_PRID,
                        m_serviceId: serviceFetch.fetchedData.offers[0].QF_MERCHANT_SERVICE_PRID
                    }
                    let url = '/website/serviceRatingsFetch/sv2'
                    let method = 'POST'
                    let ratingFetch = await customFetchData(method, body, url, state, dispatch)
                    // console.log('ratings', ratingFetch)
                    let check1 = ratingFetch.fetchedData
                    if (ratingFetch.fetch) {
                        if (check1.data) {
                            if (check1.ratingsResult.length !== 0) {
                                setRatingDetails(check1.ratingsResult)
                                setTotalRatings(check1.totalRatings)
                                setRatings1(check1.ratings1)
                                setRatings2(check1.ratings2)
                                setRatings3(check1.ratings3)
                                setRatings4(check1.ratings4)
                                setRatings5(check1.ratings5)
                                setAvgRating(check1.avgRating)
                            } else {
                                setRatingDetails([])
                            }
                        }
                    }
                } else {
                    setLoading(false)
                }

            }
        }
        
    }else{
        setProData({})
        setServiceDetails({})
        setLoading(false)
        setRatingDetails([])
        setCouponDetails([])
        setBrokenLink(true)
    }
}
const navToLoginOrCheckout=()=>{
    navigate('/checkout',{state:{
        categories: DeepClone(state.categories), subCategories: DeepClone(state.subcategories),
        addressDetails:
        {
          totalAddress: state.address.totalAddress,
          addressLine1: state.address.addressLine1,
          country: state.address.country,
          city: state.address.city,
          stateOrProvince: state.address.stateOrProvince,
          zipcode: state.address.zipcode,
          district: state.address.district,
          latitude: state.address.latitude,
          longitude: state.address.longitude,
          currentAddress: state.address.currentAddress,
          searchAddress: state.address.searchAddress,
          currentLocationDenied: state.address.currentLocationDenied
        },
        allServices: DeepClone(state.services)
    }})
}
const WishlistPressOnService = async() =>{
    setWishlistPress(!wishlistPress)
    let body = {
        // cprid:item.QF_CONSUMER_PRID,
        cprid: state.user.id,
        mpprid: serviceDetails.QF_MERCHANT_PERSONNEL_PRID,
        wishLstType: 'service',
        servicePrid: serviceDetails.QF_MERCHANT_SERVICE_PRID
    }
    let WishlistInsert = await customFetchData('POST', body, '/website/add/wishList/sv2', state, dispatch)
      console.log('wish list response',WishlistInsert)
}
const ratingsFetchHandler = async() =>{
    let body1={
        m_id:paramsLocation.state.proData.QF_MERCHANT_PRID,
        // loc_prid:route.params.serviceDetails.QF_MERCHANT_LOCATION_PRID
    }
    let url1='/website/serviceDetailsCoupons/sv2'
    let method1='POST'
    let couponDetails=await customFetchData(method1,body1,url1,state,dispatch)
    // console.log('coupons',couponDetails)
    let check=couponDetails.fetchedData
    if(couponDetails.fetch){
        if(check.data){
            if(check.couponRes.length!==0){
                setCouponDetails(check.couponRes)
            }else{
                setCouponDetails([])  
            }
        }
    }
    let body={
        mp_id:proData.QF_MERCHANT_PERSONNEL_PRID,
        m_serviceId:serviceDetails.QF_MERCHANT_SERVICE_PRID
    }
    let url='/website/serviceRatingsFetch/sv2'
    let method='POST'
    let ratingFetch=await customFetchData(method,body,url,state,dispatch)
    // console.log('ratings',ratingFetch)
    let check1=ratingFetch.fetchedData
    if(ratingFetch.fetch){
        if(check1.data){
            if(check1.ratingsResult.length !==0){
                setRatingDetails(check1.ratingsResult)
                setTotalRatings(check1.totalRatings)
                setRatings1(check1.ratings1)
                setRatings2(check1.ratings2)
                setRatings3(check1.ratings3)
                setRatings4(check1.ratings4)
                setRatings5(check1.ratings5)
                setAvgRating(check1.avgRating)
            }else{
                setRatingDetails([])
            }
        }
    }
}
useEffect(()=>{
    // console.log("serviceDetails",serviceDetails)
    let cart=DeepClone(state.cart)

    let addCheck=cart.some((item)=>{
        return item.QF_MERCHANT_SERVICE_PRID===serviceDetails.QF_MERCHANT_SERVICE_PRID
    })
    // console.log("addCheck",addCheck,data.QF_MERCHANT_SERVICE_PRID)
    setIsAddedToCart(addCheck)
})
useEffect(() => {
    // console.log("state cart count in use effect",state.cartCount)
    let cart = [...state.cart], tempCartAmount = 0
    cart.map(item => {
        tempCartAmount += item.service_offer_price
    })
    setCartAmount(tempCartAmount)
}, [state.cart])

useEffect(()=>{
    let fromDateTime,toDateTime;
    if(Object.keys(state.fromDateTime).length===0)
    {
        //// setting from date in global state if already not set
        // dispatch({type:'SET_FROM_DATE_TIME',fromDateTime:moment().tz(state.country.timezone).set({ 'hour': 0, 'minute': 0 })})
        fromDateTime=moment().tz(state.country.timezone).set({ 'hour': 0, 'minute': 0 })
    }
    if(Object.keys(state.toDateTime).length===0)
    {
        //// setting to date in global state if already not set
        // dispatch({type:'SET_TO_DATE_TIME',toDateTime:moment().tz(state.country.timezone).set({ 'hour': 23, 'minute': 59 }).add(14, "days")})
        toDateTime=moment().tz(state.country.timezone).set({ 'hour': 23, 'minute': 59 }).add(14, "days")
    }
    if( fromDateTime && toDateTime ){
        storeDateAndTime(fromDateTime,toDateTime,dispatch)
    }
},[])
const next = () => {
    // if (animating) return;
    const nextIndex = activeIndex === couponDetails.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
};
const previous = () => {
    // if (animating) return;
    const nextIndex = activeIndex === 0 ? couponDetails.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
};
const goToIndex = (newIndex) => {
    //   console.log("newIndex",newIndex)
    // if (this.animating) return;
    setActiveIndex(newIndex);

}
let couponSlides;
if(couponDetails.length!==0){
    couponSlides=couponDetails.map((item) => {
        return (
    
            <CarouselItem
                // onExiting={this.onExiting}
                // onExited={this.onExited}
                key={item.QF_MERCHANT_COUPON_PRID}
            >
                <div style={{marginTop:15,background: 'linear-gradient(86deg, #8C52FF 3%, #F7F2FF 98%)',height:80,display:'flex',justifyContent:'space-between',alignItems:'center',padding:10,borderRadius:8}}>
                    <text>{item.MERCHANT_COUPON_CODE}</text>
                    <text >{item.MERCHANT_COUPON_PERCENT_OFF ? item.MERCHANT_COUPON_PERCENT_OFF + "% OFF" : (paramsLocation.state ? (paramsLocation.state.proData.MERCHANT_PERSONNEL_ADDRESS_COUNTRY== "India" ? ('\u20B9') : (paramsLocation.state.proData.MERCHANT_PERSONNEL_ADDRESS_COUNTRY== "United States" ? '$' : '')) : (proData.MERCHANT_PERSONNEL_ADDRESS_COUNTRY== "India" ? ('\u20B9') : (proData.MERCHANT_PERSONNEL_ADDRESS_COUNTRY== "United States" ? '$' : ''))) + item.MERCHANT_COUPON_VALUE_OFF + " OFF"}</text>
                </div>
            </CarouselItem>
    
        );
    });
}
const onAddToCart = (data) => {
    console.log("on click add ot cart")
    // onClickCart(!cartClicked)
    addToCart(data,state,dispatch)
}

const onRemoveFromCart = (data)=>{
    console.log("on click add ot cart")
    // onClickCart(!cartClicked)
    removeFromCart(data,state,dispatch)
}
const copyClipHandler = () => {
    // let code=paramsLocation.pathname + paramsLocation.search
    let code=window.location.href
    navigator.clipboard.writeText(code)
    toast(
      <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
        <text>Copied to clipboard</text> 
      </div>,
      {
        position:'bottom-center',
        hideProgressBar: true,
        progress: undefined,
        closeButton:false,
        style:{backgroundColor:'#E0D1FF'}
    });
  }
// console.log('service details',serviceDetails,proData)
  return (
      <div style={{backgroundColor:'#fff'}}>
          {
            loading ?
            <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',paddingTop:40,height:200}}>
               <ClipLoader widthUnit={"%"} size={20} loadings={loading} color="#8c52ff" />
            </div>
            : 
              Object.keys(serviceDetails).length !== 0 ?
              <>
                  <div className='service_details_banner_mobile' style={{ background: 'linear-gradient(to top, rgba(0, 0, 0) 100%, rgba(0, 0, 0) 0%)' }}>
                      <img className='imgStylesv2_servicedetail' src={serviceDetails.image_link} />
                      <div style={{ position: 'absolute', bottom: 25, right: 20, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <div className='outlay_share_mobile' onClick={()=>copyClipHandler()}>
                            <img src={ShareIcon} style={{ width: 14, height: 14 }} />
                        </div>
                          {state.token ?
                              <div onClick={WishlistPressOnService} className='outlay_share_mobile' style={{ cursor: 'pointer', marginLeft: 15 }}>
                                  {
                                      wishlistPress ? <img src={WishlistYes} style={{ width: 18, height: 18, }} /> : <img src={WishlistIcon} style={{ width: 18, height: 18, }} />
                                  }
                              </div>
                              : null
                          }
                      </div>
                      <div style={{ position: 'absolute', bottom: 30, left: 15, display: 'flex', flexDirection: 'column' }}>
                          <text className='proNameStylesv2_Pro'>{serviceDetails.MERCHANT_NAME}</text>
                          <text className='addressTextsv2_Pro' style={{ marginTop: 5 }}>
                              {serviceDetails.category_name == "Home Care" || serviceDetails.category_name == "Tutoring"
                                  ?
                                  (serviceDetails.MERCHANT_PERSONNEL_ADDRESS_CITY + ", " + serviceDetails.MERCHANT_PERSONNEL_ADDRESS_STATE + ", " + serviceDetails.MERCHANT_PERSONNEL_ADDRESS_ZIPCODE)
                                  :
                                  (serviceDetails.MERCHANT_PERSONNEL_ADDRESS_LINE2.length > 34 ? serviceDetails.MERCHANT_PERSONNEL_ADDRESS_LINE2.substr(0,34)+ '...' : serviceDetails.MERCHANT_PERSONNEL_ADDRESS_LINE2)
                              }
                          </text>
                          <div style={{ marginTop: 5, display: 'flex', alignItems: 'center' }}>
                              <img src={DistanceIcon} />
                              <text className='distanceTextsv2_Pro'>
                                  {proData.SERVICE_CURRENCY == 'Rupees' ? numeral(proData.distance).divide(1000).format('0.00') + ' km away' : numeral(proData.distance).divide(1609.344).format('0.00') + ' mi away'}
                              </text>
                          </div>
                          {
                           ratingDetails.length !==0 && 
                          <div style={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                              <img src={StarIcon} style={{ width: 16, height: 16 }} />
                              <text className='distanceTextsv2_Pro'>
                                  {numeral(avgRating).format('0.0')}
                              </text>
                          </div>
                          }
                      </div>
                  </div>
                  <div className='MainReusable_Container_Home'>

                      <div className='service_desc_sv2'>
                          <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                              <text className='sevice_name_mobile'>{serviceDetails.SERVICE_NAME}</text>
                              {/* <text className='sevice_name_mobile'>Caramel Waxing</text> */}
                              <text className='service_price_mobile' >{serviceDetails.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'}{numeral(serviceDetails.service_offer_price).format('0.00')}</text>
                              {/* <text className='service_price_mobile' >₹ 350</text> */}
                          </div>
                          <div style={{ paddingTop: 10 }}>
                              <text className='service_desc_mobile'>{serviceDetails.SERVICE_DETAIL_DESC ? serviceDetails.SERVICE_DETAIL_DESC : 'No Description'}</text>
                              {/* <text className='service_desc_mobile'>Lorem ipsum dolor sit amet. Qui exercitationem deserunt quo volup tatem doloribus, Read more...</text> */}
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 10 }}>
                              <img src={DurationIcon} style={{ width: 16, height: 16 }} />
                              <text className='service_desc_mobile'>{moment.utc(moment.duration(serviceDetails.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") == 0 ? null : moment.utc(moment.duration(serviceDetails.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") + " hr"}{' '}{moment.utc(moment.duration(serviceDetails.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") == 0 ? null : moment.utc(moment.duration(serviceDetails.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") + " mins"}</text>
                          </div>
                      </div>
                      <div>
                          {
                              couponDetails.length !== 0 ?
                                  <Carousel
                                      activeIndex={activeIndex}
                                      next={next}
                                      previous={previous}
                                      ride="carousel"
                                      interval={20000}
                                  >
                                      {couponDetails.length > 1 ? <CarouselIndicators style={{ bottom: -8 }} items={couponDetails} activeIndex={activeIndex} onClickHandler={goToIndex} /> : null}
                                      {couponSlides}
                                  </Carousel>
                                  :
                                  null
                          }
                      </div>
                      <div style={serviceDetailsCard}>
                          {/* <LinearGradient colors={['rgb(140,82,255)', 'rgba(140,82,255,0.0)']} start={{ x: 0, y: 0.5 }} end={{ x: 1, y: 2 }} style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}> */}
                          <div style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 10, height: 39, display: 'flex', background: 'linear-gradient(86deg, #8C52FF 3%, #F7F2FF 98%)', borderTopRightRadius: 8, borderTopLeftRadius: 8 }}>
                              <text className='service_id_mobile'>Service ID</text>
                              <text className='prid_mobile'>{serviceDetails.QF_MERCHANT_SERVICE_PRID}</text>
                              {/* <text className='prid_mobile'>1115</text> */}
                          </div>
                          {/* </LinearGradient> */}
                          <div style={{ paddingTop: 16, paddingBottom: 16, paddingLeft: 16, paddingRight: 16 }}>
                              <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', paddingVertical: 4 }}>
                                  <text className='reg_price_mobile'>Regular Price</text>
                                  {/* <text className='prid_mobile'>₹ 616.00</text> */}
                                  <text style={{ fontWeight: '600' }}>{serviceDetails.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'} {numeral(serviceDetails.service_list_price).format('0.00')}</text>
                              </div>
                              {/* {
                                        serviceDetails.cpPrid
                                            ?
                                            <div style={{ flexDirection: 'row', justifyContent: 'space-between',paddingTop: 4,paddingBottom:4,display:'flex'}}>
                                                {serviceDetails.multiplier_diff_price > 0 ? <Text style={styles.desc}>Slash Fee</Text> : <Text style={styles.desc}>Slash Discount</Text>}
                                                    {
                                                        serviceDetails.SERVICE_CURRENCY === 'Rupees'
                                                            ?
                                                            <text className='prid_mobile'>{'\u20B9'}{serviceDetails.multiplier_diff_price ? Math.abs(numeral(serviceDetails.multiplier_diff_price).value().toFixed(2)) : '0.00'}</text>
                                                            :
                                                            <text className='prid_mobile'>${serviceDetails.multiplier_diff_price ? Math.abs(serviceDetails.multiplier_diff_price).toFixed(2) : '0.00'}</text>
                                                    }
                                            </div>
                                            :
                                            null
                                    } */}
                              <div style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: 4, paddingBottom: 4, display: 'flex' }}>
                                  <text className='reg_price_mobile'>Service Discount Percent</text>
                                  {/* <text className='prid_mobile'>10%</text> */}
                                  <text className='prid_mobile'>{serviceDetails.service_discount}%</text>
                              </div>
                              <div style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: 4, paddingBottom: 4, display: 'flex' }}>
                                  <text className='reg_price_mobile'>Service Discount Price</text>
                                  {/* <text className='prid_mobile' >₹ 66</text> */}
                                  <text style={{ fontWeight: '600' }}>{serviceDetails.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'} {numeral(serviceDetails.service_list_price).subtract(serviceDetails.SERVICE_OFFER_PRICE).value().toFixed(2)}</text>
                              </div>
                              <div style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: 4, paddingBottom: 4, display: 'flex' }}>
                                  <text className='pro_price_mobile'>Professional Price</text>
                                  {/* <text>₹ 550.00</text> */}
                                  <text className='prid_mobile'>{serviceDetails.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'} {numeral(serviceDetails.service_offer_price).format('0.00')}</text>
                              </div>
                              <hr />
                              {/* <div style={horizontalLine}></div> */}
                              <div style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 12, paddingTop: 4, paddingBottom: 4, display: 'flex' }}>
                                  <text className='total_price_mobile'>Total Price</text>
                                  <text className='service_price_mobile'>{serviceDetails.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'} {numeral(serviceDetails.service_offer_price).format('0.00')}</text>
                                  {/* <text className='service_price_mobile'>₹ 416</text> */}
                              </div>
                          </div>
                      </div>
                      <div className='cancel_box_mobile'>
                          <div>
                              <text className='pro_price_mobile'>Cancellation Disclaimer</text>
                          </div>
                          {
                              // serviceDetails.SERVICE_CANCEL_ALLOWED === 1 ?
                              serviceDetails.SERVICE_CANCEL_ALLOWED === 1 ?
                                  <>
                                      <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', paddingTop: 10, paddingBottom: 2 }}>
                                          <text className='reg_price_mobile'>Cancellation Before</text>
                                          {/* <text className='reg_price_mobile'>2 Days</text> */}
                                          <text className='reg_price_mobile'>{serviceDetails.SERVICE_CANCEL_ALLOWED_DAYS + (serviceDetails.SERVICE_CANCEL_ALLOWED_DAYS === 0 || 1 ? " day" : " days")}</text>
                                      </div>
                                      <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', paddingTop: 2, paddingBottom: 2 }}>
                                          <text className='reg_price_mobile'>Cancellation Fee</text>
                                          <text className='cancel_fee_mobile'>-{serviceDetails.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'} {serviceDetails.SERVICE_CANCEL_FEE}</text>
                                          {/* <text className='cancel_fee_mobile'>-₹ 20</text> */}
                                      </div>
                                  </>
                                  :
                                  <div>
                                      <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', paddingTop: 10 }}>
                                          <text className='reg_price_mobile'>Cancellation Allowed</text>
                                          <text className='reg_price_mobile'>No</text>
                                      </div>
                                  </div>
                          }

                      </div>
                      {
                          ratingDetails.length !== 0 ?
                              <div style={{ marginTop: 20 }}>
                                  <RatingsAndReviews rating={avgRating} ratings1={ratings1} ratings2={ratings2} ratings3={ratings3} ratings4={ratings4} ratings5={ratings5} totalNofRatings={totalRatings} reviews={50} />
                              </div>
                              : null
                      }
                      {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 15 }}>
                          {
                              isAddedToCart
                                  ?

                                  <div className='addToCart_Btn_Service_details' style={{ cursor: 'pointer' }} onClick={() => onRemoveFromCart(serviceDetails)}>
                                      <text className='addToCart_Btn_Service_details_Text'>Remove From Cart</text>
                                  </div>
                                  :
                                  <div className='addToCart_Btn_Service_details' style={{ cursor: 'pointer' }} onClick={() => onAddToCart(serviceDetails)}>
                                      <text className='addToCart_Btn_Service_details_Text'>Add To Cart</text>
                                  </div>
                          }
                      </div> */}
                  </div>
                  {
                      (state.cartCount && state.cart.length !== 0) ?
                          <div className="MainReusable_Container_Home" onClick={navToLoginOrCheckout} style={{ height: 80, paddingTop: 15, zIndex: 100, paddingBottom: 15, backgroundImage: ' linear-gradient(97deg, #1a67db -2%, #02c5d1 41%, #31b12f 100%)', position: 'fixed', bottom: 0, left: 0, right: 0 }}>
                              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <text style={{ maxWidth: 180 }} className='serviceNameText_InCart'>{state.cart[0].SERVICE_NAME + "" + (state.cartCount > 1 ? (" + " + (state.cartCount - 1) + " more") : "")}</text>
                                      <text style={{ marginTop: 3, fontSize: 12 }} className='serviceNameText_InCart'>{proData.MERCHANT_PERSONNEL_ADDRESS_COUNTRY === 'India' ? '\u20B9' : '$'}{cartAmount}</text>
                                  </div>
                                  <div style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                      <text style={{ fontWeight: 600 }} className='serviceNameText_InCart'>Checkout</text>
                                      <FaArrowRight style={{ marginLeft: 5 }} name='arrow-right' color='#fff' size={25} />
                                  </div>
                              </div>
                          </div>
                          : null
                  }
              </>
              :
              brokenLink ?
                <div onClick={() => navigate('/')} style={{ flexDirection: 'column', backgroundColor: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 100, marginBottom: 40 }}>
                    <text>Page Not Found, click the below button to go to home</text>
                    <Button className='addToCartButton' style={{ marginTop: 20 }}>Back to Home</Button>
                </div>
                : null
          }
      <ToastContainer/>
      </div>

  )
}
