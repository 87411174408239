import React from 'react'

export default function WhyNewSlash_Mobile() {
  return (
    <div className='WhySlash_Div_MobileView'>
        <div style={{flexDirection:'row',display:'flex'}}>
            <text className='whySlashTextMain_Mobile'>Why Slash?</text>
        </div>
        <div style={{flexDirection:'row',display:'flex'}}>
            <text className='whySlashSub_Text_Mobile'>Our platform connects you with trusted vendors in your area, ensuring that you get the best services at lower prices.</text>
        </div>
        <div style={{paddingLeft:10,paddingRight:10}}>
            <div style={{flexDirection:'row',display:'flex',marginTop:20}}>
                <div className='whySlashCard_Mobile'>
                    <img src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684327963852-lg.png'} style={{width:60,height:60}}/>
                </div>
                <div style={{flexDirection:'column',display:'flex',justifyContent:'center',marginLeft:7}}>
                    <text className='Low-demand_Mobile'>Low demand hour discounts</text>
                    <text className='book_service'>Book a service during a low-demand hour to enjoy the  discounts</text>
                </div>
            </div>
            <div style={{flexDirection:'row',display:'flex',marginTop:20}}>
                <div className='whySlashCard_Mobile'>
                    <img src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684327963783-lg.png'} style={{width:60,height:60}}/>
                </div>
                <div style={{flexDirection:'column',display:'flex',justifyContent:'center',marginLeft:7}}>
                    <text className='Low-demand_Mobile'>Easy to book services</text>
                    <text className='book_service'>Get hassle-free service booking with the nearby professionals</text>
                </div>
            </div>
            <div style={{flexDirection:'row',display:'flex',marginTop:20}}>
                <div className='whySlashCard_Mobile'>
                    <img src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684327963719-lg.png'} style={{width:60,height:60}}/>
                </div>
                <div style={{flexDirection:'column',display:'flex',justifyContent:'center',marginLeft:7}}>
                    <text className='Low-demand_Mobile'>Save money with special discounts</text>
                    <text className='book_service'>Take advantage of our seasonal and promotional discounts.</text>
                </div>
            </div>
        </div>
    </div>
  )
}
