import React from 'react'
import { Card, CardText, CardImg  } from 'reactstrap'
import { catColors } from './Colors'
import '../../../CSS/UI Components/CategoryCard.css'
import { useMediaQuery } from 'react-responsive'
let width  = window.innerWidth

export default function CategoryCards(props)
{
    // console.log('props',props,catColors)
    const useMobileAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 760px)" })
   const isMobileView = useMobileAndBelowMediaQuery()
    return(
        <div>
            {
                isMobileView ? 
                    <div>
                        <Card style={{ ...props.style, backgroundColor: catColors[props.categoryName]}} onClick={props.onClick} className="categoryCardStyle">
                            <CardImg
                                // progressiveRenderingEnabled={true}
                                className='categoryImg_Style'
                                src={props.uri}
                            // resizeMode='contain'
                            />
                            
                        </Card>
                        <div style={{display:'flex',justifyContent:'center',marginTop:5}}>
                            <text className="categoryCardText">{props.categoryName}</text>
                        </div>
                    </div>
                :
                    <Card style={{ ...props.style, backgroundColor: catColors[props.categoryName]}} onClick={props.onClick} className="categoryCardStyle">
                        <CardImg
                            // progressiveRenderingEnabled={true}
                            className='categoryImg_Style'
                            src={props.uri}
                        // resizeMode='contain'
                        />
                        <CardText className="categoryCardText">{props.categoryName}</CardText>
                    </Card>
            }
        </div>
    )
    
}
