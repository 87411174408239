import React, { useContext, useEffect, useState } from "react";
import { Container, Modal, ModalBody } from "reactstrap";
import '../../../CSS/UI Components/BookingCards.css';
import Tick_Image from '../../../Assets/status_tick.png'
import Time_Counter from '../../../Assets/time_counter.png'
import Time_Counter_Past from '../../../Assets/time_counter_past.png'
import Rating_star_icon from '../../../Assets/booking_rating_star_icon.png'
import Tick_Confirm_Icon from '../../../Assets/tick_confirm_cancel.png'
import Time_Counter_Past1 from '../../../Assets/tick_confirm_cancel_green.png'
import Calendar_Icon from '../../../Assets/calendar-month_Image.png'
import Calendar_Icon_grey from '../../../Assets/calendar-month_Image_grey.png'
import Timer_Icon from '../../../Assets/timer_Image.png'
import Cross_Icon from '../../../Assets/cross_icon.png';
import location_Icon from '../../../Assets/location-marker.png'
import Rebook_Icon from '../../../Assets/rebook_icon.png'
import UpcomingBookingDetailsPage from "../../../Screens/BookingStack/UpcomingBookingDetailsPage";
import customFetchData from "../customFetchData";
import { globalState } from "../../../GlobalState/globalState";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Rating } from 'react-simple-star-rating'
import Close_Cross from "./Close_Cross";
import momenttz from 'moment-timezone'
import PastBookingDetailsPage from "../../../Screens/BookingStack/PastBookingDetailsPage";
import CancelledBookingDetailsPage from "../../../Screens/BookingStack/CancelledBookingDetailsPage";
import numeral from "numeral";
import RatingPage from "../../../Screens/BookingStack/RatingPage";
import SubmitButtonSolid from "./submitButtonSolid";
import moment from "moment/moment";

export default function BookingCards(props){
    
    const navigate = useNavigate();

    const [state, dispatch] = useContext(globalState)

    const [detailsPage, setDetailsPage] = useState(false)
    const [cancelLoading, setCancelLoading]=useState(false)
    const [cancelSuccessModal, setCancelSuccessModal]=useState(false)
    const [serverError, setServerError]=useState(false)
    const [serviceDetails, setServiceDetails]=useState([])



    const [serviceData, setServiceData] = useState([])
    const [serviceType, setServiceType] = useState();
    const [latitude,setLatitude]=useState()
    const [longitude,setLongitude]=useState();
    
    useEffect(() => {
        const params = props
        
        setServiceData(params.item)
        setServiceType(params.serviceBookingType)
        // CurrentLocationFecthHandler();
        // console.log("params", navigation)
        // console.log("State in booking card "+serviceType, cancelSuccessModal)
        
    },[])

    const onStarRatingPress=(rating,ACCEPTED_PRID)=>{
        props.onStarPress(rating,ACCEPTED_PRID)
        
    }

    let subLocality=''
    if(serviceData.length !== 0){
        if(serviceData.MERCHANT_LOCATION_ADDRESS_LINE2 !== null){
            let address = serviceData.MERCHANT_LOCATION_ADDRESS_LINE2.split(',')
            subLocality = address[address.length-1]
            // console.log("address", subLocality)
        }
    }

    const onCardHandler = () => {
        setDetailsPage(true)
    }

    const onCancelCardHandler = () => {
        setDetailsPage(false)
    }

    const onPressCancelHandler = () => {
        setCancelSuccessModal(true)
    }

    const onPressCancelConfirm=async(item)=>{
        // console.log('item',item)
        let data={
            consumer_id:state.user.id,
            acceptance_prid:item.QF_CONSUMER_OFFER_ACCEPTED_PRID,
            uemail:state.user.email
        }
        setCancelLoading(true)
        setCancelSuccessModal(false)

        let refundPath=''
        refundPath=item.REC_SERVICE_CURRENCY==="Dollars"?'/website/refunds/stripe/v3':(item.REC_SERVICE_CURRENCY==="Rupees"?'/website/refunds/razorpay/v1':'')
            // console.log("currency",item.REC_SERVICE_CURRENCY," ",refundPath)
            try{
                if(refundPath)
                {
                    let CancelConfirmation=await customFetchData('POST',data,refundPath,state,dispatch)
                    // console.log('res',CancelConfirmation)
                    if(CancelConfirmation.fetch){
                        // setCancelLoading(false)                  
                          if(CancelConfirmation.fetchedData.sRefund)
                          {
                            setCancelLoading(false)                  
                            toast("Cancellation Successful", {
                                position: "bottom-center",
                                hideProgressBar: true,
                                progress: undefined,
                                style:{backgroundColor:'#E0D1FF'}
                            });
                            // navigate('CancelledStackIndex')
                            return props.onCancelBooking()
                          }
                          else
                          {
                            if(CancelConfirmation.fetchedData.refundDone)
                            {
                                toast("Cancellation and Refund already initiated. Please contact support if refund is not processed in 5 to 7 business days.", {
                                    position: "bottom-center",
                                    hideProgressBar: true,
                                    progress: undefined,
                                    style:{backgroundColor:'#E0D1FF'}
                                });
                            }
                            else if(CancelConfirmation.fetchedData.consume)
                            {
                                toast("This Booking is already Consumed. So it cannot be cancelled. Please contact support for further questions.", {
                                    position: "bottom-center",
                                    hideProgressBar: true,
                                    progress: undefined,
                                    style:{backgroundColor:'#E0D1FF'}
                                });
                            }
                            else if(CancelConfirmation.fetchedData.canAmountMore)
                            {
                                toast("The Cancellation Fee is higher than the Net Price you paid for this booking. So this booking cannot be cancelled", {
                                    position: "bottom-center",
                                    hideProgressBar: true,
                                    progress: undefined,
                                    style:{backgroundColor:'#E0D1FF'}
                                });
                            }
                            else if(CancelConfirmation.fetchedData.refund)
                            {
                                toast("Cancellation Successful", {
                                    position: "bottom-center",
                                    hideProgressBar: true,
                                    progress: undefined,
                                    style:{backgroundColor:'#E0D1FF'}
                                });
                                return props.onCancelBooking()
                                // navigate('CancelledStackIndex')
                                // const resetAction = StackActions.reset({
                                //     index: 0,
                                //     actions: [NavigationActions.navigate({ routeName: 'UpcomingBookingPage' })],
                                // });
                                // this.props.screenProps.navigation.state.params.fromPGToNav=false;
                                // navigation.dispatch(resetAction);
                                // navigation.navigate('Cancelled',{showCancel:true});
                            }
                            else if(CancelConfirmation.fetchedData.dbError)
                            {
                                toast.error("Something went wrong. Please try again", {
                                    position: "bottom-center",
                                    hideProgressBar: true,
                                    progress: undefined,
                                    style:{backgroundColor:'#E0D1FF'}
                                });
                            }
                            else
                            {
                                toast.error("Internal error. Please try again.", {
                                    position: "bottom-center",
                                    hideProgressBar: true,
                                    progress: undefined,
                                    style:{backgroundColor:'#E0D1FF'}
                                });
                            }
                          }
                          setCancelLoading(false)
                    } 
                    else
                    {        
                        setCancelLoading(false)
                        toast.error("Something went wrong. Please try again later", {
                            position: "bottom-center",
                            hideProgressBar: true,
                            progress: undefined,
                            style:{backgroundColor:'#E0D1FF'}
                        });
            
                    }
                }
            }catch(err){
                console.log('catch block',err);
                setServerError(true);
                // setCancelLoading(false)
                toast.error("Unable to reach server. Please try again later", {
                    position: "bottom-center",
                    hideProgressBar: true,
                    progress: undefined,
                    style:{backgroundColor:'#E0D1FF'}
                })
            }
        
       
    }

    return (
        <div style={{display:'flex', flexDirection:'row', marginBottom:20}}>
            <div className="status_div" style={{backgroundColor: serviceType=='upcoming' ? "#ffc107" : serviceType == 'past' ? ( serviceData.PIN_VALIDATED==1 ? '#5ba85a' : '#ffc107') : '#ff5d5d'}}>
                {/* <img src={Tick_Image} /> */}
            </div>
            <div className="mainDiv_second_col" style={{borderColor:serviceType=='upcoming' ? "#ffc107" : serviceType == 'past' ? ( serviceData.PIN_VALIDATED==1 ? '#5ba85a' : '#ffc107') : '#ff5d5d', backgroundColor: serviceType == 'cancel' ? '#f1f1f1' : '#ffffff', borderBottomLeftRadius:props.serviceType=='upcoming' ? 0 : 0, borderTopLeftRadius:props.serviceType=='upcoming' ? 0 : 0}}>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}} onClick={() => onCardHandler()}>
                    <div style={{display:'flex', flexDirection:'row'}}>
                        <div>
                            <img src={serviceData.image_link} className="category_imageStyle" style={{borderRadius:10}}/>
                        </div>
                    
                        <div className="details_div_style">
                            <text className="detail_header_text_style">{serviceData.QF_MERCHANT_OFFER_PRID ? serviceData.MERCHANT_OFFER_HEADER_NAME : serviceData.REC_SERVICE_NAME}</text><br />
                            <text className="detail_desc_text_style">{serviceData.MERCHANT_NAME}</text>
                            <div>
                                <img src={location_Icon} />
                                <text className="detail_desc_text_style">{subLocality}{', '}{serviceData.MERCHANT_LOCATION_ADDRESS_CITY}</text>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'end'}}>
                    {serviceData.QF_MERCHANT_OFFER_PRID ?
                            <text className="details_price_pin_text">{serviceData.MERCHANT_OFFER_CURRENCY=="Rupees" ? '\u20B9' : '$'}{numeral(serviceData.NET_PRICE).value().toFixed(2)}</text>
                            :
                            <text className="details_price_pin_text">{serviceData.REC_SERVICE_CURRENCY=="Rupees" ? '\u20B9' : '$'}{numeral(serviceData.NET_PRICE).value().toFixed(2)}</text>
                        }
                        
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                            {serviceType=='upcoming' ?
                                <div>
                                    <img src={Time_Counter} />
                                    <text className="detail_highlighted_text" style={{color:serviceType=='upcoming' ? "#ffc107" : serviceType == 'past' ? ( serviceData.PIN_VALIDATED==1 ? '#5ba85a' : '#ffc107') : '#ff5d5d'}}> (Upcoming Booking) </text>
                                </div>
                            : serviceType=='past' ?
                                <div>
                                    {serviceData.PIN_VALIDATED==1 ?
                                        <img src={Time_Counter_Past1} />
                                        :
                                        <img src={Time_Counter} />
                                    }
                                    <text className="detail_highlighted_text" style={{color: serviceType=='upcoming' ? "#ffc107" : serviceType == 'past' ? ( serviceData.PIN_VALIDATED==1 ? '#5ba85a' : '#ffc107') : '#ff5d5d'}}>{serviceData.PIN_VALIDATED? 'Booking Completed' : 'Booking yet not completed'} </text>
                                </div>
                            :
                                <div>
                                    <img src={Tick_Confirm_Icon}/>
                                    <text className="detail_highlighted_text" style={{color: serviceType=='upcoming' ? "#ffc107" : serviceType == 'past' ? ( serviceData.PIN_VALIDATED==1 ? '#5ba85a' : '#ffc107') : '#ff5d5d'}}> (Cancellation Confirmed) </text>
                                </div>
                            }
                            {serviceType == 'upcoming' ? 
                                <div>
                                    <text className="details_price_pin_text">PIN-{serviceData.OFFER_ACCEPTANCE_PIN}</text>
                                </div>
                            : null}
                        </div>
                    </div>
                </div>

                <hr />


                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        {props.serviceType=='upcoming' ?
                            <img src={Calendar_Icon}/>
                            :
                            <img src={Calendar_Icon_grey}/>
                        }
                        {serviceData.QF_MERCHANT_OFFER_PRID ? 
                            <text className="booking_date_text" style={{color: props.serviceType=='upcoming' ? '#8c52ff' : '#747474'}}>{serviceData.MERCHANT_OFFER_TIMEZONE?momenttz(serviceData.MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(serviceData.MERCHANT_OFFER_TIMEZONE).format("DD MMM YYYY"):""}</text>
                            :
                            <text className="booking_date_text" style={{color: props.serviceType=='upcoming' ? '#8c52ff' : '#747474'}}>{serviceData.REC_SERVICE_TIMEZONE?momenttz(serviceData.BOOKING_FROM_DATE_TIME+"Z").tz(serviceData.REC_SERVICE_TIMEZONE).format("DD MMM YYYY"):""}</text>
                        }
                    </div>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <div>
                            <img src={Timer_Icon} />
                            {serviceData.QF_MERCHANT_OFFER_PRID ?
                                <text className="booking_time_text">{serviceData.MERCHANT_OFFER_TIMEZONE ? momenttz(serviceData.MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(serviceData.MERCHANT_OFFER_TIMEZONE).format("hh:mm A"): ''} - {serviceData.MERCHANT_OFFER_TIMEZONE?momenttz(serviceData.MERCHANT_OFFER_TO_DATE_TIME+"Z").tz(serviceData.MERCHANT_OFFER_TIMEZONE).format("hh:mm A z"):''}</text>
                                :
                                <text className="booking_time_text">{serviceData.REC_SERVICE_TIMEZONE ? momenttz(serviceData.BOOKING_FROM_DATE_TIME+"Z").tz(serviceData.REC_SERVICE_TIMEZONE).format("hh:mm A"): ''} - {serviceData.REC_SERVICE_TIMEZONE?momenttz(serviceData.BOOKING_TO_DATE_TIME+"Z").tz(serviceData.REC_SERVICE_TIMEZONE).format("hh:mm A z"):''}</text>
                            }
                        </div>

                        {(serviceData.REC_SERVICE_CANCEL_ALLOWED && moment(moment().tz(serviceData.REC_SERVICE_TIMEZONE).format("YYYY-MM-DD HH:mm:ss")).isBefore(momenttz(serviceData.BOOKING_FROM_DATE_TIME + "Z").tz(serviceData.REC_SERVICE_TIMEZONE).subtract(serviceData.REC_SERVICE_CANCEL_ALLOWED_DAYS, 'days').format("YYYY-MM-DD HH:mm:ss")) && serviceData.PIN_VALIDATED !== 1) ?
                            props.serviceType=='upcoming' ?
                                <div className="cancel_booking_style" onClick={() => onPressCancelHandler()}>
                                    <text className="cancel_button_text">Cancel Booking</text>
                                </div>
                            : null
                        :null}
                    </div>
                </div>

                {props.serviceType=='upcoming' || props.serviceType=='cancel' ? null : 
                    serviceData.PIN_VALIDATED
                    ?
                    <hr /> 
                    : null
                }

                {props.serviceType=='upcoming' || props.serviceType=='cancel' ? null :
                    serviceData.PIN_VALIDATED
                    ?
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                            <text className="detail_header_text_style">Rate:</text>
                            <Rating 
                                initialValue={props.ratingNumber}
                                // readonly
                                size={25}
                                disabled={serviceData.SERVICE_RATING === null ? false : true}
                                maxStars={5}
                                emptyStarColor='#563951'
                                fullStarColor='#ff9f02'
                                className="rating_starContainer"
                                starStyle={{backgroundColor:'#ffffff',marginLeft:10}}
                                rating={props.starCount}
                                starSize={16}
                                onClick={(rating) => onStarRatingPress(rating, serviceData.QF_CONSUMER_OFFER_ACCEPTED_PRID)}
                            />
                            {/* <img src={Rating_star_icon} /> */}
                        </div>
                        {/* <div className="booking_again_style">
                            <img src={Rebook_Icon} />
                            <text className="booking_again_text">Book again</text>
                        </div> */}
                    </div>
                    : null
                }
            </div>

            <Modal isOpen={detailsPage} size='md'>
                {serviceType == 'upcoming' ?
                    <UpcomingBookingDetailsPage data={serviceData} onClick={onCancelCardHandler} onCancelBooking={props.onCancelBooking}/>
                : serviceType == 'past' ?
                    <PastBookingDetailsPage data={serviceData} onClick={onCancelCardHandler}/>
                : serviceType == 'cancel' ?
                    <CancelledBookingDetailsPage data={serviceData} onClick={onCancelCardHandler}/>
                : null}
            </Modal>

            <Modal isOpen={cancelSuccessModal} size="sm">
                    <div className="booking_cancel_modal_main_div">
                        <div className="booking_cancel_modal_first_div">
                            <div>
                                <text className="booking_cancel_modal_headerTextStyle">Are you sure you want to Cancel Booking?</text>
                            </div>

                            {/* <div className="cross_icon_div" onClick={() => setCancelSuccessModal(false)}>
                                <img src={Cross_Icon} />
                            </div> */}
                            <Close_Cross onClick={() => setCancelSuccessModal(false)}/>
                        </div>

                        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', padding:'16px 0'}}>
                            {/* <text style={[styles.subHeaderTextStyle]}>{'Extra cancellations charges* of ' + (serviceData.REC_SERVICE_CURRENCY=="Rupees" ? '\u20B9' : '$') + (serviceData.REC_SERVICE_CANCEL_FEE) + ' may apply'}</text> */}
                            <text className="subHeaderTextStyle">Extra cancellations charges* of {serviceData.REC_SERVICE_CURRENCY=="Rupees" ? '\u20B9' : '$'}{serviceData.REC_SERVICE_CANCEL_FEE} may apply</text>
                            <text className="subHeaderTextStyle" style={{fontSize:10,paddingTop:4}}>See the Cancellation Policy</text>
                        </div>

                        <div style={{display:'flex', flexDirection: 'row', justifyContent: "space-between"}}>
                            
                            <SubmitButtonSolid buttonTitle="Cancel Booking" 
                                onClick={()=>onPressCancelConfirm(serviceData)}
                                buttonColor="rgba(229, 228, 228, 0.4)"
                                buttonTextColor="#ff7488"
                            />

                            <SubmitButtonSolid buttonTitle="Keep Booking" 
                                onClick={() => setCancelSuccessModal(false)}
                                buttonColor="#8c52ff"
                                buttonTextColor="#ffffff"
                            />
                        </div>
                    </div>
            </Modal>
            <ToastContainer />
        </div>
    )

}