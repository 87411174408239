import React, { useState, useEffect, useContext } from 'react';
import '../CSS/TermsAndPolicies.css'
import { Container, Col, Row, Card, CardBody, CardText, NavLink, Nav, Navbar } from 'reactstrap';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import { NavLink as NavLinkReactor, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const TermsAndPolicies = ({ navigation, route }) => {
    const [termsPolicy, SetTermsPolicy] = useState([])
    const [privacyPolicy, SetPrivacyPolicy] = useState([])
    const [docs, SetDocs] = useState('terms')
    const [terms, SetTerm] = useState(true)
    const [privacy, SetPrivacy] = useState(false)
    const [loading, setLoading] = useState(false)
    const [onClickTermsText, setOnClickTermsText] = useState(false)
    const [onClickPrivacyText, setOnClickPrivacyText] = useState(false)
    const location = useLocation();

    const urlPath = location.pathname;
    useEffect(() => {
        getPoliciesTerms()
        // SetTerm(location.state.docs == 'terms'? true : false )
        // SetPrivacy(location.state.docs == 'policy'? true : false )
        // console.log('params in termssss',location.state.docs)
        // console.log('in use effect',location.hash)
        // if (location.state.docs) {
        //     if (location.state.docs == 'terms' || urlPath === '/terms&conditions') {

        //         SetTerm(true)
        //         SetPrivacy(false)
        //         SetDocs('terms')

        //     }
        //     else {
        //         SetPrivacy(true)
        //         SetTerm(false)
        //         SetDocs('privacy')

        //     }
        // }
     
        if(urlPath==='/terms&conditions'){
            console.log('terms in path')
            SetTerm(true)
            SetPrivacy(false)
            SetDocs('terms')
            // if(privacy){
            //     SetTerm(false)
            // }


        }
        else if(urlPath==='/privacy&policy'){
            console.log('privacy in path')
            SetPrivacy(true)
            SetTerm(false)
            SetDocs('privacy')
            
            // const element = document.getElementById('PrivacyId');
            // if (element) {
            //   // 👇 Will scroll smoothly to the top of the next section
            //   element.scrollIntoView({ behavior: 'smooth' });
            // }
        }



    }, [location])

    const onClickTerms = () => {
        console.log('in onClick terms')
        setOnClickTermsText(true)
        SetDocs('terms')
        SetTerm(true)
        SetPrivacy(false)
    }

    const onClickPrivacy = () => {
        setOnClickPrivacyText(true)
        SetDocs('policy')
        console.log('in onClick privacy')
        SetPrivacy(true)
        SetTerm(false)
        // docs='privacy'

    }
    console.log('terms and privacy', terms, privacy)
    const activeNavHeader = {
        borderBottom: '3px solid #a5d028',
        color: "#261640",
        fontWeight: 900,
        fontFamily: 'Mulish'
    }
    const getPoliciesTerms = () => {
        // console.log('country',this.props.country)
        let body = {
            // policy: 'Privacy',
            docs: 'Consumer',
            country: 'IN'
        }
        setLoading(true)
        axios.post('/website/currentPolicy/New', body)

            .then(res => {
                if (res.data.data) {
                    // console.log('res', res.data)
                    SetTermsPolicy(res.data.result[0].QF_BODY)
                    SetPrivacyPolicy(res.data.result1[0].QF_BODY)
                    setLoading(false)
                    // this.setState({
                    //     termsPolicy: res.data.result[0].QF_BODY,
                    //     privacyPolicy: res.data.result1[0].QF_BODY,
                    //     loading: false
                    // })
                }
                else {
                    if (res.data.dbError) {
                        console.log("db error");
                        // this.setState({
                        //     termsPolicy: '',
                        //     privacyPolicy: '',
                        //     loading:false
                        // })
                        SetTermsPolicy([])
                        SetPrivacyPolicy([])

                    }
                    else {
                        console.log("not found")
                        // this.setState({
                        //     termsPolicy: '',
                        //     privacyPolicy: '',
                        //     loading:false
                        // })
                        SetTermsPolicy([])
                        SetPrivacyPolicy([])

                    }
                }
            })
            .catch(err => {
                console.log("error", err);
                SetTermsPolicy([])
                SetPrivacyPolicy([])
                // toast.error('Server Error', {
                //     position: toast.POSITION.BOTTOM_CENTER
                // })
            })



    }

    // console.log('term and privacy',terms,privacy)
    return (
        <div id={'PrivacyId'} style={{ paddingTop: 80 }} >
            <Helmet>
                <title>Learn about Slash's privacy policy and how we safeguard your personal information. Your privacy is important to us.</title>
                <meta name="description" content="Slash privacy policy, data protection, personal information, user privacy, terms and conditions, service guidelines, user agreement, home services, Hyderabad" />
            </Helmet>

            {/* <Helmet>
                <title>Read the terms and conditions for using Slash's home services. Understand your rights and responsibilities as a customer or service provider.</title>
                <meta name="description" content="Slash terms and conditions, service guidelines, user agreement, home services, Hyderabad" />
            </Helmet> */}
            <div className='mainHedder'><text>Our Terms and Policies</text></div>
            <div className='termsdiv'>
                <div className='termText'>
                    <div className='test' style={{ textDecoration: 'none', color: terms ? '#461c99' : '#747474',cursor:'pointer' }} to='/' onClick={() => onClickTerms()}>
                        <text>Terms & Conditions</text>
                    </div>
                    {terms ? <div className='line'></div> : null}
                </div>
                <div className='termText'>
                    <div className='test' style={{ textDecoration: 'none', color: privacy ? '#461c99' : '#747474',cursor:'pointer' }} onClick={() => onClickPrivacy()}>
                        <text>Privacy & Policy</text>
                    </div>
                    {privacy ? <div className='line'></div> : null}
                </div>
            </div>

            <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: 55, alignItems: 'left' }}>

                <div className='maindivline' />
                {loading ?
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: 150 }}>
                        <ClipLoader widthUnit={"%"} size={40} loadings={true} color="#8c52ff" />
                    </div>
                    :
                    <div>
                        {terms ?
                            <div dangerouslySetInnerHTML={{ __html: termsPolicy }} />
                            :
                            <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
                        }
                    </div>}

            </Container>

        </div>
    );
}

export default TermsAndPolicies;
