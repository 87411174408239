import React from 'react'
import '../../CSS/DownloadOurApp.css'
import AppStore from '../../../New_WebPage_Design/Assets/Commons/appstore 1.png';
import GooglePlay from '../../../New_WebPage_Design/Assets/Commons/googleplay 1.png';
export default function DownloadOurApp_Mobile(props) {
  return (
    <div>
        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <div className='downloadNow_MobImage'>
                <img src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684342853213-lg.png'} style={{height:250,width:250}}/>
            </div>
        </div>
        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:20}}>
            <text className='downloadOurApp_MainTextHome'>Download Our App</text>
            <text className='downloadOurApp_subText'>Start enjoying hassle-free services that's just a click away!</text>
        </div>
        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:20}}>
            {/* <text className='downloadOurApp_fromText'>Download app from</text> */}
            <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginTop:8}}>
                  <a href={props.link ? props.link :'https://play.google.com/store/apps/details?id=com.quickfinduser&utm_campaign=Consumer_Guide&utm_source=Website&utm_medium=DigitalMarketingSources'} target="_blank">
                      <img src={GooglePlay} className='downloadOurApp_googlePlay' />
                  </a>
                  <a href={props.link2 ? props.link2:'https://apps.apple.com/us/app/slash-consumer/id1489207325?ls=1/?utm_campaign=Consumer_Guide&utm_source=Website&utm_medium=DigitalMarketingSources'} target="_blank">
                      <img src={AppStore} className='downloadOurApp_appStore' />
                  </a>
            </div>
        </div>
    </div>
  )
}
