import React, { Component, Text, useState, useRef,useEffect,useContext } from 'react'
import { Navbar, NavbarBrand, Nav, NavItem, Row, NavbarToggler, Collapse, Col, Modal, FormGroup, Input, Button, FormFeedback ,NavLinkReactor} from 'reactstrap';
import logo from '../../../Assets/slashlogo2purple.png'
import '../../../CSS/NavBarLatest.css'
import { NavLink } from 'react-router-dom';
import favouriteLogo from '../../../Assets/icon-favorite-border.png'
import cartLogo from '../../../Assets/mi-shopping-cart.png'
import wishlist_filled_icon from '../../../Assets/wishlist_filled_color_icon.png'
import cart_filled_icon from '../../../Assets/checkout_filled_color_icon.png'
import profile_filled_icon from '../../../Assets/profile_filled_color_icon.png'
import profileLogo from '../../../Assets/group-482319@2x.png'
import profileLogo1 from '../../../Assets/asicon-outline-user.png'
import favBlack from '../../../Assets/favBlck.png'
import shpCrtblk from '../../../Assets/shpCrtblk.png'
import picPro from '../../../Assets/picPro.png'
import { useLocation } from "react-router";
import slashTran from '../../../Assets/slashLogo1white.png'
import { globalState } from '../../../GlobalState/globalState';
import { ToastContainer, toast } from 'react-toastify';
import { authLogOut } from '../../../GlobalState/globalStateActions';
import { useNavigate } from 'react-router-dom';
import customFetchData from '../customFetchData';
import RegisstrAndLogin_India from '../../../Screens/RegisterrAndLogin_India';
import {mobileHeaderNav} from '../NavPaths';
import NavPaths from '../NavPaths';


export default function NavBarV2(props){
      const navigate = useNavigate();
      let location = useLocation();
      let [state, dispatch] = useContext(globalState)
      const [country, setCoutry] = useState('India')
      const [stateColor, setStateColor] = useState()
      const [modal, setModal] = useState(false)
      const [pathnameLink, setPathnameLink] = useState('')
   // onClick={()=>navigate('/professionalUnderCategory'
    const toggle = () =>{
      // if(country === 'India')
      //    navigate('/login/india')
      //    else
      //    navigate('/login/us')
      props.loginHandle()
    }
   //  useEffect(()=>{
   //    if(location.pathname='/areYouPro' ){
   //       setStateColor('#fff')
   //    }
   //  })

   const path = '/'+location.pathname.split('/')[1]

  useEffect(()=>{
    
   // if(window.pageYOffset == 0)
   // setStateColor("#fff")

   console.log("Navbar", location)

   if(path == "/" || path == "/home"){

      setPathnameLink('home')

    } else if(path == "/blog"){

      setPathnameLink('blog')

    } else if(path == "/businesslisting"){

      setPathnameLink('professional')

    } else if(path == "/support"){

      setPathnameLink('help')

    } else if(path == "/offers"){

      setPathnameLink('offers')

    } else if(path == "/c/mybookings"){

      setPathnameLink('bookings')

    } else if(path == "/wishlist"){

      setPathnameLink('wishlist')

    } else if(path == "/checkout"){

      setPathnameLink('checkout')

    } else if(path == "/profile" || path == "/c/deleteAccount"  || path == "/c/savedAddresses" || path == "/setting"  || path == "/referandearn" || path == "/ConsumerEditProfile" || path == "/c/myTransactions"){

      setPathnameLink('profile')

    } else {

      // setPathnameLink('Home')

    }

  },[])


   useEffect(() => { 

      !NavPaths.includes(path) ? setStateColor('#fff') :  setStateColor('transparent')
       window.scrollTo(0, 0);
   },[location.pathname])


   function listenScrollEvent()  {
      const backgroundcolor = (window.scrollY  == 0 &&  NavPaths.includes(path))  ? "transparent" : "#fff";
      setStateColor(backgroundcolor);
   }


   useEffect(() => {
      window.addEventListener('scroll', listenScrollEvent);
      return () =>
        window.removeEventListener('scroll', listenScrollEvent);
    }, [NavPaths.includes(path)]);
    
   
   //  console.log('pathname',location.pathname.split('/'))
   //  console.log('pathname11','/'+location.pathname.split('/')[1])

    
   return(
      <>
       <Navbar light expand="lg" className="navbar_main_new" style={{backgroundColor:stateColor,boxShadow: stateColor == 'transparent' ?  null : '0 4px 4px 0 rgba(0, 0, 0, 0.1)',position: 'fixed',top:0,overflow:'hidden'}}>
        <NavbarBrand  style={{width:132,marginLeft:'5%',marginTop:32}}> <NavLink onClick={() => setPathnameLink('home')} to="/"><img  height={36} src={stateColor == '#fff' ? logo : slashTran }/></NavLink></NavbarBrand>
        {state.token ? <Nav className="afterLogin_div me-auto" navbar style={{flexDirection:'row',marginTop:30,justifyContent:'space-between'}}  >
            <NavItem className={({isActive, isPending}) => isActive ? 'navbar_navbarItem_active_style' : 'navbar_navbarItem_style'}>
               <NavLink onClick={() => setPathnameLink('blog')} className={({isActive, isPending}) => isActive ? 'blog_navlink_active' : 'blog_navlink_af'} style={{color: stateColor != '#fff' ?'#fff':pathnameLink == 'blog' ? '#8c52ff' : 'black',borderBottomColor: stateColor != '#fff' ?'#fff':pathnameLink == 'blog' ? '#8c52ff' : ''}} to="/blog">Blog</NavLink>
            </NavItem>
            <NavItem className={({isActive, isPending}) => isActive ? 'navbar_navbarItem_active_style' : 'navbar_navbarItem_style'}>
               <NavLink onClick={() => setPathnameLink('professional')} className={({isActive, isPending}) => isActive ? 'Are_you_pro_navlink_active' : 'Are_you_pro_navlink_af'} style={{color: stateColor != '#fff' ?'#fff':pathnameLink == 'professional' ? '#8c52ff' : 'black',borderBottomColor: stateColor != '#fff' ?'#fff':pathnameLink == 'professional' ? '#8c52ff' : ''}}  to="/businesslisting">Are you a professional?</NavLink>
            </NavItem>
            <NavItem className={({isActive, isPending}) => isActive ? 'navbar_navbarItem_active_style' : 'navbar_navbarItem_style'}>
               <NavLink onClick={() => setPathnameLink('help')} className={({isActive, isPending}) => isActive ? 'help_navlink_active' : 'help_navlink'} style={{color: stateColor != '#fff' ?'#fff':pathnameLink == 'help' ? '#8c52ff' : 'black',borderBottomColor:stateColor != '#fff' ?'#fff':pathnameLink == 'professional' ? '#8c52ff' : '',fontSize:14,fontFamily:'Inter',marginLeft:28,textDecoration:'none'}}  to="/support">Help</NavLink>
            </NavItem>
              <NavItem className={({isActive, isPending}) => isActive ? 'navbar_navbarItem_active_style' : 'navbar_navbarItem_style'}>
               <NavLink onClick={() => setPathnameLink('offers')} className={({isActive, isPending}) => isActive ? 'offers_navlink_active' : 'offers_navlink'} style={{color: stateColor != '#fff' ?'#fff':pathnameLink == 'offers' ? '#8c52ff' : 'black',borderBottomColor: stateColor != '#fff' ?'#fff':pathnameLink == 'offers' ? '#8c52ff' : '',fontSize:14,fontFamily:'Inter',marginLeft:28,textDecoration:'none'}} to="/offers" state={{}}>Offers</NavLink>
            </NavItem>
            <NavItem className={({isActive, isPending}) => isActive ? 'navbar_navbarItem_active_style' : 'navbar_navbarItem_style'}>
               <NavLink onClick={() => setPathnameLink('bookings')} className={({isActive, isPending}) => isActive ? 'bookings_navlink_active' : 'bookings_navlink_af'} style={{color: stateColor != '#fff' ?'#fff':pathnameLink == 'bookings' ? '#8c52ff' : 'black',borderBottomColor: stateColor != '#fff' ?'#fff':pathnameLink == 'bookings' ? '#8c52ff' : ''}} to="/c/mybookings">My Bookings</NavLink>
            </NavItem>
            {/* <NavItem>
               <NavLink style={{ backgroundColor: '#8C52FF', borderRadius: 10, paddingLeft: 10, paddingRight: 10, color: '#FFFFFF', cursor: 'pointer' ,fontSize:14 }} onClick={toggle} > Log in/Register</NavLink>
            </NavItem> */}
            </Nav> : null}

        { !state.token ? 
        
         <div style={{marginRight:'5%',marginTop:28}} className='beforeLogin_div me-auto'>
            <Nav  navbar style={{flexDirection:'row'}} >
               <NavItem className={({isActive, isPending}) => isActive ? 'blog_navlink_active' : 'blog_navlink'} style={{ marginRight: 25 }}>
                  <NavLink onClick={() => setPathnameLink('blog')} className={({isActive, isPending}) => isActive ? 'blog_navlink_active' : 'blog_navlink'} style={{ color: stateColor != '#fff' ?'#fff':pathnameLink == 'blog' ? '#8c52ff' : 'black',borderBottomColor:stateColor != '#fff' ?'#fff':pathnameLink == 'blog' ? '#8c52ff' : '', fontSize:14,marginLeft:28,fontFamily:'Inter',textDecoration:'none'}} to="/blog">Blog</NavLink>
               </NavItem>
               <NavItem className={({isActive, isPending}) => isActive ? 'navbar_navbarItem_active_style' : 'navbar_navbarItem_style'} style={{ marginRight: 25 }}>
                  <NavLink onClick={() => setPathnameLink('professional')} className={({isActive, isPending}) => isActive ? 'Are_you_pro_navlink_active' : 'Are_you_pro_navlink'} style={{  color: stateColor != '#fff' ?'#fff':pathnameLink == 'professional' ? '#8c52ff' : 'black',borderBottomColor:stateColor != '#fff' ?'#fff':pathnameLink == 'professional' ? '#8c52ff' : '',fontSize:14,fontFamily:'Inter',textDecoration:'none'}} to="/businesslisting">Are you a professional?</NavLink>
               </NavItem>
               <NavItem className={({isActive, isPending}) => isActive ? 'navbar_navbarItem_active_style' : 'navbar_navbarItem_style'} style={{ marginRight: 25, }}>
                  <NavLink onClick={() => setPathnameLink('help')} className={({isActive, isPending}) => isActive ? 'offers_navlink_active' : 'offers_navlink'} style={{  color: stateColor != '#fff' ?'#fff':pathnameLink == 'help' ? '#8c52ff' : 'black',borderBottomColor: stateColor != '#fff' ?'#fff':pathnameLink == 'help' ? '#8c52ff' : '',fontSize:14,fontFamily:'Inter',textDecoration:'none' }} to="/support">Help</NavLink>
               </NavItem>
               <NavItem className={({isActive, isPending}) => isActive ? 'navbar_navbarItem_active_style' : 'navbar_navbarItem_style'} style={{ marginRight: 25,fontFamily:'Inter' }}>
                  <NavLink onClick={() => setPathnameLink('offers')} className={({isActive, isPending}) => isActive ? 'bookings_navlink_active' : 'bookings_navlink'} style={{  color: stateColor != '#fff' ?'#fff':pathnameLink == 'offers' ? '#8c52ff' : 'black',borderBottomColor: stateColor != '#fff' ?'#fff':pathnameLink == 'offers' ? '#8c52ff' : '',fontSize:14,fontFamily:'Inter',textDecoration:'none'}} to="/offers" state={{}}>Offers</NavLink>
               </NavItem>
               <NavItem>
                  <NavLink onClick={()=> {setPathnameLink('login'); setModal(!modal)}} style={{ backgroundColor: '#8C52FF',fontFamily:'Inter' , borderRadius: 10, color: '#FFFFFF', cursor: 'pointer' ,fontSize:14,paddingTop:10,paddingBottom:11,paddingLeft:20,paddingRight:13,textDecoration:'none' }}> Log in/Register</NavLink>
               </NavItem>
            </Nav>
         </div> : null }

         {state.token ?
         <Nav className='afterLogin_div2' style={{display:'flex', flexDirection:'row',marginRight:'6%',justifyContent:'space-between',gap:'1%'}} navbar  >
            
            {/* <NavItem style={{marginTop:5,marginLeft:8,marginRight:'2%'}} >
               <NavLink onClick={() => setPathnameLink('wishlist')} className={({isActive, isPending}) => isActive ? 'wishlist_navlink_active' : 'wishlist_navlink'} style={{display:'flex',justifyContent:'flex-start', color: stateColor != '#fff' ?'#fff':pathnameLink == 'wishlist' ? '#8c52ff' : 'black',borderBottomColor:stateColor != '#fff' ?'#fff':pathnameLink == 'wishlist' ? '#8c52ff' : '#fff',fontSize:14 ,marginRight:'1%',fontFamily:'Inter',textDecoration:'none'}} to="/wishlist">
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start'}}>
                     <img className="image_wishlist" style={{marginTop:0,marginRight:8}} src={stateColor != '#fff' ? pathnameLink == 'wishlist' ? wishlist_filled_icon : favouriteLogo : pathnameLink == 'wishlist' ? wishlist_filled_icon : favBlack } />
                  </div> WishList
               </NavLink>
            </NavItem> */}
            {/* <img className="image_wishlist" src={ stateColor != '#fff' ?  cartLogo : shpCrtblk}></img> */}
            {/* <NavItem style={{marginTop:5,marginLeft:8,marginRight:'2%'}}>
               <NavLink onClick={() => setPathnameLink('checkout')} className={({isActive, isPending}) => isActive ? 'checkout_navlink_active' : 'checkout_navlink'} style={{display:'flex',justifyContent:'flex-start', color: stateColor != '#fff' ?'#fff':pathnameLink == 'checkout' ? '#8c52ff' : 'black',borderBottomColor:stateColor != '#fff' ?'#fff':pathnameLink == 'checkout' ? '#8c52ff' : '#fff', fontSize:14 ,marginRight:'1%',fontFamily:'Inter',textDecoration:'none'}} to="/checkout">
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start'}}>
                     <img className="image_wishlist" style={{marginTop:0,marginRight:8}} src={ stateColor != '#fff' ? pathnameLink == 'checkout' ? cart_filled_icon : cartLogo : pathnameLink == 'checkout' ? cart_filled_icon : shpCrtblk} />
                     {state.cartCount>0 ? <div style={{height:15,width:15,backgroundColor:'red',borderRadius:50,marginLeft:-12,marginTop:-6}}><text style={{display:'flex',fontSize:10,justifyContent:'center',alignItems:'center',color:'#fff'}}>{state.cartCount}</text></div>:null}
                  </div>
                  Checkout
               </NavLink>
            </NavItem> */}

            {/* <NavItem style={{marginTop:5,marginLeft:8,display:'flex',cursor:'pointer'}}>
               <NavLink onClick={() => setPathnameLink('profile')} className={({isActive, isPending}) => isActive ? 'profile_navlink_active' : 'profile_navlink'} style={{display:'flex',justifyContent:'flex-start', color: stateColor != '#fff' ?'#fff':pathnameLink == 'profile' ? '#8c52ff' : 'black',borderBottomColor:stateColor != '#fff' ?'#fff':pathnameLink == 'profile' ? '#8c52ff' : '#fff', fontSize:14 ,marginRight:'1%',fontFamily:'Inter',textDecoration:'none'}} to={`/profile/${'editProfile'}`}>
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start'}}>
                     <text>Download the App now</text>
                  </div>
               </NavLink>
            </NavItem> */}

            <NavItem style={{marginTop:5,marginLeft:8,display:'flex',cursor:'pointer'}}>
               <NavLink onClick={() => setPathnameLink('profile')} className={({isActive, isPending}) => isActive ? 'profile_navlink_active' : 'profile_navlink'} style={{display:'flex',justifyContent:'flex-start', color: stateColor != '#fff' ?'#fff':pathnameLink == 'profile' ? '#8c52ff' : 'black',borderBottomColor:stateColor != '#fff' ?'#fff':pathnameLink == 'profile' ? '#8c52ff' : '#fff', fontSize:14 ,marginRight:'1%',fontFamily:'Inter',textDecoration:'none'}} to={`/profile/${'editProfile'}`}>
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start'}}>
                     <img className="image_wishlist" style={{marginTop:0,marginRight:8}} src={stateColor != '#fff' ? pathnameLink == 'profile' ? profile_filled_icon : profileLogo1 : pathnameLink == 'profile' ? profile_filled_icon : picPro} />
                  </div> 
                  {state.user.username ? state.user.username : 'Profile'}
               </NavLink>
            </NavItem>
            </Nav>
            
            :null}
            {/* {state.cartCount>0 ? <div style={{height:15,width:15,backgroundColor:'red',borderRadius:50,marginLeft:-12,marginTop:-6}}><text style={{display:'flex',fontSize:10,justifyContent:'center',alignItems:'center',color:'#fff'}}>{state.cartCount}</text></div>:null} */}
            {/* <img onClick={() => setPathnameLink('profile')} style={{height:20,width:20,marginTop:0,marginRight:8}} src={stateColor != '#fff' ? pathnameLink == 'profile' ? profile_filled_icon : profileLogo1 : pathnameLink == 'profile' ? profile_filled_icon : picPro}></img>
            <NavLink onClick={() => setPathnameLink('profile')} to="/profile" style={{ color: stateColor != '#fff' ?'#fff':pathnameLink == 'profile' ? '#8c52ff' : 'black',fontSize:14,fontFamily:'Inter',textDecoration:'none' }}>Profile */}
            {/* <img className='profile_logo' style={{height:35,width:35,marginTop:10,backgroundColor:"transparent",marginRight:'5%',position:'absolute',right:0}} src={profileLogo}></img> */}
            {/* { true ? <div style={{marginRight:'5%',marginTop:28}} className='mobileNav me-auto'>
           <Nav  navbar style={{flexDirection:'row'}} >
           <img className="image_wishlist_mobile"  src={favouriteLogo}></img>
           <img className="image_wishlist_mobile" src={cartLogo}></img>
         </Nav>

         </div> : null } */}
        {/* {!state.token ? <div style={{display:'flex',flexDirection:'row',listStyle:'none',marginRight:'6%',marginTop:20}}>
          
            <NavItem style={{marginTop:5,marginLeft:8,}}>
               <NavLink onClick={() => setPathnameLink('checkout')} className={({isActive, isPending}) => isActive ? 'checkout_navlink_active' : 'checkout_navlink'} style={{display:'flex',justifyContent:'flex-start', color: stateColor != '#fff' ?'#fff':pathnameLink == 'checkout' ? '#8c52ff' : 'black',borderBottomColor: stateColor != '#fff' ?'#fff':pathnameLink == 'checkout' ? '#8c52ff' : '#fff', fontSize:14 ,marginRight:'1%',fontFamily:'Inter',textDecoration:'none'}} to="/checkout">
               <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start'}}>
                  <img className="image_wishlist" style={{marginTop:0,marginRight:8}} src={ stateColor != '#fff' ? pathnameLink == 'checkout' ? cart_filled_icon : cartLogo : pathnameLink == 'checkout' ? cart_filled_icon : shpCrtblk} />
                  {state.cartCount>0? <div style={{height:15,width:15,backgroundColor:'red',borderRadius:50,marginLeft:-12,marginTop:-6}}><text style={{display:'flex',fontSize:10,justifyContent:'center',alignItems:'center',color:'#fff'}}>{state.cartCount}</text></div>:null}
               </div>
                  Checkout
               </NavLink>
            </NavItem>
            </div> :null} */}
           { modal && <RegisstrAndLogin_India closeModal={()=>setModal(!modal)}  />}
      </Navbar>
  


{/*           
<div>
        <div style={{
        position: 'fixed',
    top: 0,
    left:'auto',
    right:0,
    height: '100%',
    // overflowY: 'scroll',
    backgroundColor: '#000000',
    transition: 'transform .3s ease-out',
    zIndex: 10001,
    transform: 'translateZ(0)'}}
 >  </div>

 <div style={{position:'relative',zIndex:1000}}>modal</div>
 <div style={{    paddingLeft: 40,paddingRight:120,width: 522}}></div>

</div> */}

</>




   )
}







// import React, { Component, Text, useState, useRef,useEffect,useContext } from 'react'
// import { Navbar, NavbarBrand, Nav, NavItem, Row, NavbarToggler, Collapse, Col, Modal, FormGroup, Input, Button, FormFeedback ,NavLinkReactor} from 'reactstrap';
// import logo from '../../../Assets/slashlogo2purple.png'
// import '../../../CSS/NavBarLatest.css'
// import { NavLink } from 'react-router-dom';
// import favouriteLogo from '../../../Assets/icon-favorite-border.png'
// import cartLogo from '../../../Assets/mi-shopping-cart.png'
// import profileLogo from '../../../Assets/group-482319@2x.png'
// import profileLogo1 from '../../../Assets/asicon-outline-user.png'
// import favBlack from '../../../Assets/favBlck.png'
// import shpCrtblk from '../../../Assets/shpCrtblk.png'
// import picPro from '../../../Assets/picPro.png'
// import { useLocation } from "react-router";
// import slashTran from '../../../Assets/slashLogo1white.png'
// import { globalState } from '../../../GlobalState/globalState';



// import { useNavigate } from 'react-router-dom';
// import RegisstrAndLogin_India from '../../../Screens/RegisterrAndLogin_India';



// export default function NavBarV2(props){

//    console.log('NavBarV2Props')

//       const navigate = useNavigate();
//       let location = useLocation();
  
//       let [state, dispatch] = useContext(globalState)
//       const [country, setCoutry] = useState('India')
//       const [stateColor, setStateColor] = useState()
//       const [modal, setModal] = useState(false)

//    // onClick={()=>navigate('/professionalUnderCategory'

//     const toggle = () =>{

//       // if(country === 'India')
//       //    navigate('/login/india')
//       //    else
//       //    navigate('/login/us')

//       props.loginHandle()

       
//     }

//    //  useEffect(()=>{

//    //    if(location.pathname='/areYouPro' ){
//    //       setStateColor('#fff')

//    //    }

//    //  })

//     useEffect(()=>{

      

//       if(location.pathname == '/home'){

//          document.addEventListener("scroll", () => {
//             const backgroundcolor = window.scrollY  == 0 ? "transparent" : "#fff";
      
//             setStateColor(backgroundcolor);
//           });

//           if(location.pathname =='/offers'){

//             document.addEventListener("scroll", () => {
//                const backgroundcolor = window.scrollY  == 0 ? 'transparent' : 'transparent';
         
//                setStateColor(backgroundcolor);
//              });
//          }


//          // document.addEventListener("scroll", () => {
//          //    const backgroundcolor = window.scrollY < 70 ? "#fff" : "transparent";
      
//          //    setStateColor(backgroundcolor);
//          //  });

//          // setStateColor("transparent");
//       }
//       if(location.pathname =='/NearByProfessionalMapView'){

//          setStateColor('#fff')

//       }

//       if(location.pathname=='/areYouPro' ){
//          setStateColor('#fff')

//       }
//       else{
//       if(location.pathname == '/wishlist'){

//          setStateColor('#fff')

//       // document.addEventListener("scroll", () => {
//       //    const backgroundcolor = window.scrollY < 70 ? "#fff" : "transparent";
   
//       //    setStateColor(backgroundcolor);
//       //  });
//       }
//      }
       

//     }) 
      
// console.log('modal',modal)
   

//    return(

//        <Navbar light expand="lg" className="navbar_main_new" style={{backgroundColor:stateColor,boxShadow: stateColor == 'transparent' ?  null : '0 4px 4px 0 rgba(0, 0, 0, 0.1)',position: 'fixed',top:0,overflow:'hidden'}}> 
        
//         <NavbarBrand  style={{width:132,marginLeft:'5%',marginTop:32}}> <a href="/home"><img  height={36} src={stateColor == '#fff' ? logo : slashTran }></img></a></NavbarBrand>
//         {state.token ? <Nav className="afterLogin_div me-auto" navbar style={{flexDirection:'row',marginTop:30,justifyContent:'space-between'}}  >
           
//             <NavItem style={{  }}>
//                <NavLink className='blog_navlink' style={{color: stateColor != '#fff' ?'#fff':'black',fontSize:14,fontFamily:'Inter',marginLeft:28,textDecoration:'none'}} to="/home">Blog</NavLink>
//             </NavItem>
//             <NavItem style={{ }}>
//                <NavLink className='Are_you_pro_navlink' style={{color: stateColor != '#fff' ?'#fff':'black',fontSize:14,fontFamily:'Inter',marginLeft:28,textDecoration:'none'}}  to="/areYouPro">Are you a professional ?</NavLink>
//             </NavItem>
//             <NavItem style={{ }}>
//                <NavLink className='help_navlink' style={{color: stateColor != '#fff' ?'#fff':'black',fontSize:14,fontFamily:'Inter',marginLeft:28,textDecoration:'none'}}  to="/HelpAndSupport">Help</NavLink>
//             </NavItem>
//               <NavItem style={{ }}>
//                <NavLink className='offers_navlink' style={{color: stateColor != '#fff' ?'#fff':'black',fontSize:14,fontFamily:'Inter',marginLeft:28,textDecoration:'none'}} to="/offers">Offers</NavLink>
//             </NavItem>
            
//             <NavItem style={{}}>
//                <NavLink  className='bookings_navlink' style={{color: stateColor != '#fff' ?'#fff':'black',fontSize:14,fontFamily:'Inter',marginLeft:28,textDecoration:'none'}} to="/">My Bookings</NavLink>
//             </NavItem>
//             {/* <NavItem>
//                <NavLink style={{ backgroundColor: '#8c52ff', borderRadius: 10, paddingLeft: 10, paddingRight: 10, color: '#ffffff', cursor: 'pointer' ,fontSize:14 }} onClick={tog gle} > Log in/Register</NavLink>
//             </NavItem> */}
//             </Nav> : null}
        
//         { !state.token ? <div style={{marginRight:'5%',marginTop:28}} className='beforeLogin_div me-auto'>
//            <Nav  navbar style={{flexDirection:'row'}} >
//             <NavItem style={{ marginRight: 25 }}>
//                <NavLink style={{ color: stateColor != '#fff' ?'#fff':'black',fontSize:14,marginLeft:28,fontFamily:'Inter',textDecoration:'none'}} to="/home">Blog</NavLink>
//             </NavItem>
//             <NavItem style={{ marginRight: 25 }}>
//                <NavLink style={{  color: stateColor != '#fff' ?'#fff':'black',fontSize:14,fontFamily:'Inter',textDecoration:'none'}} to="/areYouPro">Are you a professional ?</NavLink>
//             </NavItem>
//             <NavItem style={{ marginRight: 25, }}>
//                <NavLink style={{  color: stateColor != '#fff' ?'#fff':'black',fontSize:14,fontFamily:'Inter',textDecoration:'none' }} to="/HelpAndSupport">Help</NavLink>
//             </NavItem>
//               <NavItem style={{ marginRight: 25,fontFamily:'Inter' }}>
//                <NavLink style={{  color: stateColor != '#fff' ?'#fff':'black',fontSize:14,fontFamily:'Inter',textDecoration:'none'}} to="/offers">Offers</NavLink>
//               </NavItem>
//               <NavItem>
              
//                <NavLink style={{ backgroundColor: '#8c52ff',fontFamily:'Inter' , borderRadius: 10, color: '#ffffff', cursor: 'pointer' ,fontSize:14,paddingTop:10,paddingBottom:11,paddingLeft:20,paddingRight:13,textDecoration:'none' }} onClick={()=>setModal(!modal)} > Log in/Register</NavLink>
//                </NavItem>

           
//          </Nav>

//          </div> : null }
//          {state.token ?<Nav className='afterLogin_div2' style={{flexDirection:'row',marginRight:'6%',justifyContent:'space-between',gap:'1%'}} navbar  >
//          <NavLink to="/wishlist"><img className="image_wishlist" image_wishlist src={ stateColor != '#fff' ?  favouriteLogo : favBlack  }></img> </NavLink>
//             <NavItem style={{marginTop:5,marginLeft:8,marginRight:'2%'}} >
//                <NavLink style={{ color: stateColor != '#fff' ?'#fff':'black',fontSize:14,marginRight:'1%',fontFamily:'Inter',textDecoration:'none'}} to="/wishlist">WishList</NavLink>
//             </NavItem>
//             <img className="image_wishlist" src={ stateColor != '#fff' ?  cartLogo : shpCrtblk}></img>
//             <NavItem style={{marginTop:5,marginLeft:8,marginRight:'2%'}}>
//                <NavLink style={{ color: stateColor != '#fff' ?'#fff':'black',fontSize:14 ,marginRight:'1%',fontFamily:'Inter',textDecoration:'none'}} to="/">Checkout</NavLink>
//             </NavItem>
//             <img style={{height:20,width:20,marginTop:10}} src={stateColor != '#fff' ?  profileLogo1 : picPro}></img>
//             <NavItem style={{marginTop:5,marginLeft:8}}>
//                <NavLink style={{ color: stateColor != '#fff' ?'#fff':'black',fontSize:14,fontFamily:'Inter',textDecoration:'none' }} to="/">Admin</NavLink>
//             </NavItem>
//             </Nav>:null}
//             <img className='profile_logo' style={{height:35,width:35,marginTop:10,backgroundColor:"transparent",marginRight:'5%',position:'absolute',right:0}} src={profileLogo}></img>

//             {/* { true ? <div style={{marginRight:'5%',marginTop:28}} className='mobileNav me-auto'>
//            <Nav  navbar style={{flexDirection:'row'}} >
//            <img className="image_wishlist_mobile"  src={favouriteLogo}></img>
//            <img className="image_wishlist_mobile" src={cartLogo}></img>
//          </Nav>
//          </div> : null } */}
//              { modal && <RegisstrAndLogin_India closeModal={()=>setModal(!modal)}  />}
//       </Navbar>  


      
//    )

// }