import React, { useContext, useEffect, useState } from 'react'
import '../../CSS/ReferAndEarnHome.css'
import ReferEarnImage from '../../Assets/Group 1000004884.png'
import InviteFrndsImage from '../../Assets/Group 1000005416.png'
import { useMediaQuery } from 'react-responsive'
import MicIcon from '../../Assets/MicReferEarn.png'
import CopyIcon from '../../Assets/copy_icon.png'
import customFetchData from '../../Components/Custom/customFetchData'
import { globalState } from '../../GlobalState/globalState'
import { ToastContainer, toast } from "react-toastify";
import ShareIcon from '../../Assets/share_icon_refer.png'
import { useNavigate } from 'react-router-dom'
import ShareIconColor from '../../Assets/ShareIconColor.png'
export default function ReferAndEarnHome() {
  const useMobileAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 760px)" })
  const isMobile = useMobileAndBelowMediaQuery()
  const [clipMessage, setClipMessage] = useState('')
  const [state, dispatch]=useContext(globalState);
  const [referralCode, setReferralCode]=useState('')
  let navigate=useNavigate()
  useEffect(()=>{
    if(state.token){
      fetchReferralData()
    }
  },[state.token])
  const fetchReferralData = async () => {
    // console.log("from new scratch")
    let totalEarned = 0;

    let body = {
      consumer_prid: state.user.id,
      country: state.country.country
    }

    let { fetch, fetchedData } = await customFetchData('POST', body, '/website/fetchReferralCode/sv2', state, dispatch)
    console.log('refer earn',fetchedData)
    if (!fetch) {
      toast("Session Expired"); 
    }
    else {
      if (fetchedData.data) {
        setReferralCode(fetchedData.result[0].CONSUMER_REF_CODE)
      }
    }
}
const copyClipHandler = (code) =>{
  navigator.clipboard.writeText(code)
  setClipMessage('Copied to Clipboard')
  toast(
    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      <text>Copied to clipboard</text> 
    </div>,
    {
      position:'bottom-center',
      hideProgressBar: true,
      progress: undefined,
      closeButton:false,
      style:{backgroundColor:'#E0D1FF'}
  });
  // toast('Copied to Clipboard',{ position: toast.POSITION.BOTTOM_CENTER })
  setTimeout (()=>{
      setClipMessage('')
  },1000)
}
  return (
    <div>
      {
        state.token ?
        <div className='ReferAndEarn_MainView'>
          {
            isMobile ? 
            <div style={{position:'relative',cursor:'pointer'}} onClick={()=>navigate('/referandearn',{state:{}})}>
              <div style={{position:'absolute',left:10,top:10,display:'flex',flexDirection:'column',zIndex:10}}>
                <text className='referAFriend_Home_Mob'>Refer a friend</text>
                <text className='inviteAFriend_Home_Mob' style={{marginTop:5}}>Invite Friends & Earn rewards</text>
                {/* <div style={{display:'flex',flexDirection:'row',marginTop:30}}>
                  <div className='invite_Pro_mobile'>
                    <img src={ShareIconColor} style={{width:15,height:15}}/>
                    <text className='inviteText_Box_Mob'>Invite Friend</text>
                  </div>
                  <div className='invite_Pro_mobile' style={{marginLeft:10}}>
                    <img src={ShareIconColor} style={{width:15,height:15}}/>
                    <text className='inviteText_Box_Mob'>Invite Professional</text>
                  </div>
                </div> */}
              </div>
              <img src={MicIcon} style={{width:'100%',height:'100%'}}/>
            </div>
            :
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                <div>
                  <img src={ReferEarnImage} className='referEarn_Img_Home'/>
                </div>
                <div style={{display:'flex',flexDirection:'column',justifyContent:'space-between'}} className="textDiv_HomeRefer">
                  <img src={InviteFrndsImage} className='inviteFrnds_Image'/>
                    <div className='belowText_View_REHome'>
                    <text className='yourRef_CodeText'>Your referral code</text>
                    <div style={{display:'flex',flexDirection:'row',marginTop:10}}>
                      <div className='refeCode_BgColor'>
                        <text className='referalCode_Text_Home'>{referralCode}</text>
                        <img src={CopyIcon} style={{cursor:'pointer'}} onClick={() => copyClipHandler(referralCode)}/>
                      </div>
                      {/* <div className='inviteSectionRefer_Home' style={{marginLeft:20,cursor:'pointer'}}>
                        <img src={ShareIcon} style={{width:15,height:15,paddingBottom:3}}/>
                        <text className='inviteText_Home'>Invite Friend</text>
                      </div>
                      <div className='inviteSectionRefer_Home' style={{marginLeft:20,cursor:'pointer'}}>
                        <img src={ShareIcon} style={{width:15,height:15,paddingBottom:3}}/>
                        <text className='inviteText_Home'>Invite Professional </text>
                      </div> */}
                    </div>
                    </div>
                </div>
              </div>
          }
        </div>
        :
        <div>
          {
            isMobile ?
            <div onClick={()=>navigate('/profile/referandearn',{state:{}})}>
              <img src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684926649176-lg.png'} className='referEarn_Img_Home_Mobile'/>
            </div>
            :
            <div onClick={()=>navigate(`/profile/${'referandearn'}`,{state:{}})} style={{cursor:'pointer'}}>
              <img src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1685601066726-lg.png'} className='referEarn_Img_Home_Mobile'/>
            </div>
          }
        </div>
      }
      <ToastContainer/>
    </div>
  )
}
