import React from 'react'
import { FormGroup, Label, Input, Form } from 'reactstrap'

export default function InputComponent({ textStyle, InputFields }) {
  return <Input {...InputFields} style={{...inputFieldStyle,...textStyle}}/>
  // (
    // <Form>
        {/* <FormGroup> */}
            // <Input {...InputFields} style={{...inputFieldStyle,...textStyle}}/>
        // </FormGroup>
    // </Form>
  // )
}

// To Use Input just pass required input field values with input text style, for reference added this code here
 {/*
 <InputComponent
        InputFields={{
            type:"text",
            onChangeText: ChangeHandler,
            value: inputValue,
            placeholder: "some text",
            onSubmitEditing: () => submitFunc(),
            returnKeyType: "next"
        }}
        textStyle={inputTextStyle}
        /> 
        let inputTextStyle= {
            fontFamily: "Inter",
            fontSize: 12,
            fontWeight: "500",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "left",
            color: "#9f9f9f"
        }
  */} 
let inputFieldStyle={
    height: 43,
    padding: 10,
    width:400,
    borderRadius: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d9d9d9",
    // backgroundColor: "rgb(255,255,255)",
    shadowColor: "rgba(0, 0, 0, 0.2)",
    shadowOffset: {
        width: 0,
        height: 4
    },
    shadowRadius: 50,
    shadowOpacity: 1,
}
