import React from "react";
import '../../../CSS/UI Components/Close_Cross.css'
import Cross_Icon from '../../../Assets/cross_icon.png';


export default function Close_Cross(props){

    const onClickHandler = () => {
        props.onClick()
    }

    return (
        <div className="CrossContainer" onClick={() => onClickHandler()}>
            <img src={Cross_Icon} />
        </div>
    )
}