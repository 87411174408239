import React, { useContext, useEffect, useState } from "react";
import BookingCards from "../../Components/Custom/UI/BookingCards";
import BookingCards_Mobile from "../../Components/Custom/UI/BookingCards_Mobile";
import { useMediaQuery } from "react-responsive"; 
import { globalState } from "../../GlobalState/globalState";
import customFetchData from "../../Components/Custom/customFetchData";
import LoadingSpinner from "../../Components/Custom/UI/LoadingSpinner";


export default function UpcomingBookingsPage(props){

    const MobileAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 760px)" })
    const TabletMediaQuery = () => useMediaQuery({ minWidth: 768, maxWidth: 991 })
    const isTabView = TabletMediaQuery()
    
    const isMobileView = MobileAndBelowMediaQuery();

    const [state, dispatch]=useContext(globalState);

    const [loading, setLoading]=useState(false)
    const [upcomingServices ,setUpcomingServices]=useState([])

    useEffect(() => {
        fetchUpcomingData()
    },[])


    const fetchUpcomingData = async() => {
        try {
            setLoading(true)
            let body={
                user_id:state.user.id,
                timezone: state.country.timezone
            }
            let method = 'POST';
            let url='/website/acceptedoffers2/upcoming/sv2';
            
            const {fetch, fetchedData} = await customFetchData(method, body, url, state, dispatch)
            // console.log("fetchData in upcoming bookings", fetch, fetchedData)
    
            if(fetch){
                if(fetchedData.data){
                    if(fetchedData.results.length!==0){
                        let upcomingServices= fetchedData.results;
                        setUpcomingServices(upcomingServices)
                        setLoading(false)
                    }else{
                        setUpcomingServices([])
                        setLoading(false)
                    }

                } else {
                    setUpcomingServices([])
                    setLoading(false)
                }
            } else {
                console.log("Something went wrong")
            }

        } catch(catch1){
            console.log(catch1);
        }
    }


    let upcomingServiceDetails;
    if(upcomingServices.length !== 0){
        upcomingServiceDetails = upcomingServices.map((item, index) => {
            if(isMobileView){

                return <BookingCards_Mobile serviceType='upcoming' serviceDetails={item} colour="#fed019"
                            serviceBookingType='upcoming'
                            item={item}
                            onCancelBooking={props.onCancelBooking}
                        />

            } else {

                return <BookingCards serviceType='upcoming' serviceDetails={item} colour="#fed019"
                            serviceBookingType='upcoming'
                            item={item}
                            onCancelBooking={props.onCancelBooking}
                        />

            }

        })

    }
    
    

    return (
        <div style={{minHeight:'100vh'}}>
            {upcomingServices.length !== 0 ? upcomingServiceDetails : 
                loading ? 
                    <LoadingSpinner loading={true}/>
                :
                <div style={{minHeight:300, display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <text className="detail_header_text_style">No Upcoming Bookings</text>
                </div>
            }
        </div>
    )
}