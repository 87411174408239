import React from 'react'
import {Card,CardBody,CardImg,CardText,CardImgOverlay,Row,Col} from 'reactstrap'
// import RatingComponent from './RatingComponent'
import '../../../CSS/UI Components/ProfessionalCard_Mobile.css'
// import wishlistIcon from '../../../Assets/wishList_Mobile.png'
import wishlist from '../../../Assets/ph-heart-bold.png'
import { useMediaQuery } from "react-responsive"
import DistanceIcon from '../../../Assets/gis-route.png'
import WishlistYes from '../../../Assets/iconFavorite.png' 
import numeral from 'numeral'
import RatingComponent from './RatingComponent'

export default function ProfessionalCard_Mobile({state, proData, onPressCard, WishlistPress, wishlisted, serviceCount, fromSearch, serviceName, servicePrice, servicePriceCurrency}) {
    // let cityImage='https://qf-bucket.s3.us-east-2.amazonaws.com/Images_New/1575975501048-lg.jpg'url(${cityImage})
  return (
    <Card style={{marginBottom:15}} className='main_view_pro_card_mobile'>
      <div  className="main_view_pro_card_mobile_Img" style={{backgroundSize:'cover',height: "100vh",backgroundRepeat:'no-repeat',backgroundImage: `linear-gradient(to right, #451c95 -14%, rgba(148, 101, 240, 0) 73%),url(${proData.image_link})`}}>
        <div style={{padding:15}}>
          <div style={{display:'flex',flexDirection:'column'}}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <text onClick={onPressCard} className='mer_name_css_mobile'>{proData.MERCHANT_NAME}</text>
            {/* <div>
              <img src={wishlistIcon} style={{ width: 18, height: 18, }} />
            </div> */}
            {state.token ?
            <div onClick={WishlistPress} style={{width: 24, height: 24, padding: 4, borderRadius: 8, backgroundColor: '#fff', alignItems: 'center',display:'flex',justifyContent:'center' }}>
            {
              wishlisted ? <img src={WishlistYes} style={{ width: 16, height: 16, }} /> : <img src={wishlist} style={{ width: 16, height: 16, }} /> 
            }
          </div>
          : null
        }
          </div>
          
            <text onClick={onPressCard} className='address_mobile_style' style={{marginTop:6}}>{proData.MERCHANT_PERSONNEL_ADDRESS_LINE2}</text>
            <text onClick={onPressCard}>
              <img src={DistanceIcon} className='distance_icon_mobile'/>
              <text className='distance_text_mobile' style={{marginLeft:6}}>{proData.SERVICE_CURRENCY == 'Rupees' ? numeral(proData.distance).divide(1000).format('0.00') + ' km away' : numeral(proData.distance).divide(1609.344).format('0.00') + ' mi away'}</text>
            </text>
          </div>
          
          <div onClick={onPressCard} style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:11}}>
            <div className='noOfService_mobile'>
                <text className='serviceNameText_ProCard'>{fromSearch ? (serviceName + "-" + servicePriceCurrency + servicePrice) : serviceCount + (serviceCount > 1 ? " Services" : ' Service')}</text>
            </div>
            <div>
                <RatingComponent rating={numeral(proData.MERCHANT_PERSONNEL_RATING).format('0.0')} webUsage={true}/>
            </div>
          </div>
        </div>
      </div>
    </Card>

    )
}