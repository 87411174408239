import React from "react";
import backgroundImage1 from '../../../Assets/proDiscountpink.png';
import backgroundImage2 from '../../../Assets/proDiscountblue.png';
import backgroundImage3 from '../../../Assets/proDiscountyellow.png';
import ApplyCouponIcon from '../../../Assets/bxsOffer.png';

export default function ProfessionalDiscountsCards(props){
    return (
        <div onClick={() => props.onClick(props.code)} style={{cursor:'pointer'}}>
            {props.colour == "#ff5d5d" ?
                <div className="discount_card_mainViewContainer" style={{backgroundImage: `url(${backgroundImage1})`, backgroundSize:'cover', backgroundRepeat: 'no-repeat', width:'100%', marginVertical:8, borderRadius: 10}} imageStyle={{borderRadius: 10}}>
                    <div style={{width:230,display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                        <img src={ApplyCouponIcon} style={{tintColor:props.colour, width:30, height:30}}/>
                        <text className="discount_card_discount" style={{color:props.colour, marginLeft:5}}>{props.code}</text>
                        <text className="discount_card_tittle" style={{color:props.colour, marginLeft:5}}>(UPTO {props.coupon_percent ? props.discount +'%' : (props.country == 'IN' ? '\u20B9' : '$') +props.discount} off)</text>
                    </div>
                    <div>
                        <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1694671463109-lg.png'} style={{width:"auto", height:50}}/>
                    </div>
                </div>
            : props.colour == "#6750a3" ? 
                <div className="discount_card_mainViewContainer" style={{backgroundImage: `url(${backgroundImage2})`, backgroundSize:'cover', backgroundRepeat: 'no-repeat', width:'100%', marginVertical:8}} imageStyle={{borderRadius: 10}}>
                    <div style={{width:'auto',display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                        <img src={ApplyCouponIcon} style={{tintColor:props.colour, width:30, height:30}}/>
                        <text className="discount_card_discount" style={{color:props.colour, marginLeft:5}}>{props.code}</text>
                        <text className="discount_card_tittle" style={{color:props.colour, marginLeft:5}}>(UPTO {props.coupon_percent ? props.discount +'%' : (props.country == 'IN' ? '\u20B9' : '$') +props.discount} off)</text>
                    </div>
                    <div>
                        <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1694671463109-lg.png'} style={{width:"auto", height:50}}/>
                    </div>
                </div>
            :
                <div className="discount_card_mainViewContainer" style={{backgroundImage: `url(${backgroundImage3})`, backgroundSize:'cover', backgroundRepeat: 'no-repeat', width:'100%', marginVertical:8}}>
                    <div style={{width:230,display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                        <img src={ApplyCouponIcon} style={{tintColor:props.colour, width:30, height:30}}/>
                        <text className="discount_card_discount" style={{color:props.colour, marginLeft:5}}>{props.code}</text>
                        <text className="discount_card_tittle" style={{color:props.colour?props.colour:'#fff', marginLeft:5}}>(UPTO {props.coupon_percent ? props.discount +'%' : (props.country == 'IN' ? '\u20B9' : '$') +props.discount} off)</text>
                    </div>
                    <div>
                        <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1694671463109-lg.png'} style={{width:'auto', height:50}}/>
                    </div>
                </div>
            }
        </div>
    )
}