import React, { useEffect, useState } from 'react'
import '../../CSS/FindoutWhatAreULooking.css'
import InputComponent from '../../Components/Custom/UI/InputComponent'
import { useMediaQuery } from "react-responsive"
import axios from 'axios'
import { BsCheckLg } from 'react-icons/bs'
export default function FindoutWhatAreULooking() {
    const useTabletAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 769px)" })
    const useMobileAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 760px)" })
    const isMobileTabView = useTabletAndBelowMediaQuery()
    const isMobileView = useMobileAndBelowMediaQuery()
    const [email,setEmail]=useState({
        value: '',
        valid: false,
        invalid: false
    })
    const [loading1,setLoading1]=useState(false)
    const [sentEmail,setSentEmail]=useState(false)
    const submitHandler = () => {
        console.log('pressed')
        let k=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(email.invalid || email.value === '' || !(k.test(email.value))){
            setEmail({
                ...email,
                value:email.value,
                valid:false,
                invalid:true
            })
        }else{
            setEmail({
                ...email,
                value:email.value,
                valid:true,
                invalid:false
            })
            setLoading1(true)
            let body ={
                userType:"Download_Customer",
                // userType: this.props.active == 1 || this.props.type == "Customer" ? "Download_Customer" : "Download_Professionals",
                email: email.value ? email.value : null,
                typeEmail:true,
            }
            axios.post('/website/download/sendLink',body)
            .then(res=>{
                if(res.data.data){
                    console.log('inserted email');
                    setEmail({
                        ...email,
                        value:'',
                        valid:false,
                        invalid:false
                    })
                    setLoading1(false)
                    setSentEmail(true)
                }else{
                    if(res.data.dbError){
                        console.log('db error')
                        setLoading1(false)
                    }else{
                        console.log('error')
                        setLoading1(false)
                    }
                }
            })
            .catch(error=>{
                console.log(error)
                setLoading1(false)
            })
        }
    }
    const emailHandler = (event) => {
        let k = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (event.target.value.length === 0 || event.target.value === '') {
            setEmail({
                ...email,
                value:'',
                valid:false,
                invalid:true
            })
            setSentEmail(false)
        }
        else {
            if (k.test(event.target.value)) {
                setEmail({
                    ...email,
                    value:event.target.value,
                    valid:true,
                    invalid:false
                })

            } else {
                setEmail({
                    ...email,
                    value:event.target.value,
                    valid:false,
                    invalid:true
                })
            }
        }
    }
  return (
    <div>
        <div className='findoutWhatAreYou_Home'>
            <div style={{display:'flex',flexDirection:'column'}}>
                <text className='findoutWhatAreYou_mainText'>Did you find what you were looking for?</text>
                <text className='findoutWhatAreYou_subText'>Let our experts help you find the best service for you!</text>
            </div>
            <div style={{marginTop:isMobileView ? 20 : 0,position:'relative'}}>
                <InputComponent
                InputFields={{
                    type:"text",
                    onChange:emailHandler,
                    value: email.value,
                    placeholder: "Enter email",
                    // onSubmitEditing: () => submitHandler(),
                    // returnKeyType: "next",
                    valid:email.valid ? true : false,
                    invalid:email.invalid ? true : false,
                    maxLength:40,
                    className:"inputTextStyle_Email_findOut"
                }}
                textStyle={{width:isMobileTabView ? 305 : 400}}
                />
                <div className='goEmail_findOut' onClick={()=>submitHandler()}>
                    <text className='goText_findOut'>
                        {loading1 ? <g className="loading_Landing" style={{color:'#fff'}}><span style={{padding:2}}>.</span><span style={{padding:2}}>.</span><span style={{padding:2}}>.</span></g> : sentEmail ? <BsCheckLg color='#fff' size='1.2rem'/> : 'Go'}
                    </text>
                </div>
            </div>
        </div>
        <div style={{paddingTop:40}}>
            <div style={{borderBottom:'0.5px solid #e9dfff',width:'100%'}}/>
        </div>
    </div>
  )
}
 
