import React, { useContext } from 'react'
import SlashLogoFooter from '../../Assets/download-1-1.png'
import AppStore from '../../../New_WebPage_Design/Assets/Commons/appstore 1.png';
import GooglePlay from '../../../New_WebPage_Design/Assets/Commons/googleplay 1.png';
import InstaGramIcon from '../../Assets/InstagramIcon.png'
import TwitterIcon from '../../Assets/TwitterIcon.png'
import FacebookImage from '../../Assets/FacebookIcon.png'
import PinterestImage from '../../Assets/PinterestIcon.png'
import YoutubeIamge from '../../Assets/YoutubeIcon.png'
import LinkedinImage from '../../Assets/LinkedinIcon.png'
import '../../CSS/FooterSv2.css'
import { globalState } from '../../GlobalState/globalState';
import { HashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import FindoutWhatAreULooking from './FindoutWhatAreULooking';
export default function FooterSv2_Mobile(props) {
    const [state,dispatch]=useContext(globalState)
    let paramsLocation=useLocation()
    let urlPath=paramsLocation.pathname
    let date = new Date();
    let Year = date.getFullYear();
  return (
    <div style={{marginBottom:state.cartCount > 0 && state.cart.length!==0 && (urlPath=='/allservices' || urlPath=='/servicedetails') ? 80 : (urlPath=='/checkout' ? (props.serviceSlotSelected ? 80 : 0) : (urlPath=='/professionalUnderCategory' || urlPath=='/allservices' || urlPath=='/servicedetails' ? 0 : 64))}}>
        <div style={{backgroundColor:'#461C99'}} className="MainReusable_Container_Home">
            <div style={{paddingBottom:30,paddingTop:20}}>
               <FindoutWhatAreULooking/>
            </div>
            <div style={{display:'flex',flexDirection:'column'}}>
                <div style={{paddingBottom:20}}>
                    <img src={SlashLogoFooter}/>
                </div>
                <div>
                    <text className='footerSv2_subText_Mobile'>Slash is an easy-to-use digital marketplace to find the best quality home & living service providers in your local area.</text>
                </div>
                <div style={{marginTop:20}}>
                    <div className='inputStyle_sv2'>
                        {
                            state.address.country === 'India'
                            ?
                            <text className='inputTextStyle_sv2'>
                            English (IN)
                            </text>
                            :
                            <text className='inputTextStyle_sv2'>
                            English (US)
                            </text>
                        }  
                </div>
                </div>
            </div>
            <div  style={{display:'flex',flexDirection:'row',marginTop:20,justifyContent:'space-between'}}>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <text className='footerSv2_MainText_Mobile' style={{marginBottom:15}}>Company</text>
                    <HashLink style={{ textDecoration: 'none', marginBottom: 15 }} to="/blog">
                        <text className='footerSv2_subText_Mobile'>Blogs & Articles</text>
                    </HashLink>
                    <HashLink style={{ textDecoration: 'none'}} to="/businesslisting">
                        <text className='footerSv2_subText_Mobile'>Are you a Professional?</text>
                    </HashLink>
                </div>
                <div style={{display:'flex',flexDirection:'column'}}>
                      <text className='footerSv2_MainText_Mobile' style={{ marginBottom: 15 }}>Help</text>
                      <HashLink style={{ textDecoration: 'none', marginBottom: 15 }} to="/contactus">
                          <text className='footerSv2_subText_Mobile' >Contact Us</text>
                      </HashLink>
                      {/* <a  href='/HelpAndSupport#FAQapp'>FAQs</a> */}
                      <HashLink style={{ textDecoration: 'none', }} to="/FAQs">
                          <text className='footerSv2_subText_Mobile' >FAQs</text>
                      </HashLink>
                </div>
                <div style={{display:'flex',flexDirection:'column'}}>
                      <text className='footerSv2_MainText_Mobile' style={{ marginBottom: 15 }}>Legal</text>
                      {/* <text className='footerSv2_subText_Mobile' style={{marginBottom:15}}>Terms & Conditions</text>
                        <text className='footerSv2_subText_Mobile'>Privacy & Policy</text> */}
                      <HashLink style={{ textDecoration: 'none', marginBottom: 15 }} to="/terms&conditions" state={{ docs: 'terms', type: 1, active: 1 }}>
                          <text className='footerSv2_subText_Mobile'  >Terms & Conditions</text>
                      </HashLink>
                      <HashLink style={{ textDecoration: 'none', }} to="privacy&policy" state={{ docs: 'privacy', type: 1, active: 1 }}>
                          <text className='footerSv2_subText_Mobile' >Privacy & Policy</text>
                      </HashLink>
                </div>
            </div>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:30}}>
                <div>
                    <text className='footerSv2_downloadText_Mobile'>Find Us On</text>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                    <img src={InstaGramIcon}  style={{width:20,height:20}} onClick={() => { window.open('https://www.instagram.com/goslash.app/') }} />
                    <img src={TwitterIcon} style={{ marginLeft: 20,width:20,height:20 }} onClick={() => { window.open('https://twitter.com/goslashapp') }} />
                    <img src={FacebookImage} style={{ marginLeft: 20,width:20,height:20 }} onClick={() => { window.open('https://www.facebook.com/goslashapp/') }} />
                    <img src={PinterestImage} style={{ marginLeft: 20 ,width:20,height:20}} onClick={() => { window.open('https://in.pinterest.com/goslashapp/') }} />
                    <img src={YoutubeIamge} style={{ marginLeft: 20,width:20,height:20 }} onClick={() => { window.open('https://www.youtube.com/channel/UCNIC3KzckSXejaMtWbRHA2Q') }} />
                    <img src={LinkedinImage} style={{ marginLeft: 20,width:20,height:20 }} onClick={() => { window.open('https://www.linkedin.com/company/goslashapp') }} />
                </div>
            </div>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:35}}>
                <div>
                    <text className='footerSv2_downloadText_Mobile'>Download the app </text>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                    <a href={'https://play.google.com/store/apps/details?id=com.quickfinduser&utm_campaign=Consumer_Guide&utm_source=Website&utm_medium=DigitalMarketingSources'} target="_blank">
                        <img src={GooglePlay} className='footerSv2_googlePlay'/>
                    </a>
                    <a href={'https://apps.apple.com/us/app/slash-consumer/id1489207325?ls=1/?utm_campaign=Consumer_Guide&utm_source=Website&utm_medium=DigitalMarketingSources'} target="_blank">
                        <img src={AppStore} className='footerSv2_appStore'/>
                    </a>
                </div>
            </div>
        </div>
        <div>
            <div style={{backgroundColor:'#461C99'}} className="MainReusable_Container_Home">
                <div style={{display:'flex',justifyContent:'center'}}>
                    {
                        state.address.country === 'India' ? 
                        <text className='copyRight_Text'>
                        Copyright ©{Year} Prashya Technologies Private Limited.
                        </text>
                        :
                        <text className='copyRight_Text'>
                        Copyright ©{Year} QuickFind, Inc.
                        </text>
                    }
                {/* <text className='copyRight_Text'>
                    Copyright ©2022 Prashya Technologies Private Limited.
                </text> */}
                </div>
            </div>
        </div>
    </div>
  )
}
