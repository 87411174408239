import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Button, Container, Form, FormGroup, Label, Input, FormFeedback, Modal, ModalBody, ModalFooter, CardBody, Card, CardImg, } from 'reactstrap'
import '../../../../New_Website_V2/CSS/OfferPage.css'
import { AiFillStar } from "react-icons/ai";
import RatingComponent from '../../Custom/UI/RatingComponent'
const ServiceCard = (props) => {
    return (
        <div>
            <Card className="nearbyProCard1" onClick={props.onclick} style={{ background: 'linear-gradient(to top, rgba(0, 0, 0) 100%, rgba(0, 0, 0) 0%)' }}>
                <CardImg src={props.uri} className='nearbyProCardImg1'
                />
                <div style={{ position:'absolute',left:10,top:10,display:'flex',flexDirection:'column'}}>
                    <text className='primaryTextStyle1' style={{width:150,marginTop:2}}>{props.serviceName}</text>
                    <text className='secondaryTextStyle1'>{props.proName}</text>
                    {props.rating ? 
                    <div className='rating_ProCardView1' style={{marginTop:5}}>
                        <RatingComponent rating={props.rating} webUsage={true} style={{fontsize:10}} width={45} height={26} />
                        <AiFillStar size={15} color="#fed019" />
                    </div>:null}
                </div>


                

            </Card>
        </div>
    );
}

export default ServiceCard;
