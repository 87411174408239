import React, { Component, Text, useState, useRef, useEffect, useContext } from 'react'
import { Navbar, NavbarBrand, Nav, NavItem, Row, NavbarToggler, Collapse, Col, Modal, FormGroup, Input, Button, FormFeedback, NavLinkReactor } from 'reactstrap';
import Profile from '../../../../New_Website_V2/Assets/profile.png'
import Mail from '../../../../New_Website_V2/Assets/promail.png'
import Call from '../../../../New_Website_V2/Assets/procall.png'
import '../../../../New_Website_V2/CSS/UI Components/ProfilePage.css'
import Edit from '../../../../New_Website_V2/Assets/material-symbols-edit.png'
import Pro from '../../../../New_Website_V2/Assets/manage-accounts-fill-0-wght-400-grad-0-opsz-48-2.png'
import Card from '../../../../New_Website_V2/Assets/asicon-outline-card.png'
import Refer from '../../../../New_Website_V2/Assets/moving-beds-fill-0-wght-400-grad-0-opsz-48-1.png'
import Bell from '../../../../New_Website_V2/Assets/group-1000005386.png'
import { globalState } from '../../../GlobalState/globalState'
import Address from '../../../../New_Website_V2//Assets/Saved.png'
import customFetchData from '../../Custom/customFetchData';
import { ToastContainer, toast } from 'react-toastify';
import { BiInfoCircle } from 'react-icons/bi'
import { authLogOut } from '../../../GlobalState/globalStateActions';
import RegisstrAndLogin_India from '../../../Screens/RegisterrAndLogin_India';
import SettingPage from './SettingPage';
import ReferalReward from './ReferalRewards';
import Logout from '../../../../New_Website_V2/Assets/asicon-outline-logout.webp'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ReferAndEarn from './ReferAndEarnCard';
import SavedAddresses from '../../../Screens/ProfileStack/SavedAddresses';
import ConsumerEditProfile from '../../../Screens/ProfileStack/ConsumerEditProfile';
import MyTransactions from '../../../Screens/MyTransactions/MyTransactionss';
import ActiveEdit from '../../../Assets/material-edit.png'
import ActiveCard from '../../../Assets/asicon-card.png'
import ActiveAccount from '../../../Assets/manage-accounts.png'
import ActiveRefer from '../../../Assets/moving-beds-fill-0-wght-400-grad-0-opsz-48-2.png'
import ActiveAddress from '../../../Assets/group-1000005370.png'
import CloseIcon from '../../../Assets/icons-closeModal.png';
import { useMediaQuery } from 'react-responsive';
const ProfilePage = () => {
    const params=useParams()
    const useTabletAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 760px)" })
    const isMobile = useTabletAndBelowMediaQuery()
    let paramsLocation=useLocation()
    const [login, setLogin] = useState(true)
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [modal, setModal] = useState(false)
    const [mobile, setMobile] = useState('')
    const [mobileCountryCode, setMobileCountryCode] = useState('')
    const [loading, setLoading] = useState(false)
    let [state, dispatch] = useContext(globalState)
    const [emailCheck, setEmailCheck] = useState(false)
    const [editColor, setEditColor] = useState(params.profileId === "editProfile" ? true : false)
    const [transactionColor, setTransactionColor] = useState(false)
    const [accountColor, setAccountCoulor] = useState(false)
    const [notificationColor, setNotificationColor] = useState()
    const [referColor, setReferColor] = useState(!state.token && params.profileId !== "editProfile" ? true : false)
    const [addressColor, setAddressColor] = useState(false)
    const [logoutColor, setLogoutColor] = useState(false)
    const [ details,setDetails ] = useState([])
    const [logoutModalOpen,setLogoutModalOpen]=useState(false)
    const [referalReward, setReferalReward]=useState(false)
    useEffect(() => {
        fetchDataHandler()
        console.log('params',params)
        if(params && state.token){
            setEditColor(params.profileId === "editProfile" ? true : false)
            setReferColor(params.profileId === "referandearn" ? true : false)
            setTransactionColor(params.profileId === "transactions" ? true : false)
            setAddressColor(params.profileId === "savedAddresses" ? true : false)
            setAccountCoulor(params.profileId === "settings" ? true : false)
        }
    },[logoutModalOpen])

    const navigate = useNavigate();
    

    const fetchDataHandler = async () => {
        setLoading(true)
        let body = {
            consumer_id: state.user.id
        }
        // console.log('user id', body.consumer_id)
        let userDetails = await customFetchData('POST', body, "/website/consumer/details", state, dispatch)
        // console.log('response', userDetails)
        if (userDetails.fetch) {
            if (userDetails.fetchedData.data) {
                // setReferColor(false)
                setDetails(userDetails.fetchedData.results[0])
                setLoading(false)
                let response = userDetails.fetchedData.results[0]
                setEmail(response.CONSUMER_EMAIL)
                setMobile(response.CONSUMER_PHONE)
                setName(response.CONSUMER_FNAME)
                setMobileCountryCode(response.CONSUMER_PHONE_COUNTRY_CODE)
                if (userDetails.fetchedData.resultEmailQ) {
                    setEmailCheck(userDetails.fetchedData.resultEmailQ[0].EMAIL_VERIFIED_FLAG ? true : false)
                }
            } else {
                if (userDetails.fetchedData.dbError) {
                    //   ToastAndroid.show("Internal Error. Please try again.", ToastAndroid.SHORT);
                    // toast.success("Internal Error. Please try again.", { position: toast.POSITION.BOTTOM_CENTER })
                    toast("Internal Error. Please try again.", {
                        position:'bottom-center',
                        hideProgressBar: true,
                        progress: undefined,
                        style:{backgroundColor:'#E0D1FF'}
                    });
                }
                else {
                    // ToastAndroid.show("Something went wrong. Please try again. ",ToastAndroid.SHORT);
                }
                setLoading(false)
            }

        }

    }

    const editHandler = () => {
        // console.log('it working')
        setEditColor(true)
        setTransactionColor(false)
        setAccountCoulor(false)
        setNotificationColor(false)
        setReferColor(false)
        setAddressColor(false)
        setLogoutColor(false)
        setReferalReward(false)
        navigate(`/profile/${'editProfile'}`)

    }
    const transactionHandler = () => {
        // console.log('jddjj')
        setEditColor(false)
        setTransactionColor(true)
        setAccountCoulor(false)
        setNotificationColor(false)
        setReferColor(false)
        setAddressColor(false)
        setLogoutColor(false)
        setReferalReward(false)
        navigate(`/profile/${'transactions'}`)
    }
    const accountHandler = () => {
        setEditColor(false)
        setTransactionColor(false)
        setAccountCoulor(true)
        setNotificationColor(false)
        setReferColor(false)
        setAddressColor(false)
        setLogoutColor(false)
        setReferalReward(false)
        navigate(`/profile/${'settings'}`)
    }
    const notifyHandler = () => {
        setEditColor(false)
        setTransactionColor(false)
        setAccountCoulor(false)
        setNotificationColor(true)
        setReferColor(false)
        setAddressColor(false)
        setLogoutColor(false)
        setReferalReward(false)
        navigate(`/profile/${'notifications'}`)
    }
    const referHandler = () => {
        setEditColor(false)
        setTransactionColor(false)
        setAccountCoulor(false)
        setNotificationColor(false)
        setReferColor(true)
        setAddressColor(false)
        setLogoutColor(false)
        setReferalReward(false)
        navigate(`/profile/${'referandearn'}`)
    }

    const rewardHandler = () => {
        setEditColor(false)
        setTransactionColor(false)
        setAccountCoulor(false)
        setNotificationColor(false)
        setReferColor(false)
        setAddressColor(false)
        setLogoutColor(false)
        setReferalReward(true)
        navigate(`/profile/${'rewards'}`)
    }

    const addressHandler = () => {
        setEditColor(false)
        setTransactionColor(false)
        setAccountCoulor(false)
        setNotificationColor(false)
        setReferColor(false)
        setAddressColor(true)
        setLogoutColor(false)
        setReferalReward(false)
        navigate(`/profile/${'savedAddresses'}`)

    }
    const logoutHandler = () => {
        setEditColor(editColor)
        setTransactionColor(transactionColor)
        setAccountCoulor(accountColor)
        setNotificationColor(notificationColor)
        setReferColor(referColor)
        setAddressColor(addressColor)
        // setLogoutColor(true)
        // logouthandler
        // onLogOutHandler()
        //logout modal open
        setLogoutModalOpen(true)
    }
    const onLogOutHandler = async () => {
        // console.log("inside logout handler");
        const logOutData = {
            id: state.user.id,
            token: state.token,
            role: state.user.role
        }
        let { fetchedData } = await customFetchData('POST', logOutData, '/website/logout', state, dispatch) // Custom hook to check network and fetch data
        // console.log("fetchedData from logout", fetchedData)
        if (fetchedData.logoutStatus) {
            let logOutStatusFromState = await authLogOut(dispatch)
            if (logOutStatusFromState) {
                // setReferColor(true)
                setLogoutModalOpen(false)
                // toast.success("Logout Successful", { position: toast.POSITION.BOTTOM_CENTER })
                toast("Logout Successful", {
                    position:'bottom-center',
                    hideProgressBar: true,
                    progress: undefined,
                    style:{backgroundColor:'#E0D1FF'}
                });
            }
            else {
                // toast.success("Logout Failed. Please try again.", { position: toast.POSITION.BOTTOM_CENTER })

                toast("Logout Failed. Please try again.", {
                    position:'bottom-center',
                    hideProgressBar: true,
                    progress: undefined,
                    style:{backgroundColor:'#E0D1FF'}
                });
                
            }
        }
        else {
            if (fetchedData.dbError) {
                // toast.success("Internal Error. Please try again.", { position: toast.POSITION.BOTTOM_CENTER })
                toast("Internal Error. Please try again.", {
                    position:'bottom-center',
                    hideProgressBar: true,
                    progress: undefined,
                    style:{backgroundColor:'#E0D1FF'}
                });
                
            }
            else {
                // toast.success("Logout Failed. Please try again. ", { position: toast.POSITION.BOTTOM_CENTER })
                toast("Logout Failed. Please try again.", {
                    position:'bottom-center',
                    hideProgressBar: true,
                    progress: undefined,
                    style:{backgroundColor:'#E0D1FF'}
                });
            }
        }
    }
    const logoutModalClose=()=>{
        setLogoutModalOpen(false)
        setLogoutColor(false)
        // setEditColor(true)
    }
    let logoutModalDisplay=(
        <Modal isOpen={logoutModalOpen} toggle={logoutModalClose} size={"md"} centered>
            <div style={{padding:isMobile ?  20 : 28,backgroundColor:'#fff', borderRadius:10}}>
                <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>
                    <text className='are_you_wantText'>Are you sure you want to logout?</text>
                    <div onClick={logoutModalClose} style={{cursor:'pointer',cboxShadow: '0 2px 4px 0 rgba(103, 52, 204, 0.1)',width:22,height:22,borderRadius:20,backgroundColor: '#fff',display:'flex',justifyContent:'center',flexDirection:'row',alignItems:'center'}}>
                        <img src={CloseIcon} style={{width:16,height:16}}/>
                    </div>
                </div>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:20}}>
                    <div className='yes_Logout_Btn' onClick={onLogOutHandler}>
                        <text className='yes_Text_Logout'>Yes, Logout</text>
                    </div>
                    <div className='cancel_Logout_Btn' onClick={logoutModalClose} style={{marginLeft:20}}>
                        <text className='cancel_Text_Logout'>Cancel</text>
                    </div>
                </div>
            </div>
        </Modal>
    )
    // console.log('name,email,mob',name,mobile,email,editColor,mobileCountryCode)
    return (
        <div className='profileContainer' style={{ backgroundColor: '#f1f1f1' }}>
            <div style={{ marginTop: 60, flexDirection: 'row', display: 'flex' }}>
                {/* <div>
                    <img src={Profile} />
                </div> */}
                {state.token ?
                    <div   style={{flexDirection: 'row', display: 'flex' }}>
                        <div>
                            <img src={Profile} />
                        </div>
                        <div>

                          {name ?
                            <div>
                                <text className='profileName'>{name}</text>
                            </div>
                            : <div>
                                <text className='profileName'>Not Updated</text>
                            </div>
                            }
                        {email ?
                            <div style={{ paddingLeft: 8 }}>
                                <img src={Mail} style={{ paddingRight: 10 }} />
                                <text>{email}</text>
                            </div>
                            :
                            <div style={{ paddingLeft: 8 }}>
                                <img src={Mail} style={{ paddingRight: 10 }} />
                                <text>Not Updated</text>
                            </div>
                        }
                        {mobile ?
                            <div style={{ paddingLeft: 8 }}>
                                <img src={Call} style={{ paddingRight: 10 }} />
                                <text>{mobile}</text>
                            </div> :
                            <div style={{ paddingLeft: 8 }}>
                                <img src={Call} style={{ paddingRight: 10 }} />
                                <text>Not Updated</text>
                            </div>}
                        </div>

                    </div>
                    :
                    <div style={{ marginTop: 60, flexDirection: 'row', display: 'flex' }}>
                        <div>
                            <img src={Profile} />
                        </div>
                        <div style={{ paddingTop: 10 }}>
                            <div>
                                <text style={{ fontSize: 16, fontWeight: 'bold' }} >Welcome to Slash</text>
                            </div>
                            <div>
                                <BiInfoCircle color="red" size={20} />
                                <text className='profileName_mobile' onClick={() => setModal(!modal)}>Please register to create your account</text>
                            </div>

                        </div>

                    </div>
                }

            </div>
            <hr />

            <div style={{ flexDirection: 'row', display: 'flex', }}>

                <div className='profileOptions'>

                    { 
                    state.token &&
                        <>
                            <div className='optionDiv' onClick={editHandler} style={{ backgroundColor: editColor ? '#DFC5FE' : null }} >
                               {editColor?<img src={ActiveEdit} width={18} height={18}  style={{marginLeft:5}}/>:<img src={Edit} width={18} height={18} style={{marginLeft:5}}/>} 
                                <text className='optionText'>Edit Profile</text>
                            </div>
                            <hr style={{ margin: 5 }} />
                        </>
                    }
                    {
                    state.token &&
                        <>
                            <div className='backgroudDiv' onClick={transactionHandler} style={{ backgroundColor: transactionColor ? '#DFC5FE' : null }}>
                              {transactionColor?<img src={ActiveCard} width={18} height={18} />:<img src={Card} width={18} height={18} />}  
                                <text className='optionText'>My Transactions</text>

                            </div>
                            <hr style={{ margin: 5 }} />
                        </>
                    }
                    {
                    state.token ?
                        <>
                            <div>
                                <div className='backgroudDiv' onClick={accountHandler} style={{ backgroundColor: accountColor ? '#DFC5FE' : null }}>
                                   {accountColor?<img src={ActiveAccount} width={18} height={18}  />:<img src={Pro} width={18} height={18}  />} 
                                    <text className='optionText'>Settings</text>
                                </div>
                            </div>
                            <hr style={{ margin: 5 }} />
                        </>
                        : null
                    }
                    {/* <div className='backgroudDiv' onClick={notifyHandler} style={{ backgroundColor: notificationColor ? '#DFC5FE' : null }}>
                        <img src={Bell} width={18} height={18} />
                        <text className='optionText'>Notification</text>

                    </div>
                    <hr style={{margin:5}}/> */}
                    <div className='backgroudDiv' onClick={referHandler} style={{ backgroundColor: referColor ? '#DFC5FE' : null }} >
                      {referColor?<img src={ActiveRefer} width={18} height={18} />:<img src={Refer} width={18} height={18} />}  
                        <text className='optionText'>Refer & Earn</text>

                    </div>
                    <hr style={{margin:5}}/>

                    { 
                    state.token &&
                        <>
                            <div className='backgroudDiv' onClick={addressHandler} style={{ backgroundColor: addressColor ? '#DFC5FE' : null }}>
                            {addressColor? <img src={ActiveAddress} width={16} height={16} />: <img src={Address} width={16} height={16} />}   
                                <text className='optionText'>Saved Address</text>
                            </div>
                            <hr style={{ margin: 5 }} />
                        </>
                    }
                    {state.token ?
                        <div className='logoutDiv' onClick={logoutHandler}>
                            <img src={Logout} width={18} height={18}  style={{marginLeft:5}}/>
                            <text className='optionText'>Logout</text>

                        </div> : null}
                </div>

                {referalReward ?

                    <div className='profileActionDiv_1'>
                        { referalReward ? <ReferalReward onPressBack={referHandler}/> : null}
                    </div>

                :
                    <div className='profileActionDiv'>
                        { accountColor && state.token ? <SettingPage/> : null}
                        { referColor ? <ReferAndEarn onPressReward={rewardHandler}/> : null }
                        {/* { referalReward ? <ReferalReward onPressBack={referHandler}/> : null} */}
                        
                        { addressColor ? <SavedAddresses addressProps_Web={state.address} userId={state.user.id}/> : null}
                        { transactionColor && <MyTransactions  web={true}  />}
                        { details.length!==0 && editColor && state.token? <ConsumerEditProfile mobileCountryCodeOld={mobileCountryCode} name={name} email={email} mobile={mobile}/> : null}
                    {/* <text>dnjksdnksfdkjfjdkfccccccccfdklfjdlfjdlfjldfjldfjldjfljfldjflj</text> */}
                    </div>
                }
            </div>
            {logoutModalOpen && logoutModalDisplay}
            {modal && <RegisstrAndLogin_India closeModal={() => setModal(!modal)} />}
            {/* <ToastContainer/> */}

        </div>
    );
}

export default ProfilePage;
