import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardImg, CardText, CardHeader } from 'reactstrap';

import DualCoat from '../../Assets/Commons/invalid-name.png';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import downArrow from '../../Assets/Commons/Path 8 Copy.png'
import upArrow from '../../Assets/Commons/Path 8.png'

import ArrowRight from '../../Assets/Commons/group-23.png';
import LeftArrow from '../../Assets/Landing_Page/arrow-left.png'
import RightArrow from '../../Assets/Landing_Page/arrow-right.png'
import '../../Css_files/Consumer/TestimonialsV2.css'
import Website_Container from '../Landing_Page/Components/Reusable_Container/WebsiteContainer';
import Website_Coloured_Container from '../Landing_Page/Components/Reusable_Container/WebsiteColoredContainer';
import StarImages from '../../Assets/Landing_Page/star2.png'
import StarImages3 from '../../Assets/Landing_Page/star1.png'
import Quotes from '../../Assets/Landing_Page/quotes.png'
import StarRatings from 'react-star-ratings';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1100 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    laptop: {
        breakpoint: { max: 1100, min: 900 },
        items: 2.5,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 900, min: 600 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },

    mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }

};

export default class TestimonialsV2 extends Component {
    render() {


        const ButtonGroup = ({ next, previous, ...rest }) => {
            const {
                carouselState: { currentSlide, totalItems, slidesToShow }
            } = rest;

            return (
                <div>
                    {/* <div>
                        <text style={{fontSize:40}}>What our Customer have to say!</text>
                    </div> */}
                    <div className="arrowLand_NewStyle4">


                        <Card className={currentSlide === 0 ? "disable_arrowLeft4" : "arrow_cardNewLeft4"} onClick={() => previous()}>
                            <CardImg src={RightArrow} className={"react-carousel_arrowLandPage4"} />
                        </Card>
                        <Card className={currentSlide === totalItems - slidesToShow ? "disable_arrowRight4" : "arrow_cardNewRight4"} onClick={() => next()}>
                            <CardImg src={LeftArrow} className={"react-carousel_arrowLandPage4"} />
                        </Card>
                    </div>
                </div>
            );
        };

        let ImageTestimonials = (
            // <div className='div_screen_view1'>
            <div className='row_view_DealAndOffers'>

                <Carousel
                    swipeable={true}
                    draggable={false}
                    showDots={false}
                    arrows={false}
                    // focusOnSelect={true}
                    // renderButtonGroupOutside={false}
                    customButtonGroup={<ButtonGroup
                        next={this.props.next}
                        previous={this.props.previous}
                        rest={this.props.rest}
                    />}
                    // itemClass="image-item"
                    responsive={responsive}
                    transitionDuration={500}
                    containerClass="carousel_container_DealsAndOffers"
                    renderArrowsWhenDisabled={true}
                // deviceType={this.props.deviceType}
                >

                    <Card className='card_view-testimonials_New'>
                        <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                            <div style={{ textAlign: 'left' }}>
                                <StarRatings
                                    rating={5}
                                    starRatedColor="#8c52ff"
                                    numberOfStars={5}
                                    name='rating'
                                    starSpacing={2}
                                    starDimension="15px"
                                />
                                {/* <img src={StarImages} style={{opacity:1}}/>
                                <img src={StarImages} style={{opacity:1}}/>
                                <img src={StarImages} style={{opacity:1}}/>
                                <img src={StarImages} style={{opacity:1}}/>
                                <img src={StarImages3} width={14} style={{opacity:1}}/> */}
                            </div>
                            <div>
                                <img src={Quotes} />
                            </div>
                        </div>
                        <h6 className="subTextTestimonial">This app is so friendly and useful never expected an app for car wash booking.well recommend app and the wash was quick and the people are so friendly

                        </h6>
                        <text className='proName'>SPLASH CAR WASH</text>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>

                            <div className='serviceNameDiv'>
                                <text className='serviceName'>Foam Wash - SUV</text>
                            </div>
                            {/* <div className='serviceNameDiv'>
                                        <text className='serviceName'>servicename...</text>
                                    </div> */}
                        </div>
                        <hr style={{ height: 1, margin: 0 }} />

                        <div style={{ display: 'flex', flexDirection: 'row' }} >
                            <div className='lcDiv'>
                                <text className='lcText'>AR</text>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                <text className='loraText'>Anusha Reddy</text>
                                <text className='verifiedCustomer'>Verified Customer</text>
                            </div>
                        </div>

                    </Card>
                    <Card className='card_view-testimonials_New'>
                        <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                            <div style={{ textAlign: 'left' }}>
                                <StarRatings
                                    rating={5}
                                    starRatedColor="#8c52ff"
                                    numberOfStars={5}
                                    name='rating'
                                    starSpacing={2}
                                    starDimension="15px"
                                />
                                {/* <img src={StarImages} style={{opacity:1}}/>
                                            <img src={StarImages} style={{opacity:1}}/>
                                            <img src={StarImages} style={{opacity:1}}/>
                                            <img src={StarImages} style={{opacity:1}}/>
                                            <img src={StarImages3} width={14} style={{opacity:1}}/> */}
                            </div>
                            <div>
                                <img src={Quotes} />
                            </div>
                        </div>
                        <h6 className="subTextTestimonial">This is my second visit to this saloon because of Salman #best barber
                        </h6>
                        <text className='proName'>DOLLAR‘S MENS SALOON</text>
                        <div style={{ flexDirection: 'row', display: 'flex'}}>

                            <div className='serviceNameDiv'>
                                <text className='serviceName'>Hair Cut & Beard Trim - Men</text>
                            </div>
                            {/* <div className='serviceNameDiv'>
                                <text>servicename...</text>
                            </div> */}
                        </div>
                        <hr style={{ height: 1, margin: 0 }} />

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className='lcDiv'>
                                <text className='lcText'>RK</text>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                <text className='loraText'>Rahul kumar</text>
                                <text className='verifiedCustomer'>Verified Customer</text>
                            </div>
                        </div>

                    </Card>
                    <Card className='card_view-testimonials_New'>
                        <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                            <div style={{ textAlign: 'left' }}>
                                <StarRatings
                                    rating={5}
                                    starRatedColor="#8c52ff"
                                    numberOfStars={5}
                                    name='rating'
                                    starSpacing={2}
                                    starDimension="15px"
                                />
                                {/* <img src={StarImages} style={{opacity:1}}/>
                                <img src={StarImages} style={{opacity:1}}/>
                                <img src={StarImages} style={{opacity:1}}/>
                                <img src={StarImages} style={{opacity:1}}/>
                                <img src={StarImages3} width={14} style={{opacity:1}}/> */}
                            </div>
                            <div>
                                <img src={Quotes} />
                            </div>
                        </div>
                        <h6 className="subTextTestimonial">Amazing service at a reasonable price. Would recommend everyone to go for their services.
                            Really liked it a lot.

                        </h6>
                        <text className='proName'>YAFAI STUDIO UNISEX</text>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>

                            <div className='serviceNameDiv'>
                                <text className='serviceName'>Hair Cut - Men</text>
                            </div>
                            {/* <div className='serviceNameDiv'>
                                        <text className='serviceName'>servicename...</text>
                                    </div> */}
                        </div>
                        <hr style={{ height: 1, margin: 0 }} />

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className='lcDiv'>
                                <text className='lcText'>KM</text>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                <text className='loraText'>Krishna Murthy</text>
                                <text className='verifiedCustomer'>Verified Customer</text>
                            </div>
                        </div>

                    </Card>



                </Carousel>

            </div>
            // </div>
        )

        return (



            <div>

                <Website_Container fluid style={{ backgrounColor: "#f5f4ff" }}>
                    <div>
                        <text className='testmonyMain'>What our Customer have to say!</text>
                    </div>

                    <Col style={{ padding: 0 }}>
                        {ImageTestimonials}
                    </Col>
                </Website_Container>
                <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'rgba(224, 209, 255, 0.7)', height: 230, marginTop: -370, marginBottom: 100 }} />
            </div>



        )
    }
}