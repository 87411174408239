import React, { useContext, useEffect, useState } from "react";
import BookingCards from "../../Components/Custom/UI/BookingCards";
import BookingCards_Mobile from "../../Components/Custom/UI/BookingCards_Mobile";
import { useMediaQuery } from "react-responsive"; 
import { globalState } from "../../GlobalState/globalState";
import customFetchData from "../../Components/Custom/customFetchData";
import LoadingSpinner from "../../Components/Custom/UI/LoadingSpinner";

export default function CancelledBookingsPage(){
    const MobileAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 760px)" })
    const TabletMediaQuery = () => useMediaQuery({ minWidth: 768, maxWidth: 991 })
    const isTabView = TabletMediaQuery()
    
    const isMobileView = MobileAndBelowMediaQuery();

    const [state, dispatch]=useContext(globalState);

    const [loading, setLoading]=useState(false)
    const [cancelledServices ,setCancelledServices]=useState([])

    useEffect(() => {
        fetchUpcomingData()
    },[])


    const fetchUpcomingData = async() => {
        try {
            setLoading(true)
            let body={
                user_id:state.user.id,
                timezone: state.country.timezone
            }
            let method = 'POST';
            let url='/website/acceptedoffers2/cancelled/sv2';
            
            const {fetch, fetchedData} = await customFetchData(method, body, url, state, dispatch)
            // console.log("fetchData in Cancelled bookings", fetch, fetchedData)
    
            if(fetch){
                if(fetchedData.data){
                    if(fetchedData.results.length!==0){
                        let cancelledServices= fetchedData.results;
                        setCancelledServices(cancelledServices)
                        setLoading(false)
                    }else{
                        setCancelledServices([])
                        setLoading(false)
                    }

                } else {
                    setCancelledServices([])
                    setLoading(false)
                }
            } else {
                console.log("Something went wrong")
            }

        } catch(catch1){
            console.log(catch1);
        }
    }


    let cancelledServiceDetails;
    if(cancelledServices.length !== 0){
        cancelledServiceDetails = cancelledServices.map((item, index) => {
            if(isMobileView){

                return <BookingCards_Mobile serviceType='cancel' serviceDetails={item} colour="#ff5d5d"
                            serviceBookingType='cancel'
                            item={item}
                        />

            } else {

                return <BookingCards serviceType='cancel' serviceDetails={item} colour="#ff5d5d"
                            serviceBookingType='cancel'
                            item={item}
                        />

            }

        })

    }
    
    

    return (
        <div style={{minHeight:'100vh'}}>
            {cancelledServices.length !== 0 ? cancelledServiceDetails :
                loading ? 
                    <LoadingSpinner loading={true} />
                :
                    <div style={{minHeight:300, display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                        <text className="detail_header_text_style">No Cancelled Bookings</text>
                    </div>
            }
        </div>

    )
}