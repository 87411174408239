import React from 'react'
import '../../CSS/WhySlashNew.css'
import { Row, Col, Card, CardText, Container, CardImg } from 'reactstrap'
export default function WhySlashNew() {
    return (
        <>
            <div className="whySlashNew_Div">
                <text className='WhySlashTextMain'>Why Slash?</text>
                <text className='WhySlashSubText_bm'>Our platform connects you with trusted vendors in your area, ensuring that you get the best services at lower prices.</text>
            </div>
            <Container fluid className="MainReusable_Container_Home">
            <div className='whySlashCard_RowSv2'>
                <div className='whySlashCard_ColSv2'>
                    {/* <div style={{ flexDirection: 'row', display: 'flex' }}> */}
                        <Card className='whySlashNew_CardSv2'>
                            <CardImg src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684327963852-lg.png'} style={{width:150,height:150}}/>
                        </Card>
                    {/* </div> */}
                    <div style={{flexDirection: 'column', display: 'flex',height:100}}>
                        <text className='whySlashCardText_sv2'>Low demand hour discounts</text>
                        <text className='whySlashNew_SubtextSv2'>Book a service during a low-demand hour to enjoy the  discounts</text>
                    </div>
                </div>
                <div className='whySlashCard_ColSv2'>
                    
                    <Card className='whySlashNew_CardSv2'>
                        <CardImg src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684327963783-lg.png'} style={{width:150,height:150}}/>
                    </Card>
                    <div style={{flexDirection: 'column', display: 'flex',height:100}}>
                        <text className='whySlashCardText_sv2'>Easy to book services</text>
                        <text className='whySlashNew_SubtextSv2'>Get hassle-free service booking with the nearby professionals</text>
                    </div>
                </div>
                <div className='whySlashCard_ColSv2'>
                    {/* <div style={{ flexDirection: 'row', display: 'flex' }}> */}
                        <Card className='whySlashNew_CardSv2'>
                            <CardImg src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684327963719-lg.png'} style={{width:150,height:150}}/>
                        </Card>
                    {/* </div> */}
                    <div style={{ flexDirection: 'column', display: 'flex',height:100 }}>
                        <text className='whySlashCardText_sv2'>Save money with special discounts</text>
                        <text className='whySlashNew_SubtextSv2'>Take advantage of our seasonal and promotional discounts.</text>
                    </div>
                    {/* <div style={{ flexDirection: 'row', display: 'flex' }}> */}
                        
                    {/* </div> */}

                </div>
            </div>
            </Container>
        </>
    )
}
