import React,{useState,useEffect,useContext} from 'react'
import { globalState } from './globalState';
import moment from 'moment';
const DeepClone = require("rfdc/default")

// console.log("state form globalStateActions",state)

export const setAuthToken=(token)=>{
    return {
        type:'SET_AUTH_TOKEN',
        token:token
    };
};

export const removeAuthToken=()=>{
    return {
        type: 'REMOVE_AUTH_TOKEN'
    }
}

export const storeCredentials=(id,email,username,role,city,country)=>{
    // console.log('in global state actions',username)
    return {
        type:'CREDENTIALS_STORE',
        id:id,
        email:email,
        username:username,
        role:role,
        city:city,
        country:country
    };
};

export const removeCredentials=()=>{
    return {
        type: 'CREDENTIALS_REMOVE'
    };
};

export const storeUtm=(utm_campaign,utm_source,utm_medium,utm_content,utm_term)=>{
    return {
        type:'UTM_STORE',
        utm_campaign:utm_campaign,
        utm_source:utm_source,
        utm_medium:utm_medium,
        utm_content:utm_content,
        utm_term:utm_term
    };
};

export const storeCountry=(country,timezone,latitude,longitude)=>{
    return {
        type:'COUNTRY_STORE',
        country:country,
        timezone:timezone,
        latitude:latitude,
        longitude:longitude

    };
};

export const authStoreToken=(token)=>{
        // console.log("token in action",token)
        localStorage.setItem("qf:authToken", token);
        return setAuthToken(token);
        
};

export const authGetToken=(state,dispatch)=>{
    
         return new Promise(async(resolve,reject)=>{
            const token=state.token;
            // console.log("token inside authgettoken",token)
            if(!token)
            {
                let tokenFromStorage=await localStorage.getItem("qf:authToken")
                // console.log("tokenFromStorage from authgettoken",tokenFromStorage)
                dispatch(setAuthToken(tokenFromStorage));
                resolve(tokenFromStorage);
            }
            else
            {
                resolve(token);
            }
        })
}

export const authGetCredentials=(state,dispatch)=>{
    return new Promise(async(resolve,reject)=>{

        const id=state.user.id;
        const email=state.user.email;
        const username=state.user.username;
        const role=state.user.role;
        const city=state.user.city;
        const country=state.user.country;

        let idS=null,emailS=null,usernameS=null,roleS=null,cityS=null,countryS=null,statusCount=0;
        
        // console.log("outside ",id,email,username,role,city,country);
        if(id && email && role) 
        {
            // console.log("inside if before c",id,email,username,role,city,country);
            resolve({authGet:true,id,email,username,role,city,country});
        }

        if(!id)
        {
            // console.log("before count inside id",statusCount);
            let idFromStorage=await localStorage.getItem("qf:auth:userId")        
            // console.log("idFromStorage",idFromStorage)        
            idS=JSON.parse(idFromStorage);
            statusCount++;
        }

        if(!email)
        {
            // console.log("before count inside email",statusCount);
            let emailFromStorage=await localStorage.getItem("qf:auth:email")
            // console.log("emailFromStorage",emailFromStorage)        
            emailS=JSON.parse(emailFromStorage);
            statusCount++;
        }

        if(!role)
        {
            // console.log("before count inside role",statusCount);
            let roleFromStorage= await localStorage.getItem("qf:auth:role")  
            // console.log("roleFromStorage",roleFromStorage)        
            roleS=JSON.parse(roleFromStorage);
            statusCount++;

        }

        if(!city)
        {
            // console.log("before count inside city",statusCount);
            let cityFromStorage=await localStorage.getItem("qf:auth:city")
            cityS=JSON.parse(cityFromStorage);
        }

        if(!country)
        {
            // console.log("before count inside country",statusCount);
            
            let countryFromStorage=await localStorage.getItem("qf:auth:country")
            countryS=JSON.parse(countryFromStorage);
        }

        if(statusCount==3)
        {
            // console.log("from status count",idS,emailS,usernameS,roleS,cityS,countryS);
            dispatch(storeCredentials(idS,emailS,usernameS,roleS,cityS,countryS))
            resolve({authGet:true,id:idS,email:emailS,username:usernameS,role:roleS,city:cityS,country:countryS})
        }
        else
        {
            reject({authGet:false})
        }
    });
}

export const authLoadItemsFromStorageToCart=(dispatch)=>{
    return new Promise((resolve,reject)=>{
        try{
            // console.log("from authLoadItemsFromStorageToCart")
            let cartItems=JSON.parse(localStorage.getItem('qf:web:cart_items'))
            // console.log("cartItems from storage",cartItems)
            if(cartItems)
            {
                if(cartItems.length!==0)
                {
                    dispatch({type:'ADD_TO_CART',cart:cartItems})
                    dispatch({type:'UPDATE_CART_COUNT',cartCount:cartItems.length})
                }
            }
        }
        catch(c1)
        {
            console.log(c1)
        }
    })
}

export const authAutoSignin=(state,dispatch)=>{
    
        return new Promise(async(resolve,reject)=>{
        let token=await authGetToken(state,dispatch)
        // console.log("token inside authAutoSignin",token)
        if(token)
        {
            let status=await authGetCredentials(state,dispatch)
            if(status.authGet)
            {
                // console.log("auto sign in success",status);
                resolve({autoSignIn:true,token,id:status.id,email:status.email,username:status.username,role:status.role,city:status.city,country:status.country})
            }
            else
            {
                // console.log("auto sign in fail",status);
                resolve({autoSignIn:false})
            }
        }
        else
        {
            resolve({autoSignIn:false})
        }
    });
    
}

export const authClearStorage=()=>{

    return new Promise((resolve,reject)=>{
        try{
            localStorage.removeItem("qf:authToken");
            localStorage.removeItem("qf:auth:userId");
            localStorage.removeItem("qf:auth:email");
            localStorage.removeItem("qf:auth:username");
            localStorage.removeItem("qf:auth:city");
            localStorage.removeItem("qf:auth:country");
            localStorage.removeItem("qf:auth:role");

            resolve({authClear:true})
        }
        catch(c1)
        {
            resolve({authClear:false})
        }
    })

}

export const authLogOut=(dispatch)=>{
    return new Promise(async(resolve,reject)=>{
        try{
            let {authClear}=await authClearStorage()
            if(authClear)
            {
                dispatch(removeAuthToken())
                dispatch(removeCredentials())
                resolve(true)
            }
            else
            {
                resolve(false)
            }
        }
        catch(c1)
        {
            console.log(c1)
            resolve(false)
        }
        
    })
}

export const storeCredentialsLocalStorage=(id,email,username,role,city,country)=>{
    
        localStorage.setItem("qf:auth:userId",JSON.stringify(id));
        localStorage.setItem("qf:auth:email",JSON.stringify(email));
        localStorage.setItem("qf:auth:username",JSON.stringify(username));
        localStorage.setItem("qf:auth:role",JSON.stringify(role));
        localStorage.setItem("qf:auth:city",JSON.stringify(city));
        localStorage.setItem("qf:auth:country",JSON.stringify(country));

        return storeCredentials(id,email,username,role,city,country)
}

export const addToCart=(cartItem,state,dispatch)=>{
        let prevCart=DeepClone(state.cart),newCart=[]

        //// Checking for change in pro and clearing cart if pro has been changed
        let proChangeCheck=false
        prevCart.map(prevCartItem=>{
            if(prevCartItem.QF_MERCHANT_PERSONNEL_PRID!==cartItem.QF_MERCHANT_PERSONNEL_PRID)
            {
                proChangeCheck=true
            }
        })

        if(proChangeCheck)
        {
            newCart.push(cartItem)
        }
        else
        {
            newCart=DeepClone(prevCart.filter((item,index)=>{
                return item.QF_MERCHANT_SERVICE_PRID!==cartItem.QF_MERCHANT_SERVICE_PRID
            }))
            // console.log("add to cart",JSON.stringify(cartItem))
            newCart.push(cartItem)
            // console.log("newCart after",JSON.stringify(newCart),"\n")
        }
        
         dispatch({type:'ADD_TO_CART',cart:DeepClone(newCart)})
         dispatch({type:'UPDATE_CART_COUNT',cartCount:newCart.length})

         localStorage.setItem("qf:web:cart_items",JSON.stringify(newCart));
         localStorage.setItem("qf:web:cart_count",JSON.stringify(newCart.length));
}

export const removeFromCart=(cartItem,state,dispatch)=>{
    let prevCart=DeepClone(state.cart)

    prevCart=prevCart.filter((item,index)=>{
        return item.QF_MERCHANT_SERVICE_PRID!==cartItem.QF_MERCHANT_SERVICE_PRID
    })
    // console.log("prevCart from remove",prevCart)
    
    dispatch({type:'REMOVE_FROM_CART',cart:DeepClone(prevCart)})
    dispatch({type:'UPDATE_CART_COUNT',cartCount:prevCart.length})

    localStorage.removeItem("qf:web:cart_items",JSON.stringify(prevCart));
    localStorage.removeItem("qf:web:cart_count",JSON.stringify(prevCart.length));
}

export const updateMultipleInCart=(cartitems,state,dispatch)=>{

    let newCart=DeepClone(cartitems)

    dispatch({type:'ADD_TO_CART',cart:newCart})
    dispatch({type:'UPDATE_CART_COUNT',cartCount:newCart.length})

    localStorage.setItem("qf:web:cart_items",JSON.stringify(newCart));
    localStorage.setItem("qf:web:cart_count",JSON.stringify(newCart.length));

}

export const clearCart=(dispatch)=>{
    
    // console.log("from clear cart")
    
    dispatch({type:'REMOVE_FROM_CART',cart:[]})
    dispatch({type:'UPDATE_CART_COUNT',cartCount:0})

    localStorage.removeItem("qf:web:cart_items",JSON.stringify([]));
    localStorage.removeItem("qf:web:cart_count",JSON.stringify(0));
}

export const storeServices=(services,categories,subcategories,dispatch)=>{
    // console.log("from store services",categories.length,subcategories.length,services.length)
    dispatch({type:'SET_SERVICES',services})
    dispatch({type:'SET_CATEGORIES',categories})
    dispatch({type:'SET_SUBCATEGORIES',subcategories})
    // dispatch({type:'SET_MER_LOC_PRIDS',merLocFront:merLocPridArr})
    dispatch({type:'SET_DISTANCE_MIN',distanceMin:0})
    dispatch({type:'SET_DISTANCE_MAX',distanceMax:10})
    dispatch({type:'SET_DISTANCE_CHECK_MAX',distanceCheckMax:10})

    // localStorage.setItem("qf:web:category_items",JSON.stringify(categories))
    // localStorage.setItem("qf:web:subcategory_items",JSON.stringify(subcategories))
    // localStorage.setItem("qf:web:service_items",JSON.stringify(services))
}

export const clearServices=(dispatch)=>{
    // console.log("from clear services")
    dispatch({type:'SET_SERVICES',services:[]})
    dispatch({type:'SET_CATEGORIES',categories:[]})
    dispatch({type:'SET_SUBCATEGORIES',subcategories:[]})
    // dispatch({type:'SET_MER_LOC_PRIDS',merLocFront:[]})
    dispatch({type:'SET_DISTANCE_MIN',distanceMin:0})
    dispatch({type:'SET_DISTANCE_MAX',distanceMax:10})
    dispatch({type:'SET_DISTANCE_CHECK_MAX',distanceCheckMax:10})

    localStorage.removeItem("qf:web:service_items",JSON.stringify([]))
    localStorage.removeItem("qf:web:category_items",JSON.stringify([]))
    localStorage.removeItem("qf:web:subcategory_items",JSON.stringify([]))
}

export const loadServicesFromStorage=(dispatch,urlPath)=>{
    return new Promise((resolve,reject)=>{
        try{
            // let path=useLocation().pathname
            // console.log("from loadServicesFromStorage")
            let serviceItems=JSON.parse(localStorage.getItem('qf:web:service_items'))
            let catItems=JSON.parse(localStorage.getItem('qf:web:category_items'))
            let subcatItems=JSON.parse(localStorage.getItem('qf:web:subcategory_items'))

            // console.log("services from storage",catItems.length,subcatItems.length,serviceItems.length)
            if(urlPath==='/')
            {
                clearServices(dispatch)
                // console.log("clearing services from storage")
            }
            else
            {
                // console.log("from loading services else")
                if(serviceItems)
                {
                    if(serviceItems.length!==0)
                    {
                        dispatch({type:'SET_SERVICES',services:serviceItems})
                    }
                }
                if(catItems)
                {
                    if(catItems.length!==0)
                    {
                        dispatch({type:'SET_CATEGORIES',categories:catItems})
                    }
                }
                if(subcatItems)
                {
                    if(subcatItems.length!==0)
                    {
                        dispatch({type:'SET_SUBCATEGORIES',subcategories:subcatItems})
                    }
                }
            }
            
        }
        catch(c1)
        {
            console.log(c1)
        }
    })
}
export const storeAddress=(totalAddress,addressLine1,country,city,stateOrProvince,zipcode,district,latitude,longitude,currentAddress,searchAddress,currentLocationDenied,dispatch)=>{
    // console.log("from store address",totalAddress,addressLine1,country,city,stateOrProvince,zipcode,district,latitude,longitude,currentAddress,searchAddress,currentLocationDenied)
    dispatch
    ({
        type: 'ADDRESS_STORE',
        totalAddress,
        addressLine1,
        country,
        city,
        stateOrProvince,
        zipcode,
        district,
        latitude,
        longitude,
        currentAddress,
        searchAddress,
        currentLocationDenied
    })
    let addressData={
        totalAddress:totalAddress,
        addressLine1:addressLine1,
        country:country,
        city:city,
        stateOrProvince:stateOrProvince,
        zipcode:zipcode,
        district:district,
        latitude:latitude,
        longitude:longitude,
        currentAddress:currentAddress,
        searchAddress:searchAddress,
        currentLocationDenied:currentLocationDenied
    }
    //  console.log('address data',addressData)
    // localStorage.setItem("qf:web:address_store",JSON.stringify(addressData))
    
}


export const clearAddress=(dispatch)=>{
    // console.log("from clear services")
    dispatch
    ({
        type:'ADDRESS_STORE',
        totalAddress:null,
        addressLine1:null,
        country:null,
        city:null,
        stateOrProvince:null,
        zipcode:null,
        district:null,
        latitude:null,
        longitude:null,
        currentAddress:true,
        searchAddress:false,
        currentLocationDenied:false
    })
    localStorage.removeItem("qf:web:address_store",JSON.stringify({}))
}

export const loadAddressFromStorage=(dispatch,urlPath)=>{
    return new Promise((resolve,reject)=>{
        try{
            // console.log("from loadAddressFromStorage")
            let addressItem=JSON.parse(localStorage.getItem("qf:web:address_store"))
            if(urlPath==='/'){
                clearAddress(dispatch)
            }else{
                if(addressItem)
                {
                    // console.log('get items',addressItem)
                    if(Object.keys(addressItem).length!==0)
                    {
                        dispatch
                        ({
                            type:'ADDRESS_STORE',
                            totalAddress:addressItem.totalAddress,
                            addressLine1:addressItem.addressLine1,
                            country:addressItem.country,
                            city:addressItem.city,
                            stateOrProvince:addressItem.stateOrProvince,
                            zipcode:addressItem.zipcode,
                            district:addressItem.district,
                            latitude:addressItem.latitude,
                            longitude:addressItem.longitude,
                            currentAddress:addressItem.currentAddress,
                            searchAddress:addressItem.searchAddress,
                            currentLocationDenied:addressItem.currentLocationDenied
                        })
                    }
                }
            }
        }
        catch(c1)
        {
            console.log(c1)
        }
    })
}
export const storeDateAndTime=(fromDateTime,toDateTime,dispatch)=>{
    dispatch({type:'SET_FROM_DATE_TIME',fromDateTime})
    dispatch({type:'SET_TO_DATE_TIME',toDateTime})
    // localStorage.setItem("qf:web:fromDateTime",JSON.stringify(moment(fromDateTime).format('YYYY-MM-DD HH:MM:SS')))
    // localStorage.setItem("qf:web:toDateTime",JSON.stringify(moment(toDateTime).format('YYYY-MM-DD HH:MM:SS')))
}
export const clearDateAndTime=(dispatch)=>{
    dispatch({type:'SET_FROM_DATE_TIME',fromDateTime:{}})
    dispatch({type:'SET_TO_DATE_TIME',toDateTime:{}})

    localStorage.removeItem("qf:web:fromDateTime",JSON.stringify({}))
    localStorage.removeItem("qf:web:toDateTime",JSON.stringify({}))
    
}
export const loadDateAndTimeFromStorage=(dispatch)=>{
    return new Promise((resolve,reject)=>{
        try{
            // let path=useLocation().pathname
            let fromDateTimeItem=JSON.parse(localStorage.getItem('qf:web:fromDateTime'))
            let toDateTimeItem=JSON.parse(localStorage.getItem('qf:web:toDateTime'))
                if(fromDateTimeItem)
                {
                    if(fromDateTimeItem.length!==0)
                    {
                        dispatch({type:'SET_FROM_DATE_TIME',fromDateTime:fromDateTimeItem})
                    }
                }
                if(toDateTimeItem)
                {
                    if(toDateTimeItem.length!==0)
                    {
                        dispatch({type:'SET_TO_DATE_TIME',toDateTime:toDateTimeItem})
                    }
                }
            
        }
        catch(c1)
        {
            console.log(c1)
        }
    })
}