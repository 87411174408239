import React from "react";
import '../../../CSS/UI Components/RefundStatusCard.css';
import RefundIcon from '../../../Assets/groupRefundIcon.png';
import Tick_Image from '../../../Assets/status_tick.png'

export default function RefundStatusCard(props){
    return (
        <div className="RefundStatusButton_DivStyle" style={{backgroundColor:props.colourStyle}}>
        {/* <div onClick={() => navigation.navigate('ProfessionalDiscounts', {proPersonelIds,proMerchantIds,serviceIds,services, totalAmount, country, totalCPPrice, totalTSPrice, couponApplied, couponCode, navFrom:'Checkout',userPasswordReg:params.userPasswordReg?params.userPasswordReg:false,userId:params.userId?params.userId:null})}> */}

            <div style={{flexDirection:'row'}}>
                <img src={RefundIcon} />
                <text className="CouponButton_TextStyle">{props.titleText}</text>
            </div>

            <div>
                <img src={Tick_Image} style={{alignSelf:'flex-end'}}/>
            </div>
        </div>
    )
}