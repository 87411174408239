import React, { useState, useEffect, useContext } from 'react';
import '../../../CSS/UI Components/DiscountCard.css';
import Pro from '../../../Assets/slash_new_logo_coupon.png'
import backgroundImage1 from '../../../Assets/proDiscountpink.png'
import backgroundImage2 from '../../../Assets/proDiscountblue.png'
import backgroundImage3 from '../../../Assets/proDiscountyellow.png'
import moment from 'moment';

function DiscountCardColoured(props) {
  return (

    props.colour == "#ff5d5d" ?

    <div className='coloured_c' style={{backgroundImage: `url(${backgroundImage1})`, backgroundSize:'cover', backgroundRepeat: 'no-repeat'}}>
      <div className='coupon_card_mid_coloured_div'>
     
        <div className="imageDivStyle_coloured">
          {/* <img src={Pro} className="imagestyle" /> */}
          <div className='imagestyle_coloured_div' />
        </div>

        <div>
          <div className="line_coupon_card" />
        </div>

        <div className='coupom_card_off_details'>
          {/* <text className=""{labelStyle1}>{props.name}</text> */}
          <div>
            <text className="uptoText">UPTO</text>
          </div>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start',alignItems:'flex-end'}}>
              <div style={{display:'flex',justifyContent:'flex-end'}}>
                <text className="off">{ props.coupon_percent ? props.off + "% " : ((props.country == 'IN' ?'\u20B9' : "$" ) + props.off )}</text>  
                {/* <text className="off">50</text> */}
              </div>
              {/* <div style={{display:'flex',justifyContent:'flex-end'}}>
                <text className="off">{props.coupon_percent && '%' }</text>
              </div> */}
              <div style={{display:'flex',justifyContent:'flex-end'}}>
                <text className="off">Off</text>
              </div>
          </div>
          <div className='coupom_card_code_div'>
            <text className="code">{props.code}</text>
            {/* <text className="code_coloured">HFHFHGNV</text> */}
          </div>
          {/* <text className={'date'}>{props.date}</text> */}
          {/* <div className='validity_div'>
            <text className='coloured_validity'>Valid until 6th April 2023</text>
          </div> */}
          {
            props.date !== null ? 
              <div className='validity_div'>
                <text className='coloured_validity'>Valid until + {(moment(props.date).format('DD MMM YYYY'))}</text>
              </div>
              :null
          }
        </div>


      </div>

    </div>

    : props.colour == "#6750a3" ? 

    <div className='coloured_c' style={{backgroundImage: `url(${backgroundImage2})`, backgroundSize:'cover', backgroundRepeat: 'no-repeat'}}>
      <div className='coupon_card_mid_coloured_div'>
     
        <div className="imageDivStyle_coloured">
          {/* <img src={Pro} className="imagestyle" /> */}
          <div className='imagestyle_coloured_div' />
        </div>

        <div>
          <div className="line_coupon_card" />
        </div>

        <div className='coupom_card_off_details'>
          {/* <text className=""{labelStyle1}>{props.name}</text> */}
          <div>
            <text className="uptoText">UPTO</text>
          </div>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start',alignItems:'flex-end'}}>
              <div style={{display:'flex',justifyContent:'flex-end'}}>
                <text className="off">{ props.coupon_percent ? props.off + "% " : ((props.country == 'IN' ?'\u20B9' : "$" ) + props.off )}</text>  
                {/* <text className="off">50</text> */}
              </div>
              <div style={{display:'flex',justifyContent:'flex-end'}}>
                {/* <text className="off">%</text> */}
              </div>
              <div style={{display:'flex',justifyContent:'flex-end'}}>
                <text className="off">Off</text>
              </div>
          </div>
          <div className='coupom_card_code_div'>
            <text className="code">{props.code}</text>
            {/* <text className="code_coloured">HFHFHGNV</text> */}
          </div>
          {/* <text className=""{date}>{props.date}</text> */}
          {
            props.date !== null ? 
              <div className='validity_div'>
                <text className='coloured_validity'>Valid until + {(moment(props.date).format('DD MMM YYYY'))}</text>
              </div>
              :null
          }
          {/* <div className='validity_div'>
            <text className='coloured_validity'>Valid until {props.date}</text>
          </div> */}
        </div>


      </div>

    </div>

    :

    <div className='coloured_c' style={{backgroundImage: `url(${backgroundImage3})`, backgroundSize:'cover', backgroundRepeat: 'no-repeat'}}>
      <div className='coupon_card_mid_coloured_div'>
     
        <div className="imageDivStyle_coloured">
          {/* <img src={Pro} className="imagestyle" /> */}
          <div className='imagestyle_coloured_div' />
        </div>

        <div>
          <div className="line_coupon_card" />
        </div>

        <div className='coupom_card_off_details'>
          {/* <text className=""{labelStyle1}>{props.name}</text> */}
          <div>
            <text className="uptoText">UPTO</text>
          </div>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start',alignItems:'flex-end'}}>
              <div style={{display:'flex',justifyContent:'flex-end'}}>
                <text className="off">{ props.coupon_percent ? props.off + "% " : ((props.country == 'IN' ?'\u20B9' : "$" ) + props.off )}</text>  
                {/* <text className="off">50</text> */}
              </div>
              <div style={{display:'flex',justifyContent:'flex-end'}}>
                <text className="off">%</text>
              </div>
              <div style={{display:'flex',justifyContent:'flex-end'}}>
                <text className="off">Off</text>
              </div>
          </div>
          <div className='coupom_card_code_div'>
            <text className="code">{props.code}</text>
            {/* <text className="code_coloured">HFHFHGNV</text> */}
          </div>
          {/* <text className=""{date}>{props.date}</text> */}
          {
            props.date !== null ? 
              <div className='validity_div'>
                <text className='coloured_validity'>Valid until + {(moment(props.date).format('DD MMM YYYY'))}</text>
              </div>
              :null
          }
        </div>


      </div>

    </div>

  );
}

export default DiscountCardColoured;