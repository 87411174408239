import React from 'react'
import { Form, Input, FormGroup, FormText } from 'reactstrap'
// import{ AiOutlineSearch} from 'react-icons/ai'
import searchIcon from '../../../Assets/outline-interface-search.png'
import crossIcon from '../../../Assets/group-383.png'
// import { RxCross2 } from 'react-icons/rx'
// import { TextInput } from 'react-native-gesture-handler'
export default function SearchBar(props) {
   
    return (
            <div style={{ flexDirection: 'row', display: 'flex', height: 48,width: props.width}}>
                <div style={searchIconStyle}>
                    <img src={searchIcon} style={searchImgStyle} />
                </div>
                <Input
                    // placeholderTextColor='#9d9d9d'
                    style={searchBoxOfferStyle}
                    onChange={props.onChange}
                    value={props.value}
                    placeholder={props.placeholder}
                    onSubmitEditing={props.onSubmitEditing}
                    // returnKeyType={props.returnKeyType}
                    className='shadow-none'
                    id="inputID"
                    
                />
                <div style={micStyle}>
                    {
                    props.searchTextService && <div style={{ justifyContent: 'center',backgroundColor: 'white',cursor:'pointer' }} onClick={props.onClickCross}>
                        <img src={crossIcon} onClick={props.onPressCross} style={searchImgStyle} />
                    </div>
                    }
                </div>
            </div>
)
}
let searchImgStyle = {
    width: 23,
    height: 24,
    // flexGrow: 0,
    // margin: 0 16px 0 0;
}
let searchIconStyle = {
    height: 48,
    padding: 10,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    // backgroundColor: "rgba(245, 245, 245, 0.6)", 
    shadowColor: "rgba(103, 52, 204, 0.1)",
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 1,
    borderStyle: "solid",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 0,
    backgroundColor: 'white',
    borderColor: "rgba(140, 82, 255, 0.6)"
}
let micStyle = {
    height: 48,
    padding: 10,
    // backgroundColor: "rgba(245, 245, 245, 0.6)", 
    shadowColor: "rgba(103, 52, 204, 0.1)",
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 1,
    borderStyle: "solid",
    borderColor: "rgba(140, 82, 255, 0.6)",
    borderLeftWidth: 0,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
}
let searchBoxOfferStyle = {
    flex: 1,
    height: 48,
    padding: 10,
    borderRadius: 0,
    // backgroundColor: "rgba(245, 245, 245, 0.6)",
    shadowColor: "rgba(103, 52, 204, 0.1)",
    shadowOffset: {
        width: 0,
        height: 2
    },
    shadowRadius: 4,
    shadowOpacity: 1,
    borderStyle: "solid",
    // borderWidth: 0.5,
    borderColor: "rgba(140, 82, 255, 0.6)",
    // borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 1,
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#9f9f9f",
    // borderShadow: 'none'

    // placeholderTextColor:'rgb(157, 157, 157)'
}
