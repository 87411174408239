import React from 'react'
import {Card , CardBody, CardImg, CardText, Col, Row} from 'reactstrap'
import '../../../CSS/UI Components/NearByProCards.css'
import StarOutline from '../../../Assets/asicon-outline-star.png'
import numeral from 'numeral'
import WishlistIcon from '../../../Assets/ph-heart-bold.png'
import WishlistYes from '../../../Assets/iconFavorite.png'
import LocationIcon from '../../../Assets/icon-location-on.png'
export default function NearByProCards(props) {
    // console.log('props in near by pros',props)
let locationValue=props.data.MERCHANT_PERSONNEL_ADDRESS_LINE2.length > 18 ? props.data.MERCHANT_PERSONNEL_ADDRESS_LINE2.substr(0,19) : props.data.MERCHANT_PERSONNEL_ADDRESS_LINE2 
  return (
    <Col>
    <Card className="nearbyProCard" style={{height:props.height}} >
        {
        props.state.token &&
        <div className='wishlistProCardBg' onClick={props.WishlistPress}>
            {props.wishListed ? <img src={WishlistYes} style={{width:18,height:18}}/> : <img src={WishlistIcon}/>}
        </div>
        }
        <div  onClick={props.onClickCard}>
            <div className='nearByProAdd_Overlay'>
                <div style={{flexDirection:'row',display:'flex',paddingLeft:10,paddingRight:10,paddingTop:5,paddingBottom:5}}>
                    <img src={LocationIcon} className='locationIcon_nearByPro'/>
                    <text className='nearByPro_addressText' style={{marginLeft:10}}>{locationValue + '.....'}</text>
                </div> 
            </div>
            <CardImg src={props.data.image_link} className='nearbyProCardImg'/>
            <CardBody style={{padding:'15px 0px 0px 0px'}}>
                <div style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                    {/* <div style={{flexDirection:'column',display:'flex'}}> */}
                        <text className='nearByProCardNameText'>
                            {props.data.MERCHANT_NAME}
                        </text>
                    {/* </div> */}
                    {/* <div style={{flexDirection:'column',display:'flex'}}> */}
                        <text className='distanceTextPro'>{props.data.MERCHANT_PERSONNEL_ADDRESS_COUNTRY == 'India' ? numeral(props.data.distance).divide(1000).format('0.00') + ' km' : numeral(props.data.distance).divide(1609.344).format('0.00') + ' mi'}</text>
                    {/* </div> */}
                </div>
                <div style={{flexDirection:'row',display:'flex',paddingTop:6}}>
                    <img src={StarOutline} className='starIcon_nearByPros'/>
                    <text className='ratingAndReviews'>{props.data.MERCHANT_PERSONNEL_RATING ? numeral(props.data.MERCHANT_PERSONNEL_RATING).format('0.0'):''}</text>
                </div>
              
            </CardBody>
        </div>
    </Card>
    </Col>
  )
}
