import React,{useEffect,useContext,useState} from "react";
import {useNavigate,useLocation,useParams} from 'react-router-dom'
import customFetchData from "../../Components/Custom/customFetchData";
import { globalState } from "../../GlobalState/globalState";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../config";
import randStringS from 'random-string' 
import curr from "currency.js";
import { clearCart } from '../../GlobalState/globalStateActions';
import useBackButton from "../../Components/Custom/useBackButton";
const DeepClone = require("rfdc/default")

export default function StripeV1(){
    let navigate=useNavigate()

    const [state,dispatch]=useContext(globalState)
    const [loading,setLoading]=useState(true)
    const [session,setSession]=useState("")
    const [sessionStart,setSessionStart]=useState(false)
    const [sessionError,setSessionError]=useState(false)
    const [showCancelModal,setShowCancelModal]=useState(false)
    const [showTimeoutModal,setShowTimeoutModal]=useState(false)
    const [maxCouponReachedModal,setMaxCouponReachedModal]=useState(false)
    const [maxOfferReachedModal,setMaxOfferReachedModal]=useState(false)
    const [payCompleteModal,setPayCompleteModal]=useState(false)
    const [couponExpireModal,setCouponExpireModal]=useState(false)
    const [activityType,setActivityType]=useState("")
    const [paymentFailedModal,setPaymentFailedModal]=useState(false)
    const [paymentFailDesc,setPaymentFailDesc]=useState("")
    const [payErrorObj,setPayErrorObj]=useState({})
    const [hashGen,setHashGen]=useState("")

    let timer=false
    let randString=''

    const params=useLocation().state
    useEffect(async()=>{
        
        let services=DeepClone(params.services)
        console.log('PARAMS IN STRIPE V1',params);
        let rs=randStringS({length:20}),timeOut;
        let env_system;
        if(config.localhost_url=='http://ec2-54-149-62-15.us-west-2.compute.amazonaws.com:8089')
        {
            env_system='dev_server'
        }
        else if(config.localhost_url=="http://ec2-34-214-32-243.us-west-2.compute.amazonaws.com:8089")
        {
            env_system='testing'
        }
        else if(config.localhost_url=="https://load.goslash.com")
        {
            env_system='production'
        }
        else
        {
            env_system='local'
        }
        randString=rs;

        let bodyData={
          refData:{
            randString:rs,
            consumer_id:state.user.id ? state.user.id : null,
            env_system,
            mpid:services[0].QF_MERCHANT_PERSONNEL_PRID,
            clp:params.c_l_prid,
            service_timezone:services[0].SERVICE_TIMEZONE,
          },
          services,
          serviceIds:params.serviceIds,
          accCount:services.length,
          mCouponId:params.couponType==="MCoupon"?params.couponID:0,
          oCouponId:params.couponType==="OCoupon"?params.couponID:0,
          sCouponId:params.couponType==="SCoupon"?params.couponID:0,
          mCoupon:params.couponType==="MCoupon"?true:false,
          oCoupon:params.couponType==="OCoupon"?true:false,
          sCoupon:params.couponType==="SCoupon"?true:false,
          totalCouponDiscount:params.totalCouponDiscount?params.totalCouponDiscount:0,
          randString:rs,
          consumer_id:state.user.id ? state.user.id : null,
          amount:curr(params.totalAmount).multiply(100).value,
          consumer_email:state.user.email?state.user.email:params.email,
          pay:true,
          check:false,
          loggedIn:state.token?true:false,
          userPasswordReg:false
        }
        let payData={
            pay:false,
            check:true,
            consumer_id:state.user.id ? state.user.id : null,
            randString:rs
        }


        let {fetchedData}=await customFetchData('POST',bodyData,'/website/payments/stripe/sv2',state,dispatch)
        // console.log("fetchedData from pay:true",fetchedData) 
            if(fetchedData.sessionCheck)
            {
                //// checkout session started
                // clearCart(dispatch)
                window.location.replace(fetchedData.sessionUrl)
                //// checking with server whether webhook hit our server and details are instered or not


            }
            else
            {
                if(fetchedData.couponCountReached)
                {
                    setMaxCouponReachedModal(true)
                }
                else if(fetchedData.offerCountReached)
                {
                    setMaxOfferReachedModal(true)
                }
                else if(fetchedData.couponDeleted || fetchedData.couponDeactivated || fetchedData.couponPast)
                {
                    setCouponExpireModal(true)
                }
                else
                {
                    toast.error("Something went wrong.Please try again", {position:toast.POSITION.BOTTOM_CENTER});
                    // navigation.goBack();
                }
                setSessionError(false)
                
            }
            setLoading(false)
        


    },[])
   

    // useEffect(async()=>{
    //     // https://checkout.stripe.com/c/pay/cs_test_a1th5k1SygM2tlZedaNJBhW4ORpA9MZ34GjH99VY0Nhx7wWUjyOVySpCru#fidkdWxOYHwnPyd1blpxYHZxWkhmYT18REtrTGJQfGdNR0JUPVVtbWpAdScpJ2N3amhWYHdzYHcnP3F3cGApJ2lkfGpwcVF8dWAnPyd2bGtiaWBabHFgaCcpJ2BrZGdpYFVpZGZgbWppYWB3dic%2FcXdwYHgl
    //     console.log("inside useffect")
    //     let {data}=await axios.post('/website/payments/stripe/us/v1',{})
    //     console.log("fetcheddata",data)
    //     window.location.replace(data.session.url)

    // },[])


    return(
        <div>
            <text>Stripe</text>
        </div>
    )
}