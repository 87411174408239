import React, { useState, useEffect, useContext } from 'react';
import '../../../CSS/UI Components/DiscountCard.css';
import Pro from '../../../Assets/slash_new_logo_coupon.png'
import moment from 'moment';

function DiscountCard(props) {
  return (

    // <LinearGradient colors={['#8c52ff', '#D8C5FC']} start={{ x: 0, y: 1 }} end={{ x: 1, y: 1 }} className=""{c}>
    <div className='c' onClick={() => props.onClick(props.code)}>
      <div className="c1">
        <div className="circle"/>
      </div>
      <div className='coupon_card_mid_div'>
     
        <div className="imageDivStyle">
          <img src={Pro} className="imagestyle" />
        </div>

        <div><div className="line_coupon_card" /></div>

        <div className='coupom_card_off_details'>
          {/* <text className="labelStyle1">{props.name}</text> */}
          <div>
            {props.coupon_percent ? 
              <text className="uptoText">UPTO</text>
              :
              <text className="uptoText">FLAT</text>
            }
          </div>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start',alignItems:'center'}}>
              <div style={{display:'flex',justifyContent:'flex-end'}}>
                {props.coupon_percent ? 
                  <text className="off">{props.off}</text>  
                  :
                  <text className="off">{props.country == 'IN' ? '\u20B9' : '$'}{props.off}</text>  
                }
              </div>
              {props.coupon_percent ?
              <div style={{display:'flex',justifyContent:'flex-end'}}>
                <text className="off">%</text>
              </div> : null}
              <div style={{display:'flex',justifyContent:'flex-end'}}>
                <text className="off">Off</text>
              </div>
          </div>
          <div className='coupom_card_code_div'>
            <text className="code">{props.code}</text>
          </div>
          {/* <text className=""{date}>{props.date}</text> */}
          {props.date ? 
            <div className='validity_div'>
              <text className='coloured_validity'>Valid until {moment(props.date).format('DD MMM YYYY')}</text>
            </div>
            :null
          }
        </div>


      </div>
      <div className="c2">
        <div className="circle" />
      </div>

    </div>


  );
}

export default DiscountCard;