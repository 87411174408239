import React,{useEffect,useContext,useState} from "react";
import {useNavigate,useLocation,useParams} from 'react-router-dom'
import customFetchData from "../../Components/Custom/customFetchData";
import { globalState } from "../../GlobalState/globalState";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../config";
import randStringS from 'random-string' 
import curr from "currency.js";
import { clearCart,clearServices,clearDateAndTime } from '../../GlobalState/globalStateActions';
import useBackButton from "../../Components/Custom/useBackButton";
import { ClipLoader } from 'react-spinners';

const DeepClone = require("rfdc/default")

export default function RazorpayV1()
{
    let navigate=useNavigate()
    const [state,dispatch]=useContext(globalState)
    const [loading,setLoading]=useState("")
    const [showCancelModal,setShowCancelModal]=useState(false)
    const [showTimeoutModal,setShowTimeoutModal]=useState(false)
    const [maxCouponReachedModal,setMaxCouponReachedModal]=useState(false)
    const [maxOfferReachedModal,setMaxOfferReachedModal]=useState(false)
    const [payCompleteModal,setPayCompleteModal]=useState(false)
    const [couponExpireModal,setCouponExpireModal]=useState(false)
    const [activityType,setActivityType]=useState("")
    const [paymentFailedModal,setPaymentFailedModal]=useState(false)
    const [paymentFailDesc,setPaymentFailDesc]=useState("")
    const [payErrorObj,setPayErrorObj]=useState({})
    const [orderId,setOrderId]=useState('')
    const [customerId,setCustomerId]=useState('')
    const [ locationKeys, setLocationKeys ] = useState([])
    const [loadingL,setLoadingL]=useState(true)
    // console.log("outside useffect",window.Razorpay,state)
    let timer=false
    let randString='',timeInterval=null,timeOut=null

    // let isBack=useBackButton()
    // const handleBeforeUnload = (e) => {
    //     e.preventDefault();
    //     const message =
    //       "Are you sure you want to leave? All provided data will be lost.";
    //     e.returnValue = message;
    //     return message;
    //   }

    // useEffect(() => {
    //     window.addEventListener("beforeunload", handleBeforeUnload);
    //     return () => {
    //       window.removeEventListener("beforeunload", handleBeforeUnload);
    //     };
    //   }, []);
    // console.log("isBack",isBack)

    const params=useLocation().state;
    let rs=randStringS({length:20});

    useEffect(()=>{    
        fetchPayData()
    },[])

    const fetchPayData=async()=>{
        console.log('PARAMS IN razorpayv1',params);
        
        let services=DeepClone(params.services)
        

        let env_system;
        if(config.localhost_url=='http://ec2-54-149-62-15.us-west-2.compute.amazonaws.com:8089')
        {
            env_system='dev_server'
        }
        else if(config.localhost_url=="http://ec2-34-214-32-243.us-west-2.compute.amazonaws.com:8089")
        {
            env_system='testing'
        }
        else if(config.localhost_url=="https://load.goslash.com")
        {
            env_system='production'
        }
        else
        {
            env_system='local'
        }

        randString=rs

        let bodyData={
          refData:{
            randString:rs,
            consumer_id:state.user.id ? state.user.id : null,
            env_system,
            mpid:services[0].QF_MERCHANT_PERSONNEL_PRID,
            clp:params.c_l_prid, //need to change
            service_timezone:services[0].SERVICE_TIMEZONE,
          },
          services,
          serviceIds:params.serviceIds,
          accCount:services.length,
          mCouponId:params.couponType==="MCoupon"?params.couponID:0,
          oCouponId:params.couponType==="OCoupon"?params.couponID:0,
          sCouponId:params.couponType==="SCoupon"?params.couponID:0,
          mCoupon:params.couponType==="MCoupon"?true:false,
          oCoupon:params.couponType==="OCoupon"?true:false,
          sCoupon:params.couponType==="SCoupon"?true:false,
          totalCouponDiscount:params.totalCouponDiscount?params.totalCouponDiscount:0,
          randString:rs,
          pay:true,
          check:false,
          loggedIn:state.token?true:false,
          userPasswordReg:false,
          totalGST:params.totalGST, // need to change
          margin_prid:params.margin_prid,
          finalAmountToPg:curr(params.totalAmount).multiply(100).value,
        }
        


        let {fetchedData}=await customFetchData('POST',bodyData,'/website/payments/razorpay/sv2',state,dispatch)
        // console.log("fetchedData from pay:true",fetchedData)
                    
            if(fetchedData.sessionCheck)
            {
                //// checkout session started
                
                timer=true
                setOrderId(fetchedData.order_id)
                setCustomerId(fetchedData.customer_id)
                onLoadRazorPay(fetchedData.order_id,fetchedData.customer_id)
            }
            else
            {
                if(fetchedData.couponCountReached)
                {
                    setMaxCouponReachedModal(true)
                }
                else if(fetchedData.offerCountReached)
                {
                    setMaxOfferReachedModal(true)
                }
                else if(fetchedData.couponDeleted || fetchedData.couponDeactivated || fetchedData.couponPast)
                {
                    setCouponExpireModal(true)
                }
                else
                {
                    toast.error("Something went wrong.Please try again", {position:toast.POSITION.BOTTOM_CENTER});
                    // navigate.goBack();
                }
            }
            setLoading(false) 
    }

    const onLoadRazorPay=(order_id,customer_id,consumer_phone)=>{
        var options = {
            "key": "rzp_live_Ap5Uv7VPPyeroj", // Enter the Key ID generated from the Dashboard
            "amount": curr(params.totalAmount).multiply(100).value, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "Slash", //your business name
            "description": "",
            "order_id": order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "customer_id":customer_id?customer_id:'',
            // "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
            "prefill": {
                "email": "",
                "contact": ""
            },
            "handler": function (response){
                // console.log("payment success "+response.razorpay_payment_id+" "+response.razorpay_order_id+" "+response.razorpay_signature);
                handleAfterPayment(response.razorpay_payment_id,response.razorpay_order_id,response.razorpay_signature)
            },
            "remember_customer":true,
            "timeout":180,
            "modal":{
                "escape":false,
                "confirm_close":true,
                "ondismiss":()=>{console.log("dismissed checkout");onCancelTransaction(rs)},
                "handleback":false
            },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response){
            // alert("error",response.error);
            // console.log("pay cancel or fail",response)
    });
        rzp1.open();
    }

    const handleAfterPayment=async()=>{
        // console.log("handleAfterPayment",rs)
        timeInterval=setInterval(async()=>{
            let payData={
                pay:false,
                check:true,
                consumer_id:state.user.id ? state.user.id : null,
                randString:rs
            }
            let {fetchedData}=await customFetchData('POST',payData,'/website/payments/razorpay/sv2',state,dispatch)
            // console.log("fetchedData pay staus check",fetchedData)
            if(fetchedData.spay)
                {
                    // console.log("payment success");
                    clearInterval(timeInterval);
                    clearServices(dispatch)
                    clearCart(dispatch)
                    clearDateAndTime(dispatch)
                    // toast.success("Payment successful", {position:toast.POSITION.BOTTOM_CENTER})
                    navigate('/c/mybookings',{replace:true}) 

                }
                else 
                {
                    if(fetchedData.dbError)
                    {
                        // console.log("dbError");
                        clearInterval(timeInterval);
                        toast.error("Internal Error. Please check your transactions page for your payment status", {position:toast.POSITION.BOTTOM_CENTER}); 
                        navigate('/c/mybookings',{replace:true}) 
                    }
                    else if(fetchedData.pending)
                    {
                        // console.log("payment pending")
                    }
                    else
                    {
                        clearInterval(timeInterval);
                        // console.log("payment failed");
                        toast.error("Payment Failed. Please try again", {position:toast.POSITION.BOTTOM_CENTER});                                 
                        navigate('/c/mybookings',{replace:true})                     
                    }
                }
        },4000);

        timeOut=setTimeout(()=>{
            clearInterval(timeInterval);
            // console.log("cleared time interval")
        },1000*60*3)
    }

    const onCancelTransaction=async(rs,fromPaymentFail)=>{
        // console.log("onpress onCancelTransaction",timer)
        clearInterval(timeInterval);
        let bodyData={
            randString:rs,            
            // accCount:services.length,
            consumer_id:state.user.id ? state.user.id : null,
            fromPaymentFail:false,
            serviceIds:params.serviceIds
          }

        //   if(fromPaymentFail)
        //   {
        //     bodyData.payErrorObj=payErrorObj
        //     bodyData.fromPaymentFail=true
        //   }
          
        ////cancelling the transaction and increasing the decreased count immediately
          let {fetchedData}=await customFetchData('POST',bodyData,'/website/payments/stripe/cancelTransaction/sv2',state,dispatch)
        //   console.log("fetchedData in cancel trasnaction",fetchedData)
          if(fetchedData.cancel)
            {
                // if(!fromPaymentFail)
                // {
                    navigate('/checkout',{replace:true})
                // }
                // clearCart(dispatch)
            }
            else
            {
                // if(fetchedData.pay)
                // {
                //     setPayCompleteModal(true)
                // }
                // else
                // {
                //     if(!fromPaymentFail)
                //     {
                        navigate('/checkout',{replace:true})
                    // }
                // }
                // clearCart(dispatch)
            }
    }

    return(
        <div>
            <div style={{display:'flex',marginTop:100,height:'600px',justifyContent:'center',alignItems:'center'}}>
            {/* <div> */}
                <text style={{marginRight:20}}>Please wait while we redirect you. Please do not click back or reload button.</text>
                <ClipLoader widthUnit={"%"} size={50} loadings={loadingL} color="#8c52ff" />
            {/* </div> */}
        </div>
        </div>
    )
}





// useEffect(()=>{
//     console.log("inside useffect")
//     // navigate('https://api.razorpay.com/v1/checkout/embedded')
//     // window.location.replace('https://api.razorpay.com/v1/checkout/embedded')
//     // document.getElementById('payForm').submit()
// },[])

{/* <form id="payForm" method="POST" action="https://api.razorpay.com/v1/checkout/embedded">
                <input type="hidden" name="key_id" value="rzp_test_BMpUIvfDXHa9cO"/>
                <input type="hidden" name="amount" value={curr(params.totalAmount).multiply(100).value}/>
                <input type="hidden" name="order_id" value={orderId}/>
                <input type="hidden" name="name" value="Slash"/>
                <input type="hidden" name="description" value="A Wild Sheep Chase"/>
                <input type="hidden" name="image" value="https://cdn.razorpay.com/logos/BUVwvgaqVByGp2_large.jpg"/>
                <input type="hidden" name="prefill[name]" value="Gaurav Kumar"/>
                <input type="hidden" name="prefill[contact]" value="9123456780"/>
                <input type="hidden" name="prefill[email]" value="gaurav.kumar@example.com"/>
                <input type="hidden" name="notes[shipping address]" value="L-16, The Business Centre, 61 Wellfield Road, New Delhi - 110001"/>
                <input type="hidden" name="callback_url" value="https://example.com/payment-callback"/>
                <input type="hidden" name="cancel_url" value="https://example.com/payment-cancel"/>
</form> */}