import React, { useContext, useEffect, useState } from "react";
import BookingCards from "../../Components/Custom/UI/BookingCards";
import { useMediaQuery } from "react-responsive"; 
import BookingCards_Mobile from "../../Components/Custom/UI/BookingCards_Mobile";
import { globalState } from "../../GlobalState/globalState";
import customFetchData from "../../Components/Custom/customFetchData";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import RatingPage from "./RatingPage";
import Close_Cross from "../../Components/Custom/UI/Close_Cross";
import '../../CSS/UI Components/BookingsDetails.css';
import LoadingSpinner from "../../Components/Custom/UI/LoadingSpinner";

export default function PastBookingsPage(){
    const MobileAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 760px)" })
    const TabletMediaQuery = () => useMediaQuery({ minWidth: 768, maxWidth: 991 })
    const isTabView = TabletMediaQuery()
    
    const isMobileView = MobileAndBelowMediaQuery();

    const [state, dispatch]=useContext(globalState);

    const [loading, setLoading]=useState(false)
    const [PastServices ,setPastServices]=useState([])

    const [starCount,setStarCount]=useState([])
    const [ratingStarRating,setRatingStarRating]=useState(null)
    const [ratingStarPrid,setRatingStarPrid]=useState(null)
    const [ratingModal,setRatingModal]=useState(null)

    useEffect(() => {
        fetchUpcomingData()
    },[])

    let navigate = useNavigate()


    const fetchUpcomingData = async() => {
        try {
            setLoading(true)
            let body={
                user_id:state.user.id,
                timezone: state.country.timezone
            }
            let method = 'POST';
            let url='/website/acceptedoffers2/consumed/sv2';
            
            const {fetch, fetchedData} = await customFetchData(method, body, url, state, dispatch)
            // console.log("fetchData in Past bookings", fetch, fetchedData)
    
            if(fetch){
                if(fetchedData.data){
                    if(fetchedData.results.length!==0){
                        let PastServices= fetchedData.results;
                        setPastServices(PastServices)
                        setLoading(false)
                    }else{
                        setPastServices([])
                        setLoading(false)
                    }

                } else {
                    setPastServices([])
                    setLoading(false)
                }
            } else {
                console.log("Something went wrong")
            }

        } catch(catch1){
            console.log(catch1);
        }
    }

    const movetoRating=(rating,bookingId)=>{
        // navigate('Rating', {
        //     rating:rating,
        //     prid:bookingId 
        // });

        setRatingStarRating(rating)
        setRatingStarPrid(bookingId)

        setRatingModal(true)
    }


    let pastServiceDetails;
    if(PastServices.length !== 0){
        pastServiceDetails = PastServices.map((item, index) => {
            if(isMobileView){

                return <BookingCards_Mobile serviceType='past' serviceDetails={item} colour="#fed019"
                            serviceBookingType='past'
                            item={item}
                            onStarPress={movetoRating}
                            ratingNumber={item.SERVICE_RATING}
                            starCount={starCount['starCount_'+ item.QF_CONSUMER_OFFER_ACCEPTED_PRID]}
                        />

            } else {

                return <BookingCards serviceType='past' serviceDetails={item} colour="#fed019"
                            serviceBookingType='past'
                            item={item}
                            onStarPress={movetoRating}
                            ratingNumber={item.SERVICE_RATING}
                            starCount={starCount['starCount_'+ item.QF_CONSUMER_OFFER_ACCEPTED_PRID]}/>

            }

        })

    }


    const closeRatingModalHandler = () => {
        setRatingModal(false);
        fetchUpcomingData()
    }
    
    

    return (
        <div style={{minHeight:'100vh'}}>
            {PastServices.length !== 0 ? pastServiceDetails :
                loading ? 
                    <LoadingSpinner loading={true} />
                :
                    <div style={{minHeight:300, display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                        <text className="detail_header_text_style">No Past Bookings</text>
                    </div>
            }

            <Modal isOpen={ratingModal} size="lg">
                <ModalBody className="Rating_modalbody_div_style">
                    <RatingPage rating={ratingStarRating} prid={ratingStarPrid} onPress={closeRatingModalHandler}/>
                </ModalBody>
            </Modal>
        </div>
    )
}