import React, { useContext, useEffect, useState } from 'react'
import { Card, Row, Col, CardImg, CardText, Button } from 'reactstrap'
import '../../../CSS/UI Components/ServiceCard.css'
import RatingComponent from './RatingComponent';
import WishlistIcon from '../../../Assets/ph-heart-bold.png'
import DurationIcon from '../../../Assets/asicon-outline-timer.png'
import momenttz from 'moment-timezone'
import moment from 'moment';
import numeral from 'numeral';
import { addToCart, removeFromCart } from '../../../GlobalState/globalStateActions';
import { BsCheckLg } from 'react-icons/bs'
import { globalState } from '../../../GlobalState/globalState';
import WishlistYes from '../../../Assets/iconFavorite.png'
const DeepClone = require("rfdc/default")
export default function ServicesCard(props) {
    const [state,dispatch]=useContext(globalState)
    const [isAddedToCart, setIsAddedToCart] = useState(false)
    useEffect(()=>{
        let cart=DeepClone(state.cart)
        let addCheck=cart.some((item)=>{
            return item.QF_MERCHANT_SERVICE_PRID===props.data.QF_MERCHANT_SERVICE_PRID
        })
        // console.log("addCheck",addCheck,data.QF_MERCHANT_SERVICE_PRID)
        setIsAddedToCart(addCheck)
      })
      
      const onAddToCart = (data) => {
        console.log("on click add ot cart")
        // onClickCart(!cartClicked)
        addToCart(data,state,dispatch)
      }
      
      const onRemoveFromCart = (data)=>{
        console.log("on click add ot cart")
        // onClickCart(!cartClicked)
        removeFromCart(data,state,dispatch)
      }
  return (
    // <div>
        <Card className='serviceCard_Sv2'>
                <div style={{flexDirection:'row',display:'flex',justifyContent:'space-between',width:'100%'}}>
                    <div style={{flexDirection:'row',display:'flex'}}>
                        <CardImg style={{width: 125, height: 130, borderRadius: 10}} src={props.data.image_link}  onClick={props.onClickCard}/>
                        <div style={{flexDirection:'column',display:'flex',marginLeft:10,justifyContent:'space-between'}}>
                            <div>
                                <div style={{flexDirection:'row',display:'flex'}}>
                                    <text className='serviceNameText'  onClick={props.onClickCard}>{props.data.SERVICE_NAME}</text>
                                    {/* { 
                                    state.token && 
                                    <div style={{position:'absolute',right:7}}>
                                        <div className={"wishlistBg"} onClick={props.WishlistPress} style={{cursor:'pointer'}}>
                                           {props.wishlisted ? <img src={WishlistYes} style={{width:18,height:18}}/> : <img src={WishlistIcon} />}
                                        </div>
                                    </div>
                                    } */}
                                </div>
                                {props.data.SERVICE_DETAIL_DESC && <text className='descServiceCard'  onClick={props.onClickCard}>{props.data.SERVICE_DETAIL_DESC.length > 16 ? props.data.SERVICE_DETAIL_DESC.substr(0,16) + '....' : props.data.SERVICE_DETAIL_DESC}</text> }
                                <div style={{ display:'flex',flexDirection: 'row', justifyContent: 'flex-start', marginTop: 6 }}  onClick={props.onClickCard}>
                                    <text style={{ marginRight: 8 }} className='listPrice_ServiceCard'>{(props.data.SERVICE_CURRENCY === "Rupees" ? '\u20B9' : "$") + props.data.service_list_price}</text>
                                    <text style={{ marginRight: 3 }} className='offerPrice_ServiceCard'>{(props.data.SERVICE_CURRENCY === "Rupees" ? '\u20B9' : "$") + props.data.service_offer_price}</text>
                                    <text style={{ marginTop: 3 }} className='serviceDiscount_ServiceCard'>{"(" + props.data.service_discount + "% off)"}</text>
                                </div>
                                <div style={{ flexDirection: 'row', marginVertical: 6 }}  onClick={props.onClickCard}>
                                    <img src={DurationIcon} style={{ width: 12, height: 12, }} />
                                    <text className='durationService_Card'>{moment.utc(moment.duration(props.data.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") == 0 ? null : moment.utc(moment.duration(props.data.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") + "h"}{' '}{moment.utc(moment.duration(props.data.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") == 0 ? null : moment.utc(moment.duration(props.data.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") + "m"}</text>
                                </div>
                            </div>
                            {/* <div style={{flexDirection:'row',display:'flex',marginTop:props.data.SERVICE_DETAIL_DESC ? (props.data.SERVICE_DETAIL_DESC.length > 0 ? 0 : 12) : 10}}>
                                {
                                    isAddedToCart ?
                                        <Button className='addToCartButton' onClick={() => onRemoveFromCart(props.data)}>
                                            <BsCheckLg color='#fff' size={20} />
                                        </Button>
                                        :
                                        <Button className='addToCartButton' onClick={() => onAddToCart(props.data)}>
                                            <text className='ADD-TO-CART'>Add to Cart</text>
                                        </Button>
                                }
                                <div style={{marginLeft:7}}>
                                    {props.data.MERCHANT_SERVICE_RATING ? <RatingComponent rating={numeral(props.data.MERCHANT_SERVICE_RATING).format('0.0')}/> : <div></div>}
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
        </Card>
    // </div>
  )
}

