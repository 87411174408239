import React, { useContext, useEffect, useState } from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { addToCart, removeFromCart } from '../../../GlobalState/globalStateActions'
import { globalState } from '../../../GlobalState/globalState'
import '../../../CSS/ProfessionalPage.css'
import momenttz from 'moment-timezone'
import moment from 'moment';
import RatingComponent from './RatingComponent'
import { Button, Card, CardImg } from 'reactstrap'
import WishlistIcon from '../../../Assets/ph-heart-boldColored.png'
import DurationIcon from '../../../Assets/asicon-outline-timer.png'
import WishlistYes from '../../../Assets/iconFavorite.png'
const DeepClone = require("rfdc/default")
export default function ServicesCard_Web({data,onClickCard,WishlistPress,wishlisted}) {
    const [state,dispatch]=useContext(globalState)
    const [isAddedToCart, setIsAddedToCart] = useState(false)
    useEffect(()=>{
        let cart=DeepClone(state.cart)
        let addCheck=cart.some((item)=>{
            return item.QF_MERCHANT_SERVICE_PRID===data.QF_MERCHANT_SERVICE_PRID
        })
        // console.log("addCheck",addCheck,data.QF_MERCHANT_SERVICE_PRID)
        setIsAddedToCart(addCheck)
      })
      
      const onAddToCart = (data) => {
        console.log("on click add ot cart")
        // onClickCart(!cartClicked)
        addToCart(data,state,dispatch)
      }
      
      const onRemoveFromCart = (data)=>{
        console.log("on click add ot cart")
        // onClickCart(!cartClicked)
        removeFromCart(data,state,dispatch)
      }
  return (

      <div>
          <Card style={{ display: 'flex', flexDirection: 'row', border: 'none', justifyContent: 'space-between', marginLeft: 20, marginRight: 20 }}>
              <div onClick={onClickCard} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', cursor: 'pointer' }}>
                  <CardImg src={data.image_link} style={{ width: 210, height: 190,borderRadius:15 }} />
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <text className='serviceNameText_Web'>{data.SERVICE_NAME}</text>
                          <text className='descText_Servicecard_Web'>{data.SERVICE_DETAIL_DESC}</text>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: 6 }}>
                              <text style={{ marginRight: 8 }} className='listPrice_ServiceCard_Web'>{(data.SERVICE_CURRENCY === "Rupees" ? '\u20B9' : "$") + data.service_list_price}</text>
                              <text style={{ marginRight: 3 }} className='offerPrice_ServiceCard_Web'>{(data.SERVICE_CURRENCY === "Rupees" ? '\u20B9' : "$") + data.service_offer_price}</text>
                              <text className='serviceDiscount_ServiceCard_Web'>{"(" + data.service_discount + "% off)"}</text>
                          </div>
                          <div style={{ marginTop: 6, marginBottom: 6 }}>
                              <img src={DurationIcon} style={{ width: 20, height: 20 }} />
                              <text className='durationText_ServiceCard_Web' style={{ paddingLeft: 4 }}>{moment.utc(moment.duration(data.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") == 0 ? null : moment.utc(moment.duration(data.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") + " hr"}{' '}{moment.utc(moment.duration(data.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") == 0 ? null : moment.utc(moment.duration(data.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") + " mins"}</text>
                          </div>
                      </div>
                      <div>
                          {data.MERCHANT_SERVICE_RATING ? <RatingComponent rating={data.MERCHANT_SERVICE_RATING} /> : null}
                      </div>
                  </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                      {/* { state.token &&
                        <div className={"wishlistBg_Web"} style={{cursor:'pointer'}} onClick={WishlistPress}>
                        {
                            wishlisted ? <img src={WishlistYes} style={{ width: 18, height: 18 }} /> : <img src={WishlistIcon} style={{ width: 18, height: 18 }} />
                        }
                      </div>
                      } */}
                  </div>
                  {/* {
                      isAddedToCart ?
                          <Button className='addToCartButton' onClick={() => onRemoveFromCart(data)}>
                              <BsCheckLg color='#fff' size={20} />
                          </Button>
                          :
                          <Button className='addToCartButton' onClick={() => onAddToCart(data)}>
                              <text className='ADD-TO-CART'>Add to Cart</text>
                          </Button>
                  } */}
              </div>
          </Card>
          <hr />
      </div>
  )
}
