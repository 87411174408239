import React from "react";
import '../../../CSS/UI Components/submitButtonSmall.css';
// import Check from 'react-native-vector-icons/MaterialCommunityIcons'

const SubmitButtonSmall = (props) => {
    return (
        // <TouchableOpacity onPress={props.onPress} className="buttonStyle" style={{backgroundColor: props.buttonColor, borderRadius:props.Radius?props.Radius:10, width:props.width?props.width:138}}>
        <div className="buttonStyle" onClick={props.onPress} style={{backgroundColor: props.buttonColor, borderRadius:props.Radius?props.Radius:10, width:props.width?props.width:138, border:`1px solid`+ props.borderColor,height:props.height? props.height : null,marginTop:props.topMar ? 0 : null  }}>
                {
                    props.iconVisible ?
                    <div style={{alignItems:'center',justifyContent:"center"}}>
                        {/* <Check name="check" color='#fff' size={18} /> */}
                    </div>
                    :
                    <text className="textStyle" style={{color:props.buttonTextColor,fontSize: props.size ? '3vw': props.fontSize ? props.fontSize : 12}}>
                        {props.buttonTitle}
                    </text>
                }
        {/* </TouchableOpacity> */}
        </div>

    )
}

export default SubmitButtonSmall;