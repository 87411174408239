import React from "react";
import '../../../CSS/UI Components/submitButtonSolid.css'

const SubmitButtonSolid = (props) => {
    return (
        <div className="solid_buttonStyle" onClick={props.onClick} style={{backgroundColor: props.buttonColor, width:props.width ? props.width : 'auto'}}>
            
                    <text className="solid_textStyle" style={{color: props.buttonTextColor, fontSize:props.fontSize ? props.fontSize :12}}>
                        {props.buttonTitle}
                    </text>
           
        </div>
    )
}

export default SubmitButtonSolid;