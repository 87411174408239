import React, { useContext, useEffect, useRef, useState } from 'react'
import ServicesCard_Web from '../../Components/Custom/UI/ServicesCard_Web'
import customFetchData from '../../Components/Custom/customFetchData'
import { globalState } from '../../GlobalState/globalState'
import { useLocation, useNavigate } from 'react-router-dom'
import '../../CSS/UI Components/ServiceDetailsPage.css'
import { useMediaQuery } from 'react-responsive'
import numeral from 'numeral'
import { colors } from '../../Components/Custom/UI/Colors'
import ShareIcon from '../../Assets/vectorShareIconColored.png'
import WishlistIcon from '../../Assets/ph-heart-boldColored.png'
import { addToCart, clearAddress, clearServices, removeFromCart, storeDateAndTime } from '../../GlobalState/globalStateActions'
import moment from 'moment'
import momenttz from 'moment-timezone'
import DurationIcon from '../../Assets/asicon-outline-timer.png'
import WishlistYes from '../../Assets/iconFavorite.png'
import { FaArrowRight } from 'react-icons/fa'
import { Button, Carousel, CarouselIndicators, CarouselItem } from 'reactstrap'
import RatingsAndReviews from '../../Components/Custom/UI/RatingsAndReviews'
import GreenStarIcon from '../../Assets/greenStarIcon.png'
import { ToastContainer, toast } from 'react-toastify'
import FetchCurrentLocation from '../../Components/Custom/FetchCurrentLocation'
import WhiteRectangle from '../../Assets/whiteRect.png'
import { ClipLoader } from 'react-spinners'
import DistanceIcon from '../../Assets/gis-routeBlack.png'
const DeepClone = require("rfdc/default")
export default function ServiceDetailsDeepLink_web() {
    const [proData,setProData]=useState()
    const [serviceDetails,setServiceDetails]=useState({})
    const [current,setCurrent]=useState(0)
    const [index,setIndex]=useState(0)
    const [couponDetails,setCouponDetails]=useState([])
    const [ratingDetails,setRatingDetails]=useState([])
    const [ratings1,setRatings1]=useState(0)
    const [ratings2,setRatings2]=useState(0)
    const [ratings3,setRatings3]=useState(0)
    const [ratings4,setRatings4]=useState(0)
    const [ratings5,setRatings5]=useState(0)
    const [totalRatings,setTotalRatings]=useState(0)
    const [avgRating,setAvgRating]=useState(0)
    const [wishlistPress,setWishlistPress]=useState(false)
    const [isAddedToCart, setIsAddedToCart] = useState(false)
    const [addCheck,setAddCheck]=useState(false)
    const [loading,setLoading]=useState(false)
    const [cartAmount, setCartAmount] = useState(0)
    const [state,dispatch]=useContext(globalState)
    const [brokenLink,setBrokenLink]=useState(false)
    const [ activeIndex,setActiveIndex ]=useState(0);
    const [currentLocFetch,setCurrentLocFetch]=useState(true)
    let flatListRef=useRef()
    const paramsLocation=useLocation()
    const navigate=useNavigate()
    const useTabletMediaQuery = () => useMediaQuery({ minWidth: 768, maxWidth: 991 })
    const isTabView = useTabletMediaQuery()
    useEffect(()=>{
        setCurrentLocFetch(true)
        serviceDetailsFetch()
    },[])
    const serviceDetailsFetch = async() =>{
        let searchUrl = paramsLocation.search
    //   console.log('searchUrl', searchUrl)
      let regex = /[?&]([^=#]+)=([^&#]*)/g, searchParams = {}, match
      while ((match = regex.exec(searchUrl))) {
        searchParams[match[1]] = match[2]
      }
      if(searchParams.serviceId){
        // console.log('searchParams',searchParams)
        let serviceDetails1={
            QF_MERCHANT_SERVICE_PRID:Number(searchParams.serviceId),
            QF_MERCHANT_PERSONNEL_PRID:Number(searchParams.proId)
        }
        let body;
        if (state.address.latitude === null && state.address.longitude === null) {
            let currentLocationFetch = await FetchCurrentLocation(state, dispatch)
            // console.log('currentLocationFetch', currentLocationFetch)
            // if(currentLocationFetch.locationFetch){
                setCurrentLocFetch(false)
                let { city, curr_location_latitude, curr_location_longitude, stateOrProvince } = currentLocationFetch
                body = {
                    curr_city: city,
                    curr_lat: curr_location_latitude,
                    curr_long: curr_location_longitude,
                    stateOrProvince,
                    mpid: serviceDetails1.QF_MERCHANT_PERSONNEL_PRID ? serviceDetails1.QF_MERCHANT_PERSONNEL_PRID : null,
                    serviceId: serviceDetails1.QF_MERCHANT_SERVICE_PRID ? serviceDetails1.QF_MERCHANT_SERVICE_PRID : null,
                    consumerId:state.user.id
                }
            // }
        } else {
            setCurrentLocFetch(false)
            body = {
                curr_city: state.address.city,
                curr_lat: state.address.latitude,
                curr_long: state.address.longitude,
                stateOrProvince: state.address.stateOrProvince,
                mpid: serviceDetails1.QF_MERCHANT_PERSONNEL_PRID ? serviceDetails1.QF_MERCHANT_PERSONNEL_PRID : null,
                serviceId: serviceDetails1.QF_MERCHANT_SERVICE_PRID ? serviceDetails1.QF_MERCHANT_SERVICE_PRID : null,
                consumerId:state.user.id
            }
        }
        // console.log(body,'body')
        if(Object.keys(body).length!==0){
            setLoading(true)
            let serviceFetch = await customFetchData('POST', body, '/website/proSingle/serviceDetail/sv2', state, dispatch)
        //   console.log('servicesFe', serviceFetch, proData)
          if (serviceFetch.fetch) {
            if(serviceFetch.fetchedData.offers.length!==0){
                // setLoading(false)
                let serviceData = {
                  servicePridArr: [serviceDetails1.QF_MERCHANT_SERVICE_PRID],
                  consumer_prid: state.user.id,
                  wishlistType: 'service',
                  mpprid:  serviceDetails1.QF_MERCHANT_PERSONNEL_PRID
                }
                let wishListedData = await customFetchData('POST', serviceData, '/website/wishlistedProServices/sv2', state, dispatch)
                if (wishListedData.fetchedData.wishlistRes.length !== 0) {
                    let serviceDetails = serviceFetch.fetchedData.offers[0]
                    // console.log('service details', serviceDetails)
                    wishListedData.fetchedData.wishlistRes.map(item11 => {
                      return (
                        serviceDetails.QF_WISHLIST_PRID = item11.QF_WISHLIST_PRID,
                        serviceDetails.WISHLIST_DELETED_FLAG = item11.WISHLIST_DELETED_FLAG
                      )
                    })
                    // console.log('service details after', serviceDetails)
                    if(serviceDetails.WISHLIST_DELETED_FLAG === 0){
                        setWishlistPress(true)
                    }
                    setServiceDetails(serviceDetails ? serviceDetails : [])
                    setProData(serviceDetails)
                    setLoading(false)
                } else {
                    setLoading(false)
                    let serviceDetails = serviceFetch.fetchedData.offers[0]
                    setServiceDetails(serviceDetails ? serviceDetails : [])
                    setProData(serviceDetails)
                }
                let body1 = {
                    m_id: serviceFetch.fetchedData.offers[0].QF_MERCHANT_PRID,
                    // loc_prid:route.params.serviceDetails.QF_MERCHANT_LOCATION_PRID
                }
                let url1 = '/website/serviceDetailsCoupons/sv2'
                let method1 = 'POST'
                let couponDetails = await customFetchData(method1, body1, url1, state, dispatch)
                // console.log('coupons',couponDetails)
                let check = couponDetails.fetchedData
                if (couponDetails.fetch) {
                    if (check.data) {
                        if (check.couponRes.length !== 0) {
                            setCouponDetails(check.couponRes)
                        } else {
                            setCouponDetails([])
                        }
                    }
                }
                let body = {
                    mp_id: serviceFetch.fetchedData.offers[0].QF_MERCHANT_PERSONNEL_PRID,
                    m_serviceId: serviceFetch.fetchedData.offers[0].QF_MERCHANT_SERVICE_PRID
                }
                let url = '/website/serviceRatingsFetch/sv2'
                let method = 'POST'
                let ratingFetch = await customFetchData(method, body, url, state, dispatch)
                // console.log('ratings', ratingFetch)
                let check1 = ratingFetch.fetchedData
                if (ratingFetch.fetch) {
                    if (check1.data) {
                        if (check1.ratingsResult.length !== 0) {
                            setRatingDetails(check1.ratingsResult)
                            setTotalRatings(check1.totalRatings)
                            setRatings1(check1.ratings1)
                            setRatings2(check1.ratings2)
                            setRatings3(check1.ratings3)
                            setRatings4(check1.ratings4)
                            setRatings5(check1.ratings5)
                            setAvgRating(check1.avgRating)
                        } else {
                            setRatingDetails([])
                        }
                    }
                }
            }else{
                setLoading(false)
            }
          }
        }
      }else{
        setProData({})
        setServiceDetails({})
        setLoading(false)
        setRatingDetails([])
        setCouponDetails([])
        setBrokenLink(true)
      }
        
    } 
    useEffect(()=>{
        // console.log("serviceDetails",serviceDetails)
        let cart=DeepClone(state.cart)

        let addCheck=cart.some((item)=>{
            return item.QF_MERCHANT_SERVICE_PRID===serviceDetails.QF_MERCHANT_SERVICE_PRID
        })
        // console.log("addCheck",addCheck,data.QF_MERCHANT_SERVICE_PRID)
        setIsAddedToCart(addCheck)
    })
    useEffect(() => {
        // console.log("state cart count in use effect",state.cartCount)
        let cart = [...state.cart], tempCartAmount = 0
        cart.map(item => {
            tempCartAmount += item.service_offer_price
        })
        setCartAmount(tempCartAmount)
    }, [state.cart])

    useEffect(()=>{
        let fromDateTime,toDateTime;
        if(Object.keys(state.fromDateTime).length===0)
        {
            //// setting from date in global state if already not set
            fromDateTime=moment().tz(state.country.timezone).set({ 'hour': 0, 'minute': 0 })
        }
        if(Object.keys(state.toDateTime).length===0)
        {
            //// setting to date in global state if already not set
            toDateTime=moment().tz(state.country.timezone).set({ 'hour': 23, 'minute': 59 }).add(14, "days")
        }
        if( fromDateTime && toDateTime ){
            storeDateAndTime(fromDateTime,toDateTime,dispatch)
        }
    },[])

    const onAddToCart = (data) => {
        console.log("on click add to cart")
        addToCart(data,state,dispatch)
    }

    const onRemoveFromCart = (data)=>{
        console.log("on click remove from cart")
        removeFromCart(data,state,dispatch)
    }
    const navToLoginOrCheckout=()=>{
        navigate('/checkout',{state:{
            categories: DeepClone(state.categories), subCategories: DeepClone(state.subcategories),
            addressDetails:
            {
              totalAddress: state.address.totalAddress,
              addressLine1: state.address.addressLine1,
              country: state.address.country,
              city: state.address.city,
              stateOrProvince: state.address.stateOrProvince,
              zipcode: state.address.zipcode,
              district: state.address.district,
              latitude: state.address.latitude,
              longitude: state.address.longitude,
              currentAddress: state.address.currentAddress,
              searchAddress: state.address.searchAddress,
              currentLocationDenied: state.address.currentLocationDenied
            },
            allServices: DeepClone(state.services)
        }})
    }
    const next = () => {
        // if (animating) return;
        const nextIndex = activeIndex === couponDetails.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        // if (animating) return;
        const nextIndex = activeIndex === 0 ? couponDetails.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex) => {
        //   console.log("newIndex",newIndex)
        // if (this.animating) return;
        setActiveIndex(newIndex);
    
    }
    let couponSlides;
    if(couponDetails.length!==0){
        couponSlides=couponDetails.map((item) => {
            return (
        
                <CarouselItem
                    // onExiting={this.onExiting}
                    // onExited={this.onExited}
                    key={item.QF_MERCHANT_COUPON_PRID}
                >
                    <div style={{marginTop:15,background: 'linear-gradient(87deg, #461c99 -5%, #8C52FF 39%, #ff5d5d 138%)',height:140,display:'flex',justifyContent:'space-between',alignItems:'center',padding:'25px 40px 25px 40px',borderRadius:8}}>
                        {/* <text>{item.MERCHANT_COUPON_CODE}</text> */}
                        <div style={{ backgroundImage: `url(${WhiteRectangle})`, width: 238, height: 65, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                            <text className='couponText_Latest' style={{color:'#8c52ff'}}>{item.MERCHANT_COUPON_CODE}</text>
                        </div>
                        <text className='couponPer_DiscountText'>{"Upto " +(item.MERCHANT_COUPON_PERCENT_OFF ? item.MERCHANT_COUPON_PERCENT_OFF + "% OFF" : (proData.MERCHANT_PERSONNEL_ADDRESS_COUNTRY== "India" ? ('\u20B9') : (proData.MERCHANT_PERSONNEL_ADDRESS_COUNTRY== "United States" ? '$' : '')) + item.MERCHANT_COUPON_VALUE_OFF + " OFF")}</text>
                    </div>
                </CarouselItem>
        
            );
        });
    }
    const copyClipHandler = () => {
        // let code=paramsLocation.pathname + paramsLocation.search
        let code=window.location.href
        navigator.clipboard.writeText(code)
        toast(
          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <text>Copied to clipboard</text> 
          </div>,
          {
            position:'bottom-center',
            hideProgressBar: true,
            progress: undefined,
            closeButton:false,
            style:{backgroundColor:'#E0D1FF'}
        });
      }
      const WishlistPressOnService = async() =>{
        setWishlistPress(!wishlistPress)
        let body = {
            // cprid:item.QF_CONSUMER_PRID,
            cprid: state.user.id,
            mpprid: serviceDetails.QF_MERCHANT_PERSONNEL_PRID,
            wishLstType: 'service',
            servicePrid: serviceDetails.QF_MERCHANT_SERVICE_PRID
        }
        let WishlistInsert = await customFetchData('POST', body, '/website/add/wishList/sv2', state, dispatch)
          console.log('wish list response',WishlistInsert)
    }
  return (
    <div style={{marginTop:isTabView ?  120 : 80,backgroundColor:'#fff'}}>
          {
              brokenLink
                  ?
                  <div onClick={() => navigate('/')} style={{ flexDirection: 'column', backgroundColor: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 40, paddingBottom: 40, height: 200 }}>
                      <text>Page Not Found, click the below button to go to home...</text>
                      <Button className='addToCartButton' style={{ marginTop: 20 }}>Back to Home</Button>
                  </div>
                  :
                  currentLocFetch ?
                      <div style={{ flexDirection: 'row', backgroundColor: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 40, paddingBottom: 40, height: 600 }}>
                          <text>Fetching current location please wait a while.....</text>
                          <ClipLoader widthUnit={"%"} size={20} loadings={currentLocFetch} color="#8c52ff" />
                      </div>
                      :
                      <>
                          {
                              loading ?
                                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 40, height: 200 }}>
                                      <ClipLoader widthUnit={"%"} size={20} loadings={loading} color="#8c52ff" />
                                  </div>
                                  :
                                  Object.keys(serviceDetails).length !== 0 ?
                                      <>
                                          <div className='MainReusable_Container_Home'>
                                              <div style={{ flexDirection: 'row', display: 'flex', paddingTop: 40, width: '100%' }}>
                                                  <div style={{ display: 'flex', flexDirection: 'column', width: '52%' }}>
                                                      <div style={{ position: 'relative' }}>
                                                          <img src={serviceDetails.image_link} style={{ width: '100%', height: isTabView ? 200 : 300, borderRadius: 15 }} />
                                                          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', position: 'absolute', top: 20, left: 20, right: 20 }}>
                                                              <div>
                                                                  {
                                                                      serviceDetails.MERCHANT_SERVICE_RATING &&
                                                                      <div className='ratingView_serDetDeepLink'>
                                                                          <text className='ratingText_serDetDeepLink'>{serviceDetails.MERCHANT_SERVICE_RATING ? numeral(serviceDetails.MERCHANT_SERVICE_RATING).format('0.0') : ''}</text>
                                                                          <img src={GreenStarIcon} />
                                                                      </div>
                                                                  }
                                                              </div>
                                                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                  <div className='shareIconView_serDetDeepLink' onClick={() => copyClipHandler()}>
                                                                      <img src={ShareIcon} />
                                                                  </div>
                                                                  {state.token &&
                                                                      <div className='shareIconView_serDetDeepLink' style={{ marginLeft: 20 }} onClick={() => WishlistPressOnService()}>
                                                                          {wishlistPress ? <img src={WishlistYes} /> : <img src={WishlistIcon} />}
                                                                      </div>}
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                                                          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', paddingTop: 10, paddingBottom: 20 }}>
                                                              <div>
                                                                  <text className='serviceNameText_serDetDeepLink'>{serviceDetails.SERVICE_NAME.length > 15 ? serviceDetails.SERVICE_NAME.substr(0, 20) : serviceDetails.SERVICE_NAME}</text>
                                                                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: 6 }}>
                                                                      <text style={{ marginRight: 8 }} className='listPrice_ServiceCard_Web'>{(serviceDetails.SERVICE_CURRENCY === "Rupees" ? '\u20B9' : "$") + serviceDetails.service_list_price}</text>
                                                                      <text style={{ marginRight: 3 }} className='offerPrice_ServiceCard_Web'>{(serviceDetails.SERVICE_CURRENCY === "Rupees" ? '\u20B9' : "$") + serviceDetails.service_offer_price}</text>
                                                                      <text className='serviceDiscount_ServiceCard_Web'>{"(" + serviceDetails.service_discount + "% off)"}</text>
                                                                  </div>
                                                              </div>
                                                              {/* <div>
                                                                  {
                                                                      isAddedToCart
                                                                          ?

                                                                          <div className='addToCart_Btn_serDetDeepLink' style={{ cursor: 'pointer' }} onClick={() => onRemoveFromCart(serviceDetails)}>
                                                                              <text className='addToCart_Btn_serDetDeepLinkText'>Remove From Cart</text>
                                                                          </div>
                                                                          :
                                                                          <div className='addToCart_Btn_serDetDeepLink' style={{ cursor: 'pointer' }} onClick={() => onAddToCart(serviceDetails)}>
                                                                              <text className='addToCart_Btn_serDetDeepLinkText'>Add To Cart</text>
                                                                          </div>
                                                                  }
                                                              </div> */}
                                                          </div>
                                                          <div style={{ paddingTop: 10 }}>
                                                              <text className='service_desc_mobile'>{serviceDetails.SERVICE_DETAIL_DESC ? serviceDetails.SERVICE_DETAIL_DESC : 'No Description'}</text>
                                                          </div>
                                                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 10 }}>
                                                              <img src={DistanceIcon} style={{ marginRight: 3 }} />
                                                              <text className='service_desc_mobile'>{serviceDetails.SERVICE_CURRENCY == 'Rupees' ? numeral(serviceDetails.distance).divide(1000).format('0.00') + ' km away' : numeral(serviceDetails.distance).divide(1609.344).format('0.00') + ' mi away'}</text>
                                                          </div>
                                                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 10 }}>
                                                              <img src={DurationIcon} style={{ width: 16, height: 16 }} />
                                                              <text className='service_desc_mobile'>{moment.utc(moment.duration(serviceDetails.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") == 0 ? null : moment.utc(moment.duration(serviceDetails.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") + " hr"}{' '}{moment.utc(moment.duration(serviceDetails.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") == 0 ? null : moment.utc(moment.duration(serviceDetails.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") + " mins"}</text>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div style={{ width: '3%', marginLeft: 10 }}>
                                                      <div className='verticalLine_serDetDeepLink'>
                                                      </div>
                                                  </div>
                                                  <div style={{ display: 'flex', flexDirection: 'column', width: '44%', marginLeft: 20 }}>
                                                      <div className='serviceDetView_serDetDeepLink' style={{ width: '100%' }}>
                                                          <div className='serviceIdView_serDetDeepLink'>
                                                              <text className='serviceId_TextHeader'>Service ID</text>
                                                              <text className='serviceId_TextDeep'>{serviceDetails.QF_MERCHANT_SERVICE_PRID}</text>
                                                          </div>
                                                          <div style={{ padding: '16px 10px 16px 10px' }}>
                                                              <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', paddingTop: 4, paddingBottom: 4 }}>
                                                                  <text className='prices_serDetDeepLink'>Regular Price</text>
                                                                  <text className='priceValues_serDetDeepLink'>{serviceDetails.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'} {numeral(serviceDetails.service_list_price).format('0.00')}</text>
                                                              </div>
                                                              <div style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: 4, paddingBottom: 4, display: 'flex' }}>
                                                                  <text className='prices_serDetDeepLink'>Service Discount Percent</text>
                                                                  <text className='priceValues_serDetDeepLink'>{serviceDetails.service_discount}%</text>
                                                              </div>
                                                              <div style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: 4, paddingBottom: 4, display: 'flex' }}>
                                                                  <text className='prices_serDetDeepLink'>Service Discount Price</text>
                                                                  <text className='priceValues_serDetDeepLink'>{serviceDetails.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'} {numeral(serviceDetails.service_list_price).subtract(serviceDetails.SERVICE_OFFER_PRICE).value().toFixed(2)}</text>
                                                              </div>
                                                              <div style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: 4, paddingBottom: 4, display: 'flex' }}>
                                                                  <text className='proPrice_serDetDeepLink'>Professional Price</text>
                                                                  <text className='proPriceValue_serDetDeepLink'>{serviceDetails.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'} {numeral(serviceDetails.service_offer_price).format('0.00')}</text>
                                                              </div>
                                                              <hr />
                                                              <div style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 12, paddingTop: 4, paddingBottom: 4, display: 'flex' }}>
                                                                  <text className='totalPrice_serDetDeepLink'>Total Price</text>
                                                                  <text className='totalPriceValue_serDetDeepLink'>{serviceDetails.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'} {numeral(serviceDetails.service_offer_price).format('0.00')}</text>
                                                              </div>

                                                          </div>
                                                      </div>
                                                      <div style={{ width: '100%', marginTop: 20 }}>
                                                          <div className='cancellationPolicy_serDetDeepLink'>
                                                              <text className='cancellationDisclaimer_serDetDeepLink'>Cancellation Disclaimer</text>
                                                              {
                                                                  serviceDetails.SERVICE_CANCEL_ALLOWED === 1 ?
                                                                      <>
                                                                          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 8 }}>
                                                                              <text className='prices_serDetDeepLink'>Cancellation Allowed</text>
                                                                              <text className='prices_serDetDeepLink'>Yes</text>
                                                                          </div>
                                                                          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 8 }}>
                                                                              <text className='prices_serDetDeepLink'>Cancellation Day</text>
                                                                              <text className='prices_serDetDeepLink'>{serviceDetails.SERVICE_CANCEL_ALLOWED_DAYS + (serviceDetails.SERVICE_CANCEL_ALLOWED_DAYS === 0 || 1 ? " day" : " days")}</text>
                                                                          </div>
                                                                          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 8 }}>
                                                                              <text className='prices_serDetDeepLink'>Cancellation Fee</text>
                                                                              <text className='prices_serDetDeepLink' style={{ color: colors.ERROR_RED_2 }}>-{serviceDetails.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'} {serviceDetails.SERVICE_CANCEL_FEE}</text>
                                                                          </div>
                                                                      </>
                                                                      :
                                                                      <div>
                                                                          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 8 }}>
                                                                              <text className='prices_serDetDeepLink'>Cancellation Allowed</text>
                                                                              <text className='prices_serDetDeepLink' style={{ color: colors.ERROR_RED_2 }}>No</text>
                                                                          </div>
                                                                      </div>
                                                              }
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              {
                                                  (state.cartCount && state.cart.length !== 0) ?
                                                      <div className="MainReusable_Container_Home" onClick={navToLoginOrCheckout} style={{ height: 80, paddingTop: 20, paddingBottom: 15, backgroundImage: ' linear-gradient(97deg, #1a67db -2%, #02c5d1 41%, #31b12f 100%)', position: 'fixed', zIndex: 100, bottom: 0, left: 0, right: 0, cursor: 'pointer' }}>
                                                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                  <text style={{ maxWidth: 'auto' }} className='serviceNameText_InCart'>{state.cart[0].SERVICE_NAME + "" + (state.cartCount > 1 ? (" + " + (state.cartCount - 1) + " more") : "")}</text>
                                                                  <text style={{ marginTop: 3, fontSize: 12 }} className='serviceNameText_InCart'>{proData.MERCHANT_PERSONNEL_ADDRESS_COUNTRY === 'India' ? '\u20B9' : '$'}{cartAmount}</text>
                                                              </div>
                                                              <div style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                                  <text style={{ fontWeight: 600 }} className='serviceNameText_InCart'>Checkout</text>
                                                                  <FaArrowRight style={{ marginLeft: 5 }} name='arrow-right' color='#fff' size={25} />
                                                              </div>
                                                          </div>
                                                      </div>
                                                      : null
                                              }
                                          </div>
                                          <div className='MainReusable_Container_Home' style={{ paddingTop: 10 }}>
                                              {
                                                  couponDetails.length !== 0 ?
                                                      <Carousel
                                                          activeIndex={activeIndex}
                                                          next={next}
                                                          previous={previous}
                                                          ride="carousel"
                                                          interval={20000}
                                                      >
                                                          {couponDetails.length > 1 ? <CarouselIndicators style={{ bottom: -8 }} items={couponDetails} activeIndex={activeIndex} onClickHandler={goToIndex} /> : null}
                                                          {couponSlides}
                                                      </Carousel>
                                                      :
                                                      null
                                              }
                                              {
                                                  ratingDetails.length !== 0 ?
                                                      <div style={{ marginTop: 20 }}>
                                                          <RatingsAndReviews rating={avgRating} ratings1={ratings1} ratings2={ratings2} ratings3={ratings3} ratings4={ratings4} ratings5={ratings5} totalNofRatings={totalRatings} reviews={50} />
                                                      </div>
                                                      : null
                                              }
                                          </div>
                                      </>
                                      : null
                          }
                      </>
          }
          <ToastContainer />
    </div>
  )
}
