import React from "react";
// import { StyleSheet, Text, View,TouchableOpacity } from "react-native";

const HowToDel = (props) => {

      
    return (
        
          <div style={{paddingLeft:30, width:'100%', minHeight:'100vh', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'flex-start', marginLeft:'5%'}}  >
               
               <div style={{ textAlign: 'left',  color: '#1c1b1f',marginBottom:23,fontSize:30,fontFamily:'Muli',fontStretch:'normal',lineHeight:'normal',letterSpacing:'normal',fontStyle:'normal' }}>How To Delete Your Account?</div>

               <div style={{border: 'solid 1px #9f9f9f',width:'48%',paddingLeft:14,paddingRight:14,paddingTop:20,paddingBottom:20, borderRadius:8}}>
                <div style={{textAlign:'left',marginBottom:22,display:'flex',flexDirection:'column',fontSize:16,fontStyle:'normal',fontWeight:'normal',  color: '#747474',fontStretch:'normal',lineHeight:'normal',letterSpacing:'normal',fontFamily:'Muli'}}>
                <text>You can delete your account from within Slash.</text>
                <text> Deleting your account is irreversible. we can't reverse this even if you delete it by accident.</text>
               </div>
               
                <div style={{ textAlign: 'left' }}> To Delete Your Account: </div>
               <ol style={{paddingLeft:18,display:'flex',flexDirection:'column',fontSize:16,fontStyle:'normal',fontWeight:'normal',  color: '#747474',fontStretch:'normal',lineHeight:'normal',letterSpacing:'normal',fontFamily:'Muli'}}>
               <li style={{textAlign:'left'}}> Open Slash </li>
               <li style={{textAlign:'left'}}> Click on the Profile section in the bottom right of Slash.  </li>
               <li style={{textAlign:'left'}}> Go to Settings{'>'} Account Settings{'>'} Delete Account. </li>
               <li style={{textAlign:'left'}}> Select a reason to delete your account. </li>
               <li style={{textAlign:'left'}}> Write any additional feedback if you have any and Tap Next to proceed further. </li>
               <li style={{textAlign:'left'}}> Click on Delete your Account to permanently delete your account. </li>
               </ol>
               </div>

          </div>
       )
}




export default HowToDel;