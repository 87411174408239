import mobImage  from '../../Assets/mobImage.png'
import listImage  from '../../Assets/listImage.png'
import receiveImage  from '../../Assets/recieveImage.png'
import deliverImage  from '../../Assets/deliverImage.png'
import { BsStackOverflow } from 'react-icons/bs'
import { Container, Row, Col,Card,CardBody, CarouselItem, Carousel, Navbar,CarouselIndicators, } from 'reactstrap'
import React, { Component, Text, useState, useRef } from 'react'
import DownloadOurApp_Mobile from './DownloadOurApp_Mobile'
import CitySpecificBannerHome from './CitySpecificBannerHome';
import {Helmet} from 'react-helmet'
import '../../CSS/UI Components/AreYouPro_benfitCards.css'



function AreYouproMobile(){

  const [ activeIndex,setActiveIndex ]=useState(0);

  let blogItems =[
    {
        key: 1,src:{},
        altText: (
          <div><div style={{top:'45%',left:'6%',zIndex:10,right:'15%',position:'absolute',display:'flex',flexDirection:'column'}}>
                <text  style={{fontSize:30,fontFamily:'Inter',color:'#fff',fontWeight:'bold',fontStretch:'normal',lineHeight:'normal',letterSpacing:'normal',textShadow:'0 4px 4px rgba(0, 0, 0, 0.25)'}}>Are you a service professional ?</text>
                    <text style={{fontSize:14,fontFamily:'Inter',color:'#fff',textShadow:'0 4px 4px rgba(0, 0, 0, 0.25)',}}>With Slash app in your hand, you can easily expand your business and earn more money without any hassle.</text>
                </div>
          </div>
        ),
    },
    
  ]
  
  let blogSlides = blogItems.map((item) => {
               
    return (
  
    <CarouselItem key={item.key}>
        <div className="CarousalContainer_Sv2" style={{width:'100%',background: 'linear-gradient(to top, rgba(0, 0, 0) 100%, rgba(0, 0, 0) 0%)'}}>
            {item.altText}
            <img className='imgStylesv2_HS' style={{height:268}}  src="https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684392573279-lg.png"/>
            {/* <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1666875231964-lg.jpg'} className='Carousal_policy_mobile'/> */}
        </div>
    </CarouselItem>
  
  );
  });
  
  const next = () => {
    // if (animating) return;
    const nextIndex = activeIndex === blogItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  
  const previous = () => {
    // if (animating) return;
    const nextIndex = activeIndex === 0 ? blogItems.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    //   console.log("newIndex",newIndex)
    // if (this.animating) return;
    setActiveIndex(newIndex);
  
  }
 

    return(
      <>

          <Helmet>
              <title>Need assistance with your Slash bookings or services? Visit our support page for help and FAQs. We're here to ensure you have the best experience.</title>
              <meta name="description" content="Slash support, customer support, home service help, FAQs, Slash assistance, Hyderabad" />
          </Helmet>

        <div style={{backgroundColor:'#fff',width:'100%'}}>
        <div className='corosol_div'>
            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                ride="carousel"
                interval={2000}>
                {/* {blogItems.length > 1 ? <CarouselIndicators className="carousel-indicators_Landing" items={blogItems} activeIndex={activeIndex} onClickHandler={goToIndex} /> : null} */}
                {blogSlides}
            </Carousel>
        </div>
        </div>
          <div style={{marginLeft:20,marginRight:20}}>
              <div style={{textAlign:'center',display:'flex',flexDirection:'column',marginBottom:16,marginLeft:'11%',marginRight:'11%'}}>
                  <text >Why Slash?</text>
                  <text style={{fontSize:12}}>An ultimate destination for service professionals looking to grow their business.</text>
              </div>

              <div style={{display:'flex',flexDirection:'column'}}>

              <div style={{display:'flex',flexDirection:'row',marginLeft:44,justifyContent:'center',alignItems:'center',marginBottom:16}}>
                {/* <div style={{ height:'13vw',width:'18vw',backgroundColor:'#d8c5fc',borderRadius: 15,border:'solid 2px #fff',marginRight:8}}/> */}
                <div style={{display:'flex',backgroundColor:'#d8c5fc',textAlign: 'center',height:'13vw',width:'18vw',borderRadius: 15,border:'solid 2px #fff',marginRight:8,alignItems:'center'}}>
                  <img style={{display:'block',width:'9vw',margin:'auto'}} src="https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684426276014-lg.png" alt="FCC Logo" />
                </div>

                <div style={{display:'flex',flexDirection:'column',width:'70vw'}}>
                  <text style={{fontSize:'3vw',color: '#000',fontWeight:500}} >Attract new Customers</text>
                  <text style={{fontSize:'2.5vw'}}>Showcase your services and attract new customers effortlessly</text>    
                </div>
              </div>
              
              
              
              <div style={{display:'flex',flexDirection:'row',marginLeft:44,justifyContent:'center',alignItems:'center',marginBottom:16}}>
                {/* <div style={{height:'13vw',width:'18vw',backgroundColor:'#d8c5fc',borderRadius: 15,border:'solid 2px #fff',marginRight:8}}/> */}
                <div style={{display:'flex',backgroundColor:'#d8c5fc',textAlign: 'center',height:'13vw',width:'18vw',borderRadius: 15,border:'solid 2px #fff',marginRight:8,alignItems:'center'}}>
                  <img style={{display:'block',width:'9vw',margin:'auto'}} src='https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684426405815-lg.png' alt="FCC Logo1" />
                </div>
                <div style={{display:'flex',flexDirection:'column',width:'70vw'}}>
                  <text style={{fontSize:'3vw',color: '#000',fontWeight:500}}>Free marketing</text>
                  <text style={{fontSize:'2.5vw'}}>Our free marketing for you will get you seen by potential clients in no time.</text>    
                </div>
              </div>


              <div style={{display:'flex',flexDirection:'row',marginLeft:44,justifyContent:'center',alignItems:'center',marginBottom:16}}>
                {/* <div style={{ height:'13vw',width:'18vw',backgroundColor:'#d8c5fc',borderRadius: 15,border:'solid 2px #fff',marginRight:8}}/> */}
                <div style={{display:'flex',backgroundColor:'#d8c5fc',textAlign: 'center',height:'13vw',width:'18vw',borderRadius: 15,border:'solid 2px #fff',marginRight:8,alignItems:'center'}}>
                  <img style={{display:'block',width:'9vw',margin:'auto'}} src="https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684426465819-lg.png" alt="FCC Logo2" />
                </div>
                <div style={{display:'flex',flexDirection:'column',width:'70vw'}}>
                  <text style={{fontSize:'3vw',color: '#000',fontWeight:500}} >Fill up your low demand hours</text>
                  <text style={{fontSize:'2.5vw'}}>Take advantage of our seasonal and promotional discounts.</text>    
                </div>
              </div>


              <div style={{display:'flex',flexDirection:'row',marginLeft:44,justifyContent:'center',alignItems:'center',marginBottom:16}}>
                {/* <div style={{ height:'13vw',width:'18vw',backgroundColor:'#d8c5fc',borderRadius: 15,border:'solid 2px #fff',marginRight:8}}/> */}
                <div style={{display:'flex',backgroundColor:'#d8c5fc',textAlign: 'center',height:'13vw',width:'18vw',borderRadius: 15,border:'solid 2px #fff',marginRight:8,alignItems:'center'}}>
                  <img style={{display:'block',width:'9vw',margin:'auto'}} src='https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684382387720-lg.png' alt="FCC Logo3" />
                </div>
                <div style={{display:'flex',flexDirection:'column',width:'70vw'}}>
                  <text style={{fontSize:'3vw',color: '#000',fontWeight:500}} >Save money with special discounts</text>
                  <text style={{fontSize:'2.5vw'}}>Take advantage of our seasonal and promotional discounts.</text>    
                </div>
              </div>

              </div>

              {/* how it works */}
              <div style={{backgroundColor:'#d8c5fc',marginTop:28,marginBottom:30,marginLeft:-20,marginRight:-20,
                // overflowX:'scroll',
                // overflowY:'hidden',
                height:172,
                // whitepace:'nowrap'
                textAlign:'center'
          //     overflow-x: scroll;
          //     overflow-y: hidden;
          //     height: 80px;
          // white-space:nowrap
          }}>
            <div style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',alignContent:'center',marginLeft:'auto',marginRight:"auto",width:200}}>
              <text style={{fontSize:'4vw',color:'#505050',fontWeight:'600',textAlign:'center',fontFamily:'Inter',fontSize:16,paddingTop:28}}>How it works ?</text>
              <text style={{textAlign:'center',fontSize:12,fontFamily:'Inter',fontWeight:300,color:'#9f9f9f',width:326}}> Join us now and experience the power of being connected with potential clients in your area. Try it today!</text>
              </div>
              {/* <div style={{ display:'flex',flexDirection:'row',justifyContent:'center ',alignItems:'center',marginLeft:60}} > */}
                      {/* <div >
                        <text  style={{color:'#461c99',fontSize:24,fontWeight:'bold'}} className='oneSt'>1</text>
                      </div> */}
                      {/* <hr style={{ borderTop:'1px solid black',marginBottom:10,marginTop: 60,marginLeft:0,marginRight: 0,height: 50,width:'100vw'}}></hr> */}
                      {/* <div> */}
                      {/* </div> */}
                      {/* <hr style={{ borderTop:'1px solid black',marginBottom:10,marginTop: 60,marginLeft:0,marginRight: 0,height: 50,width:'100vw'}}></hr> */}
                      {/* <div> */}
                      {/* </div> */}
                      {/* <hr style={{ borderTop:'1px solid black',marginBottom:10,marginTop: 60,marginLeft:0, marginRight: 0,height: 50,width:'100vw'}}></hr> */}
                      {/* <div> */}
                      {/* </div> */}
                  {/* </div> */}
      
              <div style={{overflowX:'scroll',overflowY:'hidden',padding:40,paddingTop:0}}>
              <div className='howItWorks_A'>
            

            <div style={{position:'relative',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
              <text  style={{color:'#461c99',fontSize:24,fontWeight:'bold'}} className='oneSt'>1</text>
            
            <div style={{height:77,width:77,backgroundColor:'#fff',borderRadius:'50%',border:'solid 1px #461c99',marginLeft:'auto',marginRight:'auto'}}>
                <img style={{position:'absolute',top:'47%',left:'50%',transform:'translate(-50%,-50%)'}} src={mobImage} />
                  {/* <text style={{fontSize:16,color: '#000',fontWeight:600,marginBottom:10,width:150,zIndex:120,position:'absolute',top:'90%',left:'50%'}} >Download the app</text> */}
            </div> 
            <div style={{display:'flex',flexDirection:'column'}}>
                <text style={{fontSize:12,color: '#000',fontWeight:600,fontFamily:'Inter'}} >Download the app</text>
                <text style={{fontSize:10,fontFamily:'Inter',width:144}}>Download Slash Pro app and register</text>    
            </div>

            {/* <div style={{display:'flex',flexDirection:'column'}}> */}
                  {/* <text style={{fontSize:16,color: '#000',fontWeight:600,marginBottom:10,width:150,zIndex:120}} >Download the app</text> */}
                  {/* <text style={{fontSize:14,zIndex:120}}>Download Slash Pro app and register</text>     */}
            {/* </div> */}
            {/* <div  style={{display:'flex',flexDirection:'column',justifyContent:'center',position:'absolute' ,marginTop:16,marginLeft:-25}}>
                <text style={{fontSize:'3vw',color: '#000',fontWeight:500,marginBottom:10,}} >Download the app</text>
                <text style={{fontSize:'2vw',marginLeft:-10}}>Download Slash Pro app and register</text>    
            </div>   */}
            {/* <div > */}
              {/* <text style={{fontSize:16,color: '#000',fontWeight:600,marginBottom:10,width:150,zIndex:120}}>Download our app</text> */}
            
              {/* <text style={{fontSize:16,color: '#000',fontWeight:600,marginBottom:10,width:150,zIndex:120}}>Download our app</text> */}
              {/* <text>Download our app</text> */}
              {/* <text>Download our app</text> */}

            {/* </div> */}
            </div>
            
              <hr style={{ borderTop:'1px solid black',marginBottom:41,marginTop: -33,marginLeft:-45,marginRight: -45,height: 50,width:144}}></hr>

            
            <div style={{position:'relative',flexDirection:'column'}}>
            <text style={{color:'#461c99',fontSize:24,fontWeight:'bold'}} className='twoSt'>2</text>

            <div style={{height:77,width:77,backgroundColor:'#fff',borderRadius:'50%',position:'relative',border:'solid 1px #461c99',marginLeft:'auto',marginRight:'auto'}}>
                <img style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)'}} src={listImage} />
            </div>  

            <div style={{display:'flex',flexDirection:'column'}}>
                <text style={{fontSize:12,color: '#000',fontWeight:600,fontFamily:'Inter'}} >List your services</text>
                <text style={{fontSize:10,fontFamily:'Inter',width:134}}>List your service,and update business hours</text>    
            </div>

            {/* <div style={{display:'flex',flexDirection:'column'}}> */}
                  {/* <text style={{fontSize:16,color: '#000',fontWeight:600,marginBottom:10,width:150,zIndex:120}} >Download the app</text> */}
                  {/* <text style={{fontSize:14,zIndex:120}}>Download Slash Pro app and register</text>     */}
            {/* </div> */}
          
            {/* <div  style={{display:'flex',flexDirection:'column',justifyContent:'center',position:'absolute',marginTop:16,marginLeft:-25}}>
                <text style={{fontSize:16,color: '#000',fontWeight:600,marginBottom:10,width:150}} >List your services</text>
                <text style={{fontSize:14,marginLeft:-10}}>List your service,and update business hours</text>    
            </div>   */}
            </div>

            <hr style={{ borderTop:'1px solid black',marginBottom:41,marginTop: -33,marginLeft:-45,marginRight: -45,height: 50,width:144}}></hr>



            <div style={{position:'relative',flexDirection:'column'}}>
            <text  style={{color:'#461c99',fontSize:24,fontWeight:'bold'}} className='threeSt'>3</text>
            <div style={{height:77,width:77,backgroundColor:'#fff',borderRadius:'50%',position:'relative',border:'solid 1px #461c99',marginLeft:'auto',marginRight:'auto'}}>
                <img style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)'}} src={receiveImage} />
            </div>  

            <div style={{display:'flex',flexDirection:'column'}}>
                <text style={{fontSize:12,color: '#000',fontWeight:600,fontFamily:'Inter'}} >Receive booking</text>
                <text style={{fontSize:10,fontFamily:'Inter',width:144}}>Receive booking appointments</text>    
            </div>
            
            {/* <div  style={{display:'flex',flexDirection:'column',justifyContent:'center',position:'absolute',marginTop:16,marginLeft:-25}}>
                <text style={{fontSize:16,color: '#000',fontWeight:600,marginBottom:10,width:150}} >Receive booking</text>
                <text style={{fontSize:14,marginLeft:-10}}>Receive booking appointments</text>    
            </div>   */}
            </div>

            <hr style={{ borderTop:'1px solid black',marginBottom:41,marginTop: -33,marginLeft:-45,marginRight: -45,height: 50,width:144}}></hr>


            <div style={{position:'relative',flexDirection:'column'}}>
            <text style={{color:'#461c99',fontSize:24,fontWeight:'bold'}} className='threeSt'>4</text>

            <div style={{height:77,width:77,backgroundColor:'#fff',borderRadius:'50%',position:'relative',border:'solid 1px #461c99',marginLeft:'auto',marginRight:'auto'}}>
                <img style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)'}} height={50} src={deliverImage} />
            </div>  

              <div style={{display:'flex',flexDirection:'column'}}>
                <text style={{fontSize:12,color: '#000',fontWeight:600,fontFamily:'Inter'}} >Deliver services</text>
                <text style={{fontSize:10,fontFamily:'Inter'}}>Deliver bookings to millions of customers with ease</text>    
            </div> 
            {/* <div  style={{display:'flex',flexDirection:'column',justifyContent:'center',position:'absolute',marginTop:16,marginLeft:-25}}>
                <text style={{fontSize:16,color: '#000',fontWeight:600,marginBottom:10,width:150}} >Deliver services</text>
                <text style={{fontSize:14,marginLeft:-10}}>Deliver bookings to millions of customers with ease</text>    
            </div>   */}
            </div>
      
      
      
        </div>
        </div>


        </div>

        


        {/* Our pricing plan */}
        <div style={{textAlign:'center',display:'flex',flexDirection:'column',color:'#1c1b1f',marginBottom:16,marginTop:110}}>
            <text style={{fontSize:'4vw',fontWeight:'bold'}} >Our pricing plan</text>
            <text style={{fontSize:'3vw',color:'#9f9f9f',fontWeight:'normal'}}>Book services at best price in your neighbourhood</text>
        </div>
  
        {/* Cards */}

        <div style={{display:'flex',flexDirection:'row',  border: 'solid 0.5px #eee',backgroundColor:'#fff',borderRadius:12,boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.15)',padding:12,alignItems:'center',justifyContent:'center',marginBottom:16}}>
        
          {/* <div style={{height:'8vw',width:'8vw',backgroundColor:'#b8b8b8',borderRadius:'50%',position:'relative',marginRight:13}}/> */}
          <img  height={27} width={27} src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684808267789-lg.png'} />
            <div style={{display:'flex',flexDirection:'column',marginLeft:7}}>
                  <text style={{color:'#461c99',fontWeight:500,fontSize:'3vw'}}>Platform fee</text>
                  <text style={{fontSize:'2vw',width:'70vw'}}>Percentage of net price paid by customer to purchase your offer (%)</text>
          </div> 
        </div>



        <div style={{display:'flex',flexDirection:'row',  border: 'solid 0.5px #eee',backgroundColor:'#fff',borderRadius:12,boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.15)',padding:12,alignItems:'center',justifyContent:'center',marginBottom:16}}>
        
        {/* <div style={{height:'8vw',width:'8vw',backgroundColor:'#b8b8b8',borderRadius:'50%',position:'relative',marginRight:13}}/> */}
        <img  height={27} width={27} src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684808247094-lg.png'} />
          <div style={{display:'flex',flexDirection:'column',marginLeft:7}}>
                <text style={{color:'#461c99',fontWeight:500,fontSize:'3vw'}}>Your earnings</text>
                <text style={{fontSize:'2vw',width:'70vw'}}>Net price of the service - Our Platform fee</text>
      </div> 
      </div>

      <div style={{display:'flex',flexDirection:'row',  border: 'solid 0.5px #eee',backgroundColor:'#fff',borderRadius:12,boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.15)',marginBottom:100,padding:12,alignItems:'center',justifyContent:'center',marginBottom:100}}>
        
      <img  height={27} width={27} src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684807988425-lg.png'} />
        {/* <div style={{height:'8vw',width:'8vw',backgroundColor:'#b8b8b8',borderRadius:'50%',position:'relative',marginRight:13}}/> */}
          <div style={{display:'flex',flexDirection:'column',marginLeft:7}}>
                <text style={{color:'#461c99',fontWeight:500,fontSize:'3vw'}}>Payment duration</text>
                <text style={{fontSize:'2vw',width:'70vw'}}>Your service payment is credited within 1-2 business days of service </text>
      </div> 
      </div>

      <div style={{textAlign:'center',display:'flex',flexDirection:'column',marginTop:-70,color:'#1c1b1f',marginBottom:28}}>
            <a href="/support#FAQapp"><text style={{fontSize:16,fontWeight:600,color:'#461c99',textDecoration:'underline'}} >Want to know more ?</text></a>
        </div>


      <div className="MainReusable_Container_Home">
              <CitySpecificBannerHome title={'Ready to grow with us ?'} desc={"With our platform, you'll receive free marketing, an influx of new clients during low-demand hours, and the opportunity to earn more money.Don't wait any longer to elevate your business- join the Slash community today!"} />
          </div>

          {/* Download our App */}
          <div className="MainReusable_Container_Home">
              <DownloadOurApp_Mobile link={'https://play.google.com/store/apps/details?id=com.quickfindmerchant&utm_campaign=Merchant_Guide&utm_source=Website&utm_medium=DigitalMarketingSources'} link2={'https://apps.apple.com/us/app/slash-merchant/id1489211413?ls=1/?utm_campaign=Merchant_Guide&utm_source=Website&utm_medium=DigitalMarketingSources'}/> 
          </div>


            


          </div>
      </>
    )




}
export default AreYouproMobile