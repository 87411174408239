import React from 'react'
import '../../CSS/UI Components/AreYouPro_benfitCards.css'
import { Row, Col, Card, CardText, Container, CardImg } from 'reactstrap'
export default function AreYouPro_benfitCards() {
    return (
      
            <Container fluid className="MainReusable_Container_Home" style={{backgroundColor:'transparent'}}>
            <div className='areyouCard_RowSv2'>
                <div className='areCard_ColSv2'> 
                    {/* <div style={{ flexDirection: 'row', display: 'flex' }}> */}
                        <Card className='areyouNew_CardSv2'>
                            <CardImg src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684426276014-lg.png'} style={{width:150,height:150}}/>
                        </Card>
                    {/* </div> */}
                    <div style={{flexDirection: 'column', display: 'flex',height:100}}>
                        <text className='areproCardText_sv2'>Attract new Customers</text>
                        <text className='proNew_SubtextSv2'>Showcase your services and attract new customers effortlessly   </text>
                    </div>
                </div>
                <div className='whySlashCard_ColSv2'>
                    
                    <Card className='areyouNew_CardSv2'>
                        <CardImg src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684426405815-lg.png'} style={{width:150,height:150}}/>
                    </Card>
                    <div style={{flexDirection: 'column', display: 'flex',height:100}}>
                        <text className='areproCardText_sv2'>Free marketing</text>
                        <text className='proNew_SubtextSv2'>Our free marketing for you will get you seen by potential clients in no time.</text>
                    </div>
                </div>
                <div className='whySlashCard_ColSv2'>
                        <Card className='areyouNew_CardSv2'>
                            <CardImg src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684426465819-lg.png'} style={{width:150,height:150}}/>
                        </Card>
                    {/* </div> */}
                    <div style={{ flexDirection: 'column', display: 'flex',height:100 }}>
                        <text className='areproCardText_sv2'>Fill up your low demand hours</text>
                        <text className='proNew_SubtextSv2'>Boosting customers with specials  discounts and filling up those hours when demand is low</text>
                    </div>  
                   
                </div>  

                <div className='whySlashCard_ColSv2'>
                        <Card className='areyouNew_CardSv2'>
                            <CardImg src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684382387720-lg.png'} style={{width:150,height:150}}/>
                        </Card>
                    {/* </div> */}
                    <div style={{ flexDirection: 'column', display: 'flex',height:100 }}>
                        <text className='areproCardText_sv2'>Save money with special discounts</text>
                        <text className='proNew_SubtextSv2'>Opportunity to increase earnings by getting extra bookings</text>
                    </div>
                   
                </div>
            </div>
            </Container>
        
    )
}
