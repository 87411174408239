import React,{useEffect, useState,useContext} from 'react'
import momenttz from 'moment-timezone'   
import {Collapse } from "reactstrap";
import { globalState } from '../../GlobalState/globalState';
import customFetchData from '../../Components/Custom/customFetchData';
import { useNavigate, NavLink } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import '../../CSS/UI Components/MyTransactions.css'
import dateIcon from '../../Assets/calandar_P.png'
import timerIcon from '../../Assets/timerIcoon.png'
import SuccessTick from '../../Assets/p_tick.png'
import SuccessTick2px from '../../Assets/p_tick@2x.png'




function  MyTransactions(props){


    const navigate = useNavigate();
    const [paymentTabStatus,setPaymentTabStatus] = useState(true)
    const [refundTabStatus,setRefundTabStatus] = useState(false)
    const [payToggle,setPayToggle] = useState([])
    const [refToggle,setRefToggle] = useState([])
    const [state,dispatch]=useContext(globalState)
    const [ErrorMsg,setErrorMsg]=useState('')
    const [payments,setPayments] = useState([])
    const [refunds,setRefunds] = useState([])
    const [loading,setLoading]=useState(true)
    const [loading1,setLoading1]=useState(true)
     
 useEffect(()=>{
        setLoading(true)
        setLoading1(true)
        fetchPayments()
        fetchRefunds()

    },[])
    //PAYMENTS 
    const fetchPayments = async () => {
      let  method="POST",body={uid:state.user.id},url="/website/mytransactions/payments/sv2"
      let {fetch,fetchedData}= await customFetchData(method,body,url,state,dispatch)   
      if(fetch){  
        if(fetchedData.payments.length!==0){
         let toggles =  fetchedData.payments.map(each => (payToggle['toggle_'+each.QF_CONSUMER_PAYMENT_PRID] = true))
          setPayToggle(toggles)
          setPayments(fetchedData.payments)
          setLoading(false)
        }else{
          setPayments([])
          setLoading(false)
          setErrorMsg('No payments found!')
        }
      }else{
        console.log('error')
      }
    }
    //REFUNDS
     const fetchRefunds = async ()=>{
      let  method="POST",body={user_id:state.user.id},url="/website/mytransactions/refunds/sv2"
      let {fetch,fetchedData}= await customFetchData(method,body,url,state,dispatch)   
      if(fetch){  
        if(fetchedData.refQres.length!==0){
          let toggles1 = fetchedData.refQres.map(each => (refToggle['toggle_'+each.QF_CONSUMER_OFFER_ACCEPTED_PRID] = true))
          setRefToggle(toggles1)
          setRefunds(fetchedData.refQres)
          setLoading1(false)
        }else{
          setRefunds([])
          setLoading1(false)
          setErrorMsg('No Refunds found!')
        }
      }else{
        console.log('error')
      }
     }  


     const collapseRefundCloseHandler = (id) =>{
        setRefToggle(prevToggle => {
          return {
              ...prevToggle,    
              ['toggle_'+id]:false 
          }})
      }

      const collapseRefundDetails = (id) => {

        if(refToggle['toggle_'+id]){        
            setRefToggle(prevToggle => {
            return {
                ...prevToggle,
                ['toggle_'+id]:false 
            }})
        }
        else{
            setRefToggle(prevToggle => {
            return {
                ...prevToggle,
                ['toggle_'+id]:true 
            }})
        }
  };




    const collapsePaymentCloseHandler = (id) =>{
        setPayToggle(prevToggle => {
          return {
              ...prevToggle,    
              ['toggle_'+id]:false 
          }})
      }

      const collapsePaymentDetails = (id) => {

        if(payToggle['toggle_'+id]){        
            setPayToggle(prevToggle => {
            return {
                ...prevToggle,
                ['toggle_'+id]:false 
            }})
        }
        else{
            setPayToggle(prevToggle => {
            return {
                ...prevToggle,
                ['toggle_'+id]:true 
            }})
        }
  };
     


    let paymentsRender = []
    let refundsRender  = []
    
    if(payments.length!=0){

     
        paymentsRender  =  payments.map((each,index)=>{
            return (
                    <div key={index} style={{backgroundColor:'#fff',marginBottom:20,padding:16,boxShadow:'0 2px 8px 0 rgba(103, 52, 204, 0.15)',borderRadius:8}}>

                        <div style={{display:'flex',flexDirection:'row'}}>
                         <img src={each.image_link} className = "p_serviceImage" />
                          <div className = "p_secondpart">
                             <p className = 'p_serviceName' >{each.booking[0] ? each.REC_SERVICE_NAME:'SERVICE NAME'}</p>
                             <p className = 'p_merchantName'>{each.booking[0] ? each.MERCHANT_NAME:'MERCHANT_NAME'}</p>
                            <div className='p_date' >
                               <img src={dateIcon} className='p_date_icon'/>
                               <text className='p_date_text'> {momenttz(each.CONSUMER_PAYMENT_PROCESSED_ON_DATE+"Z").tz(each.REC_SERVICE_TIMEZONE ?each.REC_SERVICE_TIMEZONE:'America/Los_Angeles').format("DD MMM YYYY")}</text>
                            </div>    
                            <div className='p_time' >
                               <img src={timerIcon} className='p_time_icon'/>
                               <text className='p_time_text'> {momenttz(each.CONSUMER_PAYMENT_PROCESSED_ON_DATE+"Z").tz(each.REC_SERVICE_TIMEZONE ?each.REC_SERVICE_TIMEZONE:'America/Los_Angeles').format("hh:mm A z")}</text>
                            </div>  
                          </div>  

                        <div className="p_thirdPart">
                        
                           {each.PAYMENT_PROCESSED_CURRENCY == "INR" ?  <text className='p_paidvalue' style={{color: each.CONSUMER_PAYMENT_PASSED ==1 ? '#5ba85a' : '#ff5d5d'}}  >{'\u20B9'}{each.CONSUMER_PAYMENT_PROCESSED_AMOUNT}</text>:
                           <text className='p_paidvalue' style={{color: each.CONSUMER_PAYMENT_PASSED ==1 ? '#5ba85a' : '#ff5d5d'}}>${each.CONSUMER_PAYMENT_PROCESSED_AMOUNT}</text>
                           }    
                           {/* success label */}
                            {each.CONSUMER_PAYMENT_PASSED == 1 ? <div style={{marginTop:-10,display:'flex',justifyContent:'flex-end'}}> 
                            
                            <div style={{}}>
                            <img src={SuccessTick} style={{ width: 8, height: 8,marginTop:-5}}/>
                            </div>
                                <text style={{color:'#5ba85a',marginLeft:2,fontSize:8,fontWeight: "500",fontStyle: "normal"  ,textAlign:'right',fontFamily:'Inter',marginTop:5}}>Successful</text>
                            </div>
                            :
                            <div style={{}}>

                            <div style={{}}>
                            </div>
                            <text style= {{color:'#ff5d5d',marginLeft:2,fontSize:10,fontWeight: "500",fontStyle: "normal" ,textAlign:'right'}}>Failed</text>
                            </div>}

                            <div className='p_viewOrHideDiv' >    
                           {payToggle['toggle_'+each.QF_CONSUMER_PAYMENT_PRID] ?
                
                           <text onClick={()=>collapsePaymentCloseHandler(each.QF_CONSUMER_PAYMENT_PRID)} className='p_hidedetLbl'>Hide details</text>
                           :
                            <text onClick={()=>collapsePaymentDetails(each.QF_CONSUMER_PAYMENT_PRID)} className='p_hidedetLbl' >View details</text>}
                          </div>


                        
                        </div>
                      
                    
                        
                        </div>              

                        <Collapse isOpen={payToggle['toggle_'+each.QF_CONSUMER_PAYMENT_PRID]}>

                        
                        {/* div style={{display:'flex',flexDirection:'row',marginTop:15,justifyContent:'space-between'}> */}
                         <div style={{display:'flex',flexDirection:'row',paddingTop:16 ,marginTop:10,borderTop:'1px solid #e8e8e8'}}>
                            
              
                            <div style={{display:'flex', flexDirection:'column',width:'80%'}}>  
                           
                                <div style={{display:'flex',flexDirection:'row'}}>
                                {each.PAYMENT_SOURCE_OBJECT ?  <text className='p_Debitfrmlbl' >Debited from </text>:null}
                                {each.PAYMENT_SOURCE_OBJECT ? <text className='p_paymentmode' >({each.PAYMENT_SOURCE_OBJECT})</text>:null}
                                </div>
                                <div style={{display:'flex',flexDirection:'row'}}>
                                <text className='p_Debitfrmlbl' >Booking ID</text>
                                {each.booking.length > 2 ? <div style={{marginTop:15}}>
                                </div>:null}
                                { 
                                
                                  each.booking.length !== 0 ? each.booking.map((item,index)=>{

                                    return <text className='p_paymentmode'>{item}{each.booking.length == index+1 ? null : ',' }</text>

                                  }):
                                  
                                  <text   className='p_paymentmode'>{each.booking[0]}</text>
                                }
                                
                                </div>


                                 <div style={{display:'flex',flexDirection:'row'}}>
                                 <text className='p_Debitfrmlbl' >Status</text> 
                                 <div className='p_sucdiv'>
                                 <img src={SuccessTick2px} className='p_csuccess'/>
                                 <text className='p_csuccesslbl'>Successful</text>

                                 </div>
                                </div>
                            </div>
                           
                            <div style={{width:'20%',cursor:'pointer',position:'relative'}} onClick ={()=>navigate('/support')}>
                               <text className='enquiryLbl'>Enquiry</text>

                            </div>
                            
                            </div>


                        </Collapse>


                






                    </div>
            )

        })

    }else{

      if(paymentsRender.length == 0 && !loading){
       
      paymentsRender =  <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'50vw',marginBottom:240}}>
      <text style={{}}>No payments found!</text>
      </div>
      }
                

    }
    if(refunds.length!=0){




        refundsRender  =  refunds.map((each,index)=>{

            return (
                    <div key={index} style={{backgroundColor:'#fff',marginBottom:20,padding:16,boxShadow:'0 2px 8px 0 rgba(103, 52, 204, 0.15)',borderRadius:8}}>

                        <div style={{display:'flex',flexDirection:'row'}}>
                          <img src={each.image_link} className = "p_serviceImage" />
                          <div className = "p_secondpart">
                          <p className = 'p_serviceName' >{each.SERVICE_NAME ? each.SERVICE_NAME:'SERVICE NAME'}</p>
                          <p className = 'p_merchantName'>{each.mer_name[0].MERCHANT_NAME ? each.mer_name[0].MERCHANT_NAME:'MERCHANT_NAME'}</p> 
                          <div className='p_date' >
                               <img src={dateIcon} className='p_date_icon'/>
                               <text className='p_date_text'> {momenttz(each.REFUND_CREATED_AT+"Z").tz(each.SERVICE_TIMEZONE ?each.SERVICE_TIMEZONE:'America/Los_Angeles').format("DD MMM YYYY")}</text>
                            </div>    
                            <div className='p_time' >
                               <img src={timerIcon} className='p_time_icon'/>
                               <text className='p_time_text'> {each.REFUND_TIME}</text>
                            </div>  
                          </div>  

                        <div className="p_thirdPart">
                        
                        {each.SERVICE_CURRENCY == "Rupees" && each.MERCHANT_OFFER_CURRENCY == null ? 
                         <text className='p_paidvalue' style={{color:'#5ba85a'}}>{'\u20B9'}{each.REFUND_AMOUNT}</text>:
                         <text className='p_paidvalue' style={{color:'#5ba85a'}}>{each.SERVICE_CURRENCY == "Rupees" && each.MERCHANT_OFFER_CURRENCY == null ? '\u20B9':'$'}{each.REFUND_AMOUNT}</text>
                  }   
                           {/* success label */}
                            {each.REFUND_STATUS == 1 ?

                           <div style={{marginTop:-10,display:'flex',justifyContent:'flex-end'}}>   
                            <div style={{}}>
                            <img src={SuccessTick} style={{ width: 8, height: 8,marginTop:-5}}/>
                            </div>
                                <text style={{color:'#5ba85a',marginLeft:2,fontSize:8,fontWeight: "500",fontStyle: "normal"  ,textAlign:'right',fontFamily:'Inter',marginTop:5}}>Successful</text>
                            </div>
                            
                            :
                            <div style={{}}>

                            <div style={{}}>
                            </div>
                            <text style= {{color:'#ff5d5d',marginLeft:2,fontSize:10,fontWeight: "500",fontStyle: "normal" ,textAlign:'right'}}>Failed</text>
                            </div>}

                        <div className='p_viewOrHideDiv' >    
                          {refToggle['toggle_'+each.QF_CONSUMER_OFFER_ACCEPTED_PRID] ?
                
                        <text onClick={()=>collapseRefundCloseHandler(each.QF_CONSUMER_OFFER_ACCEPTED_PRID)} className='p_hidedetLbl'>Hide details</text>
                        :
                        <text onClick={()=>collapseRefundDetails(each.QF_CONSUMER_OFFER_ACCEPTED_PRID)} className='p_hidedetLbl'>View details</text>}
                          </div>
                        
                        </div>
                      
                    
                        
                        </div>              

                        <Collapse isOpen={refToggle['toggle_'+each.QF_CONSUMER_OFFER_ACCEPTED_PRID]}>

                        

                        <div style={{display:'flex',flexDirection:'row',marginTop:15,justifyContent:'space-between',borderTop:'1px solid #e8e8e8'}}>
                        <div style={{marginTop:5,marginBottom:5}} ><text className='p_refundlbl'>Refund</text></div>
                        <div style={{marginTop:5}}>
                        {each.SERVICE_CURRENCY == "Rupees" && each.MERCHANT_OFFER_CURRENCY == null ?<text  className='p_paidvalue'>+ {'\u20B9'}{each.REFUND_AMOUNT}</text>:
                        <text  className='p_paidvalue'>+ {each.SERVICE_CURRENCY == "Rupees" && each.MERCHANT_OFFER_CURRENCY == null ? '\u20B9':'$'}{each.REFUND_AMOUNT}</text>
                        }
                       </div>
                       </div>
                       <div style={{flexDirection:'row' ,marginTop:-5}} >
                             <img src={dateIcon} className='p_date_icon'/>
                            <text className='p_date_text'> {momenttz(each.REFUND_CREATED_AT+"Z").tz(each.SERVICE_TIMEZONE ? each.SERVICE_TIMEZONE:'America/Los_Angeles').format("DD MMM YYYY")}</text>
                      </div>    
                      <div style={{flexDirection:"row",marginTop:-5}} >
                             <img src={timerIcon} className='p_time_icon'/>
                             <text className='p_time_text'  style={{marginLeft:4}}>{each.REFUND_TIME}</text>
                      </div>  




                            <div style={{display:'flex',flexDirection:'row' ,marginTop:10}}>
                            
              
                            <div style={{display:'flex', flexDirection:'column',width:'80%'}}>  
                           
                                <div style={{display:'flex',flexDirection:'row'}}>
                                <text className='p_Debitfrmlbl' >Debited from </text>
                                <text className='p_paymentmode' >({each.PAYMENT_SOURCE_OBJECT ?each.PAYMENT_SOURCE_OBJECT : 'upi'})</text>
                                </div>
                                <div style={{display:'flex',flexDirection:'row'}}>
                                <text className='p_Debitfrmlbl' >Booking ID</text>
                                <text className='p_paymentmode'>{each.QF_CONSUMER_OFFER_ACCEPTED_PRID}</text>
                                
                                
                                </div>


                                 <div style={{display:'flex',flexDirection:'row'}}>
                                 <text className='p_Debitfrmlbl' >Status</text> 
                                 <div className='p_sucdiv'>
                                 <img src={SuccessTick2px} className='p_csuccess'/>
                                 <text className='p_csuccesslbl'>Successful</text>

                                 </div>
                                </div>
                            </div>
                           
                            <div style={{width:'20%',cursor:'pointer',position:'relative'}} onClick ={()=>navigate('/support')}>
                               <text className='enquiryLbl'>Enquiry</text>

                            </div>
                            
                            </div>




                        </Collapse>


                    </div>
            )

        })

    }else{

      if(refundsRender.length == 0 && !loading){
       
        refundsRender =  <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'50vw',marginBottom:240}}>
      <text style={{}}>No refunds found!</text>
      </div>
      }
                

    }

    const paymentTabHandler = () =>{       
        setPaymentTabStatus(true)  
        setRefundTabStatus(false)  
      }
      const refundsTabHandler = () =>{
         setRefundTabStatus(true)
         setPaymentTabStatus(false)
      }
   

    return(

        <>  
      
        <div style={{display:'flex',flexDirection:'column',paddingLeft:'5%',paddingRight:'5%',paddingTop:'1%'}}>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginBottom:28,marginTop: props.web ? 40:  80}} >
        <div  onClick={()=>paymentTabHandler()} style={{width:'50%',borderBottomWidth:1.5,borderTop:0,borderLeft:0,borderRight:0,borderStyle:'solid',borderColor:paymentTabStatus ?'#8c52ff' :'#e8e8e8',textAlign:'center',cursor:'pointer'}}><text style={{textAlign:'center',marginBottom:9,fontSize:14,color:paymentTabStatus ?'#8c52ff':'#747474'}}>Payments</text></div>
        <div onClick={()=>refundsTabHandler()} style={{width:'50%',borderTop:0,borderLeft:0,borderRight:0,borderColor: refundTabStatus ? '#8c52ff' :'#e8e8e8',borderStyle:'solid',borderBottomWidth:1.5,textAlign:'center',cursor:'pointer'}}><text style={{textAlign:'center',marginBottom:9,fontSize:14,color:refundTabStatus ?'#8c52ff':'#747474'}}>Refunds</text></div>
       </div> 
           {
            refundTabStatus === true ?

            refundsRender.length == 0 ?

            <div style={{ height: '30%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
           <ClipLoader widthUnit={"%"} size={40} loadings={true} color="#8c52ff" />
            </div>
            :
             refundsRender
              : null
          }
          {
            paymentTabStatus
              ?
              paymentsRender.length == 0 ?

              <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'50vw',marginBottom:240}}>
              <ClipLoader widthUnit={"100%"} size={40} loadings={loading} color="#8c52ff" />
              </div>
            
              : paymentsRender
              :
              null
          }     
        </div>
        </>
    )


}
export default MyTransactions;