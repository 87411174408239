import React, { useEffect, useState, useContext, useRef } from 'react'
import SlashLogoFooter from '../../Assets/download-1-1.png'
import AppStore from '../../../New_WebPage_Design/Assets/Commons/appstore 1.png';
import GooglePlay from '../../../New_WebPage_Design/Assets/Commons/googleplay 1.png';
import InstaGramIcon from '../../Assets/InstagramIcon.png'
import TwitterIcon from '../../Assets/TwitterIcon.png'
import FacebookImage from '../../Assets/FacebookIcon.png'
import PinterestImage from '../../Assets/PinterestIcon.png'
import YoutubeIamge from '../../Assets/YoutubeIcon.png'
import LinkedinImage from '../../Assets/LinkedinIcon.png'
import { Link as NavLinkReactor, useLocation } from 'react-router-dom';

import '../../CSS/FooterSv2.css'
import '../../CSS/HomePage.css'
import { useMediaQuery } from "react-responsive"
import { Form, Input } from 'reactstrap';
import { globalState } from '../../GlobalState/globalState';
import { HashLink } from 'react-router-hash-link';
import FindoutWhatAreULooking from './FindoutWhatAreULooking';
import {  useNavigate, useParams } from 'react-router-dom';

export default function FooterSv2() {
    const useTabletAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 769px)" })
    const isTabView = useTabletAndBelowMediaQuery()
    const [state,dispatch]=useContext(globalState)
    let paramsLocation=useLocation()
    let urlPath=paramsLocation.pathname
    let date = new Date();
    let Year = date.getFullYear();
    const navigate = useNavigate();
    const location = useLocation();
    const urlPath1=location.pathname;
    // useEffect(()=>{

    //     console.log('out',urlPath=='/FAQs',urlPath=='/contactus')
    //     if(urlPath1=='/contactus'){
    //         console.log('dddd')
    //         // <HashLink style={{textDecoration:'none'}} to="/contactus#slash">
            
    //         // </HashLink>
    //         navigate('/contactus#slash')
              
    //         }
    //         if(urlPath1=='/FAQs'){
    //        console.log('fdfsfd')
                    
    //             // <HashLink style={{textDecoration:'none'}} to="/FAQs#FAQapp">
                
    //             // </HashLink>
    //             navigate('/FAQs#FAQapp')
                  
    //      }
         
    //       },[location.pathname])
  return (
    <div style={{ marginBottom:state.cartCount > 0 && state.cart.length!==0 && urlPath=='/allservices/:proName/:proId=?/:proDetail=?' ? 77 : 0}}>
        <div  style={{backgroundColor:'#461C99'}} className="MainReusable_Container_Home">
            <div style={{paddingBottom:50 }}>
                <FindoutWhatAreULooking/>
            </div>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <div style={{display:'flex',flexDirection:'column',width:'30%'}}>
                    <div>
                        <div style={{paddingBottom:10}}>
                            <img src={SlashLogoFooter}/>
                        </div>
                            <text className='footerSv2_subText'>Slash is an easy-to-use digital marketplace to find the best quality home & living service providers in your local area.</text>
                    </div>
                </div>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <text className='footerSv2_MainText' style={{marginBottom:20}}>Company</text>
                    <HashLink style={{textDecoration:'none',marginBottom:20}} to="/blog">
                        <text className='footerSv2_subText'>Blogs & Articles</text>
                    </HashLink>
                    <HashLink style={{textDecoration:'none'}} to="/businesslisting">
                        <text className='footerSv2_subText'>Are you a professional?</text>
                    </HashLink>
                </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <text className='footerSv2_MainText' style={{ marginBottom: 20 }}>Help</text>
                      {/* <a className='footerSv2_subText' href='/HelpAndSupport' style={{marginBottom:20}}>Contact Us</a>  onClick={navigate(`/contactus/${'contact'}`)} */}
                      <HashLink style={{textDecoration:'none',marginBottom:20 }} to={'/contactus'}>
                          <text className='footerSv2_subText'  >Contact Us</text>
                      </HashLink>
                      {/* <a  href='/HelpAndSupport#FAQapp'>FAQs</a> */}
                      <HashLink style={{textDecoration:'none',}} to="/FAQs">
                          <text  className='footerSv2_subText' >FAQs</text>
                      </HashLink>
                  </div>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <text className='footerSv2_MainText' style={{marginBottom:20}}>Legal</text>
                    {/* <a className='footerSv2_subText' href='/terms&policies' style={{marginBottom:20}}>Terms & Conditions</a>
                    <a className='footerSv2_subText' href='/terms&policies'>Privacy & Policy</a> */}
                    <HashLink style={{textDecoration:'none',marginBottom:20}} to="/terms&conditions" state={{docs:'terms', type: 1,active: 1}}>
                          <text className='footerSv2_subText'  >Terms & Conditions</text>
                    </HashLink>
                      {/* <NavLinkReactor className='land_nav_link_style' to={{ pathname: '/termsPolicies', state:{docs: 'terms', type: 1,active: 1}}}> 
                            <text className="land_slash_terms" >Terms & Conditions</text> 
                            </NavLinkReactor> */}
                      {/* <a  href='/HelpAndSupport#FAQapp'>FAQs</a> */}
                      <HashLink style={{textDecoration:'none',}} to="privacy&policy" state={{docs:'privacy', type: 1,active: 1}}>
                          <text  className='footerSv2_subText' >Privacy & Policy</text>
                      </HashLink>
                </div>
                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <text className='footerSv2_downloadText'>Download the app </text>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:20}}>
                        <a href={'https://play.google.com/store/apps/details?id=com.quickfinduser&utm_campaign=Consumer_Guide&utm_source=Website&utm_medium=DigitalMarketingSources'} target="_blank">
                            <img src={GooglePlay} className='footerSv2_googlePlay'/>
                        </a>
                        <a href={'https://apps.apple.com/us/app/slash-consumer/id1489207325?ls=1/?utm_campaign=Consumer_Guide&utm_source=Website&utm_medium=DigitalMarketingSources'} target="_blank">
                            <img src={AppStore} className='footerSv2_appStore'/>
                        </a>
                    </div>
                </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop: isTabView ? 30 : 50 }}>
                <div>
                    <div>
                        <text className='footerSv2_downloadText'>Find Us On</text>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                        <img src={InstaGramIcon} style={{ cursor:'pointer',width:20,height:20 }} onClick={() => { window.open('https://www.instagram.com/goslash.app/') }} />
                        <img src={TwitterIcon} style={{ marginLeft: 20, cursor:'pointer',width:20,height:20 }} onClick={() => { window.open('https://twitter.com/goslashapp') }} />
                        <img src={FacebookImage} style={{ marginLeft: 20,cursor:'pointer',width:20,height:20 }} onClick={() => { window.open('https://www.facebook.com/goslashapp/') }} />
                        <img src={PinterestImage} style={{ marginLeft: 20,cursor:'pointer',width:20,height:20 }} onClick={() => { window.open('https://in.pinterest.com/goslashapp/') }} />
                        <img src={YoutubeIamge} style={{ marginLeft: 20 ,cursor:'pointer',width:20,height:20}} onClick={() => { window.open('https://www.youtube.com/channel/UCNIC3KzckSXejaMtWbRHA2Q') }} />
                        <img src={LinkedinImage} style={{ marginLeft: 20,cursor:'pointer',width:20,height:20 }} onClick={() => { window.open('https://www.linkedin.com/company/goslashapp') }} />
                    </div>
                </div>
                <div className='inputStyle_sv2'>
                        {
                            
                            state.address.country === 'India'
                            ?
                            <text className='inputTextStyle_sv2'>
                            English (IN)
                            </text>
                            :
                            <text className='inputTextStyle_sv2'>
                            English (US)
                            </text>

                        }
                </div>
            </div>
            <div style={{display:'flex',justifyContent:'center',paddingTop:50}}>
                {
                    state.address.country === 'India' ? 
                    <text className='copyRight_Text'>
                    Copyright ©{Year} Prashya Technologies Private Limited.
                    </text>
                    :
                    <text className='copyRight_Text'>
                    Copyright ©{Year} QuickFind, Inc.
                    </text>
                }
            </div>
        </div>
        {/* <div>
            <div style={{backgroundColor:'#461C99'}} className="MainReusable_Container_Home">
                <div style={{display:'flex',justifyContent:'center'}}>
                {
                    state.address.country === 'India' ? 
                    <text className='copyRight_Text'>
                    Copyright ©{Year} Prashya Technologies Private Limited.
                    </text>
                    :
                    <text className='copyRight_Text'>
                    Copyright ©{Year} QuickFind, Inc.
                    </text>
                }
                </div>
            </div>
        </div> */}
    </div>
  )
}
