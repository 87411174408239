import {SET_COUNTRY} from './actionTypes';

const initialState = {
    country:''
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case SET_COUNTRY:
            return {
                ...state,
                country: action.country
            }
    }
    return state;
}

export default reducer;