
export const colors={
    DARK_GREY_BLUE: "#362550",
    WHITE: "#fdfcfc",
    LIGHT_LAVENDER: "#d1c1ff",
    DARK_SLATE_BLUE: "#402666",
    VERY_LIGHT_PURPLE: "#e1d3f8",
    DIRTY_PURPLE: "#553850",
    SLASH_DARK_ACCENT: "#461c99",
    SLASH_ACCENT_COLOR: "#8c52ff",
    ERROR_RED_2: "#ff5d5d",
    BROWNISH_GREY_40: "rgba(116, 116, 116, 0.4)",
    SLASH_ACCENT_COLOR_60: "rgba(140, 82, 255, 0.6)"
}
export const catColors={
    'Home Care':'#ffd167',
    'Hair Care':'#ffb884',
    'Auto Care':'#ff848e',
    'Pet Care':'#c28a71',
    'Garment Care':'#5ba85a',
    'Beauty':'#7db4ff',
    'Tutoring':'#1a49c0f3',
    'Body Care':'#6934ffe3',
    'Professional Services':'#3e7bb7',
    'Astro & More':'#fc79ef',
    'Nail Care':'#ac81ff',
    'Health & Fitness':'#90262f',
    'Appliance Services': '#2C74B6',
    'Electrical':'#FECA14',
    'Cleaning':'#37C8F1'
}