import React, { Component, Text, useState, useRef, useEffect, useContext } from 'react'
import OtpInput from 'react-otp-input';
import { Navbar, NavbarBrand, Nav, NavItem, Row, NavLink, NavbarToggler, Collapse, Col, Modal, FormGroup, Input, Button, FormFeedback, DeviceInfo, } from 'reactstrap';
import '../CSS/UI Components/RegisterAndLogin.css'
import Refer from '../Assets/refer.png'
import { ClipLoader } from 'react-spinners';
import { globalState } from '../GlobalState/globalState';
import { ToastContainer, toast } from 'react-toastify';
import { useMediaQuery } from "react-responsive"
import { authStoreToken, storeCredentials, setAuthToken, storeCredentialsLocalStorage, storeServices } from '../GlobalState/globalStateActions';
import Countdown, { zeroPad, calcTimeDelta, formatTimeDelta } from 'react-countdown';

import customFetchData from '../Components/Custom/customFetchData';
// import ReactMoE, { MoEProperties } from "react-native-moengage";
import Cross from '../Assets/cross.png'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import ResponseModal from '../Components/Custom/UI/ResponseModal';

const DeepClone = require("rfdc/default")
let count = 0

// let height = Dimensions.get('window').height;
const RegisstrAndLogin_India = (props, { navigation, route }) => {
    const useMobileAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 760px)" })
    const useTabletMediaQuery = () => useMediaQuery({ minWidth: 768, maxWidth: 991 })
    const isMobileView = useMobileAndBelowMediaQuery()
    const isTabView = useTabletMediaQuery()
    // console.log('props', props)
    let [state, dispatch] = useContext(globalState)
    const [country, setCoutry] = useState('India')
    let [modalInd, setModalInd] = useState(true)
    const [icon, setIcon] = useState(true)
    const [icon2, setIcon2] = useState(true)
    const [icon3, setIcon3] = useState(true)
    const [modalUSA, setModalUSA] = useState(false)
    const [modalOTP, setModalOTP] = useState(false)
    const [locModal, setlocModal] = useState(false)
    const [serviceModal, setserviceModal] = useState(false)
    // let [Code, setCode] = useState(state.country.country === 'IN' ? '+91' : '+1')
    const searchInput = useRef(null)
    const [refer, setRefer] = useState(false)
    const [refermodal, setReferModal] = useState(false)
    const [usloginText, setUslogintext] = useState(false)
    const [referCode, setReferCode] = useState('')
    const [refModalClose, SetrefModalClose] = useState(false)
    const [register, setReg] = useState('')
    const [login, setLog] = useState('')
    const [referField, setReferField] = useState(false)
    const [mobile, setMobile] = useState('')
    const [message, setMessage] = useState()
    const [message1, setMessage1] = useState()
    const [check1, setCheck1] = useState(true)
    const [check2, setCheck2] = useState(true)
    const [check3, setCheck3] = useState()
    const [status, setStatus] = useState('')
    const [status1, setStatus1] = useState('')
    const [messageModal, setMessageModal] = useState(false)
    const [messageText, setMessageText] = useState('')
    const [emailVerified, setEmailVerified] = useState('')
    const [emailError, setEmailError] = useState('')
    const [email, setEmail] = useState('')
    const [emailValid, setEmailValid] = useState('')
    const [password, setPassword] = useState('')
    const [passwordValid, setPasswordValid] = useState('')
    const [passwordError, setPasswordError] = useState('')
    let [data, setData] = useState({});
    //with out error message move to verify
    const [navstatus, setNavStatus] = useState(false)
    const [referValue, setReferValue] = useState()
    const [otpLoading, setOtploading] = useState(false)
    const [uslogMobile, setUslogMobile] = useState('')

    const [currentTimeIndex, setCurrentTimeIndex] = useState(0);
    const [timerkeyValue, setTimerkeyValue] = useState(1)
    const [timerkeyValue2, setTimerkeyValue2] = useState(1)
    // verify page 
    const [login_US, setLogin_US] = useState(false)
    const [showmailOTP, setshowMailOTP] = useState(false)
    const [otp, setOtp] = useState('')
    const [loading, setLoading] = useState('')
    const [loadingUS, setLoadingUS] = useState(false)
    // const [register, setRegister] = useState(route.params.register)
    const [verifyLoading, setVerifyloading] = useState('')
    const [inputOtp, setInputOtp] = useState('')
    const [verify, setVerify] = useState('')
    const [refCode, setRefCode] = useState(referValue)
    const [checkTerms, setCheckTerms] = useState(true)
    // device data
    const [deviceType, setdeviceType] = useState('')
    const [brand, setBrand] = useState('')
    const [model, setModel] = useState('')
    const [SystemVersion, setSystemVersion] = useState('')
    const [UniqueId, setUniqueId] = useState('')
    const [registerStatus, SetRegisterStatus] = useState('')
    const [loginStatus, SetLoginStatus] = useState(true)
    const [mobileValid, setMobileValid] = useState('')
    const [mobileError, setMobileError] = useState('')
    const [mobileMessage, setMobileMessage] = useState('')
    const [mobileVerified, setMobileVerified] = useState('')
    const [sendOTPButton, setSendOTPButton] = useState('')
    const [userType, setUserType] = useState('')
    let [Code, setCode] = useState(state.country.country === 'IN' ? '+91' : '+1')
    const [showUslogin, setShowuslogin] = useState(false)
    const [showINlogin, setShowINlogin] = useState(false)
    const [usMobilelogin, setUsMobilelogin] = useState(false)
    const [initTimestamp, setInitTimestamp] = useState(Date.now());
    const [initTimestamp2, setInitTimestamp2] = useState(Date.now());
    const [showPassword, setShowPassword] = useState(false)
    const [showchangePassword, setShowchangePassword] = useState(false)
    const [sendMailOTP, setSendMailOTP] = useState(false)
    const [mpid, setMpid] = useState('')
    const [role, setRoal] = useState('')
    const [userVerified, setUserVerified] = useState(false)
    const [newPasswordValid, setNewPasswordValid] = useState(false)
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordError, setNewPasswordError] = useState(false)
    const [errorMessagePassword, setErrorMessagePassword] = useState('')
    const [errorPassword, setErrorPassword] = useState(false)

    const [confirmPasswordValid, setconfirmPasswordValid] = useState(false)
    const [confirmPassword, setconfirmPassword] = useState('')
    const [confirmPasswordError, setconfirmPasswordError] = useState(false)
    const [errorMessageconfirmPassword, setErrorMessageconfirmPassword] = useState('')
    const [sendMailOTPLoading, setsendMailOTPLoading] = useState(false)
    const [verifyMailOTPLoading, setverifyMailOTPLoading] = useState(false)
    const [updatePasswordLoading,setupdatePasswordLoading]=useState(false)
    useEffect(() => {
        // console.log('state in reg', state.country.country)

        setCode(state.country.country === 'IN' ? '+91' : '+1')

        if (verify) {
            setLog(false)
            setReg(false)

        }


    }, [state]);
    // enter button code
    // useEffect(() => {
    //     const otdata=document.querySelector('input')
    //     console.log('data5',otdata)
 
    //         window.addEventListener('keyup', e => {
    //             console.log('salmon',e.key)
    //             if(e.key=='Enter'){
    //                 if(verify==true){
    //                     onPressVerifyOTP(otp)
    //                 }
    //             }
    //           });
     
    //     }, [])


 

    const onChangeMobile = async (event) => {
        let k = /^[0-9\b]+$/;
        if (event.target.value === '') {


            setMobile('')
            setMobileValid(false)
            setMobileError(false)
            setStatus(false)
            setMobileVerified(false)
            setSendOTPButton(false)
            setRefer(false)
            setReferField(false)

           

        } else if ((event.target.value.includes(".") || event.target.value.includes(",") || event.target.value.includes("-") || event.target.value.includes(' '))) {

            // console.log('4', event.target.value)
            setMobileValid(false)
            setMobileError(true)
            setStatus(true)
            // setMobileVerified(false)
            // setSendOTPButton(false)
            setMobileMessage('Special characters cannot be entered')
            setMessage('Special characters cannot be entered')
            // return toast.error("Special characters cannot be entered", { position: toast.POSITION.BOTTOM_RIGHT  });
            return toast("Special characters cannot be entered", {
                position: "bottom-right",
                hideProgressBar: true,
                progress: undefined,
                style: { backgroundColor: '#e0d1ff',textAlign:'center' }
            });

        } else if (/^[A-Za-z]+$/.test(event.target.value)) {
            setMobile('')
        }
        else
            if (/^(\d*)$/.test(event.target.value)) {
                // console.log('2', event.target.value)
                setStatus(false)
                setMobile(event.target.value)
                // setMobileVerified(false)
                setSendOTPButton(true)


                if (event.target.value.length < 10) {
                    // console.log('10 less', event.target.value)
                    // this.setState({
                    //     mobileValid:false,
                    //     status:'',
                    //     mobileError:true,
                    //     // message:'Please enter a valid mobile',
                    //     mobileMessage:'Please enter a valid mobile',
                    //     // nameError:false,
                    //     // emailError:false,
                    //     // passwordError:false,
                    // })


                    //  setMobile(value)
                    setRefer(false)
                    setReferField(false)
                    setMobileValid(false)
                    setMobileError(true)
                    setStatus(true)
                    setMessage('Please enter a valid mobile number')
                    // return toast.error("Please enter a valid mobile number", {position: toast.POSITION.BOTTOM_CENTER});


                }
                else if (event.target.value.length === 10) {
                    console.log('10', event.target.value)
                    setMobile(event.target.value)
                    let uniquedata = {
                        mobile: event.target.value.trim(),
                        isChecked: checkTerms
                    }

                    let { fetchedData } = await customFetchData('POST', uniquedata, '/website/mobileUniqueVerification/Register/sv2', state, dispatch)
                    // console.log("mobileUniqueVerification", fetchedData)

                    if (fetchedData.data) {


                        if (fetchedData.verified) {
                            // this.setState({
                            //     status: 'error',
                            //     // message:'Mobile Number Already Exists',
                            //     message:'This is a registered Professional mobile number. Please use a different number as a Customer',
                            //     mobileMessage:'Mobile Number Already Exists',
                            //     mobileError:true,

                            // })
                            if (fetchedData.merchantVerified) {

                                setMobileError(true)
                                setStatus(true)
                                setMobile(event.target.value)
                                setMessage('This is a registered Professional mobile number. Please use a different number as a Customer')
                                setMobileMessage('Mobile Number Already Exists')
                                // return toast("This is a registered Professional mobile number. Please use a different number as a Customer", { toastId: "professional_number" });
                                return toast("This is a registered Professional mobile number. Please use a different number as a Customer", {
                                    position: "bottom-right",
                                    hideProgressBar: true,
                                    progress: undefined,
                                    style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                                });



                            }
                            else if (fetchedData.userVerified) {
                                setMobileError(false)
                                setStatus(false)
                                setMobile(event.target.value)
                                setMessage('')
                                setRefer(false)

                            }
                            else {
                                setRefer(true)
                            }


                        }
                        else {
                            setRefer(true)
                            if (mobileVerified !== null && mobileVerified == mobile) {
                                // this.setState({
                                //     mobileValid: true,
                                //     status: '',
                                //     message:'',
                                //     modal2: false,
                                //     mobileMessage:'',
                                //     mobileError:false,
                                //     mobileEntry: false
                                // })
                                // if(this.state.emailVerified){
                                //     this.setState({
                                //         sendOTPButton: false
                                //     })
                                // }
                                setMobile(event.target.value)
                                setMobileValid(true)
                                setMobileError(false)
                                setStatus(false)
                                setMessage('')
                            } else {

                                // this.setState({
                                //     modal2:true,
                                //     sendOTP:true,
                                //     mobileMessage:'',
                                //     message:'Please verify your Mobile Number',
                                //     status:'',
                                //     mobileError:false,
                                //     mobileValid: true

                                // })
                                setMobile(event.target.value)
                                setMobileValid(true)
                                setMobileError(false)
                                setStatus(false)
                                setMobileMessage('')
                                setMessage('Please verify your Mobile Number')
                                setSendOTPButton(true)
                                return toast("Please verify your Mobile Number", {
                                    position: "bottom-right",
                                    hideProgressBar: true,
                                    progress: undefined,
                                    style: { backgroundColor: '#e0d1ff',textAlign:'center' }
                                });


                            }

                        }
                        // if (fetchedData.userVerified && !login) {
                        //     setStatus(true)
                        //     setMessage('Mobile Number Already verified')
                        //     // setNavStatus(true)
                        // }
                        // else if (fetchedData.merchantVerified === false && fetchedData.userVerified === false && fetchedData.verified === false && !login) {
                        //     setStatus(true)
                        //     setMessage('Mobile Number Not verified')
                        //     // setNavStatus(false)
                        // }
                        // else if (fetchedData.merchantVerified && !login) {
                        //     setStatus(true)
                        //     setMessage('Mobile Number Already Register As Merchant')
                        //     // setNavStatus(true)
                        // }
                    }
                    else {
                        setStatus(false)
                        setMessage('Internal Error,Please try again.')
                        // return toast.error("Internal Error,Please try again", { position: toast.POSITION.BOTTOM_RIGHT  });
                        return toast("Internal Error,Please try again", {
                            position: "bottom-right",
                            hideProgressBar: true,
                            progress: undefined,
                            style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                        });
                    }
                }




            }

    }
    const onPressBack = () => {
        // console.log('on press back ')
        navigation.goBack()
    }
    const openSendVerificationModal = () => {

        if (country === 'India') {

            setModalInd(true)

        }
        else {
            setModalUSA(false)

        }
        setModalOTP(!modalOTP)


    }
    const toggle1 = () => {

        setModalOTP(false)
        setModalInd(false)
        setModalUSA(false)
        // setModalInd(false)
        setUslogintext(false)

        props.closeModal()

    }



    // terms check box handler
    const checkHandler1 = () => {
        setCheck1(!check1)

    }
    // promotional check box handler
    const checkHandler2 = () => {
        setCheck2(!check2)

    }
    const onChangeRefer = (value) => {
        // console.log('refer', value)
        if (value === '') {
            setReferValue(value)
        }
        else {
            setReferValue(value)
        }

    }

    const userRegistration = async () => {

        let data = {
            mobile: mobile ? mobile : null,
            mobileCountryCode: '91',
            deviceType: deviceType,
            brand: brand + " " + model,
            OSWithVersion: SystemVersion,
            utm_campaign: state.utm.utm_campaign,
            utm_source: state.utm.utm_source,
            refCode: referValue ? referValue.toUpperCase() : null,
            deviceId: UniqueId,
            isChecked: check1
        }

        // console.log('body register function', data)

        let { fetchedData } = await customFetchData('POST', data, '/website/userregister/india/sv2', state, dispatch)
        // console.log("userregister in", fetchedData)
        if (fetchedData) {
            if (fetchedData.res.loginStatus) {
                // ToastAndroid.show('Register successfully', ToastAndroid.LONG)
                toast.success("Registered successfully", { position: toast.POSITION.BOTTOM_RIGHT });
                if (route.params.normalLogin) {
                    navigation.navigate('HomePage', {})
                } else {
                    navigation.navigate('CheckoutPage', {})
                }
            }
        }
    }
    const otpHandleChange = (otp) => setOtp(otp)
    const referModal = () => {
        // setRefer(true)
        setReferModal(true)
        setReferCode('')

    }
    const showPasswordHandler = () => {
        setShowchangePassword(true)
        setSendMailOTP(true)


    }
    const referHandler = (event) => {
        // console.log('change', event.target.value)
        setReferCode(event.target.value)
        if (event.target.value.length === 7) {
            setReferCode(event.target.value)
            setRefer(false)
        }

    }

    const onChangePassword = (e) => {

        setLoadingUS(false)
        if (e.target.value.trim() === '') {

            setPassword('')
            setPasswordValid(false)
            setStatus(true)
            setPasswordError(true)
            setMessage('Please enter Password')

        }

        if (e.target.value.trim() !== '' && e.target.value.length < 8) {
            // this.setState({
            //     passwordValid:false,
            //     containerStyle: 'containerError',
            //     passwordError: true
            // })
            setPasswordValid(false)
            setStatus(true)
            setPassword(e.target.value)
            setPasswordError(true)
            setMessage('Please enter Valid Password')

        }
        else {

            console.log('s')
            //     })
            setPasswordValid(true)
            setStatus(false)
            setPassword(e.target.value.trim())
            setPasswordError(false)
            setMessage('Please enter Valid Password')
        }


    }
    const resendEmail = async () => {
        // this.setState({modal1:false});
        // console.log('resend email')
        let data = {
            email: email,
            userVerified: false
        }
        // console.log('globel', data)
        let method = 'POST';
        let url = '/website/userlogin/resendVerificationCode/sv2'
        let parsedRes = await customFetchData(method, data, url, state, dispatch)
        // console.log('resend verification cade',parsedRes.fetchedData.data)
        if (parsedRes.fetchedData.data) {
            // console.log('resend verification cade')
            // toast.success(parsedRes.fetchedData.message, { position: toast.POSITION.BOTTOM_RIGHT  })
            toast(parsedRes.fetchedData.message, {
                position: "bottom-right",
                hideProgressBar: true,
                progress: undefined,
                style: { backgroundColor: '#E0D1FF',textAlign:'center' }
            });


        }
        else {
            // this.setState({
            //     error: true,
            //     message1: parsedRes.message
            // })
            // setStatus1(true)
            setMessage1(parsedRes.fetchedData.message)

            // let properties15 = new MoEProperties();
            // properties15.addAttribute("type", 'dbError');
            // properties15.addAttribute("action", 'ResendVerifyEmail');
            // properties15.addAttribute("screenName", "GuestSignupLogin");
            // properties15.addAttribute("page", 3);
            // properties15.addAttribute("message", 'Clicked on resend verification code');
            // ReactMoE.trackEvent("Screen_Track", properties15);
        }
    }
    const onPressLogin = async () => {
        setLoading(true)
        console.log('LoginUS worling', email, password)
        if (email.trim() === '') {
            setStatus(true)
            setMessage('Please enter Email address')
            setLoading(false)
        }
        else if (!/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/.test(email.trim())) {
            setStatus(true)
            setMessage('Please enter valid Email address')
            setLoading(false)
        }
        else if (password.trim() === '') {
            setStatus(true)
            setMessage('Please enter Password')
            setLoading(false)
        }
        else {
            // this.setState({loading:true});
            setLoadingUS(true)
            // setLoading(false)
            // console.log("about to fetch data")

            let userData = {
                // email: email,
                // password: password,
                // deviceType: deviceType,
                // brand:tbrand+" "+model,
                // OSWithVersion:SystemVersion,
                // deviceId:UniqueId,
                // utm_campaign:params.utm_campaign,
                // utm_source:params.utm_source,
                // deviceId: params.UniqueId

                email: email,
                password: password,
                deviceType: deviceType,
                brand: brand + " " + model,
                OSWithVersion: SystemVersion,
                deviceId: UniqueId,
                utm_campaign: state.utm.utm_campaign,
                utm_source: state.utm.utm_source,
                deviceId: UniqueId
            }

            let { fetchedData } = await customFetchData('POST', userData, '/website/userlogin/sv2', state, dispatch)
            // console.log("offer data", fetchedData)

            if (fetchedData.userDetails) {
                // console.log('ddddd',fetchedData.userDetails.role)
                if (fetchedData.userDetails.role === 'merchant') {
                    // setLoading(false)
                    // ToastAndroid.show("Please login form the Professional App",ToastAndroid.LONG)
                    // toast.error("Please login from the Professional App", { position: toast.POSITION.BOTTOM_RIGHT  })
                    toast("Please login from the Professional App", {
                        position: "bottom-right",
                        hideProgressBar: true,
                        progress: undefined,
                        style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                    });
                    setLoading(false)

                }
                else if (fetchedData.loginStatus) {
                    // console.log('success')
                    // toast.success("Login Successful", { position: toast.POSITION.BOTTOM_RIGHT  })
                    toast("Login Successful", {
                        position: 'bottom-center',
                        hideProgressBar: true,
                        progress: undefined,
                        style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                    });
                    setLoading(false)
                    // setMessageModal(true)
                    // setMessageText("Login Successful")
                    setModalInd(false)
                    setModalOTP(false)
                    setLoadingUS(false)
                    dispatch(authStoreToken(fetchedData.userDetails.token))
                    dispatch(storeCredentialsLocalStorage(fetchedData.userDetails.id, fetchedData.userDetails.email, fetchedData.userDetails.username, fetchedData.userDetails.role, fetchedData.userDetails.city_from_db, fetchedData.userDetails.country_from_db))
                    // ReactMoE.setUserUniqueID(fetchedData.userDetails.id);
                    // console.log('login',fetchedData.userDetails.id)
                    updateAccountBasedScenarioPricesToOffers(fetchedData.userDetails.id)

                    // AppEventsLogger.setUserID((fetchedData.userDetails.id).toString())

                    let uid = fetchedData.userDetails.id
                    // console.log('console user',fetchedData.userDetails.role,uid)
                    if (fetchedData.userDetails.role === 'consumer') {
                        console.log('success2')
                        //    this.props.onStoreToken(parsedRes.userDetails.token);
                        //    this.props.onStoreCredentialsAsyncStorage(parsedRes.userDetails.id,parsedRes.userDetails.email,parsedRes.userDetails.username,parsedRes.userDetails.role,parsedRes.userDetails.city_from_db,parsedRes.userDetails.country_from_db);
                        //    ReactMoE.setUserUniqueID(parsedRes.userDetails.id);
                        //    //  ReactMoE.setUserAttribute("devicePushId",'');
                        //    AppEventsLogger.setUserID((parsedRes.userDetails.id).toString())
                        //    global.guestEmail= this.state.email;  
                        //    global.guestPassword= this.state.password;  
                        setModalInd(false)
                        setModalOTP(false)
                        setLoadingUS(false)
                        setLoading(false)
                        // if (route.params.normalLogin) {
                        //     navigation.navigate('HomePage', {})
                        // } else {
                        //     navigation.navigate('CheckoutPage', {})
                        // }
                    }

                }
            }
            else {
                // setLoading(false)

                if (fetchedData.dbError || fetchedData.catch) {
                    // setError(true)
                    // setErrorMessage('Internal Error. Please try again.')
                    setStatus(true)
                    setLoading(false)
                    setMessage('Internal Error. Please try again.')
                }
                else if (fetchedData.usernameNotFound) {
                    // setError(true)
                    // setErrorMessage('Please register and then Login.')
                    setStatus(true)
                    setMessage('Please register and then Login.')
                    setLoadingUS(false)
                    setLoading(false)
                }
                else if (fetchedData.merchantFound) {
                    // setError(true)
                    // setErrorMessage('This is a registered Professional email. Please use a different email account as a Customer.')
                    setStatus(true)
                    setMessage('This is a registered Professional email. Please use a different email account as a Customer.')
                    setLoadingUS(false)
                    setLoading(false)
                }
                else if (fetchedData.passwordIncorrect) {
                    // setError(true)
                    // setErrorMessage('Incorrect Email address or Password')
                    setStatus(true)
                    setMessage('Incorrect Email address or Password')
                    setLoadingUS(false)
                    setLoading(false)

                }
                else if (fetchedData.notVerified) {
                    setModel(true)
                    return resendEmail()
                }
                else if (fetchedData.loginAttemptExceed) {
                    // setError(true)
                    // setErrorMessage('Login attempts exceeded. Please try again after 6 hours.')
                    setStatus(true)
                    setMessage('Login attempts exceeded. Please try again after 6 hours.')
                    setLoadingUS(false)
                    setLoading(false)
                }
                else {
                    // setError(true)
                    // setErrorMessage('Something went wrong. Please try again.')
                    setStatus(true)
                    setMessage('Something went wrong. Please try again.')
                    setLoadingUS(false)
                    setLoading(false)
                }
            }
        }
    }
    const onPressSendOTP = async () => {

        setTimerkeyValue(timerkeyValue + 1)
        setInitTimestamp(Date.now())
        console.log('send otop', mobile, uslogMobile)
        // let { params } = route usMobilelogin add cheyyali

        if (!usMobilelogin && mobile.lengtgh === '') {
            setStatus(true)
            setMessage('Please enter your mobile number')
        }
        else if (!usMobilelogin && mobile.length !== 10) {
            console.log('mmm', Number(mobile))

            setStatus(true)
            setMessage('Please enter valid mobile number')
        }

        // login page sms sent
        if (loginStatus || usMobilelogin) {
            setOtploading(true)
            console.log('login route')
            let otpdata = {
                // email:this.state.email ? this.state.email : '',
                email: '',
                mobile: mobile ? mobile : uslogMobile,
                otpType: 'mobileOtp',
                sendOTP: true,
                isChecked: checkTerms
            }
            // console.log('body', otpdata, state.country.country)
            let url = state.country.country === 'IN' ? '/website/login/sms/india/sv2' : '/website/sms/test/twilio/sv2'
            let { fetchedData } = await customFetchData('POST', otpdata, url, state, dispatch)
            // console.log('fetch DATA4', fetchedData, 'usertype', fetchedData.userType)
            if (fetchedData.data) {
                setOtploading(false)
                // toast.info("Verification code sent successfully", { position: toast.POSITION.BOTTOM_RIGHT });
                toast("Verification code sent successfully", {
                    position: "bottom-right",
                    hideProgressBar: true,
                    progress: undefined,
                    style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                });

                setUserType(fetchedData.userType)
                setMobile(mobile)
                setSendOTPButton(true)
                setVerify(true)
                // setLog(false)
                // setReg(false)
                setModalOTP(true)
            }
            else {
                // this.setState({
                //     status:'error',
                //     message:'Currently we are unable to send message to this number',
                //     // modal2:false,
                //     mobileModel:false,
                //     loading:false,
                //     loadingOTP:false
                // })

                setMobile(mobile)
                // setSendOTPButton(true)
                setMessage('Currently we are unable to send message to this number')
                setStatus(true)
                // setVerify(false)
                setLog(false)
                setReg(false)
            }

        }

    }
    const onPressVerifyOTP = async (inputOtpParam) => {

        setLoading(true)
        if (loginStatus || usMobilelogin) {
            if (inputOtpParam.length == 0) {
                // ToastAndroid.show('Invalid verification code', ToastAndroid.LONG);
                // toast.error("Invalid verification code", { position: toast.POSITION.BOTTOM_RIGHT  });
                toast("Invalid verification code", {
                    position: "bottom-right",
                    hideProgressBar: true,
                    progress: undefined,
                    style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                });

            }
            let body = {
                mobile: mobile ? mobile : uslogMobile,
                otp: inputOtpParam,
                otpType: 'mobileOtp',
                sendOTP: false,
                userType: userType,
                deviceType: deviceType,
                brand: brand + " " + model,
                OSWithVersion: SystemVersion,
                utm_campaign: state.utm.utm_campaign,
                utm_source: state.utm.utm_source,
                refCode: referValue ? referValue.toUpperCase() : null,
                deviceId: UniqueId,
                isChecked: check1

            }
            // console.log('body1', body)
            let url = state.country.country === 'IN' ? '/website/login/sms/india/sv2' : '/website/sms/test/twilio/sv2'

            let { fetchedData } = await customFetchData('POST', body, url, state, dispatch)
            // console.log("fetchedData in login verify", fetchedData)
            if (fetchedData.data) {
                if (fetchedData.mobileVerified) {
                    // toast.success("Mobile number verified successfully", { position: toast.POSITION.BOTTOM_CENTER  })
                    toast("Mobile number verified successfully", {
                        position: 'bottom-center',
                        hideProgressBar: true,
                        progress: undefined,
                        style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                    });
                    setMessageModal(true)
                    setMessageText('Mobile number verified successfully')
                    setModalInd(false)
                    setModalOTP(false)
                    setLoading(false)
                    // ToastAndroid.show('Mobile number verified successfully', ToastAndroid.LONG);
                    if (fetchedData.res.userDetails.role === 'consumer') {
                        dispatch(authStoreToken(fetchedData.res.userDetails.token))
                        dispatch(storeCredentialsLocalStorage(fetchedData.res.userDetails.id, fetchedData.res.userDetails.email, fetchedData.res.userDetails.username, fetchedData.res.userDetails.role, fetchedData.res.userDetails.city_from_db, fetchedData.res.userDetails.country_from_db))
                        updateAccountBasedScenarioPricesToOffers(fetchedData.res.userDetails.id)

                        // ReactMoE.setUserUniqueID(fetchedData.res.userDetails.id);
                        // AppEventsLogger.setUserID((fetchedData.res.userDetails.id).toString())
                        // if (route.params.normalLogin) {
                        //     navigation.navigate('HomePage', {})
                        // } else {
                        //     navigation.navigate('CheckoutPage', {})
                        // }
                    }
                    else {
                        // this.setState({
                        //     status: 'error',
                        //     message:'This is a registered Professional email. Please use a different email account as a Customer'
                        // });
                    }
                    // setModalInd(false)
                }
                else if (!fetchedData.mobileVerified) {
                    // ToastAndroid.show('Invalid verification code ', ToastAndroid.LONG);
                    // toast.error("Invalid verification code1", { position: toast.POSITION.BOTTOM_RIGHT  })
                    toast("Invalid verification code", {
                        position: "bottom-right",
                        hideProgressBar: true,
                        progress: undefined,
                        style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                    });

                    setStatus1(true)
                    setMessage1('Verification code expired')
                    setMobileVerified(false)
                    setVerifyloading(false)
                    setLoading(false)
                }
            }
            else {
                // status1: 'error',
                // message1: 'Invalid verification code',
                // error:true,
                // errorMessage: 'Invalid verification code',
                // mobileVerified: false,
                // emailVerified: false,
                // verifyLoading: false,
                // loading:false,
                // loadingOTP:false,
                setStatus1(true)
                setMessage1('Invalid verification code')
                setMobileVerified(false)
                setSendOTPButton(false)
                setLoading(false)
                // ToastAndroid.show('Invalid verification code ', ToastAndroid.LONG);
                if (inputOtpParam.length != 0) {
                    // toast.error("Invalid verification code", { position: toast.POSITION.BOTTOM_RIGHT  })
                    toast("Invalid verification code", {
                        position: "bottom-right",
                        hideProgressBar: true,
                        progress: undefined,
                        style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                    });
                }

            }
        }
    }
    let buttonDisabled = false;
    if (mobile === null || mobile !== null && mobile.length < 10) {
        // console.log('1')
        buttonDisabled = true
    }
    else if (mobileError) {
        // console.log('11')
        buttonDisabled = true
    } else if (!check1) {
        buttonDisabled = true
    }
    else if (!check2) {
        buttonDisabled = true
    }
    else {

        buttonDisabled = false
    }
    const onResendClickedHandler = () => {
        setTimerkeyValue(timerkeyValue + 1)
        // setResendButton(false)
    }
    const onResendClickedHandler2 = () => {
        setTimerkeyValue2(timerkeyValue2 + 1)
        // setResendButton(false)
    }
    const codeHandler = (e) => {
        // console.log('value', e.target.value)
        setCode(e.target.value)
        if (e.target.value === '+91') {
            setLogin_US(false)
            SetLoginStatus(true)
            setCode(e.target.value)
            setUslogintext(false)
        }
        else {
            setLogin_US(true)
            SetLoginStatus(false)
            setCode(e.target.value)
            setUslogintext(true)

        }
    }
    const setModalOTPHandler = () => {
        setModalOTP(false)
        setOtp('')
    }
    const setModalOTPHandler2 = () => {
        // setModalOTP(false)
        setshowMailOTP(false)
        setOtp('')
    }
    // console.log('checked', check1)

    // useEffect(()=>{
    //     updateAccountBasedScenarioPricesToOffers(1563)
    // },[])

    const updateAccountBasedScenarioPricesToOffers = async (consumerId) => {
        // console.log("from updateAccountBasedScenarioPricesToOffers", state.services,)
        if (state.services.length !== 0 && consumerId) 
        {
            console.log("services and cid present")
            let body = {
                consumerId,
                countryName: state.address.country === 'United States' ? 'USA' : (state.address.country === 'India' ? 'IN' : ''),
                services: DeepClone(state.services)
            }
            console.log("body before sending else",body)
            let { fetchedData } = await customFetchData('POST', body, '/website/accountscenario/mapservices', state, dispatch)
            console.log("updateAccountBasedScenarioPricesToOffers fetchedData",fetchedData)
            if (fetchedData.getBookingCount) 
            {
                storeServices(fetchedData.services, state.categories, state.subcategories, dispatch)
                // dispatch({type:'SET_SERVICES',services:fetchedData.services})
                // console.log("servceis after update",fetchedData.services,state.cart)

                if (fetchedData.services.length !== 0 && state.cart.length !== 0) 
                {
                    let loadedServices = DeepClone(fetchedData.services)
                    let cartServices = DeepClone(state.cart)
                    let newServicesToUpdateInCart = []
                    cartServices.forEach(cartItem => {
                        loadedServices.forEach(proArray => {
                            proArray.forEach(serviceItem => {
                                if (cartItem.QF_MERCHANT_SERVICE_PRID === serviceItem.QF_MERCHANT_SERVICE_PRID) {
                                    newServicesToUpdateInCart.push(serviceItem)
                                }
                            })
                        })
                    })

                    // console.log("newServicesToUpdateInCart",newServicesToUpdateInCart)

                    dispatch({ type: 'ADD_TO_CART', cart: newServicesToUpdateInCart })
                    dispatch({ type: 'UPDATE_CART_COUNT', cartCount: newServicesToUpdateInCart.length })

                }
            }
            else {
                // toast.error("Something went wrong. Please try again.", { position: toast.POSITION.BOTTOM_RIGHT  });
                toast("Something went wrong. Please try again.", {
                    position: "bottom-right",
                    hideProgressBar: true,
                    progress: undefined,
                    style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                });
            }
        }
        else if(state.services.length === 0 && state.cart.length!==0 && consumerId)
        {
            let toSendBackArr=[]
            toSendBackArr.push(state.cart)
            console.log("services empty and cid present")
            let body = {
                consumerId,
                countryName: state.address.country === 'United States' ? 'USA' : (state.address.country === 'India' ? 'IN' : ''),
                services: toSendBackArr
            }
            console.log("body before sending else",body)
            let { fetchedData } = await customFetchData('POST', body, '/website/accountscenario/mapservices', state, dispatch)
            console.log("updateAccountBasedScenarioPricesToOffers fetchedData only cart",fetchedData)
            if (fetchedData.getBookingCount) 
            {
                if (fetchedData.services.length !== 0 && state.cart.length !== 0) 
                {
                    let loadedServices = DeepClone(fetchedData.services)
                    let cartServices = DeepClone(state.cart)
                    let newServicesToUpdateInCart = []
                    cartServices.forEach(cartItem => {
                        loadedServices.forEach(proArray => {
                            proArray.forEach(serviceItem => {
                                if (cartItem.QF_MERCHANT_SERVICE_PRID === serviceItem.QF_MERCHANT_SERVICE_PRID) {
                                    newServicesToUpdateInCart.push(serviceItem)
                                }
                            })
                        })
                    })

                    // console.log("newServicesToUpdateInCart",newServicesToUpdateInCart)

                    dispatch({ type: 'ADD_TO_CART', cart: newServicesToUpdateInCart })
                    dispatch({ type: 'UPDATE_CART_COUNT', cartCount: newServicesToUpdateInCart.length })

                }
            }
        }
    }



    const existUsloginHandler = () => {
        setShowuslogin(true)

    }

    const gobackHandler = () => {
        setShowuslogin(false)
        setUslogintext(false)

    }
    const modalHandler = () => {
        setModalInd(false)
        setUslogintext(false)
    }
    const onChangeEmail = async (event) => {
        setLoadingUS(false)
        console.log('targetvalue', event.target.value)
        if (/^(\d*)$/.test(event.target.value)) {
            console.log('digites')
            if (event.target.value.trim() === '') {
                setUsMobilelogin(false)
            }

            if (event.target.value.length === 10) {
                console.log('leghtn is 10')
                // setShowuslogin(false)
                setUsMobilelogin(true)
                // setShowINlogin(false)
                setUslogMobile(event.target.value)

            }
            if (event.target.value.length > 10) {
                setEmail()
            }
            setShowPassword(false)


        }
        else {
            console.log('charactors')
            console.log('1')
            if (event.target.value.length >= 3) {
                setShowPassword(true)
                setEmail(event.target.value.trim())
            }

            else if (event.target.value.length < 3 && /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/.test(event.target.value.trim())) {
                setStatus(true)
                setEmail(event.target.value.trim())
                setEmailValid(false)
                setEmailError(true)
                setMessage('Please enter valid Email Address')



            } else if (event.target.value.length >= 3 && /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/.test(event.target.value.trim())) {
                setStatus(false)
                setMessage('')

            }
            else if (!/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/.test(event.target.value.trim())) {
                if (event.target.value.includes(' ')) {
                    setEmailError(true)

                    if (/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/.test(event.target.value.trim())) {
                        setEmailError(false)
                    }
                } else {

                    setStatus(false)
                    setEmail(event.target.value.trim())
                    setEmailValid(false)
                    setEmailError(true)
                    setMessage('')
                }
            } else {
                if (event.target.value.includes(' ')) {
                    // this.setState({
                    //     emailError:false
                    // })
                    setEmailError(false)
                }


                else {
                    setEmail(event.target.value.trim())
                    setEmailValid(true)
                    setStatus(false)
                    setEmailError(false)

                    // let uniquedata = {
                    //     // mobile: value.trim(),
                    //     email: value
                    // }

                    // // uniqueValidation(uniquedata)


                }
            }
        }

    }
    const onsendOTPEmail = async (event) => {

        console.log('targetvalue', event.target.value)

        console.log('charactors')
        console.log('1')
        if (event.target.value.length >= 3) {
            setShowPassword(true)
            setEmail(event.target.value.trim())
        }

        else if (event.target.value.length < 3 && /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/.test(event.target.value.trim())) {
            setStatus(true)
            setEmail(event.target.value.trim())
            setEmailValid(false)
            setEmailError(true)
            setMessage('Please enter valid Email Address')



        } else if (event.target.value.length >= 3 && /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/.test(event.target.value.trim())) {
            setStatus(false)
            setMessage('')

        }
        else if (!/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/.test(event.target.value.trim())) {
            if (event.target.value.includes(' ')) {
                setEmailError(true)

                if (/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/.test(event.target.value.trim())) {
                    setEmailError(false)
                }
            } else {

                setStatus(false)
                setEmail(event.target.value.trim())
                setEmailValid(false)
                setEmailError(true)
                setMessage('')
            }
        } else {
            if (event.target.value.includes(' ')) {
                // this.setState({
                //     emailError:false
                // })
                setEmailError(false)
            }


            else {
                setEmail(event.target.value.trim())
                setEmailValid(true)
                setStatus(false)
                setEmailError(false)

                // let uniquedata = {
                //     // mobile: value.trim(),
                //     email: value
                // }

                // // uniqueValidation(uniquedata)


            }

        }

    }

    // onPress1=()=>{

    //     Keyboard.dismiss()

    // if(this.state.email.trim() === '' ) {
    //     this.setState({
    //         error:true,
    //         errorMessage:"Please enter registered Email address"
    //     })

    // } 
    // else if(!/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/.test(this.state.email.trim()))
    // {
    //     this.setState({
    //         error:true,
    //         errorMessage:"Please Enter valid Email Address"
    //     })
    // }
    // else {


    //     NetInfo.isConnected.fetch().then(isConnected => {
    //         if(isConnected)
    //         {
    //     let emailData={
    //         email:this.state.email,
    //         fromConsumer:true
    //     }
    //     this.setState({loading:true});
    //     fetch(config.localhost_url+'/consumer/forgotpassword',{
    //         method:'POST',

    //         headers: {

    //           'Content-Type': 'application/json',

    //         },
    //         body:JSON.stringify(emailData)
    //       }).then(res=>res.json())
    //       .then(parsedRes=>{
    //         // console.log(parsedRes);
    //         if(parsedRes.forgotSuccess)
    //         {

    //             if(parsedRes.userVerified){

    //                 this.setState({box1view:false,box2view:true,box3view:false,mpid:parsedRes.id,role:parsedRes.role,loading:false, userVerified: parsedRes.userVerified}); 
    //                 if(this.state.box2view)
    //                 {
    //                     ToastAndroid.show("Verification code sent successfully",ToastAndroid.LONG);
    //                 }
    //             } else {

    //                 this.setState({box1view:false,box2view:false,modalResend:true, box3view:false,mpid:parsedRes.id,role:parsedRes.role,loading:false, userVerified: parsedRes.userVerified}); 
    //                 if(this.state.box2view)
    //                 {
    //                     ToastAndroid.show("Verification code sent successfully",ToastAndroid.LONG);
    //                 }

    //             }
    //         }
    //         else{
    //             this.setState({loading:false})
    //             if(parsedRes.dbError)
    //             {
    //                 ToastAndroid.show("Internal Error. Please try again.",ToastAndroid.LONG);
    //             }
    //             else if(!parsedRes.emailFound)
    //             {
    //                 this.setState({
    //                     error:true,
    //                     errorMessage:"Email Address not found in our records. Please verify."
    //                 })
    //             }
    //             else if(!parsedRes.emailSent)
    //             {
    //                 this.setState({
    //                     error:true,
    //                     errorMessage:"Email was not delivered. Please verify your email address and try again."
    //                 })
    //             }

    //         }

    //       }).catch(error=>{
    //           console.log(error);
    //           this.setState({loading:false})
    //           ToastAndroid.show("Unable to reach server. Please try again.",ToastAndroid.LONG);
    //       })
    //     }
    //     else
    //     {
    //         ToastAndroid.show("Please check you internet connection and try again",ToastAndroid.LONG);

    //     }
    //   });

    // }

    // }
    const sendMailOTPHandler = async () => {
        setTimerkeyValue2(timerkeyValue2 + 1)
        setInitTimestamp2(Date.now())
        setsendMailOTPLoading(true)
        console.log('otpsend mail')
        if (email.trim() === '') {
            // setEmail(email.trim())
            console.log('otpsend mail 2')
            setStatus(true)
            setEmailValid(false)
            setEmailError(true)
            setMessage('Enter email Address')
            setsendMailOTPLoading(false)
        }
        else if (!/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/.test(email.trim())) {
            console.log('otpsend mail 3')
            setStatus(true)
            setEmail(email.trim())
            setEmailValid(false)
            setEmailError(true)
            setMessage('Please enter valid Email Address')
            setsendMailOTPLoading(false)
        }
        else {
            let body = {
                email: email,
                fromConsumer: true
            }
            let url = '/website/forgotpassword/sv2'
            let { fetchedData } = await customFetchData('POST', body, url, state, dispatch)
            console.log("fetchedData from for got password", fetchedData)
            if (fetchedData.forgotSuccess) {
                toast("Verification code sent successfully to your Mail", {
                    position: "bottom-right",
                    hideProgressBar: true,
                    progress: undefined,
                    style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                });
                setshowMailOTP(true)
                setMpid(fetchedData.id)
                setRoal(fetchedData.role)
                setUserVerified(fetchedData.userVerified)
                setsendMailOTPLoading(false)
                // this.setState({box1view:false,box2view:true,box3view:false,mpid:parsedRes.id,role:parsedRes.role,loading:false, userVerified: parsedRes.userVerified}); 


            }
            else {
                if (fetchedData.dbError) {
                    toast("Internal Error. Please try again", {
                        position: "bottom-right",
                        hideProgressBar: true,
                        progress: undefined,
                        style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                    });
                    setsendMailOTPLoading(false)
                    // ToastAndroid.show("Internal Error. Please try again.",ToastAndroid.LONG);
                }
                else if (!fetchedData.emailFound) {
                    toast("Email Address not found in our records. Please verify", {
                        position: "bottom-right",
                        hideProgressBar: true,
                        progress: undefined,
                        style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                    });
                    setsendMailOTPLoading(false)

                    // this.setState({
                    //     error:true,
                    //     errorMessage:"Email Address not found in our records. Please verify."
                    // })
                }
                else if (!fetchedData.emailSent) {
                    toast("Email was not delivered. Please verify your email address and try again.", {
                        position: "bottom-right",
                        hideProgressBar: true,
                        progress: undefined,
                        style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                    });
                    setsendMailOTPLoading(false)
                    // this.setState({
                    //     error:true,
                    //     errorMessage:"Email was not delivered. Please verify your email address and try again."
                    // })
                }

            }
        }


    }
    // otpHandler = (text)=>{


    //     let OTP=text;
    //         OTP.concat(text);

    //     this.setState({vcode:text,error:false,errorMessage:""})

    //     if(text.length === 4){



    //         this.onPress2(text);

    //     }
    // }
    // onPress2=(txt)=>{

    //     if(this.state.userVerified){


    //      if(this.state.vcode.trim()==="")
    //          {
    //              this.setState({
    //                  error:true,
    //                  errorMessage:"Please enter Verification code"
    //              })
    //          }
    //          else if(txt.length<4)
    //          {
    //              this.setState({
    //                  error:true,
    //                  errorMessage:"Incorrect verification code"
    //              })
    //          }
    //          else
    //          {
    //              // console.log("Here", this.state.vcode)

    //          let details={
    //              id:this.state.mpid,
    //              code:Number(txt),
    //              role:this.state.role,
    //              userVerified:true
    //          }
    //          NetInfo.isConnected.fetch().then(isConnected => {
    //              console.log("isCon",isConnected);
    //              if(isConnected)
    //              {
    //                  this.setState({loading:true})
    //          fetch(config.localhost_url+'/website/forgotpassword/newpassword/validate/sv2',{
    //              method:'POST',

    //              headers: {

    //                'Content-Type': 'application/json',

    //              },
    //              body:JSON.stringify(details)
    //            }).then(res=>res.json())
    //            .then(parsedRes=>{
    //              //   console.log(parsedRes);
    //                if(parsedRes.code)
    //                {
    //                  this.setState({box1view:false,box2view:false,box3view:true,loading:false, modalResend: false,error:false,errorMessage:""});   
    //                }
    //                else{
    //                  this.setState({
    //                      error:true,
    //                      errorMessage:"Incorrect verification code",
    //                      loading:false

    //                  });
    //                }
    //            }).catch(err=>{
    //                console.log(err);
    //                this.setState({loading:false})

    //                ToastAndroid.show("Unable to reach server. Please try again later.",ToastAndroid.LONG);

    //              });
    //          }
    //          else
    //          {
    //              ToastAndroid.show("Please check you internet connection and try again",ToastAndroid.LONG);

    //          }
    //        });
    //      }



    //     }
    //     else{


    //      let details={
    //          id:this.state.mpid,
    //          code:Number(txt),
    //          role:this.state.role,
    //          userVerified:false
    //      }
    //      NetInfo.isConnected.fetch().then(isConnected => {
    //          console.log("isCon",isConnected);
    //          if(isConnected)
    //          {
    //              this.setState({loading:true})
    //              fetch(config.localhost_url+'/website/forgotpassword/newpassword/validate/sv2',{
    //                  method:'POST',

    //                  headers: {

    //                  'Content-Type': 'application/json',

    //                  },
    //                  body:JSON.stringify(details)
    //              }).then(res=>res.json())
    //                 .then(parsedRes=>{
    //                  // console.log(parsedRes);
    //                  if(parsedRes.code)
    //                  {

    //                      if(parsedRes.match){

    //                          if (!parsedRes.verified){

    //                                if(!parsedRes.expire){

    //                                    this.setState({box1view:false,box2view:false,box3view:true,loading:false, modalResend: false,error:false,errorMessage:""});   
    //                                    return ToastAndroid.show('Email verified successfully', ToastAndroid.LONG);

    //                                }else{

    //                                  this.setState({
    //                                  message1: 'Verification Code expired',
    //                                  error:true,
    //                                  loading:false,
    //                                });
    //                                }

    //                            }else{

    //                              this.setState({
    //                                  message1: 'Email already verified',
    //                                  error:true,
    //                                  loading:false,
    //                              })


    //                            }
    //                      }else{

    //                          this.setState({
    //                              message1: 'Invalid verification code',
    //                              error:true,
    //                              loading:false,
    //                          })
    //                      }


    //                  }
    //                  else{
    //                      this.setState({
    //                          error:true,
    //                          errorMessage:"Incorrect verification code",
    //                          loading:false

    //                      });
    //                  }
    //              }).catch(err=>{
    //                  console.log(err);
    //                  this.setState({loading:false})

    //                  ToastAndroid.show("Unable to reach server. Please try again later.",ToastAndroid.LONG);

    //              });
    //          }
    //          else
    //          {
    //              ToastAndroid.show("Please check you internet connection and try again",ToastAndroid.LONG);

    //          }
    //    });


    //  } 




    //  }


    const onPressVerifyMailOTP = async (inputOtpParam) => {
        console.log('verifyotp', userVerified)
        setverifyMailOTPLoading(true)
        if (inputOtpParam.length == 0) {
            // ToastAndroid.show('Invalid verification code', ToastAndroid.LONG);
            // toast.error("Invalid verification code", { position: toast.POSITION.BOTTOM_RIGHT  });
            toast("Invalid verification code", {
                position: "bottom-right",
                hideProgressBar: true,
                progress: undefined,
                style: { backgroundColor: '#E0D1FF',textAlign:'center' }
            });
            setverifyMailOTPLoading(false)

        }
        if (userVerified) {
            let body = {
                id: mpid,
                code: inputOtpParam,
                role: role,
                userVerified: true
            }
            console.log('verifyotp1')
            let url = '/website/forgotpassword/newpassword/validate/sv2'
            let { fetchedData } = await customFetchData('POST', body, url, state, dispatch)
            console.log("fetchedData  forgitmail password", fetchedData)
            if (fetchedData.code) {
                //   this.setState({box1view:false,box2view:false,box3view:true,loading:false, modalResend: false,error:false,errorMessage:""});   
                setMessage('')
                setStatus(false)
                setshowMailOTP(false)
                setSendMailOTP(false)
                setverifyMailOTPLoading(false)
                toast("Email verified successfully", {
                    position: "bottom-right",
                    hideProgressBar: true,
                    progress: undefined,
                    style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                });

            }
            else {
                setMessage('"Incorrect verification code')
                setStatus(false)
                setverifyMailOTPLoading(false)
                toast("Incorrect verification code", {
                    position: "bottom-right",
                    hideProgressBar: true,
                    progress: undefined,
                    style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                });


            }

        }
        else {
            console.log('verifyotp1')
            let body = {
                id: mpid,
                code: inputOtpParam,
                role: role,
                userVerified: false
            }
            let url = '/website/forgotpassword/newpassword/validate/sv2'
            let { fetchedData } = await customFetchData('POST', body, url, state, dispatch)
            console.log(" password", fetchedData)
            if (fetchedData.code) {
                if (fetchedData.match) {

                    if (!fetchedData.verified) {

                        if (!fetchedData.expire) {

                            //   this.setState({box1view:false,box2view:false,box3view:true,loading:false, modalResend: false,error:false,errorMessage:""});   
                            //   return ToastAndroid.show('Email verified successfully', ToastAndroid.LONG);
                            toast("Email verified successfully", {
                                position: "bottom-right",
                                hideProgressBar: true,
                                progress: undefined,
                                style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                            });
                            setverifyMailOTPLoading(false)
                            setshowMailOTP(false)

                        }
                        else {

                            //     this.setState({
                            //     message1: 'Verification Code expired',
                            //     error:true,
                            //     loading:false,
                            //   });
                            setverifyMailOTPLoading(false)
                            toast("Verification Code expired", {
                                position: "bottom-right",
                                hideProgressBar: true,
                                progress: undefined,
                                style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                            });
                        }

                    } else {

                        // this.setState({
                        //     message1: 'Email already verified',
                        //     error:true,
                        //     loading:false,
                        // })
                        setverifyMailOTPLoading(false)
                        toast("Email already verified", {
                            position: "bottom-right",
                            hideProgressBar: true,
                            progress: undefined,
                            style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                        });


                    }
                } else {

                    // this.setState({
                    //     message1: 'Invalid verification code',
                    //     error:true,
                    //     loading:false,
                    // })
                    setverifyMailOTPLoading(false)
                    toast("Invalid verification code", {
                        position: "bottom-right",
                        hideProgressBar: true,
                        progress: undefined,
                        style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                    });
                }
            }
            else {
                // this.setState({
                //     error:true,
                //     errorMessage:"Incorrect verification code",
                //     loading:false

                // });
                setverifyMailOTPLoading(false)
                toast("Incorrect verification code", {
                    position: "bottom-right",
                    hideProgressBar: true,
                    progress: undefined,
                    style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                });
            }

        }

    }


    // onChangeNewPassword1 = (value) => {
    // const [newPasswordValid,setNewPasswordValid]=useState(false)
    // const [newPassword,setNewPassword]=useState('')
    // const [newPasswordError,setNewPasswordError]=useState(false)
    // const [errorMessagePassword,setErrorMessagePassword]=useState('')
    // const [errorPassword,setErrorPassword]=useState(false)
    //     // let error = (value) ? false : true
    //     if(value.trim()===''){
    //         this.setState({
    //             newPasswordValid:false,
    //             newPassword:"",
    //             error:true,
    //             newPasswordError: true,
    //             errorMessage:'Please enter New Password'
    //         })

    //     }
    //     else if(value.length < 3 && /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/.test(value.trim())){

    //         this.setState({error:true,errorMessage:"Please enter valid New Password", newPasswordValid: false, newPasswordError: true })


    //       } else if(value.length >=3 && /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/.test(value.trim())){

    //         this.setState({error:false,errorMessage:''})


    //       } else if(value.trim().length<8){
    //         this.setState({
    //             newPassword: value,
    //             error:false,
    //             newPasswordValid:false,
    //             newPasswordError: true
    //         })

    //     }else{
    //     this.setState({
    //         newPassword: value,
    //         error:false,
    //         newPasswordValid:true,
    //         newPasswordError: false
    //     })
    //     if(value.trim() === this.state.confirmPassword){
    //         this.setState({confirmPasswordError: false})
    //     } else if(this.state.confirmPassword.trim() !== '' && value.trim() !== this.state.confirmPassword){
    //         this.setState({confirmPasswordError: true})
    //     }
    // }
    // }

    // onChangeConfirmPassword = (value) => {
    //     // let error = (value) ? false : true
    //     const [confirmPasswordValid,setconfirmPasswordValid]=useState(false)
    //     const [confirmPassword,setconfirmPassword]=useState('')
    //     const [confirmPasswordError,setconfirmPasswordError]=useState(false)
    //     const [errorMessageconfirmPassword,setErrorMessageconfirmPassword]=useState('')
    //     const [errorConfirmPassword,setErrorConfirmPassword]=useState(false)
    //     if(value.trim()===''){
    //         this.setState({
    //             confirmPassword: '',
    //             confirmPasswordValid:false,
    //             error:true,
    //             errorMessage:'Please enter Confirm Password',
    //             confirmPasswordError: true
    //         })
    //     } else if(value.length < 3 && /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/.test(value.trim())){

    //         this.setState({error:true,errorMessage:"Please enter valid Confirm Password", confirmPasswordValid: false, confirmPasswordError: true})


    //       } else if(value.length >=3 && /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/.test(value.trim())){

    //         this.setState({error:false,errorMessage:''})


    //       }  else if(value.trim() !== this.state.newPassword){
    //         this.setState({
    //             confirmPassword: value,
    //             confirmPasswordValid:false,
    //             error:false,
    //             errorMessage:"",
    //             confirmPasswordError: true
    //         })
    //     } else{
    //     this.setState({
    //         confirmPassword: value,
    //         error:false,
    //         confirmPasswordValid:true,
    //         confirmPasswordError: false
    //     })
    // }
    // }  

    const onChangeNewPassword = (e) => {
        if (e.target.value.trim() === '') {
            setNewPasswordValid(false)
            setNewPassword('')
            setNewPasswordError(true)
            setErrorMessagePassword('Please enter New Password')
            setErrorPassword(true)

        }
        else if (e.target.value.length < 3 && /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/.test(e.target.value.trim())) {
            setErrorMessagePassword('Please enter valid New Password')
            setErrorPassword(true)
            setNewPasswordValid(false)
            setNewPassword('')
            setNewPasswordError(true)
        }
        else if (e.target.value.length >= 3 && /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/.test(e.target.value.trim())) {

            setErrorMessagePassword('')
            setErrorPassword(false)


        }
        else if (e.target.value.trim().length < 8) {
            // this.setState({
            //     newPassword: value,
            //     error:false,
            //     newPasswordValid:false,
            //     newPasswordError: true
            // })
            setNewPasswordValid(false)
            setNewPassword(e.target.value)
            setNewPasswordError(true)
            setErrorMessagePassword('')
            setErrorPassword(false)
        }
        else {
            // this.setState({
            //     newPassword: value,
            //     error:false,
            //     newPasswordValid:true,
            //     newPasswordError: false
            // })
            setNewPasswordValid(true)
            setNewPassword(e.target.value)
            setNewPasswordError(true)
            setErrorMessagePassword('')
            setErrorPassword(false)
        }
        if (e.target.value.trim() === confirmPassword) {
            // this.setState({confirmPasswordError: false})
            setconfirmPasswordError(false)
        } else if (confirmPassword.trim() !== '' && e.target.value.trim() !== confirmPassword) {
            // this.setState({confirmPasswordError: true})
            setconfirmPasswordError(true)
        }

    }

    const onChangeConfirmPassword = (e) => {
        if (e.target.value.trim() === '') {
            setconfirmPasswordValid(false)
            setconfirmPassword('')
            setconfirmPasswordError(true)
            // setErrorMessageconfirmPassword('Please enter confirm Password')
            // setErrorConfirmPassword(true)
            setErrorMessagePassword('Please enter confirm Password')
            setErrorPassword(true)

        }
        else if (e.target.value.length < 3 && /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/.test(e.target.value.trim())) {
            // setErrorMessageconfirmPassword('Please enter valid confirm Password')
            setconfirmPasswordValid(false)
            setconfirmPassword('')
            setconfirmPasswordError(true)
            // setErrorConfirmPassword(true)
            setErrorMessagePassword('Please enter valid confirm Password')
            setErrorPassword(true)
        }
        else if (e.target.value.length >= 3 && /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/.test(e.target.value.trim())) {

            // setErrorMessageconfirmPassword('')
            // setErrorConfirmPassword(false)
            setErrorMessagePassword('')
            setErrorPassword(false)


        }
        else if (e.target.value.trim().length < 8) {
            // this.setState({
            //     newPassword: value,
            //     error:false,
            //     newPasswordValid:false,
            //     newPasswordError: true
            // })

            setconfirmPasswordValid(false)
            setconfirmPassword(e.target.value)
            setconfirmPasswordError(true)
            // setErrorMessageconfirmPassword('')
            // setErrorConfirmPassword(false)
            setErrorMessagePassword('')
            setErrorPassword(false)
        }
        else {
            // this.setState({
            //     newPassword: value,
            //     error:false,
            //     newPasswordValid:true,
            //     newPasswordError: false
            // })


            setconfirmPasswordValid(true)
            setconfirmPassword(e.target.value)
            setconfirmPasswordError(true)
            // setErrorMessageconfirmPassword('')
            // setErrorConfirmPassword(false)
            setErrorMessagePassword('')
            setErrorPassword(false)
        }
        if (e.target.value.trim() === confirmPassword) {
            // this.setState({confirmPasswordError: false})
            // setErrorConfirmPassword(false)
            setErrorPassword(false)
        } else if (confirmPassword.trim() !== '' && e.target.value.trim() !== confirmPassword) {
            // this.setState({confirmPasswordError: true})
            // setErrorConfirmPassword(true)
            setErrorPassword(false)
        }

    }


    //   onPress3=()=>{
    //     // this.setState({box1:false,box2:true,box3:false}); 

    //     if(this.state.newPassword.trim() === '' ) {
    //         this.setState({
    //             error:true,
    //             errorMessage:'Please enter New Password'
    //         })

    //     } 
    //     else if(this.state.confirmPassword.trim() === '' ) {
    //         this.setState({
    //             error:true,
    //             errorMessage:'Please enter Confirm Password'
    //         })

    //     }
    //     else if(this.state.newPassword.trim().length<8 || this.state.newPassword.trim().length>25)
    //     {
    //         this.setState({
    //             error:true,
    //             errorMessage:'Password length should be at least 8 characters'
    //         })
    //     }
    //     // else if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/.test(this.state.newPassword.trim()))
    //     // {
    //     //     this.setState({
    //     //         error:true,
    //     //         errorMessage:'Password length should be at least 8 characters'
    //     //     })
    //     // }
    //     else if(this.state.newPassword!==this.state.confirmPassword)
    //     {
    //         this.setState({
    //             error:true,
    //             errorMessage:"New Password and Confirm Password do not match"
    //         })
    //     }

    //     else {

    //         let pwdData={
    //             id:this.state.mpid,
    //             password:this.state.newPassword,
    //             role:this.state.role,
    //             code:this.state.vcode

    //         }
    //         NetInfo.isConnected.fetch().then(isConnected => {
    //             console.log("isCon",isConnected);
    //             if(isConnected)
    //             {
    //         fetch(config.localhost_url+'/consumer/forgotpassword/newpassword',{
    //             method:'POST',

    //             headers: {

    //               'Content-Type': 'application/json',

    //             },
    //             body:JSON.stringify(pwdData)
    //           }).then(res=>res.json())
    //           .then(parsedRes=>{

    //             // console.log(parsedRes);
    //             if(parsedRes.passwordChange)
    //             {
    //                 ToastAndroid.show('Password changed successfully',ToastAndroid.SHORT);
    //                 this.props.navigation.replace('Login');
    //             }
    //             else{
    //                 ToastAndroid.show("Password Change failed. Please try again later.",ToastAndroid.LONG);
    //             }

    //           }).catch(error_1=>{
    //               console.log(error_1);
    //               this.setState({loading:false})

    //               ToastAndroid.show("Unable to reach server. Please try again later.",ToastAndroid.LONG);
    //           });
    //         }
    //         else
    //         {
    //             ToastAndroid.show("Please check you internet connection and try again",ToastAndroid.LONG);

    //         }
    //       });

    //     }
    // }
    const onPressSubmit = async () => {
        // this.setState({box1:false,box2:true,box3:false}); 
        console.log('running',newPassword,confirmPassword)
      
        setupdatePasswordLoading(true)
        if (newPassword.trim() === '') {
            // this.setState({
            //     error:true,
            //     errorMessage:'Please enter New Password'
            // })
            setErrorMessagePassword('Please enter New Password')
            setErrorPassword(true)
           
            setupdatePasswordLoading(false)

        }
        else if (confirmPassword.trim() === '') {
            // this.setState({
            //     error:true,
            //     errorMessage:'Please enter Confirm Password'
            // })

            setErrorMessagePassword('Please enter confirm Password')
            setErrorPassword(true)
            setShowuslogin(true)
            setupdatePasswordLoading(false)

        }
        else if (newPassword.trim().length < 8 || newPassword.trim().length > 25) {
            // this.setState({
            //     error:true,
            //     errorMessage:'Password length should be at least 8 characters'
            // })
            setErrorMessagePassword('Password length should be at least 8 characters')
            setErrorPassword(true)
            setupdatePasswordLoading(false)

        }

        else if (newPassword !== confirmPassword) {
            // this.setState({
            //     error:true,
            //     errorMessage:"New Password and Confirm Password do not match"
            // })
            setErrorMessagePassword('New Password and Confirm Password do not match')
            setErrorPassword(true)
            setupdatePasswordLoading(false)
        }

        else {

            let pwdData = {
                id: mpid,
                password: newPassword,
                role: role,
                code: otp

            }
            let url = '/website/forgotpassword/newpassword/sv2'
            let { fetchedData } = await customFetchData('POST', pwdData, url, state, dispatch)
            console.log(" newpassword", fetchedData)
            if (fetchedData.passwordChange) {
                // ToastAndroid.show('Password changed successfully',ToastAndroid.SHORT);
                // this.props.navigation.replace('Login');
                toast("Password changed successfully", {
                    position: "bottom-right",
                    hideProgressBar: true,
                    progress: undefined,
                    style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                });
                setupdatePasswordLoading(false)
                setShowchangePassword(false)
                setShowPassword(false)

            }
            else {
                // ToastAndroid.show("Password Change failed. Please try again later.",ToastAndroid.LONG);
                toast("Password Change failed. Please try again later.", {
                    position: "bottom-right",
                    hideProgressBar: true,
                    progress: undefined,
                    style: { backgroundColor: '#E0D1FF',textAlign:'center' }
                });
                setupdatePasswordLoading(false)
            }



        }
    }
    let buttonDisabled1 = true;
    if (email.trim() === '') {
        buttonDisabled1 = true;
    }
    else if (!/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/.test(email.trim())) {
        buttonDisabled1 = true;
    }
    else if (password.trim() === '') {
        buttonDisabled1 = true;
    }
    else {
        buttonDisabled1 = false;
        // buttonDisabled = this.state.loading;
    }
    // console.log('checked', userVerified)
    let errorMessage = {
        borderRadius: 4.2,
        backgroundColor: "#ffe4c5",
        shadowColor: "rgba(0, 0, 0, 0.04)",
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowRadius: 31,
        shadowOpacity: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 10,
        paddingBottom: 10,
        marginRight: isMobileView ? 10 : 30,
        marginLeft: isMobileView ? 10 : 30,
        marginTop: 10,
        //    marginTop:20,
        height: 30

    }
    let errorTextColor = {
        fontFamily: "Inter",
        fontSize: 12.5,
        fontWeight: "600",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        color: "#f3972d"
    }
    return (

        <div>

            <Modal
                className="modalStyle"
                // style={{ position: "absolute", right: 0, top: -32, borderRadius: 0, width: '34%' }}
                //    isOpen={false}
                isOpen={modalInd}
                toggle={toggle1}
                modalTransition={{ timeout: 400 }}
            >
                <div className='modalDiv'>
                    <div className='crossdiv'>
                        {/* {state.country.country === 'IN1'?'india'+loginStatus:'us'+login_US} */}
                        <img src={Cross} style={{ width: 20, height: 20, cursor: 'pointer' }} onClick={modalHandler} />
                    </div>

                    {!showUslogin ?
                        <div>
                            <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                                {status ?
                                    <div style={errorMessage}>
                                        <text style={errorTextColor}>{message}</text>
                                    </div>
                                    : <div style={{ padding: 10, marginTop: 10, height: 30 }}></div>
                                }
                                <text className='hello'>Hello there!</text>
                                <text className='subtext'>Login or Register yourself to get started.</text>

                            </div>
                            <div className='inputdiv'>
                                <Input
                                    onChange={codeHandler}
                                    className='code4'
                                    // valid={this.state.Code.valid ? true : false}
                                    // invalid={this.state.Code.invalid ? true : false}
                                    value={Code}
                                    type="select">
                                    <option value='+1'>+1</option>
                                    <option value='+91'>+91</option>
                                </Input>

                                <Input
                                    onChange={onChangeMobile}

                                    valid={mobileValid?true:false}
                                    // invalid={mobileValid? false : true} 
                                    placeholder="Phone number"
                                    value={mobile}
                                    maxLength={10}
                                    type="text" />

                            </div>
                            {refer ?
                                <text onClick={referModal} className='referText' style={{ cursor: 'pointer' }} >Have a refferal code?</text>
                                : null}
                            {otpLoading ?
                                <div style={{ height: 200, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                    <ClipLoader widthUnit={"%"} size={40} loadings={true} color="#8c52ff" />
                                </div> :
                                <Button onClick={onPressSendOTP} disabled={buttonDisabled} style={{ cursor: 'pointer' }} className='sendButton' >Send verification code</Button>
                            }

                            <div className='check2'>
                                <Input type="checkbox" onChange={checkHandler1} style={{ marginRight: 5 }} defaultChecked={check1} />
                                <span className='checkWidth1' style={{ fontSize: 12 }}>I agree with Terms & Conditions and Privacy Policy</span>
                            </div>
                            <div className='check1'>
                                <Input type="checkbox" onChange={checkHandler2} style={{ marginRight: 5 }} defaultChecked={check2} />
                                <span className='checkWidth2' style={{ fontSize: 12 }}>I agree to receive promotional communications from slash</span>
                            </div>
                            {Code == "+1" ?
                                <div className='logintextDiv'>
                                    <text>Already have Existing Account?<span className='loginText' onClick={existUsloginHandler}>Login</span></text>
                                </div>
                                : null}
                            <text className='acctext' >   <span style={{ fontStyle: 'Inter', fontWeight: 'semi-bold' }}></span></text>
                        </div>
                        : null
                        
                    }

                    {showUslogin ?
                        <div>
                            {status ?
                                <div style={errorMessage}>
                                    <text style={errorTextColor}>{message}</text>
                                </div>
                                : <div style={{ padding: 10, marginTop: 10, height: 30 }}></div>}
                            {!showchangePassword ?
                                <div className='inputdivUS'>
                                    <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>

                                        <text className='hello1'>Welcome back</text>
                                        <text className='subtextloginUS'>Log in to an existing account to continue</text>
                                    </div>
                                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                                        {usMobilelogin ?
                                            <Input
                                                onChange={codeHandler}
                                                className='code4'
                                                style={{ marginTop: usMobilelogin ? 20 : 0 }}
                                                // valid={this.state.Code.valid ? true : false}
                                                // invalid={this.state.Code.invalid ? true : false}
                                                value={Code}
                                                type="select">
                                                <option value='+1'>+1</option>
                                                <option value='+91'>+91</option>
                                            </Input> : null}

                                        <Input
                                            onChange={onChangeEmail}
                                            style={{ marginTop: 20 }}
                                            valid={mobile}
                                            // invalid={this.state.mobileNumber.invalid ? true : false} 
                                            placeholder="Email or Phone Number"
                                            // value={this.state.mobileNumber.value}
                                            maxLength={usMobilelogin ? 10 : 60}
                                            type="text" />
                                    </div>
                                    {showPassword ?
                                        <div>

                                            <div style={{ flexDirection: 'row', display: 'flex', position: 'relative', marginBottom: 15 }}>
                                                <Input
                                                    onChange={onChangePassword}
                                                    style={{ marginTop: 20 }}
                                                    // valid={password}
                                                    // invalid={} 
                                                    placeholder="Password"
                                                    value={password}
                                                    maxLength={10}

                                                    type={icon ? "password" : 'text'} />

                                                <div style={{ position: 'absolute', right: 10, paddingTop: 25 }}>
                                                    {icon ?
                                                        <AiOutlineEye color='#c7c7c7' size={18} onClick={() => setIcon(!icon)} />
                                                        : <AiOutlineEyeInvisible color='#c7c7c7' size={18} onClick={() => setIcon(!icon)} />}
                                                </div>
                                            </div>
                                            <text onClick={showPasswordHandler} className='passwordText' style={{ cursor: 'pointer' }} >Forgot Password ?</text>
                                        </div>
                                        :
                                        null
                                    }

                                    {loadingUS ?

                                        <div style={{ height: 200, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                            <ClipLoader widthUnit={"%"} size={40} loadings={true} color="#8c52ff" />
                                        </div> :
                                        usMobilelogin ?
                                            // <Button onClick={onPressSendOTP} disabled={buttonDisabled} style={{ cursor: 'pointer' }} >Send verification code</Button>
                                            <Button onClick={onPressSendOTP} style={{ cursor: 'pointer' }} className='sendButtonUsMobile' >Send verification code</Button>
                                            :
                                            <Button onClick={() => { onPressLogin() }} style={{ cursor: 'pointer' }} className='sendButtonUs' >Login</Button>

                                    }


                                    {/* <Button onClick={onPressLogin} disabled={buttonDisabled1} className='sendButtonUs' >Login</Button> */}
                                    <div className='logintextDiv'>
                                        <text>Register?<span className='loginText' onClick={gobackHandler}>Go back</span></text>
                                    </div>
                                </div> : null}



                            {showchangePassword ?
                                !sendMailOTP ?
                                    <div className='inputdivUS'>
                                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>

                                            {errorPassword ?
                                                <div style={errorMessage}>
                                                    <text style={errorTextColor}>{errorMessagePassword}</text>
                                                </div>
                                                : <div style={{ padding: 10, marginTop: 10, height: 30 }}></div>}
                                            {/* { errorConfirmPassword?
                                                <div style={errorMessage}>
                                                    <text style={errorTextColor}>{errorMessageconfirmPassword}</text>
                                                </div>
                                                : <div style={{ padding:10, marginTop:10, height:30 }}></div>} */}
                                            <text className='hello1'>Reset your password</text>
                                        </div>

                                        <div style={{ flexDirection: 'row', display: 'flex', position: 'relative', marginBottom: 15 }}>
                                            <Input
                                                onChange={onChangeNewPassword}
                                                // style={{ marginTop: 20 }}
                                                // valid={newPassword}
                                                // invalid={} 
                                                placeholder="New Password"
                                                value={newPassword}
                                                maxLength={10}

                                                type={icon2? "password" : 'text'} />

                                            <div style={{ position: 'absolute', right: 10, paddingTop: 5 }}>
                                                {icon2 ?
                                                    <AiOutlineEye color='#c7c7c7' size={18} onClick={() => setIcon2(!icon2)} />
                                                    : <AiOutlineEyeInvisible color='#c7c7c7' size={18} onClick={() => setIcon2(!icon2)} />}
                                            </div>
                                        </div>
                                        <div style={{ flexDirection: 'row', display: 'flex', position: 'relative', marginBottom: 15 }}>

                                            <Input
                                                onChange={onChangeConfirmPassword}
                                                // style={{ marginTop: 20 }}
                                                // valid={confirmPassword}
                                                // invalid={} 
                                                placeholder="Confirm Password"
                                                value={confirmPassword}
                                                maxLength={10}
                                                type={icon3 ? "password" : 'text'} />

                                            <div style={{ position: 'absolute', right: 10, paddingTop: 5 }}>
                                                {icon3 ?
                                                    <AiOutlineEye color='#c7c7c7' size={18} onClick={() => setIcon3(!icon3)} />
                                                    :
                                                    <AiOutlineEyeInvisible color='#c7c7c7' size={18} onClick={() => setIcon3(!icon3)} />}
                                            </div>
                                        </div>




                                        {updatePasswordLoading ?

                                            <div style={{ height: 200, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                                <ClipLoader widthUnit={"%"} size={40} loadings={true} color="#8c52ff" />
                                            </div> :

                                            <Button onClick={() => { onPressSubmit() }} style={{ cursor: 'pointer' }} className='updatePassword' >Update</Button>

                                        }


                                        {/* <Button onClick={onPressLogin} disabled={buttonDisabled1} className='sendButtonUs' >Login</Button> */}

                                    </div>
                                    :
                                    <div className='inputdivUS'>
                                        <text className='hello1' style={{ alignItems: 'center', flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>Confirm Your Email</text>
                                        <Input
                                            onChange={onsendOTPEmail}
                                            style={{ marginTop: 20 }}
                                            valid={mobile}
                                            // invalid={this.state.mobileNumber.invalid ? true : false} 
                                            placeholder="Enter email"
                                            value={email}
                                            maxLength={usMobilelogin ? 10 : 60}
                                            type="text" />
                                        {sendMailOTPLoading ?
                                            <div style={{ height: 200, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                                <ClipLoader widthUnit={"%"} size={40} loadings={true} color="#8c52ff" />
                                            </div>
                                            : <Button onClick={() => { sendMailOTPHandler() }} style={{ cursor: 'pointer' }} className='sendmailOTP' >Send OTP</Button>
                                        }

                                    </div>
                                :
                                null}


                        </div>
                        : null




                    }





                </div>
                <Modal
                    className='otpModla'
                    isOpen={refermodal}
                >
                    <div className='crossdiv' >
                        <img src={Cross} style={{ width: 20, height: 20, cursor: 'pointer' }} onClick={() => { setReferModal(false) }} />
                    </div>

                    <div className='refediv'>
                        <img src={Refer} className='referimg' />
                        <label className='referlabel'>Enter referal Code</label>
                        <Input
                            onChange={referHandler}
                            // valid={this.state.mobileNumber.valid ? true : false}
                            // invalid={this.state.mobileNumber.invalid ? true : false} 
                            placeholder="Referral code"
                            value={referCode}
                            maxLength={7}
                            style={{ textAlign: 'center', color: '#8c52ff' }}
                            type='text' />


                    </div>
                </Modal>
            </Modal>

            <Modal
                className="modalStyle "
                // style={{ position: "absolute", right: 0, top: -32, borderRadius: 0, width: '34%' }}
                isOpen={modalOTP}
                toggle={toggle1}
                modalTransition={{ timeout: 400 }}
            >
                <div>

                </div>
                <div className='crossdiv' >
                    <img src={Cross} style={{ width: 20, height: 20 }} onClick={setModalOTPHandler} />
                </div>
                <text className='hello'>Verification</text>
                <text className='subtext'>Please enter the verification code</text>
                {/* <text className='timeText' >00:30</text> */}
                <Countdown
                    date={initTimestamp + 30000}
                    key={timerkeyValue}
                    renderer={({ hours, minutes, seconds, completed }) => {
                        return (
                            completed ?
                                <div>
                                    <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: 10 }}>
                                        <span><text className='timerText'>{zeroPad(minutes)}:{zeroPad(seconds)}</text></span>
                                    </div>
                                    <div className='resendButton' onClick={() => onResendClickedHandler()}>
                                        <text>I didn’t receive the code!<text onClick={() => onPressSendOTP()} style={{ color: '#8c52ff', cursor: 'pointer' }}>Resend</text></text>
                                    </div>
                                </div>
                                :
                                <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: 10 }}>
                                    <span><text className='timerText'>{zeroPad(minutes)}:{zeroPad(seconds)}</text></span>
                                </div>

                        )

                    }}
                />
                <div className='otpdiv' >

                    <OtpInput
                        value={otp}
                        onChange={otpHandleChange}
                        // otpType='number'
                        inputType='tel'
                        inputStyle={{
                            width: 48,
                            height: 48,
                            borderRadius: 10,
                            marginRight: 20,
                            border: '1px solid #747474'
                        }

                        }
                        // style={{ width: '150%' }}
                        shouldAutoFocus={true}
                        className='otpinput'
                        numInputs={4}

                        renderSeparator={<span></span>}
                        renderInput={(props) => <input  {...props} />}
                    />

                </div>
                {loading ?
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <ClipLoader widthUnit={"%"} size={40} loadings={true} color="#8c52ff" />
                    </div> :
                    <Button onClick={() => onPressVerifyOTP(otp)} className='verifybtn' style={{ backgroundColor: "#8c52ff" }} >Verify</Button>
                }
                <text className='bottemText'></text>

            </Modal>

            <Modal
                className="modalStyle "
                // style={{ position: "absolute", right: 0, top: -32, borderRadius: 0, width: '34%' }}
                isOpen={showmailOTP}
                toggle={toggle1}
                modalTransition={{ timeout: 400 }}
            >
                <div>

                </div>
                <div className='crossdiv' >
                    <img src={Cross} style={{ width: 20, height: 20 }} onClick={setModalOTPHandler2} />
                </div>
                <text className='hello'>Verification</text>
                <text className='subtext'>Please enter the verification code</text>
                {/* <text className='timeText' >00:30</text> */}
                <Countdown
                    date={initTimestamp2 + 30000}
                    key={timerkeyValue2}
                    renderer={({ hours, minutes, seconds, completed }) => {
                        return (
                            completed ?
                                <div>
                                    <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: 10 }}>
                                        <span><text className='timerText'>{zeroPad(minutes)}:{zeroPad(seconds)}</text></span>
                                    </div>
                                    <div className='resendButton' onClick={() => onResendClickedHandler2()}>
                                        <text>I didn’t receive the code!<text onClick={() => sendMailOTPHandler()} style={{ color: '#8c52ff', cursor: 'pointer' }}>Resend</text></text>
                                    </div>
                                </div>
                                :
                                <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: 10 }}>
                                    <span><text className='timerText'>{zeroPad(minutes)}:{zeroPad(seconds)}</text></span>
                                </div>

                        )

                    }}
                />
                <div className='otpdiv' >

                    <OtpInput
                        value={otp}
                        onChange={otpHandleChange}
                        // otpType='number'
                        inputType='tel'
                        inputStyle={{
                            width: 48,
                            height: 48,
                            borderRadius: 10,
                            marginRight: 20,
                            border: '1px solid #747474'
                        }

                        }
                        // style={{ width: '150%' }}
                        shouldAutoFocus={true}
                        className='otpinput'
                        numInputs={4}

                        renderSeparator={<span></span>}
                        renderInput={(props) => <input  {...props} />}
                    />

                </div>
                {verifyMailOTPLoading ?
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <ClipLoader widthUnit={"%"} size={40} loadings={true} color="#8c52ff" />
                    </div> :
                    <Button onClick={() => onPressVerifyMailOTP(otp)} className='verifybtn' style={{ backgroundColor: "#8c52ff" }} >Verify</Button>
                }
                <text className='bottemText'></text>

            </Modal>

            <ToastContainer />
            {/* <ResponseModal isOpen={messageModal} toggle={()=>setMessageModal(false)}modalTransition={{ timeout: 100 }} displayText={messageText}/> */}
        </div>
    );
}

export default RegisstrAndLogin_India;
