import React, { Component, Text, useState, useRef, useEffect, useContext } from 'react'
import { Navbar, NavbarBrand, Nav, NavItem, Row, NavbarToggler, Collapse, Col, Modal, FormGroup, Input, Button, FormFeedback, NavLinkReactor } from 'reactstrap';
import Profile from '../../../../New_Website_V2/Assets/profile.png'
import Mail from '../../../../New_Website_V2/Assets/promail.png'
import Call from '../../../../New_Website_V2/Assets/procall.png'
import { ToastContainer, toast } from 'react-toastify';
import { authLogOut } from '../../../GlobalState/globalStateActions';
import '../../../../New_Website_V2/CSS/UI Components/ProfilePage_mobile.css'
import Edit from '../../../../New_Website_V2/Assets/material-symbols-edit.png'
import Pro from '../../../../New_Website_V2/Assets/manage-accounts-fill-0-wght-400-grad-0-opsz-48-2.png'
import Card from '../../../../New_Website_V2/Assets/asicon-outline-card.png'
import Refer from '../../../../New_Website_V2/Assets/moving-beds-fill-0-wght-400-grad-0-opsz-48-1.png'
import Bell from '../../../../New_Website_V2/Assets/group-1000005386.png'
import { globalState } from '../../../GlobalState/globalState'
import customFetchData from '../../Custom/customFetchData';
import { useNavigate, NavLink } from 'react-router-dom';
import RegisstrAndLogin_India from '../../../Screens/RegisterrAndLogin_India';

import Address from '../../../../New_Website_V2/Assets/group-1000005389.png'
import Logout from '../../../../New_Website_V2/Assets/asicon-outline-logout.png'
import RightArrow from '../../../../New_Website_V2/Assets/asicon-outline-arrow-right.png'
import { BiInfoCircle } from 'react-icons/bi'
import ActiveEdit from '../../../Assets/material-edit.png'
import ActiveCard from '../../../Assets/asicon-card.png'
import ActiveAccount from '../../../Assets/manage-accounts.png'
import ActiveRefer from '../../../Assets/moving-beds-fill-0-wght-400-grad-0-opsz-48-2.png'
import ActiveAddress from '../../../Assets/group-1000005370.png'
import CloseIcon from '../../../Assets/icons-closeModal.png';

const ProfilePage_mobile = () => {
    const [editColor, setEditColor] = useState()
    const [transactionColor, setTransactionColor] = useState()
    const [accountColor, setAccountCoulor] = useState()
    const [emailCheck, setEmailCheck] = useState(false)
    const [notificationColor, setNotificationColor] = useState()
    const [referColor, setReferColor] = useState()
    const [addressColor, setAddressColor] = useState()
    const [logoutColor, setLogoutColor] = useState()
    const [modal, setModal] = useState(false)
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [mobile, setMobile] = useState('')
    let [state, dispatch] = useContext(globalState)
    const [mobileCountryCode, setMobileCountryCode] = useState('')
    const [loading, setLoading] = useState(false)
    const [logoutModalOpen,setLogoutModalOpen]=useState(false)
    const [referalReward, setReferalReward]=useState(false)

    useEffect(() => {
        fetchDataHandler()
        // console.log('token in user maibile profile', state.token)

    }, [state.user])

    const fetchDataHandler = async () => {
        setLoading(true)
        let body = {
            consumer_id: state.user.id
        }
        // console.log('user id', body.consumer_id)
        let userDetails = await customFetchData('POST', body, "/website/consumer/details", state, dispatch)
        // console.log('response', userDetails)
        if (userDetails.fetch) {
            if (userDetails.fetchedData.data) {
                setLoading(false)
                let response = userDetails.fetchedData.results[0]
                setEmail(response.CONSUMER_EMAIL)
                setMobile(response.CONSUMER_PHONE)
                setName(response.CONSUMER_FNAME)
                setMobileCountryCode(response.CONSUMER_PHONE_COUNTRY_CODE)
                if (userDetails.fetchedData.resultEmailQ) {
                    setEmailCheck(userDetails.fetchedData.resultEmailQ[0].EMAIL_VERIFIED_FLAG ? true : false)
                }
            } else {
                if (userDetails.fetchedData.dbError) {
                    //   ToastAndroid.show("Internal Error. Please try again.", ToastAndroid.SHORT);
                    // toast.success("Internal Error. Please try again.", { position: toast.POSITION.BOTTOM_CENTER })
                    toast("Internal Error. Please try again.", {
                        position: 'bottom-center',
                        hideProgressBar: true,
                        progress: undefined,
                        style:{backgroundColor:'#E0D1FF'}
                    });
                }
                else {
                    // ToastAndroid.show("Something went wrong. Please try again. ",ToastAndroid.SHORT);
                }
                setLoading(false)
            }

        }

    }
    const editHandler = () => {
        // console.log('it working')
        setEditColor(true)
        setTransactionColor(false)
        setAccountCoulor(false)
        setNotificationColor(false)
        setReferColor(false)
        setAddressColor(false)
        setLogoutColor(false)
        navigate('/ConsumerEditProfile',{state:{fromMobileProfile: true ,mobileCountryCodeOld:mobileCountryCode,name:name,mobile:mobile,email:email}})

    }
    const transactionHandler = () => {
        setEditColor(false)
        setTransactionColor(true)
        setAccountCoulor(false)
        setNotificationColor(false)
        setReferColor(false)
        setAddressColor(false)
        setLogoutColor(false)
        navigate('/c/myTransactions')
        
    }
    const accountHandler = () => {
        setEditColor(false)
        setTransactionColor(false)
        setAccountCoulor(true)
        setNotificationColor(false)
        setReferColor(false)
        setAddressColor(false)
        setLogoutColor(false)
        navigate('/setting')

    }
    const notifyHandler = () => {
        setEditColor(false)
        setTransactionColor(false)
        setAccountCoulor(false)
        setNotificationColor(true)
        setReferColor(false)
        setAddressColor(false)
        setLogoutColor(false)

    }

    const rewardHandler = () => {
        setEditColor(false)
        setTransactionColor(false)
        setAccountCoulor(false)
        setNotificationColor(false)
        setReferColor(false)
        setAddressColor(false)
        setLogoutColor(false)
        setReferalReward(true)
    }

    const referHandler = () => {
        setEditColor(false)
        setTransactionColor(false)
        setAccountCoulor(false)
        setNotificationColor(false)
        setReferColor(true)
        setAddressColor(false)
        setLogoutColor(false)

        // navigate('/profile/referandearn', {state:{onPressReward:rewardHandler}})
        navigate('/profile/referandearn')

    }
    const addressHandler = () => {
        setEditColor(false)
        setTransactionColor(false)
        setAccountCoulor(false)
        setNotificationColor(false)
        setReferColor(false)
        setAddressColor(true)
        setLogoutColor(false)
        navigate('/c/savedAddresses',{state:{addressProps:state.address}})

    }
    const logoutHandler = () => {
        setEditColor(editColor)
        setTransactionColor(transactionColor)
        setAccountCoulor(accountColor)
        setNotificationColor(notificationColor)
        setReferColor(referColor)
        setAddressColor(addressColor)
        setLogoutColor(true)
        setLogoutModalOpen(true)
        // logoutcalling
        // onLogOutHandler()
    }

    const onLogOutHandler = async () => {
        // console.log("inside logout handler");
        const logOutData = {
            id: state.user.id,
            token: state.token,
            role: state.user.role
        }
        let { fetchedData } = await customFetchData('POST', logOutData, '/website/logout', state, dispatch) // Custom hook to check network and fetch data
        // console.log("fetchedData from logout", fetchedData)
        if (fetchedData.logoutStatus) {
            let logOutStatusFromState = await authLogOut(dispatch)
            if (logOutStatusFromState) {
                setLogoutModalOpen(false)
                // toast.success("Logout Successful", { position: toast.POSITION.BOTTOM_CENTER })
                toast("Logout Successful", {
                    position: 'bottom-center',
                    hideProgressBar: true,
                    progress: undefined,
                    style:{backgroundColor:'#E0D1FF'}
                });
            }
            else {
                // toast.success("Logout Failed. Please try again.", { position: toast.POSITION.BOTTOM_CENTER })
                toast("Logout Failed. Please try again.", {
                    position: 'bottom-center',
                    hideProgressBar: true,
                    progress: undefined,
                    style:{backgroundColor:'#E0D1FF'}
                });
            }
        }
        else {
            if (fetchedData.dbError) {
                toast.success("Internal Error. Please try again.", { position: toast.POSITION.BOTTOM_CENTER })
                toast("Internal Error. Please try again.", {
                    position: 'bottom-center',
                    hideProgressBar: true,
                    progress: undefined,
                    style:{backgroundColor:'#E0D1FF'}
                });
            }
            else {
                toast.success("Logout Failed. Please try again. ", { position: toast.POSITION.BOTTOM_CENTER })
                toast("Logout Failed. Please try again.", {
                    position: 'bottom-center',
                    hideProgressBar: true,
                    progress: undefined,
                    style:{backgroundColor:'#E0D1FF'}
                });
            }
        }
    }
    const logoutModalClose=()=>{
        setLogoutModalOpen(false)
        setLogoutColor(false)
        // setEditColor(true)
    }
    let logoutModalDisplay=(
        <Modal isOpen={logoutModalOpen} toggle={logoutModalClose} size={"md"} centered>
            <div style={{padding:20,backgroundColor:'#fff', borderRadius:10}}>
                <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>
                    <text className='are_you_wantText'>Are you sure you want to logout?</text>
                    <div onClick={logoutModalClose} style={{cursor:'pointer',cboxShadow: '0 2px 4px 0 rgba(103, 52, 204, 0.1)',width:22,height:22,borderRadius:20,backgroundColor: '#fff',display:'flex',justifyContent:'center',flexDirection:'row',alignItems:'center'}}>
                        <img src={CloseIcon} style={{width:16,height:16}}/>
                    </div>
                </div>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:20}}>
                    <div className='yes_Logout_Btn' onClick={onLogOutHandler}>
                        <text className='yes_Text_Logout'>Yes, Logout</text>
                    </div>
                    <div className='cancel_Logout_Btn' onClick={logoutModalClose} style={{marginLeft:20}}>
                        <text className='cancel_Text_Logout'>Cancel</text>
                    </div>
                </div>
            </div>
        </Modal>
    )
    const navigate = useNavigate();
    return (
        <div className='MainReusable_Container_Home' style={{ paddingTop: 80 }} >
            {state.token ?
                <div style={{ marginTop: 40,marginBottom:45, flexDirection: 'row', display: 'flex',justifyContent:'flex-start' }} >
                    <div style={{marginRight:10}}>
                        <img src={Profile} />
                    </div>

                    <div>
                        {name ?
                            <div style={{marginBottom:0}}>
                                <text className='profile_name_mobile'>{name}</text>
                            </div>
                            : <div>
                                <text className='profile_name_mobile'>Not Updated</text>
                            </div>}
                        {email ?
                            <div style={{ }}>
                                <img src={Mail} style={{ paddingRight: 6 }} />
                                <text className='email_mobile'>{email}</text>
                            </div>
                            :
                            <div style={{ }}>
                                <img src={Mail} style={{ paddingRight: 6 }} />
                                <text className='email_mobile'>Not Updated</text>
                            </div>
                        }
                        {mobile ?
                            <div style={{ marginTop:-5}}>
                                <img src={Call} style={{ paddingRight: 6 }} />
                                <text className='email_mobile'>{mobile}</text>
                            </div> :
                            <div style={{ }}>
                                <img src={Call} style={{ paddingRight: 6 }} />
                                <text className='email_mobile'>Not Updated</text>
                            </div>}

                    </div>
                </div>
                : 
                <div style={{ marginTop: 40,marginBottom:45, flexDirection: 'row', display: 'flex' }}>
                    <div>
                        <img src={Profile} />
                    </div>
                    <div style={{ paddingTop: 10 }}>
                        <div>
                            <text style={{ fontSize: 16, fontWeight: 'bold' }} >Welcome to Slash</text>
                        </div>
                        <div>
                            <BiInfoCircle color="red" size={20} />
                            <text className='profileName_mobile' onClick={()=>setModal(!modal)}>Please register to create your account</text>
                        </div>

                    </div>

                </div>
                }

            {/* <hr style={{margin: 5 }} /> */}




            {state.token?
                <div>
                    <div className='optionDiv_mobile' onClick={editHandler} style={{ backgroundColor: editColor ? '#DFC5FE' : null ,marginLeft:10}} >
                        {editColor?<img src={ActiveEdit} width={18} height={18} />:<img src={Edit} width={18} height={18} />} 
                        <text className='optionText_mobile'>Edit Profile</text>
                        <img src={RightArrow} width={18} height={18} style={{ position: 'absolute', right: 30 }} />
                    </div>
                    <hr style={{ margin: 5 }} />
                </div>
            :null}
          {state.token?    
           <div>
            <div className='backgroudDiv_mobile' onClick={transactionHandler} style={{ backgroundColor: transactionColor ? '#DFC5FE' : null }}>
               {transactionColor?<img src={ActiveCard} width={18} height={18} />:<img src={Card} width={18} height={18} />} 
                <text className='optionText_mobile'>My Transactions</text>
                <img src={RightArrow} width={18} height={18} style={{ position: 'absolute', right: 30 }} />
            </div>
            <hr style={{ margin: 5 }} />
           </div>
           :null}

       
            <div>
                <div className='backgroudDiv_mobile' onClick={accountHandler} style={{ backgroundColor: accountColor ? '#DFC5FE' : null }}>
                  {accountColor?<img src={ActiveAccount} width={18} height={18} />:<img src={Pro} width={18} height={18} />}  
                    <text className='optionText_mobile'>Settings</text>
                    <img src={RightArrow} width={18} height={18} style={{ position: 'absolute', right: 30 }} />
                </div>
                <hr style={{ margin: 5 }} />
            </div>
         
        
            <div className='backgroudDiv_mobile' onClick={referHandler} style={{ backgroundColor: referColor ? '#DFC5FE' : null }}>
              {referColor?<img src={ActiveRefer} width={18} height={18} />:<img src={Refer} width={18} height={18} />}  
                <text className='optionText_mobile'>Refer & Earn</text>
                <img src={RightArrow} width={18} height={18} style={{ position: 'absolute', right: 30 }} />
            </div>
            <hr style={{ margin: 5 }} />
            {state.token ?
                <div>
                    <div className='backgroudDiv_mobile' onClick={addressHandler} style={{ backgroundColor: addressColor ? '#DFC5FE' : null }}>
                     {addressColor?<img src={ActiveAddress} width={18} height={18} />:<img src={Address} width={18} height={18} />}   
                        <text className='optionText_mobile'>Saved Address</text>
                        <img src={RightArrow} width={18} height={18} style={{ position: 'absolute', right: 30 }} />
                    </div>
                    <hr style={{ margin: 5 }} />
                </div> :
                null}
            {state.token ?
                <div>
                    <div className='logoutDiv_mobile' onClick={logoutHandler}>
                        <img src={Logout} width={18} height={18}  style={{marginLeft:5}}/>
                        <text className='optionText_mobile'>Logout</text>
                        {/* <img src={RightArrow} width={18} height={18} style={{ position: 'absolute', right: 30 }} /> */}
                    </div>
                    <hr style={{ margin: 5 }} />
                </div>
                : null}
            { modal && <RegisstrAndLogin_India closeModal={()=>setModal(!modal)}  />}
            {logoutModalOpen && logoutModalDisplay}
            {/* <ToastContainer/> */}

        </div>




    );
}

export default ProfilePage_mobile;
