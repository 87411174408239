import React, { useContext, useEffect, useState } from "react";
import DiscountCard from "../../Components/Custom/UI/DiscountCard";
import '../../CSS/CheckoutStack/CouponAppliedPage.css';
import ProfessionalDiscountsCards from "../../Components/Custom/UI/ProfessionalDiscountCards";
import CouponAppliedIcon from '../../Assets/couponApplySuccessIcon.png';
import { globalState } from "../../GlobalState/globalState";
import { useLocation, useNavigate } from "react-router-dom";
import numeral from "numeral";
import moment from "moment";
import momenttz from 'moment-timezone'
import { Modal } from "reactstrap";
import axios from "axios";
import customFetchData from "../../Components/Custom/customFetchData";
const DeepClone = require("rfdc/default")


export default function CouponDiscountPage(props){


    // const props=useLocation()

    let [state, dispatch] = useContext(globalState)
    let [coupon, setCoupon] = useState('')
    let [message, setMessage] = useState('')
    let [couponMessage, setCouponMessage] = useState('')
    let [status, setStatus] = useState('')
    let [specialCharMsg,SetspecialCharMsg]=useState('')
    const [cardData1, setCardData1] = useState([])
    const [cardData, setCardData] = useState([])
    const [proData, setProData] = useState([])
    const [loading, setLoading] = useState(false)
    const [services, setServices] = useState(props.services)
    const [systemMessage, setSystemMessage]=useState('')
    
    let [networkRefresh, setNetworkRefresh] = useState(false)
    let [navFrom, setnavFrom] = useState('')
    const [colourBack, setColourBack] = useState(['#ff5d5d','#6750a3', '#ffffff'])

    let [couponType,setCouponType]=useState('')
    let [couponID,setCouponID]=useState(null)
    let [couponCode,setCouponCode]=useState('')
    let [prevCouponCode,setPrevCouponCode]=useState('')
    let [applyStyle,setApplyStyle]=useState('')
    let [couponMaxCount,setCouponMaxCount]=useState(null)
    let [couponUsedCount,setCouponUsedCount]=useState(null)
    let [couponApplyCount,setCouponApplyCount]=useState(null)
    let [couponMaxreached,setCouponMaxreached]=useState(null)
    let [couponCancelButton,setCouponCancelButton]=useState(false)
    let [couponValid,setCouponValid]=useState(false)
    let [totalAmount,setTotalAmount]=useState(null)
    let [offerPrice,setOfferPrice]=useState(null)
    let [couponDiscount,setCouponDiscount]=useState(null)
    let [couponApplied,setCouponApplied]=useState(false)
    let [applyButton,setApplyButton]=useState(false)
    let [unitDiscountPrice,setUnitDiscountPrice]=useState(null)
    let [unitDiscount,setUnitDiscount]=useState(null)
    let [totalUnitPrice,setTotalUnitPrice]=useState(null)
    let [deviceScenario,setDeviceScenario]=useState(null)
    let [deviceMessage,setDeviceMessage]=useState(null)
    let [couponAppliedModal,setCouponAppliedModal]=useState(false)

    // const navigate=useNavigate()
    
    // useEffect(()=>{
    //     console.log("props in pr discounts",props)
    //     let properties = new MoEProperties();
    //       properties.addAttribute("type", 'view');
    //       properties.addAttribute("screenName", "Apply Coupon Page");
    //       properties.addAttribute("version", "sv2");
    //       ReactMoE.trackEvent("Screen_Track", properties);
    //   },[])
    
    useEffect(()=>{

        setnavFrom(props)
        setServices(props.services)
        // console.log("props.services", services)
        // console.log("props", props)
        // console.log("props", services)

        onFetchedProCoupon()
        


        setCouponCode(props.couponCode)
        setPrevCouponCode(props.couponCode)
        setCouponApplied(props.couponApplied)

    },[])

    const onFetchedProCoupon = async() => {
        setLoading(true)
        let method = "POST", body ={proPersonelIds : props.proPersonelIds, proMerchantIds:props.proMerchantIds, serviceIds: props.serviceIds, services: props.services, country: props.country}, url = "/website/fetchProfessionalCoupon/sv2"
        let { fetch, fetchedData } = await customFetchData(method, body, url, state, dispatch) // Custom hook to check network and fetch data

            if(fetchedData.data){  
                let filteredArrayPro=fetchedData.filteredArrayPro;
                let filteredArrayAdmin=fetchedData.filteredArrayAdmin;
                setCardData(filteredArrayPro);
                setProData(filteredArrayAdmin);
                setLoading(false)
    
            } else{
            //    console.log(fetch)
            //    console.log(fetchedData)
            //    console.log('not called')
               setLoading(false)
            }
    }

    const onPressCouponCard = (code) => {
        // console.log("Code", code)
        // onChangeApplyCoupon(code)
        setCoupon(code)
        onPressApplyCoupon(code)
        
        
    }

    const onPressApplyCoupon=async(coupon)=>{
        // const props =props;
        // let UniqueId=DeviceInfo.getUniqueId();
        // console.log("onPressApplyCoupon", coupon, props.services)
        let servicesClone=DeepClone(props.services)
        let offer_ids=[],cat_prid=[],subcat_prid=[],service_dates=[],merchant_id=null,merchant_per_id=null,timezone='',servicePrice=0,cpPrice=0, tsPrice=0, gstPrice=0;
        servicesClone.map((item) => {
            return (
                offer_ids.push(item.QF_MERCHANT_SERVICE_PRID),
                cat_prid.push(item.QF_SERVICE_CATEGORY_PRID),
                subcat_prid.push(item.QF_SERVICE_SUB_CATEGORY_PRID),
                service_dates.push(moment.tz(item.dateSlot.date, item.SERVICE_TIMEZONE).format("YYYY-MM-DD")),
                merchant_id= item.QF_MERCHANT_PRID,
                merchant_per_id= item.QF_MERCHANT_PERSONNEL_PRID,
                timezone=item.SERVICE_TIMEZONE,
                servicePrice= numeral(servicePrice).add(item.SERVICE_OFFER_PRICE).value().toFixed(2)
            )
        })

        let check = momenttz().tz(services[0].SERVICE_TIMEZONE).format("YYYY-MM-DD")

        // serviceRefreshAndGstCalc(true,country)
        // console.log("Coupon code", coupon)
    
        if(coupon.trim()==''){
            // this.setState({status:"error",message:'Please enter Coupon code'})
            setStatus('error');
            setSystemMessage('Please enter Coupon code')
        }
        // else if(isEmulator && config.localhost_url==="https://load.goslash.com"){
        //     // this.setState({status:"error",message:'Coupon doesn\'t work on emulators'})
        //     setStatus('error');
        //     SystemMessage('Coupon doesn\'t work on emulators')

        // }
        else{

            setLoading(true)

            // console.log("Apply Coupon in else")
            let data={
                // uid: state.user.id,1730
                uid: 1730,
                city: state.user.city,
                couponName: coupon,
                currentDate:check,
                serviceDate:service_dates,
                merchantPrid:merchant_id,
                couponCount:services.length,
                cat_prid:cat_prid,
                subcat_prid:subcat_prid,
                offer_price: servicePrice,
                consumer_id:state.user.id,
                offerCountry:props.country,
                merchantPerPrid:merchant_per_id,
                timezone:timezone,
                // isEmulator:DeviceInfo.isEmulator(),
                service_prid:offer_ids,
                offerCount: services.length,
                // UniqueId:UniqueId,
                services:servicesClone
            }

            // console.log("Apply coupon data",data)
            let {fetch, fetchedData} =  await customFetchData('POST', data, '/website/applyCoupon/sv2', state, dispatch)
            
                if(fetchedData.result.couponValid){
                    setLoading(false)
                    // console.log("Apply coupon price", fetchedData)
    
    
                        // let properties4 = new MoEProperties();
                        // properties4.addAttribute("type", 'sub_success');
                        // properties4.addAttribute("action", 'CouponApply');
                        // properties4.addAttribute("screenName", "NewCheckout");
                        // properties4.addAttribute("page", 4);
                        // properties4.addAttribute("message", "Coupon apply success");
                        // ReactMoE.trackEvent("Screen_Track", properties4);
    
                        // let CouponPrice=0
                    let CouponPrice= props.totalCPPrice ? 
                            props.totalTSPrice ? 
                                numeral(numeral(fetchedData.result.totalAmount).add(props.totalCPPrice).value()).add(props.totalTSPrice).value().toFixed(2) 
                                :
                                numeral(numeral(fetchedData.result.totalAmount).add(props.totalCPPrice).value()).value().toFixed(2) 
                        : 
                        props.totalTSPrice ? 
                            numeral(fetchedData.result.totalAmount).add(props.totalTSPrice).value().toFixed(2) 
                        :
                            numeral(fetchedData.result.totalAmount).value().toFixed(2);
                    
    
                    setCouponType(fetchedData.result.couponType)
                    setCouponID(fetchedData.result.couponID)
                    setCouponCode(fetchedData.result.couponCode)
                    setApplyStyle('deactiveApplyButtonStyle')
                    setCouponMaxCount(fetchedData.result.couponMaxCount)
                    setCouponUsedCount(fetchedData.result.couponUsedCount)
                    setCouponApplyCount(couponApplyCount)
                    setCouponMaxreached(couponMaxreached)
                    setCouponCancelButton(true)
                    setCouponValid(true)
                    setTotalAmount(CouponPrice)
                    setOfferPrice(CouponPrice)
                    setCouponDiscount(Number(fetchedData.result.couponDiscount))
                    setCouponMessage(fetchedData.result.message)
                    setCouponApplied(fetchedData.result.couponApplied)
                    setApplyButton(fetchedData.result.applybutton)
                    setUnitDiscountPrice(fetchedData.result.unitDiscountPrice)
                    setUnitDiscount(fetchedData.result.unitDiscount)
                    setTotalUnitPrice(numeral(servicePrice).multiply(services.length).value())
                    setDeviceScenario(true)
                    setCouponAppliedModal(true)
    
                    setTimeout(() => {
                        setCouponAppliedModal(false)
                        return props.onClick(fetchedData.result.couponCode, fetchedData.result, prevCouponCode)
                    }, 3000)
    
    
    
                    // navigationHandler();
                    // setTimeout(() => {
                        // navigate('CheckoutPage', {couponApplied:fetchedData.result.couponApplied, couponCode: fetchedData.result.couponCode, prevCouponCode,userPasswordReg:props.userPasswordReg,userId:props.userId?props.userId:null})
                    // }, 1000)
    
                    // {services[0].SERVICE_TIMEZONE !== "America/Los_Angeles" ? this.fetchDetails(true) :null}
                }
                else{
                    // console.log("Here inside else", fetchedData.result.deviceScenario)
                    setCouponMessage('Invalid Coupon')
                    setLoading(false)
    
                    if(fetchedData.result.deviceScenario === false){
                        
                        setDeviceScenario(fetchedData.result.deviceScenario)
                        // setCouponMessage('Invalid Coupon')
                        setDeviceMessage(fetchedData.result.deviceMessage)
                        setCouponAppliedModal(false)
                            
                    }else{
    
                        // console.log("Here inside else else")
    
                        // setTotalAmount(numeral(offerPrice).value())
                        // setOfferPrice(numeral(offerPrice).value())
                        setCouponDiscount(0)
                        setApplyButton(true)
                        setCouponCode(null)
                        setPrevCouponCode(null)
                        setCouponID(null)
                        setCouponType(null)
                        setApplyStyle('deactiveApplyButtonStyle')
                        setUnitDiscountPrice(servicePrice)
                        setCouponValid(false)
                        setCouponCancelButton(true)
                        setUnitDiscount(unitDiscount)
                        setTotalUnitPrice(numeral(servicePrice).value())
                        setCouponAppliedModal(false)
                        setDeviceScenario(false)
    
                    }
                   
                    // let properties5 = new MoEProperties();
                    // properties5.addAttribute("type", 'sub_success');
                    // properties5.addAttribute("action", 'CouponApply');
                    // properties5.addAttribute("screenName", "NewCheckout");
                    // properties5.addAttribute("page", 4);
                    // properties5.addAttribute("message", "Coupon apply fail");
                    // ReactMoE.trackEvent("Screen_Track", properties5);
                }
            // {services[0].SERVICE_TIMEZONE !== "America/Los_Angeles" ? this.fetchDetails(true) :null}
        }
    }

    const onChangeApplyCoupon = (event) => {
        let changedText=event.target.value;

        // console.log("onChangeApplyCoupon", changedText)

        if (/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/.test(changedText.trim())) {

            // this.setState({status:'error',message:'Please enter a valid Coupon code'})
            setMessage('Please enter a valid Coupon code')
            setStatus(true)
            SetspecialCharMsg(false)
            setCoupon(changedText)

        } else if (/^[A-Za-z0-9&%$#@'+-]*$/.test(changedText.trim())) {

            setCoupon(changedText)
            // setMessage('dddd')
            SetspecialCharMsg(false)

        } else if (!/^[A-Za-z0-9&%$#@'+-]*$/.test(changedText.trim())) {

            setCoupon('')
            // setMessage('ffff')
            SetspecialCharMsg(true)
            setCoupon(changedText)

        }


    }

    let renderProCoupons;
    if(cardData.length!=0){
        renderProCoupons = cardData.map((item, index) => {
            // console.log('data', item)
            // console.log('data', data1, index)
            const colorIndex = index%3;
            const colourback = colourBack[colorIndex]
            return (

                <ProfessionalDiscountsCards 
                        colour={colourback}
                        onClick={onPressCouponCard}
                        code={item.MERCHANT_COUPON_CODE}
                        title={item.MERCHANT_NAME}
                        discount={item.MERCHANT_COUPON_PERCENT_OFF ? item.MERCHANT_COUPON_PERCENT_OFF : item.MERCHANT_COUPON_VALUE_OFF}
                        date={item.MERCHANT_COUPON_BOOKING_DATE_TO ? item.MERCHANT_COUPON_BOOKING_DATE_TO : 'No validity date'}
                        coupon_percent={item.MERCHANT_COUPON_PERCENT_OFF ? true : false}
                        country={props.country}
                    />
                
            )
        })
    }

    let renderAdminCoupons;
    if(proData.length!=0){
        renderAdminCoupons = proData.map((item, index) => {
            // console.log('data', item)
            // console.log('data', data1, index)
            const colorIndex = index%3;
            const colourback = colourBack[colorIndex]
            return (
                 
                    <DiscountCard
                        // name={data1.name}
                        colour={colourback}
                        onClick={onPressCouponCard}
                        off={item.OUR_COUPON_PERCENT_OFF ? item.OUR_COUPON_PERCENT_OFF : item.OUR_COUPON_VALUE_OFF}
                        code={item.OUR_COUPON_CODE}
                        date={item.OUR_COUPON_BOOKING_DATE_TO}
                        coupon_percent={item.OUR_COUPON_PERCENT_OFF ? true : false}
                        country={props.country}
                    />
                
            )
        })
    }

    return (
        <div className="coupon_discount_main_div">
            <div className="coupon_discount_text_div">
                <div className="coupon_discount_input_text_div">
                    <input type="text"
                        placeholder='Enter Discount Code here'
                        placeholderTextColor='#747474'
                        maxLength={20}
                        autoCapitalize='characters'
                        onChange={(e) => onChangeApplyCoupon(e)}
                        value={coupon}
                        useNativeDriver={true}
                        activeLineWidth={0.5}
                        autoCorrect={false}
                        className="input_type_text"
                    />
                </div>
                <div className="coupon_discount_input_button_div" disabled={coupon == '' ? true : false} onClick={() => onPressCouponCard(coupon)}>
                    <text className="applyText">Apply</text>
                </div>
            </div>
            {couponMessage !== '' ? 
                <div style={{paddingTop:0, paddingBottom:7, paddingLeft:10}}>
                    <text className="FlatSavedText" style={{color: deviceScenario ? '#5ba85a' : '#9b0909'}}>{couponMessage}</text>
                </div> 
            : null}

            {cardData.length != 0 ?
                <div className="">
                    <div className="coupon_discount_header_div">
                        <text className="coupon_discount_header_text">Available Professional discounts</text>
                    </div>
                    {/* <ProfessionalDiscountsCards onClick={onPressCouponCard}/> */}
                    {renderProCoupons}
                </div> : null}

            <div className="">
                <div className="coupon_discount_header_div">
                    <text className="coupon_discount_header_text">Available Slash Discounts</text>
                </div>
                {/* <DiscountCard /> */}
                {renderAdminCoupons}
            </div>

            {cardData.length == 0 && proData.length == 0 && !loading?
                    <div style={{paddingVertical:16, flexDirection:'row', justifyContent:'center'}}>
                        <text className="labelStyle">No Coupon Available</text>
                    </div>
                : null
                }

                <Modal isOpen={couponAppliedModal}>
                    <div style={{display:'flex',alignItems:'center', flexDirection:'column', backgroundColor:'#ffffff', padding:20}}>
                        <div>
                            <img src={CouponAppliedIcon}/>
                        </div>
                        <div style={{padding:10}}>
                            <text className="modalCouponAppliedText">Coupon Applied Successfully</text>
                        </div>
                        <div>
                            <text className="modalCouponSavedText">You Saved flat <text style={{color:'#8c52ff'}}>{services[0].SERVICE_CURRENCY == "Rupees" ? '\u20B9' : '$'}{couponDiscount}</text></text>
                        </div>
                        <div style={{padding:5}}>
                            <text className="modalWOText">Wohooo !</text>
                        </div>
                    </div>
                </Modal>
        </div>
    )
}