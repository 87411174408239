import React from "react";
import { Spinner } from "reactstrap";
import { ClipLoader } from 'react-spinners';
import '../../../CSS/UI Components/LoadingSpinner.css';

export default function LoadingSpinner(props){
    return (

        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',paddingTop:50}}>
            <ClipLoader widthUnit={"%"} size={20} loadings={props.loading} color="#8c52ff" />
        </div>
        // <div className="loading_spinner_buttonStyle">
        //     <Spinner size="sm" color="secondary">
        //         Loading...
        //     </Spinner>
            /* <span className="loading_spinner_textStyle">
                {' '}Loading
            </span> */
        // </div>
    )
}