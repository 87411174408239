import React, { useContext, useEffect, useState } from "react";
import '../../CSS/UI Components/BookingsDetails.css';
import Cross_Icon from '../../Assets/cross_icon.png';
import Help_Icon from '../../Assets/help-fill-icon.png';
import Calendar_Icon from '../../Assets/calendar-month_Image_grey.png';
import Timer_Icon from '../../Assets/timer_Image.png';
import Locate_Icon from '../../Assets/material-symbols-map.png';
import Locate_marker_Icon from '../../Assets/location-marker-Icon.png';
import InfoIcon from '../../Assets/checkout_info_icon.png';
import QRCode from 'react-qr-code';
import GoogleMapReact from 'google-map-react';
import Close_Cross from "../../Components/Custom/UI/Close_Cross";
import { globalState } from "../../GlobalState/globalState";
import { useLocation, useNavigate } from "react-router-dom";
import momenttz from 'moment-timezone';
import customFetchData from "../../Components/Custom/customFetchData";
import numeral from "numeral";
import CouponButton from "../../Components/Custom/UI/CouponButton";
import RefundStatusCard from "../../Components/Custom/UI/RefundStatusCard";
import { IoLocationSharp } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";

export default function CancelledBookingDetailsPage(props){

    const [state,dispatch]=useContext(globalState)
    const [serviceDetailsData, setServiceDetailsData] = useState(props.data?props.data:{})
    const [loading, setloading] = useState(false)
    const [country, setcountry] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [cancelSuccessModal, setcancelSuccessModal] = useState(false)
    const [bookingId, setBookingId] = useState([])
    const [currency,setCurrency]=useState('')
    const [cancelLoading,setCancelLoading]=useState(false)
    const [serverError,setServerError]=useState(false)

    const onClickCross = () => {
        props.onClick()
    }

    const navigate = useNavigate()

    useEffect(() => {
        // const params = useLocation();

        setServiceDetailsData(props.data);
        // console.log("route in details", serviceDetailsData)
    },[])


    // const handleGetDirections=()=>{
    //     Geolocation.getCurrentPosition(
    //         (position) => {
    
    //             const data = {
    //                 source: {
    //                     latitude: position.coords.latitude,
    //                     longitude: position.coords.longitude
    //                 },
    //                 destination: {
    //                     latitude: serviceDetailsData.MERCHANT_LOCATION_LATITUDE,
    //                     longitude: serviceDetailsData.MERCHANT_LOCATION_LONGITUDE
    //                 },
    //                 params: [
    //                     {
    //                     key: "travelmode",
    //                     value: "driving"        // may be "walking", "bicycling" or "transit" as well
    //                     }
    //                 ]
    //                 }
                
    //                 getDirections(data)
    //         },
    //         (error) => {
    //             // See error code charts below.
    //             console.log(error.code, error.message);
    //         },
    //         { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
    //     );    
    // }
    
    const onCancelConfirm=async()=>{
        
        let data={
            consumer_id:state.user.id,
            acceptance_prid:serviceDetailsData.QF_CONSUMER_OFFER_ACCEPTED_PRID,
            uemail:state.user.email
        }
        setCancelLoading(true)
        setShowModal(false)
            let refundPath=''
            refundPath=serviceDetailsData.REC_SERVICE_CURRENCY==="Dollars"?'/website/refunds/stripe/v3':(currency==="Rupees"?'/website/refunds/razorpay/v1':'')
            // console.log("currency",currency," ",refundPath)
            try{
                if(refundPath)
                {
                    let CancelConfirmation=await customFetchData('POST',data,refundPath,state,dispatch)
                    // console.log('res',CancelConfirmation)
                    if(CancelConfirmation.fetch){
                        setCancelLoading(false)                  
                            if(CancelConfirmation.fetchedData.sRefund)
                            {
                            setCancelLoading(false)                  
                            toast("Cancellation Successful",{
                                position: "bottom-center",
                                hideProgressBar: true,
                                progress: undefined,
                                style:{backgroundColor:'#E0D1FF'}
                            });
                            navigate('CancelledStackIndex')
                            // this.props.navigation.replace('CancelledOrders',{fromPGToNav:false,showCancel:true});
                            // const resetAction = StackActions.reset({
                            //     index: 0,
                            //     actions: [NavigationActions.navigate({ routeName: 'UpcomingBookingPage' })],
                            // });
                            // // this.props.screenProps.navigation.state.params.fromPGToNav=false;
                            // navigation.dispatch(resetAction);
                            // navigation.navigate('Cancelled',{showCancel:true});
        
                            // let properties1 = new MoEProperties();
                            // properties1.addAttribute("type", 'sub_success');
                            // properties1.addAttribute("action", 'Cancel Booking');
                            // properties1.addAttribute("screenName", "Upcoming Bookings Details");
                            // properties1.addAttribute("page", 11);
                            // properties1.addAttribute("message", "Cancellation successful");
                            // ReactMoE.trackEvent("Screen_Track", properties1);
                            // );
                            }
                            else
                            {
                            if(CancelConfirmation.fetchedData.refundDone)
                            {
                                toast("Cancellation and Refund already initiated. Please contact support if refund is not processed in 5 to 7 business days.",{
                                    position: "bottom-center",
                                    hideProgressBar: true,
                                    progress: undefined,
                                    style:{backgroundColor:'#E0D1FF'}
                                });
                            }
                            else if(CancelConfirmation.fetchedData.consume)
                            {
                                toast("Cancellation and Refund already initiated. Please contact support if refund is not processed in 5 to 7 business days.",{
                                    position: "bottom-center",
                                    hideProgressBar: true,
                                    progress: undefined,
                                    style:{backgroundColor:'#E0D1FF'}
                                });
                            }
                            else if(CancelConfirmation.fetchedData.canAmountMore)
                            {
                                toast("The Cancellation Fee is higher than the Net Price you paid for this booking. So this booking cannot be cancelled",{
                                    position: "bottom-center",
                                    hideProgressBar: true,
                                    progress: undefined,
                                    style:{backgroundColor:'#E0D1FF'}
                                });
                            }
                            else if(CancelConfirmation.fetchedData.refund)
                            {
                                toast("Cancellation Successful",{
                                    position: "bottom-center",
                                    hideProgressBar: true,
                                    progress: undefined,
                                    style:{backgroundColor:'#E0D1FF'}
                                });
                                navigate('CancelledStackIndex') //need to be done
                                // const resetAction = StackActions.reset({
                                //     index: 0,
                                //     actions: [NavigationActions.navigate({ routeName: 'UpcomingBookingPage' })],
                                // });
                                // this.props.screenProps.navigation.state.params.fromPGToNav=false;
                                // navigation.dispatch(resetAction);
                                // navigation.navigate('Cancelled',{showCancel:true});
                            }
                            else if(CancelConfirmation.fetchedData.dbError)
                            {
                            toast("Something went wrong. Please try again",{
                                position: "bottom-center",
                                hideProgressBar: true,
                                progress: undefined,
                                style:{backgroundColor:'#E0D1FF'}
                            });
                            // this.props.navigation.replace('CancelledOrders',{fromPGToNav:false,showCancel:true});
                            }
                            else
                            {
                            toast("Internal error. Please try again.",{
                                position: "bottom-center",
                                hideProgressBar: true,
                                progress: undefined,
                                style:{backgroundColor:'#E0D1FF'}
                            });
                            }
                            // let properties2 = new MoEProperties();
                            // properties2.addAttribute("type", 'sub_fail');
                            // properties2.addAttribute("action", 'Cancel Booking');
                            // properties2.addAttribute("screenName", "Upcoming Bookings Details");
                            // properties2.addAttribute("page", 11);
                            // properties2.addAttribute("message", "Cancellation failure");
                            // ReactMoE.trackEvent("Screen_Track", properties2);
                            }
                            setCancelLoading(false)
                    } 
                    else
                    {        
                        setCancelLoading(false)
                        toast("Something went wrong. Please try again later",{
                            position: "bottom-center",
                            hideProgressBar: true,
                            progress: undefined,
                            style:{backgroundColor:'#E0D1FF'}
                        });
            
                    }
                }
            }catch(err){
                console.log('catch block',err);
                setServerError(true);
                setCancelLoading(false)
                toast("Unable to reach server. Please try again later",{
                    position: "bottom-center",
                    hideProgressBar: true,
                    progress: undefined,
                    style:{backgroundColor:'#E0D1FF'}
                })
            }
        
        
    }

    const onPressCancelBooking = () =>{
        setShowModal(true)
        setBookingId(serviceDetailsData.QF_CONSUMER_OFFER_ACCEPTED_PRID)
        setCurrency(serviceDetailsData.REC_SERVICE_CURRENCY)
    }

    const chatClick=()=>{
        // const {params}=props;
        // console.log("Params", params)
        navigate('IndividualChatV2',{offerId:props.data.QF_MERCHANT_SERVICE_PRID,mpid:props.data.QF_MERCHANT_PERSONNEL_PRID,CHAT_ROOM_ID:props.data.QF_CONSUMER_OFFER_ACCEPTED_PRID.toString(),bookingId:props.data.QF_CONSUMER_OFFER_ACCEPTED_PRID,uid:state.user.id,token:state.token})
    }


    const defaultProps = {
        center: {
            lat:serviceDetailsData.MERCHANT_LOCATION_LATITUDE,
            lng:serviceDetailsData.MERCHANT_LOCATION_LONGITUDE
        },
        zoom: 11
    };
    const defaultMapOptions = {
        fullscreenControl: false,
        zoomControl:false
    };

    return (
        <div className="booking_details_main_div">

            <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', width:'100%'}}>
                {/* <div className="cross_icon_div" onClick={() => onClickCross()}>
                    <img src={Cross_Icon} />
                </div> */}
                <Close_Cross onClick={() => onClickCross()}/>
            </div>

            <div className="booking_header_text_div">
                <div>
                    <text className="booking_serviceName_text_style">{serviceDetailsData.QF_MERCHANT_OFFER_PRID ? serviceDetailsData.MERCHANT_OFFER_HEADER_NAME : serviceDetailsData.REC_SERVICE_NAME}</text><br />
                    <text className="booking_proName_text_style">{serviceDetailsData.MERCHANT_NAME}</text>
                </div>
                <div onClick={() => navigate("/support")} style={{cursor:'pointer'}}>
                    <img src={Help_Icon}/>
                    <text className="booking_help_text_style">Help</text>
                </div>
            </div>

            <div className="booking_image_div">
                <img src={serviceDetailsData.image_link} className="booking_image_style"/>
            </div>

            <div className="booking_pin_dateTime_text_div">
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
                    <div>
                        <text className="booking_id_pin_head_text">Booking ID:</text>
                        <text className="booking_id_pin_desc_text">{serviceDetailsData.QF_CONSUMER_OFFER_ACCEPTED_PRID}</text>
                    </div>

                    <div>
                        <text className="booking_id_pin_head_text">PIN:</text>
                        <text className="booking_id_pin_desc_text">{serviceDetailsData.OFFER_ACCEPTANCE_PIN}</text>
                    </div>
                </div>

                <hr style={{width:'100%'}}/>

                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <img src={Calendar_Icon} />
                        <text className="booking_date_time_desc_text" style={{color: '#747474'}}>{serviceDetailsData.QF_MERCHANT_OFFER_PRID?momenttz(serviceDetailsData.MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(serviceDetailsData.MERCHANT_OFFER_TIMEZONE).format("DD MMM YYYY"):serviceDetailsData.REC_SERVICE_TIMEZONE?momenttz(serviceDetailsData.BOOKING_FROM_DATE_TIME+"Z").tz(serviceDetailsData.REC_SERVICE_TIMEZONE).format("DD MMM YYYY"):''}</text>
                    </div>

                    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <img src={Timer_Icon} />
                        {serviceDetailsData.QF_MERCHANT_OFFER_PRID ?
                            <text className="booking_date_time_desc_text">{serviceDetailsData.MERCHANT_OFFER_TIMEZONE ? momenttz(serviceDetailsData.MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(serviceDetailsData.MERCHANT_OFFER_TIMEZONE).format("hh:mm A") : ''} - {serviceDetailsData.MERCHANT_OFFER_TIMEZONE ? momenttz(serviceDetailsData.MERCHANT_OFFER_TO_DATE_TIME+"Z").tz(serviceDetailsData.MERCHANT_OFFER_TIMEZONE).format("hh:mm A z") : ''}</text>
                            :
                            <text className="booking_date_time_desc_text">{serviceDetailsData.REC_SERVICE_TIMEZONE ? momenttz(serviceDetailsData.BOOKING_FROM_DATE_TIME+"Z").tz(serviceDetailsData.REC_SERVICE_TIMEZONE).format("hh:mm A") : ''} - {serviceDetailsData.REC_SERVICE_TIMEZONE ? momenttz(serviceDetailsData.BOOKING_TO_DATE_TIME+"Z").tz(serviceDetailsData.REC_SERVICE_TIMEZONE).format("hh:mm A z") : ''}</text>
                        }
                    </div>
                </div>

            </div>

            {serviceDetailsData.OFFER_CANCELLED == 1 ?
                <div style={{marginBottom:28}}>
                    <RefundStatusCard colourStyle="#5ba85a" titleText="Refund Completed" />
                </div>
            : null
            }


            {
                ((serviceDetailsData.QF_OUR_COUPON_PRID) || (serviceDetailsData.QF_MERCHANT_COUPON_PRID) || (serviceDetailsData.QF_SUPPORT_COUPON_PRID)) ?

                <div>
                    <CouponButton couponCode={serviceDetailsData.QF_MERCHANT_COUPON_PRID ? serviceDetailsData.MERCHANT_COUPON_CODE : serviceDetailsData.QF_OUR_COUPON_PRID ? serviceDetailsData.OUR_COUPON_CODE : serviceDetailsData.QF_SUPPORT_COUPON_PRID ? serviceDetailsData.SUPPORT_COUPON_CODE : null}/> 
                </div>

            : null
            }


            <div className="booking_details_prices_main_div">
                <div className="booking_details_prices_div">
                    
                {serviceDetailsData.QF_MERCHANT_OFFER_PRID ? 
                        <text className="booking_details_serviceid_text">Offer ID</text>
                    :
                        <text className="booking_details_serviceid_text">Service ID</text>
                    }
                        <text className="booking_details_each_prices_text">{serviceDetailsData.QF_MERCHANT_OFFER_PRID ? serviceDetailsData.QF_MERCHANT_OFFER_PRID : serviceDetailsData.QF_MERCHANT_SERVICE_PRID}</text>
                </div>

                <div className="booking_details_prices_each_div">
                    <text className="boking_details_each_name_text">Professional Price</text>
                    {serviceDetailsData.QF_MERCHANT_OFFER_PRID ?
                        <text className="booking_details_each_prices_text">{serviceDetailsData.MERCHANT_OFFER_CURRENCY === "Rupees" ? '\u20B9' : "$"}{numeral(serviceDetailsData.MERCHANT_OFFER_PRICE).value().toFixed(2)}</text>
                        :
                        <text className="booking_details_each_prices_text">{serviceDetailsData.REC_SERVICE_CURRENCY === "Rupees" ? '\u20B9' : "$"}{numeral(serviceDetailsData.REC_SERVICE_OFFER_PRICE).value().toFixed(2)}</text>
                    }
                </div>

                {serviceDetailsData.CP_PRID ?
                    <div className="booking_details_prices_each_div">
                        <text className="boking_details_each_name_text">{serviceDetailsData.CP_DIFF_PRICE>0?"Slash Fee":"Slash Discount"}</text>
                        <text className="booking_details_each_prices_text">{serviceDetailsData.REC_SERVICE_CURRENCY === "Rupees" ? '\u20B9' : "$"}{ Math.abs(serviceDetailsData.CP_DIFF_PRICE).toFixed(2)}</text>
                    </div> 
                : null}

                <div className="booking_details_prices_each_div">
                    <text className="boking_details_each_name_text">Your Price</text>
                    {serviceDetailsData.QF_MERCHANT_OFFER_PRID ?
                        <text className="booking_details_each_prices_text">{serviceDetailsData.MERCHANT_OFFER_CURRENCY === "Rupees" ? '\u20B9' : "$"}{serviceDetailsData.CP_PRID?numeral(serviceDetailsData.MERCHANT_OFFER_PRICE).add(serviceDetailsData.CP_DIFF_PRICE).value().toFixed(2):(serviceDetailsData.MERCHANT_OFFER_PRICE? numeral(serviceDetailsData.MERCHANT_OFFER_PRICE).value().toFixed(2):numeral(serviceDetailsData.MERCHANT_OFFER_PRICE).value().toFixed(2))}</text>
                        :
                        <text className="booking_details_each_prices_text">{serviceDetailsData.REC_SERVICE_CURRENCY === "Rupees" ? '\u20B9' : "$"}{serviceDetailsData.CP_PRID?numeral(serviceDetailsData.REC_SERVICE_OFFER_PRICE).add(serviceDetailsData.CP_DIFF_PRICE).value().toFixed(2):(serviceDetailsData.REC_SERVICE_OFFER_PRICE? numeral(serviceDetailsData.REC_SERVICE_OFFER_PRICE).value().toFixed(2):numeral(serviceDetailsData.REC_SERVICE_OFFER_PRICE).value().toFixed(2))}</text>
                    }
                </div>

                {serviceDetailsData.TS_PRID ?
                    <div className="booking_details_prices_each_div">
                        <text className="boking_details_each_name_text">{serviceDetailsData.TS_DIFF_PRICE>0?"High Demand Surcharge":"Low Demand Discount"}</text>
                        <text className="booking_details_each_prices_text">{serviceDetailsData.REC_SERVICE_CURRENCY === "Rupees" ? '\u20B9' : "$"}{ Math.abs(serviceDetailsData.TS_DIFF_PRICE).toFixed(2)}</text>
                    </div>
                : null}

                {((serviceDetailsData.QF_OUR_COUPON_PRID) || (serviceDetailsData.QF_MERCHANT_COUPON_PRID) || (serviceDetailsData.QF_SUPPORT_COUPON_PRID)) ?
                    <div className="booking_details_prices_each_div">
                        <text className="boking_details_each_name_text">Coupon Discount Amount</text>
                        {serviceDetailsData.QF_MERCHANT_OFFER_PRID?
                            <text className="booking_details_each_prices_text">{serviceDetailsData.MERCHANT_OFFER_CURRENCY==="Rupees"?'\u20B9':"$"}{serviceDetailsData.COUPON_DISCOUNT!==null?serviceDetailsData.COUPON_DISCOUNT.toFixed(2):"0.00"}</text>
                            :
                            <text className="booking_details_each_prices_text">{serviceDetailsData.REC_SERVICE_CURRENCY==="Rupees"?'\u20B9':"$"}{serviceDetailsData.COUPON_DISCOUNT!==null?serviceDetailsData.COUPON_DISCOUNT.toFixed(2):"0.00"}</text>
                        }
                    </div>
                : null}

                {serviceDetailsData.CONVENIENCE_FEE ?
                    <div className="booking_details_prices_each_div">
                        <text className="boking_details_each_name_text">Convenience fee</text>
                        <text className="booking_details_each_prices_text">{serviceDetailsData.REC_SERVICE_CURRENCY === "Rupees" ? '\u20B9' : "$"}{serviceDetailsData.CONVENIENCE_FEE ? numeral(serviceDetailsData.CONVENIENCE_FEE).value().toFixed(2) : 0}</text>
                    </div>
                : null}

                {serviceDetailsData.REC_SERVICE_CURRENCY === "Rupees" ? 
                    <div className="booking_details_prices_each_div">
                        <text className="boking_details_each_name_text">GST</text>
                        <text className="booking_details_each_prices_text">{serviceDetailsData.REC_SERVICE_CURRENCY === "Rupees" ? '\u20B9' : "$"}{serviceDetailsData.GST_COLLECTED_ON_BOOKING ? serviceDetailsData.GST_COLLECTED_ON_BOOKING : 0.00}</text>
                    </div>
                : null}

                <hr style={{marginLeft:20, marginRight:20}}/>

                <div className="booking_details_prices_each_div">
                    <text className="booking_details_your_price_text">You Paid</text>
                    {serviceDetailsData.QF_MERCHANT_OFFER_PRID ?
                        <text className="booking_details_your_Price_details">{serviceDetailsData.MERCHANT_OFFER_CURRENCY==="Rupees"?'\u20B9':"$"}{numeral(serviceDetailsData.NET_PRICE).value().toFixed(2)}</text>
                        :
                        <text className="booking_details_your_Price_details">{serviceDetailsData.REC_SERVICE_CURRENCY==="Rupees"?'\u20B9':"$"}{numeral(serviceDetailsData.NET_PRICE).value().toFixed(2)}</text>
                    }
                </div>

                {serviceDetailsData.MERCHANT_OFFER_CANCEL_ALLOWED ?
                        <div className="booking_details_prices_each_div">
                            <text className="boking_details_each_name_text">Cancellation Fee</text>
                            <text className="booking_details_each_prices_text">{'-'}{serviceDetailsData.MERCHANT_OFFER_CANCEL_FEE < 0 ? '-' : ''}{serviceDetailsData.MERCHANT_OFFER_CURRENCY == "Rupees" ? '\u20B9' : "$"}{serviceDetailsData.MERCHANT_OFFER_CANCEL_FEE ? serviceDetailsData.MERCHANT_OFFER_CANCEL_FEE.toFixed(2) : "0.00"}</text>
                        </div>
                : null}

                {serviceDetailsData.REC_SERVICE_CANCEL_ALLOWED ?
                    <div className="booking_details_prices_each_div" style={{marginTop:16}}>
                        <text className="boking_details_each_name_text">Cancellation Fee</text>
                        <text className="booking_details_each_prices_text">{'-'}{serviceDetailsData.REC_SERVICE_CANCEL_FEE < 0 ? '-' : ''}{serviceDetailsData.REC_SERVICE_CURRENCY == "Rupees" ? '\u20B9' : "$"}{serviceDetailsData.REC_SERVICE_CANCEL_FEE ? serviceDetailsData.REC_SERVICE_CANCEL_FEE.toFixed(2) : "0.00"}</text>
                    </div> 
                :null}

                {serviceDetailsData.REFUND_GST?
                    <div className="booking_details_prices_each_div">
                        <text className="boking_details_each_name_text">GST</text>
                        {serviceDetailsData.QF_MERCHANT_OFFER_PRID ?
                            <text className="booking_details_each_prices_text">{'-'}{serviceDetailsData.MERCHANT_OFFER_CURRENCY === "Rupees" ? '\u20B9' : "$"}{serviceDetailsData.REFUND_GST ? serviceDetailsData.REFUND_GST.toFixed(2) : "0.00"}</text>
                            :
                            <text className="booking_details_each_prices_text">{'-'}{serviceDetailsData.REC_SERVICE_CURRENCY === "Rupees" ? '\u20B9' : "$"}{serviceDetailsData.REFUND_GST ? serviceDetailsData.REFUND_GST.toFixed(2) : "0.00"}</text>
                        }
                    </div>
                :null}

                <div className="booking_details_prices_each_div">
                    <text className="boking_details_each_name_text">Refund Amount</text>
                    {serviceDetailsData.QF_MERCHANT_OFFER_PRID ?
                        <text className="booking_details_your_Price_details">{'-'}{serviceDetailsData.MERCHANT_OFFER_CURRENCY === "Rupees" ? '\u20B9' : "$"}{serviceDetailsData.REFUND_AMOUNT ? serviceDetailsData.REFUND_AMOUNT.toFixed(2) : '0.00'}</text>
                        :
                        <text className="booking_details_your_Price_details">{'-'}{serviceDetailsData.REC_SERVICE_CURRENCY === "Rupees" ? '\u20B9' : "$"}{serviceDetailsData.REFUND_AMOUNT ? serviceDetailsData.REFUND_AMOUNT.toFixed(2) : '0.00'}</text>
                    }
                </div>
            </div>

            <div>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center',marginBottom:20}}>
                    <img src={Locate_Icon} />
                    <text style={{paddingLeft:10}}>Locate & Visit</text>
                </div>
                <div>
                    <div style={{width:'100%', height:400}}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyC6sHvN36SBHhSQymZ3d17MwtTeql16VbQ" }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                            options={defaultMapOptions}
                        >
                            {
                                !(serviceDetailsData.category_name == "Home Care" || serviceDetailsData.category_name=="Tutoring")
                                &&
                                <div lat={serviceDetailsData.MERCHANT_LOCATION_LATITUDE} lng={serviceDetailsData.MERCHANT_LOCATION_LONGITUDE}>
                                    <IoLocationSharp size={40} color="red"/>
                                </div>
                            }
                        </GoogleMapReact>
                    </div>

                    <div className="booking_details_address_div">
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <img src={Locate_marker_Icon} width={24} height={24}/>
                            <text className="boking_details_each_name_text" style={{paddingLeft:10}}>{serviceDetailsData.MERCHANT_LOCATION_ADDRESS_LINE2}</text>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}