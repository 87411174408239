import React, { Component, Text, useState, useRef, useEffect, useContext } from 'react'
import { Navbar, NavbarBrand, Nav, NavItem, Row, NavbarToggler, Collapse, Col, Modal, FormGroup, Input, Button, FormFeedback, Container, CardBody, Card, CardImg, ModalHeader, ModalBody } from 'reactstrap';
import logo from '../../Assets/finalLogo01.png'
import beautyLogo from '../../Assets/Beauty.png'
import hairLogo from '../../Assets/image-11.png'
import autoLogo from '../../Assets/autocare-1.png'
import nailLogo from '../../Assets/image-12.png'
import locationIcon from '../../Assets/icon-location-on.png'
import locationcolorIcon from '../../Assets/colorLocation.png'
import arrowBack from '../../Assets/arrowBack.png'
import searchIcon from '../../Assets/searchIcon.png'
import curLocationIcon from '../../Assets/currentLocation.png'
import historyLocationIcon from '../../Assets/historyLocation.png'
import OtpInput from 'react-otp-input';
import NavBarV2 from '../../Components/Custom/UI/NavBarLatest';
import '../../CSS/HomePage.css'
import CategoryCards from '../../Components/Custom/UI/CategoryCards';
import { useMediaQuery } from "react-responsive"
import NearByProCards from '../../Components/Custom/UI/NearByProCards';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import RightArrow from '../../Assets/ic-round-arrow-back-ios.png'
import WhySlashNew from './WhySlashNew';
import WhyNewSlash_Mobile from './WhyNewSlash_Mobile';
import CitySpecificBannerHome from './CitySpecificBannerHome';
import DownloadOurApp from './DownloadOurApp';
import DownloadOurApp_Mobile from './DownloadOurApp_Mobile';
import FindoutWhatAreULooking from './FindoutWhatAreULooking';
import FooterSv2 from './FooterSv2';
import FooterSv2_Mobile from './FooterSv2_Mobile';
import { useNavigate,NavLink } from 'react-router-dom';
import PlacesAutocomplete,{
   geocodeByAddress,
   geocodeByPlaceId,
   getLatLng,
 } from 'react-places-autocomplete';
import { offersDataJSONUs, offersDataJSONIn } from './ServiceStaticArray'
import FetchCurrentLocation from '../../Components/Custom/FetchCurrentLocation';
import { globalState } from '../../GlobalState/globalState';
import { ClipLoader } from 'react-spinners';
import CurrentLocationIcon from '../../Assets/icon-my-location.png'
import CloseIcon from '../../Assets/icons-closeModal.png'
import RecentLocIcon from '../../Assets/material-symbols-history-rounded.png'
import GreyLocIcon from '../../Assets/icon-location-Grey.png'
import NearByProfessionalMapView from './NearByProfessionalMapView';
import { MdOutlineEdit } from "react-icons/md";
import InputComponent from '../../Components/Custom/UI/InputComponent';
import { ToastContainer, toast } from 'react-toastify';
import customFetchData from '../../Components/Custom/customFetchData';
import { IoLocationSharp } from 'react-icons/io5';
import GoogleMapReact from 'google-map-react';
import { clearCart, clearDateAndTime, storeAddress, storeServices } from '../../GlobalState/globalStateActions';
import CurrentLocationIconRed from '../../Assets/icon-my-locationIcon.png'
import SearchCrossIcon from '../../Assets/group-383.png'
import ReferAndEarn from './ReferAndEarnHome';
import ReferAndEarnHome from './ReferAndEarnHome';
import { catColors } from '../../Components/Custom/UI/Colors';
import SavedAddresses from '../ProfileStack/SavedAddresses';
import Testimonials from '../../../New_WebPage_Design/Components/Consumer/TestimonialsV2';
import {Helmet} from "react-helmet";
const DeepClone = require("rfdc/default")
let localStorage=window.localStorage
// console.log('localStorage',localStorage)

function LandingPageUpdated() {
  
   const useMobileAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 760px)" })
   const useTabletMediaQuery = () => useMediaQuery({ minWidth: 768, maxWidth: 991 })
   const isMobileView = useMobileAndBelowMediaQuery()
   const isTabView = useTabletMediaQuery()
   const [state,dispatch]=useContext(globalState)
   // const [country, setCoutry] = useState('India')
   const [modalInd, setModalInd] = useState(false)
   const [modalUSA, setModalUSA] = useState(false)
   const [modalOTP, setModalOTP] = useState(false)
   const [locModal, setlocModal] = useState(false)
   const [serviceModal, setserviceModal] = useState(false)
   const [query, setquery] = useState('')
   // const [state, setstate] = useState({ query: '', list: [] })
   const searchInput = useRef()
   const [otp, setOtp] = useState('')
   const [nearByPros, setNearByPros] = useState([])
   const [categories, setCategories] = useState([])
   const [subCategories, setSubCategories] = useState([])
   const [mpidArr, setMpidArr] = useState([])
   const [searchText,setSearchText]=useState('')
   const [showSearchDropDown,setShowSearchDropDown]=useState(false)
   const [searchTextOffer,setSearchTextOffer]=useState('')
   const [filteredArray,setFilteredArray]=useState([])
   const [searchDropdownArray,setSearchDropdownArray]=useState([])
   const [clickOnOfferSearch,setClickOnOfferSearch]=useState(false)
   const [didClearSearch,setDidClearSearch]=useState(false)
   const [searchAddModalOpen, setSearchAddModalOpen] = useState(false)
   const [totalAddress,setTotalAddress]=useState('')
   const [latitude,setLatitude]=useState()
   const [longitude,setLongitude]=useState()
   const [stateOrProvince,setStateOrProvince]=useState('')
   const [city,setCity]=useState('')
   const [zipcode,setZipcode]=useState()
   const [country,setCountry]=useState('')
   const [district,setDistrict]=useState('')
   const [addressLine1,setAddressLine1]=useState('')
   const [loading,setloading]=useState(false)
   const [loadingL,setloadingL]=useState(false)
   const [loadingL1,setloadingL1]=useState(false)
   const [loadingCat,setLoadingCat]=useState(true)
   const [currentLocModal,setCurrentLocModal]=useState(false)
   const [currentAddress,setCurrentAddress]=useState('')
   const [addressSearchModal,setAddressSearchModal]=useState(false)
   const [recentSearchesArray,setRecentSearchesArray]=useState([])
   const [recentLocationsHide,setRecentLocationsHide]=useState(false)
   const [seeByMapModalWeb,setSeeByMapModalWeb]=useState(false)
   const [seeByMapModalMobile,setSeeByMapModalMobile]=useState(false)
   const [saveAddressModal,setSaveAddressModal]=useState(false)
   const input_Ref1 = useRef();
   const input_Ref2 = useRef();
   const [clickedValue,setClickedValue]=useState([])
   const [selectedTag,setSelectedTag]=useState('Home');
   const [mapViewModal,setMapViewModal]=useState(false)
   const [mapViewModalWeb,setMapViewModalWeb]=useState(false)
   const [editNewAddressModal,setEditNewAddressModal]=useState(false)
   const mapRef=useRef()
   let placesArray=
    [
        {
            id:1,
            place:'Home'
        },
        {
            id:2,
            place:'Work'
        },
        {
            id:3,
            place:'Hotel'
        },
        {
            id:4,
            place:'Others'
        }
    ]
    const [allValues, setAllValues] = useState({
      cmptAddress: '',
      floor: '',
      landmark: '',
      status: '',
      message: '',
      addError: false,
      floorError: false,
      landError: false
  })
  const [cityNotYet,setCityNotYet]=useState(false)
  const [checked,setChecked]=useState([])
  const [defaultProps,setDefaultProps]=useState({
     center: {
        lat: 0,
        lng: 0
     },
     zoom: 10
  })
  const [addressUpDated,setAddressUpDated]=useState(false)
  const [currentTextClick,setCurrentTextClick]=useState(false)
  const [curLocationDenied,setCurLocationDenied]=useState(false)
  const [fetchedDataResponse,setFetchedDataResponse]=useState([])
  const [editAddress,setEditAddress]=useState({})
  const searchAddRef=useRef()
  const searchAddRef1=useRef()
  const searchAddRefMob=useRef()
   const navigate = useNavigate();
   window.scrollTo(0,0)
   // console.log("state.address.totalAddress",state.address.totalAddress)
   // useLayoutEffect(()=>{console.log("test use effect")},[])
   const fetchSavedAddresses = async() => {
      let body={
          uid:state.user.id
      }
      let method = 'POST';
      let url = "/website/fetchSavedAddress/sv2"
      let DataResponse= await customFetchData(method, body, url, state, dispatch)
      // console.log('fetch data',DataResponse)
      if(DataResponse.fetch){
          let addressResult=DataResponse.fetchedData.fetchAddressResult
          if(addressResult.length!==0){
              setFetchedDataResponse(addressResult)
          }
      }
   }
   useEffect(() => {
      // console.log("state in home page",state)
      // if(state.address.searchAddress === false && state.address.currentAddress === true){
      //    currentLocationFunc(false)
      // }else if(state.address.searchAddress === true && state.address.currentAddress === false){
      //    if (state.address) {
      //       setAddressLine1(state.address.addressLine1)
      //       setCountry(state.address.country)
      //       setCity(state.address.city)
      //       setDistrict(state.address.district)
      //       setTotalAddress(state.address.totalAddress)
      //       setStateOrProvince(state.address.stateOrProvince)
      //       setZipcode(state.address.zipcode)
      //       setLatitude(state.address.latitude)
      //       setLongitude(state.address.longitude)
      //    }
      // }
      // let fetchedData=[];
      // if(state.address.country ==='India'){
      //    // setLoadingCat(true)
      //    JSON.stringify(offersDataJSONIn)
      //    fetchedData = offersDataJSONIn
      // }else{
      //    if(state.address.country === 'United States'){
      //       // setLoadingCat(true)
      //       JSON.stringify(offersDataJSONUs)
      //       fetchedData = offersDataJSONUs
      //    }
      // }
      if(state.token){
         fetchSavedAddresses()
      }
      fetchServiceData()
      // setShowSearchDropDown(false)
      // setSearchTextOffer('')
      // setSearchDropdownArray([]) 
      // local storage handler
      // localStorageHandler()
   //    placesArray.map((item,index)=>{
   //       if(item.id === 1){
   //           // console.log('place array1',item)
   //           return clickedValue['clickedValue_'+ item.id]=true
             
   //       }else{
   //           // console.log('place array2',item)
   //          return clickedValue['clickedValue_'+ item.id]=false
   //           // console.log('placse array',clickedValue)
   //       }
   //   })  
   },[state.addressUpdated])
   let fetchServiceData= async() =>{
      try{
         clearDateAndTime(dispatch)
         if(state.services.length==0 || addressUpDated){
            console.log('..state services empty check.. or ..address update..' )
            let city_data;
            if(state.address.searchAddress === false && state.address.currentAddress === true){
               // currentLocationFunc(false)
               // city_data={
               //    curr_city: city,
               //    curr_lat: latitude,
               //    curr_long: longitude,
               //    stateOrProvince:stateOrProvince,
               // }
               
               // console.log('curLocationDenied', curLocationDenied)
               // if (curLocationDenied) {
               //    setloadingL(false)
               //    setloadingL1(false)
               //    // console.log('curLocationDenied', curLocationDenied)
               //    toast.error(<text style={{ textAlign: 'center' }}>Please enable your location from your browser settings</text>, { position: isMobileView ? toast.POSITION.TOP_CENTER : toast.POSITION.BOTTOM_CENTER })
               // } else {
                  if(currentTextClick){
                     if (state.address) {
                        let region={
                              center: {
                                  lat:state.address.latitude,
                                  lng:state.address.longitude
                              },
                              zoom: 15
                        }
                        // mapRef.current.animateToRegion(region);
                        setDefaultProps(region)
                        setAddressLine1(state.address.addressLine1)
                        setCountry(state.address.country)
                        setCity(state.address.city)
                        setDistrict(state.address.district)
                        setTotalAddress(state.address.totalAddress)
                        setStateOrProvince(state.address.stateOrProvince)
                        setZipcode(state.address.zipcode)
                        setLatitude(state.address.latitude)
                        setLongitude(state.address.longitude)
                        // setloadingL(false)
                        // setloadingL1(false)
                        city_data={
                           curr_city: state.address.city,
                           curr_lat: state.address.latitude,
                           curr_long: state.address.longitude,
                           stateOrProvince:state.address.stateOrProvince,
                         }
                        setCurLocationDenied(state.address.currentLocationDenied)
                     }
                     
                  }else{
                     setloadingL(true)
                     setloadingL1(true)
                     let addressDetails = await FetchCurrentLocation(state, dispatch)
                     // console.log('address', addressDetails)
                     if (addressDetails.locationFetch) {
                        let { addressLine1, city, country, curr_location_latitude, curr_location_longitude, currentLocation, district, stateOrProvince, zipcode, currentAddress } = addressDetails
                        let region = {
                           center: {
                              lat: curr_location_latitude,
                              lng: curr_location_longitude
                           },
                           zoom: 15
                        }
                        setDefaultProps(region)
                        setLatitude(curr_location_latitude)
                        setLongitude(curr_location_longitude)
                        if (currentAddress || state.address.currentAddress) {
                           setCurrentAddress(currentLocation)
                        }
                        setTotalAddress(currentLocation)
                        setloadingL(false)
                        setloadingL1(false)
                        setAddressLine1(addressLine1)
                        setCity(city)
                        setCountry(country)
                        setZipcode(zipcode)
                        setStateOrProvince(stateOrProvince)
                        setDistrict(district)
                        city_data={
                           curr_city: city,
                           curr_lat: curr_location_latitude,
                           curr_long: curr_location_longitude,
                           stateOrProvince,
                        }
                     } else {
                        if (addressDetails.currentLocationDenied) {
                           let { addressLine1, city, country, curr_location_latitude, curr_location_longitude, currentLocation, district, stateOrProvince, zipcode, currentAddress } = addressDetails
                           let region = {
                              center: {
                                 lat: curr_location_latitude,
                                 lng: curr_location_longitude
                              },
                              zoom: 15
                           }
                           setDefaultProps(region)
                           setLatitude(curr_location_latitude)
                           setloadingL(false)
                           setTotalAddress(currentLocation)
                           setloadingL1(false)
                           setAddressLine1(addressLine1)
                           setCity(city)
                           setCountry(country)
                           setZipcode(zipcode)
                           setStateOrProvince(stateOrProvince)
                           setDistrict(district)
                           setLongitude(curr_location_longitude)
                           setCurLocationDenied(addressDetails.currentLocationDenied)
                           city_data={
                              curr_city: city,
                              curr_lat: curr_location_latitude,
                              curr_long: curr_location_longitude,
                              stateOrProvince,
                            }
                           // if (currentAddress || state.address.currentAddress) {
                           //    setCurrentAddress(currentLocation)
                           // }
                        }
                     }
                  }
               // }
            }else if(state.address.searchAddress === true && state.address.currentAddress === false){
               if (state.address) {
                  let region={
                        center: {
                            lat:state.address.latitude,
                            lng:state.address.longitude
                        },
                        zoom: 15
                  }
                  // mapRef.current.animateToRegion(region);
                  setDefaultProps(region)
                  setAddressLine1(state.address.addressLine1)
                  setCountry(state.address.country)
                  setCity(state.address.city)
                  setDistrict(state.address.district)
                  setTotalAddress(state.address.totalAddress)
                  setStateOrProvince(state.address.stateOrProvince)
                  setZipcode(state.address.zipcode)
                  setLatitude(state.address.latitude)
                  setLongitude(state.address.longitude)
                  city_data={
                     curr_city: state.address.city,
                     curr_lat: state.address.latitude,
                     curr_long: state.address.longitude,
                     stateOrProvince:state.address.stateOrProvince,
                   }
               }
            }
            setLoadingCat(true)
            // static array to be commented
            // let fetchedData;
            // if(state.address.country ==='India'){
            //    // setLoadingCat(true)
            //    JSON.stringify(offersDataJSONIn)
            //    fetchedData = offersDataJSONIn
            // }else if(state.address.country === 'United States'){
            //       // setLoadingCat(true)
            //       JSON.stringify(offersDataJSONUs)
            //       fetchedData = offersDataJSONUs
            // }else{
            //    fetchedData={
            //       getOff:false,
            //       cityNotYet:true
            //    }
            // }
            //// original route to be uncommented
            if(state.user.id && state.token)
            {
               city_data.consumerId=state.user.id
            }
            let {fetchedData}=await customFetchData('POST',city_data,'/website/services/currentservices/sv2',state,dispatch)
            console.log("offer data",fetchedData)
            if(fetchedData.getOff)
            {
            //   console.log("inside use effect home page")
              setCategories(fetchedData.catsFinal)
              setSubCategories(fetchedData.subcatsFinal)
              // setNearByPros(fetchedData.offers)
              let mpidArr=[];
              mpidArr=fetchedData.mpidArr
              if(fetchedData.mpidArr.length!==0){
                let body={
                  mpidArr:mpidArr,
                  consumer_prid:state.user.id,
                  wishlistType:'professional'
                }
                let wishListedData=await customFetchData('POST',body,'/website/wishlistedPros/sv2',state,dispatch)
               //  console.log('wish list',wishListedData)
                if(wishListedData.fetch){
                  if(wishListedData.fetchedData.wishlistRes.length!==0){
                  //   console.log('entered')
                    fetchedData.offers.map(item=>{
                      // console.log('item',item[0])
                      item.map(item11=>{
                        wishListedData.fetchedData.wishlistRes.map(item1=>{
                          // console.log('wish list values',item[0],item1)
                          if(item11.QF_MERCHANT_PERSONNEL_PRID === item1.QF_MERCHANT_PERSONNEL_PRID){
                            // console.log('wish list values',item1)
                            return (
                              item11.wishlistPro_Prid=item1.QF_WISHLIST_PRID,
                              item11.wishlist_Pro=item1.WISHLIST_DELETED_FLAG
                            )
                          }
                        })
                      })
                    })
                    fetchedData.offers.map(item11=>{
                      // console.log('item',item11)
                        if(item11[0].wishlist_Pro === 0){
                          return checked['checked_'+ item11[0].QF_MERCHANT_PERSONNEL_PRID]=true
                        }else{
                            return checked['checked_'+ item11[0].QF_MERCHANT_PERSONNEL_PRID]=false
                        }
                    })
                  //   fetchedData.offers.sort((a,b)=>{
                  //    return a[0].distance - b[0].distance
                  //   })
                    setNearByPros(fetchedData.offers)
                    setFilteredArray(fetchedData.offers)
                    storeServices(fetchedData.offers,fetchedData.catsFinal,fetchedData.subcatsFinal,dispatch)
                  //   dispatch({type:'SET_SERVICES',services:fetchedData.offers})
                  //   dispatch({type:'SET_CATEGORIES',categories:fetchedData.catsFinal})
                  //   dispatch({type:'SET_SUBCATEGORIES',subcategories:fetchedData.subcatsFinal})
                  //   dispatch({type:'SET_SERVICE_REFRESH_COUNTER',servicesRefreshCounter:servicesRefreshCounter+1})
                  }else{
                     // fetchedData.offers.sort((a,b)=>{
                     //    return a[0].distance - b[0].distance
                     // })
                    setNearByPros(fetchedData.offers)
                    setFilteredArray(fetchedData.offers)
                    storeServices(fetchedData.offers,fetchedData.catsFinal,fetchedData.subcatsFinal,dispatch)
                  //   dispatch({type:'SET_SERVICES',services:fetchedData.offers})
                  //   dispatch({type:'SET_CATEGORIES',categories:fetchedData.catsFinal})
                  //   dispatch({type:'SET_SUBCATEGORIES',subcategories:fetchedData.subcatsFinal})
                  //   dispatch({type:'SET_SERVICE_REFRESH_COUNTER',servicesRefreshCounter:servicesRefreshCounter+1})
                  }
                }else{
                  // fetchedData.offers.sort((a,b)=>{
                  //    return a[0].distance - b[0].distance
                  // })
                  setNearByPros(fetchedData.offers)
                  setFilteredArray(fetchedData.offers)
                  storeServices(fetchedData.offers,fetchedData.catsFinal,fetchedData.subcatsFinal,dispatch)
                  // dispatch({type:'SET_SERVICES',services:fetchedData.offers})
                  // dispatch({type:'SET_CATEGORIES',categories:fetchedData.catsFinal})
                  // dispatch({type:'SET_SUBCATEGORIES',subcategories:fetchedData.subcatsFinal})
                  // dispatch({type:'SET_SERVICE_REFRESH_COUNTER',servicesRefreshCounter:servicesRefreshCounter+1})
                }
              }
              setMpidArr(fetchedData.mpidArr)
            }
            else
            {
              if(fetchedData.cityNotYet)
              {
                setCityNotYet(true)
              }
              else
              {
                toast.error("Something went wrong. Please try later.", {position:toast.POSITION.BOTTOM_CENTER});
              }
              setCategories([])
              setSubCategories([])
              setNearByPros([])
              setFilteredArray([])
              storeServices([],[],[],dispatch)
            //   dispatch({type:'SET_SERVICES',services:[]})
            //   dispatch({type:'SET_CATEGORIES',categories:[]})
            //   dispatch({type:'SET_SUBCATEGORIES',subcategories:[]})
            //   dispatch({type:'SET_SERVICE_REFRESH_COUNTER',servicesRefreshCounter:servicesRefreshCounter+1})
            }
            setLoadingCat(false)
            setShowSearchDropDown(false)
            setSearchTextOffer('')
            setSearchDropdownArray([]) 
            // local storage handler
            localStorageHandler()
            placesArray.map((item,index)=>{
               if(item.id === 1){
                   // console.log('place array1',item)
                  return  clickedValue['clickedValue_'+ item.id]=true
                   
               }else{
                   // console.log('place array2',item)
                  return clickedValue['clickedValue_'+ item.id]=false
                   // console.log('placse array',clickedValue)
               }
           })  
         }
         else{
           //// Else case to prevent default reloading
            setLoadingCat(true)
            if(state.services.length!==0){
            console.log("global services dispatching to state again")
                  if (state.address) {
                     let region={
                           center: {
                               lat:state.address.latitude,
                               lng:state.address.longitude
                           },
                           zoom: 15
                     }
                     // mapRef.current.animateToRegion(region);
                     setDefaultProps(region)
                     setAddressLine1(state.address.addressLine1)
                     setCountry(state.address.country)
                     setCity(state.address.city)
                     setDistrict(state.address.district)
                     setTotalAddress(state.address.totalAddress)
                     setStateOrProvince(state.address.stateOrProvince)
                     setZipcode(state.address.zipcode)
                     setLatitude(state.address.latitude)
                     setLongitude(state.address.longitude)
                     if(state.address.currentAddress){
                        setCurrentAddress(state.address.totalAddress)
                     }
                     setCurLocationDenied(state.address.currentLocationDenied)
                  }
                  let offers = DeepClone(state.services)
                  let categoryArr = DeepClone(state.categories)
                  let subcatArr = DeepClone(state.subcategories)
                  // console.log("inside use effect else case",offers,categoryArr,subcatArr)
                  if(offers.length!==0){
                     setCategories(categoryArr)
                     setSubCategories(subcatArr)
                     let mpidArr=[];
                     mpidArr=offers.map(item=>{
                        return item[0].QF_MERCHANT_PERSONNEL_PRID
                     })
                     if (mpidArr !== 0) {
                        let body = {
                           mpidArr: mpidArr,
                           consumer_prid: state.user.id,
                           wishlistType: 'professional'
                        }
                        let wishListedData = await customFetchData('POST', body, '/website/wishlistedPros/sv2', state, dispatch)
                        // console.log('wish list', wishListedData)
                        if (wishListedData.fetch) {
                           if (wishListedData.fetchedData.wishlistRes.length !== 0) {
                              console.log('entered')
                              offers.map(item => {
                                 // console.log('item',item[0])
                                 item.map(item11 => {
                                    wishListedData.fetchedData.wishlistRes.map(item1 => {
                                       // console.log('wish list values',item[0],item1)
                                       if (item11.QF_MERCHANT_PERSONNEL_PRID === item1.QF_MERCHANT_PERSONNEL_PRID) {
                                          // console.log('wish list values',item1)
                                          return (
                                             item11.wishlistPro_Prid = item1.QF_WISHLIST_PRID,
                                             item11.wishlist_Pro = item1.WISHLIST_DELETED_FLAG
                                          )
                                       }
                                    })
                                 })
                              })
                              offers.map(item11 => {
                                 // console.log('item',item11)
                                 if (item11[0].wishlist_Pro === 0) {
                                    return checked['checked_' + item11[0].QF_MERCHANT_PERSONNEL_PRID] = true
                                 } else {
                                    return checked['checked_' + item11[0].QF_MERCHANT_PERSONNEL_PRID] = false
                                 }
                              })
                              setNearByPros(offers)
                              setFilteredArray(offers)
                              storeServices(offers,categoryArr,subcatArr,dispatch)
                              // dispatch({ type: 'SET_SERVICES', services: offers })
                              // dispatch({ type: 'SET_CATEGORIES', categories: categoryArr })
                              // dispatch({ type: 'SET_SUBCATEGORIES', subcategories: subcatArr })
                              //   dispatch({type:'SET_SERVICE_REFRESH_COUNTER',servicesRefreshCounter:servicesRefreshCounter+1})
                           } else {
                              setNearByPros(offers)
                              setFilteredArray(offers)
                              storeServices(offers,categoryArr,subcatArr,dispatch)
                              // dispatch({ type: 'SET_SERVICES', services: offers })
                              // dispatch({ type: 'SET_CATEGORIES', categories: categoryArr })
                              // dispatch({ type: 'SET_SUBCATEGORIES', subcategories: subcatArr })
                              //   dispatch({type:'SET_SERVICE_REFRESH_COUNTER',servicesRefreshCounter:servicesRefreshCounter+1})
                           }
                        } else {
                           setNearByPros(offers)
                           setFilteredArray(offers)
                           storeServices(offers,categoryArr,subcatArr,dispatch)
                           // dispatch({ type: 'SET_SERVICES', services: offers })
                           // dispatch({ type: 'SET_CATEGORIES', categories: categoryArr })
                           // dispatch({ type: 'SET_SUBCATEGORIES', subcategories: subcatArr })
                        }
                        setMpidArr(mpidArr)
                     }
                     
                     // setNearByPros(offers)
                     // setFilteredArray(offers)
                     setLoadingCat(false)
                  }
                  setShowSearchDropDown(false)
                  setSearchTextOffer('')
                  setSearchDropdownArray([])
                  // local storage handler
                  localStorageHandler()
                  placesArray.map((item, index) => {
                     if (item.id === 1) {
                        // console.log('place array1',item)
                        return clickedValue['clickedValue_' + item.id] = true
      
                     } else {
                        // console.log('place array2',item)
                        return clickedValue['clickedValue_' + item.id] = false
                        // console.log('placse array',clickedValue)
                     }
                  })
            }
            
         }
      }catch(error){
         console.log('error',error)
      }
   }
   const localStorageHandler = async() => {
      let getValue=await localStorage.getItem("qf:recent:address")
      // console.log("value in use effect",JSON.parse(getValue))
      setRecentSearchesArray(JSON.parse(getValue) !==null ? JSON.parse(getValue) : [])
   }
   const currentLocationFunc=async(fromClickCurrent)=>{
      setloadingL(true)
      setloadingL1(true)
      if(fromClickCurrent) {
         setCurrentLocModal(false)
         setCurrentTextClick(true)
      } 
      // console.log('curLocationDenied',curLocationDenied)
      if(curLocationDenied){
         // setMapViewModal(false)
         setTotalAddress(state.address.totalAddress)
         // console.log('total address',state.address.totalAddress)
         setloadingL(false)
         setloadingL1(false)
         if(isMobileView){
            setAddressSearchModal(true)
         }
         // console.log('curLocationDenied',curLocationDenied)
         toast.error(
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
               <text style={{textAlign:'center'}}>Please enable your location from your browser settings</text>
            </div>
            , {
            position: "bottom-center",
            hideProgressBar: true,
            progress: undefined,
            closeButton:false,
            style:{backgroundColor:'#E0D1FF'}
        });
         // toast.error(<text style={{textAlign:'center'}}>Please enable your location from your browser settings</text>, { position: isMobileView ? toast.POSITION.TOP_CENTER : toast.POSITION.BOTTOM_CENTER })
      }else{
         // setAddressUpDated(true)
         if(isMobileView){
            setMapViewModal(true)
         }else{
            setMapViewModalWeb(true)
         }
         let addressDetails= await FetchCurrentLocation(state,dispatch,fromClickCurrent)
         // console.log('address',addressDetails)
         if(addressDetails.locationFetch){
            let {addressLine1,city,country,curr_location_latitude,curr_location_longitude,currentLocation,district,stateOrProvince,zipcode,currentAddress}=addressDetails
            let region={
               center: {
                   lat:curr_location_latitude,
                   lng:curr_location_longitude
               },
               zoom: 15
            }
            setDefaultProps(region)
            setLatitude(curr_location_latitude)
            setLongitude(curr_location_longitude)
            if (currentAddress || state.address.currentAddress) {
               setCurrentAddress(currentLocation)
            }
            setTotalAddress(currentLocation)
            setloadingL(false)
            setloadingL1(false)
            setAddressLine1(addressLine1)
            setCity(city)
            setCountry(country)
            setZipcode(zipcode)
            setStateOrProvince(stateOrProvince)
            setDistrict(district)
            // if(!isMobileView){
            //    setAddressUpDated(true)
            //    dispatch({type:'SET_ADDRESS_UPDATED',addressUpdated:!state.addressUpdated})
            // }
         }else{
            if(addressDetails.currentLocationDenied){
               let {addressLine1,city,country,curr_location_latitude,curr_location_longitude,currentLocation,district,stateOrProvince,zipcode,currentAddress}=addressDetails
               let region={
                  center: {
                      lat:curr_location_latitude,
                      lng:curr_location_longitude
                  },
                  zoom: 15
               }
               setDefaultProps(region)
               setLatitude(curr_location_latitude)
               setloadingL(false)
               setTotalAddress(currentLocation)
               setloadingL1(false)
               setAddressLine1(addressLine1)
               setCity(city)
               setCountry(country)
               setZipcode(zipcode)
               setStateOrProvince(stateOrProvince)
               setDistrict(district)
               setLongitude(curr_location_longitude)
               setCurLocationDenied(addressDetails.currentLocationDenied)
               // if (currentAddress || state.address.currentAddress) {
               //    setCurrentAddress(currentLocation)
               // }
            }
         }
      }
   }
   // Toggle for Modals
   const toggle = () => {

      if (country === 'India') {
         setModalInd(!modalInd)
      }
      else {
         setModalUSA(!modalUSA)
      }

   }

   const toggle1 = () => {

      setModalOTP(false)
      setModalInd(false)
      setModalUSA(false)

   }



   const locationModalHandle = () => setlocModal(!locModal);
   // const serviceModalHandle = () => setserviceModal(!serviceModal);

   const openSendVerificationModal = () => {

      if (country === 'India') {
         setModalInd(false)
      }
      else {
         setModalUSA(false)
      }
      setModalOTP(!modalOTP)
   }



   // const searchHandleChange = (e) => {
   //    if (e.target.value === "") {
   //       setserviceModal(false)
   //       setquery(e.target.value)
   //    } else {

   //       setquery(e.target.value)
   //       setserviceModal(true)
   //       const results = posts.filter(post => {
   //          if (e.target.value === "") return posts
   //          else
   //             return post.categoryName.toLowerCase().includes(e.target.value.toLowerCase())
   //       })
   //       setstate({
   //          query: e.target.value,
   //          list: results
   //       })
   //    }
   // }

   let categoryCards, nearByProCardDisplay;
   if (categories.length !== 0) {
      categoryCards = (
         categories.map((item, index) => {
            return (
               // <div className='categoryCardWidth'>
               //    <CategoryCards 
               //       onClick={()=>{navigate('/professionalUnderCategory',{state:{categories,subCategories,nearByPros,catItem:item,mpidArr,catPrid:item.QF_SERVICE_CATEGORY_PRID,fromExplore:false}})}} 
               //       categoryName={item.category_name} uri={item.category_image ? item.category_image : ''} style={{ marginTop: 16, marginRight: 2 }} />
               // </div>
               <div className='categoryCardWidth'>
                  <CategoryCards 
                     // onClick={()=>{navigate(`/allprofessionals/${item.category_name.replace(" ","","g")}/${item.QF_SERVICE_CATEGORY_PRID}`,{state:{categories,subCategories,nearByPros,catItem:item,mpidArr,catPrid:item.QF_SERVICE_CATEGORY_PRID,fromExplore:false}})}} 
                     onClick={()=>{navigate({
                        pathname: `/allprofessionals`,
                        search: `?category=${item.category_name.replaceAll(" ","","g")}&categoryId=${item.QF_SERVICE_CATEGORY_PRID}`
                      },{state:{categories,subCategories,nearByPros,catItem:item,mpidArr,catPrid:item.QF_SERVICE_CATEGORY_PRID,fromExplore:false,fromSearch:false
                        ,addressDetails:
                        {
                           totalAddress: totalAddress,
                           addressLine1: addressLine1,
                           country: country,
                           city: city,
                           stateOrProvince: stateOrProvince,
                           zipcode: zipcode,
                           district: district,
                           latitude: latitude,
                           longitude: longitude,
                           currentAddress: state.address.currentAddress,
                           searchAddress: state.address.searchAddress,
                           currentLocationDenied: state.address.currentLocationDenied
                        }}})}} 
                     categoryName={item.category_name} uri={item.category_image ? item.category_image : ''} style={{ marginTop: 16, marginRight: 2 }} />
               </div>
            )
         })
      )
   }else{
      categoryCards=(
         <div>
            <text>No Categories to display</text>
         </div>
      )
   }
   // Near by pro cards carousel
   const ButtonGroup = ({ next, previous, ...rest }) => {
      const {
         carouselState: { currentSlide, totalItems, slidesToShow }
      } = rest;

      return (
         <div>
            <div className="arrowLandSv2">
               <Card  className={currentSlide === 0 ? "disable_arrowLandLeftSv2" : "arrowLandLeftSv2"} onClick={() => previous()}>
                  <CardImg src={RightArrow} style={{ transform: 'rotate(180deg)' }} className={"carousel_arrowLandingSv2"} />
               </Card>
               <Card className={currentSlide === totalItems - slidesToShow ? "disable_arrowLandRightSv2" : "arrowLandRightSv2"} onClick={() => next()}>
                  <CardImg src={RightArrow} className={"carousel_arrowLandingSv2"} />
               </Card>
            </div>
         </div>
      );
   };
   const responsive = {
      desktop: {
         breakpoint: { max: 3000, min: 1100 },
         items: 4,
         slidesToSlide: 1 // optional, default to 1.
      },
      laptop: {
         breakpoint: { max: 1100, min: 900 },
         items: 3,
         slidesToSlide: 2 // optional, default to 1.
      },
      tablet: {
         breakpoint: { max: 900, min: 600 },
         items: 2.5,
         slidesToSlide: 2 // optional, default to 1.
      },
      TabMobile:{
         breakpoint: { max: 600, min: 340 },
         items: 1.3,
         slidesToSlide: 2
      },
      mobile: {
         breakpoint: { max: 340, min: 0 },
         items: 1.15,
         slidesToSlide: 1 // optional, default to 1.
      }
   };
   const WishlistPressHandler = async(item) =>{
      // console.log("pressed",item)
      nearByPros.map(list=>{
        // console.log('list',list[0])
        if(list[0].QF_MERCHANT_PERSONNEL_PRID === item.QF_MERCHANT_PERSONNEL_PRID){
          // console.log(list[0].QF_MERCHANT_PERSONNEL_PRID === item.QF_MERCHANT_PERSONNEL_PRID,'list')
          return setChecked((prevState)=>{
            return {
              ...prevState,
              ['checked_' + list[0].QF_MERCHANT_PERSONNEL_PRID]:!checked['checked_' + list[0].QF_MERCHANT_PERSONNEL_PRID]
            }
          }) 
        }
    })
      let body={
        // cprid:item.QF_CONSUMER_PRID,
        cprid:state.user.id,
        mpprid:item.QF_MERCHANT_PERSONNEL_PRID,
        wishLstType:'professional'
      }
      // setWishlistPress(!wishlistPress)
      let WishlistInsert=await customFetchData('POST',body,'/website/add/wishList/sv2',state,dispatch)
      // console.log('wish list response',WishlistInsert)
    }
   let sortedNearByPros;
   if (nearByPros.length !== 0) {
      let nearByProsDup=DeepClone(nearByPros)
      sortedNearByPros= nearByProsDup.sort((a,b)=>{
         return a[0].distance - b[0].distance
      })
      nearByProCardDisplay = (
         <Carousel
            infinite={false}
            swipeable={isMobileView ? true : false}
            draggable={false}
            showDots={false}
            arrows={false}
            // focusOnSelect={true}
            // renderButtonGroupOutside={false}
            customButtonGroup={<ButtonGroup />}
            // itemClass="image-item"
            responsive={responsive}
            transitionDuration={500}
            containerClass="carousel_container_nearbypros"
            renderArrowsWhenDisabled={true}

         >
            {
               sortedNearByPros.map((item, index) => {
                  // console.log('console in Home',item[0])
                  return (
                     <NearByProCards state={state} WishlistPress={()=>WishlistPressHandler(item[0])} data={item[0]} key={index}  wishListed={checked['checked_'+ item[0].QF_MERCHANT_PERSONNEL_PRID] ? true : false} onClickCard={()=>navigate({pathname:`/allservices/${item[0].MERCHANT_NAME.replaceAll(" ","","g")}`,search:`?proId=${item[0].QF_MERCHANT_PERSONNEL_PRID}`},{ state: {proData:item[0],proChecked:checked['checked_'+ item[0].QF_MERCHANT_PERSONNEL_PRID] ? true : false,
                        categories:categories,subCategories:subCategories,
                        addressDetails:
                     {
                        totalAddress: totalAddress,
                        addressLine1: addressLine1,
                        country: country,
                        city: city,
                        stateOrProvince: stateOrProvince,
                        zipcode: zipcode,
                        district: district,
                        latitude: latitude,
                        longitude: longitude,
                        currentAddress: state.address.currentAddress,
                        searchAddress: state.address.searchAddress,
                        currentLocationDenied: state.address.currentLocationDenied
                     },
                     offers:item,allServices:nearByPros,proChecked:checked['checked_'+ item[0].QF_MERCHANT_PERSONNEL_PRID] ? true : false} })}/>
                  )
               })
            }
         </Carousel>
      )
   }
   let  width  = window.innerWidth
// ......end of pro cards carousel
   const otpHandleChange = (otp) => setOtp(otp)

   // console.log('isMobile',isMobileView)
   let widthCal;
   if (categories.length !== 0 && !isMobileView && !isTabView) {
      // if (categories.length == 4) {
      //    widthCal = '55%'
      // } else {
         widthCal = '80%'
      // }
   }
   if (isMobileView) {
      widthCal = '100%'
   }
   if(isTabView){
      widthCal = '95%'
   }
   // Address related functions

   const handleChange = address => {
      setTotalAddress(address)
      // console.log('address',address)
      // setSearchText(address)
      if(address.length>2){
         setCurrentLocModal(false)
      }
   };
  const handleSelect = async(address) => {
      // console.log('address',address)
      searchAddRef.current.blur();
      setMapViewModalWeb(true)
      // setAddressUpDated(true)
      setCurrentLocModal(false)
      geocodeByAddress(address)
        .then(results => {
         //   setSearchText(results[0])
         getLatLng(results[0])
           .then(latlng =>{
               // console.log('Successfully got latitude and longitude', latlng)
               setLatitude(latlng.lat)
               setLongitude(latlng.lng)
               if(latlng.lat!==null && latlng.lng!==null){
                  let region = {
                     center: {
                        lat:latlng.lat,
                        lng:latlng.lng
                     },
                     zoom: 15
                  }
                  // mapRef.current.animateToRegion(region);
                  setDefaultProps(region)
                 fetchAddress(latlng.lat,latlng.lng)
               //   saveLocationsInLocal(results[0].formatted_address,latlng.lat,latlng.lng)
               }
            }).then(latLng => console.log('Success', latLng))
            .catch(error => console.error('Error', error)); 
         })
  };
const fetchAddress = async(latitude,longitude) => {
   // setCityInValid(false)
   clearCart(dispatch)
   setloadingL(true)
   setloadingL1(true)
   setRecentLocationsHide(false)
   setAddressSearchModal(false)
   setAddressLine1('')
   setCountry('')
   setCity('')
   setZipcode('')
   setStateOrProvince('')
   setDistrict('')
   // console.log(latitude,longitude)
   if (latitude !== null && longitude !== null) {
      setCurrentAddress('')
      const LocationStr = latitude + "," + longitude;
      let ApiURL = "https://maps.googleapis.com/maps/api/geocode/json?";
      let params = `latlng=${LocationStr}&key=AIzaSyC6sHvN36SBHhSQymZ3d17MwtTeql16VbQ`;
      let finalApiURL = `${ApiURL}${encodeURI(params)}`;
      let value = await getAddressfromIP(finalApiURL).then(val => {
         //  console.log('status', val.status)
         //  console.log('result main', val.results[0].formatted_address)
         //  console.log('results 0' ,val.results)
          setTotalAddress(val.results[0].formatted_address)
          setSearchText('')
          if(val.status === 'OK'){
              if(val.results.length !== 0){
                  let countryCheck='',totalAddress=val.results[0].formatted_address,city,zipcode,country,stateOrProvince,district,addressLine1C;
                  // console.log('total address',totalAddress)
                          val.results[0].address_components.map((item, index) => {
                              if (item.types[0] == "route") {
                                  setAddressLine1(item.long_name)
                                  addressLine1C=item.long_name
                              }
                              else if (item.types[0] == "neighborhood") {
                                  if (addressLine1 == '') {
                                      setAddressLine1(item.long_name)
                                      addressLine1C=item.long_name
                                  }
                                  else if (!isNaN(Number(addressLine1))) {
                                      // let street_route = addressLine1.concat(' ').concat(item.long_name)
                                      // setAddressLine1(prevState => ([...prevState,...street_route]))
                                      setAddressLine1(item.long_name)
                                      addressLine1C=item.long_name
                                  }
                                  else {
                                      // let street_route = addressLine1.concat(', ').concat(item.long_name)
                                      // setAddressLine1(prevState => ([...prevState,...street_route]))
                                      setAddressLine1(item.long_name)
                                      addressLine1C=item.long_name
                                  }
                              }
                              else if (item.types[0] == "political") {
                                  if (addressLine1 == '') {
                                      setAddressLine1(item.long_name)
                                      addressLine1C=item.long_name
                                  }
                                  else {
                                      // let street_route = addressLine1.concat(', ').concat(item.long_name)
                                      // setAddressLine1(prevState => ([...prevState,...street_route]))
                                      setAddressLine1(item.long_name)
                                      addressLine1C=item.long_name
                                  }
                              }else if(item.types[0] == "establishment"){
                                 if (addressLine1 == '') {
                                    setAddressLine1(item.long_name)
                                    addressLine1C=item.long_name
                                }
                                else {
                                    // let street_route = addressLine1.concat(', ').concat(item.long_name)
                                    // setAddressLine1(prevState => ([...prevState,...street_route]))
                                    setAddressLine1(item.long_name)
                                    addressLine1C=item.long_name
                                }
                              }
                              else if(item.types[0] == "administrative_area_level_3" || item.types[0] == "administrative_area_level_2"){
                                  setDistrict(item.long_name)
                                  district=item.long_name
                              }
                              else if (item.types[0] == "administrative_area_level_1") {
                                  setStateOrProvince(item.long_name)
                                  stateOrProvince=item.long_name
                                  // console.log("state", item.long_name)
                              }
                              else if (item.types[0] == "country") {
                                  countryCheck=item.long_name
                                  setCountry(item.long_name)
                                  country=item.long_name
                                  // console.log("country", item.long_name)
                              }
                              else if (item.types[0] == "postal_code") {
                                  setZipcode(item.long_name)
                                  zipcode=item.long_name
                                  // console.log("zipcode", item.long_name)
                              }
                              else {
                                  console.log("else")
                              } 
                          })
                          if(countryCheck.includes('India')){
                              val.results.map(item1=>{
                                  // console.log("city", item1)
                                  if (item1.types[0] == "locality") {
                                      setCity(item1.address_components[0].long_name)
                                      city=item1.address_components[0].long_name
                                      // console.log("city", item1.long_name)
                                  }
                              })
                      
                          }else{
                              val.results[0].address_components.map((item1,index)=>{
                                  if (item1.types[0] == "locality") {
                                      setCity(item1.long_name)
                                      city=item1.long_name
                                      // console.log("city", item1.long_name)
                                  }
                              })
                          }
                          setloadingL1(false)
                          setloadingL(false)
              }
          }
      }).catch((error) => {
          console.log(error);
      });
   
      async function getAddressfromIP(finalApiURL) {
          let fetchResult = await fetch(finalApiURL); // call API
          let results = await fetchResult.json();
          return results;
      }
   }
}
const fetchAddressRecent = async(latitude,longitude) =>{
   clearCart(dispatch)
   setloadingL(true)
   setloadingL1(true)
   setRecentLocationsHide(false)
   setAddressSearchModal(false)
   setAddressLine1('')
   setCountry('')
   setCity('')
   setZipcode('')
   setStateOrProvince('')
   setDistrict('')
   // console.log(latitude,longitude)
   if (latitude !== null && longitude !== null) {
      setCurrentAddress('')
      const LocationStr = latitude + "," + longitude;
      let ApiURL = "https://maps.googleapis.com/maps/api/geocode/json?";
      let params = `latlng=${LocationStr}&key=AIzaSyC6sHvN36SBHhSQymZ3d17MwtTeql16VbQ`;
      let finalApiURL = `${ApiURL}${encodeURI(params)}`;
      let value = await getAddressfromIP(finalApiURL).then(val => {
         //  console.log('status', val.status)
         //  console.log('result main', val.results[0].formatted_address)
         //  console.log('results 0' ,val.results)
          setTotalAddress(val.results[0].formatted_address)
          setSearchText('')
          if(val.status === 'OK'){
              if(val.results.length !== 0){
                  setloadingL1(false)
                  setloadingL(false)
                  let countryCheck='',totalAddress=val.results[0].formatted_address,city,zipcode,country,stateOrProvince,district,addressLine1C;
                  // console.log('total address',totalAddress)
                          val.results[0].address_components.map((item, index) => {
                              if (item.types[0] == "route") {
                                  setAddressLine1(item.long_name)
                                  addressLine1C=item.long_name
                              }
                              else if (item.types[0] == "neighborhood") {
                                  if (addressLine1 == '') {
                                      setAddressLine1(item.long_name)
                                      addressLine1C=item.long_name
                                  }
                                  else if (!isNaN(Number(addressLine1))) {
                                      // let street_route = addressLine1.concat(' ').concat(item.long_name)
                                      // setAddressLine1(prevState => ([...prevState,...street_route]))
                                      setAddressLine1(item.long_name)
                                      addressLine1C=item.long_name
                                  }
                                  else {
                                      // let street_route = addressLine1.concat(', ').concat(item.long_name)
                                      // setAddressLine1(prevState => ([...prevState,...street_route]))
                                      setAddressLine1(item.long_name)
                                      addressLine1C=item.long_name
                                  }
                              }
                              else if (item.types[0] == "political") {
                                  if (addressLine1 == '') {
                                      setAddressLine1(item.long_name)
                                      addressLine1C=item.long_name
                                  }
                                  else {
                                      // let street_route = addressLine1.concat(', ').concat(item.long_name)
                                      // setAddressLine1(prevState => ([...prevState,...street_route]))
                                      setAddressLine1(item.long_name)
                                      addressLine1C=item.long_name
                                  }
                              }else if(item.types[0] == "establishment"){
                                 if (addressLine1 == '') {
                                    setAddressLine1(item.long_name)
                                    addressLine1C=item.long_name
                                }
                                else {
                                    // let street_route = addressLine1.concat(', ').concat(item.long_name)
                                    // setAddressLine1(prevState => ([...prevState,...street_route]))
                                    setAddressLine1(item.long_name)
                                    addressLine1C=item.long_name
                                }
                              }
                              else if(item.types[0] == "administrative_area_level_3" || item.types[0] == "administrative_area_level_2"){
                                  setDistrict(item.long_name)
                                  district=item.long_name
                              }
                              else if (item.types[0] == "administrative_area_level_1") {
                                  setStateOrProvince(item.long_name)
                                  stateOrProvince=item.long_name
                                  // console.log("state", item.long_name)
                              }
                              else if (item.types[0] == "country") {
                                  countryCheck=item.long_name
                                  setCountry(item.long_name)
                                  country=item.long_name
                                  // console.log("country", item.long_name)
                              }
                              else if (item.types[0] == "postal_code") {
                                  setZipcode(item.long_name)
                                  zipcode=item.long_name
                                  // console.log("zipcode", item.long_name)
                              }
                              else {
                                  console.log("else")
                              } 
                          })
                          if(countryCheck.includes('India')){
                              val.results.map(item1=>{
                                  // console.log("city", item1)
                                  if (item1.types[0] == "locality") {
                                      setCity(item1.address_components[0].long_name)
                                      city=item1.address_components[0].long_name
                                      // console.log("city", item1.long_name)
                                  }
                              })
                      
                          }else{
                              val.results[0].address_components.map((item1,index)=>{
                                  if (item1.types[0] == "locality") {
                                      setCity(item1.long_name)
                                      city=item1.long_name
                                      // console.log("city", item1.long_name)
                                  }
                              })
                          }
                          storeAddress(totalAddress,addressLine1C,country,city,stateOrProvince,zipcode,district,latitude,longitude,false,true,state.address.currentLocationDenied,dispatch,state)                          
                          if(totalAddress!==null) {
                             saveLocationsInLocal(totalAddress,latitude,longitude)
                          }
              }
          }
      }).catch((error) => {
          console.log(error);
      });
   
      async function getAddressfromIP(finalApiURL) {
          let fetchResult = await fetch(finalApiURL); // call API
          let results = await fetchResult.json();
          return results;
      }
   }
}
const saveLocationsInLocal = async(recentSearches,latitudeValue,longitudeValue) => {
   // console.log('pressed',recentSearches,latitudeValue,longitudeValue)
   
   try{
      setAddressUpDated(true)
      dispatch({type:'SET_ADDRESS_UPDATED',addressUpdated:!state.addressUpdated})
      setSaveAddressModal(false)
      setEditNewAddressModal(false)
      setloading(false)
      setloadingL1(false)
      setMapViewModalWeb(false)
      setMapViewModal(false)
      setAddressSearchModal(false)
       clearCart(dispatch)
           let getValue=await localStorage.getItem("qf:recent:address")
               // console.log('response',getValue);
               if(getValue === null){
                   let recentSearchesArray1=[]
                  //  console.log("recenet search array", recentSearches)
                   recentSearchesArray1.push({address:recentSearches,latitude:latitudeValue,longitude:longitudeValue})
                   let setValue=await localStorage.setItem("qf:recent:address",JSON.stringify(recentSearchesArray1))
                   console.log('set value',setValue)
               }else{
                   let array=[];
                   JSON.parse(getValue).map(item=>{
                       return array.push(item)
                   })
                   // console.log('arr1',array)
                   if(array.length === 5){
                       if(recentSearches){
                           let redundant=false;
                           array.map(i1=>{
                               if(i1.latitude === latitudeValue){
                                   return redundant=true;
                               }
                           })
                           if(redundant){
                               let setValue1=await localStorage.setItem("qf:recent:address",JSON.stringify(array))
                               let getValue1=await localStorage.getItem("qf:recent:address")
                               // console.log('recent array',JSON.parse(getValue1))
                           }else{
                               array.shift();
                               array.push({address:recentSearches,latitude:latitudeValue,longitude:longitudeValue});
                               // console.log('arr after push',array)
                               let setValue1=await localStorage.setItem("qf:recent:address",JSON.stringify(array))
                               let getValue1=await localStorage.getItem("qf:recent:address")
                              //  console.log('recent array',JSON.parse(getValue1))
                           }
                       }else{
                           let setValue1=await localStorage.setItem("qf:recent:address",JSON.stringify(array))
                           let getValue1=await localStorage.getItem("qf:recent:address")
                           // console.log('in no recent',JSON.parse(getValue1)) 
                       }
                   }else{
                       if(array.length < 5){
                           let redundant=false;
                           array.map(i1=>{
                               if(i1.latitude === latitudeValue){
                                   return redundant=true;
                               }
                           })
                           if(redundant){
                               let setValue1=await localStorage.setItem("qf:recent:address",JSON.stringify(array))
                               let getValue1=await localStorage.getItem("qf:recent:address")
                              //  console.log('recent array in else',JSON.parse(getValue1))
                           }else{
                               array.push({address:recentSearches,latitude:latitudeValue,longitude:longitudeValue})
                               let setValue1=await localStorage.setItem("qf:recent:address",JSON.stringify(array))
                               let getValue1=await localStorage.getItem("qf:recent:address")
                              //  console.log('recent array in else',JSON.parse(getValue1))
                           }
                       }
                   }
               }
               // navigation.navigate('HomePage')
       
   }catch(err){
       console.log('err',err)
   }
}
const onClickConfirmLocation = async(recentSearches,latitudeValue,longitudeValue) => {
   console.log('pressed')
   
   try{
      
      if(currentAddress){
         setEditNewAddressModal(false)
         clearCart(dispatch)
         setAddressUpDated(true)
         setMapViewModalWeb(false)
         setMapViewModal(false)
         setAddressSearchModal(false)
         dispatch({type:'SET_ADDRESS_UPDATED',addressUpdated:!state.addressUpdated})
         //   console.log('no need to save in async storage')
         //   navigation.navigate('HomePage')
         // if(isMobileView){
            storeAddress(totalAddress,addressLine1,country,city,stateOrProvince,zipcode,district,latitude,longitude,true,false,state.address.currentLocationDenied,dispatch,state)
            // dispatch({type:'SET_ADDRESS_UPDATED',addressUpdated:!state.addressUpdated})
         // }
       }else{
         // if(isMobileView){
            storeAddress(totalAddress,addressLine1,country,city,stateOrProvince,zipcode,district,latitude,longitude,false,true,state.address.currentLocationDenied,dispatch,state)
            // dispatch({ type: 'ADDRESS_STORE',totalAddress:totalAddress,addressLine1:addressLine1,country:country,city:city,stateOrProvince:stateOrProvince,zipcode:zipcode,district:district,latitude:latitude,longitude:longitude,currentAddress:false,searchAddress:true,currentLocationDenied:state.address.currentLocationDenied})
         // }
           // let removeValue=await localStorage.removeItem("qf:recent:address")
           saveLocationsInLocal(recentSearches,latitudeValue,longitudeValue)
           
       }
   }catch(err){
       console.log('err',err)
   }
}
//   search related code

const searchBoxOfferhandler = (event)=>{
   try {
      setTotalAddress(state.address.totalAddress)
      setCurrentLocModal(false)
   //   console.log("value",event.target.value)
     setSearchTextOffer(event.target.value)
     let filteredOffers = DeepClone(filteredArray),filteredOffers1 = DeepClone(filteredArray), matchesArr = [],catsFinalData=DeepClone(categories)
     let value = event.target.value ? event.target.value.trim() : event.target.value
     if (value.length > 2) {
         setShowSearchDropDown(true)
         // console.log("filtered",filteredOffers)
         catsFinalData.map((insideItem) => {
            if (insideItem.category_name.search(new RegExp(value, 'i')) !== -1) {
               insideItem.type = 'Category'
               // console.log("matchesArr in cat filter",insideItem);
               matchesArr.push(insideItem)
            }
         })
         filteredOffers.map((insideItem) => {
           // console.log('item123',insideItem)
           if (insideItem[0].MERCHANT_NAME.search(new RegExp(value, 'i')) !== -1) {
               insideItem[0].type='Professional'
               // console.log("matchesArr in pro filter",insideItem);
               matchesArr.push(insideItem[0])
           }
         })
       filteredOffers1.map((insideItem) => {
         // console.log('item service',insideItem)
         insideItem.map(serviceName=>{
           if (serviceName.SERVICE_NAME.search(new RegExp(value, 'i')) !== -1) {
               serviceName.type='Service'
               // console.log("matchesArr in service filter",serviceName);
               matchesArr.push(serviceName)
           }
         })
       })
       // console.log("matchesArr outside",matchesArr);
       let catArray,proArray,servicesArray;
       catArray=matchesArr.filter(item=>{
         return item.type == "Category"
       })
       proArray=matchesArr.filter(item=>{
         return item.type == "Professional"
       })
       servicesArray=matchesArr.filter(item=>{
         return item.type == 'Service'
       })
       // console.log('all',proArray,catArray,servicesArray)
       let finalArray=[];
       if(catArray.length!==0){
         // console.log('cat dataaaa',catArray)
         catArray = catArray.filter((v, i, a) => a.findIndex(t => ((t.category_name === v.category_name))) === i)
         catArray.map(item=>{
           finalArray.push(item);            
         })
       }
       if(proArray.length!==0){
         // console.log('pro dataaaa',proArray)
         proArray.map(item=>{
           finalArray.push(item);
         })
       }
       if(servicesArray.length!==0){
         servicesArray = servicesArray.filter((v, i, a) => a.findIndex(t => ((t.SERVICE_NAME === v.SERVICE_NAME))) === i)
         servicesArray.map(item=>{
           finalArray.push(item);                        
         })
       }
      //  console.log('final array',finalArray)
       if(finalArray.length!==0){
         setSearchDropdownArray(finalArray)          
       }
     }
     else {
       setShowSearchDropDown(false)
       setClickOnOfferSearch(false)
       if(!didClearSearch){
         setDidClearSearch(true)
       }
       setSearchDropdownArray([])
     }
 }
 catch (cErr) {
     console.log(cErr)
 }
 }
 const onPressSearchDropDown = (data) => {
   // console.log("search_clicked_value",data)
   // KeyboardEvent.dismiss()
   if(data.type=='Category'){
      // navigate('/professionalUnderCategory',{state:{categories,subCategories,nearByPros,catItem:data,mpidArr,catPrid:data.QF_SERVICE_CATEGORY_PRID,fromExplore:false}})
      navigate({
         pathname: `/allprofessionals`,
         search: `?category=${data.category_name.replaceAll(" ","","g")}&categoryId=${data.QF_SERVICE_CATEGORY_PRID}`
       },{state:{categories,subCategories,nearByPros,catItem:data,mpidArr,catPrid:data.QF_SERVICE_CATEGORY_PRID,fromExplore:false,
         addressDetails:
         {
            totalAddress: totalAddress,
            addressLine1: addressLine1,
            country: country,
            city: city,
            stateOrProvince: stateOrProvince,
            zipcode: zipcode,
            district: district,
            latitude: latitude,
            longitude: longitude,
            currentAddress: state.address.currentAddress,
            searchAddress: state.address.searchAddress,
            currentLocationDenied: state.address.currentLocationDenied
         }}})
   }
   if(data.type=='Professional'){
     let offers;
     offers=nearByPros.filter(item=>{
       return item[0].QF_MERCHANT_PERSONNEL_PRID == data.QF_MERCHANT_PERSONNEL_PRID
     })
     if(offers.length!==0){
      navigate({pathname:`/allservices/${data.MERCHANT_NAME.replaceAll(" ","","g")}`,search:`?proId=${data.QF_MERCHANT_PERSONNEL_PRID}`},{ state: {proData:data,offers:offers[0],proChecked:checked['checked_'+ data.QF_MERCHANT_PERSONNEL_PRID] ? true : false,
         categories:categories,subCategories:subCategories,
         addressDetails:
      {
         totalAddress: totalAddress,
         addressLine1: addressLine1,
         country: country,
         city: city,
         stateOrProvince: stateOrProvince,
         zipcode: zipcode,
         district: district,
         latitude: latitude,
         longitude: longitude,
         currentAddress: state.address.currentAddress,
         searchAddress: state.address.searchAddress,
         currentLocationDenied: state.address.currentLocationDenied
      },allServices:nearByPros} })
      //  navigation.navigate("ProfessionalPage",{proData:data,offers:offers[0]})
     }
   }
   if(data.type=='Service'){
       let filteredOffers = DeepClone(filteredArray), matchesArr = []
       filteredOffers.map((insideItem) => {
         insideItem.map(clickedItem=>{
           if(clickedItem.SERVICE_NAME === data.SERVICE_NAME) {
             matchesArr.push([clickedItem])
             }
         })
      })
      //  console.log("matchesArr after click",matchesArr);
       if(matchesArr.length!==0){
            navigate({
            pathname: `/allprofessionals`,
            search: `?category=${data.category_name.replaceAll(" ","","g")}&categoryId=${data.QF_SERVICE_CATEGORY_PRID}`
            },{state:{selectedServices:matchesArr,categories,subCategories,nearByPros,catItem:data,mpidArr,catPrid:data.QF_SERVICE_CATEGORY_PRID,fromExplore:false,fromSearch:true,
               addressDetails:
               {
                  totalAddress: totalAddress,
                  addressLine1: addressLine1,
                  country: country,
                  city: city,
                  stateOrProvince: stateOrProvince,
                  zipcode: zipcode,
                  district: district,
                  latitude: latitude,
                  longitude: longitude,
                  currentAddress: state.address.currentAddress,
                  searchAddress: state.address.searchAddress,
                  currentLocationDenied: state.address.currentLocationDenied
               }}})
            // navigate('/professionalUnderCategory',{state:{selectedServices:matchesArr,categories,subCategories,nearByPros,catItem:data,mpidArr,catPrid:data.QF_SERVICE_CATEGORY_PRID,fromExplore:false,fromSearch:true}})
       }
   }
   setSearchTextOffer('')
   setShowSearchDropDown(false)
   setClickOnOfferSearch(true)
   setDidClearSearch(false)
}

const clearSearchResults = () => {
 setClickOnOfferSearch(true)
 setDidClearSearch(true)
 setShowSearchDropDown(false)
 setSearchTextOffer('')
 setSearchDropdownArray([])
}
 
const onClickSeebyMap = () =>{
   if(isMobileView){
      setSeeByMapModalMobile(true)
   }else{
      setSeeByMapModalWeb(true)
   }
   // navigate('/NearByProfessionalMapView',{ state: {nearByPros:nearByPros,mpidArr} })
}
const currentModalShow = () =>{
   if(currentLocModal){
      setTotalAddress(state.address.totalAddress)
   }else{
      setTotalAddress('')
   }
   setShowSearchDropDown(false)
   setSearchTextOffer('')
   setCurrentLocModal(!currentLocModal)
}
const addressSearchModalMobile = () =>{
   // searchInput.current.focus()
   // searchAddRef1.current.blur();
   setTotalAddress(state.address.totalAddress)
   setAddressSearchModal(false)
   setSearchText('')
   setRecentLocationsHide(false)
}
const handleChangeMobile = (address) =>{
   setSearchText(address)
   setTotalAddress(address)
   if(address.length > 0){
      setRecentLocationsHide(true)
   }else{
      setRecentLocationsHide(false)
   }
}
const handleSelectMobile = (address) =>{
   // console.log('address',address)
   setMapViewModal(true)
   setAddressUpDated(true)
   geocodeByAddress(address)
     .then(results => {
      //   setSearchText(results[0])
      getLatLng(results[0])
        .then(latlng =>{
            // console.log('Successfully got latitude and longitude', latlng)
            setLatitude(latlng.lat)
            setLongitude(latlng.lng)
            if(latlng.lat!==null && latlng.lng!==null){
               setSearchText(results[0].formatted_address)
               let region = {
                  center: {
                     lat:latlng.lat,
                     lng:latlng.lng
                  },
                  zoom: 15
               }
               // mapRef.current.animateToRegion(region);
               setDefaultProps(region)
              fetchAddress(latlng.lat,latlng.lng)
            //   saveLocationsInLocal(results[0].formatted_address,latlng.lat,latlng.lng)
            }
         }).then(latLng => console.log('Success', latLng))
         .catch(error => console.error('Error', error)); 
      })
}
const onClickRecentSearch = (item,saveAddressClick) =>{
   // if(isMobileView){
   //    setAddressUpDated(false)
   // }else{
      
   // }
   if(saveAddressClick){
      setSaveAddressModal(false)
      setAddressUpDated(false)
   }else{
      setAddressUpDated(true)
   }
   let latitude=item.latitude ? item.latitude : Number(item.CONSUMER_ADDRESS_LAT)
   let longitude=item.longitude ? item.longitude  : Number(item.CONSUMER_ADDRESS_LONG)
   setloadingL1(true)
   let region = {
      center: {
         lat:latitude,
         lng:longitude
      },
      zoom: 15
   }
   // mapRef.current.animateToRegion(region);
   setDefaultProps(region)
   setCurrentLocModal(false)
   setAddressSearchModal(false)
   setLatitude(latitude)
   setLongitude(longitude)
   fetchAddressRecent(latitude,longitude)
}
const addressClickMobile= () =>{
   setAddressSearchModal(true)
   // searchAddRefMob.current.blur();
   // setMapViewModal(true)
}
const LocationPinChangingHandler = (event) => {
   console.log("event",event)
   let region = {
      center: {
         lat: event.lat,
         lng: event.lng
      },
      zoom: 15
   }
   setDefaultProps(region)
   setLatitude(event.lat)
   setLongitude(event.lng)
   fetchAddress(event.lat,event.lng)
}
let seeByMapModalView=(
   <Modal isOpen={seeByMapModalWeb} size={'xl'} centered style={{borderRadius:10,width:isTabView ? '100%' :'50%'}} toggle={()=>setSeeByMapModalWeb(false)}>
      <div style={{position:'absolute',zIndex:100,right:20,top:20,backgroundColor:'#fff',borderRadius:50,width:40,height:40,justifyContent:'center',display:'flex',alignItems:'center'}}>
         <img src={CloseIcon} style={{width:25,height:25}} onClick={()=>setSeeByMapModalWeb(false)}/>
      </div>
      <div style={{padding:10,borderRadius:10}}>
         <NearByProfessionalMapView nearByPros={nearByPros} mpidArr={mpidArr} mobileView={false}/>
      </div>
   </Modal>
)
let seeByMapModalViewMobile=(
   <Modal isOpen={seeByMapModalMobile} size={'xl'} fullscreen={'xl'} toggle={()=>setSeeByMapModalMobile(false)}>
      <div style={{padding:'20px 16px 20px 16px',display:'flex',flexDirection:'row',alignItems:'center'}}>
         <img src={arrowBack} style={{transform: 'rotate(-90deg)',width:20,height:20}} onClick={()=>setSeeByMapModalMobile(false)}/>
         <text className='nearByProMapText' style={{marginLeft:15}}>Professionals nearby</text>
      </div>
      {/* <ModalHeader toggle={()=>setSeeByMapModalMobile(false)}>Professionals nearby</ModalHeader> */}
      <div>
         <NearByProfessionalMapView nearByPros={nearByPros} mpidArr={mpidArr} mobileView={true}/>
      </div>
   </Modal>
)
const MapViewModalClose=()=>{
   setAddressSearchModal(true)
   setMapViewModal(false)
   if(state.address.currentAddress){
      setCurrentAddress(state.address.totalAddress)
   }else{
      setCurrentAddress('')
   }
   setTotalAddress(state.address.totalAddress)
   setStateOrProvince(state.address.stateOrProvince)
   setZipcode(state.address.zipcode)
   setDistrict(state.address.district)
   setCity(state.address.city)
   setCountry(state.address.country)
   setAddressLine1(state.address.addressLine1)
   setLatitude(state.address.latitude)
   setLongitude(state.address.longitude)
}
const MapViewModalCloseWeb=()=>{
   setCurrentLocModal(false)
   setMapViewModalWeb(false)
   // console.log(state.address.currentAddress)
   if(state.address.currentAddress){
      setCurrentAddress(state.address.totalAddress)
   }else{
      setCurrentAddress('')
   }
   setTotalAddress(state.address.totalAddress)
   setStateOrProvince(state.address.stateOrProvince)
   setZipcode(state.address.zipcode)
   setDistrict(state.address.district)
   setCity(state.address.city)
   setCountry(state.address.country)
   setAddressLine1(state.address.addressLine1)
   setLatitude(state.address.latitude)
   setLongitude(state.address.longitude)
}
let buttonDisabled=true;
if(addressLine1 === '' || city === '' || zipcode === '' || stateOrProvince === '' || addressLine1 === null || city === null || zipcode === null || stateOrProvince === null || addressLine1 === undefined || city === undefined || zipcode === undefined || stateOrProvince === undefined){
   buttonDisabled=true
}else{
   buttonDisabled=false
}
// console.log('buttonDisabled',buttonDisabled,addressLine1,city,zipcode,stateOrProvince)
 const defaultMapOptions = {
   fullscreenControl: false,
   zoomControl:false
 };
 let backArrowBg={
   width: 36,
   height: 36,
   backgroundColor: "#ffffff",
   shadowColor: "rgba(103, 52, 204, 0.1)",
   shadowOffset: {
     width: 0,
     height: 2
   },
   shadowRadius: 4,
   shadowOpacity: 1 ,
   borderRadius:20,
   display:'flex',
   justifyContent:'center',
   alignItems:'center',
   position:'absolute',
   left:10,
   top:20,
   zIndex:100
 }
 let backArrowBgWeb={
   width: 48,
   height: 48,
   backgroundColor: "#ffffff",
   shadowColor: "rgba(103, 52, 204, 0.1)",
   shadowOffset: {
     width: 0,
     height: 2
   },
   boxShadow: '0 2px 4px 0 rgba(103, 52, 204, 0.1)',
   backgroundColor: '#fff',
   shadowRadius: 4,
   shadowOpacity: 1 ,
   borderRadius:30,
   display:'flex',
   justifyContent:'center',
   alignItems:'center',
   position:'absolute',
   left:25,
   top:25,
   zIndex:100,
   cursor:'pointer'
 }
 let currLocBgWeb={
   width: 48,
   height: 48,
   backgroundColor: "#ffffff",
   shadowColor: "rgba(103, 52, 204, 0.1)",
   shadowOffset: {
     width: 0,
     height: 2
   },
   boxShadow: '0 2px 4px 0 rgba(103, 52, 204, 0.1)',
   backgroundColor: '#fff',
   shadowRadius: 4,
   shadowOpacity: 1 ,
   borderRadius:30,
   display:'flex',
   justifyContent:'center',
   alignItems:'center',
   position:'absolute',
   right:25,
   top:25,
   zIndex:100,
   cursor:'pointer'
 }
 
//  console.log('default props',defaultProps,editNewAddressModal)
let addressSearchMapView=(
   <Modal isOpen={mapViewModal} size={'xl'} fullscreen={'xl'}>
      {/* <div style={{padding:'20px 16px 20px 16px',display:'flex',flexDirection:'row',alignItems:'center'}}>
         <img src={arrowBack} style={{transform: 'rotate(-90deg)',width:20,height:20}} onClick={()=>MapViewModalClose()}/>
         <text className='nearByProMapText' style={{marginLeft:15,color:'#8c52ff'}}>Choose location</text>
      </div> */}
      <div style={backArrowBg}>
         <img src={arrowBack} style={{transform: 'rotate(-90deg)',width:20,height:20}} onClick={()=>MapViewModalClose()}/>
      </div>
      <div style={{ height: '100%', width: '100%',borderRadius:30}}>
         <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyC6sHvN36SBHhSQymZ3d17MwtTeql16VbQ" }}
            center={defaultProps.center}
            zoom={defaultProps.zoom}
            options={defaultMapOptions}
            ref={mapRef}
            // yesIWantToUseGoogleMapApiInternals={true}
            onClick={LocationPinChangingHandler}
         >
            <div lat={defaultProps.center.lat} lng={defaultProps.center.lng}>
               <IoLocationSharp size={40} color="red"/>
            </div>          
         </GoogleMapReact>
      </div>
      <div style={{ position: 'absolute',zIndex: 100,height:160,bottom:0,left:0,right:0,boxShadow: '0 4px 50px 0 rgba(0, 0, 0, 0.2)',backgroundColor: '#fff',borderTopLeftRadius:15,borderTopRightRadius:15,padding:'15px 10px 15px 10px'}}>
         {
            loadingL1 ?
            <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',padding:50}}>
               <ClipLoader widthUnit={"%"} size={20} loadings={loadingL1} color="#8c52ff" />
            </div>
            :
            <>
               <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:10,marginBottom:20}}>
                  <div style={{display:'flex',flexDirection:'row'}}>
                     <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <img src={locationcolorIcon} style={{width:24,height:24}}/>
                     </div>
                     <div style={{display:'flex',flexDirection:'column',marginLeft:10}}>
                     <text className='addressLine1_TextHome'>{ totalAddress.length > 28 ? totalAddress.substr(0,28) + '...' : totalAddress}</text>
                     <text className='subText_Blw_AddressLine1'>
                        {
                           stateOrProvince + (stateOrProvince ? ", " : "") + country + (country ? ", " : "") + zipcode 
                        }
                     </text>
                     </div>
                  </div>
                  {
                  state.token ?
                  <div style={{display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>EditNewAddressModalOpen()}>
                     <MdOutlineEdit size={20} color="#8c52ff"/>
                  </div> : null
                  }
               </div>
               <div style={{marginTop:30}}>   
                  <Button className="saveAdd_Btn_Home" disabled={buttonDisabled ? true : false} onClick={()=>onClickConfirmLocation(totalAddress,latitude,longitude)}>
                     <text className='saveAdd_Confm_Text'>Confirm Location</text>
                  </Button>
               </div>
            </>
         }
      </div>
      <div className='currentLocationIcon_View' onClick={()=>currentLocationFunc(true)} style={{cursor:'pointer'}}>
         <img src={CurrentLocationIconRed}/>
      </div>
   </Modal>
)
let addressSearchMapViewWeb=(
   <div  style={{height:'80vh',position:'relative',borderRadius:15,padding:5}}>
      <div style={backArrowBgWeb}>
         <img src={arrowBack} style={{ transform: 'rotate(-90deg)', width: 20, height: 20 }} onClick={() => MapViewModalCloseWeb()} />
      </div>
      <div style={currLocBgWeb} onClick={() => currentLocationFunc(true)}>
         <img src={CurrentLocationIconRed} />
      </div>
      <div style={{ height: '100%', width: '100%',borderRadius:15}}>
         <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyC6sHvN36SBHhSQymZ3d17MwtTeql16VbQ" }}
            center={defaultProps.center}
            zoom={defaultProps.zoom}
            options={defaultMapOptions}
            ref={mapRef}
            // yesIWantToUseGoogleMapApiInternals={true}
            onClick={LocationPinChangingHandler}
         >
            <div lat={defaultProps.center.lat} lng={defaultProps.center.lng}>
               <IoLocationSharp size={40} color="red" />
            </div>
         </GoogleMapReact>
      </div>
      <div style={{ position: 'absolute', zIndex: 100, height: 160, bottom: 20, left: '16%', right: '16%', boxShadow: '0 5px 17px 0 rgba(0, 0, 0, 0.25)', backgroundColor: '#fff', borderRadius: 15, padding: '20px 15px 20px 15px' }}>
         {
            loadingL1 ?
               <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: 50 }}>
                  <ClipLoader widthUnit={"%"} size={20} loadings={loadingL1} color="#8c52ff" />
               </div>
               :
               <>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, marginBottom: 20 }}>
                     <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                           <img src={locationcolorIcon} style={{ width: 24, height: 24 }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                           <text className='addressLine1_TextHome'>{totalAddress.length > 26 ? totalAddress.substr(0,26) + '...' : totalAddress}</text>
                           <text className='subText_Blw_AddressLine1'>
                              {
                                 stateOrProvince + (stateOrProvince ? ", " : "") + country + (country ? ", " : "") + zipcode
                              }
                           </text>
                        </div>
                     </div>
                     {
                        state.token ?
                           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' ,cursor:'pointer'}} onClick={() => EditNewAddressModalOpen()}>
                              <MdOutlineEdit size={20} color="#8c52ff" />
                           </div> : null
                     }
                  </div>
                  <div style={{ marginTop: 20 }}>
                     <Button className="saveAdd_Btn_Home" disabled={buttonDisabled ? true : false} onClick={() => onClickConfirmLocation(totalAddress, latitude, longitude)}>
                        <text className='saveAdd_Confm_Text'>Confirm Location</text>
                     </Button>
                  </div>
               </>
         }
      </div>
   </div>
)

let searchOfferDropDownItem;
   if (nearByPros.length !== 0 && filteredArray.length !== 0 && showSearchDropDown) {
     if (searchDropdownArray.length !== 0) {
      searchOfferDropDownItem= searchDropdownArray.map((item,index)=>{
         return (
            <div style={{zIndex:2000}}>
               {item.type === 'Category' ?
                  <div onClick={() => onPressSearchDropDown(item)} style={{ cursor:'pointer',backgroundColor: '#fff', paddingTop: 10, paddingBottom: 10,borderBottom:'1px solid rgba(216, 197, 252, 0.6)' }}>
                     <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 25 }}>
                        <div className={"imageCatStyle"}  style={{backgroundColor: catColors[item.category_name]}}>
                           <img src={item.category_image} style={{ width: 20, height: 20 }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection:'column',justifyContent: 'center', marginLeft: 22 }}>
                           <text className={"searchDropDowntextStyle"}>{item.category_name}</text>
                           <text className={"searchDropDownSubtextStyle"}>{item.type}</text>
                        </div>
                     </div>
                  </div>
                  : null}
               {item.type === 'Professional' ?
                  <div onClick={() => onPressSearchDropDown(item)} style={{cursor:'pointer', backgroundColor: '#fff',borderBottom:'1px solid rgba(216, 197, 252, 0.6)', paddingTop: 10, paddingBottom: 10 }}>
                     <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 25 }}>
                        <div className={"imageProStyle"}>
                           <img src={item.image_link} style={{ width: 44, height: 44, borderRadius: 7 }} />
                        </div>
                        <div style={{ display: 'flex',flexDirection:'column',justifyContent: 'center', marginLeft: 22 }}>
                           <text className={"searchDropDowntextStyle"}>{item.MERCHANT_NAME}</text>
                           <text className={"searchDropDownSubtextStyle"}>{item.type}</text>
                        </div>
                     </div>
                  </div>
                  : null}

               {item.type === 'Service' ?
                  <div onClick={() => onPressSearchDropDown(item)} style={{ cursor:'pointer',backgroundColor: '#fff', borderBottom:'1px solid rgba(216, 197, 252, 0.6)', paddingTop: 10, paddingBottom: 10 }}>
                     <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 25 }}>
                        <div className={"imageProStyle"}>
                           <img src={item.image_link} style={{ width: 44, height: 44, borderRadius: 7 }} />
                        </div>
                        <div style={{ display: 'flex',flexDirection:'column', justifyContent: 'center', marginLeft: 22 }}>
                           <text className={"searchDropDowntextStyle"}>{item.SERVICE_NAME}</text>
                           <text className={"searchDropDownSubtextStyle"}>{item.type}</text>
                        </div>
                     </div>
                  </div>
                  : null}
            </div>
         )
            })
     }
     else {
         searchOfferDropDownItem = (
             <div style={{ marginLeft: 20,marginRight:20, height: 150, justifyContent: "center", alignItems: "center", zIndex: 2000 ,display:'flex'}}>
                 <text style={{ fontSize: 12 }}>{"No results matching your search"}</text>
             </div>
         )
     }
 }
let recentSearchesDisplay;
if(recentSearchesArray.length!==0){
   recentSearchesDisplay=recentSearchesArray.map((item)=>{
      // console.log(item)
      return (
         <div style={{cursor:'pointer',flexDirection:'row',display:'flex',alignItems:'center',paddingTop:8,paddingBottom:8}} onClick={()=>onClickRecentSearch(item)}>
            <img src={RecentLocIcon} style={{width:18,height:18}}/>
            <text className='suggestion_Text' style={{paddingLeft:5}}>{isMobileView ? (item.address.length > 38 ? item.address.substr(0,38) + "..." : item.address) : (item.address.length > 35 ? item.address.substr(0,35) + "..." : item.address)  }</text>
         </div>
      )
   })
}
let savedAddressesDisplay;
if(fetchedDataResponse.length!==0){
   savedAddressesDisplay=fetchedDataResponse.map((item)=>{
      // console.log(item)
      return (
         <div style={{cursor:'pointer',flexDirection:'row',display:'flex',alignItems:'center',position:'relative',paddingTop:8,paddingBottom:8}}>
            <MdOutlineEdit style={{position:'absolute',right:5,top:14,cursor:'pointer'}} size={14} color="#747474" onClick={()=>saveAddressModalOpen(item)}/>
            <div onClick={()=>onClickRecentSearch(item)}>
               <img src={GreyLocIcon} style={{width:18,height:18}}/>
               <text className='suggestion_Text' style={{paddingLeft:5}}>{isMobileView ? (item.CONSUMER_ADDRESS.length > 38 ? item.CONSUMER_ADDRESS.substr(0,38) + "..." : item.CONSUMER_ADDRESS) : (item.CONSUMER_ADDRESS.length > 35 ? item.CONSUMER_ADDRESS.substr(0,35) + "..." : item.CONSUMER_ADDRESS)  }</text>
            </div>
         </div>
      )
   })
}
let SearchAddressModal=(
   <Modal isOpen={addressSearchModal} size={'xl'} fullscreen={'xl'}  autoFocus={false} style={{overflow:'hidden'}}>
      <div style={{padding:16}}>
         {/* <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
            <text className='selectLocation_Text'>Select a location</text>
            <img onClick={addressSearchModalMobile} src={CloseIcon} style={{cursor:'pointer'}}/>
         </div> */}
         <div style={{ padding: '10px 0px 10px 0px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <img src={arrowBack} style={{ transform: 'rotate(-90deg)', width: 20, height: 20 }} onClick={() => addressSearchModalMobile()} />
            <text className='nearByProMapText' style={{ marginLeft: 15, color: '#8c52ff' }}>Choose location</text>
         </div>
         <div style={{marginTop:20}}>
            <div style={{position:'relative',zIndex:100}}>
               <PlacesAutocomplete
                  // ref={searchAddRef}
                  value={searchText}
                  // autoFocus={true}
                  onChange={handleChangeMobile}
                  onSelect={handleSelectMobile}>
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                     <div>
                        <Input
                           
                           style={{paddingLeft:50,width:'100%'}}
                           value={searchText}
                           autoFocus={true}
                           // innerRef={searchInput}
                           {...getInputProps({
                              // autoFocus:true,
                              placeholder: 'Search any address',
                              className: 'location-search-input-sv2-mobile shadow-none'
                           })}
                        />
                        {/* {console.log('suggestion', suggestions)} */}
                        <img src={searchIcon} style={{ position: 'absolute', left: 10, bottom: 11 }} />
                        <div style={{
                           border: suggestions.length !== 0 ? '2px solid #fcfcfc' : 0,
                           height: suggestions.length !== 0 ? 250 : 0,
                           overflow: suggestions.length !== 0 ? 'scroll' : 0,
                           top: suggestions.length !== 0 ? 150 : 0,
                           bottom: 0,
                           position: 'absolute',
                           zIndex: 2000,
                           width:'100%',
                           border: 'none'
                        }}
                           >
                           {/* {loading && <div>Loading...</div>} */}
                           {suggestions.map(suggestion => {
                              const className = suggestion.active
                                 ? 'suggestion-item--active'
                                 : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                 ? { backgroundColor: '#c5c5c5', cursor: 'pointer' }
                                 : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              return (
                                 <div
                                    {...getSuggestionItemProps(suggestion, {
                                       className,
                                       style: { display: 'flex', flexDirection: 'row',alignItems:'center', paddingTop: 8, paddingBottom: 8 }
                                    })}
                                  >
                                    {/* {this.state.showDropdown === true ? <div>{suggestion.description}</div> : null}  */}
                                    {/* {console.log('data', suggestion.description)} */}
                                    <img src={GreyLocIcon} style={{width:20,height:20}}/>
                                    <text className='suggestion_Text' style={{paddingLeft:10,cursor:'pointer'}}>{suggestion.description.length > 45 ? suggestion.description.substr(0,45) + '...' : suggestion.description}</text>
                                 </div>
                              );
                           })}
                     </div>
                        
                        
                     </div>
                  )}
               </PlacesAutocomplete>
            </div>
            <div style={{ flexDirection: 'row', display: 'flex',marginTop:20,cursor:'pointer' }} onClick={()=>currentLocationFunc(true)}>
               <img src={CurrentLocationIcon} style={{ width: 20, height: 20 }} />
               <div style={{ flexDirection: 'column', display: 'flex', marginLeft: 16 }}>
                  {/* <div> */}
                  <text className='currentLoc_MainText' style={{ marginBottom: 5 }}>My Current location</text>
                  {/* </div> */}
                  {/* <div> */}
                  <text className='currentLoc_subText'>{curLocationDenied ? '' : (currentAddress ? currentAddress : '')}</text>
                  {/* </div> */}
               </div>
            </div>
            <hr/>
            <div style={{overflow:'scroll',height:450}}>
               {
                  !recentLocationsHide &&
                  (recentSearchesArray.length !==0 ?
                  <>
                     <div style={{ paddingBottom: 5 }}>
                        <text className='recentLocation_Text'>Recent Locations</text>
                     </div>
                     <div style={{ paddingTop: 5}}>
                        {recentSearchesDisplay}
                     </div>
                  </>
                  : null)
               }
               {
                  !recentLocationsHide &&
                  (fetchedDataResponse.length !==0 ?
                  <>
                     <div style={{ paddingBottom: 5 }}>
                        <text className='recentLocation_Text'>Saved Locations</text>
                     </div>
                     <div style={{ paddingTop: 5}}>
                        {savedAddressesDisplay}
                     </div>
                  </>
                  : null)
               }
            </div>
         </div>
      </div>
   </Modal>
)
const saveAddressModalOpen = (item) =>{
   setCurrentLocModal(false)
   setSelectedTag(item.CONSUMER_ADDRESS_SELECTED_TAG)
   placesArray.map(item1=>{
       if(item1.place === item.CONSUMER_ADDRESS_SELECTED_TAG){
       // console.log('items yes',item.id === data.id ,item.id,data.id)
           return setClickedValue({
               ['clickedValue_'+ item1.id]:true   
           })
       }
   })
   setAllValues({
       ...allValues,
       cmptAddress:item.CONSUMER_ADDRESS_LINE2,
       floor:item.CONSUMER_ADDRESS_LINE3,
       landmark:item.CONSUMER_ADDRESS_LINE4
   })
   setEditAddress(item)
   setSaveAddressModal(true)
   setStateOrProvince(item.CONSUMER_ADDRESS_STATE)
   setZipcode(item.CONSUMER_ADDRESS_ZIPCODE)
   setDistrict(item.CONSUMER_ADDRESS_DISTRICT)
   setCity(item.CONSUMER_ADDRESS_CITY)
   setCountry(item.CONSUMER_ADDRESS_COUNTRY)
   setAddressLine1(item.CONSUMER_ADDRESS_LINE1)
   setLatitude(Number(item.CONSUMER_ADDRESS_LAT))
   setLongitude(Number(item.CONSUMER_ADDRESS_LONG))
}
const EditNewAddressModalOpen = () =>{
   setEditNewAddressModal(true)
   setEditAddress({})
}
const EditNewAddressModalClose = () =>{
   setEditNewAddressModal(false)
   placesArray.map((item, index) => {
      if (item.id === 1) {
          // console.log('place array1',item)
          return clickedValue['clickedValue_' + item.id] = true

      } else {
          // console.log('place array2',item)
          return clickedValue['clickedValue_' + item.id] = false
          // console.log('placse array',clickedValue)
      }
  })
}
const saveAddressModalClose = () =>{
   setSaveAddressModal(false)
   if(!isMobileView){
      setCurrentLocModal(true)
   }
   setAllValues({
      cmptAddress: '',
      floor: '',
      landmark: '',
      status: '',
      message: '',
      addError: false,
      floorError: false,
      landError: false
   })
   setEditAddress({})
   placesArray.map((item, index) => {
      if (item.id === 1) {
          // console.log('place array1',item)
          return clickedValue['clickedValue_' + item.id] = true

      } else {
          // console.log('place array2',item)
          return clickedValue['clickedValue_' + item.id] = false
          // console.log('placse array',clickedValue)
      }
  })
  setStateOrProvince(state.address.stateOrProvince)
  setZipcode(state.address.zipcode)
  setDistrict(state.address.district)
  setCity(state.address.city)
  setCountry(state.address.country)
  setAddressLine1(state.address.addressLine1)
  setLatitude(state.address.latitude)
   setLongitude(state.address.longitude)
}
let smallCardStyle={
   width: '70px',
   height: '25px',
   display: 'flex',
   // flexDirection: 'row',
   justifyContent: 'center',
   alignItems: 'center',
   borderRadius: '7px',
   border:'solid 1px rgba(116,116,116,0.4)',
   marginRight:20,
   // padding:10
}
let smallCardSelectedStyle={
   width: '70px',
   height: '25px',
   display: 'flex',
   // flexDirection: 'row',
   justifyContent: 'center',
   alignItems: 'center',
   borderRadius: '7px',
   border: 'solid 1px #8c52ff',
   backgroundColor:'rgba(216,197,252,0.6)',
   marginRight:20,
   // padding:10
}
//// onClick func to active card with backgroud color
const onPressSmallCard = (data) => {
   // console.log('values', data)
   setSelectedTag(data.place)
   placesArray.map(item=>{
       if(item.id === data.id){
       // console.log('items yes',item.id === data.id ,item.id,data.id)
           return setClickedValue({
               ['clickedValue_'+ item.id]:true   
           })
       }
   })
   // console.log('clicked value',clickedValue)
}
const CompleteAddressHandler = (event) => {
   let value=event.target.value
   // if (value.trim() === '') {
   //     setAllValues({
   //         ...allValues,
   //         cmptAddress: '',
   //         addError: true,
   //         status: 'error',
   //         message: 'Please enter complete address'
   //     })
   // } else {
       setAllValues({
           ...allValues,
           cmptAddress: value,
           status: '',
           addError: false,
       })
   // }
}
const FloorHandler = (event) => {
   let value=event.target.value
   setAllValues({
       ...allValues,
       floor: value
   })
}
const LandmarkHandler = (event) => {
   let value=event.target.value
   setAllValues({
       ...allValues,
       landmark: value
   })
}
const onCompleteAddressSubmit = () => {
   // if (allValues.cmptAddress.trim() === '') {
   //     setAllValues({
   //         ...allValues,
   //         status: 'error',
   //         message: 'Please enter complete address',
   //         addError: true
   //     })
   // } else {
       setAllValues({ ...allValues, addError: false })
       input_Ref1.current.focus()
   // }
}
const onFloorSubmit = () => {
   setAllValues({ ...allValues, floorError: false })
   input_Ref2.current.focus()
}
const onLandmarkSubmit = () => {
   setAllValues({ landError: false })
   // Keyboard.dismiss();
}
const clickOnSaveAddress = async() =>{
   try {
      // if (allValues.cmptAddress.trim() === '') {
      //     setAllValues({
      //         ...allValues,
      //         status: 'error',
      //         message: 'Please enter complete address',
      //         addError: true
      //     })
      // }else{
         let body;
         if(Object.keys(editAddress).length !== 0){
            body = {
                totalAddress: editAddress.CONSUMER_ADDRESS,
                latitude: editAddress.CONSUMER_ADDRESS_LAT,
                longitude: editAddress.CONSUMER_ADDRESS_LONG,
                city: editAddress.CONSUMER_ADDRESS_CITY,
                country: editAddress.CONSUMER_ADDRESS_COUNTRY,
                state: editAddress.CONSUMER_ADDRESS_STATE,
                zipcode: editAddress.CONSUMER_ADDRESS_ZIPCODE,
                district: editAddress.CONSUMER_ADDRESS_DISTRICT,
                addressLine1: editAddress.CONSUMER_ADDRESS_LINE1,
                addressLine2: allValues.cmptAddress ? allValues.cmptAddress : null ,
                addressLine3: allValues.floor ? allValues.floor : null,
                addressLine4: allValues.landmark ? allValues.landmark : null,
                selectedTag: selectedTag,
                uid:state.user.id  
            }
        }else{
          body = {
              totalAddress: totalAddress ? totalAddress : currentAddress,
              latitude: latitude,
              longitude: longitude,
              city: city,
              country: country,
              state: stateOrProvince,
              zipcode: zipcode,
              district: district,
              addressLine1: addressLine1,
              addressLine2: allValues.cmptAddress,
              addressLine3: allValues.floor,
              addressLine4: allValues.landmark,
              selectedTag: selectedTag,
              uid:state.user.id
          }
         }
          // console.log('body', body)
          let method = 'POST';
          let url = "/website/addressSave/sv2"
          let useFetchRes = await customFetchData(method, body, url, state, dispatch)
          // console.log('response', useFetchRes)
          if (useFetchRes.fetch) {
            // setSaveAddressModal(false)
            // setCurrentLocModal(false)
            // setEditNewAddressModal(false)
            // setMapViewModal(false)
            // setMapViewModalWeb(false)
            // setEditAddress({})
              setAllValues({ ...allValues, cmptAddress: '', floor: '', landmark: '', addError: '', status: '' })
              placesArray.map((item,index)=>{
                  if(item.id === 1){
                      // console.log('place array1',item)
                     return  clickedValue['clickedValue_'+ item.id]=true
                      
                  }else{
                      // console.log('place array2',item)
                     return clickedValue['clickedValue_'+ item.id]=false
                      // console.log('placse array',clickedValue)
                  }
              })
              if(editNewAddressModal){
               // console.log('entered 12')
                  onClickConfirmLocation(totalAddress,latitude,longitude)
              }else{
                  // console.log('entered 123')
                  onClickRecentSearch(editAddress,true)
              }
              toast(
               <div style={{display:'flex',justifyContent:'center'}}>
                  <text style={{textAlign:'center'}}>address saved successfully</text>
               </div>
               , {
               position: "bottom-center",
               hideProgressBar: true,
               progress: undefined,
               closeButton:false,
               style:{backgroundColor:'#E0D1FF'}
           });
            //   toast.success(<text style={{textAlign:'center'}}>address saved successfully</text>, { position: toast.POSITION.BOTTOM_CENTER })
          }
      // }
  }
  catch (error) {
      toast.warn(<text style={{textAlign:'center'}}>something went wrong</text>, { position: toast.POSITION.BOTTOM_CENTER });
  }
}
let textStyle={
   width:'100%',
   opacity: 0.4,
   fontFamily: 'Inter',
   fontSize: 12,
   fontWeight: 'normal',
   fontStretch: 'normal',
   fontStyle: 'normal',
   lineHeight: 'normal',
   letterSpacing: 'normal',
   textAlign: 'left', 
   color:'#8c52ff',
}
let errorBox={
   borderRadius: 4.2,
   backgroundColor: "#ffe4c5",
   shadowColor: "rgba(0, 0, 0, 0.04)",
   shadowOffset: {
     width: 0,
     height: 0
   },
   shadowRadius: 31,
   shadowOpacity: 1,
   display:"flex",
   justifyContent: "center",
   alignItems: "center", 
   paddingTop:5,
   paddingBottom:5
}
let errorText={
   fontFamily: "Inter",
   fontSize: 12.5,
   fontWeight: "600",
   fontStyle: "normal",
   letterSpacing: 0,
   textAlign: "center",
   color: "#f3972d"
}
let addressFieldsContent=(
   <div>
      <div style={{marginTop:10}}>
            <text className='enterText_Address'>Enter complete address</text>
         </div>
         <hr/>
         <div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
               <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={locationcolorIcon} style={{ width: 24, height: 24 }} />
               </div>
               <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
               <text className='addressLine1_TextHome'>{isMobileView ? (totalAddress.length > 28 ? totalAddress.substr(0,28) + '...' : totalAddress) : (totalAddress.length > 30 ? totalAddress.substr(0,30) + '...' : totalAddress)}</text>
               <text className='subText_Blw_AddressLine1'>
                  {
                     stateOrProvince + (stateOrProvince ? ", " : "") + country + (country ? ", " : "") + zipcode
                  }
               </text>
               </div>
            </div>
         </div>
         <div>
            {
               allValues.status == 'error' &&
               <div>
                  <div style={errorBox}>
                     <text style={errorText}>{allValues.message}</text>
                  </div>
               </div>
            }
            <div style={{paddingTop:20}}>
               <InputComponent
                  inputWidthStyle={'100%'} 
                  InputFields={{
                     type:"text",
                     // placeholderTextColor: '#8c52ff',
                     onChange: CompleteAddressHandler,
                     value: allValues.cmptAddress,
                     placeholder: "Complete Address",
                     // onKeyDown: () => onCompleteAddressSubmit(),
                     blurOnSubmit: false,
                     error: allValues.addError,
                     className:'my-inputPlaceHolder'
                     // returnKeyType: "next"
                 }}
                 textStyle={textStyle}
               />
            </div>
            <div style={{paddingTop:20}}>
               <InputComponent 
                  inputWidthStyle={'100%'}
                  InputFields={{
                     type:"text",
                     // placeholderTextColor: '#9d9d9d',
                     onChange: FloorHandler,
                     value: allValues.floor,
                     placeholder: "Floor (Optional)",
                     innerRef: input_Ref1,
                     // onKeyDown: () => onFloorSubmit(),
                     blurOnSubmit: false,
                     error: allValues.floorError,
                     className:'my-inputPlaceHolder',
                     // onSubmitEditing:() => onFloorSubmit()
                     // returnKeyType: "next"
                  }}
                  textStyle={textStyle}
               />
            </div>
            <div style={{paddingTop:20}}>
               <InputComponent 
                  inputWidthStyle={'100%'}
                  InputFields={{
                     type:"text",
                     // placeholderTextColor: '#9d9d9d',
                     onChange: LandmarkHandler,
                     value: allValues.landmark,
                     placeholder: "Nearby landmark (Optional)",
                     //   keyboardType="default"
                     // returnKeyType: "done",
                     // onKeyDown: () => onLandmarkSubmit(),
                     blurOnSubmit: false,
                     innerRef: input_Ref2,
                     error: allValues.landError,
                     className:'my-inputPlaceHolder',
                     // onSubmitEditing:() => onLandmarkSubmit()
                 }}
                 textStyle={textStyle}
               />
            </div>
         </div>
         <div style={{marginTop:10}}>
            <text className='saveAdreesAs_Text'>Save address as*</text>
            <div style={{display: 'flex', flexDirection: 'row',marginTop:10}}>
               {
                  placesArray.length!==0 &&
                  placesArray.map(item=>{
                     return <div onClick={()=>onPressSmallCard(item)} style={clickedValue['clickedValue_'+ item.id] ? smallCardSelectedStyle : smallCardStyle}>
                        <text className='saveAdreesAs_Text' style={{color:clickedValue['clickedValue_'+ item.id] ? '#8c52ff' : '#747474'}}>{item.place}</text>
                     </div>
                  })
               }
            </div>
         </div>
         <div style={{marginTop:20,marginBottom:20}}>
            <Button className='saveAdd_Btn_Home' onClick={()=>clickOnSaveAddress()} disabled={buttonDisabled ? true : false}>
               <text className='saveAdd_Confm_Text'>
                  Save Address
               </text>
            </Button>
         </div>
   </div>
)
let saveAddressModalDisplay=(
   <Modal isOpen={saveAddressModal} size={'md'} toggle={() => saveAddressModalClose()} centered>
      <div style={{padding:20}}>
         <div style={{ justifyContent: 'flex-end', display: 'flex', flexDirection: 'row' }}>
            <div className='crossIcon_ViewPro_Home' style={{ cursor: 'pointer' }}>
               <img src={CloseIcon} style={{ width: 18, height: 18 }} onClick={() => saveAddressModalClose()} />
            </div>
         </div>
         {addressFieldsContent}
      </div>
   </Modal>
)
let editAddressModalDisplay=(
   <Modal isOpen={editNewAddressModal} size={'md'} toggle={()=>EditNewAddressModalClose()} centered>
      <div style={{padding:20}}>
         <div style={{ justifyContent: 'flex-end', display: 'flex', flexDirection: 'row' }}>
            <div className='crossIcon_ViewPro_Home' style={{ cursor: 'pointer' }}>
               <img src={CloseIcon} style={{ width: 18, height: 18 }} onClick={() => EditNewAddressModalClose()} />
            </div>
         </div>
         {addressFieldsContent}
      </div>
   </Modal>
)


let noOffersStyle={
   fontFamily: "Inter",
   fontSize: 14,
   fontWeight: "normal",
   fontStyle: "normal",
   letterSpacing: 0,
   textAlign: "center",
   color: "#563951"
 }
// console.log('nearByPros',nearByPros,state)

   return (



      <div>

         <Helmet>
            <title>Experience top-notch home services in Hyderabad with Slash. From beauty and hair care to cleaning and electrical services, we bring the best service providers to your doorstep. Book now!</title>
            <meta name="description" content="Slash, home services, beauty services, hair care, cleaning services, electrical services, Hyderabad, Kondapur, Gachibowli, Kukatpally, Hitech City, SR Nagar, Madhapur, Lingampally, KPHB" />
         </Helmet>

         <div style={{position:'relative', background: 'linear-gradient(to top, rgba(0, 0, 0) 100%, rgba(0, 0, 0) 0%)'}}>
            <div className='searchAndText_Banner_Sv2'>
               <div style={{display:'flex',justifyContent:'center'}}>
                  <text className='Carousel_Text_Banner_Sv2'>
                     Slash Your Way to Great Services
                  </text>
               </div>
               <div style={{display:'flex',justifyContent:'center'}}>
                  <text className='subText_Carousel_Sv2'>
                     No more waiting in line or calling around. Slash makes it easy to book services with just a few clicks.  Just find the best professional, select your service, pick a time and get the job done.
                  </text>
               </div>
               <div style={{display:'flex',justifyContent:'center'}}>
                  {
                  isMobileView ?
                  <div  style={{display:'flex',flexDirection:'column',marginTop:20}}>
                     <div style={{position:'relative',width:'100%'}}>
                        <div onClick={addressClickMobile}>
                           <Input
                           innerRef={searchAddRefMob}
                              value={totalAddress}
                              className='inputSearchCatSerPro_Style_Mobile shadow-none'
                              placeholder='Search any address'
                              onChange={addressClickMobile}
                              
                           />
                           <img src={locationcolorIcon} style={{ position: 'absolute', left: 5, bottom: 15 }} />
                                 {
                                    loadingL ?
                                       <div style={{ display: 'flex', bottom: 16, right: 40, position: 'absolute' }}>
                                          <ClipLoader widthUnit={"%"} size={12} loadings={loadingL} color="#8c52ff" />
                                       </div>
                                       : null
                                 }
                           <img onClick={()=>addressClickMobile()} style={{position:'absolute',right:10,bottom:15,transform:addressSearchModal ? 'rotate(360deg)' : 'rotate(180deg)'}} src={arrowBack}/>
                        </div>
                     </div>
                     <div style={{position:'relative',marginTop:20}}>
                        <img src={searchIcon} style={{position:'absolute',left:5,bottom:10}}/>
                        {
                        searchTextOffer &&
                        <div style={{position:'absolute',display: 'flex',justifyContent:'center',right:8,bottom:12}} onClick={clearSearchResults}>
                           <img src={SearchCrossIcon} style={{width:20,height:20}}/>
                        </div>
                        }
                        <Input type="text" 
                        placeholder='Search any Service / Professional / Category' 
                        className='inputSearchCatSerPro_Style_Mobile shadow-none'
                        onChange={searchBoxOfferhandler}
                        value={searchTextOffer}
                        // returnType="done"
                        onSubmitEditing={clearSearchResults}/>
                        {showSearchDropDown ? <Card style={{position:'absolute', height:searchDropdownArray.length > 0 ? (searchDropdownArray.length == 1 || searchDropdownArray.length == 2 ? 'auto' : 260) : 150,top:55,left:-30,right:-30, zIndex: 2000 ,overflow:'auto'}}>
                           {searchOfferDropDownItem}
                         </Card> : null}
                     </div>
                  </div>
                  :
                  <div  style={{display:'flex',flexDirection:'row',marginTop:30}}>
                     <div style={{position:'relative'}}>
                        <PlacesAutocomplete
                           value={totalAddress}
                           // ref={searchAddRef}
                           onChange={handleChange}
                           onSelect={handleSelect}>
                                       {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                          <div>
                                                <Input
                                                innerRef={searchAddRef}
                                                value={totalAddress}
                                                onClick={()=>currentModalShow()}
                                                {...getInputProps({
                                                   placeholder: 'Search any address',
                                                   className: 'location-search-input-sv2 shadow-none'
                                                })}
                                                style={{cursor:'pointer'}}
                                                />
                                                {/* {console.log('suggestion',suggestions)} */}
                                                <img src={locationcolorIcon} style={{position:'absolute',left:5,bottom:15,cursor:'pointer'}}/>
                                                {
                                                   loadingL ? 
                                                   <div style={{display: 'flex',bottom:16,right:40,position:'absolute'}}>
                                                      <ClipLoader widthUnit={"%"} size={12} loadings={loadingL} color="#8c52ff" />
                                                   </div>
                                                   : null
                                                }
                                                <img onClick={()=>currentModalShow()} style={{cursor:'pointer',position:'absolute',right:5,bottom:15,transform:currentLocModal ? 'rotate(360deg)' : 'rotate(180deg)'}} src={arrowBack}/>
                                                   <Card style={{
                                                      border: suggestions.length !==0 ? '2px solid #fcfcfc' : 0,
                                                      height: suggestions.length !==0 ? 200 : 0,
                                                      overflow : suggestions.length !==0 ? 'scroll' : 0,
                                                      top:suggestions.length !==0 ? 55 : 0,
                                                      bottom:0,
                                                      position:'absolute',
                                                      zIndex:2000,
                                                      paddingRight:10,
                                                      paddingLeft:10,
                                                      paddingRight:10,
                                                      width:300
                                                      }}
                                                      className='dropDownCardWidth_sv2'>
                                                   {/* {loading && <div>Loading...</div>} */}
                                                   <div style={{marginTop:10,marginBottom:10}}>
                                                      {suggestions.map(suggestion => {
                                                         const className = suggestion.active
                                                         ? 'suggestion-item--active'
                                                         : 'suggestion-item';
                                                         // inline style for demonstration purpose
                                                         const style = suggestion.active
                                                         ? { backgroundColor: '#c5c5c5', cursor: 'pointer' }
                                                         : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                         return (
                                                         <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style:{display:'flex',flexDirection:'row',paddingTop:5,paddingBottom:5}
                                                            })}
                                                         >
                                                            {/* {this.state.showDropdown === true ? <div>{suggestion.description}</div> : null}  */}
                                                            {/* {console.log('data',suggestion.description)} */}
                                                            <text className='suggestion_Text' style={{cursor:'pointer'}}>{suggestion.description}</text>
                                                         </div>
                                                         );
                                                })}
                                                   </div>
                                                   </Card>
                                                   {
                                                      currentLocModal ? 
                                                      <Card className='CurrentLocation_Modal_Style' style={{height:recentSearchesArray.length !==0 && fetchedDataResponse.length !==0 ? 250 : (recentSearchesArray.length !==0 || fetchedDataResponse.length !==0 ? 240 : 100)}}>
                                                         <div style={{position:'relative'}}>
                                                           <div style={{flexDirection:'row',display:'flex',marginTop:10,cursor:'pointer'}} onClick={()=>currentLocationFunc(true)}>
                                                               <img src={CurrentLocationIcon} style={{width:20,height:20}}/>
                                                               <div style={{flexDirection:'column',display:'flex',marginLeft:16}}>
                                                                     <text className='currentLoc_MainText' style={{marginBottom:5}}>My Current location</text>
                                                                     {/* {
                                                                        loadingL ? 
                                                                        <div style={{display: 'flex', flexDirection: 'column',paddingLeft:30,paddingTop:10, justifyContent: 'center', alignItems: 'center'}}>
                                                                           <ClipLoader widthUnit={"%"} size={12} loadings={loadingL} color="#8c52ff" />
                                                                        </div>
                                                                        // <ClipLoader/>
                                                                        : */}
                                                                        <text className='currentLoc_subText' style={{paddingRight:5}}>{curLocationDenied ? '' : (currentAddress ? currentAddress : '')}</text>
                                                                     {/* } */}
                                                               </div>
                                                            </div>
                                                            {/* {state.token ? <div style={{position:'absolute',right:5,top:6,cursor:'pointer'}} onClick={()=>saveAddressModalOpen()}>
                                                               <MdOutlineEdit size={15} color="#8c52ff"/>
                                                            </div> : null} */}
                                                         </div>
                                                         <hr/>
                                                         <div style={{overflow: 'scroll',marginTop:5,marginBottom:5}}>

                                                            {
                                                               recentSearchesArray.length!==0 ?
                                                               <>
                                                                  <div style={{marginBottom:5}}>
                                                                     <text className='recentLocation_Text'>Recent Locations</text>
                                                                  </div>
                                                                  <div style={{marginTop:5,overflow:'scroll'}}>
                                                                     {recentSearchesDisplay}
                                                                  </div>
                                                               </>
                                                               : null
                                                            }
                                                            {
                                                               fetchedDataResponse.length !== 0 ?
                                                                  <>
                                                                     <div style={{ paddingBottom: 5 }}>
                                                                        <text className='recentLocation_Text'>Saved Locations</text>
                                                                     </div>
                                                                     <div style={{ marginTop: 5, overflow: 'scroll' }}>
                                                                        {savedAddressesDisplay}
                                                                     </div>
                                                                  </>
                                                                  : null
                                                            }
                                                         </div>
                                                      </Card>
                                                      :
                                                      null
                                                   }
                                          </div>
                                          )}
                        </PlacesAutocomplete>
                     </div>
                     <div style={{position:'relative'}}>
                        <div className='verticalline_sv2'></div>
                        <img src={searchIcon} style={{position:'absolute',left:20,bottom:10}}/>
                        {
                        searchTextOffer &&
                        <div style={{position:'absolute',cursor:'pointer',right:8,bottom:12}} onClick={clearSearchResults}>
                           <img src={SearchCrossIcon} style={{width:20,height:20}}/>
                        </div>
                        }
                        <Input 
                        type="text" 
                        placeholder='Search any Service / Professional / Category' 
                        className='inputSearchCatSerPro_Style shadow-none'
                        onChange={searchBoxOfferhandler}
                        value={searchTextOffer}
                        // returnType="done"
                        onSubmitEditing={clearSearchResults}
                        // onPressCross={clearSearchResults}
                        // searchTextOffer={searchTextOffer}
                        />
                        {showSearchDropDown ? <Card style={{position:'absolute', height: searchDropdownArray.length > 0 ? (searchDropdownArray.length == 1 || searchDropdownArray.length == 2 ? 'auto' : 220) : 150,top:55,left:30, zIndex: 2000 ,width:300,overflow:'auto'}}>
                           {searchOfferDropDownItem}
                         </Card> : null}
                     </div>
                  </div>
                  }
               </div>
            </div>
               <img className="homePage_Banner_Img" src={isMobileView ? 'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684328056083-lg.png' : 'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684391305748-lg.png'}/>
         </div>
         <div style={{position:'relative',cursor:'pointer'}}>
         {
               categories.length !==0 ?
               isMobileView ?
               <div className='offerBanner_Home_Mob'  style={{cursor:'pointer'}} onClick={()=>navigate('/offers',{state:{nearByPros:nearByPros,categories:categories,subcategories:subCategories}})}>
                  <img src={state.address.country === 'India' ? 'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1733223533716-lg.png' : 'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1701253888157-lg.png'} style={{width:'100%'}}/>
               </div>
               :
               <div className='offerBanner_Home' onClick={()=>navigate('/offers',{state:{nearByPros:nearByPros,categories:categories,subcategories:subCategories}})}>
                  <img src={state.address.country == 'India' ? 'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1733224060122-lg.png' : "https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1701246355450-lg.png"} style={{width:'100%'}}/>
               </div>:null
               
            }
         </div>
         {/* Address Related Modals */}
         {mapViewModal && addressSearchMapView}
         {
            mapViewModalWeb &&
            <Modal isOpen={mapViewModalWeb} size={'xl'} centered style={{borderRadius:15,width:isTabView ? '100%' :'50%'}} toggle={() => MapViewModalCloseWeb()}>
               {/* <div style={{}}> */}
                  {addressSearchMapViewWeb}
               {/* </div> */}
            </Modal>
         }
         {addressSearchModal && SearchAddressModal}
         {saveAddressModal && saveAddressModalDisplay}
         {editNewAddressModal && editAddressModalDisplay}
         {/* Categories and Professional Section */}
              {
               categories.length!==0 ?
               <>
                  <div className="MainReusable_Container_Home offer_Bottom_Home">
                     <div style={{ paddingTop: 40, flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
                        <div className='horizontal_LineSv2'></div>
                        <text className='Whats-on-your-mind'>What’s on your mind?</text>
                        <div className='horizontal_LineSv2'></div>
                     </div>
                     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {/* {categories.length !== 0 && !loadingCat? <Card style={{ width: widthCal, border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                           <CardBody style={{ padding: 0 }}>
                              {categoryCards}
                           </CardBody>
                        </Card> : null} */}
                        {
                           loadingCat ?
                           <div style={{ height: 200, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                              <ClipLoader widthUnit={"%"} size={40} loadings={loadingCat} color="#8c52ff" />
                           </div>
                           :
                           <Card style={{ width: widthCal, border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <CardBody style={{ padding: 0 }}>
                                 {categoryCards}
                              </CardBody>
                           </Card>
                        }
                     </div>
                  </div>

                  {/* Near by Professionals */}
                  <div className="MainReusable_Container_Home">
                     <div>
                        <text className='Professionals-nearby'>Professionals  nearby</text>
                     </div>
                     <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {/* <div> */}
                        <text className='NearByPro_BSubtext'>Book services in your neighbourhood</text>
                        {/* </div> */}
                        {nearByPros.length !== 0 && <div style={{ display: 'flex', flexDirection: 'row' }} onClick={() => onClickSeebyMap()}>
                           <text className='See-by-map'>See by map</text>
                           <img className="locationIconStyle_landing" src={locationcolorIcon} />
                        </div>}
                     </div>
                     <div className='bigHorZLine'>
                     </div>
                     {
                        loadingCat ?
                           <div style={{ height: 200, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                              <ClipLoader widthUnit={"%"} size={40} loadings={loadingCat} color="#8c52ff" />
                           </div>
                           : 
                        nearByProCardDisplay
                     }
                     {/* <div>
                        {nearByPros.length !== 0 ? nearByProCardDisplay : null}
                     </div> */}
                  </div>
               </>
               :
               
                  loadingCat ?
                     <div style={{ height: 200, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <ClipLoader widthUnit={"%"} size={40} loadings={loadingCat} color="#8c52ff" />
                     </div>
                     : 
                     <div style={{ height: 200, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <text style={{textAlign:'center'}}>{cityNotYet ? "We are not yet available in your city. Please check back later." : "No services to display"}</text>
                     </div>
               
              }
         
               {seeByMapModalWeb && seeByMapModalView}
               {seeByMapModalMobile && seeByMapModalViewMobile}
         {/* Why Slash Cards */}
         <div>
            {isMobileView ?  <WhyNewSlash_Mobile/> : <WhySlashNew/>}
         </div>

         {/* City Specific Banner*/}
         <div className="MainReusable_Container_Home">
            <CitySpecificBannerHome title={cityNotYet ? 'Stay tuned! Slash is coming to your city very soon..' : 'Slash is now available in your city'}/>
         </div>

         {/* Download our App */}
         <div className="MainReusable_Container_Home">
            {isMobileView ? <DownloadOurApp_Mobile/> : <DownloadOurApp/> }
         </div>
         {/* Refer And Earn */}
         <div className="MainReusable_Container_Home">
            <ReferAndEarnHome/>
         </div>
         <div>
            <Testimonials/>
         </div>
         <ToastContainer/>
         {/* Footer */}
         {/* <div style={{backgroundColor:'#461c99'}} className="MainReusable_Container_Home">
            { isMobileView ? <FooterSv2_Mobile/> : <FooterSv2/> }
         </div> */}

         {/* Copyright */}

         {/* <div style={{backgroundColor:'#8c52ff'}} className="MainReusable_Container_Home">
            <div style={{display:'flex',justifyContent:'center'}}>
               <text className='copyRight_Text'>
                  Copyright ©2022 Prashya Technologies Private Limited.
               </text>
            </div>
         </div> */}







         {/* INDIA REGISTRATION SCREEN */}
         <Modal
            className="modal-content rounded-0 "
            style={{ position: "absolute", right: 0, top: -32, borderRadius: 0, width: '34%' }}
            // isOpen={false}
            isOpen={modalInd}
            toggle={toggle1}
            modalTransition={{ timeout: 400 }}
         >
            <text style={{ paddingTop: 90, textAlign: 'center', fontSize: 20, fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 'bold', marginBottom: 15 }}>Hello there!</text>
            <text style={{ textAlign: 'center', color: '#747474', fontSize: 14, fontFamily: 'Inter' }}>Register yourself to get started</text>
            <div style={{ marginLeft: 75, marginRight: 75, marginTop: 44, marginBottom: 22 }}>
               <Input
                  // onChange={this.phoneHandler}

                  // valid={this.state.mobileNumber.valid ? true : false}
                  // invalid={this.state.mobileNumber.invalid ? true : false} 
                  placeholder="phone number"
                  // value={this.state.mobileNumber.value}
                  maxLength={10}
                  type="text" />

            </div>
            <text style={{ textAlign: 'right', color: '#747474', fontSize: 16, fontFamily: 'Inter', marginRight: 75, marginBottom: 22, color: "#8c52ff", fontWeight: '600' }}>Have a refferal code?</text>
            <Button onClick={openSendVerificationModal} style={{ marginLeft: 75, marginRight: 75, backgroundColor: "#8c52ff", marginBottom: 22, fontSize: 16 }} >Send verification code</Button>

            <div style={{ marginLeft: 105 }}>
               <Input type="checkbox" />
               <span style={{ fontSize: 12 }}>I agree with Terms & Conditions and Privacy Policy</span>
            </div>
            <div style={{ marginLeft: 105, marginBottom: 30 }}>
               <Input type="checkbox" />
               <span style={{ fontSize: 12 }}>I agree to receive promotional communications from slash</span>

            </div>
            <text style={{ paddingTop: 90, textAlign: 'center', fontSize: 14, fontFamily: 'Inter', marginBottom: 46 }}> Already have an account?  <span style={{ fontStyle: 'Inter', fontWeight: 'semi-bold' }}>Log in</span></text>

         </Modal>

         {/* OTP VERIFICATION */}

         <Modal
            className="modal-content rounded-0 "
            style={{ position: "absolute", right: 0, top: -32, borderRadius: 0, width: '34%' }}
            isOpen={modalOTP}
            toggle={toggle1}
            modalTransition={{ timeout: 400 }}
         >
            <text style={{ paddingTop: 90, textAlign: 'center', fontSize: 20, fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 'bold', marginBottom: 15 }}>Verification</text>
            <text style={{ textAlign: 'center', color: '#747474', fontSize: 14, fontFamily: 'Inter' }}>Please enter the verification code</text>
            <text style={{ textAlign: 'center', color: '#000', fontSize: 16, fontWeight: '500', fontFamily: 'Inter', marginTop: 44 }}>00:30</text>
            <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 35, marginBottom: 44, textAlign: 'center' }}>

               <OtpInput
                  value={otp}
                  onChange={otpHandleChange}
                  inputStyle={{
                     width: 48,
                     height: 48,
                     borderRadius: 10,
                     marginRight: 20,
                     border: '1px solid #747474'
                  }

                  }
                  style={{ width: '150%' }}
                  numInputs={4}
                  separator={<span></span>} />
            </div>
            <Button style={{ marginLeft: 75, marginRight: 75, backgroundColor: "#8c52ff", marginBottom: 22, fontSize: 16 }} >Verify</Button>

            <text style={{ paddingTop: 233, textAlign: 'center', fontSize: 14, fontFamily: 'Inter', marginBottom: 46 }}> I didn’t receive the code!<span style={{ fontStyle: 'Inter', fontWeight: 'semi-bold', color: '#8c52ff' }}>Resend</span></text>

         </Modal>

         {/* </div> */}

         {/* USA REGISTRATION SCREEN */}

         <Modal
            className="modal-content rounded-0 "
            style={{ position: "absolute", right: 0, top: -32, borderRadius: 0, width: '34%' }}
            isOpen={modalUSA}
            toggle={toggle}
            modalTransition={{ timeout: 400 }}
         >
            <text style={{ paddingTop: 90, textAlign: 'center', fontSize: 20, fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 'bold', marginBottom: 15 }}>Hello there!</text>
            <text style={{ textAlign: 'center', color: '#747474', fontSize: 14, fontFamily: 'Inter' }}>Register yourself to explore services</text>
            <div style={{ marginLeft: 75, marginRight: 75, marginTop: 44, marginBottom: 22 }}>
               <Input
                  // onChange={this.phoneHandler}
                  style={{ marginBottom: 20 }}
                  // valid={this.state.mobileNumber.valid ? true : false}
                  // invalid={this.state.mobileNumber.invalid ? true : false} 
                  placeholder="Enter email"
                  // value={this.state.mobileNumber.value}
                  maxLength={10}
                  type="text" />
               <Input
                  // onChange={this.phoneHandler}
                  style={{ marginBottom: 20 }}

                  // valid={this.state.mobileNumber.valid ? true : false}
                  // invalid={this.state.mobileNumber.invalid ? true : false} 
                  placeholder="Password"
                  // value={this.state.mobileNumber.value}
                  maxLength={10}
                  type="text" />
               <Input
                  // onChange={this.phoneHandler}
                  // style={{marginBottom:20}} 
                  // valid={this.state.mobileNumber.valid ? true : false}
                  // invalid={this.state.mobileNumber.invalid ? true : false} 
                  placeholder="Confirm Password"
                  // value={this.state.mobileNumber.value}
                  maxLength={10}
                  type="text" />

            </div>

            <text style={{ textAlign: 'right', color: '#747474', fontSize: 16, fontFamily: 'Inter', marginRight: 75, marginBottom: 22, color: "#8c52ff", fontWeight: '600' }}>Have a refferal code?</text>
            <Button style={{ marginLeft: 75, marginRight: 75, backgroundColor: "#8c52ff", marginBottom: 22, fontSize: 16 }} >Register</Button>

            <div style={{ marginLeft: 105 }}>
               <Input type="checkbox" />
               <span style={{ fontSize: 12 }}>I agree with Terms & Conditions and Privacy Policy</span>
            </div>
            <div style={{ marginLeft: 105, marginBottom: 30 }}>
               <Input type="checkbox" />
               <span style={{ fontSize: 12 }}>I agree to receive promotional communications from slash</span>

            </div>
            <text style={{ paddingTop: 90, textAlign: 'center', fontSize: 14, fontFamily: 'Inter', marginBottom: 46 }}> Already have an account?  <span style={{ fontStyle: 'Inter', fontWeight: 'semi-bold' }}>Log in</span></text>

         </Modal>


           {/* { modal && <RegisstrAndLogin_India closeModal={()=>setModal(false)}   />} */}

      </div>

   )
}
// Old code in landing page removed, related to home page reg and login is still there
// {/* <text style={{ position: 'absolute', fontSize: window.innerWidth < 1024 ? 30 : 60, letterSpacing: 0.3, fontWeight: 'bold', right: 0, left: 0, marginTop: 218, color: '#fff', lineHeight: 1.27 }}>Lorem Ipsum is simply dummy</text>
//             <text style={{ position: 'absolute', fontSize: 16, letterSpacing: 0.1, right: 0, left: 0, marginLeft: 'auto', marginRight: 'auto', marginTop: 310, color: '#fff', width: 750 }}> Welcome to Burger Bliss, where we take your cravings to a whole new level! Our mouthwatering burgers are made from 100% beef and are served on freshly baked buns.</text>

//             {/* SEARCH SERVICE OR LOCATION INPUT BOX  */}
//             <div style={{ position: 'absolute', right: 0, left: 0, height: 44, marginTop: 409, backgroundColor: '#fff', boxShadow: '0 2px 4px 0 rgba(103, 52, 204, 0.1)', borderRadius: 10, display: 'flex',justifyContent:'flex-start' ,marginLeft:'25%',marginRight:'25%'}}>
//                <img height='24' width='24' style={{ margin: 10 }} src={locationcolorIcon} />
//                <div style={{ width: 158, padding: 6, marginLeft: -9 }}>
//                   <Input
//                      style={{ fontSize: 12, border: 0, boxShadow: 'none' }}
//                      // onChange={this.phoneHandler}

//                      // valid={this.state.mobileNumber.valid ? true : false}
//                      // invalid={this.state.mobileNumber.invalid ? true : false} 
//                      placeholder="Masjid banda Raj.."
//                      // value={this.state.mobileNumber.value}
//                      maxLength={20}
//                      type="text" />
//                </div>
//                <img onClick={locationModalHandle} height='16' width='18' style={{ margin: 16, transform: !locModal ? 'rotate(180deg)' : null }} src={arrowBack} />
//                <div style={{ width: 1, height: 30, borderLeft: '1px solid #747474', marginTop: 8 }}></div>

//                <img height='24' width='24' style={{ margin: 12 }} src={searchIcon} />

//                <div style={{ width: 250, padding: 6, marginLeft: -9 }}>
//                   <Input
//                      style={{ fontSize: 12, border: 0, boxShadow: 'none' }}
//                      onChange={(e) => searchHandleChange(e)}
//                      ref={searchInput}
//                      //    autoFocus
//                      value={query}
//                      // valid={this.state.mobileNumber.valid ? true : false}
//                      // invalid={this.state.mobileNumber.invalid ? true : false} 
//                      placeholder="Try searching a service..."
//                      maxLength={30}
//                      type="search" />
//                </div>
//             </div>

//          </div>
//          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: -60, zIndex: 100, position: 'absolute', marginLeft: '10%', marginRight: '10%', right: 0, left: 0 }}>
//             <div style={{ backgroundColor: '#fff', height: 200, marginRight: 25, border: 'solid 2px #fff', borderRadius: 15, overflow: 'hidden', boxShadow: '0 2px 8px 0 rgba(103, 52, 204, 0.15)' ,position:'relative'}} >
//                <text style={{zIndex:90, position:'absolute',textAlign:'center',left:'10%',right:'10%',color:'#fff',top:'80%',fontSize:16,fontWeight:'600'}}>Personal Services</text>
//                <img src={'https://placebear.com/g/480/480'}/>
//             </div>
//             <div style={{ backgroundColor: '#fff', height: 200, marginRight: 25, border: 'solid 2px #fff', borderRadius: 15, overflow: 'hidden', boxShadow: '0 2px 8px 0 rgba(103, 52, 204, 0.15)' ,position:'relative'}} >
//                <text style={{zIndex:90, position:'absolute',textAlign:'center',left:'10%',right:'10%',color:'#fff',top:'80%',fontSize:16,fontWeight:'600'}}>Living Services</text>
//                <img src={'https://picsum.photos/480'}/>
//             </div>
//             {/* <div style={{ backgroundColor: '#fff', height: 200, border: 'solid 2px #fff', border: 'solid 2px #fff', borderRadius: 15, overflow: 'hidden', boxShadow: '0 2px 8px 0 rgba(103, 52, 204, 0.15)' }}>
//                <img src={'https://picsum.photos/480'}/>
//                <text>Living Services</text>
//             </div> */}
//          </div>


//          <div style={{ marginTop: 253 }}>

//             <h2 style={{
//                textAlign: 'center', borderBottom: '1px solid #b8b8b8', lineHeight: '0.1em', margin: '14px 0 14px -14px', opacity: 0.6, height: 7, flexGrow: 0, marginLeft: 155, marginRight: 200,


//             }}><span style={{ backgroundColor: '#f7f7f7', padding: '0 20px', fontSize: 24, fontWeight: 'bold', width: 253 }}>What's on your mind?</span></h2>

//          </div>

//          {/* category cards */}
//          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', right: 0, left: 0, overflow: 'hidden', borderRadius: 15, marginTop: 44, marginBottom: 101,marginLeft: '15%', marginRight: '15%' }}>
//             <div style={{ backgroundColor: '#7db4ff', width: 161, height: 146, marginRight: 44, borderRadius: 20, border: 'solid 2px #fff', boxShadow: '0 2px 4px 0 rgba(103, 52, 204, 0.1)', position: 'relative' }}>
//                <img style={{ right: 0, left: 0, margin: 'auto', position: 'absolute', marginTop: 28 }} width='67.5' height='60' src={beautyLogo} />
//                <text style={{ position: 'absolute', right: 0, left: 0, margin: 'auto', marginTop: 104, fontSize: 16, fontWeight: '600', fontFamily: 'Inter', color: '#fff' }}>Beauty</text>
//             </div>
//             <div style={{ flexDirection: 'row', backgroundColor: '#ffb884', width: 161, height: 146, marginRight: 44, borderRadius: 20, border: 'solid 2px #fff', boxShadow: '0 2px 4px 0 rgba(103, 52, 204, 0.1)', position: 'relative' }}>
//                <img style={{ right: 0, left: 0, margin: 'auto', position: 'absolute', marginTop: 28 }} width='47.1' height='60' src={hairLogo} />
//                <text style={{ position: 'absolute', right: 0, left: 0, margin: 'auto', marginTop: 104, fontSize: 16, fontWeight: '600', fontFamily: 'Inter', color: '#fff' }}>Hair Care</text>
//             </div>
//             <div style={{ backgroundColor: '#ac81ff', width: 161, height: 146, marginRight: 44, borderRadius: 20, border: 'solid 2px #fff', boxShadow: '0 2px 4px 0 rgba(103, 52, 204, 0.1)', position: 'relative' }}>
//                <img style={{ right: 0, left: 0, margin: 'auto', position: 'absolute', marginTop: 28 }} width='51.5' height='60' src={nailLogo} />
//                <text style={{ position: 'absolute', right: 0, left: 0, margin: 'auto', marginTop: 104, fontSize: 16, fontWeight: '600', fontFamily: 'Inter', color: '#fff' }}>Nail Care</text>
//             </div>
//             <div style={{ backgroundColor: '#ff848e', width: 161, height: 146, borderRadius: 20, border: 'solid 2px #fff', boxShadow: '0 2px 4px 0 rgba(103, 52, 204, 0.1)', position: 'relative' }}>
//                <img style={{ right: 0, left: 0, margin: 'auto', position: 'absolute', marginTop: 28 }} width='59.1' height='60' src={autoLogo} />
//                <text style={{ position: 'absolute', right: 0, left: 0, margin: 'auto', marginTop: 104, fontSize: 16, fontWeight: '600', fontFamily: 'Inter', color: '#fff' }}>Auto Care</text>
//             </div>
//          </div>
//          {/* <Corousel /> */}

//          <div style={{display:'flex',flexDirection:'row', marginLeft: '7%', marginRight: '7%',alignItems:'flex-start',justifyContent:'space-between'}}>
//             <text style={{fontSize:24,fontWeight:'bold',fontFamily:'Inter',}}>Professionals  nearby</text>
//          </div>
//          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between', marginLeft: '7%', marginRight: '7%',marginTop:-10}}>
//              <div>
//                <text style={{fontSize:14,fontWeight:'normal',fontFamily:'Inter', color: '#9f9f9f'}}>Book services at best price in your neighbourhood</text>  
//              </div>  
//              <div>
//                <text style={{fontSize:14,fontWeight:'normal',fontFamily:'Inter', color: '#8c52ff'}}>See by map</text>
//                <img height='20' width='20' style={{ margin: 10 }} src={locationcolorIcon} />
//              </div> 
//          </div>

//          <h2 style={{ textAlign: 'center', borderBottom: '1px solid #b8b8b8', lineHeight: '0.1em', marginLeft: '7%', marginRight: '7%', opacity: 0.6, height: 7, flexGrow: 0 }} />


//          {/* professional cards */}
//          <div style={{ display: 'flex', flexDirection: 'row',borderRadius: 15, marginTop: 44,justifyContent:'space-between', marginLeft: '7%', marginRight: '7%',  }}>
//             <div style={{ backgroundImage: `url("https://picsum.photos/200")`, backgroundColor: '#747474', width: 239, height: 144, marginRight: 44, borderRadius: 10, boxShadow: '0 4px 26px 0 rgba(128, 128, 128, 0.25)', position: 'relative' }}>
//                <text style={{ position: 'absolute', marginTop: 110, fontSize: 14, fontFamily: 'Inter', color: '#fff', left: 42 }}>Masjid Banda</text>
//                <img style={{ position: 'absolute', marginTop: 113, left: 20 }} width='14' height='15' src={locationIcon} />
//             </div>
//             <div style={{ backgroundImage: `url("https://picsum.photos/200")`, backgroundColor: '#747474', width: 239, height: 144, marginRight: 44, borderRadius: 10, boxShadow: '0 4px 26px 0 rgba(128, 128, 128, 0.25)', position: 'relative' }}>
//                <text style={{ position: 'absolute', marginTop: 110, fontSize: 14, fontFamily: 'Inter', color: '#fff', left: 42 }}>Masjid Banda</text>
//                <img style={{ position: 'absolute', marginTop: 113, left: 20 }} width='14' height='15' src={locationIcon} />
//             </div>
//             <div style={{ backgroundImage: `url("https://picsum.photos/200")`, backgroundColor: '#747474', width: 239, height: 144, marginRight: 44, borderRadius: 10, boxShadow: '0 4px 26px 0 rgba(128, 128, 128, 0.25)', position: 'relative' }}>
//                <text style={{ position: 'absolute', marginTop: 110, fontSize: 14, fontFamily: 'Inter', color: '#fff', left: 42 }}>Masjid Banda</text>
//                <img style={{ position: 'absolute', marginTop: 113, left: 20 }} width='14' height='15' src={locationIcon} />
//             </div>
//             <div style={{ backgroundImage: `url("https://picsum.photos/200")`, backgroundColor: '#747474', width: 239, height: 144, borderRadius: 10, boxShadow: '0 4px 26px 0 rgba(128, 128, 128, 0.25)', position: 'relative' }}>
//                <text style={{ position: 'absolute', marginTop: 110, fontSize: 14, fontFamily: 'Inter', color: '#fff', left: 42 }}>Masjid Banda</text>
//                <img style={{ position: 'absolute', marginTop: 113, left: 20 }} width='14' height='15' src={locationIcon} />
//             </div>
//          </div>
//          {/* LOCATION MODAL */}
//          {locModal ? <div style={{ width: 240, height: 192, position: 'absolute', zIndex: 1000, right: 0, left: 390, top: 470, boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.1)', backgroundColor: '#fff', borderRadius: 10, }}>

//             <div style={{ margin: 16, display: 'flex', flexDirection: 'row' }}>
//                <img style={{ marginRight: 16.8 }} width='18.3' height='18.3' src={curLocationIcon} />
//                <text style={{ color: '#ea4335', fontWeight: '600', fontSize: 14, fontFamily: 'Inter', textAlign: 'left', fontStyle: 'normal' }}>My Current Location</text>
//             </div>
//             <div style={{ margin: 16, display: 'flex', flexDirection: 'row', marginTop: -14 }}>
//                <text style={{ fontSize: 10, marginLeft: 36, textAlign: 'left' }}>Masjid banda, Rajaprasadamu Building, Kondapur.</text>
//             </div>
//             <div style={{ display: 'flex', flexDirection: 'row', margin: 18 }}>
//                <text style={{ fontSize: 12, fontWeight: '500' }}>Recent Locations</text>
//             </div>
//             <div style={{ display: 'flex', flexDirection: 'row', margin: 18, marginTop: -10, marginLeft: 18 }}>
//                <img style={{ marginRight: 8 }} width='15' height='15' src={historyLocationIcon} />
//                <text style={{ fontSize: 10, fontFamily: 'Inter', color: '#747474' }}>Masjid banda, Rajaprasadamu Building</text>

//             </div>
//             <div style={{ display: 'flex', flexDirection: 'row', margin: 18, marginTop: -10, marginLeft: 18 }}>
//                <img style={{ marginRight: 8 }} width='15' height='15' src={historyLocationIcon} />
//                <text style={{ fontSize: 10, fontFamily: 'Inter', color: '#747474' }}>Masjid banda, Rajaprasadamu Building</text>

//             </div>



//          </div> : null}
//          {/* SEARCH SERVICE MODAL */}
//          {serviceModal ? <div style={{ width: 410, height: 184, position: 'absolute', zIndex: 1000, right: 0, left: 640, top: 470, boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.1)', backgroundColor: '#fff', borderRadius: 10, paddingTop: 8 }}>
//             <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
//                {(state.query === '' && document.activeElement === searchInput.current ? posts.map(post => {
//                   return <span style={{ padding: 15, marginTop: 23, display: 'flex', flexDirection: 'column', float: 'left' }} key={post.categoryName}>{post.categoryName}</span>
//                }) : state.list.map(post => {
//                   //  if(post.length!=3){
//                   return (

//                      <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: post.id == 1 ? '#d8c5fc' : null, paddingLeft: 14, width: '100%' }}>
//                         <div style={{ flexDirection: 'column', backgroundColor: '#ffb884', width: 40, height: 40, borderRadius: 10, border: 'solid 2px #fff', boxShadow: '0 2px 4px 0 rgba(103, 52, 204, 0.1)', marginTop: 8, marginBottom: 8 }}>
//                            <img style={{ marginTop: 7 }} width='18.2' height='21.7' src={hairLogo} />
//                         </div>
//                         <div style={{ flexDirection: 'column', marginLeft: 16, }}>
//                            <div style={{ flexDirection: 'column', marginTop: 10 }}>
//                               <text style={{ fontSize: 12, fontFamily: 'Inter' }} key={post.categoryName}>{post.categoryName}</text>
//                            </div>
//                            <div style={{ flexDirection: 'column', marginTop: -12, textAlign: 'left' }}>
//                               <text style={{ fontSize: 10, fontFamily: 'Inter' }}>Category</text>
//                            </div>
//                         </div>
//                      </div>

//                   )
//                }))}
//             </div>
//          </div> : null} */}

export default React.memo(LandingPageUpdated)
