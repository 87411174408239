import React, { useContext, useEffect, useRef, useState } from "react";
import LeftArrowIcon from '../../Assets/cgeckoutRightIcon.png'
import RightArrowIcon from '../../Assets/checkoutLeftIcon.png'
import LowDemandIcon from "../../Assets/LowDemandIcon.png"
import NormalDemandIcon from "../../Assets/NormalDemandIcon.png"
import HighDemandIcon from "../../Assets/HighDemandIcon.png"
import '../../CSS/CheckoutStack/SlotSelectionPage.css'
import { globalState } from "../../GlobalState/globalState";
import numeral from "numeral";
import moment from "moment";
import axios from "axios";
import TimeListContainer from "../../Components/Custom/UI/TimeListContainer";
import DateListContainer from "../../Components/Custom/UI/DateList";
import { Row } from "reactstrap";
import SubmitButtonSmall from "../../Components/Custom/UI/submitButtonSmall";
import {useMediaQuery} from 'react-responsive';
import { updateMultipleInCart } from "../../GlobalState/globalStateActions"; 
import LoadingSpinner from "../../Components/Custom/UI/LoadingSpinner";
import customFetchData from "../../Components/Custom/customFetchData";

const useTabletAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 768px)" })
const useSmallDesktopAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 1024px)" })
const useDesktopAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 1440px)" })

const DeepClone = require("rfdc/default")

export default function SlotSelection(props){

    const [state,dispatch]=useContext(globalState);

    const [services, setServices] = useState(props ? ( props.services ? DeepClone(props.services): [] ) : [])
    const [currentIndex, setCurrentIndex] = useState(props ? (props.serviceIndex?props.serviceIndex:0):0)
    const [slot_multiplier_offer_price,setSlot_multiplier_offer_price]=useState(0)
    const [serviceItemFocused,setServiceItemFocused]=useState(props ? props.serviceItem : {})
    const [slotDays,setSlotDays]=useState({})
    const [slotDaysArr,setSlotDaysArr]=useState([])
    const [service_timezone,SetService_timezone]=useState('')
    const [dateSelected,setDateSelected]=useState({})
    const [selectedSlots,setSelectedSlots]=useState({})

    const [noTimeSlots, setnoTimeSlots] = useState(false)
    const flatListRef = useRef(null)
    const flatListDateRef = useRef(null)
    const flatListTimeRef = useRef(null)
    const [couponApplied,setCouponApplied]=useState(props ? props.couponApplied : false)
    const [prevCouponCode,setPrevCouponCode]=useState(props ? props.couponCode : null)
    const [couponCode,setCouponCode]=useState(props ? props.couponCode : '')
    const [loading,setLoading]=useState(false)

    const isTab=useTabletAndBelowMediaQuery();
    const isSmallDesktop = useSmallDesktopAndBelowMediaQuery()


      useEffect(() => {
        try{
        // console.log("serviceIndex in useffect",currentIndex, props)
        // console.log("serviceIndex in useffect state",state)
        // console.log("slotDaysArr in slotSelect",slotDaysArr)
        // console.log("timeSlotsArr in slotSelect",timeSlotsArr)
        // console.log("selectedSlots in slotSelect",selectedSlots)
        // let { fromDateTime,toDateTime } = route.params
        let fromDateTime=moment(state.fromDateTime)
        let toDateTime=moment(state.toDateTime)
        let serviceItem=services[currentIndex]
        // let fromDateTime=moment().tz(props.timezone).set({'hour': 0, 'minute': 0});
        // let toDateTime=moment().tz(props.timezone).set({'hour': 23, 'minute': 59}).add(30,"days");
        // console.log("state",state)
        // console.log("state datetime",state.fromDateTime,state.toDateTime,moment(fromDateTime).set('hour', 13))
        let selectedSlotsReq=[]

        if(services.length!==0)
        {
            // console.log("services inside",services)
            services.map(item=>{
                if(item.QF_MERCHANT_SERVICE_PRID!==services[currentIndex].QF_MERCHANT_SERVICE_PRID && item.hasOwnProperty('timeSlot'))
                {
                    selectedSlotsReq.push(item.timeSlot)
                }
            })
        }
        // console.log("selectedSlotsReq",serviceItem)

        onFetchDateTimeSlot(selectedSlotsReq);
        
    }
    catch(c1)
    {
        console.log(c1)
    }
    },[currentIndex])

    const onFetchDateTimeSlot = async(selectedSlotsReq) => {

        let fromDateTime=moment(state.fromDateTime)
        let toDateTime=moment(state.toDateTime)
        let serviceItem=services[currentIndex]

        const ser_data = {
            mpid:serviceItem.QF_MERCHANT_PERSONNEL_PRID,
            timezone:serviceItem.SERVICE_TIMEZONE,
            serviceDuration:serviceItem.SERVICE_DURATION_IN_MINS,
            slotCheck:false,
            fromDateTimeFDay:moment(fromDateTime).date(),
            fromDateTimeFMonth:moment(fromDateTime).month(),
            toDateTimeFDay:moment(toDateTime).date(),
            toDateTimeFMonth:moment(toDateTime).month(),
            fromDateTimeFYear:moment(fromDateTime).year(),
            toDateTimeFYear:moment(toDateTime).year(),
            serviceCountry:serviceItem.SERVICE_CURRENCY==="Rupees"?"IN":"US",
            subCatId:serviceItem.QF_SERVICE_SUB_CATEGORY_PRID,
            // uniqueId:DeviceInfo.getUniqueId(),
            deCondition:serviceItem.deCondition,
            selectedSlotsReq
        }
        // console.log("ser_data",ser_data)
        setLoading(true)
        let {fetch, fetchedData}=await customFetchData('POST',ser_data,'/website/fetch/timeslots/sv2',state,dispatch)
        
        if(fetchedData.data)
        {
            if(Object.keys(fetchedData.slotDays).length===0)
            {
                // this.setState({noTimeSlots:true})
            }
            else
            {
                let slotDatesObj=DeepClone(fetchedData.slotDays)
                // console.log("slotDatesObj",slotDatesObj,fromDateTime,toDateTime)
                let slotDaysArr=[]
                let idForDate=1
                for(let prop in slotDatesObj)
                {
                    slotDaysArr.push({date:prop,dayOfWeek:slotDatesObj[prop].dayOfWeek,idForDate:idForDate++})
                }
                let daysCheck1=slotDaysArr.length

                /////// Need to remove later just for testing
                let showMoreClicked=false

                if(!showMoreClicked)
                {
                    let fromTimeHours=moment(fromDateTime).get('hour')
                    let fromTimeMins=moment(fromDateTime).get('minute')
                    let toTimeHours=moment(toDateTime).get('hour')
                    let toTimeMins=moment(toDateTime).get('minute')

                    // console.log("time check",fromTime," ",toTime)

                    // console.log("fromDateTime",moment(fromDateTime).format("YYYY-MM-DD HH:mm:ss"),"toDateTime ",moment(toDateTime).format("YYYY-MM-DD HH:mm:ss"))
                    slotDaysArr=slotDaysArr.filter(item=>{
                        let itemDateTime=moment(item.date+" "+"23:59")
                        // console.log("item",item.date,"itemdate ",moment(itemDateTime).format("YYYY-MM-DD HH:mm:ss")," ",moment(itemDateTime).isSameOrAfter(moment(fromDateTime)) && moment(itemDateTime).isSameOrBefore(moment(toDateTime)))
                        return moment(itemDateTime).isSameOrAfter(moment(fromDateTime).set({'hour':0,'minute':0})) && moment(itemDateTime).isSameOrBefore(moment(toDateTime).set({'hour':23,'minute':59}))
                    })


                    for(let prop in slotDatesObj)
                    {
                        
                            let slots=[...slotDatesObj[prop].slots]
                            slots=slots.filter(pItem=>{
                                let itemFromDateTime=moment(prop+" "+pItem.startTime)
                                // console.log("prop check ",prop+" "+pItem.startTime," ",prop+" "+pItem.endTime," ",prop+" "+fromTime," ",prop+" "+toTime)
                                let itemToDateTime=moment(prop+" "+pItem.endTime)
                                // console.log("item",prop,"itemdate ",moment(itemFromDateTime).format("YYYY-MM-DD HH:mm:ss")," ",moment(itemToDateTime).format("YYYY-MM-DD HH:mm:ss")," ",moment(prop+" "+fromTime).format("YYYY-MM-DD HH:mm:ss")," "+moment(prop+" "+toTime).format("YYYY-MM-DD HH:mm:ss")," ",moment(itemFromDateTime).isSameOrAfter(moment(prop+" "+fromTime)) && moment(itemToDateTime).isSameOrBefore(moment(prop+" "+toTime)))
                                return moment(itemFromDateTime).isSameOrAfter(moment(prop).set({'hour':fromTimeHours,'minute':fromTimeMins})) && moment(itemToDateTime).isSameOrBefore(moment(prop).set({'hour':toTimeHours,'minute':toTimeMins}))
                            })
                        
                        slotDatesObj={
                            ...slotDatesObj,
                            [prop]:{
                                ...slotDatesObj[prop],
                                slots:[...slots],
                            }
                        }
                    }

                    for(let prop in slotDatesObj)
                    {
                        if(slotDatesObj[prop].slots.length===0)
                        {
                            // console.log("deleting prop",prop)
                            delete slotDatesObj[prop]
                            slotDaysArr=slotDaysArr.filter(item=>{
                                return item.date!=prop
                            })
                        }
                    }

                    let daysCheck2=slotDaysArr.length
                    // if(daysCheck1!=daysCheck2 && slotDaysArr.length!==0)
                    // {
                    //     slotDaysArr.push({date:"Show More",type:"showMore"})
                    // }
                }

                for(let prop in slotDatesObj)
                {
                    slotDatesObj[prop].slots.map(item=>{
                        let add_diff_price=0
                        if(item.tsPrid)
                        {
                            
                            item.slot_multiplier_offer_price=Math.ceil( numeral(serviceItem.SERVICE_OFFER_PRICE).multiply(item.tsMultiplier).value() )
                            add_diff_price=Math.abs(numeral(serviceItem.SERVICE_OFFER_PRICE).subtract(item.slot_multiplier_offer_price).value() )
                            if(item.tsMultiplier<1)
                            {
                                item.slot_multiplier_offer_price=Math.abs(numeral(serviceItem.service_offer_price).subtract(add_diff_price).value())
                            }
                            else if(item.tsMultiplier>1)
                            {
                                item.slot_multiplier_offer_price=Math.abs(numeral(serviceItem.service_offer_price).add(add_diff_price).value())
                            }
                            
                            item.single_offer_price=serviceItem.service_offer_price
                            item.cp_multiplier_diff_price=serviceItem.multiplier_diff_price
                            item.cpMultiplier=serviceItem.cpMultiplier
                            item.deCondition=serviceItem.deCondition

                            if(item.slot_multiplier_offer_price==item.single_offer_price)
                            {
                                item.color="#fff"
                                delete item.tsPrid
                            }
                            else
                            {
                                
                                item.ts_multiplier_diff_price=numeral(item.slot_multiplier_offer_price).subtract(serviceItem.service_offer_price).value()
                                item.color=(item.slot_multiplier_offer_price>item.single_offer_price)?"#ff9b46":"#00c2ba"
                                item.inc_or_dec=(item.slot_multiplier_offer_price>item.single_offer_price)?"inc":"dec"
                                item.inc_or_dec_val=add_diff_price
                            }
                        }
                        else
                        {
                            item.slot_multiplier_offer_price=serviceItem.service_offer_price
                            item.single_offer_price=serviceItem.service_offer_price
                            item.cp_multiplier_diff_price=serviceItem.multiplier_diff_price
                            item.cpMultiplier=serviceItem.cpMultiplier
                            item.color="#fff"
                            item.deCondition=serviceItem.deCondition
                        }
                        if(serviceItem.cpPrid)
                        {
                            item.cpPrid=serviceItem.cpPrid
                        }
                    })
                }
                setSlotDaysArr(slotDaysArr)
                setSlotDays(slotDatesObj)
                // console.log("slotDaysArr,slotDatesObj",slotDaysArr,slotDatesObj)
                if(slotDaysArr.length!==0)
                {
                    // console.log("insude arr length")
                    if(services[currentIndex].hasOwnProperty('timeSlot') && services[currentIndex].hasOwnProperty('dateSlot') )
                    {
                        // console.log("insude t and d")
                        let selectedSlotsTemp={}
                        selectedSlotsTemp[services[currentIndex].QF_MERCHANT_SERVICE_PRID]={}
                        selectedSlotsTemp[services[currentIndex].QF_MERCHANT_SERVICE_PRID][services[currentIndex]['timeSlot']['id']] = services[currentIndex].timeSlot
                        selectedSlotsTemp[services[currentIndex].QF_MERCHANT_SERVICE_PRID]['date_slot'] = services[currentIndex].dateSlot
                        setSelectedSlots(selectedSlotsTemp)
                        // console.log("selectedSlotsTemp cmp",services[currentIndex]['timeSlot']['id'],services[currentIndex],selectedSlotsTemp)
                        onDateSelect(services[currentIndex]['dateSlot'],false,true)
                    }
                    else
                    {
                        // console.log("outside t and d")
                        onDateSelect(slotDaysArr[0],true,false)
                    }
                }
                else
                {
                    // console.log("oustide arr length")
                }
                
                // SetService_timezone(service_timezone)
            }    
        }
        else
        {

        }
        setLoading(false)
    }

    const onDateSelect=(item,defaultCall,fromCheckout)=>{
        let selectedSlotsTemp={}
        // console.log("ondate select",item,defaultCall)
        if(!defaultCall)
        {
            setSelectedSlots(prevState=>{
                selectedSlotsTemp=DeepClone(prevState)
                // fromCheckout?null: selectedSlotsTemp[services[currentIndex].QF_MERCHANT_SERVICE_PRID]={}
                // console.log("selectedSlotsTemp on date select",selectedSlotsTemp)
                return selectedSlotsTemp
            })
            setDateSelected(item)
        }
        else
        {   
            selectedSlotsTemp=DeepClone(selectedSlots)
            if(Object.keys(selectedSlotsTemp).length!==0)
            {
                if(selectedSlotsTemp[services[currentIndex].QF_MERCHANT_SERVICE_PRID])
                {
                    if(Object.keys(selectedSlotsTemp[services[currentIndex].QF_MERCHANT_SERVICE_PRID]).length!==0)
                    {
                        setDateSelected(selectedSlotsTemp[services[currentIndex].QF_MERCHANT_SERVICE_PRID]['date_slot'])
                    }
                    else
                    {
                        setDateSelected(item)
                    }
                }
                else
                {
                    setDateSelected(item)
                }
            }
            else
            {
                setDateSelected(item)
            }
            
        }
        
        // this.dateFlatListRef.scrollToIndex({animated: true, index: index?index:0,viewPosition:0.5});
        // if(index!==0)
        // {
        //     this.slotsFlatListRef.scrollToIndex({animated: true, index: 0,viewPosition:0.5});
        // }
        // if(type==="showMore")
        // {
        //    return this.fetchHandler(true)
        // }

        // this.setState({dateSelected:item,selectedSlots:{}})
        
    }

    const onSlotSelect=(item,index)=>{
        item.date=dateSelected.date
        let selectedSlotsTemp=DeepClone(selectedSlots),slot_multiplier_offer_price=0
        selectedSlotsTemp[services[currentIndex].QF_MERCHANT_SERVICE_PRID]={}
        selectedSlotsTemp[services[currentIndex].QF_MERCHANT_SERVICE_PRID][item.id]=item
        selectedSlotsTemp[services[currentIndex].QF_MERCHANT_SERVICE_PRID]['date_slot']=dateSelected
        if(item.tsPrid)
        {
            if(item.inc_or_dec==='inc')
            {
                slot_multiplier_offer_price=Math.abs(numeral(item.single_offer_price).add(item.inc_or_dec_val).value())
            }
            else if(item.inc_or_dec==='dec')
            {
                slot_multiplier_offer_price=Math.abs(numeral(item.single_offer_price).subtract(item.inc_or_dec_val).value())
            }
        }        
        else
        {
            slot_multiplier_offer_price=item.single_offer_price
        }

        setSelectedSlots(selectedSlotsTemp)
        // console.log("slotDaysArr",slotDaysArr)
        // console.log("timeSlotsArr",timeSlotsArr)
        // console.log("selectedSlots",selectedSlots)
        // console.log("slot_multiplier_offer_price",slot_multiplier_offer_price)
        setSlot_multiplier_offer_price(slot_multiplier_offer_price)
    }

    const previousObject = () => {
        if (currentIndex > 0) 
        {
            setCurrentIndex(currentIndex - 1);
            setServiceItemFocused(services[currentIndex - 1])
        } 
        else if(currentIndex === 0)
        {
            setCurrentIndex(services.length - 1);
            setServiceItemFocused(services[services.length - 1])
        }
            setSlotDays({})
            setSlotDaysArr([])
            setSlot_multiplier_offer_price(0)
            updateSlotsToServices()
    };

    const nextObject = () => {
        if (currentIndex < services.length - 1) 
        {
            setCurrentIndex(currentIndex + 1);
            setServiceItemFocused(services[currentIndex + 1])
        }
        else if(currentIndex === services.length - 1)
        {
            setCurrentIndex(0);
            setServiceItemFocused(services[0])
        }
            setSlotDays({})
            setSlotDaysArr([])
            setSlot_multiplier_offer_price(0)
            updateSlotsToServices()
    };

    const updateSlotsToServices=()=>{
        // console.log("selectedSlots in updateSlotsToServices",selectedSlots)
        if(Object.keys(selectedSlots).length!==0 && services.length!==0)
        {
            services.map(serviceItem=>{
                if(selectedSlots[serviceItem.QF_MERCHANT_SERVICE_PRID])
                {
                    if(Object.keys(selectedSlots[serviceItem.QF_MERCHANT_SERVICE_PRID]).length!==0)
                    {
                        for(let key in selectedSlots[serviceItem.QF_MERCHANT_SERVICE_PRID])
                        {
                            if(key=='date_slot')
                            {
                                // console.log("inside date_slot")
                                serviceItem.dateSlot=selectedSlots[serviceItem.QF_MERCHANT_SERVICE_PRID]['date_slot']
                            }
                            else
                            {
                                // console.log("else date_slot")
                                serviceItem.timeSlot=selectedSlots[serviceItem.QF_MERCHANT_SERVICE_PRID][key]
                            }
                        }
                    }
                }
            })
        }
    }

    const navToCheckout=()=>{
        // console.log("navToCheckout",services)

        updateSlotsToServices()
        // console.log("services on confirm",services)
        updateMultipleInCart(services,state,dispatch)

        
        props.onSelectTimeSlot();
        props.onPressCancel();
        // navigation.navigate("CheckoutPage",{couponApplied, couponCode, prevCouponCode,userPasswordReg:params.userPasswordReg,userId:params.userId?params.userId:null})
    }

    let DateList;
    // console.log("slotDaysArr",slotDaysArr)
    if(currentIndex !== -1){
        DateList = slotDaysArr.map((item, index) => {
                        return (
                            <DateListContainer onPress={()=>onDateSelect(item,false,false)} date={moment(item.date).format('DD')} month={moment(item.date).format('MMM')} day={item.dayOfWeek} isSelected={item.date===dateSelected.date}/>
                        )
                    })
    }

    let TimeList;
    let timeSlotsArr = slotDays[dateSelected.date]?(slotDays[dateSelected.date].slots?slotDays[dateSelected.date].slots:[]):[]
    // console.log("slotDaysArr",timeSlotsArr)
    if(currentIndex !== -1){
        if(timeSlotsArr.length !== 0){
            TimeList = timeSlotsArr.map((item, index) => {
                            return (
                                <TimeListContainer serviceItem={serviceItemFocused} selectedSlots={selectedSlots} onPress={()=>onSlotSelect(item,index)} slotItem={item} price={item.price} selectedSlot={item.selectedSlot} demandPrice={item.demandPrice}/>
                            )
                        })
        }
    }

    return (
        <div className="mainDiv_slotSelection">
            <div className="serviceDetailsDiv">
                {
                    services.length>1?
                        <div onClick={() => previousObject()} style={{cursor:'pointer'}}>
                            <img src={RightArrowIcon} />
                        </div>
                    :null
                }
                {
                    services.length!==0
                    ?
                        <div className="serviceNamePriceDiv" style={{marginHorizontal:20,flexWrap:'wrap'}}>
                            <text className="serviceNameTextStyle">{services[currentIndex].SERVICE_NAME}</text>
                            <text className="serivePriceStyle">{(services[currentIndex].SERVICE_CURRENCY === "Rupees" ? ("\u20B9") : "$") + (slot_multiplier_offer_price?slot_multiplier_offer_price:services[currentIndex].service_offer_price)}</text>
                        </div>
                    :
                    null
                }
                {
                    services.length>1?
                        <div onClick={() => nextObject()} style={{cursor:'pointer'}}>
                            <img src={LeftArrowIcon} />
                        </div>
                    :null
                }
            </div>

                {loading ?
                    <LoadingSpinner loading={true} />
                    : 
                    slotDaysArr.length !== 0 ?
                        <div className="dateListMainDiv">
                            {DateList}
                        </div>
                    :
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', padding:30}}>
                            <text className="CancellationChargeText">No dateslot found</text>
                        </div>
                }

            <hr className="hrSeperator"></hr>

            <div className="demandDescStyle">

                <div className={"demandHourContainer"}>
                    <div className={"demandImageStyle"} style={{backgroundColor:'#5ba85a'}}>
                        <img resizeMode='contain' src={LowDemandIcon} style={{alignSelf:'center'}}/>
                    </div>
                        <text className={"demandHourText"}>Low Demand Hours</text>
                </div>

                <div className={"demandHourContainer"}>
                    <div className={"demandImageStyle"} style={{backgroundColor:'#8c52ff'}}>
                        <img resizeMode='contain' src={NormalDemandIcon} style={{marginTop:2,alignSelf:'center'}} />
                    </div>
                        <text className={"demandHourText"}>Normal Demand Hours</text>
                </div>

                <div className={"demandHourContainer"}>
                    <div className={"demandImageStyle"} style={{backgroundColor:'#ff5d5d'}}>
                        <img resizeMode='contain' src={HighDemandIcon} />
                    </div>
                        <text className={"demandHourText"}>High Demand Hours</text>
                </div>
            </div>

            {loading ?
                <LoadingSpinner loading={true} />
                : 
                timeSlotsArr.length !==0 ? 
                    <div className="timeListMainDiv">
                        {TimeList}
                    </div>
                :
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', padding:30}}>
                        <text className="CancellationChargeText">No timeslot found</text>
                    </div>
            }
            

            {slotDaysArr.length !== 0 || timeSlotsArr.length !==0 ?
                // selectedSlots.length !==0 ?
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <SubmitButtonSmall onPress={navToCheckout} Radius={10} width={isTab?200:300} buttonColor='#8c52ff' buttonTitle="Confirm Date & Time" buttonTextColor='#ffffff' borderColor='#8c52ff'/>
                    </div>
            //     :
            //         <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            //             <SubmitButtonSmall onPress={''} Radius={10} width={isTab?200:300} buttonColor='#747474' buttonTitle="Confirm Date & Time" buttonTextColor='#ffffff' borderColor='#8c52ff'/>
            //         </div>
            : null}

        </div>
    )
}