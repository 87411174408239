import React, { useContext } from 'react'
import '../../CSS/CitySpecificBannerHome.css'
import { globalState } from '../../GlobalState/globalState'
import { useMediaQuery } from 'react-responsive'
export default function CitySpecificBannerHome(props) {
  const useMobileAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 760px)" })
  const isMobileView = useMobileAndBelowMediaQuery()
  const [state,dispatch]=useContext(globalState)
    let cityImage;
    if(isMobileView){
      cityImage=state.address.country == 'India' ? 'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684328101328-lg.png' : 'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684328089883-lg.png'
    }else{
      cityImage=state.address.country == 'India' ? 'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684324203089-lg.png' : 'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684324333119-lg.png'
    }
    
  return (
    <div className='mainBanner_View' style={{background: `linear-gradient(to left, #1c1b1f, rgba(70, 28, 153, 0.25)),url(${cityImage})`}}>
        <div className="textDiv_OnCityImage">
            <text className='cityWlc_mainText'>{"Hello! " +(state.address.city ? state.address.city : '')}</text>
            <text className='cityWlc_mainText'> {props.title ? props.title :'Slash is now available in your city'}</text>
            <text className='cityWlc_subText'>{props.desc ? props.desc : 'Slash offers a wide range of auto and personal care services so you can find the right professional for your needs, book appointments easily, and get special discounts.'}</text>
        </div>
    </div>
  )
}
