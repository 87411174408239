import React from 'react';
import {isAndroid, isIOS} from 'react-device-detect';
import {Navbar,
        NavbarBrand,
        Nav ,Row, NavLink,NavbarToggler,Collapse, Col, Modal, FormGroup, Input, Button, FormFeedback,ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
       } from 'reactstrap';
import { NavLink as NavLinkReactor } from 'react-router-dom';
import logo from '../Assets/Commons/finalLogo01.png';
import Modallogo from '../Assets/Commons/Qind_FinalLogo-03.jpg';
import cross from '../Assets/Merchant/Vector 7.png';
import '../Components/Landing_Page/LandingPage_CssFiles/NavbarLandingPage.css';
import AppStore from '../Assets/Commons/appstore 1.png';
import GooglePlay from '../Assets/Commons/googleplay 1.png';
import DownloadModalImage from '../Assets/Commons/download-modal.png'
import DownloadModalImage1 from '../Assets/Commons/download banner@copy.png'
import axios from 'axios';
import '../../WebsiteDesign/loader.css';
import logoPopup from'../../New_WebPage_Design/Assets/Commons/pop-up2img.png'
import logo2 from'../../New_WebPage_Design/Assets/Commons/Pop-up-Illustration.png'
import couponBox from'../../New_WebPage_Design/Assets/Commons/coupon box.png'
import Linkedin from'../../New_WebPage_Design/Assets/Commons/linkedin.png'
import Pinterest from'../../New_WebPage_Design/Assets/Commons/pinterest.png'
import Twitter from'../../New_WebPage_Design/Assets/Commons/Twitter.png'
import Facebook from'../../New_WebPage_Design/Assets/Commons/facebook.png'
import Instagram from'../../New_WebPage_Design/Assets/Commons/Instagram.png'
import Youtube from'../../New_WebPage_Design/Assets/Commons/youtube.png'
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from "react-responsive";
import { HashLink } from 'react-router-hash-link';
import DownloadModalImageNew from '../../New_WebPage_Design/Assets/Landing_Page/illustration 23.png'
import {BsGlobe} from 'react-icons/bs'
import {connect} from "react-redux";
import {setIPCountry} from '../../Stores/dispatchers' 

// import queryString from 'query-string';

let countryChangeCounter=0;

const useTabletAndBelowMediaQuery = () =>
  useMediaQuery({ query: "(max-width: 760px)" })

//   const Mobile = ({ children }) => {
//     const isMobile = useTabletAndBelowMediaQuery()
//     console.log('entered')
//     // return isMobile ? <WebFooter_mobile active={1}/> : <WebFooter active={1}/>
//     return isMobile ? s() :null
//     // return isMobile ? children : null
// }




var url='';
 var count=0;
 var count2=0;

//  var url2=window.location.href;



class NewNavbar_Website extends React.Component {


    

   constructor(props){
       super(props);
    

    this.state={

        collapsed:false,
        clicked:false,
        showDownloadNowModal:false,
        loading1: false,
        loading2: false,
        sentEmail: false,
        sentMobile: false,
        email:{
            value:'',
            valid:false,
            invalid:false
        },
        mobile:{
            value:'',
            valid:false,
            invalid:false
        },
        submit:{
            invalid:false
        },
        PopupModelVar:false,
        PopupModelVar2:false,
        visited:false,
        // mobilePopUpVar:false,
        mobilePopUpVar2:false,
        emailModal:{
            value:'',
            valid:false,
            invalid:false
        },
        sentEmailSubscribe:false,
        emailModal2:{
            value:'',
            valid:false,
            invalid:false
        },
        sentEmailGetToStart:false,
        openModal: false,
        showBookNowModal:false,
        sentLandMobile: false,
        sentLandEmail:false,
        mobileEntry:false,
        emailEntry:false,
        page:this.props.page?this.props.page:this.props.state?this.props.state.page?this.props.state.page:this.props.state.docs?this.props.state.docs:this.props.state:"Consumer",
        countryName:null
    }
    
}



    

  componentDidMount() {
    
    //  if (countryChangeCounter<1){
        let data=[];

        (async function getGeoInfo(){
           countryChangeCounter=1 
           await axios.get('https://ipapi.co/json/?key=fd1b56f1a7e14bc7aa238e2d7e3b15d7166ea28c')
            .then((response) => {
                // console.log("response in nav", response)
                data = response.data
                this.props.onsetIPCountryHandler(response.data.country)

            }).catch((error) => {
                console.log(error);
            });
        }).bind(this)()
    //   } 


        if(window.innerWidth>768){


           var mouseY = 0;
           var topValue = 0;
           window.addEventListener("mouseout",(e)=>{
            if(count===0)
            {
                    mouseY = e.clientY;
                    if(mouseY<topValue) {                
                        this.setState({PopupModelVar2:true,visited:true});

                        count=1;
            }
            }},false);
            
          }else{ 


        //     window.addEventListener("mouseout",(e)=>{
        //         mouseY = e.clientY;
        //          if(mouseY<topValue) {                
        //               this.setState({PopupModelVar2:true});
     
        //               console.log("done");
        //               count=1;
                      
        //          }},{once:true});
        //    this.setState({PopupModelVar2:!this.state.PopupModelVar2});
          }

            if(count2==0&&window.innerWidth>798){

                setTimeout(()=>{

                    console.log("Set time out 1",window.innerWidth)

                    if(this.state.PopupModelVar2 || this.state.visited){
                        this.setState({PopupModelVar:false});
                    } else {
                        this.setState({PopupModelVar:true});
                        count2=1
                    }
                },10000)
            }else{
                this.setState({PopupModelVar:false});
            }

          //mobile


        //   if(count!=1&&window.innerWidth<768){



        //     var mouseY = 0;
        //     var topValue = 0;
        //   window.addEventListener("mouseout",(e)=>{

        //     if(count===0)
        //     {
              
        //     mouseY = e.clientY;
        //     if(mouseY<topValue) {                
        //          this.setState({mobilePopUpVar:true});
        //          count=1;
        //     }
        //     }},false);
            
        //     }else{ 
        //     //    this.setState({mobilePopUpVar:false});
        //     }




          

          if(count2!==1&&window.innerWidth<=798){


            setTimeout(()=>{
        
              this.setState({mobilePopUpVar2:true});
              count2=1
            },10000)
           }else{
              this.setState({mobilePopUpVar2:false});
          }
     

         
    }

    onClickDownload = () => {

        if(this.props.active ==1){

            if(isAndroid){
                try{
                    window.location.href='https://play.google.com/store/apps/details?id=com.quickfinduser'
                    this.setState({loading: false, catch: false})
                } catch(err1){
                    console.log("err1", err1);
                    this.setState({catch: true, loading: false})
                }

            } else if(isIOS){
                try{
                    window.location.href='https://apps.apple.com/us/app/slash-consumer/id1489207325?ls=1'
                    this.setState({loading: false, catch: false})
                } catch(err2){
                    console.log("err2", err2);
                    this.setState({catch: true, loading: false})
                }

            } else {
                try{
                    window.location.href='/guide/customer'
                    this.setState({loading: false, catch: false})
                } catch(err3){
                    console.log("err3", err3);
                    this.setState({catch: true, loading: false})
                }

            }
        } 
        else if(this.props.active == 2){

            if(isAndroid){
                try{
                    window.location.href='https://play.google.com/store/apps/details?id=com.quickfindmerchant'
                    this.setState({loading: false, catch: false})
                } catch(err1){
                    console.log("err1", err1);
                    this.setState({catch: true, loading: false})
                }

            } else if(isIOS){
                try{
                    window.location.href='https://apps.apple.com/us/app/slash-merchant/id1489211413?ls=1'
                    this.setState({loading: false, catch: false})
                } catch(err2){
                    console.log("err2", err2);
                    this.setState({catch: true, loading: false})
                }

            } else {
                try{
                    window.location.href='/guide/customer'
                    this.setState({loading: false, catch: false})
                } catch(err3){
                    console.log("err3", err3);
                    this.setState({catch: true, loading: false})
                }

            }
        }
        else if(this.props.active ==99 && this.state.page=="Merchant" ){

            if(isAndroid){
                try{
                    window.location.href='https://play.google.com/store/apps/details?id=com.quickfindmerchant'
                    this.setState({loading: false, catch: false})
                } catch(err1){
                    console.log("err1", err1);
                    this.setState({catch: true, loading: false})
                }

            } else if(isIOS){
                try{
                    window.location.href='https://apps.apple.com/us/app/slash-merchant/id1489211413?ls=1'
                    this.setState({loading: false, catch: false})
                } catch(err2){
                    console.log("err2", err2);
                    this.setState({catch: true, loading: false})
                }

            } else {
                try{
                    window.location.href='/guide/customer'
                    this.setState({loading: false, catch: false})
                } catch(err3){
                    console.log("err3", err3);
                    this.setState({catch: true, loading: false})
                }

            }
        }
        else if(this.props.active ==88 && this.state.page=="Merchant" ){

            if(isAndroid){
                try{
                    window.location.href='https://play.google.com/store/apps/details?id=com.quickfindmerchant'
                    this.setState({loading: false, catch: false})
                } catch(err1){
                    console.log("err1", err1);
                    this.setState({catch: true, loading: false})
                }

            } else if(isIOS){
                try{
                    window.location.href='https://apps.apple.com/us/app/slash-merchant/id1489211413?ls=1'
                    this.setState({loading: false, catch: false})
                } catch(err2){
                    console.log("err2", err2);
                    this.setState({catch: true, loading: false})
                }

            } else {
                try{
                    window.location.href='/guide/customer'
                    this.setState({loading: false, catch: false})
                } catch(err3){
                    console.log("err3", err3);
                    this.setState({catch: true, loading: false})
                }

            }
        }
        else if(this.props.active ==88 && this.state.page=="Consumer" ){

            if(isAndroid){
                try{
                    window.location.href='https://play.google.com/store/apps/details?id=com.quickfinduser'
                    this.setState({loading: false, catch: false})
                } catch(err1){
                    console.log("err1", err1);
                    this.setState({catch: true, loading: false})
                }

            } else if(isIOS){
                try{
                    // window.location.href='https://apps.apple.com/us/app/slash-merchant/id1489211413?ls=1'
                    window.location.href='https://apps.apple.com/us/app/slash-consumer/id1489207325?ls=1'
                    this.setState({loading: false, catch: false})
                } catch(err2){
                    console.log("err2", err2);
                    this.setState({catch: true, loading: false})
                }

            } else {
                try{
                    window.location.href='/guide/customer'
                    this.setState({loading: false, catch: false})
                } catch(err3){
                    console.log("err3", err3);
                    this.setState({catch: true, loading: false})
                }

            }
        }
        else if(this.props.active ==99 && this.state.page=="Consumer" ){

            if(isAndroid){
                try{
                    window.location.href='https://play.google.com/store/apps/details?id=com.quickfinduser'
                    this.setState({loading: false, catch: false})
                } catch(err1){
                    console.log("err1", err1);
                    this.setState({catch: true, loading: false})
                }

            } else if(isIOS){
                try{
                    // window.location.href='https://apps.apple.com/us/app/slash-merchant/id1489211413?ls=1'
                    window.location.href='https://apps.apple.com/us/app/slash-consumer/id1489207325?ls=1'
                    this.setState({loading: false, catch: false})
                } catch(err2){
                    console.log("err2", err2);
                    this.setState({catch: true, loading: false})
                }

            } else {
                try{
                    window.location.href='/guide/customer'
                    this.setState({loading: false, catch: false})
                } catch(err3){
                    console.log("err3", err3);
                    this.setState({catch: true, loading: false})
                }

            }
        }
        else if(this.props.active ==3 && this.state.page=="Merchant" ){

            if(isAndroid){
                try{
                    window.location.href='https://play.google.com/store/apps/details?id=com.quickfindmerchant'
                    this.setState({loading: false, catch: false})
                } catch(err1){
                    console.log("err1", err1);
                    this.setState({catch: true, loading: false})
                }

            } else if(isIOS){
                try{
                    window.location.href='https://apps.apple.com/us/app/slash-merchant/id1489211413?ls=1'
                    this.setState({loading: false, catch: false})
                } catch(err2){
                    console.log("err2", err2);
                    this.setState({catch: true, loading: false})
                }

            } else {
                try{
                    window.location.href='/guide/customer'
                    this.setState({loading: false, catch: false})
                } catch(err3){
                    console.log("err3", err3);
                    this.setState({catch: true, loading: false})
                }

            }
        }
        else if(this.props.active ==5 && this.state.page=="Merchant"){


            console.log('if');

            if(isAndroid){
                try{
                    window.location.href='https://play.google.com/store/apps/details?id=com.quickfindmerchant'
                    this.setState({loading: false, catch: false})
                } catch(err1){
                    console.log("err1", err1);
                    this.setState({catch: true, loading: false})
                }

            } else if(isIOS){
                try{
                    window.location.href='https://apps.apple.com/us/app/slash-merchant/id1489211413?ls=1'
                    this.setState({loading: false, catch: false})
                } catch(err2){
                    console.log("err2", err2);
                    this.setState({catch: true, loading: false})
                }

            } else {
                try{
                    window.location.href='/guide/customer'
                    this.setState({loading: false, catch: false})
                } catch(err3){
                    console.log("err3", err3);
                    this.setState({catch: true, loading: false})
                }

            }
        }
        else if(this.props.active ==5 && this.state.page=="Consumer" ){

            console.log('elseif');


            if(isAndroid){
                try{
                    window.location.href='https://play.google.com/store/apps/details?id=com.quickfinduser'
                    this.setState({loading: false, catch: false})
                } catch(err1){
                    console.log("err1", err1);
                    this.setState({catch: true, loading: false})
                }

            } else if(isIOS){
                try{
                    // window.location.href='https://apps.apple.com/us/app/slash-merchant/id1489211413?ls=1'
                    window.location.href='https://apps.apple.com/us/app/slash-consumer/id1489207325?ls=1'
                    this.setState({loading: false, catch: false})
                } catch(err2){
                    console.log("err2", err2);
                    this.setState({catch: true, loading: false})
                }

            } else {
                try{
                    window.location.href='/guide/customer'
                    this.setState({loading: false, catch: false})
                } catch(err3){
                    console.log("err3", err3);
                    this.setState({catch: true, loading: false})
                }

            }
        }
        else if(this.props.active ==3 && this.state.page=="Consumer" ){

            if(isAndroid){
                try{
                    window.location.href='https://play.google.com/store/apps/details?id=com.quickfinduser'
                    this.setState({loading: false, catch: false})
                } catch(err1){
                    console.log("err1", err1);
                    this.setState({catch: true, loading: false})
                }

            } else if(isIOS){
                try{
                    // window.location.href='https://apps.apple.com/us/app/slash-merchant/id1489211413?ls=1'
                    window.location.href='https://apps.apple.com/us/app/slash-consumer/id1489207325?ls=1'
                    this.setState({loading: false, catch: false})
                } catch(err2){
                    console.log("err2", err2);
                    this.setState({catch: true, loading: false})
                }

            } else {
                try{
                    window.location.href='/guide/customer'
                    this.setState({loading: false, catch: false})
                } catch(err3){
                    console.log("err3", err3);
                    this.setState({catch: true, loading: false})
                }

            }
        }
        else if(this.props.active ==4 && this.state.page=="Merchant" ){

            if(isAndroid){
                try{
                    window.location.href='https://play.google.com/store/apps/details?id=com.quickfindmerchant'
                    this.setState({loading: false, catch: false})
                } catch(err1){
                    console.log("err1", err1);
                    this.setState({catch: true, loading: false})
                }

            } else if(isIOS){
                try{
                    window.location.href='https://apps.apple.com/us/app/slash-merchant/id1489211413?ls=1'
                    this.setState({loading: false, catch: false})
                } catch(err2){
                    console.log("err2", err2);
                    this.setState({catch: true, loading: false})
                }

            } else {
                try{
                    window.location.href='/guide/customer'
                    this.setState({loading: false, catch: false})
                } catch(err3){
                    console.log("err3", err3);
                    this.setState({catch: true, loading: false})
                }

            }
        }
        else if(this.props.active ==4 && this.state.page=="Consumer" ){

            if(isAndroid){
                try{
                    window.location.href='https://play.google.com/store/apps/details?id=com.quickfinduser'
                    this.setState({loading: false, catch: false})
                } catch(err1){
                    console.log("err1", err1);
                    this.setState({catch: true, loading: false})
                }

            } else if(isIOS){
                try{
                    // window.location.href='https://apps.apple.com/us/app/slash-merchant/id1489211413?ls=1'
                    window.location.href='https://apps.apple.com/us/app/slash-consumer/id1489207325?ls=1'
                    this.setState({loading: false, catch: false})
                } catch(err2){
                    console.log("err2", err2);
                    this.setState({catch: true, loading: false})
                }

            } else {
                try{
                    window.location.href='/guide/customer'
                    this.setState({loading: false, catch: false})
                } catch(err3){
                    console.log("err3", err3);
                    this.setState({catch: true, loading: false})
                }

            }
        }
        else {
            if(isAndroid){
                try{
                    window.location.href='https://play.google.com/store/apps/details?id=com.quickfinduser'
                    this.setState({loading: false, catch: false})
                } catch(err1){
                    console.log("err1", err1);
                    this.setState({catch: true, loading: false})
                }

            } else if(isIOS){
                try{
                    window.location.href='https://apps.apple.com/us/app/slash-consumer/id1489207325?ls=1'
                    this.setState({loading: false, catch: false})
                } catch(err2){
                    console.log("err2", err2);
                    this.setState({catch: true, loading: false})
                }

            } else {
                try{
                    window.location.href='/guide/customer'
                    this.setState({loading: false, catch: false})
                } catch(err3){
                    console.log("err3", err3);
                    this.setState({catch: true, loading: false})
                }

            }
            
        }
    }

    toggleNavbar=()=>{


        this.setState({collapsed:!this.state.collapsed,clicked:!this.state.clicked})
    }

    toggleNavbar2=()=>{


        this.setState({collapsed:!this.state.collapsed,clicked:!this.state.clicked})
    }
    emailHandler =  (event) => {

        event.preventDefault();

        let k=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(event.target.value.length === 0 || event.target.value === ''){
            this.setState({
                email :{ 
                ...this.state.email,
                value:'',
                valid:false,
                invalid:true
                },
                submit:{
                    ...this.state.submit,
                    invalid:true
                },
                sentEmail: false
            })
        }
        else{
            if(k.test(event.target.value)){
                this.setState({
                    email:{
                        ...this.state.email,
                        value:event.target.value,
                        valid:true,
                        invalid:false
                    },
                    submit:{
                        ...this.state.submit,
                        invalid:false
                    }, sentEmail: false
                })
    
            }else{
                this.setState({
                    email:{
                        ...this.state.email,
                        value:event.target.value,
                        valid:false,
                        invalid:true
                    },
                    submit:{
                        ...this.state.submit,
                        invalid:true
                    },
                    sentEmail: false
                })
            }
        }        
    }
    mobileHandler =  (event) => {
        let k=/^[0-9\b]+$/;
        if(event.target.value.length===0){
            this.setState({
                mobile:{
                value:event.target.value,
                invalid:true,
                valid:false
            },
                submit: {
                    ...this.state.submit,
                    invalid: true 
                },
                sentMobile: false
            })
                
            }
        
      else if(event.target.value===''||k.test(event.target.value)){
            this.setState({
                mobile: {
                    ...this.state.phone,
                    value: event.target.value,
                    valid: true,
                    invalid: false
                },
                submit: {
                    ...this.state.submit,
                    invalid: false
                }, sentMobile: false
            });
        }
    }
    submitEmailHandler = () => {
        let k=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(this.state.email.invalid || this.state.email.value === '' || !(k.test(this.state.email.value))){
            this.setState({
                email:{
                ...this.state.email,
                valid:false,
                invalid:true
                },
                submit:{
                    invalid:true
                }
            })
                        
        }else{
            this.setState({
                email:{
                    ...this.state.email,
                    valid:true,
                    invalid:false
                },
                loading1: true
            })
            let body ={
                userType: this.props.active == 1 || this.props.type == "Customer" ? "Download_Customer" : "Download_Professionals",
                email: this.state.email.value ? this.state.email.value : null,
                typeEmail:true,
                typeMobile: false
            }
            axios.post('/website/download/sendLink',body)
            .then(res=>{
                if(res.data.data){
                    // console.log('inserted email');
                    this.setState({
                        email:{
                            ...this.state.email,
                            value:'',
                            valid:false,
                            invalid:false
                        },
                        loading1: false,
                        sentEmail: true
                    })
                }else{
                    if(res.data.dbError){
                        console.log('db error')
                        this.setState({loading1: false})
                    }else{
                        console.log('error')
                        this.setState({loading1: false})
                    }
                }
            })
            .catch(error=>{
                console.log(error)
                this.setState({loading1: false})
            })
        }   
    }
    submitMobileHandler = () =>{
        let km=/^[0-9\b]+$/;
        if(this.state.mobile.invalid || this.state.mobile.value === '' || !(km.test(this.state.mobile.value))){
            this.setState({
                mobile:{
                ...this.state.mobile,
                valid:false,
                invalid:true
                },
                submit:{
                    invalid:true
                }
            })
        }else{
            this.setState({
                mobile:{
                    ...this.state.mobile,
                    valid:true,
                    invalid:false
                },
                loading2: true
            })
            let body={
                userType: this.props.active == 1 || this.props.type == "Customer" ? "Download_Customer" : "Download_Professionals",
                mobile:this.state.mobile.value ? this.state.mobile.value : null,
                typeEmail:false,
                typeMobile: true
            }
            axios.post('/website/download/sendLink',body)
            .then(res=>{
                if(res.data.data){
                    // console.log('inserted mobile');
                    this.setState({
                        mobile:{
                            ...this.state.mobile,
                            value:'',
                            valid:false,
                            invalid:false
                        },
                        loading2: false,
                        sentMobile: true
                    })
                }
                else{
                    if(res.data.dbError){
                        console.log('db error')
                        this.setState({loading2: false})
                    }else{
                        console.log('error')
                        this.setState({loading2: false})
                    }
                }
            })
            .catch(error=>{
                    console.log('catch error')
                    this.setState({loading2: false})
            })
        }
    }

    closeWeb = () =>{

        this.setState({PopupModelVar2:false})

        if(count2==0&&window.innerWidth>768){

            setTimeout(()=>{

                console.log("Set time out 2")

                 if(this.state.PopupModelVar2){
                    this.setState({PopupModelVar:false});
                } else {

                    this.setState({PopupModelVar:true});
                    count2=1
                }
        
            },10000)
           }else{
              this.setState({PopupModelVar:false});
          }

       }

   close = () =>{

    
    this.setState({mobilePopUpVar2:false});
   }
   emailSubscribeHandler =  (event) => {
    event.preventDefault();
    let k=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(event.target.value.length === 0 || event.target.value === ''){
        this.setState({
            emailModal :{
            ...this.state.emailModal,
            value:'',
            valid:false,
            invalid:true
            },
            submit:{
                ...this.state.submit,
                invalid:true
            },
            sentEmailSubscribe: false
        })
    }
    else{
        if(k.test(event.target.value)){
            this.setState({
                emailModal:{
                    ...this.state.emailModal,
                    value:event.target.value,
                    valid:true,
                    invalid:false
                },
                submit:{
                    ...this.state.submit,
                    invalid:false
                }, sentEmailSubscribe: false
            })
        }else{
            this.setState({
                emailModal:{
                    ...this.state.emailModal,
                    value:event.target.value,
                    valid:false,
                    invalid:true
                },
                submit:{
                    ...this.state.submit,
                    invalid:true
                },
                sentEmailSubscribe: false
            })
        }
    }
}
submitEmailSubscribeHandler = () => {
    let k=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(this.state.emailModal.invalid || this.state.emailModal.value === '' || !(k.test(this.state.emailModal.value))){
        this.setState({
            emailModal:{
            ...this.state.emailModal,
            valid:false,
            invalid:true
            },
            submit:{
                invalid:true
            }
        })
    }else{
        this.setState({
            emailModal:{
                ...this.state.emailModal,
                valid:true,
                invalid:false
            },
            loading1: true
        })
        let body ={
            userType: this.props.active == 1 || this.props.type == "Customer" ? "Download_Customer" : "Download_Professionals",
            email: this.state.emailModal.value ? this.state.emailModal.value : null,
            typeEmail:true,
            typeMobile: false
        }
        axios.post('/website/download/sendLink',body)
        .then(res=>{
            if(res.data.data){
                console.log('inserted email');
                this.setState({
                    emailModal:{
                        ...this.state.emailModal,
                        value:'',
                        valid:false,
                        invalid:false
                    },
                    loading1: false,
                    sentEmailSubscribe: true
                })
            }else{
                if(res.data.dbError){
                    console.log('db error')
                    this.setState({loading1: false})
                }else{
                    console.log('error')
                    this.setState({loading1: false})
                }
            }
        })
        .catch(error=>{
            console.log(error)
            this.setState({loading1: false})
        })
    }
}
emailGetToStartHandler =  (event) => {
    event.preventDefault();
    let k=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(event.target.value.length === 0 || event.target.value === ''){
        this.setState({
            emailModal2 :{
            ...this.state.emailModal2,
            value:'',
            valid:false,
            invalid:true
            },
            submit:{
                ...this.state.submit,
                invalid:true
            },
            sentEmailGetToStart: false
        })
    }
    else{
        if(k.test(event.target.value)){
            this.setState({
                emailModal2:{
                    ...this.state.emailModal2,
                    value:event.target.value,
                    valid:true,
                    invalid:false
                },
                submit:{
                    ...this.state.submit,
                    invalid:false
                }, sentEmailGetToStart: false
            })
        }else{
            this.setState({
                emailModal2:{
                    ...this.state.emailModal2,
                    value:event.target.value,
                    valid:false,
                    invalid:true
                },
                submit:{
                    ...this.state.submit,
                    invalid:true
                },
                sentEmailGetToStart: false
            })
        }
    }
}
submitEmailGetToStartHandler = () => {
    let k=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(this.state.emailModal2.invalid || this.state.emailModal2.value === '' || !(k.test(this.state.emailModal2.value))){
        this.setState({
            emailModal2:{
            ...this.state.emailModal2,
            valid:false,
            invalid:true
            },
            submit:{
                invalid:true
            }
        })
    }else{
        this.setState({
            emailModal2:{
                ...this.state.emailModal2,
                valid:true,
                invalid:false
            },
            loading1: true
        })
        let body ={
            userType: this.props.active == 1 || this.props.type == "Customer" ? "Download_Customer" : "Download_Professionals",
            email: this.state.emailModal2.value ? this.state.emailModal2.value : null,
            typeEmail:true,
            typeMobile: false
        }
        axios.post('/website/download/sendLink',body)
        .then(res=>{
            if(res.data.data){
                console.log('inserted email');
                this.setState({
                    emailModal2:{
                        ...this.state.emailModal2,
                        value:'',
                        valid:false,
                        invalid:false
                    },
                    loading1: false,
                    sentEmailGetToStart: true
                })
            }else{
                if(res.data.dbError){
                    console.log('db error')
                    this.setState({loading1: false})
                }else{
                    console.log('error')
                    this.setState({loading1: false})
                }
            }
        })
        .catch(error=>{
            console.log(error)
            this.setState({loading1: false})
        })
    }
}

showDownloadNowModalNew1 = () =>{
    this.setState({showBookNowModal:true})
}
hideDownloadNowModalNew1 = () =>{
this.setState({showBookNowModal:false,loading1:false,loading2:false,sentLandEmail:false,sentLandMobile:false,emailEntry:false,mobileEntry:false})
}
onChangeEmailMobileHandler = (event) => {
    console.log('value',event.target.value)
    let k=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let km=/^[0-9\b]+$/;
    if(event.target.value.length === 0 || event.target.value === ''){
        this.setState({
            email :{ 
            ...this.state.email,
            value:'',
            valid:false,
            invalid:true
            },
            mobile :{ 
                ...this.state.mobile,
                value:'',
                valid:false,
                invalid:true
            },
            emailEntry:false,mobileEntry:false,
            submit:{
                ...this.state.submit,
                invalid:true
            },
            sentLandEmail: false,
            sentLandMobile:false
        })
    }else if(km.test(event.target.value)){
        this.setState({mobileEntry:true,emailEntry:false,
            email :{ 
            ...this.state.email,
            value:'',
            valid:false,
            invalid:true
            }})
        if(event.target.value===''||km.test(event.target.value)){
            this.setState({
                mobile: {
                    ...this.state.mobile,
                    value: event.target.value,
                    valid: true,
                    invalid: false
                },
                email :{ 
                    ...this.state.email,
                    value:'',
                    valid:false,
                    invalid:true
                },
                mobileEntry:true,
                emailEntry:false,
                submit: {
                    ...this.state.submit,
                    invalid: false
                }, sentLandMobile: false
            });
        }
    }  
    else{
        console.log('entered into email', this.state.email.value)
        this.setState({emailEntry:true,mobileEntry:false,
            mobile :{ 
                ...this.state.mobile,
                value:'',
                valid:false,
                invalid:true
        }})
        if(k.test(event.target.value)){
            console.log('entered into email2')
            this.setState({
                email:{
                    ...this.state.email,
                    value:event.target.value,
                    valid:true,
                    invalid:false,
                },
                mobile :{ 
                    ...this.state.mobile,
                    value:'',
                    valid:false,
                    invalid:true
                },
                emailEntry:true,
                mobileEntry:false,
                submit:{
                    ...this.state.submit,
                    invalid:false
                }, sentLandEmail: false
            })

        }else{
            console.log("email else part")
            this.setState({
                ...this.state,
                email:{
                    ...this.state.email,
                    value: event.target.value,
                    valid:false,
                    invalid:true,
                },
                mobile :{ 
                    ...this.state.mobile,
                    value:'',
                    valid:false,
                    invalid:true
                },
                emailEntry:true,
                mobileEntry:false,
                submit:{
                    ...this.state.submit,
                    invalid:true
                },
                sentLandEmail: false
            })
        }
    } 
}
submitEmailMobileHandler = () =>{
    let k=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let km=/^[0-9\b]+$/;
    if(this.state.emailEntry){
        if(this.state.email.invalid || this.state.email.value === '' || !(k.test(this.state.email.value))){
            this.setState({
                email:{
                ...this.state.email,
                valid:false,
                invalid:true
                },
                submit:{
                    invalid:true
                }
            })
                        
        }else{
            this.setState({
                email:{
                    ...this.state.email,
                    valid:true,
                    invalid:false
                },
                loading1: true
            })
            let body ={
                userType: this.props.active == 1 || this.props.type == "Customer" ? "Download_Customer" : "Download_Professionals",
                email: this.state.email.value ? this.state.email.value : null,
                typeEmail:true,
                typeMobile: false
            }
            axios.post('/website/download/sendLink',body)
            .then(res=>{
                if(res.data.data){
                    // console.log('inserted email');
                    this.setState({
                        email:{
                            ...this.state.email,
                            value:'',
                            valid:false,
                            invalid:false
                        },
                        loading1: false,
                        sentLandEmail: true
                    })
                }else{
                    if(res.data.dbError){
                        console.log('db error')
                        this.setState({loading1: false})
                    }else{
                        console.log('error')
                        this.setState({loading1: false})
                    }
                }
            })
            .catch(error=>{
                console.log(error)
                this.setState({loading1: false})
            })
        }

    }else if(this.state.mobileEntry){
            if (this.state.mobile.invalid || this.state.mobile.value.length === '' || this.state.mobile.value.length!=10 || !(km.test(this.state.mobile.value))){
                this.setState({
                    mobile:{
                        ...this.state.mobile,
                        valid:false,
                        invalid:true
                        },
                    submit:{
                        invalid:true
                    }
                })
            }
            else {
                this.setState({
                    mobile:{
                        ...this.state.mobile,
                        valid:true,
                        invalid:false
                    },
                    loading2: true
                })
                let body={
                    userType: this.props.active == 1 || this.props.type == "Customer" ? "Download_Customer" : "Download_Professionals",
                    mobile:this.state.mobile.value ? this.state.mobile.value : null,
                    typeEmail:false,
                    typeMobile: true
                }
                axios.post('/website/download/sendLink',body)
                .then(res=>{
                    if(res.data.data){
                        // console.log('inserted mobile');
                        this.setState({
                            mobile:{
                                ...this.state.mobile,
                                value:'',
                                valid:false,
                                invalid:false
                            },
                            loading2: false,
                            sentLandMobile: true
                        })
                    }
                    else{
                        if(res.data.dbError){
                            console.log('db error')
                            this.setState({loading2: false})
                        }else{
                            console.log('error')
                            this.setState({loading2: false})
                        }
                    }
                })
                .catch(error=>{
                        console.log('catch error')
                        this.setState({loading2: false})
                })
            }   
    }else{
        if(this.state.email.invalid || this.state.email.value.length === 0 || !(k.test(this.state.email.value)) ){
            this.setState({
            emailEntry:true,
            email:{
            ...this.state.email,
            valid:false,
            invalid:true
            },
            submit:{
                invalid:true
            }
        })

        }else{
            if(this.state.mobile.invalid || this.state.mobile.value.length === 0 || !(km.test(this.state.mobile.value))){
                this.setState({
                    mobileEntry:true,
                    mobile:{
                        ...this.state.mobile,
                        valid:false,
                        invalid:true
                    },
                    submit:{
                        invalid:true
                    }
                })
            }
        }
        
    }
}

countryHandler = (event) => {


    switch(event) {
        case "india":
        return (
                this.setState({
                    country: {
                        ...this.state.country,
                        name: 'India'
                    }
                }),

                this.props.countryIndiaHandler(this.state.country)
        )
        case "usa":
        return (
                this.setState({
                    country: {
                        ...this.state.country,
                        name: 'USA'
                    }
                }),

                this.props.contryUSAHandler(this.state.country)
            )
    }
}


      render () {





        // console.log('debug') 
        url=this.props;
        // console.log('urlmain',window.location.pathname);

        // console.log('urlmain',window);

        // console.log('state',this.state);


        // console.log('Redux props in nav',this.props);
        // console.log('urlFinal',url);



          const activeNav={
              color:"#261640",
              fontWeight:900,
              fontFamily: 'Montserrat',
              textDecoration:'none'
          }

          const activeNavHeader={
            borderBottom:'3px solid #a5d028',
            color:"#261640",
            fontWeight:900,
            fontFamily: 'Montserrat',
            textDecoration:'none'
        }

        const activeNavHeader1={
            borderBottom:'3px solid #f9943b',
            color:"#261640",
            fontWeight:900,
            fontFamily: 'Montserrat',
            padding:10,
            borderRadius:3,
            textDecoration:"none",
            fontSize:18,
        }

        
        
          const subNavStyle={
              color:"#261640",
              padding:'10 0 0 0',
              borderRadius:3,
              textDecoration:"none",
              fontWeight:600,
              fontSize:16,
              fontFamily: 'Montserrat'
          }
        //   const subNavStyleHeader={
        //     // color:"#d1f4fa",
        //     color:"#261640",
        //     paddingTop:'10 0 10 0',
        //     borderRadius:3,
        //     textDecoration:"none",
        //     fontWeight:900,
        //     fontSize:24,
        //     fontFamily: 'Mulish'
        // }

        const activeNav2={
            color:"#ffffff",
            fontWeight:900,
            fontFamily: 'Montserrat',
            textDecoration:'none'
        }

        const subNavStyle2={
            backgroundColor:'#442b6a',
            color:"#ffffff",
            padding:10,
            borderRadius:3,
            textDecoration:"none",
            fontWeight:600,
            fontSize:18,
            fontFamily: 'Montserrat'
        }

       
          


        let popUp;
        let popUp2;
        let mobilePopUp;
      
        if(this.state.PopupModelVar===true&&window.innerWidth>798){
              popUp=(
                        <div  style={{display:'flex',flexDirection:"row",justifyContent:'center'}} >
                        <Modal style={{borderRadius:'15px',backgroundRepeat: 'repeat',  backgroundPosition:'left top',  padding: 20 ,marginTop:100}} isOpen={this.state.PopupModelVar} size='xl'  backdrop="static" >
                        <div className='gradient2' style={{display:'flex', flexDirection:'row',justifyContent:"center",borderRadius:4}}>  
                        <Col className="psstWidthImage"   ><img src={logoPopup} className="imgSizePsst"  height={492}/></Col>
                        <img onClick={()=>this.setState({PopupModelVar:false})} style={{position:"absolute",right:47,marginTop:39,cursor:'pointer'}} width={20} height={20}  src={cross}/>
                       < div style={{padding:25}}/>
                    
                        <Col className="psstWidthBody">  
                        <text className="psst">PSST, HEY YOU!</text><br/>
                        <text className="psst"> YEAH, YOU!</text>
                        <div className='textParaMain' style={{display:'flex',flexDirection:'column',marginBottom:23,marginRight:57,marginTop:32}}>Stay updated with the latest services, discounts, coupons, trends, and much more. Sign up now!</div>
                        <div style={{display:'flex',flexDirection:'row'}}>
                            <Col style={{padding:0}}>
                            <FormGroup>
                            <Input className="textBox"
                            placeholder='Your Email Please' onChange={(event)=>this.emailSubscribeHandler(event)}  value={this.state.emailModal.value} valid={this.state.emailModal.valid?true:false} invalid={this.state.emailModal.invalid?true:false} maxLength={40} />
                            
                            </FormGroup>
                            </Col>
                            <div style={{  width: 120, height: 58, padding:'10px 0px 10px 0px',marginRight:50, backgroundColor: '#A5D028', textAlign: 'center',  borderTopRightRadius:5,borderBottomRightRadius:5,marginLeft:-10}} onClick={()=>this.submitEmailSubscribeHandler()}>
                            {/* <text className='subscribe'>Subscribe</text> */}
                            <text className='subscribe'>{this.state.loading1 ? <g className="loading_Landing" style={{color:'#fff'}}><span style={{padding:2}}>.</span><span style={{padding:2}}>.</span><span style={{padding:2}}>.</span></g> : this.state.sentEmailSubscribe ? 'Subscribed !' : 'Subscribe'}</text>
                            </div> 
                         </div>        
                         <div className="textPara1"  style={{marginBottom:70,marginRight:101,marginTop:23, whiteSpace:'nowrap'}}>To see how we may use your information, take a look at our <a target="_blank" href={'/ourpolicies?docs=privacy'}>Privacy Policy</a></div> 
                        </Col>    
                        </div> 
                        </Modal>
                        </div>

      );
      }

      if(this.state.PopupModelVar2===true && window.innerWidth>798){

      popUp2=(  

        <div  style={{marginLeft:'auto',display:'flex',flexDirection:'column',justifyContent:"center",alignItems:"center"}}  >
        <Modal  style={{marginTop:100}}   isOpen={this.state.PopupModelVar2}  toggle={()=>this.closeWeb()} size='xl' backdrop={true}>

        <div className='gradient' style={{display:'flex', flexDirection:'row',justifyContent:"center",borderRadius:4,alignItems:'center'}}>

        
        <Col className="colWidthImg" ><img src={logo2} className="imgSize"/></Col>
        <Col className="colWidthBody"> 
        <img onClick={()=>this.closeWeb()} style={{position:"absolute",right:47,marginTop:39}} width={20} height={20}  src={cross}/>
        <div className="dontGoLbl">DON'T GO!</div>
        <div style={{display:'flex',flexDirection:'row',marginBottom:23}}>
       
    
        <div style={{position:'relative'}}>
            {this.props.country === 'IN' ? <text className='centerText'>SLASH50</text>  : <text className='centerText'>WLCM2SLASH</text> }
            <img src={couponBox} style={{padding:0}} width={140} height={55}/>
        </div>
        <div style={{position:'relative'}}>
            {this.props.country === 'IN' ? <text className='centerText'>50% OFF</text>  : <text className='centerText'>SPRING25</text> }
            <img src={couponBox}  style={{padding:0}} width={140} height={55}/>
        </div>
        <text className='manyMore'>& many more...</text>
       
       
        {/* <Col>
         <img src={couponBox} className="logo" width={140} height={140}/>
        <div className='centerText1'>GOSLASH22</div>
        <div className='centerText'>GOSLASH22</div>
        <img src={couponBox} className="logo" width={140} height={55}/>
        
       
        <text className='manyMore'>& many more...</text></Col> */}
        </div>

        <div className='textParaMain' style={{display:'flex',flexDirection:'column',marginBottom:23,marginRight:62,paddingLeft:8}}>A world of coupons, discounts, tips, and services awaits you! Follow us on social media and join our mailing list for more exciting deals. </div>
        <Row style={{marginBottom:23,marginRight:239}}>
        <Col><img src={Instagram}  onClick={() => { window.open('https://www.instagram.com/goslash.app/') }} className="logo" width={43} height={43}/>
        <img src={Facebook} onClick={() => { window.open('https://www.facebook.com/goslashapp/') }} className="logo" width={43} height={43}/>
        <img src={Twitter} onClick={() => { window.open('https://twitter.com/goslashapp') }} className="logo" width={43} height={43}/>
        <img src={Youtube} onClick={() => { window.open('https://www.youtube.com/channel/UCNIC3KzckSXejaMtWbRHA2Q') }} className="logo" width={43} height={43}/>
        <img src={Linkedin}  onClick={() => { window.open('https://www.linkedin.com/company/goslashapp') }} className="logo" width={43} height={43}/>
        <img src={Pinterest} onClick={() => { window.open('https://in.pinterest.com/goslashapp/') }} className="logo" width={43} height={43}/></Col>
        </Row>
        
        
        <div style={{display:'flex',flexDirection:'row'}}> 
         <Col style={{paddingLeft:8}}> 
         {/* <FormGroup> */}
            <Input className="textBox"     
            type="text" style={{paddingRight:0}} placeholder='Your Email Please' onChange={(event)=>this.emailGetToStartHandler(event)} value={this.state.emailModal2.value} valid={this.state.emailModal2.valid?true:false} invalid={this.state.emailModal2.invalid?true:false} maxLength={40} />
        {/* </FormGroup>   */}
        </Col>
        <Col style={{  width: 120, height: 55, padding:'10px 0px 10px 0px',marginRight:56, backgroundColor: '#402666', textAlign: 'center',borderRadius:5}} onClick={()=>this.submitEmailGetToStartHandler()}>
        {/* <text className='getStartedText'>Get Started</text> */}
        <text className='getStartedText'>{this.state.loading1 ? <g className="loading_Landing" style={{color:'#fff'}}><span style={{padding:2}}>.</span><span style={{padding:2}}>.</span><span style={{padding:2}}>.</span></g> : this.state.sentEmailGetToStart ? 'Sent !' : 'Get Started'}</text>
        </Col> 
         </div>        

        <div  className="textPara"  style={{marginBottom:70,marginRight:101,marginTop:23, whiteSpace:'nowrap',paddingLeft:10}}>To see how we may use your information, take a look at our <a target="_blank" href={'/ourpolicies?docs=privacy'}>Privacy Policy</a></div> 
        </Col>
     
        </div>

        
        </Modal>
        </div>
               
      )

      }

    //   if(this.state.mobilePopUpVar===true){  
    //   mobilePopUp=(

    //               <div  className="mobileModalPage1"  style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:'center',}}>
    //     <Modal  isOpen={window.innerWidth<768&&this.state.mobilePopUpVar===true ? true : false } size="sm" backdrop={true} >

            
    //     <Col><img onClick={()=>this.setState({mobilePopUpVar:false})} style={{position:"absolute",right:17,marginTop:17,cursor:'pointer', zIndex:100}} width={16} height={16}  src={cross}/></Col> 

    //                   <Col style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:'center',marginTop:16}}>

    //                   <Row>

    //                       <img src={logo2} className="logo" width={185} height={201}/></Row>
                      
    //                   <Row className="mobiledontGoLbl">DON'T GO!</Row>
         
    //      <Row>
    //     <div style={{position:'relative'}}>
    //         <text className='mobileCenterText2'>GOSLASH22</text> 
    //         <img src={couponBox} className="logo" width={80} height={30}/>
    //     </div>
    //     <div style={{position:'relative'}}>
    //         <text className='mobileCenterText'>GOSLASH22</text> 
    //         <img src={couponBox} className="logo" width={80} height={30}/>
    //     </div>
    //     <text className='mobileManyMore'>& many more...</text></Row>

        

    //     {/* <div className='mobileCenterText'>GOSLASH22</div>
    //     <img src={couponBox} style={{padding:0}} className="logo" width={80} height={30}/> */}
        
       

    //     <div className='mobielTextParaMain' style={{display:'flex',flexDirection:'column',marginBottom:23}}>A world of coupons, discounts, tips, and services awaits you! Follow us on social media and join our mailing list for more exciting deals. </div>
    //     <Row><img src={Instagram} className="logo" width={31} height={31} style={{marginBottom:21}}/>
    //     <img src={Facebook} className="logo" width={31} height={31}/>
    //     <img src={Twitter} className="logo" width={31} height={31}/>
    //     <img src={Youtube} className="logo" width={31} height={31}/>
    //     <img src={Linkedin} className="logo" width={31} height={31}/>
    //     <img src={Pinterest} className="logo" width={31} height={31}/></Row>

    //     <Row style={{display:'flex',flexDirection:'row'}}>
    //      <Col style={{padding:0}}>   
    //     <Input className="mobileTextBox"     
    //      type="text" placeholder='Your Email Please' onChange={this.emailHandler}  maxLength={40} />
    //     </Col>
    //     <Col style={{  width: 85, height: 'auto', padding:'0px 0px 0px 0px', backgroundColor: '#402666', textAlign: 'center',borderRadius:5}}>
    //     <text className='mobileGetStartedText'>Get Started</text>
    //     </Col> 
    //      </Row>   

    //     <Row  className="mobielTextPara"  style={{marginBottom:22,marginTop:21, whiteSpace:'nowrap'}}>To see how we may use your information, take a look at our <a href="/ourpolicies">Privacy Policy</a></Row> 
         
        
                      
    //             </Col>
    //     </Modal>
                    
    //               </div>



    //   );
    //   }

      let mobilePopUp2;

      if(this.state.mobilePopUpVar2===true&&window.innerWidth<=798){  
        mobilePopUp2=(
  
                    <div  className="mobileModalPage"  style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:'center'}}>
                    <Modal  isOpen={this.state.mobilePopUpVar2} size="sm" toggle={()=>this.setState({mobilePopUpVar2:!this.state.mobilePopUpVar2})} backdrop={true} style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:'center',marginTop:100}}>
  
              
                    <Col  onClick={this.close} ><img onClick={()=>this.setState({mobilePopUpVar2:false})} style={{position:"relative",right:17,marginTop:17,cursor:'pointer', zIndex:100}} width={16} height={16}  src={cross}/></Col> 
  
                        <Col style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:'center',marginTop:16}}>
  
                        <Row>
  
                            <img src={logoPopup} className="logo" width={185} height={201}/></Row>
                        
                        <Row className="mobiledontGoLbl1" >PSST, HEY YOU!</Row>
                        <Row className="mobiledontGoLbl1">YEAH, YOU!</Row>
         
  
                 <div className='mobielTextParaMain1' style={{display:'flex',flexDirection:'column',marginBottom:23}}>Stay updated with the latest services, discounts, coupons, trends, and much more. Sign up now! </div>
         
  
               <Row style={{display:'flex',flexDirection:'row'}}>
               <Col style={{padding:0}}>
                {/* <FormGroup> */}
                    <Input className="mobileTextBox" style={{fontSize:14}}    
                    type="text" placeholder='Your Email Please' onChange={(event)=>this.emailSubscribeHandler(event)}  value={this.state.emailModal.value} valid={this.state.emailModal.valid?true:false} invalid={this.state.emailModal.invalid?true:false}  maxLength={40} />
                {/* </FormGroup>    */}
              </Col>
              <Col style={{  width: 85, height: 'auto', padding:'0px 0px 0px 0px', backgroundColor: '#a5d028', textAlign: 'center',borderRadius:5}} onClick={()=>this.submitEmailSubscribeHandler()}>
              <text className='mobileGetStartedText'>{this.state.loading1 ? <g className="loading_Landing" style={{color:'#fff'}}><span style={{padding:2}}>.</span><span style={{padding:2}}>.</span><span style={{padding:2}}>.</span></g> : this.state.sentEmailSubscribe ? 'Subscribed !' : 'Subscribe'}</text>
              </Col> 
              </Row>   
  
              <Row  className="mobielTextPara"  style={{marginBottom:22,marginTop:21, whiteSpace:'nowrap'}}>To see how we may use your information, take a look at our <a target="_blank" href={'/ourpolicies?docs=privacy'}>Privacy Policy</a></Row> 
           
          
                        
                  </Col>
          </Modal>
                      
                    </div>
  
  
  
        );
        }



         



        const closeBtn = <button className="close_Landing" onClick={()=>this.setState({showDownloadNowModal:false})}>&times;</button>;
        return(
                <Navbar color="#fff"  light expand="lg" className='navbar_main' >
                    <div style={{width:'100%', display:'flex', flexDirection:'row'}}>
                        <NavbarBrand className="nav_logo_Landing"><NavLinkReactor to={{pathname:'/', state:{page:this.state.page}}} ><img src={logo} className="logoSlash_nav_Landing"/></NavLinkReactor></NavbarBrand>
                        {(this.props.active !== 10) ? 
                        <Nav className="nav_bar_content" style={{paddingRight:0,paddingTop:0}}>
                            <NavLink className="navlinkPadding">
                            {
                                this.props.activeHash == 5 && this.props.active==1
                                ?
                                <a className="subNavStyleHeader_Landing" href={'#carousel'} active={this.props.activeHash == 5 ? activeNavHeader1 : "subNavStyleHeader"} activeStyle={activeNavHeader1}> Customer </a>
                                :
                                <NavLinkReactor className="subNavStyleHeader_Landing" to={{pathname:'/customer', state:{page:this.state.page}}} activeStyle={activeNavHeader} isActive={() => { if (this.props.active == 1 && this.props.activeHash == 0) { return true;} else { return false;}}}> Customer </NavLinkReactor>
                            }
                            </NavLink>
                            <NavLink className="navlinkPadding">
                            {
                                this.props.activeHash == 5 && this.props.active==2
                                ?
                                <a className="subNavStyleHeader_Landing" href={'#carousel'} active={this.props.activeHash == 5 ? activeNavHeader1 : "subNavStyleHeader"} activeStyle={activeNavHeader1}> Professionals </a>
                                :
                                <NavLinkReactor className="subNavStyleHeader_Landing" to={{pathname:'/professional', state:{page:this.state.page}}} activeStyle={activeNavHeader} isActive={() => { if (this.props.active == 2 && this.props.activeHash == 0) { return true;} else { return false;}}}> Professionals </NavLinkReactor>
                            }
                            </NavLink>
                        </Nav> : null}

                        {(this.props.active == 10) ? 
                        <Nav className="nav_bar_content1" style={{paddingRight:0,paddingTop:0}}>
                            <NavLink className="navlinkPadding">
                            {
                                this.props.activeHash == 5 && this.props.active==1
                                ?
                                <a className="subNavStyleHeader_Landing" href={'#carousel'} active={this.props.activeHash == 5 ? activeNavHeader1 : "subNavStyleHeader"} activeStyle={activeNavHeader1}> Customer </a>
                                :
                                <NavLinkReactor className="subNavStyleHeader_Landing" to={'/customer'} activeStyle={activeNavHeader} isActive={() => { if (this.props.active == 1 && this.props.activeHash == 0) { return true;} else { return false;}}}> Customer </NavLinkReactor>
                            }
                            </NavLink>
                            <NavLink className="navlinkPadding">
                            {
                                this.props.activeHash == 5 && this.props.active==2
                                ?
                                <a className="subNavStyleHeader_Landing" href={'#carousel'} active={this.props.activeHash == 5 ? activeNavHeader1 : "subNavStyleHeader"} activeStyle={activeNavHeader1}> Professionals </a>
                                :
                                <NavLinkReactor className="subNavStyleHeader_Landing" to={'/professional'}  activeStyle={activeNavHeader} isActive={() => { if (this.props.active == 2 && this.props.activeHash == 0) { return true;} else { return false;}}}> Professionals </NavLinkReactor>
                            }
                            </NavLink> 
                        </Nav> : null}

                        {this.props.active==10 ? !this.state.clicked ? <Nav className='ResponsiveDownload' >

                        {/* <NavLink style={{paddingLeft:10, paddingRight:10,margin:0, textDecoration:'none', color:'#fff', cursor:'pointer'}} className="subNavStyle2_Landing" activeStyle={activeNav2} onClick={()=>this.showDownloadNowModalNew1()}>
                                            Download Now1
                                    </NavLink> */}

                                    <NavLink style={{paddingLeft:10, paddingRight:10,marginRight:30, textDecoration:'none', color:'#fff', cursor:'pointer'}} className="subNavStyle2_Landing" to={'#'} onClick={()=> this.onClickDownload()} activeStyle={activeNav2}>
                                        Download Now
                                    </NavLink>




                                    </Nav>:null : null}

                        {/* {!this.state.clicked?<Nav className='ResponsiveDownload' >

                        <NavLink style={{paddingLeft:10, paddingRight:10,margin:0, textDecoration:'none', color:'#fff', cursor:'pointer'}} className="subNavStyle2_Landing" activeStyle={activeNav2} onClick={()=>this.setState({showDownloadNowModal:true})}>
                                            Download Now
                                    </NavLink>

                                    </Nav>:null} */}

                        {/* <Navbar className="navoptions" style={{paddingRight:0,paddingTop:11}}> */}

                        <Nav className="navoptions_Landing" style={{paddingRight:0,paddingTop:0}}>
                                    <NavLink style={{margin:0}} className="navlinkPadding">
                                    {
                                            this.props.activeHash == 5 && this.props.active==4
                                            ?
                                            <NavLinkReactor className="subNavStyle_Landing" to={{pathname:'/about/guidetoSlash', state:{active: this.props.active}}} isActive={() => { if (this.props.active == 4 && this.props.activeHash == 0) { return true;} else { return false;}}} activeStyle={activeNavHeader}> About Slash </NavLinkReactor>
                                            // <a className="subNavStyle" href={'#carousel'} active={this.props.active == 4 ? activeNavHeader1 : "subNavStyle"} activeStyle={activeNavHeader1}> About Slash </a>
                                            :
                                        // <NavLinkReactor  className="subNavStyle" to={this.props.active == 1 ? '/guide/customer' : '/guide/professional'}  isActive={() => { if (this.props.active == 4 && this.props.activeHash == 0) { return true;} else { return false;}}} activeStyle={activeNavHeader}> About Slash </NavLinkReactor>
                                        <NavLinkReactor className="subNavStyle_Landing" to={{pathname:'/about/guidetoSlash', state:{active: this.props.active}}} isActive={() => { if (this.props.active == 4 && this.props.activeHash == 0) { return true;} else { return false;}}} activeStyle={activeNavHeader}> About Slash </NavLinkReactor>
                                    }  
                                    </NavLink> 

                                    <NavLink style={{margin:0}} className="navlinkPadding">
                                        {
                                            this.props.activeHash == 5 && this.props.active==3
                                            ?
                                            <a className="subNavStyle_Landing" href={'#carousel'} active={this.props.activeHash == 5 ? activeNavHeader1 : "subNavStyle"} activeStyle={activeNavHeader1}>Blogs & Articles</a>
                                            :
                                            <NavLinkReactor className="subNavStyle_Landing" to={{pathname: "/blogs"}}  isActive={() => { if (this.props.active == 5) { return true;} else { return false;}}} activeStyle={activeNavHeader}>Blogs & Articles</NavLinkReactor>
                                        }  

                                          {/* /professional/Registration */}
                                    </NavLink>

                                    <NavLink style={{margin:0}} className="navlinkPadding">
                                        <NavLinkReactor className="subNavStyle_Landing" to={{pathname: '/helpandsupport', state:{active: this.props.active,page:this.state.page}}}  activeStyle={activeNavHeader} page={this.state.page}>  Help & Support </NavLinkReactor> 
                                    </NavLink>

                                    {/* <NavLinkReactor style={{paddingLeft:10, paddingRight:20, textDecoration:'none', color:'#fff', cursor:'pointer'}} to={this.props.active == 1 ? '/guide/customer' : this.props.active == 2 || this.props.active == 3 ? '/guide/professional' : '/guide/customer'} className="subNavStyle2" activeStyle={activeNav2}> */}
                                    <NavLink style={{paddingLeft:16, paddingRight:16,margin:0, textDecoration:'none', color:'#fff', cursor:'pointer',borderRadius:20,fontSize:16,height:40}} className="subNavStyle2_Landing" activeStyle={activeNav2} onClick={()=>this.showDownloadNowModalNew1()}>
                                            Download Now
                                    </NavLink>
                                    {/* </NavLinkReactor> */}
                                </Nav>
                              
                            {/* </Navbar> */}

                        

                            
                    </div>
                    {this.state.clicked?<div className="cross_div_Landing"><img onClick={this.toggleNavbar2} className="cross_Landing" src={cross}/></div>: <NavbarToggler onClick={this.toggleNavbar} className="nav_button_Landing" style={{border:'1px solid black'}}/>}

                            <Collapse isOpen={this.state.collapsed} navbar>
                                <Nav className="ml-auto" navbar style={{alignItems:'flex-start',textAlign:'left', width:'50%', padding:0}} >
                                    <div className="togglerNav-div_Landing">
                                    {(this.props.active == 10) ? 
                                        <NavLink className="navlinkPadding">
                                        {
                                            this.props.activeHash == 5 && this.props.active==1
                                            ?
                                            <a className="subNavStyleHeader_Landing" href={'#carousel'} active={this.props.activeHash == 5 ? activeNavHeader1 : "subNavStyleHeader"} activeStyle={activeNavHeader1}> Customer </a>
                                            :
                                            <NavLinkReactor className="subNavStyleHeader_Landing" to={'/customer'} activeStyle={activeNavHeader} isActive={() => { if (this.props.active == 1 && this.props.activeHash == 0) { return true;} else { return false;}}}> Customer </NavLinkReactor>
                                        }
                                        </NavLink> : null}
                                    {(this.props.active == 10) ? 
                                        <NavLink className="navlinkPadding">
                                        {
                                            this.props.activeHash == 5 && this.props.active==2
                                            ?
                                            <a className="subNavStyleHeader_Landing" href={'#carousel'} active={this.props.activeHash == 5 ? activeNavHeader1 : "subNavStyleHeader"} activeStyle={activeNavHeader1}> Professionals </a>
                                            :
                                            <NavLinkReactor className="subNavStyleHeader_Landing" to={'/professional'}  activeStyle={activeNavHeader} isActive={() => { if (this.props.active == 2 && this.props.activeHash == 0) { return true;} else { return false;}}}> Professionals </NavLinkReactor>
                                        }
                                        </NavLink> : null}
                                    
                                        <NavLink>
                                        {
                                            this.props.activeHash == 5 && this.props.active==4
                                            ?
                                            <NavLinkReactor className="subNavStyle_Landing" to={{pathname:'/about/mobile/guidetoSlash', state:{active: this.props.active}}} isActive={() => { if (this.props.active == 4 && this.props.activeHash == 0) { return true;} else { return false;}}} activeStyle={activeNavHeader}> About Slash </NavLinkReactor>
                                            // <a className="subNavStyle" href={'#carousel'} active={this.props.active == 4 ? activeNavHeader1 : "subNavStyle"} activeStyle={activeNavHeader1}> About Slash </a>
                                            :
                                            // <NavLinkReactor className="subNavStyle" to={this.props.active == 1 ? '/guide/customer' : '/guide/professional'} onClick={() => this.setState({collapsed:!this.state.collapsed,clicked:!this.state.clicked})}  activeStyle={activeNav}> About Slash </NavLinkReactor>
                                            <NavLinkReactor className="subNavStyle_Landing" to={{pathname:'/about/mobile/guidetoSlash',state:{active: this.props.active,page:this.state.page}}} onClick={() => this.setState({collapsed:!this.state.collapsed,clicked:!this.state.clicked})}  activeStyle={activeNav}> About Slash </NavLinkReactor>

                                        } 
                                        </NavLink> 

                                        <NavLink>
                                            {
                                            this.props.activeHash == 5 && this.props.active==3 
                                            ?
                                            <a className="subNavStyle_Landing" href={'#carousel'} active={this.props.activeHash == 5 ? activeNavHeader1 : "subNavStyle"} activeStyle={activeNavHeader1}> Blogs & Articles </a>
                                            :
                                            <NavLinkReactor className="subNavStyle_Landing" to={{pathname:'/blogs/mobile',state:{page:this.state.page}}} onClick={() => this.setState({collapsed:!this.state.collapsed,clicked:!this.state.clicked})} activeStyle={activeNav}>  Blogs & Articles </NavLinkReactor>
                                            }
                                            </NavLink>
                                        <NavLink>
                                        <NavLinkReactor className="subNavStyle_Landing" to={{pathname: '/helpandsupport/mobile', state:{active: this.props.active,page:this.state.page}}} isActive={() => { if (this.props.activeHash == 5) { return true;} else { return false;}}} onClick={() => this.setState({collapsed:!this.state.collapsed,clicked:!this.state.clicked})} activeStyle={activeNavHeader} >  Help & Support </NavLinkReactor> 
                                        {/* <NavLinkReactor className="subNavStyle_Landing" to={{pathname: '/helpandsupport/mobile', state:{active: this.props.active}}} isActive={() => { if (this.props.activeHash == 5) { return true;} else { return false;}}} onClick={() => this.setState({collapsed:!this.state.collapsed,clicked:!this.state.clicked})} activeStyle={activeNavHeader}>  Help & Support </NavLinkReactor>  */}
                                        </NavLink>

                                        {this.props.active==1||this.props.active==2||this.props.active==4||this.props.active==3||this.props.active==99||this.props.active==5||this.props.active==88
                                            ?<NavLink style={{paddingLeft:10, paddingRight:10,margin:'10px -15px', textDecoration:'none', color:'#fff', cursor:'pointer'}} className="subNavStyle2_Landing" activeStyle={activeNav2} to={'#'} onClick={()=> this.onClickDownload()}>
                                                Download Now
                                            </NavLink>:null}
                                        
                                        <span style={{fontSize:12}}>{this.props.country}</span>
                                        {/* <NavLink style={{paddingTop:20}}> */}
                                            {/* <NavLinkReactor style={{paddingLeft:10, paddingRight:20, textDecoration:'none', color:'#fff', cursor:'pointer'}} to={this.props.active == 1 ? '/guide/customer' : this.props.active == 2 || this.props.active == 3 ? '/guide/professional' : '/guide/customer'} className="subNavStyle2" activeStyle={activeNav2}> */}
                                            {/* <NavLinkReactor className="subNavStyle2" to={'#'} onClick={()=>this.setState({showDownloadNowModal:true})} activeStyle={activeNav2}> */}
                                            {/* <NavLinkReactor className="subNavStyle2_Landing" to={'#'} onClick={()=> this.onClickDownload()} activeStyle={activeNav2}> */}
                                                 {/* Download Now2 */}
                                            {/* </NavLinkReactor> */}

                                        {/* </NavLink> */}
                                    </div>
                                    
                                </Nav>

                            </Collapse>

                            <Modal isOpen={this.state.showBookNowModal} style={{width:961}} size='xl' backdrop={true} toggle={()=>this.hideDownloadNowModalNew1()}>
                            <ModalBody className='DOWNLOAD-NOW-POP-UP-DTP'>  
                                <img onClick={()=>this.hideDownloadNowModalNew1()} style={{position:"absolute",right:47,top:29,cursor:'pointer'}} width={20} height={20}  src={cross}/>
                                <div style={{display:'flex', flexDirection:'row',justifyContent:"center",marginTop:46,padding:0}}>
                                    <Col style={{padding:0,marginTop:46}}>
                                        <img src={DownloadModalImageNew} className='GirlSitting_Image_Style'/>
                                    </Col>
                                    <Col style={{padding:0}}>
                                    <div style={{flexDirection:'row',marginTop:46}}>
                                        <h3 className='download_Htext1'>DON’T MISS OUT ON THE LATEST DEALS!</h3>
                                    </div>
                                    <div style={{flexDirection:'row',marginTop:24,marginBottom:24}}>
                                        <text className='paragraph_DownloadModal'>Book quality services at your convenience and price.</text>
                                    </div>
                                    <div style={{flexDirection:'row'}}>
                                    <FormGroup style={{display:'flex', flexDirection:'row'}}>
                                        <Input type="text" className='textBox_downloadModal' placeholder='Enter Your Email OR Phone Number' maxLength={this.state.emailEntry ? 40 : 10} onChange={(e)=>this.onChangeEmailMobileHandler(e)} value={this.state.emailEntry ? this.state.email.value : this.state.mobile.value} valid={this.state.emailEntry ? (this.state.email.valid ? true : false) : (this.state.mobile.valid ? true : false)} invalid={this.state.emailEntry ? (this.state.email.invalid ? true : false) : (this.state.mobile.invalid ? true : false)} />
                                        <Button type="submit" className ='btn_downloadModal' onClick={() => this.submitEmailMobileHandler()}>{this.state.loading1 || this.state.loading2? <g className="loading_Landing" style={{color:'#fff'}}><span style={{padding:2}}>.</span><span style={{padding:2}}>.</span><span style={{padding:2}}>.</span></g> : this.state.sentLandEmail || this.state.sentLandMobile ? 'Sent !' : 'Send Link'}</Button>
                                    </FormGroup>
                                    <FormFeedback>Email Address or Mobile Number is invalid!</FormFeedback>
                                    </div>
                                    <div className='availableOn_Text'>
                                    <text>AVAILABLE ON</text>
                                    </div>                        
                                    <div className="downloadImage-div_Landing" style={{justifyContent:'center',flexDirection:'row'}}>
                                        <div className="downloadImage-appstore_Nav_Landing">
                                            <a href={'https://apps.apple.com/us/app/slash-consumer/id1489207325?ls=1/?utm_campaign=Consumer_Guide&utm_source=Website&utm_medium=DigitalMarketingSources'} target="_blank"><img src={AppStore} className="downloadAppImage_Nav_Landing"/></a>
                                        </div>
                                        <div className="downloadImage-googleplay_Nav_Landing">
                                            <a href={'https://play.google.com/store/apps/details?id=com.quickfinduser&utm_campaign=Consumer_Guide&utm_source=Website&utm_medium=DigitalMarketingSources'} target="_blank"><img src={GooglePlay} className="downloadAppImage_Nav_Landing"/></a>
                                        </div>
                                    </div>

                                    </Col>
                                </div>
                        </ModalBody>
                        </Modal>
                        <Modal isOpen={this.state.showDownloadNowModal} size='xl' backdrop={true} toggle={()=>this.setState({showDownloadNowModal:false})}>
                            <div className="modal_main_div_Landing">
                            <div className='skip_download_Landing'><text onClick={()=>this.setState({showDownloadNowModal:false,loading1:false,loading2:false,sentEmail:false,sentMobile:false})} style={{textDecoration:'underline'}}>SKIP</text></div>
                                <div className="logo_div_modal_Landing">
                                    <img src={Modallogo} className="logo_image_modal_Landing"/>
                                </div>
                                <img src={DownloadModalImage} className="modal_phone_image_Landing"/>
                            </div>
                            <div className="text-div_modal_Landing">
                                <div className="text-div-main_Landing">
                                    <text className="main-text_modal_Landing">Elevate Your Business</text>
                                </div>

                                <div className="text-div-main1_Landing">
                                    <text className="main-text_modal_Landing">to Next Level!</text>
                                </div>

                                <div className="text-div-info_modal_Landing">
                                    <text className="get-digital-real_Landing">Get Digital real quick & start expanding your customer base.</text>
                                </div>

                                <div className="sendlink_input_div_Landing">
                                    <FormGroup style={{display:'flex', flexDirection:'row', justifyContent:'left', alignItems:'left', padding:'0px 0px 0px 0px'}}>
                                        <Input type="text"  placeholder='Enter Your Email Address' maxLength={40} onChange={this.emailHandler} value={this.state.email.value} valid={this.state.email.valid?true:false} invalid={this.state.email.invalid?true:false} style={{backgroundColor: '#e8e6f9', width:'73%', height:'50px', border:0, marginRight:'auto'}}/>
                                        <Button type="submit" className='button_send_link_Landing' style={{color:'#fff',border:0, width:'24%', margin:'0px 0px 0px 0px'}} onClick={() => this.submitEmailHandler()}>{this.state.loading1 ? <g className="loading_Landing" style={{color:'#fff'}}><span style={{padding:2}}>.</span><span style={{padding:2}}>.</span><span style={{padding:2}}>.</span></g> : this.state.sentEmail ? 'Sent !' : 'Send Link'}</Button>
                                    </FormGroup>
                                    <FormFeedback>Email Address is invalid!</FormFeedback>
                                </div>

                                <div className="sendlink_input_div_SMS_Landing">
                                    <FormGroup style={{display:'flex', flexDirection:'row', justifyContent:'left', alignItems:'left', padding:'0px 0px 0px 0px'}}>
                                        <Input type="text" placeholder='Enter Your Mobile Number' maxLength={10} onChange={this.mobileHandler} value={this.state.mobile.value} valid={this.state.mobile.valid?true:false} invalid={this.state.mobile.invalid?true:false} style={{backgroundColor: '#e8e6f9', width:'73%', height:'50px', border:0, marginRight:'auto'}}/>
                                        <Button type="submit" className='button_send_link_Landing' style={{color:'#fff', width:'24%', border:0, margin:'0px 0px 0px 0px'}} onClick={this.submitMobileHandler}>{this.state.loading2 ? <g className="loading_Landing" style={{color:'#fff'}}><span style={{padding:2}}>.</span><span style={{padding:2}}>.</span><span style={{padding:2}}>.</span></g> : this.state.sentMobile ? 'Sent !' : 'Send Link'}</Button>
                                    </FormGroup>
                                    <FormFeedback>Mobile Number is Invalid!</FormFeedback>
                                </div>


                                <div className="text-div-available_modal_Landing">
                                    <text className="Build-your-Business_Landing">AVAILABLE ON</text>
                                </div>

                                {this.props.active == 1 || this.props.type == "Customer"?
                        
                                <div className="downloadImage-div_Landing">
                                    <div className="downloadImage-appstore_Nav_Landing">
                                        <a href={'https://apps.apple.com/us/app/slash-consumer/id1489207325?ls=1/?utm_campaign=Consumer_Guide&utm_source=Website&utm_medium=DigitalMarketingSources'} target="_blank"><img src={AppStore} className="downloadAppImage_Nav_Landing"/></a>
                                    </div>
                                    <div className="downloadImage-googleplay_Nav_Landing">
                                        <a href={'https://play.google.com/store/apps/details?id=com.quickfinduser&utm_campaign=Consumer_Guide&utm_source=Website&utm_medium=DigitalMarketingSources'} target="_blank"><img src={GooglePlay} className="downloadAppImage_Nav_Landing"/></a>
                                    </div>
                                </div>

                                :

                                <div className="downloadImage-div_Landing">
                                    <div className="downloadImage-appstore_Nav_Landing">
                                        <a href={'https://apps.apple.com/us/app/slash-merchant/id1489211413?ls=1/?utm_campaign=Merchant_Guide&utm_source=Website&utm_medium=DigitalMarketingSources'} target="_blank"><img src={AppStore} className="downloadAppImage_Nav_Landing"/></a>
                                    </div>
                                    <div className="downloadImage-googleplay_Nav_Landing">
                                        <a href={'https://play.google.com/store/apps/details?id=com.quickfindmerchant&utm_campaign=Merchant_Guide&utm_source=Website&utm_medium=DigitalMarketingSources'} target="_blank"><img src={GooglePlay} className="downloadAppImage_Nav_Landing"/></a>
                                    </div>
                                </div>

                                }

                            </div>
                            <div style={{textAlign:'center'}}>
                                {/* <div className="logo_div_modal">
                                    <img src={Modallogo} className="logo_image_modal"/>
                                </div> */}
                                <img src={DownloadModalImage1} className="modal_phone_image1_Landing"/>
                            </div>
                        </Modal>

                        
                       
                         {popUp2}
                         {popUp}
                        {mobilePopUp} 
                        {mobilePopUp2}

                    
                        {/* /* <Mobile/> */ }


                       
                        <span className='UsLabel' style={{fontSize:11,position:'fixed',right:12}}>{this.props.country}</span>

                        {/* <Nav className="navbar_globe1" style={{paddingTop:0}}>
                        // {this.props.country =='India' ? <span style={{fontSize:11,position:'fixed',right:15}}>IN</span>:
                        // <span style={{fontSize:11,position:'fixed',right:12}}>US</span>}
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret style={{color:'black',position:'fixed',right:0,top:9, zIndex:9999,display:'block'}}>
                                            <BsGlobe color='black' stye={{position:'fixed',display:'none',right:0,top:0}} size={22}/>
                                       
                                        </DropdownToggle>
                                        <span style={{position:'fixed',right:10,top:55,zIndex:10}}>
                                        <DropdownMenu right  style={{color:'black'}}>
                                            <DropdownItem style={{color:this.props.country!='India'?'white':'black',backgroundColor: this.props.country!='India'?'#231709':''}} onClick={()=>this.countryHandler('usa')}>
                                                USA
                                            </DropdownItem>
                                            <DropdownItem style={{color:this.props.country=='India'?'white':'black',backgroundColor: this.props.country=='India'?'#231709':''}} onClick={()=>this.countryHandler('india')}>
                                                India
                                            </DropdownItem>
                                        </DropdownMenu>
                                        </span>
                                    </UncontrolledDropdown>
                                </Nav>
                     */}
                    </Navbar>

                    
        );    
    }
}


const mapStateToProps=state=>{
    return {
        country: state.country,
    
    } 
} 

const mapDispatchToProps=dispatch=>{
    return {
        onsetIPCountryHandler: (country) => dispatch(setIPCountry(country))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(NewNavbar_Website);