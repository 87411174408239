import React,{ useContext, useEffect,useState } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import NavBarV2 from "../Components/Custom/UI/NavBarLatest";
import CheckoutPage from "../Screens/CheckoutStack/CheckoutPage";
import FooterSv2 from "../Screens/HomeStack/FooterSv2";
import '../CSS/HomePage.css'
import { useMediaQuery } from "react-responsive"
import FooterSv2_Mobile from "../Screens/HomeStack/FooterSv2_Mobile";
import RazorpayV1 from '../Screens/PaymentsStack/RazorpayV1'
import StripeV1 from "../Screens/PaymentsStack/StripeV1";

import LandingPageUpdated from "../Screens/HomeStack/LandingPageUpdated";
import NearByProfessionalMapView from "../Screens/HomeStack/NearByProfessionalMapView";
import NavBarV2_Mobile from "../Components/Custom/UI/NavBarLatest_Mobile";
import BookingCards from "../Components/Custom/UI/BookingCards";
import ProfessionalPage from "../Screens/HomeStack/ProfessionalPage";
import ProfessionalUnderCategory from "../Screens/HomeStack/ProfessionalUnderCategory";
import ProfessionalUnderCategory_Mobile from "../Screens/HomeStack/ProfessionalUnderCategory_Mobile";
import UpcomingBookingDetailsPage from "../Screens/BookingStack/UpcomingBookingDetailsPage";
import MyBookings from "../Screens/BookingStack/MyBookings";
import WishList from "../Components/Custom/UI/WishList";
import WishList_mobile from "../../WishList_mobile";
import { UAParser } from 'ua-parser-js';
import AreYoupro from "../Screens/HomeStack/AreYoupro";
import HelpAndSupport from "../Screens/Help&Support/HelpAndSupport";
import Offers from "../Screens/Offers";
import RegisstrAndLogin_India from "../Screens/RegisterrAndLogin_India";
import TermsAndPolicies from '../Screens/TermsAndPolicies'
import FaqConsumer from "../Screens/Help&Support/FaqConsumer";
import BlogsArticles from "../Screens/HomeStack/BlogsArticles";
import FaqMerchant from "../Screens/Help&Support/FaqMerchant";
import MyTransactions from "../Screens/MyTransactions/MyTransactionss";
import HelpAndSupport_mobile from "../Screens/Help&Support/HalpAndSupport_mobile";
import FaqConsumerMobile from "../Screens/Help&Support/FaqConsumer_mobile";
import FaqMerchantMobile from "../Screens/Help&Support/FaqMerchant_mobile";
import Profile from '../../New_Website_V2/Components/Custom/UI/ProfilePage'
import ServiceDetailsPage from "../Screens/HomeStack/ServiceDetailsPage";
import Profile_mobile from '../../New_Website_V2/Components/Custom/UI/ProfilePage_mobile'
import ReferAndEarn from "../Components/Custom/UI/ReferAndEarnCard";
import StripeSuccess from "../Screens/PaymentsStack/StripeSuccess";
import ConsumerEditProfile from "../Screens/ProfileStack/ConsumerEditProfile";
import RatingPage from "../Screens/BookingStack/RatingPage";
import SavedAddresses from "../Screens/ProfileStack/SavedAddresses";
import ConsumerEditProfile_Mobile from "../Screens/ProfileStack/ConsumerEditProfile_Mobile";
import BlogsArticles_Details from "../Screens/HomeStack/BlogsArticles_Details";
import SettingPage from "../Components/Custom/UI/SettingPage";
import { ClipLoader } from 'react-spinners';
import AreYouproMobile from "../Screens/HomeStack/AreYoupro_mobile";
import { globalState } from "../GlobalState/globalState";
import { FooterHidePaths } from '../Components/Custom/NavPaths';
import DeleteAccount_Mobile from "../Screens/ProfileStack/DeleteAccount_Mobile";
import BlogsArticles_mobile from "../Screens/HomeStack/BlogsArticles_mobile";
import ReferalReward from "../Components/Custom/UI/ReferalRewards";

import RaiseComplain_mobile from "../Screens/Help&Support/CustomerAppScreen/RaiseAComplain_mobile";
import RaiseComplain_web from "../Screens/Help&Support/CustomerAppScreen/RaiseComplain_web";
import RaiseComplain_pro_mobile from "../Screens/Help&Support/ProfessionalAppScreen/RaiseComplain_pro_mobile";
import RaiseComplain_pro_web from "../Screens/Help&Support/ProfessionalAppScreen/RaiseComplain_pro_web";

import ServiceDetailsDeepLink_web from "../Screens/HomeStack/ServiceDetailsDeepLink_web";
import QRScannerMerchant from "../Components/Custom/QRScannerComponents/QRScannerMerchant";
import QRScannerConsumer from "../Components/Custom/QRScannerComponents/QRScannerConsumer";


import HowToDel from "../../New_WebPage_Design/Components/Consumer/HowToDeleteAcc";
const DeepClone = require("rfdc/default")

const parser=new UAParser()
// console.log("ua parser",parser.getResult(),navigator)


export default function MainNavigation(props){

    const MobileAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 760px)" })
    const TabletMediaQuery = () => useMediaQuery({ minWidth: 768, maxWidth: 991 })
    const isTabView = TabletMediaQuery()
    const MobileAndTabletMediaQuery = () => useMediaQuery({ minWidth: 0, maxWidth: 769 })
    const isTabMob=MobileAndTabletMediaQuery()
    const [routesArr,setRoutesArr]=useState([])
    
    const isMobileView = MobileAndBelowMediaQuery();
    const [loadingL,setLoadingL]=useState(true)
    const [state, dispatch]=useContext(globalState);
    const [serviceSlotSelected, setServiceSlotSelected]=useState(false)
    
    const Pro_Under_Cat_Responsive = ({children}) => {
        return isMobileView ? <ProfessionalUnderCategory_Mobile /> : <ProfessionalUnderCategory />
    }

    const onTimeSlotSelectedHandler = () => {

        let services=DeepClone(state.cart)

        if(services.length!==0){
            if(services.some((eachItem)=> eachItem.hasOwnProperty('timeSlot'))){
                setServiceSlotSelected(true)
            }
        }

    }
    let pathValue=isMobileView ? '/profile' : '/profile/:profileId'

    useEffect(()=>{
        console.log("from main nav root /", location)
        if(props.navContinueCheck)
        {
        setRoutesArr(
        [
                {path:"/", element: <LandingPageUpdated/>},
                // {path:'/',element: <ConsumerEditProfile/>},
                {path:'/bm',element:<BlogsArticles_mobile/>},
                {path:"/payments/razorpay/in/v1", element: <RazorpayV1/>},
                {path:"/payments/stripe/us/v1", element: <StripeV1/>},
                // {path:"/home", element: <LandingPageUpdated/>},
                {path:"/allservices/:proName", element: <ProfessionalPage/>},
                // {path:"/serviceDetails", element: <ServiceDetailsPage/>},  
                {path:"/servicedetails/:servicename", element: isMobileView ? <ServiceDetailsPage/> : <ServiceDetailsDeepLink_web/>},  
                {path:"/blog", element: isMobileView ? <BlogsArticles_mobile /> : <BlogsArticles />},  
                
                {path:"/allprofessionals", element: isMobileView ? <ProfessionalUnderCategory_Mobile /> : <ProfessionalUnderCategory />},   
                // {path:"/login/us", element: <RegisterAndLoginUS/>},      
                {path:"/login", element: <RegisstrAndLogin_India/>},
                // {path:"/wishlist", element: isMobileView ? <WishList_mobile/>: <WishList/>},
                // {path:"/checkout", element: <CheckoutPage onSelectTimeSlot={onTimeSlotSelectedHandler} />},
                {path:"/c/mybookings", element: <MyBookings/>},
                {path:"/businesslisting", element: isMobileView ? <AreYouproMobile/> :  <AreYoupro/>},  
                {path:"/offers", element: <Offers/>},  
                {path:"/support", element: isTabMob ? <HelpAndSupport_mobile/> : <HelpAndSupport/>},
                {path:"/contactus", element: isTabMob ? <HelpAndSupport_mobile/> : <HelpAndSupport/>},
                {path:"/FAQs", element: isTabMob ? <HelpAndSupport_mobile/> : <HelpAndSupport/>},

                {path:"/terms&policy", element: <TermsAndPolicies/>},
                {path:"/terms&conditions", element: <TermsAndPolicies/>},
                {path:"privacy&policy", element: <TermsAndPolicies/>},
                {path:"/cFAQ", element: <FaqConsumer/>},
                {path:"/mFAQ", element: <FaqMerchant/>},
                // {path:'/blogAricles',element:<BlogsArticles/> },
                {path:'/c/myTransactions',element:<MyTransactions/>},
                // {path:'/Help&Support_mobile',element:<HelpAndSupport_mobile/>},
                {path:'/FAQconsumer_mobile',element:<FaqConsumerMobile/>},
                {path:'/FAQmerchant_mobile',element:<FaqMerchantMobile/>},
                {path:pathValue,element:isMobileView ? <Profile_mobile/> : <Profile />},
                // {path:'/profile',element:isMobileView ? <Profile_mobile/> : <Profile />},
                // {path:'/profile_mobile',element:<Profile_mobile/>},
                // {path:'/c/refer',element:<ReferAndEarn />},
                {path:isMobileView ? '/profile/referandearn' : '/referandearn',element:<ReferAndEarn />},
                {path:'/referandearn/rewards',element:<ReferalReward />},
                {path:'/payments/stripe/us/paystatus',element:<StripeSuccess/>},
                {path:'/c/savedAddresses',element:<SavedAddresses/>},
                {path:'/ConsumerEditProfile',element: isMobileView ? <ConsumerEditProfile_Mobile /> : <ConsumerEditProfile />},  
                {path:'/c/rating',element:<RatingPage/>},  
                {path:'/blogdetails/:name',element:<BlogsArticles_Details />},
                {path:'/setting',element:<SettingPage/>},  
                {path:'/c/deleteAccount',element:<DeleteAccount_Mobile/>},
                {path:'/slashApp/helpAndSupport/customerView',element:<FaqConsumerMobile/>},
                {path:'/slashApp/helpAndSupport/professionalView',element:<FaqMerchantMobile/>},

                {path:'/slashApp/helpAndSupport/complaint/consumer', element: isTabMob ? <RaiseComplain_mobile/> : <RaiseComplain_web/>},
                {path:'/slashApp/helpAndSupport/complaint/merchant', element: isTabMob ? <RaiseComplain_pro_mobile/> : <RaiseComplain_pro_web/>},

                {path:'/slash/merchant/redirect',element:<QRScannerMerchant/>},
                {path:'/slash/consumer/redirect',element:<QRScannerConsumer/>},

                {path:'/slashApp/deleteyouraccount/instructions', element: <HowToDel />}
            ])
        }
    },[props.navContinueCheck])
    

    const location = useLocation();
    const urlPath=location.pathname; 

    return (
        <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-start'}}>
            {urlPath == '/slashApp/helpAndSupport/customerView'|| urlPath ==  '/slashApp/helpAndSupport/professionalView' || urlPath ==  '/slashApp/helpAndSupport/complaint/consumer' || urlPath == '/slashApp/helpAndSupport/complaint/merchant' ? null : isMobileView ? <NavBarV2_Mobile excludePath={urlPath}/> : <NavBarV2 />}
            {!props.navContinueCheck?
            <div style={{display:'flex',marginTop:100,height:'600px',justifyContent:'center',alignItems:'center'}}>
                <ClipLoader widthUnit={"%"} size={50} loadings={loadingL} color="#8c52ff" />
            </div>:null
            }
            {useRoutes(routesArr)}

            {FooterHidePaths.includes(urlPath) ? null :
            <div>
                { isMobileView ? <FooterSv2_Mobile serviceSlotSelected/> : <FooterSv2/> }
            </div>
            }
        </div> 
    )

}