import React from "react";
import { Container } from "reactstrap";
import './WebsiteContainer.css';

export default function Website_Container (props) {
    return(
        <Container fluid className="Container_Class" style={{paddingBottom: props.pdB, paddingTop: props.pdT, backgroundColor: props.bgColor }}>
            {props.children}
        </Container>
    )
}