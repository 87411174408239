
import React,{useEffect, useState,useContext} from 'react'
import { Container, Row, Col,Card,CardBody, CarouselItem, Carousel, Navbar,CarouselIndicators, } from 'reactstrap'
import {useLocation } from 'react-router-dom';

import '../../CSS/UI Components/BlogsArticles.css';

export default function BlogsArticles_Details(props){
  let paramsLocation=useLocation()



  console.log(props)

  const [ activeIndex,setActiveIndex ]=useState(0);



    let blogItems =[
        {
            key: 1,src:{},
            altText: (
              <div><div style={{top:'45%',left:'6%',right:'15%',position:'absolute',display:'flex',flexDirection:'column'}}>
                    <text style={{}} className='blogText'>Blogs & Articles</text>
                    <text className='blogText2'>All you need to know</text>
                    </div>
              </div>
            ),
        },
      
      ]
    

      console.log('paramsLocation',paramsLocation)
             
    
    
      const next = () => {
        // if (animating) return;
        const nextIndex = activeIndex === blogItems.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
      };
      
      const previous = () => {
        // if (animating) return;
        const nextIndex = activeIndex === 0 ? blogItems.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
      };
      const goToIndex = (newIndex) => {
        //   console.log("newIndex",newIndex)
        // if (this.animating) return;
        setActiveIndex(newIndex);
    
      }

   let details = '';


        if( paramsLocation.state && paramsLocation.state.id == 1 || paramsLocation.pathname === '/blogdetails/summer-coming' ){


        details=<div style={{marginLeft:'7%',marginRight:'7%',marginTop:20}}>
        <p style={{fontSize:24,fontFamily:'Inter',width:'100%',textAlign:'center',fontWeight:'bold',color:'#747474',marginTop:30}}>Is your Home Summer ready ?</p>
        <ol style={{padding:20}}>
            
        {/* <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1655708668172-lg.jpg'} width='100%' height={400} style={{margin:'50px 0px 50px 0px', borderRadius:5}}/> */}
            {/* <img src={''} width='80%' style={{margin:'50px 0px 50px 0px', borderRadius:5}}/> */}
            
            <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                “Summer is coming” and you’re still worried about where to start with your home maintenance. We have compiled a list of practical tips for you to keep your home running smoothly without stressing about the mess this summer.<br/><br/>
            </p>
            <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                1. Air Conditioner - Our summer savior needs to be duly serviced. Check for the dust and dirt clogged up in the filter. Make sure you remove the filter, vacuum it and clean it with soapy water.<br/><br/>
            </p>
            <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                2. Fans - The blades of fans need regular cleaning and dusting. They can accumulate a lot of dust and pollen causing hay fever and summer allergies. Use a clean cloth to wipe the blades of the fan on both sides.<br/><br/>
            </p>
            <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                3. Windows - You need to scrub out the dust using a sponge and soap water to get clean and shiny windows. Better choose a pleasant morning to do this.<br/><br/>
            </p>
            <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                4. Fridge - Hotter temperatures means better living conditions for bacteria and viruses. Take the time to wipe off fridge shelves and inner walls using a mild antibacterial soap. Try replacing the existing containers and plastics with food-grade ones. Wash your veggies and fruits thoroughly to clean off the warmth loving salmonella.<br/><br/>
            </p>
            <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                5. Patio - Perfect place for chilling during evenings in summer. To make the evenings really enjoyable throughout the season we advise you to get rid of moss and lichen by spraying bleach + water mix or soda crystals.<br/><br/>
            </p>
            <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                6. Drain and garbage disposal - Summer calls for faster food decomposition. A regular and thorough cleaning of the garbage drain will ensure no food is left to rot due to summer humidity. You can clear all the drains by adding baking soda followed by vinegar. Let the drain holes bubble for five minutes and follow it up with hot water. Repeat this activity every two weeks to ward off bad odor.<br/><br/>
            </p>
            <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                7. Counters - Humid summer weather is also a good thriving condition for creepies. The only way to avoid them on your counters/table tops is to cut off their food source. Make it a habit to clean your counters and floors every night. This goes a long way to eliminating bugs and pests from your home.<br/><br/>
            </p>
            <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                If you want professional help getting your house summer ready. Download Slash and get started! Use the discount code WLCM2SLASH and get $20 OFF!<br/><br/>
            </p>
        </ol> 
    </div>

        }
        else if(paramsLocation.state && paramsLocation.state.id == 12 || paramsLocation.pathname ==='/blogdetails/is-your-hair-ready-to-be-styled'){


        details=<div style={{marginLeft:'7%',marginRight:'7%',marginTop:20}}>
        {/* <p style={{fontWeight:'normal',fontSize:36,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',padding:20,textAlign:'center'}}>Are you a single mom juggling virtual school for your kids?</p> */}
        <p style={{fontSize:24,fontFamily:'Inter',width:'100%',textAlign:'center',fontWeight:'bold',color:'#747474', marginTop:30}}>Are you a single mom juggling virtual school for your kids?</p>
        
            <ol style={{padding:20}}>
                
                {/* <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635950993326-lg.jpg'} width='80%' style={{margin:'50px 0px 50px 0px', borderRadius:5}}/> */}
                
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Annie switched off the alarm and rolled out of bed. The house was filled with a fresh coffee aroma.
                        “A morning coffee is a blessing”, she thought. She got dressed and checked the clock again. “Wake
                        up Pat, it is time for school,” she said. Pat is Annie’s 10-year-old boy. The school has been virtual
                        since Aug 2020 due to COVID-19. Annie is a single mom who works at Mixtiles as a customer support
                        agent. Thankfully she can work from home and she loves her job. Pat used to enjoy school but he
                        misses his friends. He hates the virtual experience and often cries. <br/><br/>
                    </p>
                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635950993102-lg.jpg'} width='80%' style={{margin:'50px 0px 50px 0px', borderRadius:5}}/>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                        Annie and Pat open their laptops and start typing away. Milk, coffee, and scones can be seen on the
                        table. Annie has a lot of meetings during the day. She multitasks between meetings and Pat’s school
                        work. Before you know it, the mornings become afternoons. Pat is tired and so is Annie. “Pat, could
                        you please finish your homework? “ Annie calls out. “I have a meeting now”, she says. “I can’t, it’s so
                        hard” Pat cries. Annie gives him a hug and quickly starts her meeting. The meeting is with her
                        manager, an older mom with teenage kids, who understands what Annie is going through. After the
                        quick call, the manager asks Annie if everything is ok as she looks overwhelmed. “Could we please
                        meet much earlier in the day”, Annie asks “Pat needs help with schoolwork and I am finding it hard
                        to balance work and school”. “Let me check,” the manager replies. “Sorry, I don’t have any time
                        available earlier in the day”, she replies. Annie ends the call and gets back to Pat. <br/><br/>
                    </p>
                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635950993220-lg.jpg'} width='80%' style={{margin:'50px 0px 50px 0px', borderRadius:5}}/>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                        It’s the month of October. Pat has been attending virtual school for 2 months. His teacher sends a
                        note to Annie that Pat needs to spend more time after school going over the material. 
                        Annie is in tears. She is not sure how she can help Pat and work. She loves her job and besides it’s
                        paying the bills. Just then the phone beeps. “Hey Girl, how is it going “the text reads. The text is from
                        Linda, Annie’s friend. Annie calls Linda and they chat. Annie tells her about Pat. “Have you heard of
                        Slash App”, Linda asks. “No! What is that? `` Annie sounds curious. “It’s a mobile app and I found our
                        tutor on it”, Linda says. “The tutoring is for an hour and a good price. It has been so helpful”, she
                        adds. “Oh, tutoring is expensive. I won’t be able to afford it”, Annie says. “No Annie, the app lets you

                        filter by price and I have seen different price ranges. Just try it. Nothing to lose”, Linda assures. Annie
                        ends the call feeling relieved. <br/><br/>
                    </p>
                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635950992949-lg.jpg'} width='80%' style={{margin:'50px 0px 50px 0px', borderRadius:5}}/>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                        That night, after putting Pat to bed, Annie downloads the Slash App on her phone. Linda was right,
                        she thought. Annie finds several tutors with varying price ranges and timing. She finds a tutor who
                        can help Pat from 2-5 pm every day with his homework. Annie is happy and can’t wait to tell Pat
                        about it the next day. For tonight, Annie goes to sleep with a less heavy heart.  <br/><br/>
                        Many parents like Annie are looking for tutoring services for their children on Slash. If you are a
                        tutor, make yourself available on Slash and don’t miss out on tutoring opportunities. Try us!
                    </p>
            </ol> 
        </div>


        }
        else if(paramsLocation.state && paramsLocation.state.id == 2 || paramsLocation.pathname == '/blogdetails/is-your-car-ready-to-hit-the-road'){

        details=<div style={{marginLeft:'7%',marginRight:'7%',marginTop:20}}>
        <p style={{fontSize:24,fontFamily:'Inter',width:'100%',textAlign:'center',fontWeight:'bold',color:'#747474', marginTop:30}}>Is your Car ready to hit the road?</p>
            <ol style={{padding:20}}>
                {/* <img src={'} width='80%' style={{margin:'50px 0px 50px 0px', borderRadius:5}}/> */}
                {/* <img  height={500} width='100%' style={{marginTop:30,objectFit:'cover',borderRadius:10}} src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954071929-lg.jpg'}/> */}
                <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                    Most of us enjoy the pleasant drive down the road, with sweet music in our ears and the wind in our
                    face. But, a bad breakdown can kill the vibe. While you seek out to soothe your soul, it is also
                    important to check your vehicle’s condition. Here is the checklist that you need to consider when
                    you go out for a spin.|<br/><br/><br/>

                    <text style={{fontWeight:'bold',fontSize:20,letterSpacing:'normal',fontStyle:'normal',textDecoration:'underline', color:'black',fontStretch: 'normal',textAlign:'left'}}>
                        Essentials:
                    </text>
                </p>
                <ol>
                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954072163-lg.jpg'} width='80%' style={{margin:'50px 0px 50px 0px', borderRadius:5}}/>
                    <li style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                    Make sure you check your vehicle’s engine oil levels.
                    </li>
                    <li style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                    Ensure adequate engine coolant levels to prevent your hood from overheating.     
                    </li>
                    <li style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                    Change your brake fluid if you find a soft or spongy response from the pedal.
                    </li>
                    <li style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                    Always keep your windshield wiper fluid to the brim, to withstand the unpredictable weather changes.
                    </li>

                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954072080-lg.jpg'} width='80%' style={{margin:'50px 0px 50px 0px', borderRadius:5}}/>
                    
                    <li style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                    Check the Tires’ air pressure for every 700miles.
                    </li>
                    <li style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                    A Good Wheel alignment will take you to great places.  (We mean it!)    
                    </li>
                    <li style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                    Do frequent checks of your tire&#39;s condition. If you notice any wear patterns or uneven tread, your vehicle is bound for a check-up.
                    </li>

                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954071821-lg.jpg'} width='80%' style={{margin:'50px 0px 50px 0px', borderRadius:5}}/>

                    <li style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                    Have your vehicle’s Brake pads checked at every oil change and/or 10000 miles.
                    </li>
                    <li style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                    Necessary cleaning should be done to your vehicle’s Air filters.
                    </li>
                    <li style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                    Before you take off, ensure all the electricals and lights are in good working condition.
                    </li>
                </ol>

                <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'left ', paddingTop:20}}>
                    You can make a quick digital pit stop with the Slash app. Our Auto care professionals make sure that
                    your vehicle gets all the attention and health it needs.
                </p>
                
            </ol> 
        </div>
        }
        else if(paramsLocation.state && paramsLocation.state.id == 67 || paramsLocation.pathname == '/blogdetails/the-best-four-wheeler-repair-maintenance'){

            details=<div style={{marginLeft:'7%',marginRight:'7%',marginTop:20}}>
            <p style={{fontSize:24,fontFamily:'Inter',width:'100%',textAlign:'center',fontWeight:'bold',color:'#747474',marginBottom:30, marginTop:30}}>We've got your vehicle's back- all the time, every time!</p>
                <ol style={{padding:20}}>
                    {/* <img src={'} width='80%' style={{margin:'50px 0px 50px 0px', borderRadius:5}}/> */}
                    
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                    The Best Four and Two-Wheeler Repair and Maintenance Services are just a click away with Slash.<br/><br/><br/>
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:-40}}>
                    It's raining cats and dogs. Mr. Rahul (naam toh suna hi hoga!) is getting ready to go to the office and just had piping hot paranthas. In a good mood, he plugs in his airpods and starts his car…<br/><br/><br/>
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:-40}}>
                    Just silence. Period.<br/><br/><br/>
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:-40}}>
                    He is out of his car, kicking and cursing it as he is just 45 minutes away from an important client meeting. With the burden of the universe over him, he doesn't have any leisure time to attend to his car's plea.<br/><br/><br/>
                    </p>

                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:-40}}>
                    You see yourself in Rahul's place, don't you?<br/><br/><br/>
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:-40}}>
                    Many people struggle with this prevalent scenario and lose their peace of mind. However, you need not raise your BP like Rahul. Ask us why.<br/><br/><br/>
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:-40}}>
                    You have Slash to your rescue!<br/><br/><br/>
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:-40}}>
                    Slash, the top-rated and most preferred online service booking app, has the AutoCare service along with other services like personal care that includes beauty, hair, and nails. It is redefining the way people look after their bikes and cars. Slash app provides an advanced and simple way to book automobile repair and maintenance services. The automobile experts at Slash give the most affordable and comprehensive car and bike repair and maintenance solutions that you can trust. Quality and reliability are the two most important aspects that help maximize the lifespan of your vehicle, and our partners certainly exceed your expectations.
                    Thinking how it all works? Let us brief you.
                    Just download the Slash app if you still need to do it. Once you get the app up and running, you can choose the auto services and check in the relevant details, and voila, you will be shown a list of professionals with immense experience and expertise. The most important advantage of booking an auto care expert with us is the pickup and drop feature at quite affordable and competitive prices from your doorstep.<br/><br/><br/>
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:-40}}>
                    Thinking how it all works? Let us brief you.<br/><br/><br/>
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:-40}}>
                    Just download the Slash app if you still need to do it. Once you get the app up and running, you can choose the auto services and check in the relevant details, and voila, you will be shown a list of professionals with immense experience and expertise. The most important advantage of booking an auto care expert with us is the pickup and drop feature at quite affordable and competitive prices from your doorstep.<br/><br/><br/>
                    </p>

                    <p style={{fontWeight:'bold',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'center',marginTop:-40}}>
                    Download the app<br/><br/><br/>
                    </p>

                

                
                
                    <div style={{textAlign:'center'}}>
                        <img src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703054795111-lg.jpg'} width='80%' style={{margin:'-20px 0px 50px 0px', borderRadius:5}}/>
                        
                        <p style={{fontWeight:'bold',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'center',marginTop:-20}}>
                        Connect with thorough and seasoned mechanics<br/><br/><br/>
                    </p>

                
    
                        <img src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703055199765-lg.jpg'} width='80%' style={{margin:'-20px 0px 50px 0px', borderRadius:5}}/>
                        
                        <p style={{fontWeight:'bold',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'center',marginTop:-20}}>
                        Enjoy pickup and drop of your vehicle<br/><br/><br/>
                    </p>

                
                    <img src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703056067235-lg.jpg'} width='80%' style={{margin:'-20px 0px 50px 0px', borderRadius:5}}/>
                    <p style={{fontWeight:'bold',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'center',marginTop:-20}}>
                    Get awed by the ease of payment<br/><br/><br/>
                    </p>

                
                    <img src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703056087516-lg.jpg'} width='80%' style={{margin:'-20px 0px 50px 0px', borderRadius:5}}/>
                    <p style={{fontWeight:'bold',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'center',marginTop:-20}}>
                    Your sparkling, hale, and healthy vehicle is ready!<br/><br/><br/>
                    </p>

                
                    <img src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703049806512-lg.jpg'} width='80%' style={{margin:'-20px 0px 50px 0px', borderRadius:5}}/>
                        
                    </div>
    
                
                    
                </ol> 
            </div>
        }
        else if(paramsLocation.state && paramsLocation.state.id == 68 || paramsLocation.pathname == '/blogdetails/dont-go-on-a-rough-patch-with-dandruff'){

            details=<div style={{marginLeft:'7%',marginRight:'7%',marginTop:20}}>
            <p style={{fontSize:24,fontFamily:'Inter',width:'100%',textAlign:'center',fontWeight:'bold',color:'#747474',marginBottom:30, marginTop:30}}>Don’t go on a rough patch with dandruff this winter</p>
                <ol style={{padding:20}}>
                    {/* <img src={'} width='80%' style={{margin:'50px 0px 50px 0px', borderRadius:5}}/> */}
                
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                    Snowflakes look pretty in the winter, but not dandruff flakes. For those who deal with dandruff daily, winters mean even more horror, isn't it? Everything we know about hair care goes for a toss in the winter. During winter, the air is dry and devoid of moisture. This causes dryness of the skin as well as the scalp. When dry, the scalp becomes flaky, making it easier for dandruff to thrive.<br/><br/><br/>
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:-40}}>
                    It is quite understandable to feel embarrassed and out of place when you are suffering from dandruff. Those itchy fits, the visible embarrassing flakes and total overall discomfort, can all be very annoying<br/><br/><br/>
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:-40}}>
                    Do you want to know some tried and tested tips and tricks to keep dandruff at bay?<br/><br/><br/>
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:-40}}>
                    Let's get started.<br/><br/><br/>
                    </p>

                    <p style={{fontWeight:'bold',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:-40}}>
                    Avocado hair pack:<br/><br/><br/>
                    </p>

                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                        <img  height={500} width='90%' style={{objectFit:'cover',borderRadius:10,marginTop:-30}} src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703087953584-lg.png'}/>
                    </div>

                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:20}}>
                    Sounds a bit rare, isn't it?<br/><br/><br/>
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:-40}}>
                    The protein and oil content of avocado nourishes hair, adding volume and shine. To make a hair pack, we must mash one avocado into a pulp. Next, we have to add one tablespoon of green tea and one tablespoon of fenugreek seed powder and mix them into a paste, adding lukewarm water. Applying and washing it off after half an hour helps reduce dandruff.<br/><br/><br/>
                    </p>
                    <p style={{fontWeight:'bold',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:-40}}>
                    Apple cider vinegar:<br/><br/><br/>
                    </p>

                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                        <img  height={500} width='90%' style={{objectFit:'cover',borderRadius:10,marginTop:-30}} src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703088010785-lg.png'}/>
                    </div>

                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:20}}>
                    This is also an offbeat technique.<br/><br/><br/>
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:-40}}>
                    For oily scalp and dandruff, mixing ripe papaya pulp with gram flour, egg white, and four teaspoons of apple cider vinegar into a paste works like a wonder. Applying it to the hair and washing it off after half an hour helps reduce dandruff.<br/><br/><br/>
                    </p>
                    <p style={{fontWeight:'bold',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:-40}}>
                    Fenugreek seeds:<br/><br/><br/>
                    </p>

                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                        <img  height={500} width='90%' style={{objectFit:'cover',borderRadius:10,marginTop:-30}} src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1703087792122-lg.png'}/>
                    </div>

                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:20}}>
                    So, let's begin with soaking fenugreek (methi) seeds overnight in water. We then need to grind them into a paste and add 2 teaspoons of olive oil and a paste of hibiscus leaves and flowers. We then have to apply the paste on the scalp, leave it on for 20 to 30 minutes, and rinse well with water.<br/><br/><br/>
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',marginTop:-40}}>
                    So, the colder months mean dandruff and a flaky scalp. Look no further if you're still scratching your head about how you will manage it this time. A good hair massage followed by a hair spa by our expert would be the saviour, isn't it? It involves oil reflexology, steaming and shampooing followed by a deep conditioning mask and increases scalp blood circulation. Book a hair care professional on Slash and get your tresses dancing along to the wind, devoid of dandruff, of course! <br/><br/><br/>
                    </p>
                
                    
                </ol> 
            </div>
        }  
        else if( paramsLocation.state && paramsLocation.state.id == 4 || paramsLocation.pathname == '/blogdetails/digital-assistant'){

        details=<div style={{textAlign:'center',marginLeft:'7%',marginRight:'7%'}}>
        <p style={{fontSize:24,fontFamily:'Inter',width:'100%',textAlign:'center',fontWeight:'bold',color:'#747474',marginBottom:30, marginTop:30}}>Slash - Your Digital Assistant</p>
            <ol>
                <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954540960-lg.jpg'} width='100%' height={400} style={{margin:'50px 0px 50px 0px', borderRadius:5}}/>
                <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                    As a service professional, whether as an Individual or as a business, you know the importance of
                    earning more while retaining flexibility of scheduling your service/work hours. Slash is all about
                    helping you do that and more. With a host of features that can help you with publishing your
                    services, scheduling your work hours with just a few clicks, Slash offers you an opportunity to put
                    yourself in front of a huge customer base. With Slash as your digital assistant, you can make your
                    dream of being fully booked true and enjoy the phenomenon akin to “stores filled with customers”.
                </p>
                {/* <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954540848-lg.jpg'} width='80%' style={{margin:'50px 0px 50px 0px'}}/> */}
                <p>
                    <text style={{fontWeight:'bold',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial'}}>
                        Let’s deep dive into features that will make your journey a piece of cake with Slash.
                    </text>
                </p>

                <p style={{fontWeight:'normal',fontSize:36,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',padding:20,textAlign:'center'}}>
                    Are you looking for ways to earn more?<br/>
                    Publish your service on Slash!
                </p>
                <Row style={{marginTop:50}}>
                    <Col style={{textAlign:'left'}}>
                        <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954540848-lg.jpg'} width='70%' style={{margin:'10px 0px 10px 0px', borderRadius:5}}/>
                    </Col>
                    <Col>
                        <p style={{fontWeight:'normal',fontSize:32,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',textAlign:'left'}}>
                            Home &amp; Living Service!
                        </p>
                        <p style={{fontWeight:'normal',fontSize:14,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'left '}}>
                            You can choose from a wide variety of categories and
                            subcategories (Auto Care, Garment Care, Body Care,
                            Hair Care, Home Care, Manicure, Massage, Pedicure,
                            Pet Care &amp; Tutor). List your service on your terms,
                            prices, timing, etc., and get the ball rolling.
                        </p>
                    </Col>
                </Row>
                <Row style={{marginTop:50}}>
                    <Col>
                        <p style={{fontWeight:'normal',fontSize:32,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',textAlign:'left'}}>
                            Smart &amp; Efficient way!
                        </p>
                        <p style={{fontWeight:'normal',fontSize:14,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'left '}}>
                            Slash is a smart and efficient way to increase your reach and grow
                            your business. Customers looking for services that can be booked
                            at the convenience of their home are on Slash. Hassle-free
                            payments and reduced cancellations will up your game like no
                            other. Ratings can give you more visibility and get all the attention
                            that you need.
                        </p>
                    </Col>
                    <Col style={{textAlign:'right'}}>
                        <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954540768-lg.jpg'} width='70%' style={{margin:'10px 0px 10px 0px', borderRadius:5}}/>
                    </Col>
                </Row>
                <Row style={{marginTop:50}}>
                    <Col style={{textAlign:'left'}}>
                        <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954541103-lg.jpg'} width='70%' style={{margin:'10px 0px 10px 0px', borderRadius:5}}/>
                    </Col>
                    <Col>
                        <p style={{fontWeight:'normal',fontSize:32,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',textAlign:'left'}}>
                            Happy &amp; Excited Customers!
                        </p>
                        <p style={{fontWeight:'normal',fontSize:14,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'left '}}>
                            Create coupons easily to give discounts/deals to mark
                            special occasions or festivities like Halloween,
                            Thanksgiving, Christmas, New Year, Easter, etc. Celebrate
                            these occasions with your customers and make them feel
                            happy and excited about their appointment.
                        </p>
                    </Col>
                </Row>
            </ol> 
        </div>

        }
        else if(paramsLocation.state && paramsLocation.state.id == 42 || paramsLocation.pathname == '/blogdetails/why-not-slash'){

            details=<div style={{textAlign:'center',marginLeft:'7%',marginRight:'7%'}}>
            <p style={{fontSize:24,fontFamily:'Inter',width:'100%',textAlign:'center',fontWeight:'bold',color:'#747474',marginTop:30}}>Why not Slash?</p>
                {/* <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1637568950896-lg.jpg'} width='80%' style={{margin:'50px 0px 50px 0px', borderRadius:5}}/> */}
                <ol style={{padding:20}}>
                    {/* <li style={{fontWeight:'bold',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                        Update your schedules clearly
                    </li> */}
                    {/* <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1638427989315-lg.jpg'} width='100%' height={500} style={{margin:'50px 0px 50px 0px', borderRadius:5}}/> */}
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Hey, are you looking for after party home cleaning service, or car servicing due to frozen tank? Are
                        you attending a friend’s baby shower and worried about leaving your pet alone? Wedding season
                        has kicked in, and everyone is going all gaga about it. Perfect time for a makeover to look your
                        absolute best in your friend’s wedding. But are you ready to put an effort on calling a number of
                        professionals before finding out the one who’s best for your needs or you are looking for an easy
                        way out?<br/><br/>

                    </p>
                    <p style={{fontWeight:'bold',fontSize:24,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',padding:20,textAlign:'center'}}>Wait! Is there an easy way out from all these hassles?</p>

                    {/* <li style={{fontWeight:'bold',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                        Add promotional coupons or festive coupons
                    </li> */}
                        <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1638427989482-lg.jpg'} width='80%' style={{margin:'50px 0px 50px 0px'}}/>
                        <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>

                            Slash is here for your rescue. A remedy to all your home &amp; living needs. Book your services on slash
                            and get exciting offers every time you visit the app. Get ready for a personalized experience on the
                            app that lets you find the perfect professional that will suit your liking. No matter what you are
                            looking for, pocket friendly options or convenience, Slash has something for everyone.<br/><br/>
                        </p>

                        {/* <li style={{fontWeight:'bold',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                            More communication
                        </li> */}
                        <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1638427989787-lg.jpg'} width='80%' style={{margin:'50px 0px 50px 0px'}}/>
                        <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                            A user friendly app that lets you find the nearest professional, book an appointment with them and
                            rate them after service completion. Features like Chats are enabled on app for you to connect with
                            professionals. Not only this you can refer your friend or service professionals on the app. You name
                            it Slash has it.<br/><br/>

                            Well you have reached to the end of the page and still didn’t find that you’re looking for on Slash?
                            Then you must be a service professional. As promised Slash is everyone’s cup of tea. You might want
                            to know how Slash will help you with your business.<br/><br/>
                        </p>

                        {/* <li style={{fontWeight:'bold',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                            Update recent work details and pictures
                        </li> */}
                        <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1638427989639-lg.jpg'} width='80%' style={{margin:'50px 0px 50px 0px', borderRadius:5}}/>
                        <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                            Slash can become your digital assistant and help you shine. Publish your services on your terms, set
                            the price on Slash and it will bring you customers from your neighbourhood. Create coupons or
                            curate festivity specific services for them. Slash will market your service for free and do all the hard
                            work to help you earn more. <br/><br/>
                        </p>
                        <p style={{fontWeight:'bold',fontSize:24,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',textAlign:'center'}}>What are you waiting for? Download Slash today!</p>
                        <p style={{fontWeight:'normal',fontSize:18,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                            Link – <a href="https://c.goslash.com/camp/inv">https://c.goslash.com/camp/inv</a><br/><br/>
                        </p>
                        <p style={{fontWeight:'bold',fontSize:24,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',textAlign:'left'}}>Have a service to publish and offer via Slash? Get our Service Pro version here!</p>
                        <p style={{fontWeight:'normal',fontSize:18,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                            Link - <a href="https://m.goslash.com/camp/inv">https://m.goslash.com/camp/inv</a>
                        </p>


                        
                </ol> 
            </div>
        }
        else if(paramsLocation.state && paramsLocation.state.id == 52 || paramsLocation.pathname == '/blogdetails/5-unique-ways-to-surprise'){



            details=    
                    <div style={{textAlign:'center',marginLeft:'7%',marginRight:'7%'}}>
                    <p style={{fontSize:24,fontFamily:'Inter',width:'100%',textAlign:'center',fontWeight:'bold',color:'#747474',marginBottom:30, marginTop:30}}>5 unique ways to surprise your loved ones</p>

            {/* <p style={{fontWeight:'bold',fontSize:36,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',padding:20,textAlign:'center'}}>5 unique ways to surprise your loved ones</p> */}
                {/* <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1676007914853-lg.png'} width='100%' height={500} style={{margin:'50px 0px 50px 0px', borderRadius:5}}/> */}
                <ul style={{padding:20}}>
                    {/* <li style={{fontWeight:'bold',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                    Old is gone, the new has come
                    </li> */}
                        {/* <img src={DecorationXmasTree} width='80%' style={{margin:'50px 0px 50px 0px', borderRadius:5}}/> */}
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Giving surprises to your loved ones is the best way of expressing gratitude. It shows that you  respect and care about them and would always love to see happiness on their faces. But it is not always easy to surprise. Either you don’t have any clue or you might be afraid that they will get upset or hurt by your gift.
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        If you are still confused, then this article is a must read. In this post we are sharing 5 unique ways to surprise your loved ones without breaking the spirit.
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        <ul>
                            <li style={{fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial'}}>
                                Hide notes 
                            </li>
                            <li style={{fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial'}}>
                                Car Boot Love Surprise 
                            </li>
                            <li style={{fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial'}}>
                                Pamper them with personalized gifts 
                            </li>
                            <li style={{fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial'}}>
                                Long drive 
                            </li>
                            <li style={{fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial'}}>
                                Make a video montage
                            </li>
                        </ul>
                    </p>

                    <p style={{fontWeight:'bold',fontSize:18,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial', paddingTop:20}}>
                        Hide notes
                    </p>
                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1676007914514-lg.png'} width='50%' style={{margin:'20px 0px 50px 0px'}}/>

                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Writing your thoughts in sticky notes and hiding it somewhere is an interesting way to surprise your loved ones.
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        You could write song lines, poems they love or something you want to share with them.
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        You can sneak a handwritten love note in their lunchbox. They will be surprised by your cute gesture as soon as they open the box. Additionally, you can also stick the notes on the washroom for them to find out on their visit. Moreover, you can hide somewhere in the room and make them search by giving them clues.
                    </p>

                    <p style={{fontWeight:'bold',fontSize:18,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial', paddingTop:20}}>
                        Car Boot Love Surprise
                    </p>
                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1676007914659-lg.png'} width='50%' style={{margin:'20px 0px 50px 0px', borderRadius:5}}/>

                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Decorating cars has always been a way to create loving memories. But this idea has evolved over a period of time to decorating car boots. It has been trending and used by many for giving surprises.
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        From flowers-ribbons-balloons there are many artsy things that can be used to ornate the car and give a vibrant look. For decorating the car you just need to be creative and take your surprise to the next level. What can be more amazing than turning your car trunk into a space full of surprises?
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        For instance, imagine the car boot is beautifully decorated with colorful balloons,stars, banners and you go to that special person at midnight and ask him/her to open the door. You can also keep cake, a card and bouquet of flowers that they love. This will make them feel loved and put a smile on their faces.
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Car boot love surprise can also be used to acknowledge that you did something wrong and apologizing for it is the first step towards making things better. So, if you want to take your surprise game a notch higher you can choose car boot love surprise.
                    </p>

                    <p style={{fontWeight:'bold',fontSize:18,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial', paddingTop:20}}>
                        Pamper them with personalized gifts
                    </p>
                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1676007914976-lg.png'} width='50%' style={{margin:'20px 0px 50px 0px', borderRadius:5}}/>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Everyone is busy these days and hardly finds any time to spend with their loved ones. Try this amazing idea to make them feel special even though you are not spending time together. Surprise  with a personalized gift or a beautiful gesture.
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Few of the easy ways would be to give them a little break from everyday chores, cook their favorite dish, take them for a vacation, buy something on their wishlist, book a personal care service like spa, massage etc.
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        If you run out of time, then you can use apps like <a href="https://www.goslash.com/"> Slash </a> to book personal care and living services. There are many customers who are happy with Slash.
                    </p>

                    <p style={{fontWeight:'bold',fontSize:18,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial', paddingTop:20}}>
                        Long Drive
                    </p>
                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1676007914375-lg.png'} width='50%' style={{margin:'20px 0px 50px 0px', borderRadius:5}}/>

                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Does the person like long drives? Do they feel excited?
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Then taking them out for a long drive is the easiest way to surprise.You can either take them to their favorite place or somewhere they have never been. This not only helps in spending quality time but also refreshes their mood, relieves stress and gives relaxation which boosts up energy.
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        To make your long drives magical you can turn on the music and sing with them. You can also stop by roadside stalls, grab a coffee and talk about interesting events.
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        You can surprise them by not revealing the destination and give clues to find out. This will keep them excited throughout the journey.
                    </p>

                    <p style={{fontWeight:'bold',fontSize:18,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial', paddingTop:20}}>
                        Make a video montage
                    </p>
                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1676007914787-lg.png'} width='50%' style={{margin:'20px 0px 50px 0px', borderRadius:5}}/>

                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Now-a-days videos are something people love to watch. Then, why not go for a video montage. It is a thoughtful way to express love for your special person.
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        You can create a video of memories that connects and reminds them of their special days, you can shoot a short video with recorded messages or add their all time favorite music.
                    </p>
                    
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial', paddingTop:20}}>
                        The above ideas are just for your help. Hope, you liked our ideas of surprising your loved ones. Let us know in the comment section below what are your thoughts. We would be happy to read them.
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Download our app, android <a href="https://play.google.com/store/apps/details?id=com.quickfinduser&utm_campaign=Consumer_Guide&utm_source=Website&utm_medium=DigitalMarketingSources" style={{textDecoration:'underline'}}>here</a> & ios <a href="https://apps.apple.com/us/app/slash-consumer/id1489207325?ls=1/?utm_campaign=Consumer_Guide&utm_source=Website&utm_medium=DigitalMarketingSources" style={{textDecoration:'underline'}}>here</a>
                    </p>
                    {/* <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Download our app, (ios)
                    </p> */}
                        {/* <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                                <text style={{fontSize:18}}>App Store</text> - <a href="https://apps.apple.com/us/app/slash-merchant/id1489211413" style={{textDecoration:'underline'}}>https://apps.apple.com/us/app/slash-merchant/id1489211413</a>
                        </p>
                        <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                                <text style={{fontSize:18}}>Play Store</text> - <a href="https://play.google.com/store/apps/details?id=com.quickfindmerchant" style={{textDecoration:'underline'}}>https://play.google.com/store/apps/details?id=com.quickfindmerchant</a>
                        </p> */}
                </ul> 
            </div>

        }
        else if( paramsLocation.state && paramsLocation.state.id == 5 || paramsLocation.pathname === '/blogdetails/trending'){

            details=  <div style={{marginLeft:'7%',marginRight:'7%',marginTop:20}}>
            {/* <p style={{fontWeight:'bold',fontSize:36,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',padding:20,textAlign:'center'}}></p> */}
            <p style={{fontSize:24,fontFamily:'Inter',width:'100%',textAlign:'center',fontWeight:'bold',color:'#747474',marginBottom:30, marginTop:30}}>Trending Makeup Looks For Any Season</p>
                {/* <img  height={500} width='100%' src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1677148261662-lg.jpg'} style={{margin:'0px 0px 50px 0px',objectFit:'cover', borderRadius:10}}/> */}
                {/* <img  height={500} width='100%' style={{marginTop:30,objectFit:'cover',borderRadius:10}} src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954071929-lg.jpg'}/> */}
                <ul style={{padding:20}}>
                
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Makeup is something that everyone loves to wear as it accentuates the beauty of an individual. If there is a special occasion then it's a must-have but we do struggle to match our makeup with attire.
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        To help you decide we have listed down the latest makeup trends for 2023. From natural, luminous looks, bold eyes to a minimalist bronzed face, we’ve got everything covered.
                    </p>

                    <p style={{fontWeight:'bold',fontSize:18,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial', paddingTop:20}}>
                        Tantouring
                    </p>
                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1677148244288-lg.jpg'} width='50%' style={{margin:'20px 0px 50px 0px'}}/>

                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        This makeup trend has its origin in the UK. Tantouring comes from the word tanning and contouring.
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Actually, this process is self-tanning done with self tanner to enhance the natural beauty of your face. This technique is not only used for faces but also for defining cheekbones and sculpting legs.
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Unlike contouring, tantouring does not get wiped away easily by makeup remover or face wash as it stays for a few days.
                    </p>

                    <p style={{fontWeight:'bold',fontSize:18,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial', paddingTop:20}}>
                        Lavender Makeup
                    </p>
                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1677148244834-lg.jpg'} width='50%' style={{margin:'20px 0px 50px 0px', borderRadius:5}}/>

                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        The lavender makeup look is a pretty feminine look that's perfect for any occasion.The purple color in this makeup makes you look young and energetic. It also improves your complexion.
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        The best way to wear lavender makeup is with an outfit that matches your skin tone, hair color and eye color. This look matches well with attires in earth tones like brown, green,etc as it helps to highlight the purple color on eyes.
                    </p>
                    
                    <p style={{fontWeight:'bold',fontSize:18,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial', paddingTop:20}}>
                        Sequin Makeup
                    </p>
                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1677148244543-lg.jpg'} width='50%' style={{margin:'20px 0px 50px 0px', borderRadius:5}}/>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        This makeup is new and hot. From celebrities to influencers everyone is in love with it. Sequin makeup adds a touch of glam and glitz to our personality. It looks very creative with the use of eyeshadow, glitter and rhinestone.
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        If you're looking for an edgy, bold look or something more subtle, sequin makeup is the perfect way to make your eyes stand out and add some drama to your overall look. This is perfect for special occasions or just for adding a bit of sparkle to your everyday look.
                    </p>
                    

                    <p style={{fontWeight:'bold',fontSize:18,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial', paddingTop:20}}>
                        Natural Look
                    </p>
                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1677148244754-lg.jpg'} width='50%' style={{margin:'20px 0px 50px 0px', borderRadius:5}}/>

                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        A natural look with minimal makeup is perfect for any season. Use a light foundation or tinted moisturizer, a touch of blush, and a nude or light pink lip color for a simple and fresh look. Traditional outfits go really well with natural makeup. You can also try bright colors with this look.
                    </p>
                    

                    <p style={{fontWeight:'bold',fontSize:18,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial', paddingTop:20}}>
                        Bold lips
                    </p>
                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1677148244910-lg.jpg'} width='50%' style={{margin:'20px 0px 50px 0px', borderRadius:5}}/>

                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Darker or brighter lip colors like red, berry, or plum can add a pop of color to your look and are perfect for fall and winter. It highlights our lips beautifully. In spring and summer, bright and pastel shades like coral, pink, or peach can add a fun and playful touch. Bold lips makeup can be complemented with clean skin.
                    </p>

                    <p style={{fontWeight:'bold',fontSize:18,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial', paddingTop:20}}>
                        Statement Eyes
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        There is a famous saying that “eyes are the window to the soul”. Our eyes have the ability to tell everything about a person, from their mood to personality. That is why it is said, when words fail to express emotions then eyes do the talking.
                    </p>

                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Here are 4 ideas for an alluring eye makeup that will definitely steal the show.
                    </p>

                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        <ol>
                            <li style={{fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial'}}>
                                <p style={{fontWeight:'bold',fontSize:18,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial', paddingTop:20}}>
                                    Multiple Color Makeup
                                </p>
                                <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1677148244793-lg.jpg'} width='50%' style={{margin:'20px 0px 50px 0px', borderRadius:5}}/>

                                <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                                    Ever thought of combining colors and wearing it on your eyes? Ever tried to do rainbow eye makeup? Tik-tokers are going crazy over this multi-color makeup as it looks amazing and unique. It adds drama to the face and is commonly used to create a bold look.
                                </p> 
                                <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                                    To do multi-color makeup you need a  neutral-colored eye primer (it’ll prevent fading or smearing of eye shadow you put on top) and a palette full of vibrant shades. You need to be creative and play with the colors in the palette to get a mix which suits your style.
                                </p> 
                            </li>
                            <li style={{fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial'}}>
                                <p style={{fontWeight:'bold',fontSize:18,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial', paddingTop:20}}>
                                    Smoky eyes
                                </p>
                                <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1677148244453-lg.jpg'} width='50%' style={{margin:'20px 0px 50px 0px', borderRadius:5}}/>

                                <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                                    A classic smoky eye is versatile and can work for any season. Use darker shades like black or brown for fall and winter, or experiment with lighter and brighter colors like purple or blue for spring and summer. From traditional outfits to western, smokey eyes go well with all.
                                </p> 
                            </li>
                            <li style={{fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial'}}>
                                <p style={{fontWeight:'bold',fontSize:18,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial', paddingTop:20}}>
                                    Graphic liner
                                </p>
                                <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1677148261603-lg.jpg'} width='50%' style={{margin:'20px 0px 50px 0px', borderRadius:5}}/>

                                <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                                    Apply graphic liner for a bold and modern look. Use liquid liner or eyeshadow to create a dramatic wing or geometric shapes. This makeup can be worn for an evening party.
                                </p> 
                            </li>
                            <li style={{fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial'}}>
                                <p style={{fontWeight:'bold',fontSize:18,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial', paddingTop:20}}>
                                    Colorful eyeliner
                                </p>
                                <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1677148244865-lg.jpg'} width='50%' style={{margin:'20px 0px 50px 0px', borderRadius:5}}/>

                                <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                                    Instead of black or brown eyeliner, try experimenting with bright shades like blue, green, or purple for a playful touch. This can be a fun way to play with color without going too bold.
                                </p> 

                                <p style={{fontWeight:'bold',fontSize:18,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial', paddingTop:20}}>
                                    Dewy skin
                                </p>
                                <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                                    A dewy and glowy complexion is perfect for spring and summer. Use a lightweight foundation or tinted moisturizer, add a touch of highlighter to your cheekbones and temples, and finish with a nude or light pink lip color.
                                </p>  
                            </li>
                        </ol>
                    </p>

                    <p style={{fontWeight:'bold',fontSize:18,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial', paddingTop:20}}>
                        Shimmery look
                    </p>
                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1677148244415-lg.jpg'} width='50%' style={{margin:'20px 0px 50px 0px', borderRadius:5}}/>

                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Sparkle and shimmer can add a festive touch to any makeup look, making them great for holiday parties or special occasions. Use a glittery eyeshadow or highlighter for a fun and glamorous touch.
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        You can also go for a metallic look. Metallic eyeshadows or lipsticks can add a fun and futuristic touch to any look. Apply  gold, silver, or bronze shades for a glamorous touch, or experiment with brighter metallic shades like pink or purple for a more daring look.
                    </p>

                    <p style={{fontWeight:'bold',fontSize:18,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial', paddingTop:20}}>
                        Monochromatic look
                    </p>
                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1677148244629-lg.jpg'} width='50%' style={{margin:'20px 0px 50px 0px', borderRadius:5}}/>

                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Choose a color and use it for your eyes, cheeks, and lips to create a monochromatic look. For example, use a pink or peach shade for a fresh and youthful look, or a deeper berry shade for a more dramatic look. It gives a high-glam look in less time as it doesn’t require to blend two colors into one another.
                    </p>


                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial', paddingTop:20}}>
                        If you are currently looking for the above makeup looks, then head on to <a href="https://goslash.com" style={{textDecoration:'underline'}}>Slash</a>. We have well qualified professionals to doll you up the way you like. You can book services in a click.
                    </p>
                    
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Currently, we are running special coupons. To avail these coupons you can download our app here <a href="https://apps.apple.com/us/app/slash-consumer/id1489207325?ls=1/?utm_campaign=Consumer_Guide&utm_source=Website&utm_medium=DigitalMarketingSources" style={{textDecoration:'underline'}}>appstore</a> & <a href="https://play.google.com/store/apps/details?id=com.quickfinduser&utm_campaign=Consumer_Guide&utm_source=Website&utm_medium=DigitalMarketingSources" style={{textDecoration:'underline'}}>playstore.</a>
                    </p>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        Lastly, we hope you enjoyed reading this article. You can comment down your favorite makeup look. Feel free to reach out to us.
                    </p>

                
                </ul> 
            </div>
        }
        else if( paramsLocation.state && paramsLocation.state.id == 6 || paramsLocation.pathname === '/blogdetails/onlineservice'){

            details=  <div style={{marginLeft:'7%',marginRight:'7%',marginTop:20}}>
            {/* <p style={{fontWeight:'bold',fontSize:36,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',padding:20,textAlign:'center'}}></p> */}
            {/* <p style={{fontSize:24,fontFamily:'Inter',width:'100%',textAlign:'left',fontWeight:'bold',color:'#747474',marginBottom:30}}>Trending Makeup Looks For Any Season</p> */}
            {/* <img  height={400} width='100%' src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1705479647109-lg.png'} style={{margin:'0px 0px 50px 0px',objectFit:'cover', borderRadius:10}}/> */}
                {/* <img  height={500} width='100%' style={{marginTop:30,objectFit:'cover',borderRadius:10}} src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954071929-lg.jpg'}/> */}

            <p style={{fontSize:24,fontFamily:'Inter',width:'100%',textAlign:'center',fontWeight:'bold',color:'#747474',marginBottom:30, marginTop:30}}>How Online Service Booking Systems Increase Revenue for Local Businesses</p>
            <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                Technology is advancing every second, and smartphones have become an inevitable part of our lives, isn't it? With the advent of online booking systems and apps, life has become easier for customers as well as professionals. According to a survey, 65% of online service bookings happen through mobile devices, and 42% are after business hours. The three Cs- Communication, Commute, and Convenience- have undergone a major transformation with the online service booking systems. Businesses have a big opportunity to reach customers with a mobile app, thereby improving operational efficiency, reducing no-shows, filling empty slots, and growing their revenue.
            </p>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                <img height='70%' width='90%' src={'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1705479647242-lg.png'} style={{margin:'20px 0px 50px 0px'}}/>
            </div>

                <ul>
                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        The sales of small businesses are indeed boosted by online booking methodology:
                    </p>

                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                        <ol type='a'>
                            <li style={{fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial'}}>
                                <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial'}}>
                                    Customers can easily book appointments anytime, anywhere, based on their convenience and requirements. Eliminating the frustration of long wait times for clients helps create a seamless and efficient experience.
                                </p>
                            </li>
                            <li style={{fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial'}}>
                                <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                                    Reduction in repeated administrative tasks leads to better client relationships and better service quality and saves businesses time and money, ultimately boosting productivity.
                                </p> 
                            </li>
                            <li style={{fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial'}}>
                                <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                                    At the point of booking or as a part of marketing and advertising, customers are offered deals, discounts, and offers that attract them and convert them into loyals, making them book on the same platform over and again.
                                </p> 
                            </li>
                            <li style={{fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial'}}>
                                <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial'}}>
                                    The customers get a plethora of options to choose from for payments, and the confirmation of payment means an opportunity is secured for sure for the business/professional.
                                </p> 
                            </li>
                            <li style={{fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial'}}>
                                <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial'}}>
                                    Increased customer convenience, fewer booking errors, enhanced professionalism, transparent pricing, automated reminders, and customer testimonials are the crucial aspects that small businesses have found advantageous when they are registered on online booking apps/services.
                                </p>
                            </li>
                            <li style={{fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial'}}>
                                <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial'}}>
                                    Being technologically convenient to clients opens up more opportunities for scaling business in the future and generating more revenue, of course.
                                </p>  
                            </li>
                        </ol>
                    </p>

                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial', paddingTop:20, marginBottom:20}}>
                        For small businesses, the move to online booking isn't just a nod to modernity but a strategic decision. It's a commitment to customer satisfaction, operational excellence, and future growth.
                    </p>
                </ul> 
            </div>
        }
        else{

            if(paramsLocation.state && paramsLocation.state.id == 3 || paramsLocation.pathname === '/blogdetails/skin') {  

            details= <div style={{marginLeft:'7%',marginRight:'7%',marginTop:20}}>
            <p style={{fontSize:24,fontFamily:'Inter',width:'100%',textAlign:'center',fontWeight:'bold',color:'#747474',marginBottom:30, marginTop:30}}>Skin Rituals for proper Healthy skin</p>
            {/* <img  height={500} width='100%' style={{marginTop:30,objectFit:'cover',borderRadius:10}} src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954352488-lg.jpg'}/> */}
            <div>
                            {/* <p style={{fontWeight:'normal',fontSize:36,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',padding:20,textAlign:'center'}}></p> */}
                                <ol style={{padding:20}}>
                                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                                        Every woman wants beautiful and healthy skin. Healthy skin is as important as beautiful
                                        skin.  Why wait for your skin care treatment when you can start with some self-care tips and
                                        rituals that can help you with that. 
                                        Using prescribed skin care day and night helps focus on the outside of your skin to help give
                                        it a rejuvenated glow. But some aspects are equally important to make our skin healthy from
                                        the inside.
                                    </p>
                                    {/* <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954352488-lg.jpg'} width='80%' style={{margin:'50px 0px 50px 0px', borderRadius:5,textAlign:'center'}}/> */}
                                    <li style={{fontWeight:'bold',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                                        A good night’s rest
                                    </li>
                                        <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                                            Did you know good sleep is equivalent to a natural skin moisturiser? It helps to smooth out
                                            wrinkles and is really important for a healthy complexion. No wonder our faces “look tired”
                                            when we haven’t slept properly; as lack of sleep affects the skin’s natural pH, making your
                                            complexion look dull, dry and less youthful. Plus, the imbalance caused by these results in
                                            the skin secreting more oil, which often leads to breakouts.<br/><br/>

                                            Regular nightly rituals that need to be followed for a good night’s sleep:
                                        </p>
                                        <ul>
                                            <li style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                                                Say NO to desserts: Eating desserts before bed spoils your digestive system, which
                                                disrupts your sleep and can keep you awake for longer.
                                            </li>
                                            <li style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                                                Drinking a soothing cup of tea: like chamomile tea or green tea is also preferable.       
                                            </li>
                                            <li style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                                                Moisturise: Better to use Oil and Body Butter cream. It makes skin soft and the
                                                antioxidants nourish the skin through the night.
                                            </li>
                                            <li style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                                                Relax: It is advisable to listen to meditation music to help relax your mind and calm
                                                yourself after a busy day.
                                            </li>
                                        </ul>

                                    <li style={{fontWeight:'bold',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                                        Exercise daily
                                    </li>
                                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                                        Busy people have to create dedicated time for exercise every day! Even if it is only for a short
                                        duration, it is a much needed habit that helps regain focus on both body and mind. Regular
                                        exercise makes us strong and also reduces our body stress levels.<br/><br/>
                                        
                                        Exercise is also essential to healthy skin because it increases blood flow. When your blood
                                        flows during exercise, it flushes the toxins out from the cells, basically cleaning the skin from
                                        the inside out.
                                    </p>
                                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954352683-lg.jpg'} width='80%' style={{margin:'50px 0px 50px 0px', borderRadius:5}}/>

                                    <li style={{fontWeight:'bold',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                                        Eat the right things
                                    </li>
                                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                                        Eating the right things is one important step to maintain your skin. It plays a crucial role in
                                        taking care of your body as well as skin. Junk foods should be avoided as much as possible.
                                        Besides, the calories aren’t worth it! Foods with high sugar levels eventually increase insulin
                                        levels and affect gut bacteria, causing breakouts on the face, chest and back. Diet should
                                        begin with hydration which means drinking lots of water. To hydrate better, you could
                                        incorporate more kinds of liquids or fluids into your day, such as drinking herbal tea
                                        regularly. You can even avoid dairy and gluten to improve your skin health. <br/><br/>


                                        If you’re the kind who can’t get through the day without a little treat, start making clever,
                                        healthy substitutions. For instance, start adding healthy options like dates and dark chocolate
                                        for your daily sweet cravings. And keep actual dessert or sweets for your less frequent cheat
                                        days.<br/><br/>
                                    </p>
                                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954352588-lg.jpg'} width='80%' style={{margin:'50px 0px 50px 0px', borderRadius:5}}/>

                                    <li style={{fontWeight:'bold',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'black',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                                        Remedial treatments
                                    </li>
                                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                                        As we get older, it is really important to take care of our skin. Never compromise on your
                                        monthly remedial treatments like Body spa or skin rejuvenating spa. Aging causes the
                                        muscles to become less toned and less able to contract, resulting in more rigid limbs and an
                                        increased risk of muscle damage. You can book any services for your skin care at slash or do
                                        it at home by using deeply nourishing skin care products available at salons.
                                    </p>
                                    <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1635954352755-lg.jpg'} width='80%' style={{margin:'50px 0px 50px 0px', borderRadius:5}}/>
                                    <p style={{fontWeight:'normal',fontSize:16,letterSpacing:'normal',fontStyle:'normal',color:'#1a0c2e',fontStretch: 'normal',textAlign:'initial',paddingTop:20}}>
                                        WINTER IS COMING … <br/>
                                        Just in time for much needed skin care. Stay tuned!
                                    </p>
                                </ol> 
                            </div>
        </div>
            }
        }







    return(
     <>
        <div >
            <img  height={250} width='100%' style={{objectFit:'cover'}} src={paramsLocation.state && paramsLocation.state.image ? paramsLocation.state.image : 'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684421293381-lg.png'}/>
        </div>
       
         {details}
    </>
    )



}