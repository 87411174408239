import React, { useContext, useEffect, useRef, useState } from 'react'
import GoogleMapReact from 'google-map-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { globalState } from '../../GlobalState/globalState';
import numeral from 'numeral';
import locationcolorIcon from '../../Assets/LocWithDistanceIcon.png'
import '../../CSS/HomePage.css'
import '../../CSS/NearByProMapView.css'
import { Button, Card, CardImg } from 'reactstrap';
import RatingComponent from '../../Components/Custom/UI/RatingComponent';
import WishlistIcon from '../../Assets/ph-heart-boldColored.png'
import DistanceIcon from '../../Assets/gis-routeBlack.png' 
import { useMediaQuery } from 'react-responsive';
import WishlistWhite from '../../Assets/wishlistWhite.png'
import DistanceIconWhite from '../../Assets/gis-route.png'
import WishlistYes from '../../Assets/iconFavorite.png'
import customFetchData from '../../Components/Custom/customFetchData';
const DeepClone = require("rfdc/default")
export default function NearByProfessionalMapView(props) {
    // window.scrollTo(0,0)
    // const paramsLocation = useLocation();
    // console.log('props',paramsLocation.state)    
    let [state,dispatch]=useContext(globalState)
    const [markerArray,setMarkerArray]=useState([])
    const [showBox,setShowBox]=useState(false)
    const [mpidArr,setMpidArr]=useState(props.mpidArr ? props.mpidArr : [])
    // const [mpidArr,setMpidArr]=useState(paramsLocation.state ? (paramsLocation.state.mpidArr ? paramsLocation.state.mpidArr : []) :[])
    const [proServiceCount,setProServiceCount]=useState([])
    const [details,setDetails]=useState({
        location_prid:null,
        merchant_name:"",
        discount:0,
        distance:0,
        service_name:"",
        serviceCount:0,
        address:"",
        image_link:"",
        latitude:null,
        longitude:null,
        markerIndex:0,
        merchLatitude:null,
        merchLongitude:null,
        rating:null,
        merP_Id:null,
        wishlistFlag:false,
        currency:'',
        catCount:0,
        city:'',
        stateorProvince:'',
        zipcode:null

    })
    const useTabletMediaQuery = () => useMediaQuery({ minWidth: 768, maxWidth: 1050 })
    const isTabView = useTabletMediaQuery()
    const useMobileMediaQuery = () => useMediaQuery({ minWidth: 0, maxWidth: 760 })
    const isMobileView = useMobileMediaQuery()
    const mapRef=useRef()
    let navigate=useNavigate()
    useEffect(()=>{
        // let offers =paramsLocation.state.nearByPros
        let offers=props.nearByPros
        let markerArrays=[]
        // console.log('offers',offers)
        if (offers.length !== 0) {
          offers.map((item, index) => {
              let catCount=0;
                // console.log('item',item)
                if(item.some(eachItem=> ( eachItem.category_name=="Home Care" || eachItem.category_name=="Tutoring"))){
                  catCount = catCount + 1;
                }
                markerArrays.push({
                    latitude: item[0].MERCHANT_LOCATION_LATITUDE,
                    longitude: item[0].MERCHANT_LOCATION_LONGITUDE,
                    location_prid: item[0].QF_MERCHANT_LOCATION_PRID,
                    merchant_name: item[0].MERCHANT_NAME,
                    discount: item[0].service_discount,
                    distance: item[0].distance,
                    service_name: item[0].SERVICE_NAME,
                    address: item[0].MERCHANT_PERSONNEL_ADDRESS_LINE2,
                    image_link: item[0].image_link,
                    merP_Id:item[0].QF_MERCHANT_PERSONNEL_PRID,
                    rating:item[0].MERCHANT_PERSONNEL_RATING,
                    wishlistFlag:item[0].wishlist_Pro === 0 ? true : false,
                    currency:item[0].SERVICE_CURRENCY,
                    catCount:catCount,
                    city:item[0].MERCHANT_PERSONNEL_ADDRESS_CITY,
                    stateorProvince:item[0].MERCHANT_PERSONNEL_ADDRESS_STATE,
                    zipcode:item[0].MERCHANT_PERSONNEL_ADDRESS_ZIPCODE
                })
            })
            // console.log('vv',markerArrays)
            setMarkerArray( markerArrays )
        }
    },[])
    const onPressMarker = (data,markerIndex) => {
        setShowBox(true)
        let serviceCount=0;
        props.nearByPros.map(item=>{
            if(item[0].QF_MERCHANT_PERSONNEL_PRID == data.merP_Id){
                serviceCount=item.length
            }
        })
        // console.log('data',data,serviceCount)
        // mapRef.current.animateToRegion({
        //     latitude:data.latitude,
        //     longitude:data.longitude})
        setDetails({
            ...details,
            location_prid:data.location_prid,
            merchant_name:data.merchant_name,
            discount:data.discount,
            distance:data.distance,
            service_name:data.service_name,
            serviceCount:serviceCount,
            address:data.address,
            image_link:data.image_link,
            markerIndex:markerIndex,
            merchLatitude:data.latitude,
            merchLongitude:data.longitude,
            rating:data.rating,
            merP_Id:data.merP_Id,
            wishlistFlag:data.wishlistFlag,
            currency:data.currency,
            catCount:data.catCount,
            city:data.city,
            stateorProvince:data.stateorProvince,
            zipcode:data.zipcode
        })
    }
    let markerView=null;
    
    
    if(markerArray.length!==0 )
    // && params.catName !== "Home Care" && params.catName !== "Tutoring"
    {
        markerView= (
            markerArray.map((markerItem,markerIndex)=>{
              // console.log('item',markerItem)
              if(markerItem.catCount === 0){
                return (
                    <div
                        key={markerItem.location_prid}
                        lat={markerItem.latitude} 
                        lng={markerItem.longitude}
                        style={{display:'flex',justifyContent:'center',zIndex:100,cursor:'pointer'}}
                        onClick={()=>onPressMarker(markerItem,markerIndex)}
                    >
                        <img src={locationcolorIcon} style={{width:60,height:88,objectFit:'contain'}}/>
                        <div style={{position:'absolute',top:41}}>
                            <text className='markerTextStyle'>{markerItem.currency == 'Rupees' ? numeral(markerItem.distance).divide(1000).format('0.00') + 'km' : numeral(markerItem.distance).divide(1609.344).format('0.00') + 'mi'}</text>
                        </div>
                    </div>
                )
              }
            })
                        
        )
        
    }
    const defaultProps = {
        center: {
            lat:state.address.latitude,
            lng:state.address.longitude
        },
        zoom: 10
      };
      const defaultMapOptions = {
        fullscreenControl: false,
        zoomControl:false
      };
  const onBoxPressHandler = () => {
    // console.log('card pressed')
    let offers = props.nearByPros
    let filteredArray = []
    offers.map(item => {
      // console.log('check t/f',details.merP_Id === item.QF_MERCHANT_PERSONNEL_PRID)
      if (details.merP_Id === item[0].QF_MERCHANT_PERSONNEL_PRID) {
        navigate({pathname:`/allservices/${item[0].MERCHANT_NAME.replaceAll(" ","","g")}`,search:`?proId=${item[0].QF_MERCHANT_PERSONNEL_PRID}`}, {state:{proData: item[0], offers: item, fromMapClick: true, wishlistFlag: details.wishlistFlag,
          categories:DeepClone(state.categories),subCategories:DeepClone(state.subcategories),
          addressDetails:
       {
          totalAddress: state.address.totalAddress,
          addressLine1: state.address.addressLine1,
          country: state.address.country,
          city: state.address.city,
          stateOrProvince: state.address.stateOrProvince,
          zipcode: state.address.zipcode,
          district: state.address.district,
          latitude: state.address.latitude,
          longitude: state.address.longitude,
          currentAddress: state.address.currentAddress,
          searchAddress: state.address.searchAddress,
          currentLocationDenied: state.address.currentLocationDenied
       },
       allServices:props.nearByPros}})
      }
    })
  }
  const WishlistPressHandler = async() =>{
    setDetails({...details,wishlistFlag:!details.wishlistFlag})
    let body={
      // cprid:item.QF_CONSUMER_PRID,
      cprid:state.user.id,
      mpprid:details.merP_Id,
      wishLstType:'professional'
    }
    let WishlistInsert=await customFetchData('POST',body,'/website/add/wishList/sv2',state,dispatch)
    console.log('wish list response',WishlistInsert)
}
    
  return (
    <div style={{ height: props.mobileView ? '100vh' : '80vh', width: '100%',borderRadius:30,position:'relative' }}>
          <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyC6sHvN36SBHhSQymZ3d17MwtTeql16VbQ" }}
              center={defaultProps.center}
              zoom={defaultProps.zoom}
              options={defaultMapOptions}

            //   ref={mapRef}
          >
            {markerView}
          </GoogleMapReact>
          {
            showBox ?
            isMobileView ?
            <div className='box_ViewMapMobile'>
              <Card className="card_ProMap_Mobile">
                  <div className="cardImg_ProMap_Mobile" style={{backgroundSize: "cover",height: "100vh",backgroundImage: `linear-gradient(to right, #451c95 -14%, rgba(148, 101, 240, 0) 73%),url(${details.image_link})`}}>
                    <div style={{padding:15}}>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',cursor:'pointer'}}>
                        <div style={{display:'flex',flexDirection:'column',cursor:'pointer'}} onClick={()=>onBoxPressHandler()}>
                          <text className='proName_ProMap'>{details.merchant_name}</text>
                        </div>
                        {
                        state.token && 
                        <div style={{display:'flex',flexDirection:'column',cursor:'pointer'}} onClick={()=>WishlistPressHandler()}>
                          { details.wishlistFlag ? <img src={WishlistYes} style={{width:20,height:20}}/> : <img src={WishlistWhite} style={{width:18,height:18}}/>}
                        </div>
                        }
                      </div>
                      <div onClick={()=>onBoxPressHandler()} style={{cursor:'pointer'}}>
                          <text className='proAddress_ProMap' style={{marginTop:5}}>{details.address.length > 45 ? details.address.substr(0,45) + "...." : details.address}</text>
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop:10 }}>
                            <img src={DistanceIconWhite} style={{ width: 14, height: 14, marginRight: 8 }} />
                            <text className='distanceText_ProMap'>{details.currency == 'Rupees' ? numeral(details.distance).divide(1000).format('0.00') + ' km away' : numeral(details.distance).divide(1609.344).format('0.00') + ' mi away'}</text>
                          </div>
                      </div>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:18,cursor:'pointer'}} onClick={()=>onBoxPressHandler()}>
                          <div className='serviceCount_Btn_Map_Mob'>
                            <text className='serviceCount_Btn_Text'>
                              {details.serviceCount + (details.serviceCount > 1  ? ' Services' : ' Service')}
                            </text>                          
                          </div>
                          <div>
                            <RatingComponent rating={details.rating} webUsage={true}/>
                          </div>
                      </div>
                    </div>
                  </div>
              </Card>
            </div>
            :
              <div className='box_ViewMap'>
              <Card className='cardView_ProMap'>
                <div style={{display:'flex',flexDirection:'row',cursor:'pointer'}} onClick={()=>onBoxPressHandler()}>
                  <div style={{position:'relative',display:'flex',alignItems:'center'}}>
                  <CardImg src={details.image_link} className='cardImg_ProMap'/>
                  <div style={{position:'absolute',left:10,top:10}}>
                    <RatingComponent rating={details.rating} webUsage={true}/>
                  </div>
                  </div>
                  <div style={{display:'flex',marginLeft:15,flexDirection:'column',justifyContent:'space-between'}}>
                    <div  style={{display:'flex',flexDirection:'column'}}>
                        <text className='proName_ProMap' style={{ paddingBottom: isTabView ? 10 : 15 }}>{details.merchant_name}</text>
                        <text className='proAddress_ProMap'>{details.address}</text>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: isTabView ? 5 : 10 }}>
                          <img src={DistanceIcon} style={{ width: 14, height: 14, marginRight: 8 }} />
                          <text className='distanceText_ProMap'>{details.currency == 'Rupees' ? numeral(details.distance).divide(1000).format('0.00') + ' km away' : numeral(details.distance).divide(1609.344).format('0.00') + ' mi away'}</text>
                        </div>
                    </div>
                      <div>
                        <div className='serviceCount_Btn_Map' >
                          <text className='serviceCount_Btn_Text'>
                            {details.serviceCount + (details.serviceCount > 1  ? ' Services' : ' Service')}
                          </text>
                          </div>
                      </div>
                  </div>
                </div>
                {
                state.token && <div style={{display:'flex',justifyContent:'flex-start'}}>
                  <div className='wishlistBg_ProMap' onClick={()=>WishlistPressHandler()} style={{cursor:'pointer'}}>
                   { details.wishlistFlag ? <img src={WishlistYes} style={{width:16,height:16}}/> : <img src={WishlistIcon} style={{width:16,height:16}}/>}
                  </div>
                </div>
                }
              </Card>
              </div>
            : null
          }

    </div>
  )
}

