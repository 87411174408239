    import React, { useContext, useEffect, useState } from 'react'
    import SearchBar from '../../Components/Custom/UI/SearchBar'
    import '../../CSS/UI Components/ProfessionalUnderCategory_Mobile.css'
    import { useMediaQuery } from "react-responsive"
    import { NavLink, useLocation, useNavigate,useSearchParams } from 'react-router-dom';
    import AllServicesIcon from '../../Assets/AllServices.png'
    import filtersIcon from '../../Assets/filterIcon.png'
    import ratingsIcon from '../../Assets/ratingsIcon.png'
    import discountIcon from '../../Assets/discountIcon.png'
    import filterIcon_white from '../../Assets/filterIcon_white.png'
    import ratingsIcon_white from '../../Assets/ratingsIcon_white.png'
    import discountIcon_white from '../../Assets/discountIcon_white.png'
    import DropdownIcon from '../../Assets/dropdown_black.png'
    import dropDown_filter from '../../Assets/dropdown_white.png'
    import subcat_img from '../../Assets/subcat_img.png'
    import FilterArrowUp from '../../Assets/filters_arrow_up.png'
    import FilterArrowDown from '../../Assets/filters_arrow_down.png'
    import crossIcon from '../../Assets/crossIcon.png'
    import DatePicker from "react-date-picker";
    import { ToastContainer, toast } from 'react-toastify';
    import 'react-date-picker/dist/DatePicker.css';
    import 'react-calendar/dist/Calendar.css';
    import TimePicker from 'react-time-picker';
    import moment from 'moment'
    import momenttz from "moment-timezone"
    import { globalState } from '../../GlobalState/globalState'
    import calendarIcon from '../../Assets/calendarIcon.png'
    import timeIcon from '../../Assets/timeIcon.png'
    import 'react-time-picker/dist/TimePicker.css';
    import 'react-clock/dist/Clock.css';
    import { Range } from 'react-range';
    import numeral from 'numeral';
    import curr from 'currency.js';
    import customFetchData from '../../Components/Custom/customFetchData';
    import { catColors } from '../../Components/Custom/UI/Colors'
    import { ClipLoader } from 'react-spinners';
    // import "react-datepicker/dist/react-datepicker-cssmodules.css";
    // import '../../../../node_modules/react-datepicker/dist/react-datepicker.css';
    import { Container, Row, Col, Card, CardBody, CarouselItem, Carousel, Navbar, CarouselIndicators, Modal, ModalFooter, ModalHeader, ModalBody, Collapse, FormGroup } from 'reactstrap'
    import ProfessionalCard_Mobile  from '../../Components/Custom/UI/ProfessionalCard_Mobile'
    import { clearAddress, clearServices, storeAddress, storeDateAndTime, storeServices } from '../../GlobalState/globalStateActions'
    import FetchCurrentLocation from '../../Components/Custom/FetchCurrentLocation'
    const DeepClone = require("rfdc/default")
    export default function ProfessionalUnderCategory() {
        const [state, dispatch] = useContext(globalState)
        const paramsLocation = useLocation()
        const navigate = useNavigate()
        const [searchTextOffer, setSearchTextOffer] = useState("")
        const [categoryName, setCategoryName] = useState('')
        const [categoryImage, setCategoryImage] = useState('')
        const [nearByPros, setNearByPros] = useState([])
        const [categories, setCategories] = useState([])
        const [mpidArr, setMpidArr] = useState([])
        const [subCategories, setsubCategories] = useState([])
        const [activeIndex, setActiveIndex] = useState(0);
        const [subcategories, setSubcategories] = useState([])
        const [filtersModal, setFiltersModal] = useState(false)
        const [sortRatings, setSortRatings] = useState(false)
        const [sortDiscount, setSortDiscount] = useState(false)
        const [dateCollapsed, setDateCollapsed] = useState(true)
        const [timeCollapsed, setTimeCollapsed] = useState(true)
        const [priceCollapsed, setPriceCollapsed] = useState(true)
        const [distanceCollapsed, setDistanceCollapsed] = useState(true)
        const [distanceMin, setDistanceMin] = useState(state.distanceMin)
        const [distanceMax, setDistanceMax] = useState(state.distanceMax)
        const [ratingsMin, setRatingsMin] = useState(0)
        const [ratingsMax, setRatingsMax] = useState(5)
        const [priceMin, setPriceMin] = useState(0)
        const [priceMax, setPriceMax] = useState(state.address.country === 'India' ? 15000 : 5000)
        const [male, setMale] = useState(false)
        const [both, setBoth] = useState(false)
        const [checked, setChecked] = useState([])
        const [selectedCatPrid, setSelectedCatPrid] = useState(0)
        const [onClickReset, setOnClickReset] = useState(false)
        const [country, setCountry] = useState('IN')
        const [isDateTimePickerVisible, setIsDateTimePickerVisible] = useState(false)
        const [isDateTimePickerVisible1, setIsDateTimePickerVisible1] = useState(false)
        const [isDateTimePickerVisible2, setIsDateTimePickerVisible2] = useState(false)
        const [isDateTimePickerVisible3, setIsDateTimePickerVisible3] = useState(false)
        const [fromDateTime, setFromDateTime] = useState('')
        const [toDateTime, setToDateTime] = useState('')
        const [mindate, setMindate] = useState('')
        const [selectedBTimes, setSelectedBTimes] = useState({ fromTime: moment().set({ 'hour': 0, 'minute': 0 }), toTime: moment().set({ 'hour': 23, 'minute': 59 }) })
        const [fromHours, setFromHours] = useState('')
        const [toHours, setToHours] = useState('')
        const [subLoading, setSubLoading] = useState(true)
        const [proLoading, setProLoading] = useState(true)
        const [catPrid, setCatPrid] = useState(null)
        const [filteredSubCats, setFilteredSubCats] = useState([])
        const [subCatPrid, setSubCatPrid] = useState(null)
        const [subcatCheck, setSubcatCheck] = useState({})
        const [searchDropdownArray, setSearchDropdownArray] = useState([])
        const [didClearSearch, setDidClearSearch] = useState(false)
        const [showSearchDropDown, setShowSearchDropDown] = useState(false)
        const [searchClickedValue, setSearchClickedValue] = useState("")
        const [clickOnOfferSearch, setClickOnOfferSearch] = useState(false)
        const [merchantTimeZone, setMerchantTimeZone] = useState(state.country.timezone)
        const [filteredNearByPros, setFilteredNearByPros] = useState([])
        const [applyButtonLoading, setApplyButtonLoading] = useState(false)
        const [calFilteredOffers, setCalFilteredOffers] = useState('')
        const [onApplyClick, setOnApplyClick] = useState(false)
        const [onApplyClick2, setOnApplyClick2] = useState(false)
        const [prevFilters, setPrevFilters] = useState({})
        const [bannerImage, setBannerImage] = useState('')
        const [searchSamePage, setSearchSamePage] = useState(false)
        const [cityNotYet, setCityNotYet] = useState(false)
        const [searchParams, setSearchParams] = useSearchParams()
        const [catModal, setCatModal ] = useState(false)
        const useMobileAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 760px)" })
        const useTabletMediaQuery = () => useMediaQuery({ minWidth: 768, maxWidth: 991 })
        const isMobileView = useMobileAndBelowMediaQuery()
        const isTabView = useTabletMediaQuery()
        let searchUrl = paramsLocation.search
        let merchantTimezoneL = state.country.timezone
        let MerchantItems =[
        {
            key: 1,
            src:{
                // web:NewImageMer,
                // mobile:NewImageMerMobile
            },
            altText: (
            <div>
                {/* <div style={{position:'absolute'}}>
                    <NewNavbarWebsite/>
                </div> */}
                <div style={{top:'45%',left:'5%',right:'5%',position:'absolute',display:'flex',flexDirection:'column',zIndex:10 }}>
                    {/* <div className="textStyle_sv2"> */}
                        <text className='serviceText_mobile_sv2'>Are you looking for services ?</text>
                        <text className='serviceTextDesc_mobile_sv2'>Explore top deals and offers exclusively for you</text>
                    {/* </div> */}
                </div>
            </div>
            ),
        },
    ]
    let MerchantSlides = MerchantItems.map((item) => {
        return (
        
                <CarouselItem
                    // onExiting={this.onExiting}
                    // onExited={this.onExited}
                    key={item.key}
                >
                    <div className="CarousalContainer_Sv2_mobile">
                        {item.altText}

                        <img className='imgStylesv2' src={ bannerImage }/>
                        {/* <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1666875231964-lg.jpg'} className='Carousal_policy_mobile'/> */}
                    </div>
                </CarouselItem>
            
        );
    });
    let header = {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        // marginHorizontal: 30,
        marginTop: 25,
    }
    let dateStyle = {
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#747474'
    }
    let dateViewStyle = {
        borderRadius: 5,
        backgroundColor: "#ffffff",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#e1e1e1",
        marginHorizontal: 30,
        marginTop: 20,
        padding: 12,
    }
    let timeViewStyle = {
        borderRadius: 5,
        backgroundColor: "#ffffff",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#e1e1e1",
        marginHorizontal: 30,
        marginTop: 20,
        padding: 12,
    }
    let priceBox = {
        width: 75,
        // height: 28,
        borderRadius: 5,
        backgroundColor: "#ffffff",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#e1e1e1",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0
    }
    let priceTextStyle = {
        fontFamily: "Inter",
        fontSize: 12,
        fontWeight: "300",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "left",
        color: "#000000"

    }
    let resetStyle= {
        width: '47%',
        height: 60,
        // padding:10,
        borderRadius: 10,
        backgroundColor: "#ffffff",
        borderStyle: "solid",
        borderWidth: 1.5,
        borderColor: 'rgb(140,82,255)',
        alignItems: 'center',
        display:'flex',
        justifyContent: 'center',
        cursor:'pointer'
    }
    let updateStyle= {
        width: '47%',
        height: 60,
        borderRadius: 10,
        backgroundColor: "#ffffff",
        borderStyle: "solid",
        borderWidth: 1.5,
        borderColor: 'rgb(140,82,255)',
        alignItems: 'center',
        justifyContent: 'center',
        display:'flex',
        backgroundColor: 'rgb(140,82,255)',
        cursor:'pointer'

    }
    let ResetTextStyle= {
        // height: 19,
        fontFamily: "Inter",
        fontSize: 16,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        // textAlign: "center",
        color: 'rgb(140,82,255)'
    }
    let updateTextStyle= {
        // height: 19,
        fontFamily: "Inter",
        fontSize: 16,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        // textAlign: "center",
        color: '#fff'
    }
    const onSubCatSelection = (sub_item) => {
        console.log("subcatCheck", subcatCheck)
        setSearchSamePage(false)
        paramsLocation.state.fromSearch= false
        setSubCatPrid(sub_item.qf_service_sub_category_prid)
        let subcatCheckTemp = { ...subcatCheck }
        subCategories.map((item) => {
            if (item.qf_service_sub_category_prid === sub_item.qf_service_sub_category_prid) {
                subcatCheckTemp['subcheck_' + item.qf_service_sub_category_prid] = true
            }
            else {
                subcatCheckTemp['subcheck_' + item.qf_service_sub_category_prid] = false
            }
        })
        let offers = [], newOffers1 = [], newOffers2 = [], newOffers3 = [], newOffers4 = [], matchesArr = [];
        offers = DeepClone(nearByPros)
        offers.map((item1) => {
            newOffers3 = item1.filter(item2 => {
                return (item2.QF_SERVICE_SUB_CATEGORY_PRID === sub_item.qf_service_sub_category_prid)
                &&
                (priceMin <= item2.service_offer_price) && (item2.service_offer_price <= priceMax)
                &&
                (distanceMin <= (item2.SERVICE_CURRENCY==='Rupees'? curr(item2.distance).divide(1000).value : curr(item2.distance).divide(1609.344).value)) && ((item2.SERVICE_CURRENCY==='Rupees' ? curr(item2.distance).divide(1000).value : curr(item2.distance).divide(1609.344).value) <= distanceMax)
            })
            if (newOffers3.length !== 0) {
                newOffers4.push(newOffers3);
            }
        });

        if (sortRatings) {
            //// Sorting by ratings - high to low
            newOffers4.sort((a, b) => {
                return (b[0].MERCHANT_PERSONNEL_RATING ? b[0].MERCHANT_PERSONNEL_RATING : 0) - (a[0].MERCHANT_PERSONNEL_RATING ? a[0].MERCHANT_PERSONNEL_RATING : 0)
            });
        }

        if (sortDiscount) {
            //// Sorting by Discount - high to low
            newOffers4.map((item, index) => {
                item.sort((a, b) => {
                    return b.service_discount - a.service_discount
                })
            })
            newOffers4.sort((a, b) => {
                return b[0].service_discount - a[0].service_discount
            });
        }

        setFilteredNearByPros(newOffers4)
        setSubcatCheck(subcatCheckTemp)

    }
    const onload1 = (categoryPrid, nearByPros, categories, subCategories) => {
        console.log('data in puc page', categories, subCategories, nearByPros,)
        // let professionals;
        if (nearByPros.length !== 0) {
            setNearByPros(nearByPros)
            setFilteredNearByPros(nearByPros)
            setProLoading(false)


            nearByPros.map(checkedItem => {
                // console.log('checked item widhlist value',checkedItem)
                if (checkedItem[0].wishlist_Pro === 0) {
                    return checked['checked_' + checkedItem[0].QF_MERCHANT_PERSONNEL_PRID] = true
                } else {
                    return checked['checked_' + checkedItem[0].QF_MERCHANT_PERSONNEL_PRID] = false
                }
            })
        }

        if (subCategories.length !== 0) {
            setSubLoading(false)
            let filteredSub = []
            if (categoryPrid != 0) {
                // console.log('in cat prid not null',categoryPrid)
                filteredSub = subCategories.filter((item) => {
                    return item.QF_SERVICE_CATEGORY_PRID === categoryPrid
                })
            }
            else {
                filteredSub = subCategories.filter((item) => {
                    return item
                })
            }
            setFilteredSubCats(filteredSub)
            let subcatToState = {}
            subCategories.map((subcatItem) => {
                // if ( fromCatSearch) {
                //     setSubCatPrid(params.subcatItem.qf_service_sub_category_prid)
                //     if (subcatItem.qf_service_sub_category_prid === params.subcatItem.qf_service_sub_category_prid) {
                //         subcatToState["subcheck_" + subcatItem.qf_service_sub_category_prid] = true;
                //     }
                //     else {
                //         subcatToState["subcheck_" + subcatItem.qf_service_sub_category_prid] = false;
                //     }
                // }
                // else
                // {
                subcatToState["subcheck_" + subcatItem.qf_service_sub_category_prid] = true;
                // }

            })
            setSubcatCheck(subcatToState)
        }
        let filteredPros = [], newOffers2 = [], newOffers1 = []
        // if (route.params.fromExplore && !fromCatSearch) 
        // {
        //     if(route.params.nearByPros.length!==0)
        //     {
        //         route.params.nearByPros.map(item1 => {
        //             newOffers1 = item1.filter(item2 => {
        //                 return item2.QF_SERVICE_CATEGORY_PRID === categoryPrid && item2.QF_SERVICE_SUB_CATEGORY_PRID === route.params.subcatItem.qf_service_sub_category_prid 
        //             })
        //             if (newOffers1.length !== 0) {
        //                 newOffers2.push(newOffers1);
        //             }
        //         })
        //         filteredPros = newOffers2;
        //     }

        // }
        // else 
        // {
        if (nearByPros.length !== 0) {
            nearByPros.map(item1 => {
                // console.log('nearBypros',nearByPros)
                if (categoryPrid != 0) {
                    newOffers1 = item1.filter(item2 => {
                        // console.log('itemsss',item2.QF_SERVICE_CATEGORY_PRID,categoryPrid)
                        return item2.QF_SERVICE_CATEGORY_PRID == Number(categoryPrid)
                    })
                }
                else {
                    newOffers1 = item1.filter(item2 => {
                        return item2
                    })
                }
                // console.log('newoffers1',newOffers1)
                if (newOffers1.length !== 0) {
                    newOffers2.push(newOffers1);
                }
            });
            filteredPros = newOffers2;
            console.log('filteredpros', filteredPros)
        }
        // }
        setFilteredNearByPros(filteredPros)
        // if( paramsLocation.state && paramsLocation.state.fromSearch){
        //     setFilteredNearByPros(paramsLocation.state.selectedServices)
        // }
        // if(fromSearch){
        //     setSearchSamePage(true)
        //     setFilteredNearByPros(finalPros)
        // }
    }
    const FetchLocation = async (searchParams) => {
        let addressDetails = await FetchCurrentLocation(state, dispatch)
        // console.log('addressdetails', addressDetails)
        let city_data = {
            curr_city: addressDetails.city,
            curr_lat: addressDetails.curr_location_latitude,
            curr_long: addressDetails.curr_location_longitude,
            stateOrProvince: addressDetails.stateOrProvince,
        }
        if (state.user.id && state.token) {
            city_data.consumerId = state.user.id
        }
        let { fetchedData } = await customFetchData('POST', city_data, '/website/services/currentservices/sv2', state, dispatch)
        if (fetchedData.getOff) {
            // console.log("inside use effect puc page",fetchedData)
            setCategories(fetchedData.catsFinal)
            setsubCategories(fetchedData.subcatsFinal)
            // setNearByPros(fetchedData.offers)
            let mpidArr = [];
            mpidArr = fetchedData.mpidArr
            if (fetchedData.mpidArr.length !== 0) {
                let body = {
                    mpidArr: mpidArr,
                    consumer_prid: state.user.id,
                    wishlistType: 'professional'
                }
                let wishListedData = await customFetchData('POST', body, '/website/wishlistedPros/sv2', state, dispatch)
                //  console.log('wish list',wishListedData)
                if (wishListedData.fetch) {
                    if (wishListedData.fetchedData.wishlistRes.length !== 0) {
                        //   console.log('entered')
                        fetchedData.offers.map(item => {
                            // console.log('item',item[0])
                            item.map(item11 => {
                                wishListedData.fetchedData.wishlistRes.map(item1 => {
                                    // console.log('wish list values',item[0],item1)
                                    if (item11.QF_MERCHANT_PERSONNEL_PRID === item1.QF_MERCHANT_PERSONNEL_PRID) {
                                        // console.log('wish list values',item1)
                                        return (
                                            item11.wishlistPro_Prid = item1.QF_WISHLIST_PRID,
                                            item11.wishlist_Pro = item1.WISHLIST_DELETED_FLAG
                                        )
                                    }
                                })
                            })
                        })
                        fetchedData.offers.map(item11 => {
                            // console.log('item',item11)
                            if (item11[0].wishlist_Pro === 0) {
                                return checked['checked_' + item11[0].QF_MERCHANT_PERSONNEL_PRID] = true
                            } else {
                                return checked['checked_' + item11[0].QF_MERCHANT_PERSONNEL_PRID] = false
                            }
                        })
                        setNearByPros(fetchedData.offers)
                        setFilteredNearByPros(fetchedData.offers)
                        storeServices(fetchedData.offers,fetchedData.catsFinal,fetchedData.subcatsFinal,dispatch)
                    } else {
                        setNearByPros(fetchedData.offers)
                        setFilteredNearByPros(fetchedData.offers)
                        storeServices(fetchedData.offers,fetchedData.catsFinal,fetchedData.subcatsFinal,dispatch)
                    }
                } else {
                    setNearByPros(fetchedData.offers)
                    setFilteredNearByPros(fetchedData.offers)
                    storeServices(fetchedData.offers,fetchedData.catsFinal,fetchedData.subcatsFinal,dispatch)
                }
            }
            setMpidArr(fetchedData.mpidArr)
            if (fetchedData.offers && fetchedData.offers.length !== 0) {
                setMerchantTimeZone(fetchedData.offers[0][0].SERVICE_TIMEZONE)
                merchantTimezoneL = fetchedData.offers[0][0].SERVICE_TIMEZONE
                setMindate(moment().tz(merchantTimezoneL))
                setFromDateTime(moment().tz(merchantTimezoneL).set({ 'hour': 0, 'minute': 0 }))
                setToDateTime(moment().tz(merchantTimezoneL).set({ 'hour': 23, 'minute': 59 }).add(14, "days"))
            }
            if (fetchedData.offers) {
                if(searchParams.category == 'Beauty' ){
                    setBannerImage('https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684328633938-lg.png')
                } 
                else if(searchParams.category == 'HairCare'){
                    setBannerImage('https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684328680186-lg.png' )
                }
                else if(searchParams.category == 'NailCare' ){
                    setBannerImage( 'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684328652706-lg.png')
                }
                else if(searchParams.category == 'Auto Care'){
                    setBannerImage( 'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684328607990-lg.png')
                }
                else{
                    setBannerImage('https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684328607990-lg.png')
                }
            }
            let fromDateTime = moment().tz(merchantTimezoneL).set({ 'hour': 0, 'minute': 0 })
            let toDateTime = moment().tz(merchantTimezoneL).set({ 'hour': 23, 'minute': 59 }).add(14, "days")
            // storeDateAndTime(fromDateTime,toDateTime,dispatch)
            setSearchTextOffer('')
            setShowSearchDropDown(false)
            let cat = { category_image: AllServicesIcon, QF_SERVICE_CATEGORY_PRID: 0, category_name: 'All Professionals' }
            let categories = DeepClone(fetchedData.catsFinal ? fetchedData.catsFinal : [])
            categories.splice(0, 0, cat)
            setCategories(categories)
            setCatPrid(Number(searchParams.catId ? searchParams.catId : 0))
            setCategoryName(searchParams.category)
            setSelectedCatPrid(Number(searchParams.catId ? searchParams.catId : 0))
            onload1(searchParams.catId ? Number(searchParams.catId) : 0, fetchedData.offers, fetchedData.catsFinal, fetchedData.subcatsFinal)
        }
        else {
            if (fetchedData.cityNotYet) {
                setCityNotYet(true)
            }
            else {
                toast.error("Something went wrong. Please try later.", { position: toast.POSITION.BOTTOM_CENTER });
            }
            setCategories([])
            setsubCategories([])
            setNearByPros([])
            setFilteredNearByPros([])
            // storeServices([],[],[],dispatch)
            //   dispatch({type:'SET_SERVICES',services:[]})
            //   dispatch({type:'SET_CATEGORIES',categories:[]})
            //   dispatch({type:'SET_SUBCATEGORIES',subcategories:[]})
            //   dispatch({type:'SET_SERVICE_REFRESH_COUNTER',servicesRefreshCounter:servicesRefreshCounter+1})
        }
    }
    useEffect(() => {
        
        // console.log('params ',paramsLocation.state)
        // let services= DeepClone(state.services)
        if(paramsLocation.state){
            if( state.services && state.services.length!==0){
                setMerchantTimeZone(state.services[0][0].SERVICE_TIMEZONE)
                merchantTimezoneL=state.services[0][0].SERVICE_TIMEZONE
                setMindate(moment().tz(merchantTimezoneL))
                setFromDateTime(moment().tz(merchantTimezoneL).set({ 'hour': 0, 'minute': 0 }))
                setToDateTime(moment().tz(merchantTimezoneL).set({ 'hour': 23, 'minute': 59 }).add(14, "days"))
            }
            if(paramsLocation.state.catItem.category_name == 'Beauty' ){
                setBannerImage('https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684328633938-lg.png')
            } 
            else if(paramsLocation.state.catItem.category_name == 'Hair Care'){
                setBannerImage('https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684328680186-lg.png' )
            }
            else if(paramsLocation.state.catItem.category_name == 'Nail Care' ){
                setBannerImage( 'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684328652706-lg.png')
            }
            else if(paramsLocation.state.catItem.category_name == 'Auto Care'){
                setBannerImage( 'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684328607990-lg.png')
            }
            else{
                setBannerImage('https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684328607990-lg.png')
            }
        //// setting from and to date in global state
        dispatch({type:'SET_FROM_DATE_TIME',fromDateTime:moment().tz(merchantTimezoneL).set({ 'hour': 0, 'minute': 0 })})
        dispatch({type:'SET_TO_DATE_TIME',toDateTime:moment().tz(merchantTimezoneL).set({ 'hour': 23, 'minute': 59 }).add(14, "days")})
        let fromDateTime=moment().tz(merchantTimezoneL).set({ 'hour': 0, 'minute': 0 })
        let toDateTime=moment().tz(merchantTimezoneL).set({ 'hour': 23, 'minute': 59 }).add(14, "days")
        storeDateAndTime(fromDateTime,toDateTime,dispatch)
        setSearchTextOffer('')
        setShowSearchDropDown(false)
        let cat = { category_image: AllServicesIcon, QF_SERVICE_CATEGORY_PRID: 0, category_name: 'All Professionals' }
        let categories = DeepClone(paramsLocation.state ? paramsLocation.state.categories : [])
        categories.splice(0, 0, cat)
        setCategories(categories)
        setCatPrid(paramsLocation.state ? (paramsLocation.state.catPrid ? paramsLocation.state.catPrid : null): null)
        setCategoryName(paramsLocation.state  ? (paramsLocation.state.catItem.category_name ? paramsLocation.state.catItem.category_name : ' ') : '')
        setCategoryImage(paramsLocation.state  ? (paramsLocation.state.catItem ? paramsLocation.state.catItem.category_image : ' ') : ' ')
        setSelectedCatPrid( paramsLocation.state ? (paramsLocation.state.catPrid ? paramsLocation.state.catPrid : null): null)
        if(state.services.length === 0){
            storeServices(paramsLocation.state.nearByPros,paramsLocation.state.categories,paramsLocation.state.subCategories,dispatch)
          }
          if(state.address.totalAddress ===  null){
            let addressDetails=paramsLocation.state.addressDetails
            storeAddress(addressDetails.totalAddress,addressDetails.addressLine1,addressDetails.country,addressDetails.city,addressDetails.stateOrProvince,addressDetails.zipcode,addressDetails.district,addressDetails.latitude,addressDetails.longitude,addressDetails.currentAddress,addressDetails.searchAddress,addressDetails.currentLocationDenied,dispatch,state)
          }
        onload(paramsLocation.state ? paramsLocation.state.catPrid : null)
        }
        else {
            let regex = /[?&]([^=#]+)=([^&#]*)/g, searchParams = {}, match
            while ((match = regex.exec(searchUrl))) {
                searchParams[match[1]] = match[2]
            }
            // clearServices(dispatch)
            // clearAddress(dispatch)
            // if (Object.keys(searchParams).length !== 0) {
                FetchLocation(searchParams)
            // }
        }
    },[])
    const onload = (categoryPrid, fromCatSearch, fromSearch, finalPros,) => {
        if(paramsLocation.state){
        if ( paramsLocation.state.nearByPros.length !== 0) {
            setNearByPros(paramsLocation.state.nearByPros)
            setFilteredNearByPros(paramsLocation.state.nearByPros)
            setProLoading(false)
        

            paramsLocation.state.nearByPros.map(checkedItem => {
                // console.log('checked item widhlist value',checkedItem)
                if (checkedItem[0].wishlist_Pro === 0) {
                    return checked['checked_' + checkedItem[0].QF_MERCHANT_PERSONNEL_PRID] = true
                } else {
                    return checked['checked_' + checkedItem[0].QF_MERCHANT_PERSONNEL_PRID] = false
                }
            })
        }
    
        if ( paramsLocation.state && paramsLocation.state.subCategories.length !== 0) {
            setSubLoading(false)
            setsubCategories(paramsLocation.state.subCategories)
            let filteredSub = []
            if(categoryPrid !=0 ){
                filteredSub = paramsLocation.state.subCategories.filter((item) => {
                    return item.QF_SERVICE_CATEGORY_PRID === categoryPrid
                }) 
            }
            else{
                filteredSub = paramsLocation.state.subCategories.filter((item) => {
                    return item
                })
            }
            setFilteredSubCats(filteredSub)
            let subcatToState = {}
            paramsLocation.state.subCategories.map((subcatItem) => {
                // if ( fromCatSearch) {
                //     setSubCatPrid(params.subcatItem.qf_service_sub_category_prid)
                //     if (subcatItem.qf_service_sub_category_prid === params.subcatItem.qf_service_sub_category_prid) {
                //         subcatToState["subcheck_" + subcatItem.qf_service_sub_category_prid] = true;
                //     }
                //     else {
                //         subcatToState["subcheck_" + subcatItem.qf_service_sub_category_prid] = false;
                //     }
                // }
                // else
                // {
                subcatToState["subcheck_" + subcatItem.qf_service_sub_category_prid] = true;
                // }

            })
            setSubcatCheck(subcatToState)
        }
        let filteredPros = [], newOffers2 = [], newOffers1 = []
        // if (route.params.fromExplore && !fromCatSearch) 
        // {
        //     if(route.params.nearByPros.length!==0)
        //     {
        //         route.params.nearByPros.map(item1 => {
        //             newOffers1 = item1.filter(item2 => {
        //                 return item2.QF_SERVICE_CATEGORY_PRID === categoryPrid && item2.QF_SERVICE_SUB_CATEGORY_PRID === route.params.subcatItem.qf_service_sub_category_prid 
        //             })
        //             if (newOffers1.length !== 0) {
        //                 newOffers2.push(newOffers1);
        //             }
        //         })
        //         filteredPros = newOffers2;
        //     }

        // }
        // else 
        // {
        if (paramsLocation.state.nearByPros.length !== 0) {
            paramsLocation.state.nearByPros.map(item1 => {
                if(categoryPrid != 0){
                    newOffers1 = item1.filter(item2 => {
                        return item2.QF_SERVICE_CATEGORY_PRID === categoryPrid
                    })
                }
                else{
                    newOffers1 = item1.filter(item2 => {
                        return item2
                    })
                }
                if (newOffers1.length !== 0) {
                    newOffers2.push(newOffers1);
                }
            });
            filteredPros = newOffers2;
            // console.log('filteredpros',filteredPros)
        }
        // }
        setFilteredNearByPros(filteredPros)
        if( paramsLocation.state && paramsLocation.state.fromSearch){
            // console.log('in from search',route.params.selectedServices)
            setFilteredNearByPros(paramsLocation.state.selectedServices)
        }
        if(fromSearch){
            setSearchSamePage(true)
            setFilteredNearByPros(finalPros)
        }
        }
        else{
        if ( nearByPros.length !== 0) {
            setNearByPros(nearByPros)
            setFilteredNearByPros(nearByPros)
            setProLoading(false)
        

            nearByPros.map(checkedItem => {
                // console.log('checked item widhlist value',checkedItem)
                if (checkedItem[0].wishlist_Pro === 0) {
                    return checked['checked_' + checkedItem[0].QF_MERCHANT_PERSONNEL_PRID] = true
                } else {
                    return checked['checked_' + checkedItem[0].QF_MERCHANT_PERSONNEL_PRID] = false
                }
            })
        }
    
        if (subCategories.length !== 0) {
            setSubLoading(false)
            setsubCategories(subCategories)
            let filteredSub = []
            if(categoryPrid !=0 ){
                filteredSub = subCategories.filter((item) => {
                    return item.QF_SERVICE_CATEGORY_PRID === categoryPrid
                }) 
            }
            else{
                filteredSub = subCategories.filter((item) => {
                    return item
                })
            }
            setFilteredSubCats(filteredSub)
            let subcatToState = {}
            subCategories.map((subcatItem) => {
                // if ( fromCatSearch) {
                //     setSubCatPrid(params.subcatItem.qf_service_sub_category_prid)
                //     if (subcatItem.qf_service_sub_category_prid === params.subcatItem.qf_service_sub_category_prid) {
                //         subcatToState["subcheck_" + subcatItem.qf_service_sub_category_prid] = true;
                //     }
                //     else {
                //         subcatToState["subcheck_" + subcatItem.qf_service_sub_category_prid] = false;
                //     }
                // }
                // else
                // {
                subcatToState["subcheck_" + subcatItem.qf_service_sub_category_prid] = true;
                // }

            })
            setSubcatCheck(subcatToState)
        }
        let filteredPros = [], newOffers2 = [], newOffers1 = []
        // if (route.params.fromExplore && !fromCatSearch) 
        // {
        //     if(route.params.nearByPros.length!==0)
        //     {
        //         route.params.nearByPros.map(item1 => {
        //             newOffers1 = item1.filter(item2 => {
        //                 return item2.QF_SERVICE_CATEGORY_PRID === categoryPrid && item2.QF_SERVICE_SUB_CATEGORY_PRID === route.params.subcatItem.qf_service_sub_category_prid 
        //             })
        //             if (newOffers1.length !== 0) {
        //                 newOffers2.push(newOffers1);
        //             }
        //         })
        //         filteredPros = newOffers2;
        //     }

        // }
        // else 
        // {
        if (nearByPros.length !== 0) {
            nearByPros.map(item1 => {
                if(categoryPrid != 0){
                    newOffers1 = item1.filter(item2 => {
                        return item2.QF_SERVICE_CATEGORY_PRID === categoryPrid
                    })
                }
                else{
                    newOffers1 = item1.filter(item2 => {
                        return item2
                    })
                }
                if (newOffers1.length !== 0) {
                    newOffers2.push(newOffers1);
                }
            });
            filteredPros = newOffers2;
            // console.log('filteredpros',filteredPros)
        }
        // }
        setFilteredNearByPros(filteredPros)
        // if( paramsLocation.state && paramsLocation.state.fromSearch){
        //     setFilteredNearByPros(paramsLocation.state.selectedServices)
        // }
        if(fromSearch){
            setSearchSamePage(true)
            setFilteredNearByPros(finalPros)
        }
        }
        
    }
    const searchBoxOfferhandler = (event) => {
        try {
            //   console.log("value",event.target.value)
            setSearchTextOffer(event.target.value)
            let filteredOffers = DeepClone(filteredNearByPros), filteredOffers1 = DeepClone(filteredNearByPros), matchesArr = [], catsFinalData = DeepClone(categories)
            let value = event.target.value ? event.target.value.trim() : event.target.value
            if (value.length > 2) {
                setShowSearchDropDown(true)
                // console.log("filtered",filteredOffers)
                catsFinalData.map((insideItem) => {
                    if (insideItem.category_name.search(new RegExp(value, 'i')) !== -1) {
                        insideItem.type = 'Category'
                        // console.log("matchesArr in cat filter",insideItem);
                        matchesArr.push(insideItem)
                    }
                })
                filteredOffers.map((insideItem) => {
                    // console.log('item123',insideItem)
                    if (insideItem[0].MERCHANT_NAME.search(new RegExp(value, 'i')) !== -1) {
                        insideItem[0].type = 'Professional'
                        // console.log("matchesArr in pro filter",insideItem);
                        matchesArr.push(insideItem[0])
                    }
                })
                filteredOffers1.map((insideItem) => {
                    // console.log('item service',insideItem)
                    insideItem.map(serviceName => {
                        if (serviceName.SERVICE_NAME.search(new RegExp(value, 'i')) !== -1) {
                            serviceName.type = 'Service'
                            // console.log("matchesArr in service filter",serviceName);
                            matchesArr.push(serviceName)
                        }
                    })
                })
                // console.log("matchesArr outside",matchesArr);
                let catArray, proArray, servicesArray;
                catArray = matchesArr.filter(item => {
                    return item.type == "Category"
                })
                proArray = matchesArr.filter(item => {
                    return item.type == "Professional"
                })
                servicesArray = matchesArr.filter(item => {
                    return item.type == 'Service'
                })
                // console.log('all',proArray,catArray,servicesArray)
                let finalArray = [];
                if (catArray.length !== 0) {
                    // console.log('cat dataaaa',catArray)
                    catArray = catArray.filter((v, i, a) => a.findIndex(t => ((t.category_name === v.category_name))) === i)
                    catArray.map(item => {
                        finalArray.push(item);
                    })
                }
                if (proArray.length !== 0) {
                    // console.log('pro dataaaa',proArray)
                    proArray.map(item => {
                        finalArray.push(item);
                    })
                }
                if (servicesArray.length !== 0) {
                    servicesArray = servicesArray.filter((v, i, a) => a.findIndex(t => ((t.SERVICE_NAME === v.SERVICE_NAME))) === i)
                    servicesArray.map(item => {
                        finalArray.push(item);
                    })
                }
                //  console.log('final array',finalArray)
                if (finalArray.length !== 0) {
                    setSearchDropdownArray(finalArray)
                }
            }
            else {
                setShowSearchDropDown(false)
                setClickOnOfferSearch(false)
                if (!didClearSearch) {
                    setDidClearSearch(true)
                }
                setSearchDropdownArray([])
            }
        }
        catch (cErr) {
            console.log(cErr)
        }
    }
    let searchOfferDropDownItem;
    if (nearByPros.length !== 0 && filteredNearByPros.length !== 0 && showSearchDropDown) {
        if (searchDropdownArray.length !== 0) {
            searchOfferDropDownItem = searchDropdownArray.map((item, index) => {
                return (
                    <div style={{ zIndex: 2000 }}>
                        {item.type === 'Category' ?
                            <div onClick={() => onPressSearchDropDown(item)} style={{ cursor: 'pointer', backgroundColor: '#fff', paddingTop: 10, paddingBottom: 10, borderBottom: '1px solid rgba(216, 197, 252, 0.6)' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 25 }}>
                                    <div className={"imageCatStyle_puc"} style={{backgroundColor:catColors[item.category_name]}}>
                                        <img src={item.category_image} style={{ width: 20, height: 20 }} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 22 }}>
                                        <text className={"searchDropDowntextStyle_puc"}>{item.category_name}</text>
                                        <text className={"searchDropDownSubtextStyle_puc"}>{item.type}</text>
                                    </div>
                                </div>
                            </div>
                            : null}
                        {item.type === 'Professional' ?
                            <div onClick={() => onPressSearchDropDown(item)} style={{ cursor: 'pointer', backgroundColor: '#fff', borderBottom: '1px solid rgba(216, 197, 252, 0.6)', paddingTop: 10, paddingBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 25 }}>
                                    <div className={"imageProStyle"}>
                                        <img src={item.image_link} style={{ width: 44, height: 44, borderRadius: 7 }} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 22 }}>
                                        <text className={"searchDropDowntextStyle_puc"}>{item.MERCHANT_NAME}</text>
                                        <text className={"searchDropDownSubtextStyle_puc"}>{item.type}</text>
                                    </div>
                                </div>
                            </div>
                            : null}

                        {item.type === 'Service' ?
                            <div onClick={() => onPressSearchDropDown(item)} style={{ cursor: 'pointer', backgroundColor: '#fff', borderBottom: '1px solid rgba(216, 197, 252, 0.6)', paddingTop: 10, paddingBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 25 }}>
                                    <div className={"imageProStyle"}>
                                        <img src={item.image_link} style={{ width: 44, height: 44, borderRadius: 7 }} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 22 }}>
                                        <text className={"searchDropDowntextStyle_puc"}>{item.SERVICE_NAME}</text>
                                        <text className={"searchDropDownSubtextStyle_puc"}>{item.type}</text>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                )
            })
        }
        else {
            searchOfferDropDownItem = (
                <div style={{ marginLeft: 20, marginRight: 20, height: 150, justifyContent: "center", alignItems: "center", zIndex: 2000, display: 'flex' }}>
                    <text style={{ fontSize: 12 }}>{"No results matching your search"}</text>
                </div>
            )
        }
    }

    const onPressSearchDropDown = (data) => {
        console.log("search_clicked_value", data)
        // KeyboardEvent.dismiss()
        if (data.type == 'Category') {
            setSearchTextOffer(data.category_name)
            setShowSearchDropDown(false)
            setClickOnOfferSearch(true)
            setDidClearSearch(false)
            onload(data.QF_SERVICE_CATEGORY_PRID,true)
            // navigation.navigate('ProfessionalUnderCategory',{categories,subCategories,nearByPros,catItem:data,mpidArr,catPrid:data.QF_SERVICE_CATEGORY_PRID,fromExplore:false})
            // setCategoryName(data.category_name)
            setSelectedCatPrid(data.QF_SERVICE_CATEGORY_PRID)
            setCatPrid(data.QF_SERVICE_CATEGORY_PRID)
        }
        if (data.type == 'Professional') {
            let offers;
            offers = nearByPros.filter(item => {
                return item[0].QF_MERCHANT_PERSONNEL_PRID == data.QF_MERCHANT_PERSONNEL_PRID
            })
            if (offers.length !== 0) {
                // navigate({pathname:`/allservices/${data.MERCHANT_NAME.replaceAll(" ","","g")}`,search:`?proId=${data.QF_MERCHANT_PERSONNEL_PRID}`}, { state: { proData: data, offers: offers[0] } })
                navigate({ pathname: `/allservices/${data.MERCHANT_NAME.replaceAll(" ", "", "g")}`, search: `?proId=${data.QF_MERCHANT_PERSONNEL_PRID}` }, { state: { proData: data, offers: offers[0],
                    categories: DeepClone(state.categories), subCategories: DeepClone(state.subcategories),
                    addressDetails:
                    {
                        totalAddress: state.address.totalAddress,
                        addressLine1: state.address.addressLine1,
                        country: state.address.country,
                        city: state.address.city,
                        stateOrProvince: state.address.stateOrProvince,
                        zipcode: state.address.zipcode,
                        district: state.address.district,
                        latitude: state.address.latitude,
                        longitude: state.address.longitude,
                        currentAddress: state.address.currentAddress,
                        searchAddress: state.address.searchAddress,
                        currentLocationDenied: state.address.currentLocationDenied
                    },
                 allServices:nearByPros } })
            }
        }
        if (data.type == 'Service') {
            setSearchTextOffer(data.SERVICE_NAME)
            setShowSearchDropDown(false)
            setClickOnOfferSearch(true)
            setDidClearSearch(false)
            let filteredNearByProsArray = DeepClone(nearByPros), matchesArr = []
            filteredNearByProsArray.map((insideItem) => {
                insideItem.map(clickedItem => {
                    if (clickedItem.SERVICE_NAME === data.SERVICE_NAME) {
                        matchesArr.push([clickedItem])
                    }
                })
            })
            if (matchesArr.length !== 0) {
                onload(matchesArr[0][0].QF_SERVICE_CATEGORY_PRID, false, true, matchesArr,)
        }
        }
        setSearchTextOffer('')
        setShowSearchDropDown(false)
        setClickOnOfferSearch(true)
        setDidClearSearch(false)
    }

    const clearSearchResults = () => {
        setSearchTextOffer("")
        setShowSearchDropDown(false)
        setClickOnOfferSearch(false)
        // onApplyFilters(false, true)
        onload(catPrid)
        setDidClearSearch(true)
        setSearchSamePage(false)
        setSearchDropdownArray([])

    }

    const next = () => {
        // if (animating) return;
        const nextIndex = activeIndex === MerchantItems.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        // if (animating) return;
        const nextIndex = activeIndex === 0 ? MerchantItems.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex) => {
        //   console.log("newIndex",newIndex)
        // if (this.animating) return;
        setActiveIndex(newIndex);

    }
    let widthCal;
    widthCal = '80%'
    if (isTabView) {
        widthCal = '95%'
    }
    const WishlistPressHandler = async (item) => {
        console.log("pressed", item)
        nearByPros.map(list => {
            if (list[0].QF_MERCHANT_PERSONNEL_PRID === item.QF_MERCHANT_PERSONNEL_PRID) {
                return setChecked((prevState) => {
                    return {
                        ...prevState,
                        ['checked_' + list[0].QF_MERCHANT_PERSONNEL_PRID]: !checked['checked_' + list[0].QF_MERCHANT_PERSONNEL_PRID]
                    }
                })
            }
        })
        let body = {
            // cprid:item.QF_CONSUMER_PRID,
            cprid: state.user.id,
            mpprid: item.QF_MERCHANT_PERSONNEL_PRID,
            wishLstType: 'professional'
        }
        let WishlistInsert = await customFetchData('POST', body, '/website/add/wishList/sv2', state, dispatch)
        // console.log('wish list response', WishlistInsert)
    }
    const onPressProCard= (item) =>{
        // let params=paramsLocation.state
        if(paramsLocation.state){
            if(paramsLocation.state.fromSearch || searchSamePage){
                console.log('item',item)
                let offers;
                offers=nearByPros.filter(item1=>{
                    if(item1[0].QF_MERCHANT_PERSONNEL_PRID == item[0].QF_MERCHANT_PERSONNEL_PRID){
                        return item1
                    }
                })
                // console.log('offers',offers)
                if(offers.length!==0){
                    // navigate({pathname:`/allservices/${item[0].MERCHANT_NAME.replaceAll(" ","","g")}`,search:`?proId=${item[0].QF_MERCHANT_PERSONNEL_PRID}`}, {state:{ proData: item[0], offers:offers[0], singleService:item,catId:catPrid,proChecked:checked['checked_'+ item[0].QF_MERCHANT_PERSONNEL_PRID] ? true : false}})
                    navigate({ pathname: `/allservices/${item[0].MERCHANT_NAME.replaceAll(" ", "", "g")}`, search: `?proId=${item[0].QF_MERCHANT_PERSONNEL_PRID}` }, { state: { proData: item[0], offers: offers[0], singleService: item, catId: catPrid, proChecked: checked['checked_' + item[0].QF_MERCHANT_PERSONNEL_PRID] ? true : false,
                    categories: DeepClone(state.categories), subCategories: DeepClone(state.subcategories),
                    addressDetails:
                    {
                        totalAddress: state.address.totalAddress,
                        addressLine1: state.address.addressLine1,
                        country: state.address.country,
                        city: state.address.city,
                        stateOrProvince: state.address.stateOrProvince,
                        zipcode: state.address.zipcode,
                        district: state.address.district,
                        latitude: state.address.latitude,
                        longitude: state.address.longitude,
                        currentAddress: state.address.currentAddress,
                        searchAddress: state.address.searchAddress,
                        currentLocationDenied: state.address.currentLocationDenied
                    },
                 allServices:nearByPros } })
                }
            }else{
                console.log('item',item)
                // navigate({pathname:`/allservices/${item[0].MERCHANT_NAME.replaceAll(" ","","g")}`,search:`?proId=${item[0].QF_MERCHANT_PERSONNEL_PRID}`}, {state:{ proData: item[0],offers:item,catId:catPrid,subcatId: subCatPrid,fromPfu: subCatPrid !== null ? true : (catPrid !=0 ? true : false), proChecked:checked['checked_'+ item[0].QF_MERCHANT_PERSONNEL_PRID] ? true : false}})
                navigate({ pathname: `/allservices/${item[0].MERCHANT_NAME.replaceAll(" ", "", "g")}`, search: `?proId=${item[0].QF_MERCHANT_PERSONNEL_PRID}` }, { state: { proData: item[0], offers: item, catId: catPrid, subcatId: subCatPrid, fromPfu: subCatPrid !== null ? true : (catPrid != 0 ? true : false), proChecked: checked['checked_' + item[0].QF_MERCHANT_PERSONNEL_PRID] ? true : false,
                categories: DeepClone(state.categories), subCategories: DeepClone(state.subcategories),
                addressDetails:
                {
                    totalAddress: state.address.totalAddress,
                    addressLine1: state.address.addressLine1,
                    country: state.address.country,
                    city: state.address.city,
                    stateOrProvince: state.address.stateOrProvince,
                    zipcode: state.address.zipcode,
                    district: state.address.district,
                    latitude: state.address.latitude,
                    longitude: state.address.longitude,
                    currentAddress: state.address.currentAddress,
                    searchAddress: state.address.searchAddress,
                    currentLocationDenied: state.address.currentLocationDenied
                },
             allServices:nearByPros } })
            }
        }
        else{
            navigate({ pathname: `/allservices/${item[0].MERCHANT_NAME.replaceAll(" ", "", "g")}`, search: `?proId=${item[0].QF_MERCHANT_PERSONNEL_PRID}` }, { state: { proData: item[0], offers: item, catId: catPrid, subcatId: subCatPrid, fromPfu: subCatPrid !== null ? true : (catPrid != 0 ? true : false), proChecked: checked['checked_' + item[0].QF_MERCHANT_PERSONNEL_PRID] ? true : false ,
            categories: DeepClone(state.categories), subCategories: DeepClone(state.subcategories),
            addressDetails:
            {
                totalAddress: state.address.totalAddress,
                addressLine1: state.address.addressLine1,
                country: state.address.country,
                city: state.address.city,
                stateOrProvince: state.address.stateOrProvince,
                zipcode: state.address.zipcode,
                district: state.address.district,
                latitude: state.address.latitude,
                longitude: state.address.longitude,
                currentAddress: state.address.currentAddress,
                searchAddress: state.address.searchAddress,
                currentLocationDenied: state.address.currentLocationDenied
            },
         allServices:nearByPros} })
        }
        
    }
    // Sub-Categories display
    let subItems;
    if (filteredSubCats.length !== 0) {
        subItems = (
            filteredSubCats.map((item, index) => {
                // {console.log('subcat',item)}
                return (
                    <div onClick={() => onSubCatSelection(item)} style={{width:100,cursor:'pointer',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',alignContent:'center',marginRight:10 }}>
                        <div className='subcat_div_sv2_mobile' style={{borderColor : subcatCheck["subcheck_" + item.qf_service_sub_category_prid] ?  catColors[item.category_name] :  "#fff" }}>
                            <div className='sub-catimg_sv2_mobile' style={{ backgroundColor: catColors[item.category_name] }}>
                                <img src={item.service_sub_category_image } style={{ width: 50, height: 60, }} />
                            </div>
                        </div>
                        <text className='sub_name_sv2' style={{  marginTop: 6, color: subcatCheck["subcheck_" + item.qf_service_sub_category_prid] ? catColors[item.category_name] : "#b8b8b8" }}>{item.service_sub_category_name}</text>
                    </div>
                )
            })
        )

    }
    else {
        if (subLoading && filteredSubCats.length == 0) {
            subItems = (
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',padding:15 }}>
                    <text className='no_pro_style'>No sub categories found.</text>   
                </div>
            )
        }
    }
    let proCards;
    if (nearByPros.length !== 0) {
        // let params=paramsLocation.state
        if(filteredNearByPros.length !==0 ){

            proCards = (
                filteredNearByPros.map((item, index) => {
                    return (
                        <div style={{ width: '100%' }}>
                           <ProfessionalCard_Mobile state={state} serviceCount={item.length} serviceName={item[0].SERVICE_NAME} servicePriceCurrency={item[0].SERVICE_CURRENCY === "Rupees" ? '\u20B9' : "$"} servicePrice={item[0].service_offer_price} fromSearch={paramsLocation.state ? (paramsLocation.state.fromSearch ? paramsLocation.state.fromSearch : searchSamePage) : null} onPressCard={() => onPressProCard(item)} wishlisted={checked['checked_' + item[0].QF_MERCHANT_PERSONNEL_PRID] ? true : false} WishlistPress={() => WishlistPressHandler(item[0])} proData={item[0]} />
                        </div>
                    )
                })
            )
        }
        else{
            proCards = (
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',padding:15 }}>
                    <text className='no_pro_style_mobile'>Currently there are no Professionals for the selected filters. Please change the filters and try again.</text>   
                </div>
        )
        }
    }
    else {
        if (nearByPros.length == 0 && proLoading  ) {
            proCards = (
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',padding:15 }}>
                        <text className='no_pro_style_mobile'>No Professionals found</text>   
                    </div>
            )

        }
    }
    const onPressFilters = () => {
        setFiltersModal(true)
    }
    const toggleExpandedDate = () => {
        setDateCollapsed(!dateCollapsed);
    };
    const toggleExpandedTime = () => {
        setTimeCollapsed(!timeCollapsed);
    }
    const toggleExpandedPrice = () => {
        setPriceCollapsed(!priceCollapsed);
    };
    const toggleExpandedDistance = () => {
        setDistanceCollapsed(!distanceCollapsed);
    };
    const handleDatePicked = (date) => {
        // console.log('date value in date picker', date)
        let day = "", month = "", year = "";
        day = date.getDate(); month = date.getMonth(); year = date.getFullYear();
        // hideDateTimePicker()
        setFromDateTime(fromDateTime.set({ 'year': year, 'month': month, 'date': day }))
        //// setting from and to date in global state
        // dispatch({type:'SET_FROM_DATE_TIME',fromDateTime:fromDateTime.set({ 'year': year, 'month': month, 'date': day })})  
        let fromDateTime1=fromDateTime.set({ 'year': year, 'month': month, 'date': day })
        storeDateAndTime(fromDateTime1,toDateTime,dispatch)          
        // setIsCancel(isCancel ? !isCancel : isCancel)
    };
    const handleDatePicked1 = (date) => {
        console.log("date in hp1", fromDateTime);
        // hideDateTimePicker1();
        let day = "", month = "", year = "";
        day = date.getDate(); month = date.getMonth(); year = date.getFullYear();
        setToDateTime(toDateTime.set({ 'year': year, 'month': month, 'date': day }))
        //// setting to date in global state
        // dispatch({type:'SET_TO_DATE_TIME',toDateTime:toDateTime.set({ 'year': year, 'month': month, 'date': day })})
        let toDateTime1=toDateTime.set({ 'year': year, 'month': month, 'date': day })
        storeDateAndTime(fromDateTime,toDateTime1,dispatch)
    };
    const handleDatePicked2 = (time) => {
        // hideDateTimePicker2();
        console.log('time value', time)
        let hour = "", min = "";
        if (time !== null) {
            // time.split(":");
            let hours = time.split(":");
            console.log(hours, 'hours', hours[0],)

            hour = hours[0] ? hours[0] : '00'
            min = hours[1] ? hours[1] : '00'
        }
        setSelectedBTimes((prevState) => {
            return {
                ...prevState,
                fromTime: moment().set({ 'hour': hour, 'minute': min })
            }
        })
        setFromHours(hour)
        
        // setFromDateTime(fromDateTime.set({ 'hour': hour, 'minute': min }))
        //// setting from and to date in global state
        // dispatch({type:'SET_FROM_DATE_TIME',fromDateTime:fromDateTime.set({ 'hour': hour, 'minute': min })})
        let fromDateTime1=fromDateTime.set({ 'hour': hour, 'minute': min })
        storeDateAndTime(fromDateTime1,toDateTime,dispatch)
        //  setIsCancel2(isCancel3 ? !isCancel3 : isCancel3
    };
    // const hideDateTimePicker3 = () => setIsDateTimePickerVisible3(false);
    const handleDatePicked3 = (time) => {
        // hideDateTimePicker3();
        // let hour = "", min = "";
        // hour = time.getHours(); min = time.getMinutes()
        // setToDateTime(toDateTime.set({ 'hour': hour, 'minute': min }))
        let hour = "", min = "";

        if (time !== null) {
            // time.split(":");
            let hours = time.split(":");
            hour = hours[0] ? hours[0] : '00'
            min = hours[1] ? hours[1] : '00'
        }
        setSelectedBTimes((prevState) => {
            return {
                ...prevState,
                toTime: moment().set({ 'hour': hour, 'minute': min })
            }

        })
        setToHours(hour)
        //// setting to date in global state
        // dispatch({type:'SET_TO_DATE_TIME',toDateTime:toDateTime.set({ 'hour': hour, 'minute': min })})
        let toDateTime1=toDateTime.set({ 'hour': hour, 'minute': min })
        storeDateAndTime(fromDateTime,toDateTime1,dispatch)
    }
    const onChangeSliderValues = (values) => {
        setPriceMin(values[0])
        setPriceMax(values[1])
    }
    const onChangeDistanceSliderValues = (values) => {
        setDistanceMin(values[0])
        setDistanceMax(values[1])
    }
    const onPressRatings = () => {
        // console.log(sortRatings)
        if (!sortRatings) {
            setSortRatings(true)
            setSortDiscount(false)
            // Sorting by ratings - high to low
            let newOffers=DeepClone(filteredNearByPros)
            newOffers.sort((a, b) => {
                return (b[0].MERCHANT_PERSONNEL_RATING ? b[0].MERCHANT_PERSONNEL_RATING : 0) - (a[0].MERCHANT_PERSONNEL_RATING ? a[0].MERCHANT_PERSONNEL_RATING : 0)
            });
            setFilteredNearByPros(newOffers)
        }
        else {
            setSortRatings(false)
            setSortDiscount(false)
        }
    }

    const onPressDiscounts = () => {
        if (!sortDiscount) {
            setSortRatings(false)
            setSortDiscount(true)
            // Sorting by Discounts - high to low
            let newOffers=DeepClone(filteredNearByPros)
            // console.log('services', newOffers)
            newOffers.map((item, index) => {
                item.sort((a, b) => {
                    return b.service_discount - a.service_discount
                })
            })
            newOffers.sort((a, b) => {
                return b[0].service_discount - a[0].service_discount
            });
            setFilteredNearByPros(newOffers)
        }
        else {
            setSortRatings(false)
            setSortDiscount(false)
        }
    }
    const onCatSelection = (cat_item) => {
        let offers = [], newOffers1 = [], newOffers2 = [], newOffers3 = [], newOffers4 = [], matchesArr = [];
        console.log('cat prid', cat_item.QF_SERVICE_CATEGORY_PRID, cat_item.QF_SERVICE_CATEGORY_PRID != 0)
        setCatModal(false)
        setSearchSamePage(false)
        paramsLocation.state.fromSearch= false
        //// setting category selection
        setCatPrid(cat_item.QF_SERVICE_CATEGORY_PRID)
        setSelectedCatPrid(cat_item.QF_SERVICE_CATEGORY_PRID)
        setCategoryName(cat_item.category_name)
        setCategoryImage(cat_item.category_image)
        // if(paramsLocation.state){
            if(cat_item.category_name == 'Beauty' ){
                setBannerImage('https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684328633938-lg.png')
            } 
            else if(cat_item.category_name == 'Hair Care'){
                setBannerImage('https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684328680186-lg.png' )
            }
            else if(cat_item.category_name == 'Nail Care' ){
                setBannerImage( 'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684328652706-lg.png')
            }
            else if(cat_item.category_name == 'Auto Care'){
                setBannerImage( 'https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684328607990-lg.png')
            }
            else{
                setBannerImage('https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684328607990-lg.png')
            }
        // }

        let subcatToState = {}, filteredSub = [];
        if (cat_item.QF_SERVICE_CATEGORY_PRID != 0) {

            if (subCategories.length !== 0) {
                //// setting related subcategories  to selected category
                filteredSub = subCategories.filter((item) => {
                    return item.QF_SERVICE_CATEGORY_PRID === cat_item.QF_SERVICE_CATEGORY_PRID
                })
                setFilteredSubCats(filteredSub)

                //// setting filtered subcategories of changed category as true for selecting all in frontend
                subCategories.map((subcatItem) => {
                    // if (route.params.fromExplore) 
                    // {
                    //     setSubCatPrid(route.params.subcatItem.qf_service_sub_category_prid)
                    //     if (subcatItem.qf_service_sub_category_prid === route.params.subcatItem.qf_service_sub_category_prid) {
                    //         subcatToState["subcheck_" + subcatItem.qf_service_sub_category_prid] = true;
                    //     }
                    //     else {
                    //         subcatToState["subcheck_" + subcatItem.qf_service_sub_category_prid] = false;
                    //     }
                    // }
                    // else
                    // {
                    subcatToState["subcheck_" + subcatItem.qf_service_sub_category_prid] = true;
                    // }

                })
                setSubcatCheck(subcatToState)
            }

        }
        else {
            if (subCategories.length !== 0) {
                filteredSub = subCategories.filter((item) => {
                    return item
                })
                setFilteredSubCats(filteredSub)

                //// setting filtered subcategories of changed category as true for selecting all in frontend
                subCategories.map((subcatItem) => {
                    // if (route.params.fromExplore) 
                    // {
                    //     setSubCatPrid(route.params.subcatItem.qf_service_sub_category_prid)
                    //     if (subcatItem.qf_service_sub_category_prid === route.params.subcatItem.qf_service_sub_category_prid) {
                    //         subcatToState["subcheck_" + subcatItem.qf_service_sub_category_prid] = true;
                    //     }
                    //     else {
                    //         subcatToState["subcheck_" + subcatItem.qf_service_sub_category_prid] = false;
                    //     }
                    // }
                    // else
                    // {
                    subcatToState["subcheck_" + subcatItem.qf_service_sub_category_prid] = true;
                    // }

                })
                setSubcatCheck(subcatToState)
            }
        }

        //// Filtering out all the category specific offers in all pros
        offers = DeepClone(nearByPros)
        offers.map(item1 => {
            newOffers1 = item1.filter(item2 => {
                if (cat_item.QF_SERVICE_CATEGORY_PRID === 0) {
                    return item2
                }
                else {
                    return item2.QF_SERVICE_CATEGORY_PRID === cat_item.QF_SERVICE_CATEGORY_PRID;
                }

            })
            if (newOffers1.length !== 0) {
                newOffers2.push(newOffers1);
            }
        });
        offers = []
        offers = DeepClone(newOffers2)

        //// Filtering out all the selected subcategories, price and distance (if already selected previously or default filter values)
        console.log()
        offers.map((item1) => {
            newOffers3 = item1.filter(item2 => {
                return (subcatToState["subcheck_" + item2.QF_SERVICE_SUB_CATEGORY_PRID] === true)
                &&
                ((priceMin <= item2.service_offer_price) && (item2.service_offer_price <= priceMax))
                &&
                ((distanceMin <= (merchantTimezoneL==="Asia/Kolkata" ? curr(item2.distance).divide(1000).value : curr(item2.distance).divide(1609.344).value)) && ((merchantTimezoneL==="Asia/Kolkata" ? curr(item2.distance).divide(1000).value : curr(item2.distance).divide(1609.344).value) <= distanceMax))

            })
            if (newOffers3.length !== 0) {
                newOffers4.push(newOffers3);
            }
        })

        if (sortRatings) {
            //// Sorting by ratings - high to low
            newOffers4.sort((a, b) => {
                return (b[0].MERCHANT_PERSONNEL_RATING ? b[0].MERCHANT_PERSONNEL_RATING : 0) - (a[0].MERCHANT_PERSONNEL_RATING ? a[0].MERCHANT_PERSONNEL_RATING : 0)
            });
        }

        if (sortDiscount) {
            //// Sorting by Discount - high to low
            newOffers4.map((item, index) => {
                item.sort((a, b) => {
                    return b.service_discount - a.service_discount
                })
            })
            newOffers4.sort((a, b) => {
                return b[0].service_discount - a[0].service_discount
            });
        }

        // console.log('newoffers4 in catSelection', newOffers4)
        setFilteredNearByPros(newOffers4)
        // setCategoryName(cat_item.category_name)
        // setCategoryImage(cat_item.category_image)
    }
    const onPressReset = (fromClick) => {
        let prevFilters = {};
        console.log("merchantTimeZone",merchantTimeZone)
        // this.state.subcats.map((item, index) => {
        //     subcatCheck["subcheck_" + item.qf_service_sub_category_prid] = true;
        // })

        // if (fromClick) 
        // {
        //     prevFilters.distanceMin = distanceMin;
        //     prevFilters.distanceMax = distanceMax;
        //     // prevFilters.subcatCheck = this.state.subcatCheck;
        //     prevFilters.ratingsMin = ratingsMin;
        //     prevFilters.ratingsMax = ratingsMax;
        // }

        setDistanceMin(0)
        setDistanceMax(state.address.country == 'India' ? 20 : 25)

        setPriceMin(0)
        setPriceMax(state.address.country=='India'?15000:5000)

        setFromDateTime(moment().tz(merchantTimeZone).set({ 'hour': 0, 'minute': 0 }))
        setToDateTime(moment().tz(merchantTimeZone).set({ 'hour': 23, 'minute': 59 }).add(14, "days"))

        //// setting from and to date in global state
        let fromDateTime=moment().tz(merchantTimeZone).set({ 'hour': 0, 'minute': 0 })
        let toDateTime=moment().tz(merchantTimeZone).set({ 'hour': 23, 'minute': 59 }).add(14, "days")
        storeDateAndTime(fromDateTime,toDateTime,dispatch)
        // dispatch({type:'SET_FROM_DATE_TIME',fromDateTime:moment().tz(merchantTimeZone).set({ 'hour': 0, 'minute': 0 })})
        // dispatch({type:'SET_TO_DATE_TIME',toDateTime:moment().tz(merchantTimeZone).set({ 'hour': 23, 'minute': 59 }).add(14, "days")})
        setSelectedBTimes((prevState)=>{
            return {...prevState,
                fromTime: moment().set({ 'hour': 0, 'minute': 0 }),
                toTime: moment().set({ 'hour': 23, 'minute': 59 })}
        })
        
        setOnClickReset(fromClick ? true : false)
        // setPrevFilters((prevState) => {
        //     return {
        //         ...prevState,
        //         prevFilters: prevState.onClickReset ? prevState.prevFilters : prevFilters,
        //     }
        // })
        // prevFilters:prevState.prevFilters
    }
    
    const getLatestCalAvailabilityOfPros = (checkAndFetchDistance, distanceBody) => {
        return new Promise(async (resolve, reject) => {
            let offers1 = DeepClone(nearByPros), backToArr1 = [], backToArr2 = [], filterArr1 = [], filterArr2 = [];
            let cats = DeepClone(state.categories), subcats = DeepClone(state.subcategories)
            offers1.map((item1) => {
                backToArr1 = item1.filter(item2 => {

                    return ((distanceMin <= (item2.SERVICE_CURRENCY === 'Rupees' ? curr(item2.distance).divide(1000).value : curr(item2.distance).divide(1609.344).value)) && ((item2.SERVICE_CURRENCY === 'Rupees' ? curr(item2.distance).divide(1000).value : curr(item2.distance).divide(1609.344).value) <= distanceMax))
                })
                if (backToArr1.length !== 0) {
                    backToArr2.push(backToArr1[0].QF_MERCHANT_PERSONNEL_PRID);
                }
            });

            if (backToArr2.length !== 0) {

                let reqToSend = {
                    fromDate: fromDateTime.format("YYYY-MM-DD HH:mm:ss"),
                    toDate: toDateTime.format("YYYY-MM-DD HH:mm:ss"),
                    mArray: backToArr2,
                    checkAndFetchDistance,
                    ...distanceBody
                }
                console.log("diffDaysFront",reqToSend)

                let { fetchedData } = await customFetchData('POST', reqToSend, '/website/dateandtime/filters/sv2', state, dispatch)
                console.log("from get cal pro availability", fetchedData)
                if (fetchedData.data) 
                {
                    let merchTrueObj = fetchedData.merchTrueObj
                    console.log("offers1 before", offers1.length, fetchedData.newServices.length)
                    if (fetchedData.newServices.length !== 0) {
                        fetchedData.newServices.forEach(outerItem => {
                            offers1.push(outerItem)
                        })

                        fetchedData.newCatsFinal.forEach(catItemNew => {
                            if (!cats.some(catItem => catItem.category_name == catItemNew.category_name)) {
                                cats.push(catItemNew)
                            }
                        })
                        console.log("new cats", cats)
                        fetchedData.newSubCatsFinal.forEach(subcatItemNew => {
                            if (!cats.some(subcatItem => subcatItem.service_sub_category_name == subcatItemNew.service_sub_category_name)) {
                                subcats.push(subcatItemNew)
                            }
                        })
                        console.log("new subcats", subcats)

                        // fetchedData.newSubCatsFinal
                        // offers1.map((item, index) => {
                        //     item.sort((a, b) => {
                        //         return moment(b.SERVICE_UPDATED_ON_DATE).diff(moment(a.SERVICE_UPDATED_ON_DATE))
                        //     })
                        // })
                        // offers1.sort((a, b) => {
                        //     return moment(b[0].SERVICE_UPDATED_ON_DATE).diff(moment(a[0].SERVICE_UPDATED_ON_DATE))
                        // });
                        // dispatch({ type: 'SET_SERVICES', services: offers1 })
                        // dispatch({ type: 'SET_CATEGORIES', categories: cats })
                        // dispatch({ type: 'SET_SUBCATEGORIES', subcategories: subcats })
                    }

                    console.log("offers1 before", offers1.length)
                    for (let prop in merchTrueObj) 
                    {
                        offers1.map(outerItem => {
                            filterArr1 = outerItem.filter(innerItem => {
                                return innerItem.QF_MERCHANT_PERSONNEL_PRID == prop && innerItem.SERVICE_DURATION_IN_MINS <= (merchTrueObj[prop] === 0 ? 480 : merchTrueObj[prop])
                            })
                            if (filterArr1.length !== 0) {
                                filterArr2.push(filterArr1)
                            }
                        })
                    }
                    console.log("filterArr2", filterArr2)
                    resolve({ get: true, offers: filterArr2 })
                }
                else {
                    if (fetchedData.dbError) {
                        // ToastAndroid.show("Internal Error. Please try again.", ToastAndroid.LONG);
                    }
                    else if (fetchedData.alreadyIgnored) {
                        // ToastAndroid.show("Service is already ignored.", ToastAndroid.LONG);
                    }
                    else {
                        // ToastAndroid.show("Internal Error. Please try again.", ToastAndroid.LONG);
                    }
                    resolve({ get: false, offers: [] })
                }
            }
            else {
                resolve({ get: false, offers: [] })
            }
        })
    }

// console.log('params',paramsLocation.state)
    // Apply filter function
    const onApplyFilters = async(buttonClicked, fromCross) => {
        setProLoading(true)
        let offers = [], newOffers1 = [], newOffers2 = [], newOffers3 = [], newOffers4 = [], prevFilters = {}, matchesArr = [],checkAndFetchDistance = false, distanceBody = {},merLocFront=[];
        dispatch({ type: 'SET_DISTANCE_MIN', distanceMin })
        dispatch({ type: 'SET_DISTANCE_MAX', distanceMax })
        console.log("call backend check distance", state.distanceCheckMax, distanceMax, state)

        if (state.distanceCheckMax < distanceMax) 
        {

            nearByPros.forEach(item=>{
                merLocFront.push(item[0].QF_MERCHANT_LOCATION_PRID)
            })

            console.log("call backend")
            dispatch({ type: 'SET_DISTANCE_CHECK_MAX', distanceCheckMax: distanceMax })
            distanceBody = {
                curr_lat: state.address.latitude,
                curr_long: state.address.longitude,
                curr_city: state.address.city,
                stateOrProvince: state.address.stateOrProvince,
                countryName: state.address.country,
                distanceMax,
                merLocFront,
                consumerId:state.user.id
            }
            console.log("state", state)
            checkAndFetchDistance = true
            // let {fetchedData} = await useFetchData('POST', body, '/consumer/test/dservicesBasedOnDistance', state, dispatch)
            // console.log("fetchedData dist",fetchedData)
        }


        if (buttonClicked) 
        {
            setApplyButtonLoading(true)
            ///// getting all the Professional where the distance and ratings filters are getting satisfied
            let calFilteredOffers = await getLatestCalAvailabilityOfPros(checkAndFetchDistance, distanceBody)
            console.log("calFilteredOffers", calFilteredOffers)
            if (calFilteredOffers.get) 
            {
                offers = DeepClone(calFilteredOffers.offers)
                setCalFilteredOffers(offers)
            }
            else 
            {
                offers = DeepClone(nearByPros)
            }

        }
        else 
        {
            offers = DeepClone(nearByPros)
        }
        // console.log("offers before map on apply filters",offers)
        if(catPrid!=0){
            offers.map(item1 => {
                newOffers1 = item1.filter(item2 => {
                    return item2.QF_SERVICE_CATEGORY_PRID === catPrid;
                })
                if (newOffers1.length !== 0) {
                    newOffers2.push(newOffers1);
                }
            });
        }
        else{
            offers.map(item1 => {
                newOffers1 = item1.filter(item2 => {
                    return item2
                })
                if (newOffers1.length !== 0) {
                    newOffers2.push(newOffers1);
                }
            });
        }
        offers=[]
        offers = DeepClone(newOffers2)

        // console.log("offers before map on apply filters2",offers)

        offers.map((item1) => {
            newOffers3 = item1.filter(item2 => {
                // console.log("on press apply",fromDateTime.get("minutes"),"  ",moment(item2.MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(merchantTimezone).get("minutes"),"  ",toDateTime.get("minutes")," ",moment(item2.MERCHANT_OFFER_TO_DATE_TIME+"Z").tz(merchantTimezone).get("minutes")," ",item2.QF_MERCHANT_SERVICE_PRID);
                return (subcatCheck["subcheck_" + item2.QF_SERVICE_SUB_CATEGORY_PRID] === true)
                    &&
                    ((priceMin <= item2.service_offer_price) && (item2.service_offer_price <= priceMax))
                    &&
                    ((distanceMin <= (item2.SERVICE_CURRENCY==='Rupees' ? curr(item2.distance).divide(1000).value : curr(item2.distance).divide(1609.344).value)) && ((item2.SERVICE_CURRENCY==='Rupees' ? curr(item2.distance).divide(1000).value : curr(item2.distance).divide(1609.344).value) <= distanceMax))

            })
            if (newOffers3.length !== 0) {
                newOffers4.push(newOffers3);
            }
        })
        // console.log("offers before map on apply filters3",newOffers4)

        // // console.log('newoffers3',newOffers3)
        // newOffers3 = newOffers3.filter(item2 => {
        //         return item2.QF_SERVICE_CATEGORY_PRID === catPrid
        // })
        // if (newOffers3.length !== 0) {
        //     newOffers3 = Object.values(newOffers3.reduce((acc, cur) => Object.assign(acc, { [cur.QF_MERCHANT_PERSONNEL_PRID]: cur }), {}))
        // }
        // if (newOffers3.length !== 0) {
        //     newOffers4.push(newOffers3);
        //     // console.log('new offers3',newOffers3)
        // }
        // // })
        if (!fromCross) 
        {
            if (searchClickedValue && clickOnOfferSearch) 
            {
                if (newOffers4.length !== 0) 
                {
                    newOffers4.map((outerItem) => {
                        outerItem.map((insideItem) => {
                            if (insideItem.MERCHANT_NAME === searchClickedValue) {
                                matchesArr.push([insideItem])
                            }
                        })
                    })
                }
                newOffers4 = DeepClone(matchesArr)
            }
        }
        // console.log("offers before map on apply filters4",newOffers4)
        
        if (sortRatings) 
        {
            //// Sorting by ratings - high to low
            newOffers4.sort((a, b) => {
                return (b[0].MERCHANT_PERSONNEL_RATING ? b[0].MERCHANT_PERSONNEL_RATING : 0) - (a[0].MERCHANT_PERSONNEL_RATING ? a[0].MERCHANT_PERSONNEL_RATING : 0)
            });
        }

        if (sortDiscount) 
        {
            //// Sorting by Discount - high to low
            newOffers4.map((item, index) => {
                item.sort((a, b) => {
                    return b.service_discount - a.service_discount
                })
            })
            newOffers4.sort((a, b) => {
                return b[0].service_discount - a[0].service_discount
            });
        }

        // console.log("offers before map on apply filters5",newOffers4)
        // prevFilters.distanceMin = distanceMin;
        // prevFilters.distanceMax = distanceMax;
        // prevFilters.ratingsMin = ratingsMin;
        // prevFilters.ratingsMax = ratingsMax;
        // if(newOffers4.length == 0 ){
        //     setProLoading(false)
        // }
        setFilteredNearByPros(newOffers4.length !== 0 ? newOffers4 : [])
        setFiltersModal(false)
        setApplyButtonLoading(false)
        setProLoading(false)
        setOnApplyClick(true)
        setPrevFilters(prevFilters)
        setOnClickReset(false)
        setOnApplyClick2(buttonClicked ? true : false)
    }
    const onClickCatName = ()=>{
        console.log("Inside Onclick")
        setCatModal(true)
    }
    let catModalView=(
        <div style={{position:'absolute',left:'auto',zIndex:10}}>
            <Card className='catCard_mobile' style={{paddingTop:10,paddingLeft:10,paddingRight:10}}>
                
                    <div>
                        {/* <div style={{ display: 'flex', flexDirection: 'row',cursor:'pointer', justifyContent:'flex-end'}}>
                                <div className='filters_body_style_mobile' onClick={() => setCatModal(false)}>
                                    <img src={crossIcon} style={{ width: 12, height: 12, }} />
                                </div>
                        </div> */}
                    {
                        categories.map((item) => {
                            return (
                                <div style={{ display: 'flex',backgroundColor: selectedCatPrid === item.QF_SERVICE_CATEGORY_PRID ? 'rgba(216,197,252,0.6)' : '', flexDirection: 'row',marginTop:10,marginBottom:10,padding:10,borderRadius:10 ,alignItems:'center'}} onClick={()=>onCatSelection(item)}>
                                    <div className='catmain_sv2_mobile' >
                                        <img src={item.category_image} className='cat_modal_img_mobile' />
                                    </div>
                                    <text className='catName_modal_style'>{item.category_name}</text>
                                </div>

                            )
                        })

                    }
                    </div>
                
                
            </Card>
        </div>
    )
    // console.log('catmodal',catModal)
    return (
        <div style={{backgroundColor:'#fff'}}>
            <div className='proUnderCat_mobile_sv2'>
                <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                    ride="carousel"
                    interval={2000}
                >
                    {MerchantItems.length > 1 ? <CarouselIndicators className="carousel-indicators_Landing" items={MerchantItems} activeIndex={activeIndex} onClickHandler={goToIndex} /> : null}
                    {MerchantSlides}
                </Carousel>
            </div>
            <div className='subdiv_mobile_sv2'>
                <div onClick={onClickCatName} style={{ flexDirection: 'row', display: 'flex', cursor: 'pointer', justifyContent: 'flex-start', position: 'relative' }}>

                    <div className='catmain_sv2_mobile' >
                        <img src={categoryImage} className='cat_img_style_mobile' />
                    </div>
                    <text className='catname_mobile_sv2'>{categoryName}</text>
                    <img src={DropdownIcon} className='dropdown_mobile_sv2' />

                </div>
                {catModal ? catModalView : null}
                <div style={{ marginTop: 20, position: 'relative' }}>
                    <SearchBar
                        type='text'
                        value={searchTextOffer}
                        placeholder="Search any Service / Professional / Category"
                        onChange={searchBoxOfferhandler}
                        // returnType="done"
                        onClickCross={clearSearchResults}
                        searchTextService={searchTextOffer}
                        onSubmitEditing={clearSearchResults}
                    />
                    {showSearchDropDown ? <Card style={{ position: 'absolute', height: searchDropdownArray.length > 0 ? (searchDropdownArray.length ===1 || searchDropdownArray.length ===2 ? 'auto' : 220) : 150, top: 55, zIndex: 2000,left:-30,right:-30 , overflow: 'auto' }}>
                        {searchOfferDropDownItem}
                    </Card> : null}
                </div>
                {/* <div style={{display:'flex',flexDirection:'row',marginBottom:18,marginTop:20}}>
                    <div className='filterBox-sv2_mobile'>
                        <img src={ratingsIcon} className='img_sv2'/>
                        <text className='filtersText_sv2_mobile'>Filters</text>
                        <img src={DropdownIcon} className='img_sv2'/>
                    </div>
                    <div className='ratingsBox-sv2_mobile'>
                        <img src={ratingsIcon} className='img_sv2'/>
                        <text className='filtersText_sv2_mobile'>Ratings</text>
                    </div>
                    <div className='discountsBox-sv2_mobile'>
                        <img src={ratingsIcon} className='img_sv2'/>
                        <text className='filtersText_sv2_mobile'>Discounts</text>
                    </div>
                </div> */}
                {/* Filters  */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 18, marginTop: 20 }}>
                    <div className='filterBox-sv2_mobile' style={{ borderColor: filtersModal ? '#fff' : '#dedede', backgroundColor: filtersModal ? 'rgb(166,121,255)' : '#fff', cursor: 'pointer' }} onClick={() => onPressFilters()}>
                        {filtersModal ? <img src={filterIcon_white} className='img_sv2' /> : <img src={filtersIcon} className='img_sv2' /> }
                        <text className='filtersText_sv2' style={{ color: filtersModal ? '#fff' : '#000' }}>Filters</text>
                        {filtersModal ? <img src={dropDown_filter} className='img_sv2' /> : <img src={DropdownIcon} className='img_sv2' />}
                    </div>
                    {/* Ratings filter */}
                    <div className='ratingsBox-sv2_mobile' style={{ borderColor: sortRatings ? '#fff' : '#dedede', backgroundColor: sortRatings ? 'rgb(166,121,255)' : '#fff', cursor: 'pointer' }} onClick={() => onPressRatings()}>
                        {sortRatings ? <img src={ratingsIcon_white} className='img_sv2' /> : <img src={ratingsIcon} className='img_sv2' /> }
                        <text className='filtersText_sv2' style={{ color: sortRatings ? '#fff' : '#000000' }}>Ratings</text>
                    </div>
                    {/* Discount filter */}
                    <div className='discountsBox-sv2_mobile' style={{ borderColor: sortDiscount ? '#fff' : '#dedede', backgroundColor: sortDiscount ? 'rgb(166,121,255)' : '#fff', cursor: 'pointer' }} onClick={() => onPressDiscounts()}>
                        {sortDiscount ? <img src={discountIcon_white} className='img_sv2' /> : <img src={discountIcon} className='img_sv2' /> }
                        <text className='filtersText_sv2' style={{ color: sortDiscount ? '#fff' : '#000000' }}>Discounts</text>
                    </div>
                </div>
                {
                    subLoading ?
                        <div style={{ height: 120, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <ClipLoader widthUnit={"%"} size={28} loadings={subLoading} color="#8c52ff" />
                        </div>
                :
                <div className='sub-row-sv2_mobile'>
                    {subItems}
                </div>
                 }
                {
                     proLoading ?
                        <div style={{ height: 200, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <ClipLoader widthUnit={"%"} size={40} loadings={proLoading} color="#8c52ff" />
                        </div>
                 :
                    <div style={{ display: 'flex', flexDirection:'column',justifyContent: 'center', alignItems: 'center' }}> 
                        {proCards}
                    </div>
                }
            </div>
            {/* Filters Modal */}
            <Modal isOpen={filtersModal} toggle={() => setFiltersModal(false)} centered>
                    <ModalBody>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <div className='filters_body_style' onClick={() => setFiltersModal(false)}>
                                <img src={crossIcon} style={{ width: 18, height: 18, }} />
                            </div>
                        </div>
                        <text className='filtersText_sv2_text'>Filters</text>
                        <hr />
                        {/* Date Filter */}
                        <div onClick={() => toggleExpandedDate()} style={header}>
                            <text style={dateStyle}>Date</text>
                            <img src={dateCollapsed ? FilterArrowDown : FilterArrowUp} style={{ width: 13, height: 13 }} />
                        </div>
                        <Collapse isOpen={!dateCollapsed}>
                            <div style={dateViewStyle}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                                    {/* <TouchableOpacity onPress={() => setIsDateTimePickerVisible(!isDateTimePickerVisible)}> */}
                                    <div style={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' }}>
                                        <img src={calendarIcon} style={{ width: 15, height: 15, marginRight: 8 }} />
                                        {/* <text>{fromDateTime ? fromDateTime.format("DD MMM YYYY") : fromDateTime}</text> */}

                                        <DatePicker
                                        value={fromDateTime}
                                        calendarIcon={null}
                                        clearIcon={null}
                                        className='react-date-picker__wrapper'
                                        isOpen={isDateTimePickerVisible}
                                        onChange={handleDatePicked}
                                        minDate={mindate ? new Date(mindate.format("YYYY-MM-DD")) : new Date()}
                                    // onConfirm={handleDatePicked}
                                    // onCancel={cancelPicker}
                                    // onHide={() => {
                                    //     if (isCancel === false) {
                                    //         setIsDateTimePickerVisible1(true)
                                    //     }
                                    // }
                                    // }
                                    />

                                    </div>
                                    {/* </TouchableOpacity> */}
                                    <div style={{ marginTop: 12, width: 15.6, height: 1, backgroundColor: "#a679ff" }}></div>
                                    {/* <TouchableOpacity onPress={() => setIsDateTimePickerVisible1(!isDateTimePickerVisible1)}> */}
                                    <div style={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' }}>
                                        <img src={calendarIcon} style={{ width: 15, height: 15, marginRight: 8 }} />
                                        {/* <Text>{toDateTime ? toDateTime.format("DD MMM YYYY") : toDateTime}</Text> */}
                                        <DatePicker
                                        value={toDateTime}
                                        date={toDateTime ? new Date(toDateTime.format("YYYY-MM-DD")) : new Date()}
                                        minDate={fromDateTime ? new Date(fromDateTime.format("YYYY-MM-DD")) : new Date()}
                                        // locale="en_US"
                                        calendarIcon={null}
                                        clearIcon={null}
                                        isOpen={isDateTimePickerVisible1}
                                        className='react-date-picker__wrapper'
                                        isVisible={isDateTimePickerVisible1}
                                        onChange={handleDatePicked1}
                                    // onCancel={hideDateTimePicker1}
                                    />
                                    </div>
                                    {/* </TouchableOpacity>  */}
                                </div>
                            </div>
                        </Collapse>
                        {/* Time Filter */}
                        <div onClick={() => toggleExpandedTime()} style={header}>
                            <text style={dateStyle}>Time</text>
                            <img src={timeCollapsed ? FilterArrowDown : FilterArrowUp} style={{ width: 13, height: 13 }} />
                        </div>
                        <Collapse isOpen={!timeCollapsed} >
                            <div style={timeViewStyle}>
                                <div style={{ flexDirection: 'row', justifyContent: "space-between", display: 'flex', }}>
                                    {/* <TouchableOpacity onPress={() => setIsDateTimePickerVisible2(!isDateTimePickerVisible2)}> */}
                                    <div style={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' }}>
                                        <img src={timeIcon} style={{ width: 17, height: 17, marginRight: 8 }} />
                                        {/* <text style={{ marginTop: -1 }}>{fromDateTime ? fromDateTime.format("HH:mm") : ""}</text> */}
                                        <TimePicker
                                            format={'HH:mm'}
                                            className='react-time-picker__wrapper'
                                            value={selectedBTimes.fromTime ? new Date(selectedBTimes.fromTime) : null}
                                            onChange={handleDatePicked2}
                                            clockIcon={null}
                                            clearIcon={null}
                                    //      isOpen={isDateTimePickerVisible2}
                                    />
                                    </div>
                                    {/* </TouchableOpacity> */}
                                    <div style={{ marginTop: 12, width: 15.6, height: 1, backgroundColor: "#a679ff" }}></div>
                                    {/* <TouchableOpacity onPress={() => setIsDateTimePickerVisible3(!isDateTimePickerVisible3)}> */}
                                    <div style={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' }}>
                                        <img src={timeIcon} style={{ width: 17, height: 17, marginRight: 8 }} />
                                        {/* <text style={{ marginTop: -1 }}>{toDateTime ? toDateTime.format("HH:mm") : ""}</text> */}
                                        <TimePicker
                                            format={'HH:mm'}
                                            minTime={selectedBTimes.fromTime ? new Date(selectedBTimes.fromTime) : null}
                                            className='react-time-picker__wrapper'
                                            value={selectedBTimes.toTime ? (new Date(selectedBTimes.toTime)) : null}
                                            onChange={handleDatePicked3}
                                            clockIcon={null}
                                            clearIcon={null}
                                        // isOpen={isDateTimePickerVisible3}
                                    />
                                    </div>
                                    {/* </TouchableOpacity> */}
                                </div>
                            </div>
                        </Collapse>
                        {/* price filter */}
                        <div onClick={() => toggleExpandedPrice()} style={header}>
                            <text style={dateStyle}>Price Range</text>
                            <img src={priceCollapsed ? FilterArrowDown : FilterArrowUp} style={{ width: 13, height: 13 }} />
                        </div>
                        <Collapse isOpen={!priceCollapsed}>
                            <div style={{ marginHorizontal: 30, marginTop: 5 }}>

                                {/* <MultiSlider
                                        values={[priceMin, priceMax]}
                                        min={0}
                                        max={merchantTimeZone==="Asia/Kolkata"?15000:5000}
                                        selectedStyle={{ backgroundColor: 'rgb(140,82,255)' }}
                                        unselectedStyle={{ backgroundColor: '#e1e1e1' }}
                                        pressedMarkerStyle={{ backgroundColor: '#563951' }}
                                        markerStyle={{ height: 15, width: 15, borderRadius: 10, backgroundColor: 'rgb(140,82,255)', borderWidth: 0.2, borderColor: 'rgb(140,82,255)' }}
                                        onValuesChange={(values) => onChangeSliderValues(values)}
                                        onValuesChangeFinish={(values) => onChangeSliderValues(values)}
                                        sliderLength={deviceWidth - 60}
                                        step={merchantTimeZone==="Asia/Kolkata"?100:10}
                                    /> */}
                                <Range
                                    step={100}
                                    min={0}
                                    max={state.address.country=='India'?15000:5000}
                                    values={[priceMin, priceMax]}
                                    onChange={(values) => onChangeSliderValues(values)}
                                    onFinalChange={(values) => onChangeSliderValues(values)}
                                    renderTrack={({ props, children }) => (
                                        <div
                                            {...props}
                                            style={{
                                                ...props.style,
                                                height: '2px',
                                                width: '100%',
                                                backgroundColor: '#ccc',
                                                marginTop: '20px',
                                            }}
                                        >
                                            {children}
                                        </div>
                                    )}
                                    renderThumb={({ props }) => (
                                        <div
                                            {...props}
                                            style={{
                                                ...props.style,
                                                height: '20px',
                                                width: '20px',
                                                borderRadius: '10px',
                                                backgroundColor: '#8c52ff',
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                <div style={priceBox}>
                                    <text style={priceTextStyle}>{merchantTimeZone==="Asia/Kolkata" ? '\u20B9' : "$"}{priceMin} </text>
                                </div>
                                <div style={priceBox}>
                                <text style={priceTextStyle}>{merchantTimeZone==="Asia/Kolkata" ? '\u20B9' : "$"}{priceMax} </text>
                                </div>
                            </div>
                        </Collapse>
                        {/* distance filter */}
                        <div onClick={() => toggleExpandedDistance()} style={header}>
                            <text style={dateStyle}>Distance</text>
                            <img src={distanceCollapsed ? FilterArrowDown : FilterArrowUp} style={{ width: 13, height: 13 }} />
                        </div>
                        <Collapse isOpen={!distanceCollapsed}>
                            <div style={{ marginHorizontal: 30, marginTop: 5 }}>
                                {/* <MultiSlider
                                        values={[distanceMin, distanceMax]}
                                        min={0}
                                        max={100}
                                        selectedStyle={{ backgroundColor: 'rgb(140,82,255)' }}
                                        unselectedStyle={{ backgroundColor: '#e1e1e1' }}
                                        pressedMarkerStyle={{ backgroundColor: '#563951' }}
                                        markerStyle={{ height: 15, width: 15, borderRadius: 10, backgroundColor: 'rgb(140,82,255)', borderWidth: 0.2, borderColor: 'rgb(140,82,255)' }}
                                        onValuesChange={(values) => onChangeDistanceSliderValues(values)}
                                        onValuesChangeFinish={(values) => onChangeDistanceSliderValues(values)}
                                        sliderLength={deviceWidth - 60}
                                        step={1}
                                    /> */}
                                <Range
                                    step={1}
                                    values={[distanceMin, distanceMax]}
                                    min={0}
                                    max={state.address.country == 'India' ? 20 : 25}
                                    onChange={(values) => onChangeDistanceSliderValues(values)}
                                    onFinalChange={(values) => onChangeDistanceSliderValues(values)}
                                    renderTrack={({ props, children }) => (
                                        <div
                                            {...props}
                                            style={{
                                                ...props.style,
                                                height: '2px',
                                                width: '100%',
                                                backgroundColor: '#ccc',
                                                marginTop: '20px',
                                            }}
                                        >
                                            {children}
                                        </div>
                                    )}
                                    renderThumb={({ props }) => (
                                        <div
                                            {...props}
                                            style={{
                                                ...props.style,
                                                height: '20px',
                                                width: '20px',
                                                borderRadius: '10px',
                                                backgroundColor: '#8c52ff',
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                <div style={priceBox}>
                                    <text style={priceTextStyle}>{distanceMin}{merchantTimeZone==="Asia/Kolkata"?" km":" mi"}</text>
                                </div>
                                <div style={priceBox}>
                                    <text style={priceTextStyle}>{distanceMax}{merchantTimeZone==="Asia/Kolkata"?" km":" mi"} </text>
                                </div>
                            </div>
                        </Collapse>
                        {/* apply and reset UI */}
                        <div style={{ flexDirection: 'row', justifyContent: 'space-between', display:'flex', marginTop: 20 }}>
                            <div onClick={() => onPressReset(true) } style={resetStyle}>
                                <text style={ResetTextStyle}>Reset</text>
                            </div>
                            {
                                applyButtonLoading
                                    ?
                                    <div style={{  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                        <ClipLoader widthUnit={"%"} size={25} loadings={applyButtonLoading} color="#2C2165" />
                                    </div>
                                    : 
                                    <div style={updateStyle} onClick={() => onApplyFilters(true, false)}  >
                                        <text style={updateTextStyle}>Update</text>
                                    </div>
                            }
                        </div>
                    </ModalBody>
                </Modal> 
        
        </div>
        
        
    )
    }
