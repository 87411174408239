import React, { useContext, useEffect, useState } from "react";
import '../../../CSS/UI Components/ReferAndEarnCard.css';
import ShareAndRefer from '../../../Assets/shareAndEarn_referal_image.png'
import ShareOnMobile from '../../../Assets/share_mobile_referal_image.png'
import InviteFrined from '../../../Assets/invite_friend_earn.png'
import InviteFrinedMobile from '../../../Assets/invite_friend_earn_mobile.png'
import Share_Icon from '../../../Assets/share_icon_refer.png'
import Copied_Icon from '../../../Assets/copy_icon.png'
import Redeem_Icon from '../../../Assets/redeem_icon_refer.png'
import Navigation_Icon from '../../../Assets/icons-navigate-before.png'
import customFetchData from "../customFetchData";
import { globalState } from "../../../GlobalState/globalState";
import { ToastContainer, toast } from "react-toastify";
import SubmitButtonSolid from "./submitButtonSolid";
import SubmitButtonSmall from "./submitButtonSmall";
import { Modal } from "bootstrap";
import { ModalBody } from "reactstrap";
import Close_Cross from "./Close_Cross";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {Helmet} from 'react-helmet';
import RegisstrAndLogin_India from "../../../Screens/RegisterrAndLogin_India";


const useTabletAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 768px)" })
const useSmallDesktopAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 1024px)" })
const useDesktopAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 1440px)" })
const MobileAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 425px)" })

export default function ReferAndEarn(props){

    const [clipMessage, setClipMessage] = useState('')
    const [state, dispatch]=useContext(globalState);
    const [referralCode, setReferralCode]=useState('')
    const [modal, setModal] = useState(false)

    const navigate = useNavigate()

    const isTab=useTabletAndBelowMediaQuery();
    const isMobileView = MobileAndBelowMediaQuery();
    let location=useLocation();

    useEffect(() => {
        if(state.token) {
            fetchReferralData()
        }
    },[state.token])

    const fetchReferralData = async() => {
            // console.log("from new scratch")
            let totalEarned=0;

            let body={
                consumer_prid:state.user.id,
                country: state.country.country
            }

            let {fetch, fetchedData} = await customFetchData('POST', body, '/website/fetchReferralCode/sv2', state, dispatch)
            // console.log(fetchedData)
            
                if(!fetch)
                {
                    toast("Session Expired");
                        // this.props.onLogOut().then(
                        //     ()=>{
                        //         this.props.navigation.navigate('Profile',{});
                        //     }
                        // ).catch(err1=>{
                        //     ToastAndroid.show("Something went wrong. Please try again. ",ToastAndroid.LONG);
                        //     });  
                }
                else{
                    if(fetchedData.data){

                        setReferralCode(fetchedData.result[0].CONSUMER_REF_CODE)

                    } 
                }       
    }

    const copyClipHandler = (code) =>{
        navigator.clipboard.writeText(code)
        setClipMessage('Copied to Clipboard')
        toast('Copied to Clipboard',{ position: toast.POSITION.BOTTOM_CENTER })
        setTimeout (()=>{
            setClipMessage('')
        },1000)
    }

    const onClickRewardHandler=()=>{
        console.log("location in refer", location)
        if(isMobileView){
            navigate('/referandearn/rewards')
        } else {
            console.log("props in refer and earn", props)
            props.onPressReward()
        }
    }

    return (
        <>
            <Helmet>
                <title>Refer & Earn | Save Big with Slash Referrals </title>
                <meta name="description" content="Experience the thrill of earning rewards at Slash. Refer friends to book top-quality services & enjoy exclusive discounts. Start sharing, start saving.Join now" />
            </Helmet>

            <div className="Refer_main_div_style">
                <div className="Refer_inner_div_style">
                    <div className="Refer_inner_image_div1">
                        <img className="Refer_image_1" src={ShareAndRefer}/>
                        <img className="Refer_image_2" src={InviteFrined}/>
                    </div>
                    <div className="Refer_inner_image_div2">
                        <img className="Refer_image_3" src={ShareOnMobile}/>
                    </div>
                </div>

                <div className="Refer_invite_friend_text_div">
                    <text className="Refer_header_text_style">Invite Friends & Businesses</text>
                    <text className="Refer_other_text_style">Copy your code and share it with your friends.</text>
                </div>

                <div style={{marginBottom:28, marginTop:28}}>
                    <div className="Refer_referal_code_text_div">
                        <text className="Your-referral-code">Your referral code</text>
                    </div>
                    <div className="Refer_input_button_style">
                        {state.token ?
                            <div className="Refer_input_code_style">
                                <text className="Refer_referal_code">{referralCode}</text>
                                <div style={{cursor:'pointer'}} onClick={() => copyClipHandler(referralCode)}>
                                    <img src={Copied_Icon}/>
                                </div>
                            </div>

                        :
                            <SubmitButtonSmall Radius={10} buttonColor='#ffffff' buttonTitle="Log in" buttonTextColor='#8c52ff' borderColor='#8c52ff' width='100%' onPress={() => setModal(true)} />
                        }
                    </div>

                        {/* <div style={{display:'flex', flexDirection:'row'}}>
                            <div className="Refer_share_button_style">
                                <img src={Share_Icon} width={22} height={16}/>
                                <text className="Refer_button_text_style">Invite Friend</text>
                            </div>

                            <div className="Refer_share_button_style">
                                <img src={Share_Icon} width={22} height={16}/>
                                <text className="Refer_button_text_style">Invite Professional</text>
                            </div>
                        </div> */}
                </div>

                <hr />

                <div>
                    <div style={{marginTop:27, marginBottom:16}}>
                        <text className="Refer_header_text_style">How it works?</text>
                    </div>

                    <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                        <div className="Refer_ellipse_style">
                            <text className="Your-referral-code" style={{fontSize:12}}>1</text>
                        </div>
                        <text className="Refer_other_text_style">Invite your friends to signup with your unique code. </text>
                    </div>

                    <div className="Refer_veritical_line_style"></div>

                    <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                        <div className="Refer_ellipse_style">
                            <text className="Your-referral-code" style={{fontSize:12}}>2</text>
                        </div>
                        <text className="Refer_other_text_style">You get a scratch card of up to {state.country.country == 'IN' ? '₹1000' : '$50'}</text>
                    </div>

                    <div className="Refer_veritical_line_style"></div>

                    <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                        <div className="Refer_ellipse_style">
                            <text className="Your-referral-code" style={{fontSize:12}}>3</text>
                        </div>
                        <text className="Refer_other_text_style">You make saving!</text>
                    </div>
                </div>

                {/* <div className="Refer_reedem_div_style" onClick={() => onClickRewardHandler()}> */}
                <div className="Refer_reedem_div_style">
                    <div>
                        <img src={Redeem_Icon} />
                        <text className="Refer_redeem_text_style">Rewards</text>
                    </div>

                    <img src={Navigation_Icon} />
                </div>

                {/* Login Modal */}
                { modal && <RegisstrAndLogin_India closeModal={()=>setModal(!modal)} />}

                <ToastContainer />

            </div>
        </>
    )
}