import React,{createContext,useReducer} from "react";
const DeepClone = require("rfdc/default")

const initialState={
    token:null,
    netInfo:{
        isConneted:false,
        isInternetReachable:false,
        type:''
    },
    user:{
        id:null,
        email:null,
        username:null,
        role:null,
        city:null,
        country:null
    },
    utm:{
        utm_campaign:null,
        utm_source:null,
        utm_medium:null,
        utm_content:null,
        utm_term:null
    },
    country:{
        country:null,
        timezone:null,
        latitude:null,
        longitude:null
    },
    address:{
        totalAddress:null,
        addressLine1:null,
        country:null,
        city:null,
        stateOrProvince:null,
        zipcode:null,
        district:null,
        latitude:null,
        longitude:null,
        currentAddress:true,
        searchAddress:false,
        currentLocationDenied:false
    },
    fromDateTime:{},
    toDateTime:{},
    cart:[],
    cartCount:0,
    services:[],
    categories:[],
    subcategories:[],
    addressUpdated:false,
    distanceMin:0,
    distanceMax:10,
    distanceCheckMax:10,
    merLocFront:[]
}

export const globalState=createContext(initialState)

export const Store=({children})=>{

    const [state,dispatch]=useReducer((state,action)=>{
        // console.log("action",action)
        switch(action.type)
        {
            //// Net Info state updates
            case 'netInfo-isConnected': return {...state,netInfo:{...state.netInfo,isConnected:action.payload}}
            case 'netInfo-isInternetReachable': return {...state,netInfo:{...state.netInfo,isInternetReachable:action.payload}}
            case 'netInfo-type': return {...state,netInfo:{...state.netInfo,type:action.payload}}
            case 'netInfo-multiple':return {...state,netInfo:{...action.payload}}

            //// Token state update
            case 'SET_AUTH_TOKEN': return {...state,token:action.token}
            case 'REMOVE_AUTH_TOKEN': return {...state,token:null}

            //// Country and Timezone state update
            case 'COUNTRY_STORE': return {...state,country:{...state.country,country:action.country,timezone:action.timezone,latitude:action.latitude,longitude:action.longitude}}

            //// Credentials state update and remove
            case 'CREDENTIALS_STORE': return {...state,user:{...state.user,id:action.id,email:action.email,username:action.username,role:action.role,city:action.city,country:action.country}}
            case 'CREDENTIALS_REMOVE': return {...state,user:{...state.user,id:null,email:null,username:null,role:null,city:null,country:null}}

            //// UTM store state update
            case 'UTM_STORE': return {...state,utm:{...state.utm,utm_campaign:action.utm_campaign,utm_source:action.utm_source,utm_medium:action.utm_medium,utm_content:action.utm_content,utm_term:action.utm_term}}
            
            //// updation of Address Store
            case 'ADDRESS_STORE': return {...state,address:{...state.address,totalAddress:action.totalAddress,addressLine1:action.addressLine1,country:action.country,city:action.city,stateOrProvince:action.stateOrProvince,zipcode:action.zipcode,district:action.district,latitude:action.latitude,longitude:action.longitude,currentAddress:action.currentAddress,searchAddress:action.searchAddress,currentLocationDenied:action.currentLocationDenied}}

            //// Cart operations
            case 'ADD_TO_CART' : return {...state,cart:DeepClone(action.cart)}
            case 'REMOVE_FROM_CART' : return {...state,cart:DeepClone(action.cart)}
            case 'UPDATE_CART_COUNT' : return {...state,cartCount:action.cartCount}

            /////date time
            case 'SET_FROM_DATE_TIME':return {...state,fromDateTime:action.fromDateTime}
            case 'SET_TO_DATE_TIME':return {...state,toDateTime:action.toDateTime}

            //// setting complete services array for whislist
            case 'SET_SERVICES': return {...state,services:action.services}
            
            //// setting categories
            case 'SET_CATEGORIES': return {...state,categories:action.categories}

            //// setting subcategories
            case 'SET_SUBCATEGORIES': return {...state,subcategories:action.subcategories}
            
             //// address updation checking store
             case 'SET_ADDRESS_UPDATED': return {...state,addressUpdated:action.addressUpdated}

             //// Distance updations
            case 'SET_DISTANCE_MIN': return {...state,distanceMin:action.distanceMin}
            case 'SET_DISTANCE_MAX': return {...state,distanceMax:action.distanceMax}
            case 'SET_DISTANCE_CHECK_MAX': return {...state,distanceCheckMax:action.distanceCheckMax}

            //// Setting merchant location prids
            case 'SET_MER_LOC_PRIDS':return {...state,merLocFront:action.merLocFront}

            default : return state
        }
    },initialState)

    return (
        <globalState.Provider value={[state,dispatch]}>
            {children}
        </globalState.Provider>
    )

}