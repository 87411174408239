import React from 'react'
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import customFetchData from './customFetchData';
import { storeAddress } from '../../GlobalState/globalStateActions';
export default function FetchCurrentLocation(state,dispatch,fromClickCurrent,fromBookings) {
    return new Promise(async(resolve,reject)=>{
        //// Checking for network and fetching and returning the data to requested hook
    // console.log("fetchedData in usefetchdata",method,body,state,dispatch)
    try{
        // fetching and updating netinfo in global state 
        // console.log('state ip details',state.ipDetails)
        let netState= navigator.onLine;
        // console.log("Connection type", netState);
        dispatch({type:'netInfo-multiple',payload:{isConnected:netState}})  
        if(!netState)
        {
            toast.error("Unable to reach server. Please check your connection.");
            resolve({fetch:false,networkConnection:false})
        }
        else
        {
            navigator.geolocation.getCurrentPosition(async(curr_location_succ) =>{
                // console.log('current loc',curr_location_succ)
                // console.log("Latitude is :", curr_location_succ.coords.latitude);
                // console.log("Longitude is :", curr_location_succ.coords.longitude);
                // Geocoder.from(curr_location_succ.coords.latitude, curr_location_succ.coords.longitude)
                const LocationStr = curr_location_succ.coords.latitude + "," + curr_location_succ.coords.longitude;
                let ApiURL = "https://maps.googleapis.com/maps/api/geocode/json?";
                let params = `latlng=${LocationStr}&key=AIzaSyC6sHvN36SBHhSQymZ3d17MwtTeql16VbQ`;
                let finalApiURL = `${ApiURL}${encodeURI(params)}`;
                let getData=await getAddressfromIP(finalApiURL)
                .then((json) => {
                    console.log("JSON in fetch location", json)
                    if (json.status === "OK") {
                        if (json.results.length !== 0) {
                            // console.log('results',json.results)
                            let addressLine1, city, country, zipcode, stateOrProvince, district,currentLocation;
                            let countryCheck = '';
                            currentLocation=json.results[0].formatted_address;
                            json.results[0].address_components.map((item, index) => {
                                if (item.types[0] == "route") {
                                    addressLine1 = item.long_name;
                                    // console.log("address", item.long_name)
                                }
                                else if (item.types[0] == "neighborhood") {
                                    if (addressLine1 == '') {
                                        addressLine1 = item.long_name;
                                        // console.log("address1", item.long_name)
                                    }
                                    else if (!isNaN(Number(addressLine1))) {
                                        addressLine1 = item.long_name;
                                        // console.log("address2", item.long_name)
                                    }
                                    else {
                                        addressLine1 = item.long_name;
                                        // console.log("address3", item.long_name)
                                    }
                                }else if(item.types[0] == "point_of_interest"){
                                    addressLine1 = item.long_name;
                                }
                                else if (item.types[0] == "political") {
                                    if (addressLine1 == '') {
                                        // console.log("address4", item.long_name)
                                        addressLine1 = item.long_name;
                                    }
                                    else {
                                        // console.log("address5", item.long_name)
                                        addressLine1 = item.long_name;
                                    }
                                }
                                else if (item.types[0] == "administrative_area_level_1") {
                                    stateOrProvince = item.long_name
                                    // console.log("state", item.long_name)
                                }
                                else if (item.types[0] == "country") {
                                    countryCheck = item.long_name
                                    country = item.long_name
                                    // console.log("country", item.long_name)
                                }
                                else if (item.types[0] == "postal_code") {
                                    zipcode = item.long_name
                                    // console.log("zipcode", item.long_name)
                                }
                                else if (item.types[0] == "administrative_area_level_3" || item.types[0] == "administrative_area_level_2") {
                                    district = item.long_name
                                    // console.log('enter dis',district)
                                }
                                else {
                                    // console.log("else")
                                }
                            })
                            if (countryCheck.includes('India')) {
                                json.results.map(item1 => {
                                    // console.log("city", item1)
                                    if (item1.types[0] == "locality") {
                                        city = item1.address_components[0].long_name
                                        // console.log("city", item1.long_name)
                                    }
                                })

                            } else {
                                json.results[0].address_components.map((item1, index) => {
                                    if (item1.types[0] == "locality") {
                                        city = item1.long_name
                                        // console.log("city", item1.long_name)
                                    }
                                })
                            }
                            if(fromClickCurrent || fromBookings){
                                console.log('no need to dispatch now')
                            }else{
                                storeAddress(currentLocation,addressLine1,country,city,stateOrProvince,zipcode,district,curr_location_succ.coords.latitude,curr_location_succ.coords.longitude,true,false,false,dispatch)
                            }
                            // dispatch({ type: 'ADDRESS_STORE', totalAddress:currentLocation,addressLine1:addressLine1,country:country,city:city,stateOrProvince:stateOrProvince,zipcode:zipcode,district:district,latitude:curr_location_succ.coords.latitude,longitude: curr_location_succ.coords.longitude,currentAddress:true,searchAddress:false,currentLocationDenied:false})
                            resolve({
                                locationFetch:true,
                                addressLine1:addressLine1,
                                zipcode:zipcode,
                                city:city,
                                country:country,
                                stateOrProvince:stateOrProvince,
                                district:district,
                                currentAddress:true,
                                currentLocation:currentLocation,
                                curr_location_latitude:curr_location_succ.coords.latitude,
                                curr_location_longitude:curr_location_succ.coords.longitude
                            })
                        }
                    }
                    else {
                        resolve({ locationFetch: false })
                        toast.warn("Unable to fetch current location. Please try again later");
                    }
                    })
                    .catch(geocoder_error => {
                        console.log(geocoder_error);
                        resolve({locationFetch:false})
                    });
                    async function getAddressfromIP(finalApiURL) {
                        let fetchResult = await fetch(finalApiURL); // call API
                        let results = await fetchResult.json();
                        return results;
                    }
            },
            async(curr_location_err) => {
                console.log("from curr location err", curr_location_err)
                // let ipParsedRes=await customFetchData('GET',{},null,state,dispatch,"https://ipapi.co/json/?key=fd1b56f1a7e14bc7aa238e2d7e3b15d7166ea28c") // Custom hook to check network and fetch data
                // ipParsedRes=ipParsedRes.fetchedData
                console.log('ipParsedRes',global.latLng_Ip_Global.latitude,global.latLng_Ip_Global.longitude)
                if(global.latLng_Ip_Global.latitude!==null && global.latLng_Ip_Global.longitude!==null){
                    let latitudeIp=global.latLng_Ip_Global.latitude,longitudeIp=global.latLng_Ip_Global.longitude;
                        const LocationStr = latitudeIp + "," + longitudeIp;
                        let ApiURL = "https://maps.googleapis.com/maps/api/geocode/json?";
                        let params = `latlng=${LocationStr}&key=AIzaSyC6sHvN36SBHhSQymZ3d17MwtTeql16VbQ`;
                        let finalApiURL = `${ApiURL}${encodeURI(params)}`;
                        let getData=await getAddressfromIP(finalApiURL)
                        .then((json) => {
                            console.log("JSON in fetch location", json)
                            if (json.status === "OK") {
                                if (json.results.length !== 0) {
                                    // console.log('results',json.results)
                                    let addressLine1, city, country, zipcode, stateOrProvince, district,currentLocation;
                                    let countryCheck = '';
                                    currentLocation=json.results[0].formatted_address;
                                    json.results[0].address_components.map((item, index) => {
                                        if (item.types[0] == "route") {
                                            addressLine1 = item.long_name;
                                            // console.log("address", item.long_name)
                                        }
                                        else if (item.types[0] == "neighborhood") {
                                            if (addressLine1 == '') {
                                                addressLine1 = item.long_name;
                                                // console.log("address1", item.long_name)
                                            }
                                            else if (!isNaN(Number(addressLine1))) {
                                                addressLine1 = item.long_name;
                                                // console.log("address2", item.long_name)
                                            }
                                            else {
                                                addressLine1 = item.long_name;
                                                // console.log("address3", item.long_name)
                                            }
                                        }else if(item.types[0] == "point_of_interest"){
                                            addressLine1 = item.long_name;
                                        }
                                        else if (item.types[0] == "political") {
                                            if (addressLine1 == '') {
                                                // console.log("address4", item.long_name)
                                                addressLine1 = item.long_name;
                                            }
                                            else {
                                                // console.log("address5", item.long_name)
                                                addressLine1 = item.long_name;
                                            }
                                        }
                                        else if (item.types[0] == "administrative_area_level_1") {
                                            stateOrProvince = item.long_name
                                            // console.log("state", item.long_name)
                                        }
                                        else if (item.types[0] == "country") {
                                            countryCheck = item.long_name
                                            country = item.long_name
                                            // console.log("country", item.long_name)
                                        }
                                        else if (item.types[0] == "postal_code") {
                                            zipcode = item.long_name
                                            // console.log("zipcode", item.long_name)
                                        }
                                        else if (item.types[0] == "administrative_area_level_3" || item.types[0] == "administrative_area_level_2") {
                                            district = item.long_name
                                            // console.log('enter dis',district)
                                        }
                                        else {
                                            // console.log("else")
                                        }
                                    })
                                    if (countryCheck.includes('India')) {
                                        json.results.map(item1 => {
                                            // console.log("city", item1)
                                            if (item1.types[0] == "locality") {
                                                city = item1.address_components[0].long_name
                                                // console.log("city", item1.long_name)
                                            }
                                        })
        
                                    } else {
                                        json.results[0].address_components.map((item1, index) => {
                                            if (item1.types[0] == "locality") {
                                                city = item1.long_name
                                                // console.log("city", item1.long_name)
                                            }
                                        })
                                    }
                                    if(fromBookings){
                                        console.log('no need to dispatch to the global state in current denied case')
                                    }else{
                                        storeAddress(currentLocation,addressLine1,country,city,stateOrProvince,zipcode,district,latitudeIp,longitudeIp,true,false,true,dispatch)
                                    }
                                    // dispatch({ type: 'ADDRESS_STORE', totalAddress:currentLocation,addressLine1:addressLine1,country:country,city:city,stateOrProvince:stateOrProvince,zipcode:zipcode,district:district,latitude:latitudeIp,longitude: longitudeIp,currentAddress:true,searchAddress:false,currentLocationDenied:true})
                                    resolve({
                                        locationFetch:false,
                                        currentLocationDenied:true,
                                        addressLine1:addressLine1,
                                        zipcode:zipcode,
                                        city:city,
                                        country:country,
                                        stateOrProvince:stateOrProvince,
                                        district:district,
                                        currentAddress:true,
                                        currentLocation:currentLocation,
                                        curr_location_latitude:latitudeIp,
                                        curr_location_longitude:longitudeIp
                                    })
                                }
                            }
                            else {
                                resolve({ locationFetch: false })
                                toast.warn("Unable to fetch current location. Please try again later");
                            }
                            })
                            .catch(geocoder_error => {
                                console.log(geocoder_error);
                                resolve({locationFetch:false})
                            });
                            async function getAddressfromIP(finalApiURL) {
                                let fetchResult = await fetch(finalApiURL); // call API
                                let results = await fetchResult.json();
                                return results;
                            }
                }
                
                
                // resolve({locationFetch:false})

            });           
        }
    }
    catch(c1)
    {
        console.log(c1)
        toast.error("Something went wrong. Please try again.");
        resolve({fetch:false,fetchedData:[],catch:true})
    }
    })
}
