import React,{useEffect,useState,useContext} from "react";
import {useNavigate} from 'react-router-dom'
import { ClipLoader } from 'react-spinners';
import { useParams,useSearchParams } from "react-router-dom";
import customFetchData from "../../Components/Custom/customFetchData";
import { clearCart,clearServices,clearDateAndTime } from "../../GlobalState/globalStateActions";
import { globalState } from "../../GlobalState/globalState";
import { ToastContainer, toast } from "react-toastify";
import Lottie from 'react-lottie';
import paymentLoading from '../../Assets/Animations/paymentLoading.json'

export default function StripeSuccess(){
    const navigate=useNavigate()
    const params=useParams()
    const [loadingL,setLoadingL]=useState(true)
    let [searchParams, setSearchParams] = useSearchParams();
    const [state,dispatch]=useContext(globalState)

    let timer=false,timeInterval=null,timeOut
    // console.log("params",params,searchParams.forEach((v,k)=>console.log("v and k",v,k)))
    // console.log("params check url",searchParams.has('success'),searchParams.has('rs'))
    useEffect(()=>{
        
        payStatusCheck()
        // setTimeout(()=>{navigate('/c/mybookings')},3000)

    },[])

    const payStatusCheck=async()=>{
        if(searchParams.has('success') && searchParams.has('rs'))
        {
            // console.log("payment success")
            timeInterval=setInterval(async()=>{
                let payData={
                    pay:false,
                    check:true,
                    consumer_id:state.user.id ? state.user.id : null,
                    randString:searchParams.get('rs')?searchParams.get('rs'):''
                }
                let {fetchedData}=await customFetchData('POST',payData,'/website/payments/razorpay/sv2',state,dispatch)
                if(fetchedData.spay)
                {
                    // console.log("payment success");
                        clearInterval(timeInterval);
                        // toast.success("Payment successful", {position:toast.POSITION.BOTTOM_CENTER})
                        clearServices(dispatch)
                        clearCart(dispatch)
                        clearDateAndTime(dispatch)
                        navigate('/c/mybookings',{replace:true})
                        
                }
                else 
                {
                    if(fetchedData.dbError)
                    {
                        // console.log("dbError");
                        clearInterval(timeInterval);
                        navigate('/c/mybookings',{replace:true})   
                        toast.error("Internal Error. Please check your transactions page for your payment status", {position:toast.POSITION.BOTTOM_CENTER}); 
                    }
                    else if(fetchedData.pending)
                    {
                        // console.log("payment pending")
                    }
                    else
                    {
                        // console.log("payment failed");
                        clearInterval(timeInterval);
                        navigate('/c/mybookings',{replace:true})        
                        toast.error("Payment Failed. Please try again", {position:toast.POSITION.BOTTOM_CENTER});         
                    }
                }
            },4000)

            timeOut=setTimeout(()=>{
                clearInterval(timeInterval);
                console.log("cleared time interval")
            },1000*60*3)
        }
        else if(searchParams.has('cancel') && searchParams.has('rs'))
        {
            // console.log("payment cancelled",searchParams.get('rs'))
            let bodyData={
            randString:searchParams.get('rs')?searchParams.get('rs'):'',            
            consumer_id:state.user.id ? state.user.id : null,
            fromPaymentFail:false,
          }

        //   if(fromPaymentFail)
        //   {
        //     bodyData.payErrorObj=payErrorObj
        //     bodyData.fromPaymentFail=true
        //   }
          
        ////cancelling the transaction and increasing the decreased count immediately
          let {fetchedData}=await customFetchData('POST',bodyData,'/website/payments/stripe/cancelTransaction/sv2',state,dispatch)
        //   console.log("fetchedData in cancel trasnaction",fetchedData)
          if(fetchedData.cancel)
            {
                // if(!fromPaymentFail)
                // {
                    navigate('/checkout',{replace:true})
                // }
                // clearCart(dispatch)
            }
            else
            {
                // if(fetchedData.pay)
                // {
                //     setPayCompleteModal(true)
                // }
                // else
                // {
                //     if(!fromPaymentFail)
                //     {
                        navigate('/checkout',{replace:true})
                    // }
                // }
                // clearCart(dispatch)
            }

        }
    }

    return(
        <div style={{display:'flex',marginTop:100,height:'600px',justifyContent:'center',alignItems:'center'}}>
            {/* <div> */}
                {/* <ClipLoader widthUnit={"%"} size={50} loadings={loadingL} color="#8c52ff" /> */}
                <div style={{display:'flex',flexDirection:'column'}}>
                    <Lottie 
                        options={{
                            loop: true,
                            autoplay: true, 
                            animationData: paymentLoading,
                            // rendererSettings: {
                            //     preserveAspectRatio: 'xMidYMid slice'
                            // }
                        }}
                        height={300}
                        width={300}
                    />
                    <text style={{marginRight:20}}>Please wait while we check status of your payment and confirm your booking</text>
                </div>
            {/* </div> */}
        </div>
    )
}