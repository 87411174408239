import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, FormGroup, Label, Input, FormText, Modal, ModalFooter, ModalHeader, ModalBody, } from 'reactstrap';
import '../../CSS/UI Components/ConsumerEditProfile.css'
import edit_profile from '../../../New_Website_V2/Assets/edit_profile.png'
// import {FiSearch} from 'react-icons/fi'
import { RxPencil1 } from "react-icons/rx";
import { globalState } from '../../GlobalState/globalState'
import customFetchData from '../../Components/Custom/customFetchData'
import { ToastContainer, toast } from 'react-toastify';
import ProfileImage from '../../../New_Website_V2/Assets/profile.png'
import { storeCredentialsLocalStorage } from '../../GlobalState/globalStateActions'
import CrossIcon from '../../../New_Website_V2/Assets/crossIcon.png';
import OtpInput from 'react-otp-input';
import momenttz from 'moment-timezone'
import moment from 'moment';
import Countdown, { zeroPad, calcTimeDelta, formatTimeDelta } from 'react-countdown';
export default function ConsumerEditProfile(props) {
    const [state, dispatch] = useContext(globalState)
    const [name, setName] = useState('')
    const [nameValid, setNameValid] = useState(false)
    const [nameInvalid, setNameInvalid] = useState(false)
    const [mobile, setMobile] = useState('')
    const [mobileValid, setMobileValid] = useState(false)
    const [mobileError, setMobileError] = useState('')
    const [mobileInvalid, setMobileInvalid] = useState(false)
    const [email, setEmail] = useState('')
    const [changedName, setChangedName] = useState(false)
    const [emailValid, setEmailValid] = useState(false)
    const [emailInvalid, setEmailInvalid] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [updateMobileModal, setUpdateMobileModal] = useState(false)
    const [mobileCountryCode, setMobileCountryCode] = useState(state.country.country == 'IN' ? '+91' : '+1')
    const [mobileVerified, setMobileVerified] = useState('')
    const [sendOtp, setSendOtp] = useState(false)
    const [verificationModal, setVerificationModal] = useState(false)
    const [sendVC, setSendVC] = useState(false)
    const [mobileAlreadyVerify, setMobileAlreadyVerify] = useState(false)
    const [mobileCheck, setMobileCheck] = useState('')
    const [resendButton, setResendButton] = useState(false)
    const [otpInput, setOtpInput] = useState('')
    const [otpValid, setOtpValid] = useState(true)
    const [existingEmail, setExistingEmail] = useState('')
    const [otp, setOtp] = useState('')
    const [emailOtp, setEmailOtp] = useState('')
    const [verify, setVerify] = useState('')
    const [oldMobile, setOldMobile] = useState('')
    const [oldMobileCountryCode, setOldMobileCountryCode] = useState('')
    const [oldEmail, setOldEmail] = useState('')
    const [ resendButtonClicked, setResendButtonClicked ] = useState(true)
    const [emailUpdateModal, setEmailUpdateModal] = useState(false)
    const [currentTimeIndex, setCurrentTimeIndex] = useState(0);
    const [ timerkeyValue, setTimerkeyValue ] = useState(1)
    // a hook for the current time
    const [initTimestamp, setInitTimestamp] = useState(Date.now());
    const [currentTime, setCurrentTime] = useState(null);
    let localStorage = window.localStorage
    const times=[Date.now()+5000,Date.now()+12000,Date.now()+17000,Date.now()+22000]
    useEffect(() => {
        setName(props.name ? props.name : '')
        setOldMobile(props.mobile ? props.mobile : '')
        setOldEmail(props.email ? props.email : '')
        setOldMobileCountryCode(props.mobileCountryCodeOld)
          
    }, [])
    const onChangeNameHandler = (event) => {
        let value = event.target.value
        if (value.trim() === '') {
            setName('')
            setNameValid(false)
            setNameInvalid(true)
            setChangedName(false)
        }
        else if (value.length < 3 && /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/.test(value.trim())) {
            setNameValid(false)
            setNameInvalid(true)
            // name:value,

        } else if (value.length >= 3 && /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/.test(value.trim())) {
            setNameValid(true)
            setNameInvalid(false)
            // name:value,
            // message:'Please enter Name',
        }
        else {
            setName(value)
            setNameValid(true)
            setNameInvalid(false)
            setChangedName(true)

        }
    }
    const dropdownCountry = (event) => {
        setMobileCountryCode(event.target.value);
    }
    const nameUpdateHandler = async () => {
        // console.log('in update function', nameValid, changedName, state.user.id)
        if (!nameValid) {
            toast.error("Please enter the name", { position: toast.POSITION.BOTTOM_CENTER });
        }
        else {

            let data = {
                name: name.trim(),
                id: state.user.id
            }
            let nameUniqueData = await customFetchData('POST', data, '/website/userdetailsupdate', state, dispatch)
            // console.log('nameUniqueData', nameUniqueData)
            if (nameUniqueData.fetchedData.data) {

                toast.success('name updated successfully', { position: toast.POSITION.BOTTOM_CENTER });
                let id = state.user.id, email = state.user.email, username = name, role = state.user.role, city = state.user.city, country = state.user.country

                // console.log("storeCredentialsLocalStorage", id, email, username, role, city, country)
                dispatch(storeCredentialsLocalStorage(id, email, username, role, city, country))
                // navigation.navigate('Profile')
            }
            else {
                // setStatus('error')
                // setMessage('Something went wrong, Please try again.')
            }


        }
    }
    let textColorStyle={
        color:'#8c52ff',
        opacity: 0.4,
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        height:48,
        borderRadius:10
    }
    let buttonDisabled = false;
    if (mobile === '') {
        buttonDisabled = true
    }
    else if (mobileError) {
        buttonDisabled = true
    }
    else {

        buttonDisabled = false
    }
    let nameFromLocalStorage = localStorage.getItem("qf:auth:username")
    // console.log('name and local storage name', name, nameFromLocalStorage, nameFromLocalStorage == name)
    let nameNotUpdated = true
    if (changedName) {
        nameNotUpdated = false
    }
    const onPressSendOTPEmail = async () => {
        setEmailUpdateModal(false)
        if (email === '') {
            toast.error('Please enter email address', { position: toast.POSITION.BOTTOM_CENTER });
        }
        else if (email.length < 3 && /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/.test(email.trim())) {
            // this.setState({status:'error',message:'Please enter valid Email Address',  emailValid:false, emailError: true,})
            setEmail(email.trim())
            setEmailValid(false)
            setEmailError(true)
            toast.error('Please enter valid Email Address', { position: toast.POSITION.BOTTOM_CENTER });
        } else if (email.length >= 3 && /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/.test(email.trim())) {
            // this.setState({status:'',message:''})
            setEmailError(false)
            setEmailValid(true)
            setEmailInvalid(false)
        }
        else if (!/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/.test(email.trim())) {
            if (email.includes(' ')) {
                // this.setState({
                //     emailError:true
                // })
                setEmailError(true)
                toast.error('Please enter valid Email Address', { position: toast.POSITION.BOTTOM_CENTER });

                if (/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/.test(email.trim())) {
                    setEmailError(false)
                    setEmailValid(true)
                    setEmailInvalid(false)
                }
            } else {
                setEmail(email)
                setEmailValid(false)
                setEmailError(true)
                toast.error('Please enter valid Email Address', { position: toast.POSITION.BOTTOM_CENTER });
            }
        }
        else if (email === existingEmail) {
            setEmail(email)
            toast.error('Email Address already exists', { position: toast.POSITION.BOTTOM_CENTER });

        }
        else {
            setEmail(email.trim())
            setEmailValid(false)
            setEmailError(true)
            let randomPin = null;
            randomPin = Math.floor(1000 + Math.random() * 9000);

            let otpdata = {
                // email: email ? email : '',
                // mobile: '',
                // randomNum: randomPin,
                // otpType: 'emailOtp',
                // sendOTP: true

                email: email ? email.trim() : null,
                uid: state.user.id,
                role: state.user.role
            }

            let method = 'POST';
            let url = '/website/checkemail/sendemail/sv2'
            let responseJson = await customFetchData(method, otpdata, url, state, dispatch)
            // console.log('fetch data', responseJson)
            if (responseJson.fetchedData.emailCodeUpdate) {
                setVerify(true)
                toast.success('Verification code sent successfully', { position: toast.POSITION.BOTTOM_CENTER });
            }
            else if (responseJson.tokenValid === false || responseJson.dbTokenError) {
                if (responseJson.dbTokenError) {
                    toast.error('Internal error,Please try again later', { position: toast.POSITION.BOTTOM_CENTER });
                }
            }
        }


    }
    
    const onPressSendOTP = async () => {
        setTimerkeyValue(timerkeyValue+1)
        setInitTimestamp(Date.now())
        setUpdateMobileModal(false)
        setVerificationModal(true)
        // setUpdateMobileModal(false)
        let otpdata = {

            id: state.user.id,
            mobileNumber: mobile,
            sendMessage: true,
            country: 'GM',
            type: state.user.role,

        }

        let updateUrl = mobileCountryCode == "+91" ? "/website/verify/mobile/india" : '/website/verify/mobile/twilio'
        let mobileUniqueData = await customFetchData('POST', otpdata, updateUrl, state, dispatch)
        if (mobileUniqueData.fetchedData.data) {
            console.log('mobileUniqueData', mobileUniqueData)
            toast.success("Verification code sent successfully", { position: toast.POSITION.BOTTOM_CENTER });
            setSendOtp(true)
            setVerificationModal(true)

        }
        else {

            if (mobileUniqueData.fetchedData.msgData.code === 21211) {
                toast.error("This number is not a valid Mobile Number", { position: toast.POSITION.BOTTOM_CENTER });
                setVerificationModal(false)
            }
            else if (mobileUniqueData.fetchedData.msgData.code === 21612) {
                setVerificationModal(false)
                toast.error("Currently we are unable to send message to this number", { position: toast.POSITION.BOTTOM_CENTER });
            }
            else if (mobileUniqueData.fetchedData.msgData.code === 21408) {
                setVerificationModal(false)
                toast.error("Currently we are unable to send international messages", { position: toast.POSITION.BOTTOM_CENTER });
            }
            else if (mobileUniqueData.fetchedData.msgData.code === 21614) {
                setVerificationModal(false)
                toast.error("Please use a number which is capabale of recieving messages", { position: toast.POSITION.BOTTOM_CENTER });
            }
            else if (mobileUniqueData.fetchedData.msgData.code === 21608) {
                setVerificationModal(false)
                toast.error("Please verify the number with twilio, Before getting verification code", { position: toast.POSITION.BOTTOM_CENTER });


            }
            else {
                setVerificationModal(false)
                toast.error("Currently we are unable to send message to this number", { position: toast.POSITION.BOTTOM_CENTER });
            }


        }
    }
    const onChangeEmail = async (event) => {
        let value = event.target.value
        // console.log('emailhandler', email)
        if (value.trim() === '') {
            setEmailValid(false)
            setEmailError(true)
            setEmail('')
            toast.error('Please enter email address', { position: toast.POSITION.BOTTOM_CENTER });

        }
        else if (value.length < 3 && /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/.test(value.trim())) {
            setEmail(value)
            setEmailValid(false)
            setEmailError(true)
            toast.error('Please enter valid Email Address', { position: toast.POSITION.BOTTOM_CENTER });
        } else if (value.length >= 3 && /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/.test(value.trim())) {
            // this.setState({status:'',message:''})
            setEmailValid(true)
            setEmailError(false)

        }
        else if (!/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/.test(value.trim())) {
            if (value.includes(' ')) {
                // this.setState({
                //     emailError:true
                // })
                setEmailError(true)
                setEmailInvalid(true)
                setEmailValid(false)

                if (/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/.test(value.trim())) {
                    setEmailError(false)
                    setEmailInvalid(false)
                    setEmailValid(true)
                }
            } else {
                // this.setState({
                //     email: value.trim(),
                //     emailValid:false,
                //     containerStyle: 'containerError',
                //     emailError: true,
                //     status:''
                // })
                // setStatus(false)
                setEmail(value)
                setEmailValid(false)
                setEmailError(true)
                // setMessage('Please enter Email Address')
            }
        } else {
            if (value.includes(' ')) {
                // this.setState({
                //     emailError:false
                // })
                setEmailError(false)
            }
            //   else {
            //     // this.setState({
            //     //     email: value.trim(),
            //     //     emailValid:true,
            //     //     status:'',
            //     //     emailError: false
            //     // })
            //     setEmail(value.trim())
            //     setEmailValid(true)
            //     setStatus(false)
            //     setEmailError(false)
            // }

            else {

                console.log('mail unique', value)
                setEmail(value)
                setEmailValid(true)
                setEmailError(false)

                let uniquedata = {
                    // mobile: value.trim(),
                    email: value
                }
                uniqueValidation(uniquedata)


            }
        }

    }
    const uniqueValidation = async (uniquedata) => {
        // console.log('body', uniquedata)
        let method = 'POST';
        let url = '/website/guestUniqueValidation/sv2'
        let responseJson = await customFetchData(method, uniquedata, url, state, dispatch)
        // console.log('fetch data', responseJson)
        if (responseJson.fetch) {
            // console.log('data')

            if (responseJson.fetchedData.userVerified == true) {
                setVerify(false)
                toast.error('Email Address Already Exists', { position: toast.POSITION.BOTTOM_CENTER });

            }
            else if (responseJson.fetchedData.merchantVerified == true) {
                toast.error('This is a registered Professional email. Please use a different email address as a Customer', { position: toast.POSITION.BOTTOM_CENTER });

            }

        }


    }
    const onChangeMobile = async (event) => {
        setSendVC(false)
        let value = event.target.value
        if (value === '') {
            setMobile('')
            setMobileValid(false)
            setMobileError(true)
        }

        else if ((value.includes(".") || value.includes(",") || value.includes("-") || value.includes(' '))) {

            setMobileValid(false)
            setMobileError(true)
            toast.error("Special characters cannot be entered", { position: toast.POSITION.BOTTOM_CENTER });

            if (mobileCheck.length == 0) {
                setMobileValid(true)
                setMobileError(false)
            }

            if (value.length <= 10) {

                setMobileValid(false)
                setMobileError(true)
                setSendVC(false)
            }
        }
        else if (/^(\d*)$/.test(value)) {
            setMobile(value)
            setMobileValid(false)
            if (value.length < 10) {
                setMobileError(true)
                // toast.error("Please enter valid muber", { position: toast.POSITION.BOTTOM_CENTER });

            } else if (value.length == 10) {
                setMobile(value)
                setMobileError(false)
                toast.success("Please verify your verify mobile number", { position: toast.POSITION.BOTTOM_CENTER });
                let uniquedata = {
                    mobile: value
                }

                let mobileUniqueData = await customFetchData('POST', uniquedata, '/website/mobileUniqueVerification', state, dispatch)
                // console.log('mobile uinquee data', mobileUniqueData)
                if (mobileUniqueData.fetchedData.data) {
                    if (mobileUniqueData.fetchedData.verified) {
                        setMobileError(true)
                        setMobileAlreadyVerify(true)
                        setSendVC(false)
                        toast.success("Mobile Number already Exists", { position: toast.POSITION.BOTTOM_CENTER });

                    }
                    else {
                        // setSendVC(true)
                        setMobileAlreadyVerify(false)
                        // setVerificationModal(true)
                        // setUpdateMobileModal(false)
                        // onPressSendOTP(value)

                    }
                }
                else {
                    console.log('no data found')
                }


            }

        } else {
            setMobileValid(false)
            setMobileError(true)
            toast.error("Please enter a valid number", { position: toast.POSITION.BOTTOM_CENTER });
        }

    }
    const otpHandler = (text) => {

        let OTP = text;
        OTP.concat(text);

        setOtp(text)

        if (text.length === 4) {

            // onPressEmailOTP(OTP);

        }
    }
    const onPressMobileOTP = async (otp) => {

        if (otp.trim() == '') {
            setOtpValid(false)
            toast.error('verification code cant\'t be empty', { position: toast.POSITION.BOTTOM_CENTER });
        }
        else {
            let otpdata = {
                id: state.user.id,
                mobile: mobile,
                sendMessage: false,
                country: 'GM',
                type: state.user.role,
                otpCode: Number(otp),
                businessName: name,
                // merchantEmail:route.params.email
            }

            let updateVerifyUrl = mobileCountryCode == "+91" ? "/website/verify/mobile/india" : '/website/verify/mobile/twilio'
            let MobileData = await customFetchData('POST', otpdata, updateVerifyUrl, state, dispatch)
            // console.log('response from twilio otp check', MobileData)
            if (MobileData.fetchedData.data) {
                if (MobileData.fetchedData.mVerified) {
                    // mobile: this.state.mobile,
                    setMobileValid(true)
                    setMobileError(false)
                    setVerificationModal(false)
                    setOldMobile(mobile)
                    setOldMobileCountryCode(mobileCountryCode)
                    // setOldEmail(email)
                    setMobile('')
                    setMobileCountryCode('')
                    // mobileCheck: this.state.mobile
                    setTimeout(() => {
                        toast.success('Mobile Number Updated Successfully', { position: toast.POSITION.BOTTOM_CENTER });
                    }, 500)
                    // clearInterval(countTime)
                    // navigation.replace('Profile')
                }
                else {
                    if (MobileData.fetchedData.expired) {
                        toast.error('verification code is expired', { position: toast.POSITION.BOTTOM_CENTER });
                        setOtpValid(false)

                    } else {
                        toast.error('verification code is not valid', { position: toast.POSITION.BOTTOM_CENTER });
                        setOtpValid(false)
                    }
                }
            }
            else {
                toast.error('Internal error,Please try again later.', { position: toast.POSITION.BOTTOM_CENTER });
                setVerificationModal(false)

            }




        }
    }
    // let nameUpdated= false;
    // if(changedName){
    //     nameUpdated=false
    // }
    // else if(name === state.user.username){
    //     nameUpdated= true
    // }
    // else{
    //     nameUpdated= true
    // }
    let emailbuttonDisabled = true;

    if (email.trim() == '') {

        emailbuttonDisabled = true;

    } else if (!/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/.test(email.trim())) {

        emailbuttonDisabled = true;

    } else if (emailError) {

        emailbuttonDisabled = true;
    }
    else {
        emailbuttonDisabled = false
    }
    const emailOtpHandler = (text) => {
        console.log(text)
        let OTP = text;
        OTP.concat(text);
        // console.log('inputOTP',OTP)
        setEmailOtp(OTP)
    }
    const onPressEmailOTP = async (emailOtp) => {
        let body = {
            email: email ? email : '',
            otp: emailOtp,
            otpType: 'emailOtp',
            sendOTP: false,
            onlyVerify: false,
            mobile: mobile ? mobile : '',
            uid: state.user.id,
            oldEmail: state.user.email,

        }

        // console.log('inputotp', inputOtp)
        // console.log('bodyg', body)

        let updateUrl = state.country.country == "IN" ? '/website/userverifyotp/india/sv2' : '/website/userverifyotp/sv2';
        let method = 'POST';
        let url = updateUrl
        let responseJson = await customFetchData(method, body, url, state, dispatch)
        // console.log('fetch data', responseJson)

        if (responseJson.fetchedData.data) {
            if (responseJson.fetchedData.code) {
                toast.success('Email Address Updated Successfully', { position: toast.POSITION.BOTTOM_CENTER });
                // navigation.navigate('Profile')
                setVerify(false)
                setOldEmail(email)
                setEmail('')
                // setEmailUpdateModal(false)
                // let id = state.user.id, email = email, role = state.user.role, city = state.user.city, country = state.user.country
                dispatch(storeCredentialsLocalStorage(state.user.id, email, state.user.username, state.user.role, state.user.city, state.user.country))
                // dispatch({ type: 'CREDENTIALS_STORE', })

            }
            else if (!responseJson.fetchedData.code) {
                toast.error('Invalid code', { position: toast.POSITION.BOTTOM_CENTER });

            }
            else if (responseJson.tokenValid === false || responseJson.dbTokenError) {
                if (responseJson.dbTokenError) {
                    toast.error('Internal Error. Please try again', { position: toast.POSITION.BOTTOM_CENTER });
                }
            }
        }
    }

    const onResendClickedHandler = () => {
        setTimerkeyValue(timerkeyValue+1)
        setResendButton(false)
    }

    return (
        <div className='edit_profile_web'>
            <div className='content_profile_web'>
                <div>
                    <img src={ProfileImage} className='profile_image_style' />
                </div>
                <div className='inputName'>
                    <FormGroup>
                        <Input
                            type='text'
                            valid={nameValid ? true : false}
                            invalid={nameInvalid ? true : false}
                            maxLength={20}
                            // id="nameInputID"
                            placeholder="Name"
                            value={name}
                            style={textColorStyle}
                            onChange={onChangeNameHandler} />
                    </FormGroup>
                    <div style={{ display: 'flex', flex: 1, marginTop: 30 }}>
                        <div className='country_code_web'>
                            <FormGroup>
                                <Input type='select'
                                    value={oldMobileCountryCode}
                                    disabled={true}
                                    style={textColorStyle}
                                    onChange={dropdownCountry}>
                                    <option value='+1'>+1</option>
                                    <option value='+91'>+91</option>
                                </Input>
                            </FormGroup>
                        </div>
                        <div className='mobile_num_web'>
                            <FormGroup>
                                <Input type='number'
                                    maxLength={10}
                                    value={oldMobile}
                                    style={textColorStyle}
                                    disabled={true}
                                    placeholder='Mobile Number'>
                                    {/* onChange={onChangeMobile} */}
                                </Input>

                                <img src={edit_profile} onClick={() => setUpdateMobileModal(true)} style={{ width: 16.5, height: 16.5, position: 'absolute', right: 10, bottom: 28, cursor: 'pointer' }} />
                            </FormGroup>
                        </div>
                    </div>

                    <div style={{ marginTop: 15, marginBottom: 30, position: 'relative' }}>
                        <FormGroup>
                            <Input
                                type='text'
                                placeholder='Please Enter Your Email Address'
                                value={oldEmail}
                                style={textColorStyle}
                                id='nameInputID'
                                disabled={oldEmail ? true : false}
                                onClick={() => setEmailUpdateModal(true)}
                                // valid={emailValid ? true : false}
                                // invalid={emailInvalid ? true : false}
                                // onChange={emailHandler}
                                maxLength={40}></Input>
                            {oldEmail ? <img src={edit_profile} onClick={() => setEmailUpdateModal(true)} style={{ width: 16.5, height: 16.5, position: 'absolute', right: 10, bottom: 10, cursor: 'pointer' }} /> : null}
                        </FormGroup>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                        <Button className='update_button_name' disabled={nameNotUpdated} onClick={nameUpdateHandler} style={{ width: 200 }}>Update</Button>
                    </div>
                    {/* {
                        resendButton ?

                    <div style={{cursor:'pointer'}} onClick={() => onResendClickedHandler()}>
                        <text>Resend buttion</text>
                    </div>
                    :
                    null
                    } */}
                </div>
            </div>

            <ToastContainer />
            {/* { update mobile modal} */}
            <Modal isOpen={updateMobileModal} size={"md"} toggle={() => setUpdateMobileModal(false)} centered>
                <div style={{padding:20}}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <div className='cross_update_mobile' onClick={() => setUpdateMobileModal(false)}>
                            <img src={CrossIcon} style={{ width: 18, height: 18, }} />
                        </div>

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop:20}}>
                        <text className='update_mobile_text'>Update Phone number</text>
                        <text className='update_mobile_desc'>Enter a new phone number, and we will send the code for verification</text>
                    </div>
                    <div style={{ display: 'flex', flex: 1, marginTop: 30 }}>
                        <div style={{ flex: 0.8 }}>
                            <FormGroup>
                                <Input type='select'
                                    value={mobileCountryCode}
                                    style={textColorStyle}
                                    onChange={dropdownCountry}>
                                    <option value='+1'>+1</option>
                                    <option value='+91'>+91</option>
                                </Input>
                            </FormGroup>
                        </div>
                        <div style={{ flex: 2.2, marginLeft: 10, }}>
                            <FormGroup>
                                <Input
                                    maxLength={10}
                                    style={textColorStyle}
                                    placeholder='Enter new phone number'
                                    value={mobile}
                                    id='nameInputID'
                                    onChange={onChangeMobile}>
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Button onClick={onPressSendOTP} disabled={buttonDisabled} className='sendVerification_web' >Send verification code</Button>
                    </div>
                </div>
            </Modal>
            <Modal isOpen={verificationModal}  toggle={() => setVerificationModal(false)} centered >
                <ModalBody className='modal_main_web'>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <div className='cross_update_mobile' onClick={() => setVerificationModal(false)}>
                            <img src={CrossIcon} style={{ width: 18, height: 18, }} />
                        </div>

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <text className='update_mobile_text'>Verification</text>
                        <text className='update_mobile_desc'>Please enter the verification code</text>
                    </div>
                    <div style={{display:'flex',justifyContent:'center',flexDirection:'row',alignItems:'center'}}>
                    <Countdown
                        date={initTimestamp+30000}
                        key={timerkeyValue}
                        // onComplete={() => {
                          
                        // }}
                        // render completed
                        renderer={({ hours, minutes, seconds, completed }) => {
                                return (
                                    completed ? 
                                    <div>
                                            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: 10 }}>
                                                <span><text className='timerText'>{zeroPad(minutes)}:{zeroPad(seconds)}</text></span>
                                            </div>
                                            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', position: 'absolute',bottom:30, left: '20%', right: '20%' }} onClick={() => onResendClickedHandler()}>
                                                <text>I didn’t receive the code!<text onClick={() => onPressSendOTP()}  style={{ color: '#8c52ff', cursor: 'pointer' }}>Resend</text></text>
                                            </div>
                                    </div> 
                                    : 
                                    <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: 10 }}>
                                        <span><text className='timerText'>{zeroPad(minutes)}:{zeroPad(seconds)}</text></span>
                                    </div>
                                    
                                )

                        }}
                    />
                    </div>
                    <div style={{ marginTop: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <OtpInput
                            value={otp}
                            type='number'
                            onChange={(text) => otpHandler(text)}
                            inputStyle={{
                                width: 48,
                                height: 48,
                                borderRadius: 10,
                                marginRight: 10,
                                marginLeft:10,
                                border: '1px solid #747474'
                            }

                            }
                            shouldAutoFocus={true}
                            // className='otpinput'
                            numInputs={4}
                            renderSeparator={<span></span>}
                            renderInput={(props) => <input {...props} />}
                        />
                        <Button onClick={() => onPressMobileOTP(otp)} style={{ backgroundColor: "#8c52ff", marginTop: 40, width: '75%' }} >Verify</Button>
                    </div>
                    {
                        resendButton ?
                            <div onClick={() => onPressSendOTP()} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginTop: 50, cursor: 'pointer' }}>
                                <text className='resend_style_web'> I didn’t receive the code!<span style={{ fontStyle: 'Inter', fontWeight: 'semi-bold', color: '#8c52ff' }}>Resend</span></text>
                            </div>
                            :
                            null
                    }
                </ModalBody>
            </Modal>
            <Modal isOpen={emailUpdateModal} toggle={() => setEmailUpdateModal(false)} centered>
                <ModalBody className='modal_main_web'>
                    <div style={{ paddingLeft: 25, paddingRight: 25 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <div className='cross_update_mobile' onClick={() => setEmailUpdateModal(false)}>
                                <img src={CrossIcon} style={{ width: 18, height: 18, }} />
                            </div>

                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',marginTop:20 }}>
                            <text className='update_mobile_text'>Update Email Address</text>
                            <text className='update_mobile_desc'>Enter a new email, and we will send the code for verification</text>
                        </div>
                        <div style={{ marginTop: 35, width: '100%' }}>
                            <FormGroup>
                                <Input type='text'
                                    placeholder='Enter New Email Address'
                                    valid={emailValid}
                                    invalid={emailInvalid}
                                    id='nameInputID'
                                    onChange={onChangeEmail}>
                                </Input>
                            </FormGroup>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                            <Button onClick={onPressSendOTPEmail} disabled={emailbuttonDisabled} className='sendVerification_web' >Send verification code</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={verify} toggle={() => setVerify(false)} centered>
                <ModalBody className='modal_main_web'>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <div className='cross_update_mobile' onClick={() => setVerify(false)}>
                            <img src={CrossIcon} style={{ width: 18, height: 18, }} />
                        </div>

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <text className='update_mobile_text'>Verification</text>
                        <text className='update_mobile_desc'>Please enter the verification code</text>
                    </div>
                    <div style={{display:'flex',justifyContent:'center',flexDirection:'row',alignItems:'center'}}>
                    <Countdown
                        date={initTimestamp+30000}
                        key={timerkeyValue}
                        // onComplete={() => {
                          
                        // }}
                        // render completed
                        renderer={({ hours, minutes, seconds, completed }) => {
                                return (
                                    completed ? 
                                    <div>
                                            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: 10 }}>
                                                <span><text className='timerText'>{zeroPad(minutes)}:{zeroPad(seconds)}</text></span>
                                            </div>
                                            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', position: 'absolute',bottom:30, left: '20%', right: '20%' }} onClick={() => onResendClickedHandler()}>
                                                <text>I didn’t receive the code!<text onClick={onPressSendOTPEmail}  style={{ color: '#8c52ff', cursor: 'pointer' }}>Resend</text></text>
                                            </div>
                                    </div> 
                                    : 
                                    <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: 10 }}>
                                        <span><text className='timerText'>{zeroPad(minutes)}:{zeroPad(seconds)}</text></span>
                                    </div>
                                    
                                )

                        }}
                    />
                    </div>
                    <div style={{ marginTop: 35, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <OtpInput
                            value={emailOtp}
                            onChange={(text) => emailOtpHandler(text)}
                            type='number'
                            inputStyle={{
                                width: 48,
                                height: 48,
                                borderRadius: 10,
                                marginRight: 20,
                                border: '1px solid #747474'
                            }

                            }
                            shouldAutoFocus={true}
                            // className='otpinput'
                            numInputs={4}
                            renderSeparator={<span></span>}
                            renderInput={(props) => <input {...props} />}
                        />
                        <Button onClick={() => onPressEmailOTP(emailOtp)} style={{ backgroundColor: "#8c52ff", marginTop: 25, width: '75%' }} >Verify</Button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}
