import React from "react";
import '../../../CSS/UI Components/CouponButton.css';
import ApplyCouponIcon from '../../../Assets/bxsOffer.png';
import Tick_Image from '../../../Assets/status_tick.png'

export default function CouponButton(props){
    return (
        <div className="CouponButton_DivStyle">
        {/* <div onClick={() => navigation.navigate('ProfessionalDiscounts', {proPersonelIds,proMerchantIds,serviceIds,services, totalAmount, country, totalCPPrice, totalTSPrice, couponApplied, couponCode, navFrom:'Checkout',userPasswordReg:params.userPasswordReg?params.userPasswordReg:false,userId:params.userId?params.userId:null})}> */}

            <div style={{flexDirection:'row'}}>
                <img src={ApplyCouponIcon} />
                <text className="CouponButton_TextStyle">Discount Code - <strong>{' '+props.couponCode+' '}</strong> is applied </text>
            </div>

            <div>
                <img src={Tick_Image} style={{alignSelf:'flex-end'}}/>
            </div>
        </div>
    )
}