import React from "react";
import CouponAppliedIcon from '../../../Assets/couponApplySuccessIcon.png';
import '../../../CSS/RatingPage.css';
import { Modal } from "reactstrap";

export default function ResponseModal(props){
    return (
        <Modal isOpen={props.isOpen} modalTransition={props.modalTransition} toggle={props.toggle} style={{borderRadius:20}} size="md" centered>
            <div style={{padding:20, display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', background:'#e0d1ff', borderRadius:10}}>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <img src={CouponAppliedIcon} width={30} height={30} style={{marginRight:10}}/>
                    <text className="modalCouponSavedText">{props.displayText}</text>
                </div>
            </div>
        </Modal>
    )
}