import React from 'react'
import { Card, CardBody, CardImg, CardText, CardImgOverlay, Row, Col } from 'reactstrap'
import RatingComponent from './RatingComponent'
import '../../../CSS/UI Components/ProfessionalCard.css'
import wishlist from '../../../Assets/ph-heart-bold.png'
import { useMediaQuery } from "react-responsive"
import { AiFillStar } from "react-icons/ai";
import DistanceIcon from '../../../Assets/gis-routeBlack.png'
import WishlistYes from '../../../Assets/iconFavorite.png' 
import numeral from 'numeral'
export default function ProfessionalCard({ state, proData, onPressCard, WishlistPress, wishlisted, serviceCount, fromSearch, serviceName, servicePrice, servicePriceCurrency }) {
  return (
    <Card className='proCard_Sv2' >
      <CardImg
        // progressiveRenderingEnabled={true}
        className='cardImg_style_sv2'
        src={proData.image_link}
        resizeMode='contain'
        onClick={onPressCard}
        style={{cursor:'pointer'}}
      />
      <div style={{ position: 'absolute',cursor:'pointer', top: 20, left: 20 }} className='rating_ProCardView' onClick={onPressCard}>
        {/* <RatingComponent/> */}
        <text className='ratingText_SCard'>{numeral(proData.MERCHANT_PERSONNEL_RATING).format('0.0')}</text>
        <AiFillStar size={15} color="#fed019" />
      </div>
      {state.token ?
        <div onClick={WishlistPress} style={{ position: 'absolute', display: 'flex', top: 20, right: 20, width: 24, height: 24, padding: 4, borderRadius: 8, backgroundColor: '#fff', alignItems: 'center' }}>
          {
            wishlisted ?  <img src={WishlistYes} style={{ width: 16, height: 16, }} /> : <img src={wishlist} style={{ width: 16, height: 16, }} /> 
          }
        </div>
        : null
      }

      <div style={{ marginTop: 8,cursor:'pointer' }} onClick={onPressCard}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <text className='proName_sv2'>{proData.MERCHANT_NAME}</text>
          <text className='noOfServices_sv2'>{fromSearch ? (serviceName + "-" + servicePriceCurrency + servicePrice) : serviceCount + (serviceCount > 1 ? " Services" : ' Service')}</text>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ width: '70%' }}>
            <text className='addressText_sv2'>{proData.MERCHANT_PERSONNEL_ADDRESS_LINE2}</text>
          </div>
          <div style={{ width: '30%' }}>
            <img src={DistanceIcon} style={{ width: 12, height: 12, tintColor: '#fff' }} />
            <text className='distanceText_style' >{proData.SERVICE_CURRENCY == 'Rupees' ? numeral(proData.distance).divide(1000).format('0.00') + ' km away' : numeral(proData.distance).divide(1609.344).format('0.00') + ' mi away'}</text>
          </div>
        </div>
      </div>



    </Card>
  )
}
