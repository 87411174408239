import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Reducer from './Stores/Reducer';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Store } from './New_Website_V2/GlobalState/globalState';

const store = createStore(Reducer);

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container)
root.render(<Store> <BrowserRouter> <App name="Entry point"/></BrowserRouter></Store>)

// ReactDOM.render(<Provider store={store}> <App /> </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
