import React, { useContext, useEffect, useState } from 'react'
import NewNavbarWebsite from '../../../New_WebPage_Design/Components/NewNavbarWebsite'
import { Container, Row, Col, CarouselItem, Carousel, Navbar, Card, CardImg, Button, Modal, ModalBody } from 'reactstrap'
import '../../CSS/ProfessionalPage.css'
import RatingsAndReviews from '../../Components/Custom/UI/RatingsAndReviews';
import DistanceIcon from '../../Assets/gis-route.png'
import StarIcon from '../../Assets/group-482015.png'
import { useMediaQuery } from "react-responsive"
import { NavLink as NavLinkReactor, useLocation, useSearchParams,useNavigate, useParams } from 'react-router-dom';
import ServicesCard from '../../Components/Custom/UI/ServicesCard';
import '../../CSS/HomePage.css'
import RatingComponent from '../../Components/Custom/UI/RatingComponent';
import momenttz from 'moment-timezone'
import moment from 'moment';
import DurationIcon from '../../Assets/asicon-outline-timer.png'
import OffersIcon from '../../Assets/tabler-discount-2.png'
import StarIconFilter from '../../Assets/carbon-star-review.png'
import numeral from 'numeral';
import WishlistIcon from '../../Assets/ph-heart-boldColored.png'
import WishlistIconGrey from '../../Assets/ph-heart-bold.png'
import ShareIcon from '../../Assets/vectorShareIcon.png'
import ShareIconColored from '../../Assets/vectorShareIconColored.png'
import SearchBar from '../../Components/Custom/UI/SearchBar';
import ShopIcon from '../../Assets/ShopNameIcon.png'
import LocateVisitIcon from '../../Assets/LocateVisit.png'
import BusinessHoursIcon from '../../Assets/ic-baseline-access-time-filled.png'
import customFetchData from '../../Components/Custom/customFetchData';
import { globalState } from '../../GlobalState/globalState';
import GoogleMapReact from 'google-map-react';
import { IoLocationSharp } from "react-icons/io5";
import locationcolorIcon from '../../Assets/colorLocation.png'
import ServiceDetails from '../../Components/Custom/UI/ServiceDetails';
import CloseIcon from '../../Assets/icons-closeModal.png'
import { addToCart, clearAddress, clearServices, removeFromCart, storeAddress, storeDateAndTime, storeServices } from '../../GlobalState/globalStateActions';
import ServicesCard_Web from '../../Components/Custom/UI/ServicesCard_Web';
import { FaArrowRight } from 'react-icons/fa';
import WishlistYes from '../../Assets/iconFavorite.png'
import { ClipLoader } from 'react-spinners';
import DiscountCardColoured from '../../Components/Custom/UI/DiscountCardColoured';
import ColorCoupon from '../../Assets/mask-groupMob.png'
import WhiteRectangle from '../../Assets/whiteRect.png'
import DiscountWhite from '../../Assets/discountWhite.png'
import Ratingswhite from '../../Assets/ratingsWhite.png'
import ProfessionalDiscountsCards from '../../Components/Custom/UI/ProfessionalDiscountCards';
import FetchCurrentLocation from '../../Components/Custom/FetchCurrentLocation';
import { ToastContainer, toast } from 'react-toastify';
import OpenWithMapsIcon from '../../Assets/openWithMapIcon.png'
import redirectBannerCrossIcon from '../../Assets/bannerCrossIcon.png'
import { isAndroid, isIOS } from 'react-device-detect';


const DeepClone = require("rfdc/default")
export default function ProfessionalPage() {
  const paramsLocation = useLocation();
  const urlParams=useParams()
  const [proData, setProData] = useState(paramsLocation.state ? (paramsLocation.state.proData ? paramsLocation.state.proData : []) : [])
  const [services, setServices] = useState([])
  const [filteredArray, setFilteredArray] = useState([])
  const useTabletAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 760px)" })
  const isMobile = useTabletAndBelowMediaQuery()
  const [activeIndex,setActiveIndex]=useState(0);
  const [sectionArray,setSectionArray]=useState(['Services','About','Reviews','Offers'])
  const [selectedView,setSelectedView]=useState(paramsLocation.state ? (paramsLocation.state.fromOffers ? 3 : 0) : 0)
  const [searchTextService, setSearchTextService] = useState('')
  const [searchDropdownArray, setSearchDropdownArray] = useState([])
  const [showSearchDropDown, setShowSearchDropDown] = useState(false)
  const [clickOnServiceSearch, setClickOnServiceSearch] = useState(false)
  const [didClearSearch, setDidClearSearch] = useState(false)
  const [state,dispatch]=useContext(globalState)
  const [categoryCount, setCategoryCount] = useState(0)
  const [coupons, setCoupons] = useState([])
  const [ratings1, setRatings1] = useState(0)
  const [ratings2, setRatings2] = useState(0)
  const [ratings3, setRatings3] = useState(0)
  const [ratings4, setRatings4] = useState(0)
  const [ratings5, setRatings5] = useState(0)
  const [colourBack, setColourBack] = useState(['#ff5d5d', '#6750a3', '#ffffff'])
  const [totalRatings, setTotalRatings] = useState(0)
  const [latestCoupon, setLatestCoupon] = useState({})
  const [discountCardArray, setDiscountArray] = useState([])
  const [checked, setChecked] = useState([])
  const [wishlistPress, setWishlistPress] = useState(false)
  const [proLatitude, setProLatitude] = useState("")
  const [proLongitude, setProLongitude] = useState("")
  const [businessHours, setBusinessHours] = useState([])
  const [loading,setLoading]=useState(true)
  const [loading1,setLoading1]=useState(true)
  const [serviceDetailsModal,setServiceDetailsModal]=useState(false)
  const [serviceDetails,setServiceDetails]=useState()
  const [cartAmount, setCartAmount] = useState(0)
  const [couponLoading,setCouponLoading]=useState(true)
  const [sortRatings, setSortRatings] = useState(false)
  const [sortDiscount, setSortDiscount] = useState(false)
  const [brokenLink,setBrokenLink]=useState(false)
  const [searchParams,setSearchParams]=useSearchParams()
  const [wishlistProChild,setWishlistProChild]=useState(false)
  const [redirectBannerModal,setRedirectBannerModal]=useState(false)
  let navigate=useNavigate()
  useEffect(()=>{
    console.log("paramsLocation",paramsLocation,window.history)
   fecthData()
  },[])
  const fecthData= async()=>{
    let catCount=0
    // Params services
    // let offersData;
    // if(paramsLocation.state && paramsLocation.state.offers){
    //   if(paramsLocation.state.singleService){
    //     offersData=paramsLocation.state ? (paramsLocation.state.offers ? paramsLocation.state.offers : []) : []
    //     const index = offersData.findIndex(x => x.QF_MERCHANT_SERVICE_PRID ===paramsLocation.state.singleService[0].QF_MERCHANT_SERVICE_PRID);
    //     let removedItem=offersData.splice(index,1)
    //     offersData.splice(0,0,paramsLocation.state.singleService[0])
    //   }else{
    //       offersData=paramsLocation.state ? (paramsLocation.state.offers ? paramsLocation.state.offers : []) : []
    //   }
    // }
    // console.log('paramsLocation.state',paramsLocation.state)
    if(paramsLocation.state){
      // global services
      setBrokenLink(false)
      let offersData; let offersData1;
      // console.log('state services',paramsLocation.state)
      // if(state.services.length!==0){
      //   let services=DeepClone(state.services)
      //   services.map(item=>{
      //     // console.log(item,'item')
      //     if(paramsLocation.state.fromPfu){
      //       if(item[0].QF_MERCHANT_PERSONNEL_PRID == proData.QF_MERCHANT_PERSONNEL_PRID){
      //         offersData=item.filter((serviceItem,serviceId)=>{
      //           if (paramsLocation.state.subcatId) {
      //             return serviceItem.QF_SERVICE_SUB_CATEGORY_PRID == paramsLocation.state.subcatId
      //           }
      //           else {
      //             return serviceItem.QF_SERVICE_CATEGORY_PRID == paramsLocation.state.catId
      //           }
      //         })
      //       }
      //     }
      //     else{
      //         if(item[0].QF_MERCHANT_PERSONNEL_PRID == proData.QF_MERCHANT_PERSONNEL_PRID){
      //         // offersData=item
      //         if(paramsLocation.state.singleService){
      //           offersData=item
      //           const index = offersData.findIndex(x => x.QF_MERCHANT_SERVICE_PRID ===paramsLocation.state.singleService[0].QF_MERCHANT_SERVICE_PRID);
      //           let removedItem=offersData.splice(index,1)
      //           offersData.splice(0,0,paramsLocation.state.singleService[0])
      //         }else{
      //             offersData=item
      //         }
      //       }
      //     }
      //   })
      // }
      if(paramsLocation.state && paramsLocation.state.offers){
        console.log('params',paramsLocation.state)
        if(state.services.length === 0){
          storeServices(paramsLocation.state.allServices,paramsLocation.state.categories,paramsLocation.state.subCategories,dispatch)
        }
        if(state.address.totalAddress ===  null){
          console.log('params',paramsLocation.state.addressDetails)
          let addressDetails=paramsLocation.state.addressDetails
          storeAddress(addressDetails.totalAddress,addressDetails.addressLine1,addressDetails.country,addressDetails.city,addressDetails.stateOrProvince,addressDetails.zipcode,addressDetails.district,addressDetails.latitude,addressDetails.longitude,addressDetails.currentAddress,addressDetails.searchAddress,addressDetails.currentLocationDenied,dispatch,state)
        }
          if(paramsLocation.state.singleService){
          offersData=paramsLocation.state ? (paramsLocation.state.offers ? paramsLocation.state.offers : []) : []
          const index = offersData.findIndex(x => x.QF_MERCHANT_SERVICE_PRID ===paramsLocation.state.singleService[0].QF_MERCHANT_SERVICE_PRID);
          let removedItem=offersData.splice(index,1)
          offersData.splice(0,0,paramsLocation.state.singleService[0])
        }else{
            offersData=paramsLocation.state ? (paramsLocation.state.offers ? paramsLocation.state.offers : []) : []
        }
      }  
      // console.log(offersData,'offersData')
  
      let body = {
        // uniqueId: DeviceInfo.getUniqueId(),
        countryName: proData.MERCHANT_PERSONNEL_ADDRESS_COUNTRY ? proData.MERCHANT_PERSONNEL_ADDRESS_COUNTRY : state.country.country,
        mpid: proData.QF_MERCHANT_PERSONNEL_PRID,
        mid: proData.QF_MERCHANT_PRID
    }
      console.log('use effect in pro',body)
      let proBusinessHoursData = await customFetchData('POST', body, '/website/pros/businesshours/sv2', state, dispatch)
          if (proBusinessHoursData.fetchedData.data) {
            console.log('')
              if(proBusinessHoursData.fetchedData.businessHours){
                  setLoading1(false)
                  setBusinessHours(proBusinessHoursData.fetchedData.businessHours)
              }else{
                  setLoading1(false)
                  setBusinessHours([])
              }
          }else{
              setLoading1(false)
              setBusinessHours([])
          }
         
          if (offersData.length!==0) {
            if (paramsLocation.state.fromMapClick) {
              setWishlistPress(paramsLocation.state.wishlistFlag ? true : false)
            } else {
              // if (proData.WISHLIST_DELETED_FLAG === 0) {
              //     setWishlistPress(true)
              // }
              console.log('paramsLocation.state.fromPucWishList',paramsLocation.state.fromPucWishList,proData.wishlist_Pro,)
              // if(paramsLocation.state.fromPucWishList){
                if(paramsLocation.state.proChecked){
                  setWishlistPress(true)
                }
                else{
                  setWishlistPress(false)
                }
              // }
              // else if(proData.wishlist_Pro === 0){
              //   setWishlistPress(true)
              // }
              // if (proData.wishlist_Pro === 0 || paramsLocation.state.proChecked) {
              //   // || checkedPro['checkedPro_'+ proData.QF_MERCHANT_PERSONNEL_PRID]) {
              //     console.log('checked status',paramsLocation.state.proChecked,proData.wishlist_Pro)
              //   setWishlistPress(true)
              // }
            }
              let servicePridArr = []
              offersData.map(item => {
                  return servicePridArr.push(item.QF_MERCHANT_SERVICE_PRID)
              })
              // console.log('data',servicePridArr)
              if (servicePridArr.length !== 0) {
                  let body = {
                      servicePridArr: servicePridArr,
                      consumer_prid:state.user.id,
                      wishlistType: 'service',
                      mpprid: proData.QF_MERCHANT_PERSONNEL_PRID
                  }
                  let wishListedData = await customFetchData('POST', body, '/website/wishlistedProServices/sv2', state, dispatch)
                  // console.log('wish list',wishListedData)
                  if (wishListedData.fetch) {
                      if (wishListedData.fetchedData.wishlistRes.length !== 0) {
                          console.log('entered')
                          offersData.map(item => {
                              wishListedData.fetchedData.wishlistRes.map(item1 => {
                                  if (item.QF_MERCHANT_SERVICE_PRID === item1.QF_MERCHANT_SERVICE_PRID) {
                                      // console.log('wish list values',item,item1)
                                      return (
                                          item.QF_WISHLIST_PRID = item1.QF_WISHLIST_PRID,
                                          item.WISHLIST_DELETED_FLAG = item1.WISHLIST_DELETED_FLAG
                                      )
                                  }
                              })
                          })
                          offersData.map(item11 => {
                              if (item11.WISHLIST_DELETED_FLAG === 0) {
                                  return checked['checked_' + item11.QF_MERCHANT_SERVICE_PRID] = true
                              } else {
                                  return checked['checked_' + item11.QF_MERCHANT_SERVICE_PRID] = false
                              }
                          })
                          setLoading(false)
                          setServices(offersData)
                          setFilteredArray(offersData)
                      } else {
                          setLoading(false)
                          setServices(offersData)
                          setFilteredArray(offersData)
                      }
                  } else {
                      setLoading(false)
                      setServices(offersData)
                      setFilteredArray(offersData)
                  }
              }else{
                  setServices([])
                  setLoading(false)
                  setFilteredArray([])
              }
              setProLatitude(proData.MERCHANT_LOCATION_LATITUDE)
              setProLongitude(proData.MERCHANT_LOCATION_LONGITUDE)
              if(offersData.some(eachItem=> ( eachItem.category_name=="Home Care" || eachItem.category_name=="Tutoring"))){
                  catCount = catCount + 1;
              }
              setCategoryCount(catCount)
          }else{
              setServices([])
              setLoading(false)
              setFilteredArray([])
          }
  
          let proRatingResults = await customFetchData('POST', body, '/website/pros/ratings/sv2', state, dispatch);
          if (proRatingResults.fetch) {
              if (proRatingResults.fetchedData.data) {
                  setRatings1(proRatingResults.fetchedData.ratings1)
                  setRatings2(proRatingResults.fetchedData.ratings2)
                  setRatings3(proRatingResults.fetchedData.ratings3)
                  setRatings4(proRatingResults.fetchedData.ratings4)
                  setRatings5(proRatingResults.fetchedData.ratings5)
                  setTotalRatings(proRatingResults.fetchedData.totalRatings)
  
              }
          }
          setSearchTextService('')
          setShowSearchDropDown(false)
          let body1 = {
            mid: proData.QF_MERCHANT_PRID
        }
        setCouponLoading(true)
        let proCouponDetails = await customFetchData('POST', body1, '/website/pros/coupons/sv2', state, dispatch);
        if (proCouponDetails.fetchedData.data) {
            if(proCouponDetails.fetchedData.proCoupons.length!==0){
                setCouponLoading(false)
                setCoupons(proCouponDetails.fetchedData.proCoupons)
                setLatestCoupon(proCouponDetails.fetchedData.latestCoupon)
            }else{
                setCouponLoading(false)
                setCoupons([])
                // setLatestCoupon(proCouponDetails.fetchedData.latestCoupon)
            }
        }else{
            setCouponLoading(false)
            setCoupons([])
        }
    }else{
      console.log('state in deep link check ',state)
      let searchUrl = paramsLocation.search
      // console.log('searchUrl', searchUrl)
      let regex = /[?&]([^=#]+)=([^&#]*)/g, searchParams = {}, match
      while ((match = regex.exec(searchUrl))) {
        searchParams[match[1]] = match[2]
      }
      // console.log('cccc', searchParams,searchParams.proDetail)
      if(searchParams.proId){
        setSelectedView(
          searchParams.offers ? 3 :  (searchParams.reviews ? 2 : (searchParams.about ? 1 : 0))
        )
        let proId;
        if(state.address.latitude === null && state.address.longitude === null && state.address.stateOrProvince === null && state.address.city === null){
        let currentLocationFetch=await FetchCurrentLocation(state,dispatch)
        // console.log('currentLocationFetch',currentLocationFetch)
        let { city,curr_location_latitude,curr_location_longitude,stateOrProvince }=currentLocationFetch
          proId={
            curr_city: city,
            curr_lat: curr_location_latitude,
            curr_long: curr_location_longitude,
            stateOrProvince,
            mpid:Number(searchParams.proId),
            consumerId:state.user.id
          }
        }else{
          proId={
            curr_city: state.address.city,
            curr_lat: state.address.latitude,
            curr_long: state.address.longitude,
            stateOrProvince:state.address.stateOrProvince,
            mpid:Number(searchParams.proId),
            consumerId:state.user.id
          }
        }
        if(Object.keys(proId).length!==0){
          // console.log(proId)
          let servicesFetchData=await customFetchData('POST',proId, '/website/pros/services/sv2', state, dispatch)
          // console.log(servicesFetchData,'servicesFetchData')
          if(servicesFetchData.fetch){
            if(servicesFetchData.fetchedData.data){
              if(servicesFetchData.fetchedData.offers.length!==0){
                let proData1=servicesFetchData.fetchedData.offers[0]
                setProData(proData1)
                let body11={
                  mpidArr:[proData1.QF_MERCHANT_PERSONNEL_PRID],
                  consumer_prid:state.user.id,
                  wishlistType:'professional'
                }
                let wishListedData=await customFetchData('POST',body11,'/website/wishlistedPros/sv2',state,dispatch)
                if(wishListedData.fetch){
                  // console.log(wishListedData.fetchedData.wishlistRes)
                 if(wishListedData.fetchedData.wishlistRes.length!==0){
                   if(wishListedData.fetchedData.wishlistRes[0].QF_MERCHANT_PERSONNEL_PRID === proData1.QF_MERCHANT_PERSONNEL_PRID){
                     setWishlistPress(true)
                   }
                 }else{
                  setWishlistPress(false)
                 }
                }
                let offersData=servicesFetchData.fetchedData.offers
                // console.log('offersData',offersData)
                let body = {
                  // uniqueId: DeviceInfo.getUniqueId(),
                  countryName: proData1.MERCHANT_PERSONNEL_ADDRESS_COUNTRY ? proData1.MERCHANT_PERSONNEL_ADDRESS_COUNTRY : state.country.country,
                  mpid: proData1.QF_MERCHANT_PERSONNEL_PRID,
                  mid: proData1.QF_MERCHANT_PRID
              }
                // console.log('use effect in pro',body)
                let proBusinessHoursData = await customFetchData('POST', body, '/website/pros/businesshours/sv2', state, dispatch)
                    if (proBusinessHoursData.fetchedData.data) {
                      console.log('')
                        if(proBusinessHoursData.fetchedData.businessHours){
                            setLoading1(false)
                            setBusinessHours(proBusinessHoursData.fetchedData.businessHours)
                        }else{
                            setLoading1(false)
                            setBusinessHours([])
                        }
                    }else{
                        setLoading1(false)
                        setBusinessHours([])
                    }
                   
                    if (offersData.length!==0) {
                      // if (paramsLocation.state.fromMapClick) {
                      //   setWishlistPress(paramsLocation.state.wishlistFlag ? true : false)
                      // } else {
                      //   // if (proData.WISHLIST_DELETED_FLAG === 0) {
                      //   //     setWishlistPress(true)
                      //   // }
                      //   if (proData.wishlist_Pro === 0 || paramsLocation.state.proChecked) {
                      //     // || checkedPro['checkedPro_'+ proData.QF_MERCHANT_PERSONNEL_PRID]) {
                      //     setWishlistPress(true)
                      //   }
                      // }
                        let servicePridArr = []
                        offersData.map(item => {
                            return servicePridArr.push(item.QF_MERCHANT_SERVICE_PRID)
                        })
                        // console.log('data',servicePridArr)
                        if (servicePridArr.length !== 0) {
                            let body = {
                                servicePridArr: servicePridArr,
                                consumer_prid:state.user.id,
                                wishlistType: 'service',
                                mpprid: proData1.QF_MERCHANT_PERSONNEL_PRID
                            }
                            let wishListedData = await customFetchData('POST', body, '/website/wishlistedProServices/sv2', state, dispatch)
                            // console.log('wish list',wishListedData)
                            if (wishListedData.fetch) {
                                if (wishListedData.fetchedData.wishlistRes.length !== 0) {
                                    console.log('entered')
                                    offersData.map(item => {
                                        wishListedData.fetchedData.wishlistRes.map(item1 => {
                                            if (item.QF_MERCHANT_SERVICE_PRID === item1.QF_MERCHANT_SERVICE_PRID) {
                                                // console.log('wish list values',item,item1)
                                                return (
                                                    item.QF_WISHLIST_PRID = item1.QF_WISHLIST_PRID,
                                                    item.WISHLIST_DELETED_FLAG = item1.WISHLIST_DELETED_FLAG
                                                )
                                            }
                                        })
                                    })
                                    offersData.map(item11 => {
                                        if (item11.WISHLIST_DELETED_FLAG === 0) {
                                            return checked['checked_' + item11.QF_MERCHANT_SERVICE_PRID] = true
                                        } else {
                                            return checked['checked_' + item11.QF_MERCHANT_SERVICE_PRID] = false
                                        }
                                    })
                                    setLoading(false)
                                    setServices(offersData)
                                    setFilteredArray(offersData)
                                } else {
                                    setLoading(false)
                                    setServices(offersData)
                                    setFilteredArray(offersData)
                                }
                            } else {
                                setLoading(false)
                                setServices(offersData)
                                setFilteredArray(offersData)
                            }
                        }else{
                            setServices([])
                            setLoading(false)
                            setFilteredArray([])
                        }
                        setProLatitude(proData1.MERCHANT_LOCATION_LATITUDE)
                        setProLongitude(proData1.MERCHANT_LOCATION_LONGITUDE)
                        if(offersData.some(eachItem=> ( eachItem.category_name=="Home Care" || eachItem.category_name=="Tutoring"))){
                            catCount = catCount + 1;
                        }
                        setCategoryCount(catCount)
                    }else{
                        setServices([])
                        setLoading(false)
                        setFilteredArray([])
                    }
            
                    let proRatingResults = await customFetchData('POST', body, '/website/pros/ratings/sv2', state, dispatch);
                    if (proRatingResults.fetch) {
                        if (proRatingResults.fetchedData.data) {
                            setRatings1(proRatingResults.fetchedData.ratings1)
                            setRatings2(proRatingResults.fetchedData.ratings2)
                            setRatings3(proRatingResults.fetchedData.ratings3)
                            setRatings4(proRatingResults.fetchedData.ratings4)
                            setRatings5(proRatingResults.fetchedData.ratings5)
                            setTotalRatings(proRatingResults.fetchedData.totalRatings)
            
                        }
                    }
                    setSearchTextService('')
                    setShowSearchDropDown(false)
                    let body1 = {
                      mid: proData1.QF_MERCHANT_PRID
                  }
                  setCouponLoading(true)
                  let proCouponDetails = await customFetchData('POST', body1, '/website/pros/coupons/sv2', state, dispatch);
                  if (proCouponDetails.fetchedData.data) {
                      if(proCouponDetails.fetchedData.proCoupons.length!==0){
                          setCouponLoading(false)
                          setCoupons(proCouponDetails.fetchedData.proCoupons)
                          setLatestCoupon(proCouponDetails.fetchedData.latestCoupon)
                      }else{
                          setCouponLoading(false)
                          setCoupons([])
                          // setLatestCoupon(proCouponDetails.fetchedData.latestCoupon)
                      }
                  }else{
                      setCouponLoading(false)
                      setCoupons([])
                  }
              }
            }else{
              setServices([])
              setLoading(false)
              setFilteredArray([])
            }
          }else{
            setServices([])
            setLoading(false)
            setFilteredArray([])
          } 
          setBrokenLink(false)
        }
      }else{
        setServices([])
        setLoading(false)
        setFilteredArray([])
        setCouponLoading(false)
        setCoupons([])
        setLoading1(false)
        setBusinessHours([])
        setBrokenLink(true)
      }
    }
  }
  useEffect(() => {
    // console.log("state cart count in use effect",state.cartCount)
    let cart = [...state.cart], tempCartAmount = 0
    cart.map(item => {
        tempCartAmount += item.service_offer_price
    })
    setCartAmount(tempCartAmount)
}, [state.cart])
useEffect(()=>{
  let fromDateTime,toDateTime;
  if(Object.keys(state.fromDateTime).length===0)
  {
      //// setting from date in global state if already not set
      // dispatch({type:'SET_FROM_DATE_TIME',fromDateTime:moment().tz(state.country.timezone).set({ 'hour': 0, 'minute': 0 })})
      fromDateTime=moment().tz(state.country.timezone).set({ 'hour': 0, 'minute': 0 })
  }
  if(Object.keys(state.toDateTime).length===0)
  {
      //// setting to date in global state if already not set
      toDateTime=moment().tz(state.country.timezone).set({ 'hour': 23, 'minute': 59 }).add(14, "days")
      // dispatch({type:'SET_TO_DATE_TIME',toDateTime:moment().tz(state.country.timezone).set({ 'hour': 23, 'minute': 59 }).add(14, "days")})
  }
  if( fromDateTime && toDateTime ){
    storeDateAndTime(fromDateTime,toDateTime,dispatch)
  }
},[])

  const searchBoxServiceHandler = (event) => {
    try {
        console.log("value", event.target.value)
        let value=event.target.value
        setSearchTextService(value)
        let filteredOffers = DeepClone(filteredArray), matchesArr = []
        value = value ? value.trim() : value
        if (value.length > 2) {
            setShowSearchDropDown(true)
            // console.log("filtered", filteredOffers)
            filteredOffers.map((insideItem) => {
                if (insideItem.SERVICE_NAME.search(new RegExp(value, 'i')) !== -1) {
                    matchesArr.push(insideItem)
                }
            })
            matchesArr = matchesArr.filter((v, i, a) => a.findIndex(t => ((t.SERVICE_NAME === v.SERVICE_NAME))) === i)
            setSearchDropdownArray(matchesArr)
            // console.log("matchesArr", matchesArr);
        }
        else {
            setShowSearchDropDown(false)
            setClickOnServiceSearch(false)
            if (!didClearSearch) {
                setDidClearSearch(true)
            }
            setSearchDropdownArray([])
            setFilteredArray(services)        
        }
    }
    catch (cErr) {
        console.log(cErr)
    }
}
const onPressSearchDropDown = (data) => {
  // console.log('data',data)
    setSearchTextService(data.SERVICE_NAME)
    setShowSearchDropDown(false)

    let filteredOffers = DeepClone(filteredArray), matchesArr = []

    filteredOffers.map((insideItem) => {
        if (insideItem.SERVICE_NAME === data.SERVICE_NAME) {
            matchesArr.push(insideItem)
        }
    })
    setFilteredArray(matchesArr)
    setClickOnServiceSearch(true)
    setDidClearSearch(false)
    // matchesArr=matchesArr.filter((v,i,a)=>a.findIndex(t=>( (t.SERVICE_NAME === v.SERVICE_NAME) ))===i)
    // console.log("matchesArr after click",matchesArr);
    // setFilteredArray(matchesArr)
}
const clearSearchResults = () => {
  setClickOnServiceSearch(true)
  setDidClearSearch(true)
  setShowSearchDropDown(false)
  setSearchTextService('')
  setSearchDropdownArray([])
  setFilteredArray(services)        
}
const copyClipHandler = () => {
  // let code=paramsLocation.pathname + paramsLocation.search
  let code=window.location.href
  navigator.clipboard.writeText(code)
  toast(
    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      <text>Copied to clipboard</text> 
    </div>,
    {
      position:'bottom-center',
      hideProgressBar: true,
      progress: undefined,
      closeButton:false,
      style:{backgroundColor:'#E0D1FF'}
  });

}
  let MerchantItems =[
    {
        key: 1,
        src:{
            // web:NewImageMer,
            // mobile:NewImageMerMobile
        },
        altText: (
          <div className='ProDetailsView_OnBanner'>
              <div style={{display:'flex',flexDirection:'column'}}>
                <div style={{marginTop:5}}>
                  <text className='proNameStylesv2_Pro'>
                     {proData.MERCHANT_NAME}
                  </text>           
                </div>
                <div style={{marginTop:5}}>
                  <text className='addressTextsv2_Pro'>
                    {categoryCount == 0 ? proData.MERCHANT_PERSONNEL_ADDRESS_LINE2 :
                    ( proData.MERCHANT_PERSONNEL_ADDRESS_CITY + ", " + proData.MERCHANT_PERSONNEL_ADDRESS_STATE + ", " + proData.MERCHANT_PERSONNEL_ADDRESS_ZIPCODE)}
                  </text>            
                </div> 
               { 
               !brokenLink &&
               <div style={{marginTop:5,display: 'flex',alignItems:'center'}}>
                  <img src={DistanceIcon}/>
                  <text className='distanceTextsv2_Pro'>
                    {proData.SERVICE_CURRENCY=='Rupees' ? numeral(proData.distance).divide(1000).format('0.00') + ' km away' : numeral(proData.distance).divide(1609.344).format('0.00') + ' mi away'}
                  </text>
                </div>
                }
                <div style={{marginTop:5,flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                  <div style={{ display: 'flex',alignItems:'center'}}>
                    { proData.MERCHANT_PERSONNEL_RATING && <img src={StarIcon} style={{width:16,height:16}}/> }
                    <text className='distanceTextsv2_Pro'>
                      {proData.MERCHANT_PERSONNEL_RATING ? numeral(proData.MERCHANT_PERSONNEL_RATING).format('0.0') : ''}
                    </text>
                  </div>
                  <div style={{ flexDirection: 'row', display: 'flex'}}>
                  <div className={"wishlistBg_Web"} style={{cursor:'pointer'}}>
                    {
                      isMobile ? <img src={ShareIcon} style={{ width: 15, height: 15 }} onClick={()=>copyClipHandler()}/> : <img src={ShareIconColored} style={{ width: 15, height: 15 }} onClick={()=>copyClipHandler()}/>
                    }
                  </div>
                  { (state.token && !brokenLink)  && 
                  <div className={"wishlistBg_Web"} onClick={()=>ProWishlistPress(proData)} style={{ marginLeft: 10,cursor:'pointer' }}>
                    {
                      isMobile ? (wishlistPress ? <img src={WishlistYes} style={{ width: 18, height: 18 }} /> : <img src={WishlistIconGrey} style={{ width: 18, height: 18 }} />) : (wishlistPress ?  <img src={WishlistYes} style={{ width: 18, height: 18 }} /> : <img src={WishlistIcon} style={{ width: 18, height: 18 }} />)
                    }
                  </div>}
                </div>
                </div>
              </div>
          </div>
        )
    }
  ]
  let MerchantSlides = MerchantItems.map((item) => {
    return (
       
            <CarouselItem
                // onExiting={this.onExiting}
                // onExited={this.onExited}
                key={item.key}
            >
                <div className="CarousalContainer_Sv2_Pro" style={{  background: 'linear-gradient(to top, rgba(0, 0, 0) 100%, rgba(0, 0, 0) 0%)'}}>
                    {item.altText}

                    <img className='imgStylesv2_Pro' src={isMobile ? "https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684328184527-lg.png" : "https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684392573279-lg.png"}/>
                    {/* <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1666875231964-lg.jpg'} className='Carousal_policy_mobile'/> */}
                </div>
            </CarouselItem>
        
    );
  });
  const activeNavHeader={
    borderBottom:'4px solid #a5d028',
    color:"#261640",
    fontWeight:700,
    fontFamily: 'Inter',
    // border:'1px solid black',
}
const next = () => {
  // if (animating) return;
  const nextIndex = activeIndex === MerchantItems.length - 1 ? 0 : activeIndex + 1;
  setActiveIndex(nextIndex);
};

const previous = () => {
  // if (animating) return;
  const nextIndex = activeIndex === 0 ? MerchantItems.length - 1 : activeIndex - 1;
  setActiveIndex(nextIndex);
};

const sectionSelection = (value) =>{
  setSelectedView(value)
  let paramValue=value===0 ? 'services' : (value===1 ? 'about' : (value===2 ? 'reviews' : (value===3 ? 'offers':'services')))
  if(paramValue === 'services'){
    setSearchParams({['proId']:proData.QF_MERCHANT_PERSONNEL_PRID})
  }else{
    setSearchParams({['proId']:proData.QF_MERCHANT_PERSONNEL_PRID,[paramValue]: true})
  }
}
const serviceDetailsModalShow = (item) =>{
  setServiceDetailsModal(true)
  setServiceDetails(item)
}
const serviceDetailsModalClose =()=>{
  setServiceDetailsModal(false)
  setServiceDetails()
}
let activeStyleForSelection={
  color:'#8c52ff',
  fontFamily: 'Inter',
  fontSize: isMobile ? '14px' : '16px',
  fontWeight: 600,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  borderBottom: '1.5px solid #8c52ff',
  paddingBottom:10,
}
let searchOfferDropDownItem;
   if (filteredArray.length !== 0 && showSearchDropDown) {
     if (searchDropdownArray.length !== 0) {
      searchOfferDropDownItem= searchDropdownArray.map((item,index)=>{
         return (
            <div style={{zIndex:100}}>
                  <div onClick={() => onPressSearchDropDown(item)} style={{cursor:'pointer', backgroundColor: '#fff', borderBottom:'1px solid rgba(216, 197, 252, 0.6)', paddingTop: 10, paddingBottom: 10 }}>
                     <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 25 }}>
                        <div className={"imageProStyle"}>
                           <img src={item.image_link} style={{ width: 44, height: 44, borderRadius: 7 }} />
                        </div>
                        <div style={{ display: 'flex',flexDirection:'column', justifyContent: 'center', marginLeft: 22 }}>
                           <text className={"searchDropDowntextStyle"}>{item.SERVICE_NAME}</text>
                           <text className={"searchDropDownSubtextStyle"}>Service</text>
                        </div>
                     </div>
                  </div>
            </div>
         )
      })
     }
     else {
         searchOfferDropDownItem = (
             <div style={{ marginLeft: 20,marginRight:20, height: 150, justifyContent: "center", alignItems: "center", zIndex: 2000 ,display:'flex'}}>
                 <text style={{ fontSize: 12 }}>{"No results matching your search"}</text>
             </div>
         )
     }
 }
 const navToLoginOrCheckout=()=>{
  navigate('/checkout',{state:{
    categories: DeepClone(state.categories), subCategories: DeepClone(state.subcategories),
    addressDetails:
    {
      totalAddress: state.address.totalAddress,
      addressLine1: state.address.addressLine1,
      country: state.address.country,
      city: state.address.city,
      stateOrProvince: state.address.stateOrProvince,
      zipcode: state.address.zipcode,
      district: state.address.district,
      latitude: state.address.latitude,
      longitude: state.address.longitude,
      currentAddress: state.address.currentAddress,
      searchAddress: state.address.searchAddress,
      currentLocationDenied: state.address.currentLocationDenied
    },
    allServices: DeepClone(state.services)
  }})
}
const onClickWishChild = (value) => {
  setWishlistProChild(value)
}
 let serviceDetailsDisplayModal=(
  <Modal isOpen={serviceDetailsModal} size={'md'}  toggle={()=>serviceDetailsModalClose()} centered>
    <div style={{padding:16}}>
      <div style={{justifyContent:'flex-end',display:'flex',flexDirection:'row'}}>
        <div className='crossIcon_ViewPro' style={{cursor:'pointer'}}>
          <img src={CloseIcon} style={{width:18,height:18}} onClick={()=>serviceDetailsModalClose()}/>
        </div>
      </div>
      <div style={{marginTop:20}}>
        <ServiceDetails proData={proData} serviceDetails={serviceDetails} onClickWishChild={onClickWishChild} wishlisted={serviceDetails ? checked['checked_' + serviceDetails.QF_MERCHANT_SERVICE_PRID] ? true : false : null}/>
      </div>
    </div>
  </Modal>
 )
 
 const WishlistPressHandler = async (item) => {
  console.log("pressed", item)
  services.map(list => {
      if (list.QF_MERCHANT_SERVICE_PRID === item.QF_MERCHANT_SERVICE_PRID) {
          return setChecked((prevState) => {
              return {
                  ...prevState,
                  ['checked_' + list.QF_MERCHANT_SERVICE_PRID]: !checked['checked_' + item.QF_MERCHANT_SERVICE_PRID]
              }
          })
      }
  })
  let body = {
      // cprid:item.QF_CONSUMER_PRID,
      cprid: state.user.id,
      mpprid: item.QF_MERCHANT_PERSONNEL_PRID,
      wishLstType: 'service',
      servicePrid: item.QF_MERCHANT_SERVICE_PRID
  }
  let WishlistInsert = await customFetchData('POST', body, '/website/add/wishList/sv2', state, dispatch)
  // console.log('wish list response', WishlistInsert)
}
const ProWishlistPress = async (proData) => {
  setWishlistPress(!wishlistPress)
  let body = {
      // cprid:item.QF_CONSUMER_PRID,
      cprid:state.user.id,
      mpprid: proData.QF_MERCHANT_PERSONNEL_PRID,
      wishLstType: 'professional',
  }
  let WishlistInsert = await customFetchData('POST', body, '/website/add/wishList/sv2', state, dispatch)
  // console.log('wish list response in single pro', WishlistInsert)
}
const NavToserviceDetails_Mobile=(item)=>{
  navigate({pathname:`/servicedetails/${item.SERVICE_NAME.replaceAll(/[&\/\\#, +()$~%.'":*?<>{}]/g, "")}` ,search:`proId=${item.QF_MERCHANT_PERSONNEL_PRID}&serviceId=${item.QF_MERCHANT_SERVICE_PRID}`},{state:{proData:proData,serviceDetails:item,wishlisted:checked['checked_' + item.QF_MERCHANT_SERVICE_PRID] ? true : false,
  categories:DeepClone(state.categories),subCategories:DeepClone(state.subcategories),
  addressDetails:
{
  totalAddress: state.address.totalAddress,
  addressLine1: state.address.addressLine1,
  country: state.address.country,
  city: state.address.city,
  stateOrProvince: state.address.stateOrProvince,
  zipcode: state.address.zipcode,
  district: state.address.district,
  latitude: state.address.latitude,
  longitude: state.address.longitude,
  currentAddress: state.address.currentAddress,
  searchAddress: state.address.searchAddress,
  currentLocationDenied: state.address.currentLocationDenied
},
allServices:DeepClone(state.services)}})
}
// console.log('checked',checked,serviceDetails)
let serviceCards;
if(services.length!==0){
  if(filteredArray.length!==0){
    serviceCards=filteredArray.map(item => {
      return (
        isMobile ?
          <div style={{ marginTop: 10 }}>
            <ServicesCard  onClickCard={()=>NavToserviceDetails_Mobile(item)} data={item} wishlisted={checked['checked_' + item.QF_MERCHANT_SERVICE_PRID] ? true : false} WishlistPress={() => WishlistPressHandler(item)}/>
          </div>
          :
          <div style={{ marginTop: 10 }}>
            <ServicesCard_Web onClickCard={()=>serviceDetailsModalShow(item)} data={item} wishlisted={checked['checked_' + item.QF_MERCHANT_SERVICE_PRID] || wishlistProChild ? true : false} WishlistPress={() => WishlistPressHandler(item)}/>
          </div>
      )
    })
  }else{
    if(filteredArray.length === 0){
      serviceCards=<div style={{flexDirection:'row',display:'flex',justifyContent:'center',alignItems:'center',height:200}}>
        <text>
          No Services To Display
        </text>
      </div>
    }
  }
}else{
  if(services.length === 0 && loading){
    serviceCards=<div style={{flexDirection:'row',display:'flex',justifyContent:'center',alignItems:'center',height:200}}>
      <text>
        No Services To Display
      </text>
    </div>
  }
}
let couponsData;
if(coupons.length!==0){
  couponsData=coupons.map((item,index)=>{
    const colorIndex = index % 3;
    const colourback = colourBack[colorIndex]
    return(
      <DiscountCardColoured
      colour={colourback} 
      onClick={()=>{}} 
      code={item.MERCHANT_COUPON_CODE} 
      date={item.MERCHANT_COUPON_BOOKING_DATE_TO ? item.MERCHANT_COUPON_BOOKING_DATE_TO : null}
      coupon_percent={item.MERCHANT_COUPON_PERCENT_OFF ? true : false} 
      off={item.MERCHANT_COUPON_PERCENT_OFF ? item.MERCHANT_COUPON_PERCENT_OFF : item.MERCHANT_COUPON_VALUE_OFF} 
      country={proData.MERCHANT_PERSONNEL_ADDRESS_COUNTRY ? proData.MERCHANT_PERSONNEL_ADDRESS_COUNTRY =='India' ? 'IN' : 'US' : state.country.country}/>
    )
  })

}else{
  if(coupons.length === 0 && !couponLoading){
    couponsData=<div style={{flexDirection:'row',display:'flex',justifyContent:'center',alignItems:'center',height:200}}>
      <text>
        No offers To Display
      </text>
    </div>
  }
}
const onPressRatings = () =>{
  // console.log(sortRatings)
  if (!sortRatings) {
      setSortRatings(true)
      setSortDiscount(false)
      //// Sorting by ratings - high to low
      if(searchTextService){
          let newOffers=DeepClone(filteredArray)
          // console.log('services', newOffers)
          newOffers.sort((a, b) => {
              return (b.MERCHANT_SERVICE_RATING ? b.MERCHANT_SERVICE_RATING : 0) - (a.MERCHANT_SERVICE_RATING ? a.MERCHANT_SERVICE_RATING : 0)
          });
          setFilteredArray(newOffers)
      }else{
          let newOffers=DeepClone(services)
          // console.log('services', newOffers)
          newOffers.sort((a, b) => {
              return (b.MERCHANT_SERVICE_RATING ? b.MERCHANT_SERVICE_RATING : 0) - (a.MERCHANT_SERVICE_RATING ? a.MERCHANT_SERVICE_RATING : 0)
          });
          setFilteredArray(newOffers)
      }
  }
  else{
      setSortRatings(false)
      setSortDiscount(false)
      if(searchTextService){
          setFilteredArray(filteredArray)
      }else{
          setFilteredArray(services) 
      }
  }
}
const onPressDiscounts = () =>{
  if(!sortDiscount) {
      setSortRatings(false)
      setSortDiscount(true)
      if(searchTextService){
          let newOffers=DeepClone(filteredArray)
          // console.log('services', newOffers)
          newOffers.sort((a, b) => {
              return b.service_discount - a.service_discount
          })
          setFilteredArray(newOffers)
      }else{
          let newOffers=DeepClone(services)
          // console.log('services', newOffers)
          newOffers.sort((a, b) => {
              return b.service_discount - a.service_discount
          })
          setFilteredArray(newOffers)
      }
  }
  else {
      setSortRatings(false)
      setSortDiscount(false)
      if(searchTextService){
          setFilteredArray(filteredArray)
      }else{
          setFilteredArray(services)
      }
  }
}
let SelectedView;
if(selectedView === 0){
      SelectedView=(
        <div>
          <div style={{marginBottom:30,marginTop:30,width:isMobile ? '100%' : '75%',position:'relative'}}>
            <SearchBar
              onChange={searchBoxServiceHandler}
              value={searchTextService}
              searchTextService={searchTextService}
              onSubmitEditing={clearSearchResults}
              onClickCross={clearSearchResults}
              placeholder='Search any Service' 
            />
            {
            showSearchDropDown ? 
            <Card style={{ position: 'absolute', height: searchDropdownArray.length > 0 ? (searchDropdownArray.length === 1 || searchDropdownArray.length === 2 ? 'auto' : 220) : 150, top: 55,left:isMobile ? -30 : 0,right:isMobile ? -30 : 0, zIndex: 2000, width: isMobile ? '':'60%', overflow: 'auto' }}>
              {searchOfferDropDownItem}
            </Card> 
            : null
            }
          </div>
          <div style={{flexDirection:'row',display:'flex',marginBottom:30}}>
            <div className='cardsDiscRating_ProPage' style={{marginRight:30,borderColor:sortRatings ? '#fff' : '#dedede',backgroundColor: sortRatings ? 'rgb(166,121,255)' : '#fff',cursor:'pointer'}} onClick={()=>onPressRatings()}>
              { sortRatings ? <img src={Ratingswhite} style={{width:15,height:15}}/> : <img src={StarIconFilter} style={{width:15,height:15}}/>}
              <text className='cardsDiscRating_ProPage_Text' style={{color:sortRatings ? '#fff' :'#000'}}>Ratings</text>
            </div>
            <div className='cardsDiscRating_ProPage' style={{marginRight:30,borderColor:sortDiscount ? '#fff' : '#dedede',backgroundColor: sortDiscount ? 'rgb(166,121,255)' : '#fff',cursor:'pointer'}} onClick={()=>onPressDiscounts()}>
              { sortDiscount ? <img src={DiscountWhite} style={{width:15,height:15}}/> : <img src={OffersIcon} style={{width:15,height:15}}/> }
              <text className='cardsDiscRating_ProPage_Text' style={{color:sortDiscount ? '#fff' :'#000'}}>Discounts</text>
            </div>
          </div>
          {
            loading ?
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',paddingTop:50}}>
                  <ClipLoader widthUnit={"%"} size={20} loadings={loading} color="#8c52ff" />
               </div>
            :
            serviceCards
          }
        </div>
        
      )
}else if(selectedView === 1){
  const defaultProps = {
    center: {
        lat:proLatitude,
        lng:proLongitude
    },
    zoom: 10
  };
  const defaultMapOptions = {
    fullscreenControl: false,
    zoomControl:false
  };
  SelectedView=(
    <div>
      <div>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <img src={ShopIcon} style={{width:20,height:20}}/>
          <text className='aboutSection_MainText' style={{marginLeft:isMobile ? 10 : 20}}>Shop Name</text>
        </div>
        <div style={{marginTop:25,marginLeft:isMobile ? 33 : 43}}>
          <text className='aboutSection_SubText'>{proData.MERCHANT_NAME}</text>
        </div>
      </div>
      <div style={{marginTop:20}}>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <img src={BusinessHoursIcon} style={{width:20,height:20}}/>
          <text className='aboutSection_MainText' style={{marginLeft:isMobile ? 10 : 20}}>Business Hours</text>
        </div>
        <div style={{marginTop:25,marginLeft:isMobile ? 33 : 43}}>
          <div>
            {
              businessHours.length!==0 &&
              businessHours.map((item,index)=>{
                // {console.log('item',item)}
                  return (
                    <div style={{flexDirection:'row',display:'flex',paddingTop:2,paddingBottom:2}}>
                      <div style={{width:100}}>
                        <text className='aboutSection_SubText'>{item.enName}</text>
                      </div>
                      <div style={{marginLeft:20}}>
                        {
                          item.QF_CAL_AVAILABLE_DAY === 1 ?
                          <text className='timeText_Pro'>{(moment().set({ 'hour': item.QF_CAL_FROM_TIME_HOURS, 'minute': item.QF_CAL_FROM_TIME_MINS })).format("HH:mm A") + " - " + (moment().set({ 'hour': item.QF_CAL_TO_TIME_HOURS, 'minute': item.QF_CAL_TO_TIME_MINS })).format("HH:mm A")}</text>
                          :
                          <text className='timeText_Pro' style={{color:'red'}}>Closed</text>
                        }
                      </div>
                    </div>
                  )
              })
            }
            {
              loading1 && 
              <div style={{display:'flex',flexDirection:'row',alignItems:'center',paddingTop:40}}>
                  <ClipLoader widthUnit={"%"} size={20} loadings={loading1} color="#8c52ff" />
               </div>
            }
          </div>
        </div>
      </div>
      <div style={{marginTop:20}}>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <img src={LocateVisitIcon} style={{width:20,height:20}}/>
          <text className='aboutSection_MainText' style={{marginLeft:isMobile ? 10 : 20}}>Locate & Visit </text>
        </div>
        <div style={{marginTop:25}}>
          <Card style={{boxShadow: '0 2px 4px 0 rgba(103, 52, 204, 0.1)',border: 'solid 1px #eee',backgroundColor: '#fff'}}>
            <div style={{ height: 200, width: '100%',borderRadius:30,position:'relative' }}>
              <GoogleMapReact
                  bootstrapURLKeys={{ key: "AIzaSyC6sHvN36SBHhSQymZ3d17MwtTeql16VbQ" }}
                  center={defaultProps.center}
                  zoom={defaultProps.zoom}
                  options={defaultMapOptions}
                //   ref={mapRef}
              >
                {
                categoryCount === 0 && <div lat={proLatitude} lng={proLongitude}>
                  <IoLocationSharp size={40} color="red"/>
                </div>
                }
              </GoogleMapReact>
               
              {
              (proData.MERCHANT_PERSONNEL_ADDRESS_CITY && categoryCount === 0) &&
              <a href={`https://www.google.com/maps/dir/?api=1&origin=${state.address.latitude},${state.address.longitude}&destination=${proLatitude},${proLongitude}`} target="_blank">
                <div className="openWithMaps_View" >
                  <img src={OpenWithMapsIcon} className='openWithMap_Icon'/>
                  <text className='openWithMap_Text'>Open With Maps</text>
                </div>
              </a>
              }
          
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',padding:'15px 10px 15px 10px'}}>
              <img src={locationcolorIcon} style={{width:22,height:22,marginRight:10}}/>
              {
                categoryCount > 0 ?
                  <text className='addressPro_Text'>
                    {proData.MERCHANT_PERSONNEL_ADDRESS_CITY ? proData.MERCHANT_PERSONNEL_ADDRESS_CITY + ", " : null}
                    {proData.MERCHANT_PERSONNEL_ADDRESS_STATE ? proData.MERCHANT_PERSONNEL_ADDRESS_STATE + ", " : null}
                    {proData.MERCHANT_PERSONNEL_ADDRESS_ZIPCODE ? proData.MERCHANT_PERSONNEL_ADDRESS_ZIPCODE : null}
                  </text>
                  :
                  <text className='addressPro_Text'>
                    {proData.MERCHANT_PERSONNEL_ADDRESS_LINE2 ? proData.MERCHANT_PERSONNEL_ADDRESS_LINE2 + ", " : null}
                    {proData.MERCHANT_PERSONNEL_ADDRESS_LINE3 ? proData.MERCHANT_PERSONNEL_ADDRESS_LINE3 + ", " : null}
                    {proData.MERCHANT_PERSONNEL_ADDRESS_CITY ? proData.MERCHANT_PERSONNEL_ADDRESS_CITY + ", " : null}
                    {proData.MERCHANT_PERSONNEL_ADDRESS_STATE ? proData.MERCHANT_PERSONNEL_ADDRESS_STATE + ", " : null}
                    {proData.MERCHANT_PERSONNEL_ADDRESS_ZIPCODE ? proData.MERCHANT_PERSONNEL_ADDRESS_ZIPCODE : null}
                  </text>
              }
              
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}else if(selectedView === 2){
  SelectedView=(
    <div style={{height:250}}>
      <RatingsAndReviews rating={proData.MERCHANT_PERSONNEL_RATING} ratings1={ratings1} ratings2={ratings2} ratings3={ratings3} ratings4={ratings4} ratings5={ratings5} totalNofRatings={totalRatings} reviews={''} />
    </div>
  )
}else if(selectedView === 3){
  SelectedView=(
    <div>
      { couponLoading ? 
      <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',height:200}}>
        <ClipLoader widthUnit={"%"} size={20} loadings={loading1} color="#8c52ff" />
      </div>
      : couponsData  }
    </div>
  )
}
let sectionsHeader;
if(sectionArray.length!==0){
  sectionsHeader=sectionArray.map((item,index)=>{
    return (
      <div style={{width:isMobile ? '25%' : '15%',paddingBottom:9.5,display:'inline-block',cursor:'pointer'}} onClick={()=>sectionSelection(index)}>
        <text className='sectionHeader_Text' style={ selectedView === index ? activeStyleForSelection : {}}>{item}</text>
      </div>
    )
  })
}
const onClickOffer=()=>{
  setSearchParams({['proId']:proData.QF_MERCHANT_PERSONNEL_PRID,['offers']: true})
  setSelectedView(3)
}

const onClickRedirectBannerMobile = () => {

      let mainUtmURL= paramsLocation.search != "" ? paramsLocation.search.split('?') : []
      let androidUtmURL= mainUtmURL.length != 0 ? mainUtmURL[1] : ''
      let iosUtmURL= paramsLocation.search != "" ? paramsLocation.search : ''

      if(isAndroid){
        try{
            if(androidUtmURL !== ''){
                window.location.href='https://play.google.com/store/apps/details?id=com.quickfinduser'+'&'+androidUtmURL
            } else {
                window.location.href='https://play.google.com/store/apps/details?id=com.quickfinduser'
            }
            // this.setState({loading: false, catch: false})
        } catch(err1){
            console.log("err1", err1);
            // this.setState({catch: true, loading: false})
        }

    } else if(isIOS){
        try{
            window.location.href='https://apps.apple.com/us/app/slash-consumer/id1489207325?ls=1'+iosUtmURL
            // this.setState({loading: false, catch: false})
        } catch(err2){
            console.log("err2", err2);
            // this.setState({catch: true, loading: false})
        }

    } else {
        try{
            window.location.href='https://www.goslash.com'+iosUtmURL
            // this.setState({loading: false, catch: false})
        } catch(err3){
            console.log("err3", err3);
            // this.setState({catch: true, loading: false})
        }

    }
}

// console.log('prodate',paramsLocation.state)

// window.scrollTo(0,0)
  return (
    <div style={{position:'relative',backgroundColor:brokenLink ? '#fff' :''}}>
      
      <div className='mainContainersv2'>
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          ride="carousel"
          interval={2000}
        >
          {MerchantSlides}
        </Carousel>
      </div>
      
      <div className='redirectWebBanner' style={{width:'40%', height:'30%', backgroundColor:'transparent', position:'fixed', zIndex:1000, right:0, bottom:10}} onClick={() => setRedirectBannerModal(true)}>
        <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1726743828440-lg.png'} style={{height:'100%', width:'100%'}}/>
      </div>

      <div className='redirectMobileBanner' style={{width:'100%', height:'13%', borderTopLeftRadius:12, borderTopRightRadius:12, position:'fixed', zIndex:1000, right:0, bottom:0, backgroundColor:'#000000', paddingTop:5, paddingBottom:5, justifyContent:'center', alignItems:'center'}} onClick={() => onClickRedirectBannerMobile()}>
        <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1726743726480-lg.png'} style={{height:'100%', width:'100%', borderTopLeftRadius:12, borderTopRightRadius:12, backgroundColor:'transparent'}}/>
      </div>

      <Modal isOpen={redirectBannerModal} style={{borderRadius:25}} size='lg'>
        <ModalBody style={{borderRadius:25}}>
          <div style={{position:'absolute', right:52, top:50}} onClick={() => {setRedirectBannerModal(false)}}>
            <img src={redirectBannerCrossIcon} style={{width:50, height:50}}/>
          </div>
          <div style={{width:'100%', height:'100%', borderRadius:25}}>
            <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1726809198975-lg.png'} style={{width:'100%', height:'100%'}}/>
          </div> 
        </ModalBody>
      </Modal>

      {
      coupons.length !==0?
        isMobile ?
        <div className="MainReusable_Container_Home" style={{paddingBottom:0}}>
          <ProfessionalDiscountsCards
          colour={"#ff5d5d"}
          onClick={()=>setSelectedView(3)}
          code={latestCoupon.MERCHANT_COUPON_CODE}
          title={latestCoupon.MERCHANT_NAME}
          discount={latestCoupon.MERCHANT_COUPON_PERCENT_OFF ? latestCoupon.MERCHANT_COUPON_PERCENT_OFF : latestCoupon.MERCHANT_COUPON_VALUE_OFF}
          date={latestCoupon.MERCHANT_COUPON_BOOKING_DATE_TO ? latestCoupon.MERCHANT_COUPON_BOOKING_DATE_TO : 'No validity date'}
          coupon_percent={latestCoupon.MERCHANT_COUPON_PERCENT_OFF ? true : false}
          country={proData.MERCHANT_PERSONNEL_ADDRESS_COUNTRY == 'India' ? 'IN' : 'US'}
          />
        </div>
      :
        <div className="MainReusable_Container_Home">
          <div style={{position:'relative',cursor:'pointer'}} onClick={()=>onClickOffer()}>
            <div style={{position:'absolute',top:'20%',bottom:'20%',left:'10%',right:'10%'}}>
              <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',flexDirection:'row',padding:8}}>
                  <div style={{backgroundImage:`url(${WhiteRectangle})`,width:238,height:65,display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'row'}}>
                      <text className='couponText_Latest'>{latestCoupon.MERCHANT_COUPON_CODE}</text>
                  </div>
                  
                  {/* <img src={WhiteRectangle}/> */}
                <text className='couponPer_DiscountText'>{"Upto " + (latestCoupon.MERCHANT_COUPON_PERCENT_OFF ? latestCoupon.MERCHANT_COUPON_PERCENT_OFF + "%" : ((proData.MERCHANT_PERSONNEL_ADDRESS_COUNTRY == 'India' ? "\u20B9" : "$") + latestCoupon.MERCHANT_COUPON_VALUE_OFF)) + ' Off'}</text>
              </div>
            </div>
            <img src={ColorCoupon} style={{width:'100%',height:120}}/>
          </div>

        </div>
        : null
      }
      {
        brokenLink ?
          <div onClick={() => navigate('/')} style={{ flexDirection:'column',backgroundColor:'#fff',display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:40,marginBottom:40 }}>
              <text>Page Not Found, click the below button to go to home</text>
              <Button className='addToCartButton' style={{marginTop:20}}>Back to Home</Button>
          </div>
          :
          <div className="MainReusable_Container_Home">
            <div style={{ overflowX: 'scroll' }}>
              {sectionsHeader}
            </div>
            <hr style={{ marginTop: -1 }} />
            <div style={{ marginTop: 30 }}>
              {SelectedView}
            </div>
          </div>
      }
      {
        (state.cartCount && state.cart.length !== 0 ) ?
          <div className="MainReusable_Container_Home" onClick={navToLoginOrCheckout} style={{height:80,paddingTop:isMobile ? 15 : 20,paddingBottom:15,backgroundImage:' linear-gradient(97deg, #1a67db -2%, #02c5d1 41%, #31b12f 100%)',position:'fixed',zIndex:100,bottom:isMobile ? 0 : 0,left:0,right:0,cursor:'pointer'}}>
              <div style={{ display:'flex',flexDirection: 'row', justifyContent: 'space-between',alignItems:'center' }}>
                <div style={{display:'flex',flexDirection: 'column'}}>
                  <text  style={{maxWidth:isMobile ? 180 : 'auto'}} className='serviceNameText_InCart'>{state.cart[0].SERVICE_NAME + "" + (state.cartCount > 1 ? (" + " + (state.cartCount - 1) + " more") : "")}</text>
                  <text style={{ marginTop:3, fontSize:12 }} className='serviceNameText_InCart'>{proData.MERCHANT_PERSONNEL_ADDRESS_COUNTRY === 'India' ? '\u20B9' : '$'}{cartAmount}</text>
                </div>
                <div style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <text style={{fontWeight:600}} className='serviceNameText_InCart'>Checkout</text>
                  <FaArrowRight  style={{marginLeft:5}} name='arrow-right' color='#fff' size={25} />
                </div>
              </div>
          </div>
          : null
      }
      {serviceDetailsModal && serviceDetailsDisplayModal}
      <ToastContainer/>
    </div>
  )
}
