import React, { Component, Text, useState, useRef, useEffect, useContext } from 'react'
import { Navbar, NavbarBrand, Nav, NavItem, Row, NavbarToggler, Collapse, Col, Modal, FormGroup, Input, Button, FormFeedback ,NavLinkReactor} from 'reactstrap';
import { NavLink } from 'react-router-dom'; 
import logo from '../../../Assets/slashlogo2purple.png'
import '../../../CSS/NavBarLatest_Mobile.css'
// import { NavLink } from 'react-router-dom';
import favouriteLogo from '../../../Assets/icon-favorite-border.png'
import cartLogo from '../../../Assets/mi-shopping-cart.png'
import profileLogo from '../../../Assets/group-482319@2x.png'
import HomeIcon from '../../../Assets/home_icon_navbar_mobile.png'
import HomeIcon_Active from '../../../Assets/homeIconNavbarActiveMobile.png'
import OffersIcon from '../../../Assets/Offers_icon_navbar_mobile.png'
import OffersIcon_Active from '../../../Assets/offers_icon_navbar_active_mobile.png'
import BookingsIcon from '../../../Assets/bookings_icon_navbar_mobile.png'
import BookingsIcon_Active from '../../../Assets/bookings_icon_navbar_active_mobile.png'
import ProfileIcon from '../../../Assets/profile_icon_navbar_mobile.png'
import ProfileIcon_Active from '../../../Assets/profile_icon_navbar_active_mobile.png'
import wishlist_filled_icon from '../../../Assets/wishlist_filled_color_icon.png'
import cart_filled_icon from '../../../Assets/checkout_filled_color_icon.png'
import slashTran from '../../../Assets/slashTran.png'
import favBlack from '../../../Assets/favBlck.png'
import headArrow from '../../../Assets/headerArrow.png'
import whiteArrowHd from '../../../Assets/whiteArrowHd.png'
import Offer from '../../../../New_Website_V2/Assets/bxs-offer.png'


import shpCrtblk from '../../../Assets/shpCrtblk.png'
import NavPaths from '../NavPaths';
import {mobileHeaderNav, BottomNavHidePaths} from '../NavPaths';
import { useLocation } from "react-router";
import { globalState } from '../../../GlobalState/globalState';
import { useNavigate } from 'react-router-dom';


export default function NavBarV2_Mobile(props){

   // console.log('props',props)

   const navigate=useNavigate()
   const [activeItem, setActiveItem] = useState('Home')
   const [stateColor, setStateColor] = useState()
   const [state,dispatch]=useContext(globalState)
   const [header,setHeader]=useState([])

   let location = useLocation()
   const path = ('/'+location.pathname.split('/')[1]) == '/c' ||  ('/'+location.pathname.split('/')[1]) == '/profile' ?  ('/'+location.pathname.split('/')[2]) : ('/'+location.pathname.split('/')[1])
   console.log('path',path)

   useEffect(() => {

      !NavPaths.includes(path) ? setStateColor('#fff') :  setStateColor('transparent')
       window.scrollTo(0, 0);
      let hasPath = mobileHeaderNav.some(function(ele) {return ele.pathname === path })
          if(hasPath){
           let cloneRecord = mobileHeaderNav.filter(function (el) { return el.pathname == path })
           setHeader(cloneRecord)
          }else{
            setHeader([])
          }

   },[location.pathname])

   function listenScrollEvent()  {
      const backgroundcolor = (window.scrollY  == 0 &&  NavPaths.includes(path))  ? "transparent" : "#fff";
      setStateColor(backgroundcolor);
   }


   useEffect(() => {
      window.addEventListener('scroll', listenScrollEvent);
      return () =>
        window.removeEventListener('scroll', listenScrollEvent);
    }, [NavPaths.includes(path)]);
    


   useEffect(()=>{
    
    
      if(path == "/" || path == "/home"){

         setActiveItem('Home')

       } else if(path == "/offers"){

         setActiveItem('Offers')

       } else if(path == "/c/mybookings"){

         setActiveItem('Bookings')

       } else if(path == "/profile" || path == "/c/deleteAccount"  || path == "/c/savedAddresses" || path == "/setting"  || path == "/referandearn" || path == "/ConsumerEditProfile" || path == "/c/myTransactions"){

         setActiveItem('Profile')

       } else if(path == "/wishlist"){

         setActiveItem('Wishlist')
   
       } else if(path == "/checkout"){
   
         setActiveItem('Checkout')
   
       } else {

         // setActiveItem('Home')
         
       }

    },[path]) 
      

   return(
      <>
      {
      location.pathname == '/referandearn/rewards' ? null :

         <div className='MainReusable_Container_Home'   style={{marginRight:'5%',paddingTop:21,display:'flex',flexDirection:'row',justifyContent:'space-between',backgroundColor:stateColor,boxShadow: stateColor == 'transparent' ?  null : '0 4px 4px 0 rgba(0, 0, 0, 0.1)',width:'100%',position:'fixed',zIndex:100}} >
         {  
         
         header.length ? header[0].HeaderName ?  
         
         <div style={{display:'flex',width:'70%'}}>
         {header[0].showBackIcon ? <img onClick={()=>{ header[0].backIconPath ? navigate(header[0].backIconPath) : navigate(-1)}} height={18} width={18} style={{marginTop:3}} src={stateColor == '#fff' ? headArrow : whiteArrowHd}/>:null}
         <text style={{fontSize:20,fontFamily:'Inter',fontWeight:'bold',marginLeft:'5%',lineHeight:'normal',letterSpacing:'normal',fontStyle:'normal',color: stateColor == 'transparent' ? '#fff':'#8c52ff'}}>{header[0].icon?<img src={Offer} width={24}height={24} style={{marginRight:5,marginTop:-5}}/>:null}{header[0].HeaderName}</text>
         </div>
         :
         <NavbarBrand style={{width:88,marginLeft:'5%'}}><NavLink to="/"><img  height={24} src={stateColor == '#fff' ? logo : slashTran } /></NavLink> </NavbarBrand>
         : <NavbarBrand style={{width:88,marginLeft:'5%'}}><NavLink to="/"><img  height={24} src={stateColor == '#fff' ? logo : slashTran } /></NavLink> </NavbarBrand> }
         
            {/* <Nav  navbar style={{flexDirection:'row'}} >
            {state.token?
               <NavLink to="/wishlist" onClick={() => setActiveItem('Wishlist')}>
                  <img className="image_wishlist_mobile"  style={{marginRight:24,marginTop:-8}} height={21} width={18} src={stateColor === "#fff" ? activeItem == 'Wishlist' ? wishlist_filled_icon : favBlack : activeItem == 'Wishlist' ? wishlist_filled_icon : favouriteLogo} />
               </NavLink> 
            :null} */}

            {/* <NavLink to='/checkout' onClick={() => setActiveItem('Checkout')}>
               <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start'}}>
                  <img className="image_wishlist_mobile"  height={20} width={20} src={stateColor === "#fff" ? activeItem == 'Checkout' ? cart_filled_icon : shpCrtblk : activeItem == 'Checkout' ? cart_filled_icon : cartLogo}/>
                  {state.cartCount>0? <div style={{height:10,width:10,backgroundColor:'red',borderRadius:50,marginLeft:-6,marginTop:-6}}><text style={{display:'flex',fontSize:10,justifyContent:'center',alignItems:'center',color:'#fff'}}>{state.cartCount}</text></div>:null}
               </div>
               </NavLink> */}
         {/* </Nav> */}
         </div> 
      }

      {(BottomNavHidePaths.includes(path)) ? null :

         <Navbar light expand="lg" className='navbar_navbar_main_mobile_style' style={{width:'100%'}}>
               <Nav className="navbar_main_new_mobile px-0">
                  <NavItem className='navItem_mobile_style' onClick={() => setActiveItem('Home')}>
                  <NavLink to={'/'}><img src={ activeItem == 'Home' ? HomeIcon_Active : HomeIcon} style={{width:20, height:20}}/></NavLink>
                     <NavLink to={'/'} className={activeItem == 'Home' ? 'navbar_navlink_mobile_style_active' : 'navbar_navlink_mobile_style'}><text  className={activeItem == 'Home' ? 'navbar_navlink_mobile_style_active' : 'navbar_navlink_mobile_style'} >Home</text></NavLink>
                  </NavItem>
                  <NavItem className='navItem_mobile_style' onClick={() => setActiveItem('Offers')}>
                  <NavLink to="/offers" state={{}}><img src={ activeItem == 'Offers' ?  OffersIcon_Active : OffersIcon} style={{width:20, height:20}}/></NavLink>
                     <NavLink to="/offers" state={{}} className={activeItem == 'Offers' ? 'navbar_navlink_mobile_style_active' : 'navbar_navlink_mobile_style'}><text  className={activeItem == 'Offers' ? 'navbar_navlink_mobile_style_active' : 'navbar_navlink_mobile_style'} >Offers</text></NavLink>
                  </NavItem>

                  {state.token ?
                     <NavItem className='navItem_mobile_style' onClick={() => setActiveItem('Bookings')}>
                        <NavLink to={'/c/mybookings'}><img src={ activeItem == 'Bookings' ? BookingsIcon_Active : BookingsIcon} style={{width:20, height:20}}/></NavLink>
                        <NavLink to={'/c/mybookings'} className={activeItem == 'Bookings' ? 'navbar_navlink_mobile_style_active' : 'navbar_navlink_mobile_style'}><text  className={activeItem == 'Bookings' ? 'navbar_navlink_mobile_style_active' : 'navbar_navlink_mobile_style'} >My Bookings</text></NavLink>
                     </NavItem> 
                  : null}

                  <NavItem className='navItem_mobile_style' onClick={() => setActiveItem('Profile')}>
                  <NavLink to={'/profile'}><img src={ activeItem == 'Profile' ?  ProfileIcon_Active : ProfileIcon} style={{width:20, height:20}}/></NavLink>
                     <NavLink to={'/profile'} className={activeItem == 'Profile' ? 'navbar_navlink_mobile_style_active' : 'navbar_navlink_mobile_style'}><text  className={activeItem == 'Profile' ? 'navbar_navlink_mobile_style_active' : 'navbar_navlink_mobile_style'} >Profile</text></NavLink>
                  </NavItem>
               </Nav>
         </Navbar>  } 
      </>   
   )

}