import React from 'react'
import '../../../CSS/UI Components/ServiceCard.css'
import { AiFillStar } from "react-icons/ai";
import { useMediaQuery } from "react-responsive"
import StarRatings from 'react-star-ratings';
import numeral from 'numeral';
import curr from 'currency.js';
export default function RatingComponent(props) {
  const useTabletAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 760px)" })
  const isMobile = useTabletAndBelowMediaQuery()


  //  console.log(props.webUsage)
  return (
    <div>
      {

     props.webUsage ?
            <div className='ratingComponent' style={{width:props.width ? props.width: null, height:props.height ? props.height: null}} >
            <text className='ratingText_SCard' style={{fontSize: props.foNsize ? props.foNsize : null}} >{numeral(props.rating).format('0.0')}</text>
            <AiFillStar size={props.size ? props.size : 15} color="#fed019" />
            </div>
            :
            isMobile ?
               <div className='ratingComponent' style={{width:props.width ? props.width: null, height:props.height ? props.height: null}}>
               <text className='ratingText_SCard' style={{fontSize: props.foNsize ? props.foNsize : null}}>{numeral(props.rating).format('0.0')}</text>
               <AiFillStar size={15} color="#fed019" />
               </div> 
             :
            <div className="serviceRatingWeb_View">
              <text className='ratingText_SCard'>{numeral(props.rating).format('0.0')}</text>
              <div style={{marginTop:-5,marginLeft:4}}>
                <StarRatings
                  rating={props.rating}
                  starRatedColor="#5ba85a"
                  numberOfStars={5}
                  name='rating'
                  starSpacing={0}
                  starDimension="20px"
                />
              </div>
            </div>
      }
    </div>
  )
}
