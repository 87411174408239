import React,{useState} from 'react'
import {Container, Col, Row } from 'reactstrap'
import curr from "currency.js";
import StarIcon from '../../../Assets/ic-round-star.png'
import StarIconGreen from '../../../Assets/material-symbols-star-rounded.png'
import { useMediaQuery } from "react-responsive"
import StarRatings from 'react-star-ratings';
import Website_Container from '../../../../New_WebPage_Design/Components/Landing_Page/Components/Reusable_Container/WebsiteContainer';
import '../../../CSS/UI Components/RatingsAndReviews.css'
import numeral from 'numeral';
import RatingsReviewIcon from '../../../Assets/RatingsReview.png'
const widthD = window.innerWidth;
export default function RatingsAndReviews(props) {
    // const [defaultRatings,setDefaultRatings] = useState[5,4,3,2,1,2,3,4,3,1]
    // console.log('widthD',widthD,props)
    const useTabletAndBelowMediaQuery = () => useMediaQuery({ query: "(max-width: 760px)" })
    const isMobile = useTabletAndBelowMediaQuery()
    // console.log('isMobile',isMobile)
    let width,mulValue;
    if(isMobile){
        if(widthD <= 375){
            width='115%'
            mulValue=125
        }else if(widthD > 375 && widthD <= 760){
            width='140%'
            mulValue=140
        }
    }else{
        if(props.serviceDetails){
            width='125%'
            mulValue=125
        }else{
            // if(widthD > 760){
                width='220%'
                mulValue=220
            // }
        }
    }
    let widthCalRate1,widthCalRate2,widthCalRate3,widthCalRate4,widthCalRate5;
    if(props.ratings1!=0){
        widthCalRate1=curr(curr(props.ratings1).divide(props.totalNofRatings).value).multiply(mulValue).value
    }else{
        widthCalRate1=0
    }
    if(props.ratings2!=0){
        widthCalRate2=curr(curr(props.ratings2).divide(props.totalNofRatings).value).multiply(mulValue).value
    }else{
        widthCalRate2=0
    }
    if(props.ratings3!=0){
        widthCalRate3=curr(curr(props.ratings3).divide(props.totalNofRatings).value).multiply(mulValue).value
    }else{
        widthCalRate3=0
    }
    if(props.ratings4!=0){
        widthCalRate4=curr(curr(props.ratings4).divide(props.totalNofRatings).value).multiply(mulValue).value
    }else{
        widthCalRate4=0
    }
    if(props.ratings5!=0){
        widthCalRate5=curr(curr(props.ratings5).divide(props.totalNofRatings).value).multiply(mulValue).value
    }else{
        widthCalRate5=0
    }
let verticalLine;
if(isMobile){
    verticalLine={
        width: 1,
        height: 130,
        backgroundColor: "#e8e8e8",
        marginLeft:widthD <= 375 ? 15 : 40
    }  
}else{
    verticalLine={
        width: 1,
        height: 130,
        backgroundColor: "#e8e8e8",
        marginLeft:20
    } 
}
  return (
    <div>
        <div style={{textAlign:'initial',display:'flex',alignItems:'center'}}>
            <img src={RatingsReviewIcon} style={{width:20,height:20,marginRight:10}}/>
            <text className={"rateAndReviewtext"}>Ratings & Reviews</text>
        </div>
        <div style={{flexDirection:'row',display:'flex',marginTop:20,marginBottom:20}}>
            <div style={{flexDirection:'column',display:'flex',justifyContent:'center',marginLeft:10,marginRight:10}}>
                <div style={{flexDirection:'row',display:'flex',justifyContent:'center'}}>
                    <text className='ratingText' style={{fontSize: props.serviceDetails && 26}}>{props.rating ? numeral(props.rating).format('0.0') : ''}</text>
                    <div style={{flexDirection:'column',display:'flex',justifyContent:'center'}}>
                        {
                        isMobile  ? <img src={StarIconGreen}/> : 
                        <div style={{marginTop:-5}}>
                            <StarRatings
                                rating={props.rating}
                                starRatedColor="#5ba85a"
                                numberOfStars={5}
                                name='rating'
                                starSpacing={0}
                                starDimension="20px"
                            />  
                          </div>
                          }                    
                    </div>
                </div>
                <text className={"ratings"}>{props.totalNofRatings + (props.totalNofRatings > 1 ? " Ratings" : " Rating")} </text>
                {/* <text style={styles.ratings}>{props.reviews} Reviews</text> */}
            </div>
            <div style={verticalLine} ></div>
            <div style={{paddingLeft:20,paddingRight:20,justifyContent:'center'}}>
                <div style={{flexDirection:'row',display:'flex',alignItems:'center',marginTop:2,marginBottom:2}}>
                    <div>
                        <text className='rate5'>5</text>
                    </div>
                    <div style={{marginLeft:5}}>
                        <img src={StarIcon}/>
                    </div>
                    <div style={{marginLeft:10}}>
                        <div style={{justifyContent:'flex-start',display:'flex',flexDirection:'row',width:width}}>
                            <div style={{
                                width:widthCalRate5,
                                height: 3,
                                backgroundColor: "#5ba85a"}}>
                        
                            </div>
                            <div style={{
                                width: curr(width).subtract(widthCalRate5).value,
                                height: 3,
                                backgroundColor: "rgba(116, 116, 116, 0.23)"}}>
                            </div>
                        </div>
                    </div>
                    <div style={{marginLeft:10}}>
                        <text className={"ratingNums"}>{props.ratings5}</text>
                    </div>
                </div>
                <div style={{flexDirection:'row',display:'flex',alignItems:'center',marginTop:2,marginBottom:2}}>
                    <div>
                        <text className="rate5">4</text>
                    </div>
                    <div style={{marginLeft:5}}>
                        <img src={StarIcon}/>
                    </div>
                    <div style={{marginLeft:10}}>
                        <div style={{justifyContent:'flex-start',display:'flex',flexDirection:'row',width:width}}>
                            <div style={{
                                width:widthCalRate4,
                                height: 3,
                                backgroundColor: "#9deb49"}}>
                        
                            </div>
                            <div style={{
                                width: curr(width).subtract(widthCalRate4).value,
                                height: 3,
                                backgroundColor: "rgba(116, 116, 116, 0.23)"}}>
                            </div>
                        </div>
                    </div>
                    <div style={{marginLeft:10}}>
                        <text className={"ratingNums"}>{props.ratings4}</text>
                    </div>
                </div>
                <div style={{flexDirection:'row',display:'flex',alignItems:'center',marginTop:2,marginBottom:2}}>
                    <div>
                        <text className="rate5">3</text>
                    </div>
                    <div style={{marginLeft:5}}>
                        <img src={StarIcon}/>
                    </div>
                    <div style={{marginLeft:10}}>
                        <div style={{justifyContent:'flex-start',display:'flex',flexDirection:'row',width:width}}>
                            <div style={{
                                width:widthCalRate3,
                                height: 3,
                                backgroundColor: "#ffff35"}}>
                        
                            </div>
                            <div style={{
                                width: curr(width).subtract(widthCalRate3).value,
                                height: 3,
                                backgroundColor: "rgba(116, 116, 116, 0.23)"}}>
                            </div>
                        </div>
                    </div>
                    <div style={{marginLeft:10}}>
                        <text className={"ratingNums"}>{props.ratings3}</text>
                    </div>
                </div>
                <div style={{flexDirection:'row',display:'flex',alignItems:'center',marginTop:2,marginBottom:2}}>
                    <div>
                        <text className="rate5">2</text>
                    </div>
                    <div style={{marginLeft:5}}>
                    <img src={StarIcon}/>
                    </div>
                    <div style={{marginLeft:10}}>
                        <div style={{justifyContent:'flex-start',display:'flex',flexDirection:'row',width:width}}>
                            <div style={{
                                width:widthCalRate2,
                                height: 3,
                                backgroundColor: "#feb104"}}>
                        
                            </div>
                            <div style={{
                                width: curr(width).subtract(widthCalRate2).value,
                                height: 3,
                                backgroundColor: "rgba(116, 116, 116, 0.23)"}}>
                            </div>
                        </div>
                    </div>
                    <div style={{marginLeft:10}}>
                        <text className={"ratingNums"}>{props.ratings2}</text>
                    </div>
                </div>
                <div style={{flexDirection:'row',display:'flex',alignItems:'center',marginTop:2,marginBottom:2}}>
                    <div>
                        <text className="rate5">1</text>
                    </div>
                    <div style={{marginLeft:6}}>
                    <img src={StarIcon}/>
                    </div>
                    <div style={{marginLeft:10}}>
                        <div style={{justifyContent:'flex-start',display:'flex',flexDirection:'row',width:width}}>
                            <div style={{
                                width:widthCalRate1,
                                height: 3,
                                backgroundColor: 'red'}}>
                        
                            </div>
                            <div style={{
                                width: curr(width).subtract(widthCalRate1).value,
                                height: 3,
                                backgroundColor: "rgba(116, 116, 116, 0.23)"}}>
                            </div>
                        </div>
                    </div>
                    <div style={{marginLeft:10}}>
                        <text className={"ratingNums"}>{props.ratings1}</text>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

    

